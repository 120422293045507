import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { PasswordMatchPattern } from 'src/app/services/validation/password-match-pattern';
import { HttpClient } from '@angular/common/http';
import { loadStripe } from '@stripe/stripe-js';
import { join, parse } from 'path';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  AddForm: FormGroup | any; submitted = false; btnsubmitted = false;
  paymentHandler: any = null;
  CategoryList:any=[]
  value:any=[]
  stripeAPIKey: any = 'pk_live_51LHclFKqsR0a21um0DThlLmARmGPnKVCKGkgp8hIUWYFTc9GuWat0iFmgYYK51urkUTkAXUCc4LTWNKPHMr2QcT900IVbCAVrf';
  constructor(private http: HttpClient,private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService) {

  }
  get f() { return this.AddForm.controls; }

  ngOnInit(): void {
    this.invokeStripe();
  }
  onClosePopup() {
    this.commonService.Setpayments(false);
  }
  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
  
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: this.stripeAPIKey,
          locale: 'auto',
          amount: 100,
          token: function (stripeToken: any){
            console.log(stripeToken);
            alert('Payment has been successfull!');
          },
        });
      };
      window.document.body.appendChild(script);
    }
  }
  onCheckout(): void {
    this.http.post('https://api.skolbryggan.se/create-checkout-session', {
        items: JSON.parse(localStorage.getItem('book_slot_student') || '{}'),
      })
      .subscribe(async (res: any) => {
        let stripe = await loadStripe('pk_live_51LHclFKqsR0a21um0DThlLmARmGPnKVCKGkgp8hIUWYFTc9GuWat0iFmgYYK51urkUTkAXUCc4LTWNKPHMr2QcT900IVbCAVrf');
        stripe?.redirectToCheckout({
          sessionId: res.id,
        });
      });
  }
  cashbackOfferId(){
    // var sendrequestinput:any={amount:1000};
    // this.apiService.post('cashbackOfferId',sendrequestinput).subscribe((data:any) => {
    //   console.log('cashbackOfferId data',data.data);
    //   window.location.href =  data.data;
      
    //   this.CategoryList=data.data;
    // })
    var jsonData: any = localStorage.getItem('supertoken');
    this.value = JSON.parse(jsonData);
    window.location.href =this.value.redirectUrl ;
  }
}
