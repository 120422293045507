<div class="modal fade show pp" id="c-l" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered logmod" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">Add Group</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePopup()">
               <span aria-hidden="true">×</span>
               </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 pr-0">

                        <form [formGroup]="createuser1">
                            <div class="emp_area">
                                <div class="name_feild bdrb">
                                    <div class="row justify-content-center">
                                        <div class="col-md-4 text-center pb-4">
                                            <img src="assets/images/p-blank.jpg" alt="" class="m-auto img-fluid" *ngIf="uploadedImagesData ==''">
                                            <img [src]="allowurl(apiService.imageBase+uploadedImagesData)" alt="" class="m-auto img-fluid" *ngIf="uploadedImagesData !=''">
                                            <p class="mt-3">Add Group Icon</p>
                                            <p><button class="compare"><i class="fas fa-plus"></i><input
                                                type="file" accept="image/*"
                                                (change)="onSelectFile($event.target)" #file id="file"
                                                style="    opacity: 0;
                                                position: absolute;
                                                left: 1px;"> Add Photo</button></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="name_feild">
                                    <p>Group Name</p>
                                    <div><input type="text" class="form-control" formControlName="group_name" [ngClass]="{ 'is-invalid': submitted1 && f1.group_name.errors }">
                                        <div *ngIf="f1.group_name.invalid && (f1.group_name.dirty || f1.group_name.touched)">

                                            <div class="warning" *ngIf="f1.group_name.errors.required">
                                                Group Name is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="emp_area">
                                    <div class="name_feild">
                                        <p>Select Group Type</p>
                                        <div>
                                            <select name="" id="" class="form-control" formControlName="type" [ngClass]="{ 'is-invalid': submitted1 && f1.type.errors }">
                                                <option value="">Select</option>, 
                                               
                                                <!-- <option value="1">Password protected group</option> -->
                                                <option value="2">Invitation only group</option>
                                                <option value="3">Private (hidden)</option>
                                                <option value="0">default</option>
                                            </select>
                                            <div *ngIf="f1.type.invalid && (f1.type.dirty || f1.type.touched)">

                                                <div class="warning" *ngIf="f1.type.errors.required">
                                                     Type is required.
                                                </div>
                                            </div></div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary mt-4" data-dismiss="modal" (click)="onClosePopup()">Cancel</button>
                <button type="button" class="submit" (click)="onSubmit()">{{editFlag?'Update':'Add'}}</button>
            </div>
          
        </div>
        
    </div>
</div>