import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';

import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-brand-product',
  templateUrl: './brand_product.component.html',
  styleUrls: ['./brand_product.component.scss']
})
export class BrandProductComponent implements OnInit {
  flag:any=null;
  brand=null;
  homeBlog: any=[];
  resData:any=null;
  catData:any=null;
  constructor(public apiService :ApiService,public sanitizer: DomSanitizer, public activatedRoute: ActivatedRoute,private title: Title, private meta: Meta,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
    this.flag = this.activatedRoute.snapshot.paramMap.get("brand");
    if (this.flag) {
      this.brand = this.flag;
    }
    
  }

  ngOnInit(): void {
    this.getDataById();
  }
  allowurl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getDataById() {
    var self = this;
    var sendRequestData = {
      isAdmin:0,
      brand:this.brand
    };
    self.apiService.post('product_list', sendRequestData).subscribe((res: any) => {
      if (res.replyCode == 'success') {
        this.resData = res.data;
        
      }
    },
    error => {
      console.log("Oops! Something went wrong!");
    });

  }

}
