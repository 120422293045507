
<style>
  .style{
    transition: 0.2s all;
    color: black !important;
    border-radius: 30px !important;
    border: 2px solid #cab776;
    padding: 9px 25px !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    background: none;
  }
</style>

<div class="">
  <div class="container">
    <div class="col-md-12">
      <div class="card center-div">
        <div class="srt">
          <i class="checkmark">✓</i> 
        </div>
        <h1>Payment Success</h1>
        <p class="pt-2">
          We received your purchase request;<br/>
          we'll be in touch shortly!
        </p>
           <a href="https://demo.skolbryggan.se" >  <button class=" style1 bg">Go Back</button> </a>
      </div>
    </div>
  </div>
</div>
