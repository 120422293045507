<div class="modal fade show pp" id="c-l" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered logmod" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">Update User</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePopup()">
               <span aria-hidden="true">×</span>
               </button>
            </div>
            <div class="modal-body">
                
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 pr-0">

                        <form [formGroup]="createuser1">
                            <div class="name_feild bdrb">
                                <div class="row justify-content-center">
                                    <div class="col-md-4 text-center pb-4">
                                        <img src="assets/images/p-blank.jpg" alt="" class="m-auto img-fluid" *ngIf="uploadedImagesData ==''">
                                        <img [src]="allowurl(uploadedImagesData)" alt="" class="m-auto img-fluid" *ngIf="uploadedImagesData !=''">
                                        <p class="mt-3">Add Group Icon</p>
                                        <p><button class="compare"><i class="fas fa-plus"></i><input
                                            type="file" accept="image/*"
                                            (change)="onSelectFile($event.target)" #file id="file"
                                            style="    opacity: 0;
                                            position: absolute;
                                            left: 1px;"> Add Photo</button></p>
                                    </div>
                                </div>
                            </div>
                            <div class="emp_area">
                                <div class="name_feild">
                                    <p>User Name</p>
                                    <p>
                                        <input name="" id="" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid': submitted1 && f1.name.errors }">
                                     
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary mt-4" data-dismiss="modal" (click)="onClosePopup()">Cancel</button>
                <button type="button" class="submit" (click)="onSubmit()">{{editFlag?'Update':'Add'}}</button>
            </div>
        </div>
    </div>
</div>