<div class="mb-3 ptfix contT">
    <div class="container pt-4">
        <div class="row">
            <div class="col-md-12">
                <ul class="breadcrumb">
                    <li><a href="/">Home</a></li>
                    <li class="active">På plats class enquiry </li>
                </ul>
            </div>

            <div class="col-md-12">
                <h1><span class="txt_gray mob_hide">Welcome to our</span> På plats Class Enquiry</h1>
            </div>
        </div>
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->

<!-- Content Stars -->
<section class="animated3 wow drop_eff5">
    <div class="container mb-5">
        <div class="row">
            <div class="col-lg-12 wow fadeInLeft ">
                <div class="row">
                   
                </div>
                <div class="row mt-5 bg-color person-class-enquiry">
                    <div class="row MB-5">
                        <div class="col-md-12">
                          <h1 class="text-center mb-1 animated2 drop_eff2">Enquire Now</h1>
                          <p class="ac mb-3 fs16">	Se för dig själv vad våra kunder säger om oss
                        </p>
                        </div>
                      </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="contact-enquire">
                            <form  [formGroup]="addForm">
                                <div class="form-group">
                                    <label for="inputName">Name</label>
                                    <input type="text" formControlName="first_name" class="form-control" id="inputName" placeholder="Name">
                                </div>
                                <div class="form-group">
                                    <label for="inputEmail4">Email</label>
                                    <input type="email" formControlName="email" class="form-control" id="inputEmail4" placeholder="Email">
                                </div>
                                <div class="form-group">
                                    <label for="inputLast">Father Name</label>
                                    <input type="text" formControlName="last_name" class="form-control" id="inputLast" placeholder="Father Name">
                                </div>
                                
                                <div class="form-group">
                                    <label for="inputLast">Mobile No.</label>
                                    <input type="text" formControlName="mobile" class="form-control" id="inputLast" placeholder="Mobile Number">
                                </div>
                                <div class="form-group">
                                    <label >Subject</label>
                                    <input class="form-control" placeholder="Subject" rows="3" formControlName="subject">
                                </div>

                                <div class="form-group">
                                    <label for="inputLast">Additional Details</label>
                                    <input type="text" formControlName="additional_details" class="form-control" id="inputLast" placeholder="Additional Details">
                                </div>

                                <button class="style1 bg"  (click)="onaddForm()">Submit</button>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="Google-map">
                           <img src="./assets/img/tt.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>