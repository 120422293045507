<div class="clearfix"></div>
<div class="mb-3 contT">
    <div class="container pl-3">
        <div class="col-md-12">
            <ul class="breadcrumb">
                <li><a href="javascript:void(0);">Home</a></li>
                <li><a href="javascript:void(0);">Hitta en Studiementor</a></li>
                <li class="active">Book A 15 Minute Meeting</li>
            </ul>
        </div>
    </div>
</div>
<div class="clearfix"></div>
<section class="animated3 wow drop_eff5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="Book-a-New-Class">
                    <h1 class="">Book a complimentary class</h1>
                </div>
            </div>

            <div class="col-md-5 col-lg-3 col-sm-12 col-xs-12 New-Class-bg-gray" *ngIf="TeacherDetails" style="padding: 20px 0px 30px 30px;">
                <div class="list_box">
                    <a data-type="iframe" routerLink="/tutor-details/{{TeacherDetails.id}}" href="javascript:void(0);"
                        title="" class="port_pop1"><img [src]="allowurl(apiService.imageBase+TeacherDetails.image)"
                            onError="this.src='assets/images/profile-demo.png'" alt="" class="img-fluid"></a>
                    <div class="p-3">
                        <p *ngIf="TeacherDetails.avg_rating?.toFixed()==5" class="star"><i class="fas fa-star"></i><i
                                class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                class="fas fa-star"></i><span class="letn"> Rating</span></p>
                        <p *ngIf="TeacherDetails.avg_rating?.toFixed()==4" class="star"><i class="fas fa-star"></i><i
                                class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                class='far'>&#xf005;</i><span class="letn"> Rating</span></p>
                        <p *ngIf="TeacherDetails.avg_rating?.toFixed()==3" class="star"><i class="fas fa-star"></i><i
                                class="fas fa-star"></i><i class="fas fa-star"></i><i class='far'>&#xf005;</i><i
                                class='far'>&#xf005;</i><span class="letn"> Rating</span></p>
                        <p *ngIf="TeacherDetails.avg_rating?.toFixed()==2" class="star"><i class="fas fa-star"></i><i
                                class="fas fa-star"></i><i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i
                                class='far'>&#xf005;</i><span class="letn"> Rating</span></p>
                        <p *ngIf="TeacherDetails.avg_rating?.toFixed()==1" class="star"><i class="fas fa-star"></i>
                            <i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i
                                class='far'>&#xf005;</i><i class='far'>&#xf005;</i>
                            <span class="letn"> Rating</span>
                        </p>
                        <p *ngIf="TeacherDetails.avg_rating==null" class="star"><i class='far'>&#xf005;</i>
                            <i class='far'>&#xf005;</i>
                            <i class='far'>&#xf005;</i>
                            <i class='far'>&#xf005;</i>
                            <i class='far'>&#xf005;</i>

                            <span class="letn"> Rating</span>
                        </p>

                        <div class="Tutors-name">
                            <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title=""
                                class="port_pop1">
                                <h4>{{TeacherDetails.name}}</h4>
                            </a>
                            <span>SEK {{TeacherDetails.rate}}.00/hr</span>
                        </div>
                        <div class="Tutors-name-Gujarat">
                            <p class="fs12 st2024">{{TeacherDetails.city_name}}, {{TeacherDetails.state_name}},
                                {{TeacherDetails.country_name}}</p>

                            <p class="tbot st2023">Totala klass Erfarenhet : <span>{{TeacherDetails.work_experience}}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- <p class="link_btn"><a href="javascript:void(0);" routerLink="/chats/{{TeacherDetails?.id}}"
                        (click)="onClosePopup()"><i class="fas fa-comments"></i>Let’s Chat </a></p> -->
                <p class="link_btn"><a href="javascript:void(0);" routerLink="/book-a-class/{{TeacherDetails.id}}"
                        (click)="onClosePopup()"><i class="fas fa-laptop fs18"></i>Intresseanmälan </a></p>

            </div>
            <div class="col-md-7 col-lg-6 col-sm-12 col-xs-12 New-Class-bg-gray">
                <div class="list_box p-3 m-auto bg-gray">
                    <form [formGroup]="createuser">
                        <div class="emp_area">
                            <div class="form-group">
                                <label for="">Level</label>
                                <select name="" id="" class="form-control" formControlName="level_id"
                                    [ngClass]="{ 'is-invalid': submitted && f.level_id.errors }">
                                    <option value="">Select</option>
                                    <option [value]="lvl.id" *ngFor="let lvl of LevelList">{{lvl.title}}
                                    </option>
                                </select>
                                <div *ngIf="f.level_id.invalid && (f.level_id.dirty || f.level_id.touched)">

                                    <div class="warning" *ngIf="f.level_id.errors.required">
                                        level is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Subject</label>
                                <select name="" id="" class="form-control" formControlName="subject_id"
                                    [ngClass]="{ 'is-invalid': submitted && f.subject_id.errors }">
                                    <option value="">Select</option>
                                    <option [value]="lvl.id" *ngFor="let lvl of CategoryList">{{lvl.name}}
                                    </option>
                                </select>
                                <div *ngIf="f.subject_id.invalid && (f.subject_id.dirty || f.subject_id.touched)">

                                    <div class="warning" *ngIf="f.subject_id.errors.required">
                                        subject is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">For whom is the class for?</label>
                                <select name="" id="" class="form-control" formControlName="tution_for"
                                    [ngClass]="{ 'is-invalid': submitted && f.tution_for.errors }">
                                    <option value="">Select</option>
                                    <option value="{{localData.name}} {{localData.last_name}}" selected>
                                        {{localData.name}} {{localData.last_name}}</option>

                                </select>
                                <div *ngIf="f.tution_for.invalid && (f.tution_for.dirty || f.tution_for.touched)">

                                    <div class="warning" *ngIf="f.tution_for.errors.required">
                                        Select tution For is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Frequency of the tuition classes?</label>
                                <select name="" id="" class="form-control" formControlName="tution_frequency"
                                    [ngClass]="{ 'is-invalid': submitted && f.tution_frequency.errors }">
                                    <option value="">Select</option>
                                    <option value="Regular lessons">Regular lessons</option>
                                    <option value="One-off lesson">One-off lesson</option>
                                </select>
                                <div
                                    *ngIf="f.tution_frequency.invalid && (f.tution_frequency.dirty || f.tution_frequency.touched)">

                                    <div class="warning" *ngIf="f.tution_frequency.errors.required">
                                        Tution Frequency is required.
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-4">
                                <div class="form-group col-md-6">
                                    <label for="">Select Date</label>
                                    <select name="" id="" (change)="onSelectDate($event.target)" class="form-control"
                                        formControlName="schedule_slot_date"
                                        [ngClass]="{ 'is-invalid': submitted && f.schedule_slot_date.errors }">
                                        <option value="">Select</option>
                                        <option [value]="lvl.id" *ngFor="let lvl of TeacherSLotDetails"
                                            [disabled]="lvl.available != 1">{{lvl.schedule_date |date}}
                                        </option>
                                    </select>
                                    <div
                                        *ngIf="f.schedule_slot_date.invalid && (f.schedule_slot_date.dirty || f.schedule_slot_date.touched)">

                                        <div class="warning" *ngIf="f.schedule_slot_date.errors.required">
                                            Select Schedule Slot Date is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="">Select Slot</label>
                                    <select name="" id="" class="form-control" formControlName="slot_id"
                                        [ngClass]="{ 'is-invalid': submitted && f.slot_id.errors }"
                                        (change)="onSelectTime($event.target)">
                                        <option value="">Select</option>
                                        <option [value]="lvl.id" *ngFor="let lvl of SlotData"
                                            [disabled]="lvl.available != 1">{{lvl.time_from}} - {{lvl.time_to}}
                                        </option>
                                    </select>
                                    <div *ngIf="f.slot_id.invalid && (f.slot_id.dirty || f.slot_id.touched)">

                                        <div class="warning" *ngIf="f.slot_id.errors.required">
                                            This field is required.
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div class="loc_feild">
                                <div class="row justify-content-center">
                                    <div
                                        class="col-md-6 col-lg-6 col-sm-6 col-xs-6 p-0 mb-2 nyo justify-content-center">
                                        <a href="javascript:void(0);" class="style1" (click)="onSubmit()"><i
                                                class="fas fa-laptop"></i> Confirm</a>
                                    </div>
                                    <div
                                        class="col-md-6 col-lg-6 col-sm-6 col-xs-6 p-0 mb-2 nyo justify-content-center">
                                        <a href="javascript:void(0);" class="style1" (click)="backClicked()"><i
                                                class="fas fa-laptop"></i> Cancel</a>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>