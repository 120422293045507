<!--head+filter-->
<div class="mb-3 contT">
    <div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="javascript:void(0);" routerLink="/">Home</a></li>
            <li class="active">Hitta en Studiementor</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3 mb-5">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="content_cent">
                    <h1 class="b">Enastående mentorer som passar ditt schema. </h1>Hitta fantastiska mentorer, lär känna dem och sikta mot stjärnorna tillsammans!
                </div>
            </div>
            <div class="col-lg-4 col-sm-12 pb-4">
                <div class="text-right tutorandpress">Markera bara  <img src="assets/images/tickc.jpg" alt=""> 3 handledarprofiler och tryck på knappen
                    <form class="frmt">
                        <input type="submit" class="style1 bg" value="Jämför" (click)="getCompare()">
                    </form> 
                </div>
            </div>
        </div>
    </div>
</div>
<div class="clearfix"></div>

<section class="Find-A-Tutor">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12 col-sm12">
                <div class="Find-A-Tutor-side-bar">
                    <div class="serch-main">
                        <div class="Filter-Your-Search"><h5>Filtrera din sökning</h5></div>
                        <form [formGroup]="createuser" class="main-serch-bar">
                            <div class="row justify-content-center">
                                <div class="col-md-12">
                                    <div class="name_feild">
                                        <p class="">Ämne</p>
                                        <p><select name="" id="" class="form-control" formControlName="slot_id"
                                                [(value)]="type" (click)="onSearchKey($event.target)">
                                                <option value="">Välj </option>
                                                <option [value]="lvl.id" *ngFor="let lvl of CategoryList">{{lvl.name}}
                                                </option>
                                            </select>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="name_feild">
                                        <p class="">Plats</p>
                                        <p><select id="" class="form-control" formControlName="slot_id"
                                                (change)="onChangeMethod($event.target)">
                                                <option value="">Välj</option>
                                                <option value="1">Online</option>
                                                <option value="2">In-person</option>
                                            </select>
                                        </p>
                                        <!-- <p class="flt dn" id="off"><input type="text" formControlName="slot_id"
                                                placeholder="Enter Postcode"></p> -->
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="name_feild">
                                        <p class="">Nivå</p>
                                        <p><select id="" class="form-control" formControlName="slot_id" [(value)]="type"
                                                (change)="onChangeLevel($event.target)">
                                                <option value="">Välj </option>
                                                <option [value]="lvl.id" *ngFor="let lvl of LevelList">{{lvl.title}}
                                                </option>
                                            </select></p>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="name_feild">
                                        <p class="">Postnummer</p>
                                        <!-- <div class="selection-group">
                                            <p><select id="" class="form-control" formControlName="slot_id"
                                                    (change)="onChangegender($event.target)">
                                                    <option value="">Select </option>
                                                    <option value="1">Male </option>
                                                    <option value="2">Female </option>
                                                    <option value="3">Other </option>
                                                </select></p>
                                        </div> -->
                                        <p class=" mt-2" id="off" >
                                            <input type="text" class="form-control" #postcodeInput placeholder="Ange postnummer">
                                            <button type="button" class="style1" (click)="onSearchpostcode(postcodeInput.value)" style="margin-top: 12px;width: -webkit-fill-available;">Sök</button>
                                          </p>
                                          
                                          
                                    </div>
                                </div>
                                <div class="col-md-12 mt-3" align="center">
                                    <span class=""><a href="javascript:void(0);" class="style1 bg custm-padding"
                                            (click)="clearfilter()">Rensa filter</a></span>
                                    <span class=""><a href="javascript:void(0);" (click)="onFlag1()"
                                            class="style1 custm-padding">{{flag1?'Stäng':'Mer'}}
                                            filter</a></span>
                                </div>
                            </div>


                            <div id="collapse1" class="dn" [ngStyle]="{'display': flag1 ? 'block' : 'none'}">
                                <div class="row">
                                    <div class="col-md-12 col-sm-12 col-lg-12 mt-5">
                                        <div class="tutors_date">
                                            <label> Startdatum</label>
                                            <input id="" class="form-control ng-valid ng-touched ng-dirty" type="date" formControlName="slot_id" [ngModel]="startDate | date:'yyyy-MM-dd'"
                                                (ngModelChange)="setDate('start', $event)" name="startDate">
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-lg-12 mt-3">
                                        <div class="tutors_date">
                                            <label> Slutdatum</label>
                                            <input id="" class="form-control ng-valid ng-touched ng-dirty" type="date" formControlName="slot_id"[ngModel]="endDate"
                                                (ngModelChange)="setDate('end', $event)" name="endDate">
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-12 col-sm-12 col-lg-12 mt-3">
                                        <div class="tutors_date">
                                            <label>Time Slot</label>
                                            <select id="" formControlName="slot_id" class="form-control ng-valid ng-touched ng-dirty"
                                                (change)="onChangetime($event.target)">
                                                <option value="">select</option>
                                                <option value="08:00:00 09:00:00" selected>08:00:00 - 09:00:00</option>
                                                <option value="09:00:00 10:00:00" selected>09:00:00 - 10:00:00</option>
                                                <option value="10:00:00 11:00:00" selected>10:00:00 - 11:00:00</option>
                                                <option value="11:00:00 12:00:00" selected>11:00:00 - 12:00:00</option>
                                                <option value="12:00:00 13:00:00" selected>12:00:00 - 13:00:00</option>
                                                <option value="13:00:00 14:00:00" selected>13:00:00 - 14:00:00</option>
                                                <option value="14:00:00 15:00:00" selected>14:00:00 - 15:00:00</option>
                                                <option value="15:00:00 16:00:00" selected>15:00:00 - 16:00:00</option>
                                                <option value="16:00:00 17:00:00" selected>16:00:00 - 17:00:00</option>
                                                <option value="17:00:00 18:00:00" selected>17:00:00 - 18:00:00</option>
                                                <option value="18:00:00 19:00:00" selected>18:00:00 - 19:00:00</option>
                                                <option value="19:00:00 20:00:00" selected>19:00:00 - 20:00:00</option>

                                            </select>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-12 col-sm-12 col-lg-12 mt-3">
                                        <div class="loc_feild tutors_date">
                                            <label> Price Range</label>
                                            <ngx-slider [(value)]="value" formControlName="slot_id"
                                                [(highValue)]="highValue" [options]="options"
                                                (click)="rangefilter(value,highValue)"></ngx-slider>

                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 col-md-12 col-sm12">
                <div class="Find-A-Tutor-main-bar">
                    <div class="row Show-Records mb-5">
                        <div class="col-lg-6 col-md-6 col-sm-12 showrecord">
                            <label>
                                Visa
                                <select class="filter-select mr-2" [(ngModel)]="bodyDataRequest['limit']"
                                    (change)="onChangeLimit($event.target.value)">
                                    <option selected disabled>Välj</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                </select>
                                Poster
                            </label>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 text-right">
                            <pagination-controls previousLabel="Föregående" class="showrecordd" nextLabel="Nästa"
                                (pageChange)="onPageChange($event)"></pagination-controls>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 wow fadeInLeft"
                            *ngFor="let list of tutorList| paginate:{itemsPerPage:bodyDataRequest['limit'],currentPage:p,totalItems:total_count};let i= index">
                            <div class="list_box Featured-Tutors">
                                <div *ngIf="LoginUserDetails.role_id!=3 && LoginUserDetails">
                                    <!-- <a *ngIf="LoginUserDetails.role_id!=5" href="javascript:void(0);" class="chat_btn"
                                        routerLink="/chats/{{list?.id}}" title="Let's Chat"><i
                                            class="fas fa-comments"></i></a> -->

                                     <!-- <a href="javascript:void(0);" class="book_btn" title="Book a free trial class"
                                        routerLink="/book-a-demo-class/{{list.id}}"><i class="fas fa-handshake"></i></a>  -->

                                    <a *ngIf="LoginUserDetails.role_id!=3" href="javascript:void(0);" class="class_btn"
                                        title="Intresseanmälan " routerLink="/book-a-class/{{list.id}}" style="left: 40%;"><i
                                            class="fas fa-laptop"></i></a>
                                </div>
                                <div *ngIf="!LoginUserDetails">
                                    <!-- <a href="javascript:void(0);" class="chat_btn" (click)="onOpenLoginPopup()"
                                        title="Let's Chat"><i class="fas fa-comments"></i></a>
                                    <a *ngIf="LoginUserDetails.role_id!=5" href="javascript:void(0);" class="book_btn"
                                        title="Book a free trial class" (click)="onOpenLoginPopup()"><i
                                            class="fas fa-handshake"></i></a> -->
                                    <a *ngIf="LoginUserDetails.role_id!=3" href="javascript:void(0);" class="class_btn"
                                        title="Intresseanmälan " (click)="onOpenLoginPopup()" style="left: 40%;"><i
                                            class="fas fa-laptop"></i></a>
                                </div>
                                <div class="snip1205 desktop_images_fix">
                                    <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:void(0);"
                                        title="" class="port_pop1 mb-3">
                                        <div class="div"><a href="javascript:void(0);"
                                                routerLink="/tutor-details/{{list.id}}" title="" class="port_pop1">
                                                <img [src]="allowurl(apiService.imageBase+list.image)" alt=""
                                                    class="img-fluid"
                                                    onerror="this.src='assets/images/profile-demo.png';" alt="..."
                                                    style="width: 100%;height: 100%;"></a>
                                        </div>
                                    </a>
                                </div>

                                <div class="all-Featured">
                                    <div class="star-reating mb-0">
                                        <div class="star-reating">
                                            <p *ngIf="list.avg_rating?.toFixed()==5" class="star"><i
                                                    class="fas fa-star"></i><i class="fas fa-star"></i><i
                                                    class="fas fa-star"></i><i class="fas fa-star"></i><i
                                                    class="fas fa-star"></i><span class="letn">
                                                    Rating</span></p>
                                            <p *ngIf="list.avg_rating?.toFixed()==4" class="star"><i
                                                    class="fas fa-star"></i><i class="fas fa-star"></i><i
                                                    class="fas fa-star"></i><i class="fas fa-star"></i><i
                                                    class='far'>&#xf005;</i><span class="letn">
                                                    Rating</span></p>
                                            <p *ngIf="list.avg_rating?.toFixed()==3" class="star"><i
                                                    class="fas fa-star"></i><i class="fas fa-star"></i><i
                                                    class="fas fa-star"></i><i class='far'>&#xf005;</i><i
                                                    class='far'>&#xf005;</i><span class="letn">
                                                    Rating</span></p>
                                            <p *ngIf="list.avg_rating?.toFixed()==2" class="star"><i
                                                    class="fas fa-star"></i><i class="fas fa-star"></i><i
                                                    class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i
                                                    class='far'>&#xf005;</i><span class="letn">
                                                    Rating</span></p>
                                            <p *ngIf="list.avg_rating?.toFixed()==1" class="star"><i
                                                    class="fas fa-star"></i>
                                                <i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i
                                                    class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i
                                                    class='far'>&#xf005;</i>
                                                <span class="letn"> Rating</span>
                                            </p>
                                            <p *ngIf="list.avg_rating==null" class="star"><i class='far'>&#xf005;</i>
                                                <i class='far'>&#xf005;</i>
                                                <i class='far'>&#xf005;</i>
                                                <i class='far'>&#xf005;</i>
                                                <i class='far'>&#xf005;</i>

                                                <span class="letn"> Rating</span>
                                            </p>
                                        </div>
                                        <div class="Tutors-name">
                                            <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;"
                                                title="" class="port_pop1" routerLink="/tutor-details/{{list.id}}">
                                                <h4>{{list.name}}</h4>
                                            </a>
                                            <span>SEK {{list.rate}}.00/hr</span>
                                        </div>
                                        <p class="country_name">{{list.country_name }}</p>
                                        <div class="Tutors-checkbox">
                                            <p class="chkbox"><input type="checkbox"
                                                    (click)="addUserToCompare($event.target,list.id)"></p>
                                            <p class="tbot st2023 desktop_class_center">Totala klass Erfarenhet :
                                                <span>{{list.work_experience}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
