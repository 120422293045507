import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
@Component({
  selector: 'app-edit-group-detail',
  templateUrl: './edit-group-detail.component.html',
  styleUrls: ['./edit-group-detail.component.scss']
})
export class EditGroupDetailComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any = [];
  STudentList: any = [];

  uploadedImagesData: any = '';
  fileToUpload: any = null;
  localData: any;
  createuser1: FormGroup | any; public submitted1 = false;
  editFlag: boolean = false;
  constructor(private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService) {
    var jsonData: any = localStorage.getItem('user');
    this.localData = JSON.parse(jsonData);
  }
  get f1() { return this.createuser1.controls; }

  ngOnInit(): void {
    this.getUserList();

    this.createuser1 = this.fb.group({
      groupname: new FormControl('', [Validators.required]),
      // group_id: new FormControl(''),
    });

  }
  allowurl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  uploadFileToActivity(image: any) {
    this.apiService.postFile(image).subscribe((data: any) => {
      this.uploadedImagesData = data.name;
    }, error => {
      console.log(error);
    });
  }
  onSelectFile(file: any) {
    this.uploadFileToActivity(file.files[0])
    if (file.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(file.files[0]);
      reader.onload = (event) => { }
    }
  }
  onClosePopup() {
    this.commonService.SeteditchatGroup(false);
  }
  groupList: any = {}
  groupid: any
  members: any = {}
  getUserList() {
    this.commonService.GeteditchatGroup().subscribe((data: any) => {
      this.groupid = data.guid
      if (data) {
        // console.log(data,"data.guid");

        var sendrequestinput: any = {}
        sendrequestinput.GUIDs = data.guid;

        sendrequestinput.limit = "";
        sendrequestinput.offset = "";
        this.apiService.post('getGroupList', sendrequestinput).subscribe((data: any) => {
          this.groupList = data.success.data[sendrequestinput.GUIDs];
          this.members = data.success.data[sendrequestinput.GUIDs].group_members;
          console.log(this.groupList, "data.guid");

          // console.log(data.success.data[sendrequestinput.GUIDs].group_members);

          this.createuser1.controls['groupname'].setValue(this.groupList.groupname);
        })
       
      }
    })



  }
  updateGroup() {

        console.log(this.createuser1.value.groupname,"11this.createuser1");

        var sendrequestinput: any = {}
        sendrequestinput.GUID = this.groupid;

        sendrequestinput.name = this.createuser1.value.groupname;
        // sendrequestinput.offset = "";
        this.apiService.post('updateGroup', sendrequestinput).subscribe((data: any) => {

console.log();
      if(data?.success?.status=="2000"){
           this.onClosePopup();
          this.commonService.SetUpdateProfileChanged(true);
          this.messageService.showSuccess("Group Name updated Successfully");
        }
      if(data?.failed?.status=="2001"){
           this.onClosePopup();
          this.commonService.SetUpdateProfileChanged(true);
          this.messageService.showSuccess("Having some issue");
        }
      
      })
       
      }
 


  

  kickUsersFromGroup(value: any) {
    console.log(value, 'any');
    var sendRequestData: any = {};

    sendRequestData.UIDs = value.uid
    sendRequestData.GUID = this.groupid

    console.log(sendRequestData, 'sendRequestData');
    this.apiService.post('kickUsersFromGroup', sendRequestData).subscribe((data: any) => {
      this.getUserList()

    })
  }
  removeUsersFromGroup(value: any) {
    console.log(value, 'any');
    var sendRequestData: any = {};

    sendRequestData.UIDs = value.uid
    sendRequestData.GUID = this.groupid

    console.log(sendRequestData, 'sendRequestData');
    this.apiService.post('removeUsersFromGroup', sendRequestData).subscribe((data: any) => {
      this.getUserList()

    })
  }
  banUsersFromGroup(value: any) {
    console.log(value, 'any');
    var sendRequestData: any = {};

    sendRequestData.UIDs = value.uid
    sendRequestData.GUID = this.groupid

    console.log(sendRequestData, 'sendRequestData');
    this.apiService.post('banUsersFromGroup', sendRequestData).subscribe((data: any) => {
      this.getUserList()

    })
  }
  unbanUsersFromGroup(value: any) {
    console.log(value, 'any');
    var sendRequestData: any = {};

    sendRequestData.UIDs = value.uid
    sendRequestData.GUID = this.groupid

    console.log(sendRequestData, 'sendRequestData');
    this.apiService.post('unbanUsersFromGroup', sendRequestData).subscribe((data: any) => {
      this.getUserList()

    })
  }
  onAdduserfriend() {
    this.commonService.SetAddFriendPopup(this.groupid);
    this.onClosePopup() 
  }
}
