<div class="Scorecard-top contT">
    <div class="container pl-3">
        <div class="row">
            <div class="col-md-12">
                <ul class="breadcrumb">
                    <li><a href="./">Home</a></li>
                    <li class="active">Mitt Resultatkort</li>
                </ul>
                <div class="Scorecard-tittle">
                    <h1><span class="txt_gray mob_hide">Mitt </span>Resultatkort</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="animated3 wow drop_eff5 animated mb-5" style="visibility: visible;">
    <div class="container mb-5">
        <div class="row bstyle-pages">
            <div class="col-md-12 text-center">
                <div class="st-slider">
                    <div class="row justify-content-center pt-4 pb-4">
                        <div class="col-md-2">
                            <p class="cir_img"><img src="assets/images/p-blank.jpg" alt="" class="m-auto img-fluid"
                                    *ngIf="uploadedImagesData ==''">
                                <!-- <img *ngIf="LoginUserDetails?.image"
                                [src]="allowurl(apiService.imageBase + LoginUserDetails.image)"
                                onError="this.src='assets/images/profile-demo.png'" alt="" />  -->
                                <!-- <img *ngIf="!(LoginUserDetails?.image)" src="assets/images/profile-demo.png" alt="" /> -->
                            </p>
                            <h4 class="fs18 pt-2">{{ LoginUserDetails?.name }} {{Username}}</h4>
                        </div>
                        <div class="col-md-2">
                            <div class="progress">
                                <div class="barOverflow">

                                    <div class="bar" [style.transform]="'rotate('+  (result)*260/100+ 'deg)'"></div>
                                </div>
                                <div class="Overall-Performance">{{result.toFixed()/scorecard_length || '0'}}</div>
                            </div>
                            <p class="fs18 b Collaboration-Shown">Exceptional</p>
                            <p class="Collaboration-Showns">Overall Performance Rating</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="s-slider">
                <mdb-carousel>
                    <mdb-carousel-item *ngFor="let studedent_review of student_scorecard" class="row">
                        <div class="col-md-3">
                            <div class="bg_orng">
                                <h4 class="b fs16 mb-2">Collaboration Shown During Lesson</h4>
                                <p *ngIf="studedent_review.collaboration" class="Collaboration-Showns">
                                    {{studedent_review.collaboration}}</p>
                                <p *ngIf="!(studedent_review.collaboration)" class="Collaboration-Showns">0</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="bg_orng">
                                <h4 class="b fs16 mb-2">Student’s strengths</h4>
                                <p *ngIf="studedent_review.strengths" class="Collaboration-Showns">
                                    {{studedent_review.strengths}}</p>
                                <p *ngIf="!(studedent_review.strengths)" class="Collaboration-Showns">0</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="bg_orng">
                                <h5 class="b fs16 mb-2">Area for Improvement</h5>
                                <p *ngIf="studedent_review.Improvement" class="Collaboration-Showns">
                                    {{studedent_review.Improvement}}</p>
                                <p *ngIf="!(studedent_review.Improvement)" class="Collaboration-Showns">0</p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="bg_orng">
                                <h5 class="b fs16 mb-2">What to prepare and progress moving forward
                                </h5>
                                <p *ngIf="studedent_review.moving_forward" class="Collaboration-Showns">
                                    {{studedent_review.moving_forward}}</p>
                                <p *ngIf="!(studedent_review.moving_forward)" class="Collaboration-Showns">0</p>
                            </div>
                        </div>
                    </mdb-carousel-item>

                </mdb-carousel>
            </div>
        </div>


        <div class="row">
            <div class="col-md-9 p-0">
                <div class="row">
                    <!-- <p> [animation]="'slide'"</p> -->
                    <div class="col-md-6">
                        <mdb-carousel>
                            <mdb-carousel-item *ngFor="let studedent_review of student_scorecard">
                                <div class="list_box p-4 studedent_review">
                                    <h5 class="b fs16 mb-3 Collaboration-Shown">Tutor's Answer</h5>
                                    <p class="Collaboration-Showns">{{studedent_review.t_ans}}</p>
                                </div>
                            </mdb-carousel-item>
                        </mdb-carousel>
                    </div>

                    <div class="col-md-6">
                        <mdb-carousel>
                            <mdb-carousel-item *ngFor="let studedent_review of student_scorecard">
                                <div class="list_box p-4 studedent_review">
                                    <h5 class="b fs16 mb-3 Collaboration-Shown">Student's Answer</h5>
                                    <p class="Collaboration-Showns">{{studedent_review.s_ans}}</p>
                                </div>
                            </mdb-carousel-item>
                        </mdb-carousel>
                    </div>


                    <div class="col-md-12 col-lg-4">
                        <div class="list_box p-4 Student-Performance">
                            <h4 class="b fs16 mb-5">Student Prestanda</h4>
                            <div id="wrapper">
                                <div class="chart">
                                    <div id="figure ">
                                        <div class="graph">
                                            <ul class="x-axis">
                                                <li><span>Mindful-ness</span></li>
                                                <li><span>Engage-ment</span></li>
                                                <li><span>Willing-ness To Learn</span></li>
                                                <li><span>Persave-rance</span></li>
                                            </ul>
                                            <ul class="y-axis">
                                                <li *ngIf="mindfullness;else other_content">
                                                    <span>{{(mindfullness).toFixed()}}%</span>
                                                </li>
                                                <ng-template #other_content>0</ng-template>
                                                <li *ngIf="engagement;else other_content1">
                                                    <span>{{(engagement/scorecard_length).toFixed()}}%</span>
                                                </li>
                                                <ng-template #other_content1>0</ng-template>
                                                <li *ngIf="willingness;else other_content2">
                                                    <span>{{(willingness/scorecard_length).toFixed()}}%</span>
                                                </li>
                                                <ng-template #other_content2>0</ng-template>
                                                <li *ngIf="persaverance;else other_content3">
                                                    <span>{{(persaverance/scorecard_length).toFixed()}}%</span>
                                                </li>
                                                <ng-template #other_content3>0</ng-template>
                                            </ul>
                                            <div class="bars ">
                                                <div class="bar-group">
                                                    <div *ngIf="mindfullness;else other_contentt" class="bar fig0"
                                                        [style.height]="(mindfullness/scorecard_length).toFixed() +'%'">
                                                        <span>{{(mindfullness/scorecard_length).toFixed()}}%</span>
                                                    </div>
                                                </div>
                                                <ng-template #other_contentt class="bar-group">
                                                    <div class="bar fig0" [style.height]="0 +'%'">
                                                        <span>0%</span>
                                                    </div>

                                                </ng-template>

                                                <div class="bar-group">
                                                    <div *ngIf="engagement;else other_contentt1" class="bar fig0"
                                                        [style.height]="(engagement/scorecard_length).toFixed() +'%'">
                                                        <span>{{(engagement/scorecard_length).toFixed()}}%</span>
                                                    </div>
                                                </div>
                                                <ng-template #other_contentt1 class="bar-group">
                                                    <div class="bar fig0" [style.height]="0 +'%'">
                                                        <span>0%</span>
                                                    </div>

                                                </ng-template>
                                                <div class="bar-group">
                                                    <div *ngIf="engagement;else other_contentt2" class="bar fig0"
                                                        [style.height]="(willingness/scorecard_length).toFixed() +'%'">
                                                        <span>{{(willingness/scorecard_length).toFixed()}}%</span>
                                                    </div>
                                                </div>
                                                <ng-template #other_contentt2 class="bar-group">
                                                    <div class="bar fig0" [style.height]="0 +'%'">
                                                        <span>0%</span>
                                                    </div>

                                                </ng-template>
                                                <div class="bar-group">
                                                    <div *ngIf="engagement;else other_contentt3" class="bar fig0"
                                                        [style.height]="(persaverance/scorecard_length).toFixed() +'%'">
                                                        <span>{{(persaverance/scorecard_length).toFixed()}}%</span>
                                                    </div>
                                                </div>
                                                <ng-template #other_contentt3 class="bar-group">
                                                    <div class="bar fig0" [style.height]="0 +'%'">
                                                        <span>0%</span>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-8">
                        <div class="list_box p-4 Student-Technical">
                            <h4 class="b fs16 mb-3">Student Technical Skills Analysis</h4>
                            <div class="row">
                                <div class="col-md-3 ac">
                                    <div class="pie-chart"
                                        attr.data-percentage="{{(l_understanding/scorecard_length).toFixed()} || '0'}">
                                    </div>
                                    <p>Level of Understanding</p>
                                </div>
                                <div class="col-md-3 ac">
                                    <div class="pie-chart"
                                        attr.data-percentage="{{(remory_retention/scorecard_length).toFixed()} || '0'}">
                                    </div>
                                    <p>Memory Retention</p>
                                </div>
                                <div class="col-md-3 ac">
                                    <div class="pie-chart"
                                        attr.data-percentage="{{(analytical_skills/scorecard_length).toFixed()} || '0'}">
                                    </div>
                                    <p>Analytical Skills</p>
                                </div>
                                <div class="col-md-3 ac">
                                    <div class="pie-chart"
                                        attr.data-percentage="{{(problem_solving/scorecard_length).toFixed()} || '0'}">
                                    </div>
                                    <p>Problem Solving</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div *ngIf="student_scorecard.length>0" class="col-md-3">
                <div class="list_box p-4 clar"
                    *ngFor="let studedent_review of student_scorecard| paginate:{itemsPerPage:bodyDataRequest['limit'],currentPage:p,totalItems:total_count};let i= index">
                    <p class="bdrb p-3">{{studedent_review.classes}} Class <b style="float:right"> Tutor:-
                            {{studedent_review.name}} {{studedent_review.last_name}}</b> </p>
                </div>
            </div>


            <div *ngIf="student_scorecard.length==0" class="col-md-3 p-0">
                <div class="list_box p-4 clar">
                    <p class="bdrb p-3">Class #1 <i class="fas fa-arrow-up"></i></p>
                    <p class="bdrb p-3">Class #2 <i class="fas fa-arrow-down"></i></p>
                    <p class="bdrb p-3">Class #3 <i class="fas fa-arrow-down"></i></p>
                    <p class="bdrb p-3">Class #4 <i class="fas fa-arrow-down"></i></p>

                </div>
            </div>


        </div>
        <div class="row mt-5">
            <div class="col-6 showrecord">
                <label>
                    Show
                    <select class="filter-select mr-2" [(ngModel)]="bodyDataRequest['limit']"
                        (change)="onChangeLimit($event.target)">
                        <option selected disabled>select</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                    Records
                </label>
            </div>
            <div class="col-6 mt-3 text-right">
                <pagination-controls previousLabel="Previous" nextLabel="Next"
                    (pageChange)="onPageChange($event)"></pagination-controls>
            </div>
        </div>

    </div>
</section>