<div class="mb-3 contT">
   <div class="container pl-3">
      <div class="row">
         <div class="col-md-12">
            <ul class="breadcrumb">
               <li><a href="/">Home</a></li>
               <li class="active">Blog Details</li>
            </ul>
            <div class="Blog-Details-pages pl-3 pr-3">
               <h1><span class="txt_gray mob_hide">Welcome To </span>Blog Details</h1>
            </div>
         </div>
      </div>
   </div>
</div>

<section class="animated3 wow drop_eff5 animated Blog-Details" style="visibility: visible;">
   <div class="container">
      <div class="row custm-Blog-Details">
         <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="news_box text-center rel custom-style">
               <a href="#">
                  <img [src]="allowurl(apiService.imageBase+blogDetailsList.image)" alt="" class="img-fluid"
                     *ngIf="blogDetailsList.image !=''">
               </a>
            </div>
         </div>
         <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="news_box text-center rel custom-style">
               <p style="position:absolute;right: 0px;" title="Go Back"><a (click)="back();" title="Go Back"><i
                        class="fas fa-arrow-circle-left"></i></a></p>
               <h4>{{blogDetailsList.title}}</h4>
               <p class="fs12"><strong>Posted by:</strong> {{blogDetailsList.name}} <span class="ml-3 mr-3">|</span>
                  <strong>Posted Date:</strong> {{blogDetailsList.updated_at | date:'longDate'}}
               </p>
               <p class="mt-2">{{blogDetailsList.body}}</p>
            </div>
         </div>
      </div>
   </div>
</section>