// gaurav
import { Injectable } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    constructor(private toastrService: ToastService) {}

    showSuccess(message: string) {this.toastrService.success(message)};
    showError(message: string) {this.toastrService.error(message)};
    showInfo(message: string) {this.toastrService.info(message)};
    showWarning(message: string) {this.toastrService.warning(message)};

}