<div class="mb-3 contT">
   <div class="container pl-3">
      <ul class="breadcrumb">
         <li><a href="index.htm">Home</a></li>
         <!--<li><a href="listing.htm">Chair</a></li>-->
         <li class="active">Group Classes</li>
      </ul>
   </div>
   <div class="container pl-3 pr-3">
      <h1><span class="txt_gray mob_hide">Group </span>Group Classes</h1>
   </div>
</div>

<div class="mb-3 contT">
   <div class="container pl-3">
      <ul class="breadcrumb">
         <li><a href="index.htm">Home</a></li>
         <!--<li><a href="listing.htm">Chair</a></li>-->
         <li class="active">Hitta en Studiementor</li>
      </ul>
   </div>
   <div class="container pl-3 pr-3">
      <div class="row">
         <div class="col-md-7">
            <div class="content_cent">
               <h1 class="b">5-day courses to boost grades and confidence</h1>
               <p>Groups of up to 6 students<br>
                  5-day courses from SEK 40<br>
                  Curriculum-focused courses from experienced tutors
               </p>
               <p class="mt-3 b fs18">Book now for the Easter holidays</p>
               <p class="add_cart mt-3 w50 auto"><a href="find-a-course.htm">View All Courses</a></p>
            </div>
         </div>
         <div class="col-md-5 pb-4">
            <div id="owl-info" class="owl-carousel owl-theme mt-3 mb-3 owl-loaded owl-drag">
               <div class="owl-stage-outer">
                  <div class="owl-stage" style="transform: translate3d(-1038px, 0px, 0px); transition: all 0.25s ease 0s; width: 1557px;">
                     <div class="owl-item" style="width: 519px;">
                        <div class="item"><img src="assets/images/be.jpg" class="img-fluid" alt=""></div>
                     </div>
                     <div class="owl-item" style="width: 519px;">
                        <div class="item"><img src="assets/images/be1.jpg" class="img-fluid" alt=""></div>
                     </div>
                     <div class="owl-item active" style="width: 519px;">
                        <div class="item"><img src="assets/images/be2.jpg" class="img-fluid" alt=""></div>
                     </div>
                  </div>
               </div>
               <div class="owl-nav disabled"><button type="button" role="presentation" class="owl-prev"></button><button type="button" role="presentation" class="owl-next"></button></div>
               <div class="owl-dots disabled"></div>
            </div>
         </div>
      </div>
      <div class="mt-5">
         <div class="emp_area">
            <div class="row justify-content-center">
               <div class="col-md-12 ac fs22 b">Find a course</div>
               <div class="col-md-3">
                  <select name="" id="">
                     <option value="">Subject</option>
                  </select>
               </div>
               <div class="col-md-3">
                  <select name="" id="">
                     <option value="">Level</option>
                  </select>
               </div>
               <div class="col-md-3 add_cart"><a href="#">Search</a></div>
            </div>
         </div>
      </div>
   </div>
</div>

<div class="mb-3 contT">
      <div class="container pl-3">
            <ul class="breadcrumb">
                <li><a href="index.htm">Home</a></li>
                <!--<li><a href="listing.htm">Chair</a></li>-->
                <li class="active">Hitta en Studiementor</li>
            </ul>
        </div>
        <div class="container pl-3 pr-3">
         <div class="row">
            <div class="col-md-7">
               <div class="content_cent">
                  <h1 class="b">5-day courses to boost grades and confidence</h1>
                  <p>Groups of up to 6 students<br>
5-day courses from SEK 40<br>
Curriculum-focused courses from experienced tutors</p>
                  <p class="mt-3 b fs18">Book now for the Easter holidays</p>
                  <p class="add_cart mt-3 w50 auto"><a href="find-a-course.htm">View All Courses</a></p>
               </div>
            </div>
            <div class="col-md-5 pb-4">
               <div id="owl-info" class="owl-carousel owl-theme mt-3 mb-3 owl-loaded owl-drag">
                  
                  
                  
               <div class="owl-stage-outer"><div class="owl-stage" style="transform: translate3d(-1038px, 0px, 0px); transition: all 0.25s ease 0s; width: 1557px;"><div class="owl-item" style="width: 519px;"><div class="item"><img src="assets/images/be.jpg" class="img-fluid" alt=""></div></div><div class="owl-item" style="width: 519px;"><div class="item"><img src="assets/images/be1.jpg" class="img-fluid" alt=""></div></div><div class="owl-item active" style="width: 519px;"><div class="item"><img src="assets/images/be2.jpg" class="img-fluid" alt=""></div></div></div></div><div class="owl-nav disabled"><button type="button" role="presentation" class="owl-prev"></button><button type="button" role="presentation" class="owl-next"></button></div><div class="owl-dots disabled"></div></div>
            </div>
         </div>
         <div class="mt-5">
            <div class="emp_area">
               <div class="row justify-content-center">
                  <div class="col-md-12 ac fs22 b">Find a course</div>
                  <div class="col-md-3"><select name="" id=""><option value="">Subject</option></select></div>
                  <div class="col-md-3"><select name="" id=""><option value="">Level</option></select></div>
                  <div class="col-md-3 add_cart"><a href="#">Search</a></div>
               </div>
            </div>
         </div>
        </div>
    </div>

<section class="ac mb-3"><img src="assets/images/fat-b.jpg" class="img-fluid radius15" alt=""></section>
<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
   <div class="container">
      <div class="row">
         <div class="col-md-12 ac fs22 b mt-5">Upcoming course</div>
         <div class="col-md-12">
            <div class="row justify-content-center">
               <div class="col-lg-3 col-sm-6 wow fadeInLeft animated animated" style="visibility: visible;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a href="course-details.htm" title=""><img src="assets/images/t5.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></p>
                        
                        <p class="b float-left"><a href="course-details.htm" title="">Computer Progr...</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Max C.</p>
                     </div>
                  </div>
               </div>

               <div class="col-lg-3 col-sm-6 wow fadeInLeft animated animated" style="visibility: visible;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a href="course-details.htm" title=""><img src="assets/images/t5.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></p>
                        <p class="b float-left"><a href="course-details.htm" title="">Computer Progr...</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Max C.</p>
                     </div>
                  </div>
               </div>

               <div class="col-lg-3 col-sm-6 wow fadeInLeft animated animated" style="visibility: visible;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a href="course-details.htm" title=""><img src="assets/images/t5.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></p>
                        <p class="b float-left"><a href="course-details.htm" title="">Computer Progr...</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Max C.</p>
                     </div>
                  </div>
               </div>

               <div class="col-lg-3 col-sm-6 wow fadeInLeft animated" style="visibility: visible;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a href="course-details.htm" title=""><img src="assets/images/t5.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></p>
                        <p class="b float-left"><a href="course-details.htm" title="">Computer Progr...</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Max C.</p>
                     </div>
                  </div>
               </div>

               <div class="col-lg-3 col-sm-6 wow fadeInLeft animated" style="visibility: visible;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a href="course-details.htm" title=""><img src="assets/images/t5.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></p>
                        <p class="b float-left"><a href="course-details.htm" title="">Computer Progr...</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Max C.</p>
                     </div>
                  </div>
               </div>

               <div class="col-lg-3 col-sm-6 wow fadeInLeft animated" style="visibility: visible;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a href="course-details.htm" title=""><img src="assets/images/t5.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></p>
                        <p class="b float-left"><a href="course-details.htm" title="">Computer Progr...</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Max C.</p>
                     </div>
                  </div>
               </div>

               <div class="col-lg-3 col-sm-6 wow fadeInLeft animated" style="visibility: visible;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a href="course-details.htm" title=""><img src="assets/images/t5.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></p>
                        <p class="b float-left"><a href="course-details.htm" title="">Computer Progr...</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Max C.</p>
                     </div>
                  </div>
               </div>

               <div class="col-lg-3 col-sm-6 wow fadeInLeft animated" style="visibility: visible;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a href="course-details.htm" title=""><img src="assets/images/t5.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></p>
                        <p class="b float-left"><a href="course-details.htm" title="">Computer Progr...</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Max C.</p>
                     </div>
                  </div>
               </div>
               <div class="col-md-12 ac"><p><a href="find-a-course.htm" class="morebtn">view all courses</a></p></div>
              </div>
         </div>
      </div>
   </div>
</section>

<section class="animated3 wow drop_eff5 mt-5 animated" style="visibility: visible;">
      <div class="container">
         <div class="row">
            <div class="col-md-4">
               <div class="blog_box">
                  <img src="assets/images/b2.jpg" class="img-fluid" alt="">
                  <div class="blog_box_cont">
                     <p class="mt-3 b fs18">Up to 5 students</p>
                     <p class="mt-3">Teens learn with other students in groups of up to 6. So they can ask the tutor any questions they like and get the help they need. They're in it together.</p>
                  </div>
               </div>
            </div>
            <div class="col-md-4">
               <div class="blog_box">
                  <img src="assets/images/b1.jpg" class="img-fluid" alt="">
                  <div class="blog_box_cont">
                     <p class="mt-3 b fs18">5-day courses from SEK 40</p>
                     <p class="mt-3">Courses cost from SEK 40 for a 5-day course, with an hour's tutorial each day.</p>
                  </div>
               </div>
            </div>
            <div class="col-md-4">
               <div class="blog_box">
                  <img src="assets/images/b3.jpg" class="img-fluid" alt="">
                  <div class="blog_box_cont">
                     <p class="mt-3 b fs18">Curriculum-focused learning</p>
                     <p class="mt-3">Courses give students focused, expert support from a relatable tutor. They can get their grades up and start the new term with confidence.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>

   <section class="animated3 wow drop_eff5 mt-5 animated" style="visibility: visible;">
      <div class="container">
         <h1 class="ac mb-3">Why Groups?</h1>
         <div class="row justify-content-center mb-5">
            <div class="col-md-3 tr">
               <img src="assets/images/h1.jpg" class="img-fluid" alt="">
            </div>
            <div class="col-md-5">
               <p class="mt-3 b fs18">Interactive 5-day courses</p>
               <p class="mt-3">Tutors use quizzes and games to bring the curriculum to life. Students share with each other and have fun while boosting their knowledge.</p>
            </div>
         </div>
         <div class="row justify-content-center mb-5">
            <div class="col-md-3 tr">
               <img src="assets/images/h2.jpg" class="img-fluid" alt="">
            </div>
            <div class="col-md-5">
               <p class="mt-3 b fs18">Relatable expert tutors</p>
               <p class="mt-3">Tutors use quizzes and games to bring the curriculum to life. Students share with each other and have fun while boosting their knowledge.</p>
            </div>
         </div>
         <div class="row justify-content-center mb-5">
            <div class="col-md-3 tr">
               <img src="assets/images/h3.jpg" class="img-fluid" alt="">
            </div>
            <div class="col-md-5">
               <p class="mt-3 b fs18">Boost your child's learning without breaking the bank</p>
               <p class="mt-3">Tutors use quizzes and games to bring the curriculum to life. Students share with each other and have fun while boosting their knowledge.</p>
            </div>
         </div>
      </div>
   </section>

   <div class="mt-5 mb-5">
      <div class="container">
         <h1 class="text-center mb-3 animated2 drop_eff2">Vanliga frågor</h1>
         <p class="text-center fs16 mb-4">Handpicked and interviewed tutors, who are the best subject experts</p>
         <div class="row justify-content-center">           
            <div class="col-md-8">
               <section class="faq-section">
                  <div class="faq" id="accordion">
                     <div class="card">
                        <div class="card-header" id="faqHeading-1">
                           <div class="mb-0">
                              <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-1" data-aria-expanded="true" data-aria-controls="faqCollapse-1">
                                 How do you select your tutors?  <img src="assets/images/daro.png" alt="" class="daro1">
                              </h5>
                           </div>
                        </div>
                        <div id="faqCollapse-1" class="collapse" aria-labelledby="faqHeading-1" data-parent="#accordion">
                           <div class="card-body">
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                           </div>
                        </div>
                     </div>
                     <div class="card">
                        <div class="card-header" id="faqHeading-2">
                           <div class="mb-0">
                              <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-2" data-aria-expanded="false" data-aria-controls="faqCollapse-2">
                                 Are the lessons one to one or in groups? <img src="assets/images/daro.png" alt="" class="daro1">
                              </h5>
                           </div>
                        </div>
                        <div id="faqCollapse-2" class="collapse" aria-labelledby="faqHeading-2" data-parent="#accordion">
                           <div class="card-body">
                              <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                           </div>
                        </div>
                     </div>
                     <div class="card">
                        <div class="card-header" id="faqHeading-3">
                           <div class="mb-0">
                              <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-3" data-aria-expanded="false" data-aria-controls="faqCollapse-3">
                                 Where do the lessons take place?  <img src="assets/images/daro.png" alt="" class="daro1">
                              </h5>
                           </div>
                        </div>
                        <div id="faqCollapse-3" class="collapse" aria-labelledby="faqHeading-4" data-parent="#accordion">
                           <div class="card-body">
                              <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here.</p>
                           </div>
                        </div>
                     </div>
                     <div class="card">
                        <div class="card-header" id="faqHeading-4">
                           <div class="mb-0">
                              <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-4" data-aria-expanded="false" data-aria-controls="faqCollapse-4">
                                 Are the lessons one to one or in groups? <img src="assets/images/daro.png" alt="" class="daro1">
                              </h5>
                           </div>
                        </div>
                        <div id="faqCollapse-4" class="collapse" aria-labelledby="faqHeading-2" data-parent="#accordion">
                           <div class="card-body">
                              <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                           </div>
                        </div>
                     </div>
                     <div class="card">
                        <div class="card-header" id="faqHeading-5">
                           <div class="mb-0">
                              <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-5" data-aria-expanded="false" data-aria-controls="faqCollapse-5">
                                 Where do the lessons take place?  <img src="assets/images/daro.png" alt="" class="daro1">
                              </h5>
                           </div>
                        </div>
                        <div id="faqCollapse-5" class="collapse" aria-labelledby="faqHeading-5" data-parent="#accordion">
                           <div class="card-body">
                              <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here.</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
            <div class="col-md-12 ac"><p><a href="faq.htm" class="morebtn">view all faq<span class="sm">s</span></a></p></div>
         </div>
      </div>
   </div>
