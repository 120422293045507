<div class="ac"><img src="assets/images/banner-in.jpg" class="img-fluid" alt=""></div>
<!--head+filter-->
<div class="mb-3 mt-3">
    <div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="/">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Our Team</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide">Our  </span>Team</h1>            
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->

<!-- Content -->
<section class="animated3 wow drop_eff5">
    <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 wow fadeInLeft">
              <div class="row justify-content-center">
                <div class="col-md-4" *ngFor="let team of Teamist; let i= index">
                  <div class="teambox">
                    <img [src]="allowurl(apiService.imageBase+team?.brand_image)" alt="" />
                    <div class="tcbox">
                      <p class="black b">{{team.brand_name}}</p>
                      <p class="gray1 fs12">{{team.brand_description}}</p>
                      <div class="ft-social ac">
                        <a href="{{team.fb_link}}" title="Facebook" target="_blank"
                          ><i class="fab fa-facebook-f"></i
                        ></a>
                        <a href="{{team.insta_link}}" title="Instagram" target="_blank"
                          ><i class="fab fa-instagram"></i
                        ></a>
                        <a href="{{team.ln_link}}" title="Linkedin" target="_blank"
                          ><i class="fab fa-linkedin-in"></i
                        ></a>
                        <a href="{{team.youtube_link}}" title="Youtube" target="_blank"
                          ><i class="fab fa-youtube"></i
                        ></a>
                      </div>
                    </div>
                    <p class="clearfix"></p>
                  </div>
                </div>
              </div>
            
            </div>
        </div>
    </div>
</section>