<div class="mb-3 ptfix">
    <div class="container pt-4 pl-3">
        <ul class="breadcrumb">
            <li><a href="/">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Feedback</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide">Feedback </span></h1>
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->

<!-- Content Stars -->
<section class="animated3 wow drop_eff5">
    <div class="container mb-5 minh_600">
        <form [formGroup]="addForm" (ngSubmit)="onaddForm()">
            <div class="row justify-content-center">
                <div class="col-lg-6 wow fadeInLeft newsletter">
                    <div class="mb-3">
                        <p>Your Name</p>
                       
                        <input type="text" mdbInput class="form-control" formControlName="posted_by"
                        placeholder="Enter name"
                        [class.is-invalid]="addForm.get('posted_by').invalid && addForm.get('posted_by').touched">
                        <div *ngIf="addForm.get('posted_by').invalid && addForm.get('posted_by').touched">
                            <small *ngIf="addForm.get('posted_by').errors?.required" class="text-danger">
                                name is required</small>

                        </div>
                    </div>
                    <div class="mb-3">
                        <p>Your Email</p>
                        <input type="text" mdbInput class="form-control" formControlName="email"
                        placeholder="Enter email"
                        [class.is-invalid]="addForm.get('email').invalid && addForm.get('email').touched">
                        <div *ngIf="addForm.get('email').invalid && addForm.get('email').touched">
                            <small *ngIf="addForm.get('email').errors?.required" class="text-danger">
                                email is required</small>

                        </div>
                    </div>
                    
                    <div class="mb-3">
                        <p>Your Comment</p>
                        <textarea name="description" id="description" cols="45" rows="4"
                                placeholder="Comment/Message *" class="form-control" formControlName="description"></textarea>
                    </div>
                    
                    <div class="mb-3">
                        <button class="nbtn1 text-center ttu mt-5 w100" mdbBtn type="submit" gradient="purple" size="sm">Submit</button>
                        <!-- <p class="nbtn1 text-center ttu mt-5"><a href="#" class="w100">Submit</a></p> -->
                    </div>
                </div>
            </div>
        </form>
       
    </div>
</section>