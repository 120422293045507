import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
  homeTestimonial: any=[];
  constructor(public apiService :ApiService,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
  }

  ngOnInit(): void {
    this.getTestimonials();
  }
  getTestimonials(){
    var sendrequestinput:any={featured:"0",limit:"20"};
    this.apiService.post('web_testimonials_list',sendrequestinput).subscribe((data:any) => this.homeTestimonial=data.data)
  }
}
