<div class="modal fade show pp" id="c-l" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered logmod" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">Add Children Details</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePopup()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="createuser1">
                    <div class="emp_area">
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="">First Name</label>
                                <input type="text" class="form-control" formControlName="name"
                                    placeholder="Please enter your child’s first name"
                                    [ngClass]="{ 'is-invalid': submitted1 && f1.name.errors }">


                                <div *ngIf="f1.name.invalid && (f1.name.dirty || f1.name.touched)">

                                    <div class="warning" *ngIf="f1.name.errors.required">
                                        First Name is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="">Last Name</label>
                                <input type="text" class="form-control" formControlName="last_name"
                                    placeholder="Please enter your child’s last name"
                                    [ngClass]="{ 'is-invalid': submitted1 && f1.last_name.errors }">
                                <div *ngIf="f1.last_name.invalid && (f1.last_name.dirty || f1.last_name.touched)">

                                    <div class="warning" *ngIf="f1.last_name.errors.required">
                                        Last Name is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="">Mobile</label>
                                <input placeholder="Please enter your child’s mobile number"
                                    (keyup)="onSearchKey3($event.target.value,'phone')" formControlName="phone"
                                    class="form-control" type="tel"
                                    [ngClass]="{ 'is-invalid': submitted1 && f1.phone.errors }">
                                <div *ngIf="f1.phone.invalid && (f1.phone.dirty || f1.phone.touched)">

                                    <div class="warning" *ngIf="f1.phone.errors.required">
                                        Mobile number is required. </div>
                                </div>
                                <span class="warning">{{ phone }} </span>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="">Email</label>
                                <input type="email" class="form-control" placeholder="Please enter your child’s email"
                                    formControlName="email" [ngClass]="{ 'is-invalid': submitted1 && f1.email.errors }">
                                <div *ngIf="f1.email.invalid && (f1.email.dirty || f1.email.touched)">

                                    <div class="warning" *ngIf="f1.email.errors.required">
                                        Email is required.
                                    </div>
                                    <div *ngIf="f1.email.errors.email" class="warning">Email
                                        must be a valid email address</div>
                                </div>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="">Child’s DOB</label>
                                <input type="date" formControlName="dob" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted1 && f1.dob.errors }">
                                <div *ngIf="f1.dob.invalid && (f1.dob.dirty || f1.dob.touched)">

                                    <div class="warning" *ngIf="f1.dob.errors.required">
                                        Child Age is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-6 rel">
                                <label for="">Password</label>
                                <a class="abico top-13" (click)="onChangeType1()" *ngIf="!type"><i
                                        class="fas fa-eye-slash"></i></a>
                                <a href="javascript:void(0);" class="abico" (click)="onChangeType2()" *ngIf="type"><i
                                        class="fas fa-eye"></i></a>
                                <input [type]="type? 'text':'password'" minlength="8" class="form-control" placeholder="Please enter a password for your child’s account
                                            " formControlName="password"
                                    [ngClass]="{ 'is-invalid': submitted1 && f1.password.errors }">
                                <div *ngIf="f1.password.invalid && (f1.password.dirty || f1.password.touched)">
                                    <div class="warning" *ngIf="f1.password.errors.required">
                                        Password is required.
                                    </div>
                                </div>
                                <div class="warning" *ngIf="f1.password.errors?.minlength">
                                    Password must be at least 8 characters long.
                                </div>
                                <p class="fs12 gray1 text-right mt-2">8 characters minimum
                                </p><br>
                            </div>

                            <div class="form-group col-md-6 rel">
                                <label for="">Confirm Password</label>
                                <a class="abico top-13" (click)="onChangecnType1()" *ngIf="!cptype"><i
                                        class="fas fa-eye-slash"></i></a>
                                <a href="javascript:void(0);" class="abico" (click)="onChangecnType2()"
                                    *ngIf="cptype"><i class="fas fa-eye"></i></a>
                                <input [type]="cptype? 'text':'password'" placeholder="Please confirm the password entered above
                                        " minlength="8" class="form-control" formControlName="reenterpassword"
                                    [ngClass]="{ 'is-invalid': submitted1 && f1.reenterpassword.errors }">
                                <div
                                    *ngIf="f1.reenterpassword.invalid && (f1.reenterpassword.dirty || f1.reenterpassword.touched)">

                                    <div class="warning" *ngIf="f1.reenterpassword.errors.required">
                                        Confirmed password is required.
                                    </div>
                                </div>
                                <div *ngIf="(f1.reenterpassword.errors && f1.reenterpassword.touched) || (f1.reenterpassword.errors && submitted1)"
                                    class="errorMsg">
                                    <div *ngIf="f1.reenterpassword.errors.mustMatch">Password and Confirm Password’
                                        field do not match.</div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="modal-footer">
                                    <button type="button" class="style1" data-dismiss="modal"
                                        (click)="onClosePopup()">Cancel</button>
                                    <button type="button" class="style1 bg" (click)="onSubmit()">Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </div>

        </div>
    </div>
</div>