<div class="modal fade show pp" id="c-l" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered logmod" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">Change Location</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 pr-0">
                        <!-- start -->
                        <div class="log_box pt-0">
                            <div class="login_form">
                                <p class="mt10">
                                    <label>Country</label>
                                    <input type="text" name="contry" id="country">
                                </p>
                                <div class="mt-4">
                                    <label class="float-left">Time Zone</label>
                                    <input type="text" name="tz" id="tz">
                                </div>
                            </div>
                        </div>
                        <!-- end -->
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary mt-4" data-dismiss="modal">Cancel</button>
                <button type="button" class="submit">Submit</button>
            </div>
        </div>
    </div>
</div>