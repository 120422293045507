<div class="mb-3 contT">
    <div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="javascript:void(0);" routerLink="/">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Student Signup</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide">Student </span>Signup</h1>
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->

<!-- Content -->
<section class="animated3 wow drop_eff5">
    <div class="container mb-5">
        <div class="row justify-content-center">
            <div class="col-lg-6 wow fadeInLeft">
                <!-- start -->
                <div class="login_form">
                    <div class="student">
                        <form [formGroup]="AddForm">
                            <div class="emp_area">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="name_feild">
                                            <p>First Name <span class="red">*</span></p>
                                            <p><input type="text" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" class="form-control"></p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="name_feild">
                                            <p>Last Name <span class="red">*</span></p>
                                            <p><input type="text" class="form-control" formControlName="last_name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="name_feild">
                                            <p>age<span class="red">*</span></p>
                                            <p><input type="text" class="form-control" formControlName="age" [ngClass]="{ 'is-invalid': submitted && f.age.errors }">
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="loc_feild">
                                            <p>Select Country <span class="red">*</span></p>
                                            <p><select style="height:auto !important;" name="" class="form-control" id="" formControlName="country" [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                                                    <option [value]="lvl.id"
                                                        *ngFor="let lvl of CountryList">{{lvl.name}}
                                                    </option>
                                                </select></p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="loc_feild">
                                            <p>country Code<span class="">*</span></p>
                                            <p><select  name="" class="form-control" id="" formControlName="phone_code" [ngClass]="{ 'is-invalid': submitted && f.phone_code.errors }">
                                                <option [value]="lvl.phonecode"
                                                    *ngFor="let lvl of CountryList">+ {{lvl.phonecode}}
                                                </option>
                                            </select>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="loc_feild">
                                            <p>Mobile Number <span class="red">*</span></p>
                                            <p><input type="text" class="form-control" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                            </p>
                                        </div>
                                    </div>
                                 
                                    <div class="col-md-12">
                                        <div class="loc_feild">
                                            <p>Email ID <span class="red">*</span></p>
                                            <p><input type="text" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="loc_feild">
                                            <p>Password <span class="red">*</span></p>
                                            <p class="rel">
                                                <a class="abico" (click)="onChangeType1()" *ngIf="!type"><i
                                                        class="fas fa-eye-slash"></i></a>
                                                <a href="javascript:void(0);" class="abico" (click)="onChangeType2()" *ngIf="type"><i
                                                        class="fas fa-eye"></i></a>
                                                <input [type]="type? 'text':'password'" class="form-control" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                            </p>
                                            <p class="fs12 gray1 text-right mt-2">8 characters minimum<br>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="loc_feild">
                                            <p>Confirm Password <span class="red">*</span></p>
                                            <p class="rel">
                                                <a class="abico" (click)="onChangecnType1()" *ngIf="!cptype"><i
                                                    class="fas fa-eye-slash"></i></a>
                                                <a href="javascript:void(0);" class="abico" (click)="onChangecnType2()" *ngIf="cptype"><i
                                                    class="fas fa-eye"></i></a>
                                                <input [type]="cptype? 'text':'password'" class="form-control" formControlName="reenterpassword" [ngClass]="{ 'is-invalid': submitted && f.reenterpassword.errors }">
                                            </p>
                                        </div>
                                        <div *ngIf="(f.reenterpassword.errors && f.reenterpassword.touched) || (f.reenterpassword.errors && submitted)" class="errorMsg">
                                            <div *ngIf="f.reenterpassword.errors.mustMatch">The password and Re-Enter password do not match.</div>
                                        </div>
                                    </div>

                                    <ng-container *ngIf="tutorFlag">
                                        <div class="col-md-6">
                                            <div class="loc_feild">
                                                <p>Select Level <span class="red">*</span></p>
                                                <p><select name="" id="" class="form-control" formControlName="age_group_id" [ngClass]="{ 'is-invalid': submitted && f.age_group_id.errors }">
                                                        <option [value]="lvl.id"
                                                            *ngFor="let lvl of LevelList">{{lvl.title}}
                                                        </option>
                                                    </select></p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="loc_feild">
                                                <p>Select Method <span class="red">*</span></p>
                                                <p><select name="" class="form-control" id="" formControlName="job_type" [ngClass]="{ 'is-invalid': submitted && f.job_type.errors }">
                                                        <option value="1">Online</option>
                                                        <option value="2">På plats</option>
                                                    </select></p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="loc_feild">
                                                <p>Hourly Rate (in $) <span class="red">*</span></p>
                                                <p><input type="text" class="form-control" formControlName="rate" [ngClass]="{ 'is-invalid': submitted && f.rate.errors }">
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="loc_feild">
                                                <p>Work Experience <span class="red">*</span></p>
                                                <p><input type="text" class="form-control" formControlName="work_experience" [ngClass]="{ 'is-invalid': submitted && f.work_experience.errors }">
                                                </p>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="col-md-6">
                                        <div class="loc_feild">
                                            <p>Select Gender <span class="red">*</span></p>
                                            <p><select name="" class="form-control" id="" formControlName="gender" [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                                                    <option value="1">Male</option>
                                                    <option value="2">Female</option>
                                                    <option value="3">Other</option>
                                                </select></p>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="loc_feild font-weight-normal">
                                            <input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2" formControlName="termscond" [ngClass]="{ 'is-invalid': submitted && f.termscond.errors }"> 
                                            I have read and agreed with ST’s <a href="javascript:void(0);"
                                                class="red">Terms &amp; Condition</a> and <a href="javascript:void(0);" class="red">Privacy Policy</a>.
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="loc_feild font-weight-normal">
                                            <input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2" formControlName="news_letter" [ngClass]="{ 'is-invalid': submitted && f.news_letter.errors }"> Happy to subscribe to ST’s Newsletter.
                                        </div>
                                    </div>
                                    <ng-container *ngIf="tutorFlag">
                                        <div class="col-md-12">
                                            <div class="loc_feild font-weight-normal">
                                                <input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2"> I am an online course creator. <a href="javascript:void(0);" class="text-info tip" data-tip="tip-first" title="An individual who creates and uploads courses on the ST websites, to help students learn.">?</a>

                                            </div>
                                        </div>
                                    </ng-container>


                                    <!-- 
                                    <div class="col-md-12">
                                        <div class="loc_feild font-weight-normal">
                                            <input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2" formControlName="termscond" [ngClass]="{ 'is-invalid': submitted && f.termscond.errors }"> 
                                             to the <a href="website-terms.htm"
                                                class="dib">Terms &amp;
                                                Condition</a>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-12">
                                        <div class="loc_feild font-weight-normal">
                                            <input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2" formControlName="news_letter" [ngClass]="{ 'is-invalid': submitted && f.news_letter.errors }"> Subscribe ST's Newsletter
                                        </div>
                                    </div> -->
                                    <div class="col-md-12">
                                        <div class="loc_feild">
                                            <p class="add_cart"><a href="javascript:void(0);" (click)="onSubmit()"><i class="fas fa-laptop"></i>
                                                    Register Now!</a></p>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="loc_feild font-weight-normal ac">
                                            Already a member? <a href="login.htm">Logga in!</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
                <!-- end -->
            </div>
        </div>
    </div>
</section>
<!-- Content Ends -->