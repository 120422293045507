<div class="top=main-section contT">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <ul class="breadcrumb">
                    <li><a href="/">Home</a></li>
                    <li class="active">Mitt Konto</li>
                </ul>
            </div>
            <div class="col-md-12">
                <h1><span class="txt_gray mob_hide"></span>{{Content_Type? Content_Type : ''}}</h1>
            </div>
        </div>
    </div>
</div>
<div class="clearfix"></div>
<section class="animated3 wow drop_eff5 mb-5" ng-init="initcheck()" *ngIf="userData">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-4 col-sm-12 wow fadeInLeft ">
                <div class="lft_link1">
                    <ul class="nav nav-pills mb-3 d-block" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="pills-tab1-tab" data-toggle="pill" href="#pills-tab1"
                                role="tab" aria-controls="pills-tab1" aria-selected="true"
                                (click)="onChangeTypeContent('Redigera och Uppdatera Profil');"><i class="fa fa-pencil"></i>Min Profil</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="pills-tab2-tab" data-toggle="pill" href="#pills-tab2" role="tab"
                                aria-controls="pills-tab2" aria-selected="false"
                                (click)="onChangeTypeContent('Uppgifter om ditt barn');"><i class="fas fa-child"></i>Uppgifter om ditt barn
                            </a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="pills-tab6-tab" data-toggle="pill" href="#pills-tab6" role="tab"
                                (click)="onChangeTypeContent('Klassrumsdemo');" aria-controls="pills-tab6"
                                aria-selected="false"><i class="fa fa-solid fa-graduation-cap"></i>Klassrumsdemo</a>
                        </li>

                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="pills-tab44-tab" data-toggle="pill" href="#pills-tab44" role="tab"
                                aria-controls="pills-tab44" aria-selected="false"
                                (click)="onChangeTypeContent('Transaktionsdetaljer');"><i
                                    class="fas fa-edit"></i>Transaktionsdetaljer</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="pills-tab222-tab" data-toggle="pill" href="#pills-tab222" role="tab"
                                aria-controls="pills-tab222" aria-selected="false"
                                (click)="onChangeTypeContent('Mina Klasser');"><i class="fas fa-money-bill-alt"></i>Mina Klasser</a>
                        </li>

                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="pills-tab4-tab" href="javascript:void();" routerLink="/contact-us"><img
                                    _ngcontent-nwn-c175="" alt=""
                                    onerror="this.onerror=null;this.src='assets/images/profile-demo.png';"
                                    style="width: 18px; opacity: 0.5"
                                    src="https://suited-admin.mrmmbs.com/node/uploads/attachment-1674819240238.png" />
                                Support</a>
                        </li>
                        <!-- <li class="nav-item custam-code-st1" role="presentation">
                            <a class="nav-link" showFooter='false'>
                                <img _ngcontent-nwn-c175="" alt=""
                                    onerror="this.onerror=null;this.src='assets/images/profile-demo.png';"
                                    style="width: 18px; opacity: 0.5" src="../../../../assets/images/comment.png" />
                                Give Feedback</a>

                        </li> -->

                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="pills-tab12-tab" data-toggle="pill" href="#pills-tab12" role="tab"
                                aria-controls="pills-tab12" aria-selected="false" (click)="onOpenLogout()"><i
                                    class="fas fa-sign-out-alt"></i>Logga ut</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-9 col-md-8 col-sm-12 wow fadeInLeft">
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-tab1" role="tabpanel"
                        aria-labelledby="pills-tab1-tab">
                        <div class="list_box1">

                            <div class="tab_link">
                                <p class="for_m dd_next hand">Edit Profile <i class="fas fa-caret-down"></i></p>
                                <div class="for_d ">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link active" id="pills-home-tab" data-toggle="pill"
                                                href="#pills-home" role="tab" aria-controls="pills-home"
                                                aria-selected="true">Personuppgifter</a>
                                        </li>
                                        <!-- <li class="nav-item" role="presentation">
                                            <a class="nav-link" id="pills-profile-tab" data-toggle="pill"
                                                href="#pills-profile" role="tab" aria-controls="pills-profile"
                                                aria-selected="false">Tidszon och Land</a>
                                        </li> -->
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link" id="pills-contact-tab" data-toggle="pill"
                                                href="#pills-contact" role="tab" aria-controls="pills-contact"
                                                aria-selected="false">Fakturaadress</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link" id="pills-password-tab" data-toggle="pill"
                                                href="#pills-password" role="tab" aria-controls="pills-password"
                                                aria-selected="false">Lösenordsdetaljer</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link" id="pills-contact-tab" data-toggle="pill"
                                                href="#pills-photo" role="tab" aria-controls="pills-contact"
                                                aria-selected="false">Profilfoto</a>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div class="tab_cont tab-content" id="pills-tabContent">

                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                    aria-labelledby="pills-home-tab">
                                    <form [formGroup]="createuser6">
                                        <div class="emp_area">
                                            <div class="row">
                                                <div class="form-group col-md-6">
                                                    <label for="" class="form-label">Förnamn *</label>
                                                    <input class="form-control"
                                                        placeholder="Vänligen skriv ditt förnamn" type="text"
                                                        [(ngModel)]="userData.name"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (keyup)="onSearchKey2($event.target,'name')" required>
                                                    <span class="warning">{{namefield }} </span>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="" class="form-label">Efternamn</label>
                                                    <input class="form-control" placeholder="Vänligen skriv ditt efternamn"
                                                        type="text" [(ngModel)]="userData.last_name"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (keyup)="onSearchKey2($event.target,'last_name')">
                                                    <span class="warning">{{lastname }} </span>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="" class="form-label">Födelsedatum</label>
                                                    <input class="form-control" type="date" [(ngModel)]="userData.dob"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (keyup)="onSearchKey2($event.target,'dob')">
                                                    <span class="warning">{{dob}} </span>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="" class="form-label">E-post</label>
                                                    <input placeholder="Vänligen ange ditt lösenord" class="form-control"
                                                        type="email" [(ngModel)]="userData.email"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (keyup)="onSearchKey2($event.target,'email')">
                                                    <span class="warning">{{email }} </span>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label for="" class="form-label">Mobil</label>
                                                    <input placeholder="Please type your mobile number"
                                                        class="form-control" type="tel"
                                                        (keyup)="onSearchKey3($event.target.value,'phone')"
                                                        [(ngModel)]="userData.phone"
                                                        [ngModelOptions]="{ standalone: true }" />
                                                    <span class="warning">{{phone}}</span>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label for="" class="form-label">Om Mig</label>
                                                    <textarea
                                                        placeholder="Dela gärna lite om dig själv och dina stora förhoppningar"
                                                        name="" id="" class="new-styleform" cols="30" rows="5"
                                                        [(ngModel)]="userData.bio"
                                                        [ngModelOptions]="{standalone: true}"></textarea>
                                                </div>
                                                <div class="col-md-12">
                                                    <p class="add_cart"><a href="javascript:void(0);"
                                                            (click)="onUpdateProfile()">SPARA & UPPDATERA</a></p>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <!-- <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                                    aria-labelledby="pills-profile-tab">
                                    <form [formGroup]="createuser3">
                                        <div class="emp_area">
                                            <div class="row">
                                                <div class="form-group col-md-6">
                                                    <label for="" class="form-label">Gatuadress</label>
                                                    <input type="text" formControlName="address" class="form-control"
                                                        [(ngModel)]="userData.address"
                                                        [ngClass]="{ 'is-invalid': submitted3 && f3.address.errors }">
                                                    <div
                                                        *ngIf="f3.address.invalid && (f3.address.dirty || f3.address.touched)">

                                                    </div>
                                                    <span class="warning">{{address}}</span>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="" class="form-label">Land</label>
                                                    <select name="" id="" formControlName="country" class="form-control"
                                                        [(ngModel)]="userData.country"
                                                        [ngClass]="{ 'is-invalid': submitted3 && f3.country.errors }"
                                                        (change)="onChangeCountry($event.target)">
                                                        <option value="" selected>välj</option>
                                                        <option [value]="countryData.id"
                                                            *ngFor="let countryData of loadcountryList">
                                                            {{countryData.name}}</option>
                                                    </select>
                                                    <div
                                                        *ngIf="f3.country.invalid && (f3.country.dirty || f3.country.touched)">

                                                    </div>
                                                    <span class="warning">{{country}}</span>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="" class="form-label">Stat/Län
                                                    </label>
                                                    <select name="" id="" formControlName="state" class="form-control"
                                                        [(ngModel)]="userData.state"
                                                        [ngClass]="{ 'is-invalid': submitted3 && f3.state.errors }"
                                                        (change)="onChangeState($event.target)">
                                                        <option value="" selected>välj</option>
                                                        <option [value]="stateData.id"
                                                            *ngFor="let stateData of loadstateList">{{stateData.name}}
                                                        </option>
                                                    </select>
                                                    <div
                                                        *ngIf="f3.state.invalid && (f3.state.dirty || f3.state.touched)">

                                                    </div>
                                                    <span class="warning">{{state}}</span>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="" class="form-label">Stad</label>
                                                    <select name="" id="" formControlName="city" class="form-control"
                                                        [(ngModel)]="userData.city"
                                                        [ngClass]="{ 'is-invalid': submitted3 && f3.city.errors }">
                                                        <option value="" selected>välj</option>
                                                        <option [value]="cityData.id"
                                                            *ngFor="let cityData of loadcityList">{{cityData.name}}
                                                        </option>
                                                    </select>
                                                    <div *ngIf="f3.city.invalid && (f3.city.dirty || f3.city.touched)">

                                                    </div>
                                                    <span class="warning">{{city}}</span>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="" class="form-label">Postnummer</label>
                                                    <input placeholder="Vänligen skriv ditt postnummer" type="text"
                                                        formControlName="pcode" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted3 && f3.pcode.errors }">
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="" class="form-label">Tidszon</label>
                                                    <select name="" id="" formControlName="time_zone"
                                                        class="form-control" [(ngModel)]="userData.time_zone"
                                                        [ngClass]="{ 'is-invalid': submitted3 && f3.time_zone.errors }">
                                                        <option value="" selected>välj</option>
                                                        <option [value]="time" *ngFor="let time of TimeZone">{{time}}
                                                        </option>
                                                    </select>
                                                    <span class="warning">{{time_zone}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <p class="add_cart"><a href="javascript:void(0);"
                                                            (click)="onSubmitAddress()">SPARA & UPPDATERA</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div> -->

                                <div class="tab-pane fade" id="pills-contact" role="tabpanel"
                                    aria-labelledby="pills-contact-tab">
                                    <form [formGroup]="createuser2">
                                        <div class="emp_area">
                                            <div class="row">
                                                <div class="form-group col-md-6 col-sm-12 col-lg-6">
                                                    <label for="">Förnamn</label>
                                                    <input placeholder=" Vänligen skriv ditt förnamn" type="text"
                                                        formControlName="b_name" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted2 && f2.b_name.errors }">
                                                </div>
                                                <div class="form-group col-md-6 col-sm-12 col-lg-6">
                                                    <label for="">Efternamn</label>
                                                    <input placeholder="Vänligen skriv ditt efternamn" type="text"
                                                        formControlName="b_last_name" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted2 && f2.b_last_name.errors }">
                                                </div>
                                                <div class="form-group col-md-6 col-sm-12 col-lg-6">
                                                    <label for="">Gatuadress</label>
                                                    <input placeholder="Vänligen skriv din adress" type="text"
                                                        formControlName="billing_address" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted2 && f2.billing_address.errors }">
                                                </div>
                                                <div class="form-group col-md-6 col-sm-12 col-lg-6">
                                                    <label for="">Postnummer</label>
                                                    <input placeholder="Vänligen skriv ditt postnummer" type="text"
                                                        formControlName="bpcode" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted2 && f2.bpcode.errors }">
                                                </div>
                                                <div class="form-group col-md-6 col-sm-12 col-lg-6">
                                                    <label for="">Stad</label>
                                                    <input placeholder="Please type your Stad" type="text"
                                                        formControlName="bcity" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted2 && f2.bcity.errors }">
                                                </div>
                                                <div class="form-group col-md-6 col-sm-12 col-lg-6">
                                                    <label for="">Stat/Län</label>
                                                    <input placeholder="Vänligen skriv din stat eller län" type="text"
                                                        formControlName="bstate" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted2 && f2.bstate.errors }">
                                                </div>
                                                <div class="form-group col-md-12 col-sm-12 col-lg-12">
                                                    <label for="">Land</label>
                                                    <input placeholder="Vänligen skriv ditt land" type="text"
                                                        formControlName="bcountry" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted2 && f2.bcountry.errors }">
                                                </div>
                                            </div>




                                            <div class="loc_feild">
                                                <div class="row">
                                                    <div class="col-md-12 p-0">
                                                        <p class="add_cart"><a href="javascript:void(0);"
                                                                (click)="onSubmitBillingAddress()">SPARA & UPPDATERA</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>

                                <div class="tab-pane fade" id="pills-password" role="tabpanel"
                                    aria-labelledby="pills-password-tab">
                                    <form [formGroup]="createuser">
                                        <div class="emp_area">
                                            <div class="name_feild">
                                                <p>Gammalt Lösenord</p>
                                                <div><input type="text" formControlName="current_password"
                                                        [ngClass]="{ 'is-invalid': submitted && f.current_password.errors }"
                                                        class="form-control" (keyup)="er_pass($event.target)">
                                                    <span class="warning">{{password_error}}</span>

                                                    <div *ngIf="(f.current_password.errors && f.current_password.touched) || (f.current_password.errors && submitted)"
                                                        class="errorMsg">
                                                        <div class="warning"
                                                            *ngIf="f.current_password.errors.mustMatch">Password do not
                                                            match.</div>
                                                    </div>
                                                    <div
                                                        *ngIf="f.current_password.invalid && (f.current_password.dirty || f.current_password.touched)">

                                                        <div class="warning" *ngIf="f.current_password.errors.required">
                                                            Old password is required.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="loc_feild">
                                                <p>Nytt Lösenord</p>
                                                <div><input type="text" formControlName="password"
                                                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                                        class="form-control">
                                                    <div
                                                        *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)">

                                                        <div class="warning" *ngIf="f.password.errors.required">
                                                            New password is required.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="loc_feild">
                                                <p>Bekräfta Nytt Lösenord</p>
                                                <div><input type="text" formControlName="reenterpassword"
                                                        [ngClass]="{ 'is-invalid': submitted && f.reenterpassword.errors }"
                                                        class="form-control">
                                                    <div *ngIf="(f.reenterpassword.errors && f.reenterpassword.touched) || (f.reenterpassword.errors && submitted)"
                                                        class="errorMsg">
                                                        <div class="warning" *ngIf="f.reenterpassword.errors.mustMatch">
                                                            The new password and confirmed password do not match.</div>
                                                    </div>
                                                    <div
                                                        *ngIf="f.reenterpassword.invalid && (f.reenterpassword.dirty || f.reenterpassword.touched)">

                                                        <div class="warning" *ngIf="f.reenterpassword.errors.required">
                                                            This field is required.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="loc_feild">
                                                <div class="row">
                                                    <div class="col-md-12 p-0">
                                                        <p class="add_cart"><a href="javascript:void(0);"
                                                                (click)="onChangePassword()">SPARA & UPPDATERA</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="tab-pane fade" id="pills-photo" role="tabpanel"
                                    aria-labelledby="pills-contact-tab">
                                    <form>
                                        <div class="emp_area">
                                            <div class="name_feild bdrb">
                                                <div class="row justify-content-center">
                                                    <div class="col-md-3 text-center pb-4">
                                                        <div class="profile-photo-pick">
                                                            <img src="assets/images/p-blank.jpg" alt=""
                                                                class="m-auto img-fluid"
                                                                *ngIf="uploadedImagesData ==''">
                                                            <img [src]="allowurl(apiService.imageBase+uploadedImagesData)"
                                                                alt="" class="m-auto img-fluid"
                                                                *ngIf="uploadedImagesData !=''">
                                                            <p class="mt-3">Lägg till Din Profilbild</p>
                                                            <p><button class="style1"><i class="fas fa-plus"></i>
                                                                    <input type="file"
                                                                        (change)="onSelectFile($event.target)" #file
                                                                        id="file" style="opacity: 0;
                                                            position: absolute;
                                                            left: 1px;">
                                                                    LÄGG TILL FOTO</button></p>
                                                            <div class="clearfix"></div>
                                                            <span class="tooltip-icon mt-2" data-toggle="tooltip"
                                                                data-placement="right"
                                                                title=" Please upload photos on the following format .jpeg, jpg or .png"
                                                                style="margin-top:15px;">
                                                                <i class="fas fa-info-circle"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <p class="pull-right" style="text-align: center"><a
                                                        href="javascript:void(0);" class="style1 bg"
                                                        (click)="onSubmitPhotoVideo()">SPARA & UPPDATERA</a></p>
                                            </div>
                                        </div>
                                    </form>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-tab222" role="tabpanel" aria-labelledby="pills-tab222-tab">
                        <div class="list_box1">
                            <div class="tab_link">
                                <p class="for_m dd_next hand">konto<i class="fas fa-caret-down"></i></p>
                                <div class="for_d">
                                    <ul class="nav nav-pills mb-3" id="quiz-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link active" id="pills-lesson1-tab" data-toggle="pill"
                                                href="#pills-lesson1" role="tab" aria-controls="pills-lesson1"
                                                aria-selected="true">Kommande  </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link" id="pills-lesson2-tab" data-toggle="pill"
                                                href="#pills-lesson2" role="tab" aria-controls="pills-lesson2"
                                                aria-selected="false">Slutförda</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link" id="pills-lesson3-tab" data-toggle="pill"
                                                href="#pills-lesson3" role="tab" aria-controls="pills-lesson3"
                                                aria-selected="false">Avbrutna</a>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div class="tab_cont p-2">
                                <div class="tab_cont tab-content" id="pills-tabContent">
                                    <div class="">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-12">
                                                <div class=""><label>Startdatum</label>
                                                    <input type="date" class="form-control-design1"
                                                        [ngModel]="startDate | date:'yyyy-MM-dd'"
                                                        (ngModelChange)="setDate('start', $event)" name="startDate">
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-12">
                                                <div class=""><label>Slutdatum</label>
                                                    <input type="date" class="form-control-design1" [ngModel]="endDate"
                                                        (ngModelChange)="setDate('end', $event)" name="endDate">
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                <div class="edt-profile-design">
                                                    <select name="" id="" class="form-select"
                                                        (change)="onChangetime($event.target)">
                                                        <option value="" selected>välj</option>
                                                        <option value="08:00:00 09:00:00" selected>
                                                            08:00:00 - 09:00:00
                                                        </option>
                                                        <option value="09:00:00 10:00:00" selected>
                                                            09:00:00 - 10:00:00
                                                        </option>
                                                        <option value="10:00:00 11:00:00" selected>
                                                            10:00:00 - 11:00:00
                                                        </option>
                                                        <option value="11:00:00 12:00:00" selected>
                                                            11:00:00 - 12:00:00
                                                        </option>
                                                        <option value="12:00:00 13:00:00" selected>
                                                            12:00:00 - 13:00:00
                                                        </option>
                                                        <option value="13:00:00 14:00:00" selected>
                                                            13:00:00 - 14:00:00
                                                        </option>
                                                        <option value="14:00:00 15:00:00" selected>
                                                            14:00:00 - 15:00:00
                                                        </option>
                                                        <option value="15:00:00 16:00:00" selected>
                                                            15:00:00 - 16:00:00
                                                        </option>
                                                        <option value="16:00:00 17:00:00" selected>
                                                            16:00:00 - 17:00:00
                                                        </option>
                                                        <option value="17:00:00 18:00:00" selected>
                                                            17:00:00 - 18:00:00
                                                        </option>
                                                        <option value="18:00:00 19:00:00" selected>
                                                            18:00:00 - 19:00:00
                                                        </option>
                                                        <option value="19:00:00 20:00:00" selected>
                                                            19:00:00 - 20:00:00
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-sm-12 mt-3"></div>
                                            <div class="col-md-6 col-sm-12 mt-3 Clear-Search">

                                                <span>
                                                    <button type="button" class="style1"
                                                        (click)="clearDateFilter()">RENSA</button>
                                                </span>
                                                <span><button type="button" class="style1 bg">SÖK</button></span>
                                            </div>

                                        </div>

                                    </div>


                                    <div class="tab-pane fade show active" id="pills-lesson1" role="tabpanel"
                                        aria-labelledby="pills-home-tab">
                                        <div class="col-12">
                                            <div class="row my-5">
                                                <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin showrecord">
                                                    <label>
                                                        Visa
                                                        <select class="filter-select mr-2"
                                                            [(ngModel)]="bodyDataRequestu['limit']"
                                                            (change)="onChangeLimitupc($event.target.value)">
                                                            <option selected disabled>välj</option>
                                                            <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="50">50</option>

                                                        </select>
                                                        Poster
                                                    </label>
                                                </div>
                                                <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin text-right">
                                                    <pagination-controls previousLabel="Föregående" nextLabel="Nästa"
                                                        id="u"
                                                        (pageChange)="onPageChangeupc($event)"></pagination-controls>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-12 wow fadeInLeft">

                                            <ng-container class="list_box p-4 fs13 rel"
                                                *ngFor="let myclasslist of MyClass_upcoming| paginate:{itemsPerPage:bodyDataRequestu['limit'],currentPage:pu,totalItems:total_count_upcoming,id:'u'};let i= index">
                                                <div class="row" *ngIf="myclasslist.status==1">
                                                    <div class="row">
                                                        <div class="pt-5 px-0">
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Lärarnamn</th>
                                                                        <th scope="col">Bokat Datum</th>
                                                                        <th scope="col">Lärarnamn</th>
                                                                        <th *ngIf="myclasslist.class_type == 0"
                                                                            scope="col">
                                                                            Belopp
                                                                        </th>
                                                                        <th *ngIf="myclasslist.class_type == 1"
                                                                            scope="col">
                                                                            Type
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{{myclasslist.teacher_name}}</td>
                                                                        <td>{{myclasslist.schedule_slot_date | date:'EEEE, MMMM d, y'}} - {{myclasslist.time_from}}</td>
                                                                        <td>Betald</td>
                                                                        <td>{{myclasslist?.rate || '12'}}SEK </td>
                                                                    </tr>
                                                                    <tr class="d-none">
                                                                        <div class="btn-group">

                                                                            <button type="button"
                                                                                class="btn btn-outline-primary"
                                                                                *ngIf="(myclasslist.schedule_slot_date|date:'EEEE, MMMM d, y')==(today_date|date:'EEEE, MMMM d, y')">
                                                                                <a href="https://merithub.com/cjiau685utj49marj5s0/sessions/view/{{myclasslist?.classId}}/{{myclasslist?.commonParticipantLink}}"
                                                                                    target="_blank" class="">GÅ MED</a>
                                                                            </button>
                                                                            <!-- <button type="button"
                                                                                class="btn btn-outline-primary">
                                                                                <a href="javascript:void(0);"
                                                                                    (click)="onsub(myclasslist)"
                                                                                    class="">Reschedule</a>
                                                                            </button> -->
                                                                            <!-- <button type="button"
                                                                                class="btn btn-outline-primary">
                                                                                <a href="javascript:void(0);" class=""
                                                                                    (click)="onCancelClassPopup(myclasslist)">Cancel
                                                                                    &amp; Refund</a>
                                                                            </button> -->
                                                                            <!-- <button *ngIf="myclasslist.status == 2"
                                                                                type="button"
                                                                                class="btn btn-outline-primary">
                                                                                <a href="javascript:void(0);" class=""
                                                                                    *ngIf="myclasslist.status == 2">Delivered</a>
                                                                            </button> -->

                                                                        </div>
                                                                    </tr>
                                                                </tbody>
                                                                <!-- <tbody>
                                                                    <tr>
                                                                        <td>Teacher Name</td>
                                                                        <td>
                                                                            Tue 13-06-2023
                                                                        </td>
                                                                        <td>Betald</td>
                                                                        <td>
                                                                            Demolektion
                                                                        </td>
                                                                    </tr>
                                                                    <tr class="d-none">
                                                                        <div class="btn-group impwidg">
                                                                            <button type="button"
                                                                                class="btn btn-outline-primary">
                                                                                <a href="javascript:void(0);"
                                                                                    class="">GÅ MED</a>

                                                                            </button>
                                                                            <button type="button"
                                                                                class="btn btn-outline-primary">
                                                                                Reschedule
                                                                            </button>

                                                                            <button type="button"
                                                                                class="btn btn-outline-primary">
                                                                                <a href="javascript:void(0);"
                                                                                    class="">Cancel &amp; Refund</a>
                                                                            </button>
                                                                        </div>
                                                                    </tr>
                                                                </tbody> -->
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>


                                    <div class="tab-pane fade" id="pills-lesson2" role="tabpanel"
                                        aria-labelledby="pills-profile-tab">
                                        <div class="row my-5">
                                            <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin showrecord">
                                                <label>
                                                    Visa
                                                    <select class="filter-select mr-2"
                                                        [(ngModel)]="bodyDataRequestc['limit']"
                                                        (change)="onChangeLimitcom($event.target.value)">
                                                        <option selected disabled>välj</option>
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>

                                                    </select>
                                                    Poster
                                                </label>
                                            </div>
                                            <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin text-right">
                                                <pagination-controls previousLabel="Föregående" nextLabel="Nästa" id="c"
                                                    (pageChange)="onPageChangecom($event)"></pagination-controls>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 wow fadeInLeft">
                                            <ng-container class="list_box p-4 fs13 rel"
                                                *ngFor="let myclasslist of MyClass| paginate:{itemsPerPage:bodyDataRequestc['limit'],currentPage:pc,totalItems:total_count_complete,id:'c'};let i= index">
                                                <div class="row" *ngIf="myclasslist.status==2">
                                                    <div class="col-lg-4 col-md-6 pb-3">
                                                        <p class="Tuition-Frequency">Lärarnamn</p>
                                                        <p class="b mt-2">{{myclasslist.teacher_name}}</p>
                                                    </div>
                                                    <div class="col-lg-4 col-md-6 pb-3">
                                                        <p class="Tuition-Frequency">Bokat Datum</p>
                                                        <p class="b mt-2">{{myclasslist.schedule_slot_date |date:'EEEE, MMMM d, y'}} - {{myclasslist.time_from}}
                                                        </p>
                                                    </div>
                                                    <div class="col-lg-2 col-md-6 pb-3">
                                                        <p class="Tuition-Frequency">Lärarnamn</p>
                                                        <p class="b mt-2">Betald</p>
                                                    </div>
                                                    <div class="col-lg-2 col-md-6 pb-3">
                                                        <p class="Tuition-Frequency">Belopp</p>
                                                        <p class="b mt-2">{{myclasslist?.rate || '0'}}SEK </p>
                                                    </div>

                                                    <div class="col-lg-12 col-md-12 bdrb"></div>
                                                    <div class="col-lg-12 col-md-12 pt-4">
                                                        <div class="row">
                                                            <div class="col-md-7 p-0"></div>

                                                            <div class="col-md-2 p-0 pl-3"><a href="javascript:void(0);"
                                                                    (click)="onsub(myclasslist)"
                                                                    class="compare">Reschedule</a></div>
                                                            <!-- <div class="col-md-3 p-0 pl-3"><a href="javascript:void(0);" class="compare"
                                                                    (click)="onCancelClassPopup(myclasslist)">Cancel &amp; Refund</a>
                                                            </div> -->

                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>


                                    <div class="tab-pane fade" id="pills-lesson3" role="tabpanel"
                                        aria-labelledby="pills-contact-tab">
                                        <div class="row my-5">
                                            <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin showrecord">
                                                <label>
                                                    Visa
                                                    <select class="filter-select mr-2"
                                                        [(ngModel)]="bodyDataRequestca['limit']"
                                                        (change)="onChangeLimitcan($event.target.value)">
                                                        <option selected disabled>välj</option>
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="50">50</option>

                                                    </select>
                                                    Poster
                                                </label>
                                            </div>
                                            <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin text-right">
                                                <pagination-controls previousLabel="Föregående" nextLabel="Nästa" id="ca"
                                                    (pageChange)="onPageChangecan($event)"></pagination-controls>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 wow fadeInLeft">
                                            <ng-container class="list_box p-4 fs13 rel"
                                                *ngFor="let myclasslist of MyClass| paginate:{itemsPerPage:bodyDataRequestca['limit'],currentPage:pca,totalItems:total_count_cancel,id:'ca'};let i= index">
                                                <div class="row" *ngIf="myclasslist.status==3">
                                                    <div class="col-lg-4 col-md-6 pb-3">
                                                        <p class="Tuition-Frequency">Lärarnamn</p>
                                                        <p class="b mt-2">{{myclasslist.teacher_name}}</p>
                                                    </div>
                                                    <div class="col-lg-4 col-md-6 pb-3">
                                                        <p class="Tuition-Frequency">Bokat Datum</p>
                                                        <p class="b mt-2">{{myclasslist.schedule_slot_date |date:'EEEE, MMMM d, y'}} - {{myclasslist.time_from}}
                                                        </p>
                                                    </div>
                                                    <div class="col-lg-2 col-md-6 pb-3">
                                                        <p class="Tuition-Frequency">Lärarnamn</p>
                                                        <p class="b mt-2">Betald</p>
                                                    </div>
                                                    <div class="col-lg-2 col-md-6 pb-3">
                                                        <p class="Tuition-Frequency">Belopp</p>
                                                        <p class="b mt-2">{{myclasslist?.rate || '0'}}SEK </p>
                                                    </div>

                                                    <div class="col-lg-12 col-md-12 bdrb"></div>
                                                    <div class="col-lg-12 col-md-12 pt-4">
                                                        <div class="row">
                                                            <div class="col-md-7 p-0"></div>

                                                            <div class="col-md-2 p-0 pl-3"><a href="javascript:void(0);"
                                                                    (click)="onsub(myclasslist)"
                                                                    class="compare">Reschedule</a></div>
                                                            <!-- <div class="col-md-3 p-0 pl-3"><a href="javascript:void(0);" class="compare"
                                                                (click)="onCancelClassPopup(myclasslist)">Cancel &amp; Refund</a>
                                                        </div> -->
                                                            <!-- <div class="col-md-3 p-0 pl-3"><a href=""  class="compare" (click)="data_user(myclasslist)">FeedBack</a></div> -->

                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="pills-tab2" role="tabpanel" aria-labelledby="pills-tab2-tab">
                        <div class="col-md-12">
                            <div class="My-Earning">
                                <div class="ext-button mb-5">
                                    <p class="text-right">
                                        <a href="javascript:void(0);" (click)="onOpenSubjectModal()"
                                            id="add_childspopup" class="style1">
                                            <i class="fas fa-plus"></i> LÄGG TILL DINA BARN</a>
                                    </p>
                               
                                </div>
                                 <!-- <p>*ngIf="Content_Type === 'Student Details'"</p> -->
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Förnamn</th>
                                            <th scope="col">Efternamn</th>
                                            <th scope="col">E-post</th>
                                            <th scope="col">Telefon</th>
                                            <th scope="col">Redigera och Radera</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let child of ParentChildList">
                                            <td>{{child.name}}</td>
                                            <td>{{child.last_name}}</td>
                                            <td>{{child.email}}</td>
                                            <td>{{child.phone}}</td>
                                            <td>
                                                <div class="Edit-and-Delete"><a href="" data-toggle="modal"
                                                        id="edit_childpopup" data-target="#edit" class=""
                                                        (click)="data_user(child)"><i class="fas fa-edit"></i></a>
                                                    <a href="javascript:void(0)" class="delete-btn-icon px-1"
                                                        (click)="delete_user(child)">
                                                        <i class="fas fa-trash" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-tab6" role="tabpanel" aria-labelledby="pills-tab6-tab">
                        <div class="col-lg-12 wow fadeInLeft">

                            <div class="My-Earning">
                                <table class="table table-bordered tutor-class-ext">
                                    <thead>
                                        <tr>
                                            <th scope="col">Lärarnamn</th>
                                            <th scope="col">Lärarnamn</th>
                                            <th scope="col">Undervisningsfrekvens</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Demolektion</td>
                                            <td>Gratis</td>
                                            <td>Demolektion</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row">
                                    <div class="col-md-12 mt-3"><a
                                            href="https://merithub.com/cjiau685utj49marj5s0/sessions/view/cjomurr92kq5ihnkg9pg/2uzmssj.oq5fH-eV7N6D0YTb54oLhQ1kH7wcnGV702iezJbjZhotkQBQSOtEqlGbpmW-7Kq15WEIQf-sBvh7IjOZ_v8Gf_I"
                                            target="_blank" class="style1 extra-padding">GÅ MED</a></div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="pills-tab16" role="tabpanel" aria-labelledby="pills-tab16-tab">
                        <div class="col-lg-12 wow fadeInLeft">
                            <div class="list_box p-4 fs13 rel"
                                *ngFor="let myclasslist of MyClass| paginate:{itemsPerPage:bodyDataRequest['limit'],currentPage:p,totalItems:total_count};let i= index">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 pb-3">
                                        <p class="Tuition-Frequency">Lärarnamn</p>
                                        <p class="b mt-2">{{myclasslist.teacher_name}}</p>
                                    </div>
                                    <div class="col-lg-4 col-md-6 pb-3">
                                        <p class="Tuition-Frequency">Bokat Datum</p>
                                        <p class="b mt-2">{{myclasslist.schedule_slot_date |date:'EEEE, MMMM d, y'}} -
                                            {{myclasslist.time_from}}
                                        </p>
                                    </div>
                                    <div class="col-lg-2 col-md-6 pb-3">
                                        <p class="Tuition-Frequency">Lärarnamn</p>
                                        <p class="b mt-2">Betald</p>
                                    </div>
                                    <div class="col-lg-2 col-md-6 pb-3">
                                        <p class="Tuition-Frequency">Belopp</p>
                                        <p class="b mt-2">{{myclasslist?.rate || '0'}}SEK </p>
                                    </div>

                                    <div class="col-lg-12 col-md-12 bdrb"></div>
                                    <div class="col-lg-12 col-md-12 pt-4">
                                        <div class="row">
                                            <div class="col-md-7 p-0"></div>
                                            <div *ngIf="(myclasslist.schedule_slot_date|date:'EEEE, MMMM d, y')==(today_date|date:'EEEE, MMMM d, y')"
                                                class="col-md-3 p-0 pl-3"><a
                                                    href="https://merithub.com/cjiau685utj49marj5s0/sessions/view/{{myclasslist?.classId}}/{{myclasslist?.commonParticipantLink}}"
                                                    target="_blank" class="compare">GÅ MED</a></div>
                                            <div class="col-md-2 p-0 pl-3"><a href="javascript:void(0);"
                                                    (click)="onsub(myclasslist)" class="compare">Reschedule</a></div>
                                            <div class="col-md-3 p-0 pl-3"><a href="javascript:void(0);" class="compare"
                                                    (click)="onCancelClassPopup(myclasslist)">Cancel &amp; Refund</a>
                                            </div>
                                            <div class="col-md-3 p-0 pl-3"><a href="" data-toggle="modal"
                                                    data-target="#edit" class="compare"
                                                    (click)="data_user(myclasslist)">FeedBack</a></div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin">
                                    <label>
                                        Visa
                                        <select class="filter-select mr-2" [(ngModel)]="bodyDataRequest['limit']"
                                            (change)="onChangeLimit($event.target)">
                                            <option selected disabled>välj</option>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <!--<option value="">20</option>
                                            <option value="50">50</option>-->
                                        </select>
                                        Poster
                                    </label>
                                </div>
                                <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin text-right">
                                    <pagination-controls (pageChange)="p = $event" previousLabel="Föregående"
                                        nextLabel="Nästa" (pageChange)="onPageChange($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form [formGroup]="createuser">

                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary"
                                            data-dismiss="modal">Close</button>
                                        <button type="button" class="btn btn-primary">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="pills-tab8" role="tabpanel" aria-labelledby="pills-tab8-tab">
                        <div class="col-md-12">
                            <div class="list_box p-5" *ngFor="let child of ParentChildList">
                                <div class="row">
                                    <div class="col-md-8">Name : {{child.name}} {{child.last_name}}</div>
                                    <div class="col-md-8">Class : {{child.class || 'NaN'}}</div>
                                    <div class="col-md-8">Role No : {{child.id}}</div>
                                    <div class="col-md-8">School Name : {{child.school_name || 'NaN'}}</div>
                                    <div class="col-md-8">Scorecard show over here</div>

                                </div>
                            </div>
                            <!-- <div class="list_box p-5">
                                <div class="row">
                                    <div class="col-md-8"><a href="parent-view-children.htm">John Doe</a></div>
                                    <div class="col-md-2"><a href="" data-toggle="modal" data-target="#edit" class="compare"><i class="fas fa-edit"></i></a></div>
                                    <div class="col-md-2"><a href="" class="compare"><i class="fas fa-trash-alt"></i></a></div>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <!-- <div class="tab-pane fade" id="pills-tab5" role="tabpanel"
                                    aria-labelledby="pills-tab5-tab">
                                    <section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
                                        <div class="containers">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="row justify-content-center">
                                                        <div class="col-lg-4 col-sm-6 wow fadeInLeft animated"
                                                            style="visibility: visible;"
                                                            *ngFor="let teacherlist of teacherList">
                                                            <div class="list_box">
                                                             
                                                                <a data-type="iframe" data-src="tutor-short-details.htm"
                                                                    href="javascript:;" title="" class="port_pop1"><img
                                                                        [src]="allowurl(apiService.imageBase+teacherlist.image)"
                                                                        alt="" class="img-fluid image-touter"
                                                                        onError="this.src='assets/images/profile-demo.png'"></a>


                                                                <div class="p-3 rel">
                                                                    <p class="star"><i class="fas fa-star"></i><i
                                                                            class="fas fa-star"></i><i
                                                                            class="fas fa-star"></i><i
                                                                            class="fas fa-star"></i><i
                                                                            class="fas fa-star"></i></p>
                                                                    <p class="b float-left"><a data-type="iframe"
                                                                            data-src="tutor-short-details.htm"
                                                                            href="javascript:;" title=""
                                                                            class="port_pop1">{{teacherlist.name}}</a>
                                                                    </p>
                                                                    <p class="b float-right">${{teacherlist.rate}}.00/hr
                                                                    </p>
                                                                    <p class="clearfix"></p>
                                                                    <p class="fs12">{{teacherlist.address}}</p>
                                                                    <p class="tbot">Totala klass Erfarenhet :
                                                                        <span>{{teacherlist.work_experience}}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div> -->
                    
                    <div mdbModal #deleteModal="mdb-modal" class="modal fade" id="centralModalSuccess" tabindex="-1"
                        user="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto;">
                        <div class="modal-dialog modal-notify modal-danger" user="document">
                            <div class="modal-content">
                                <div class="modal-header" style="background-color: #1c1919">
                                    <p class="heading lead">Delete</p>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                        (click)="deleteModal.hide()">
                                        <span aria-hidden="true" class="white-text" style="color: #cab776;">×</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="text-center">
                                        <i class="fa fa-close fa-4x mb-3 animated rotateIn"></i>
                                        <p>Are you sure, You want to delete this records?</p>
                                    </div>
                                </div>
                                <div class="modal-footer justify-content-center">
                                    <a type="button" class="style1 extra-padding bg " mdbRippleRadius
                                        (click)="deleteRecord()">Yes</a>
                                    <a type="button" class="style1 extra-padding " data-dismiss="modal"
                                        (click)="deleteModal.hide()" mdbRippleRadius>No</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="pills-tab3" role="tabpanel" aria-labelledby="pills-tab3-tab">
                        <div class="list_box1">
                            <div class="tab_link">
                                <p class="for_m dd_next hand">Edit Profile <i class="fas fa-caret-down"></i></p>
                                <div class="for_d ">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link active" id="pills-lesson1-tab" data-toggle="pill"
                                                href="#pills-lesson1" role="tab" aria-controls="pills-lesson1"
                                                aria-selected="true">Upcoming  </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link" id="pills-lesson2-tab" data-toggle="pill"
                                                href="#pills-lesson2" role="tab" aria-controls="pills-lesson2"
                                                aria-selected="false">Slutförda</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link" id="pills-lesson3-tab" data-toggle="pill"
                                                href="#pills-lesson3" role="tab" aria-controls="pills-lesson3"
                                                aria-selected="false">Avbrutna</a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div class="tab_cont tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-lesson1" role="tabpanel"
                                    aria-labelledby="pills-home-tab">
                                    <form>
                                        <div class="emp_area">
                                            <div class="lesson_tbl">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <p class="title">Michelle Leong</p>
                                                                <p>Maths (GCSE)</p>
                                                            </td>
                                                            <td>
                                                                <p>Sat 12th Jan</p>
                                                                <p>12:00 - 13:00</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">SEK 30.00</p>
                                                                <p>Avbrutna</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">-</p>
                                                                <p>-</p>
                                                            </td>
                                                            <td><a href="#" data-toggle="modal" data-target="#ld"
                                                                    class="buttona">Lesson Details</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="lesson_tbl">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <p class="title">Michelle Leong</p>
                                                                <p>Maths (GCSE)</p>
                                                            </td>
                                                            <td>
                                                                <p>Sat 12th Jan</p>
                                                                <p>12:00 - 13:00</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">SEK 30.00</p>
                                                                <p>Avbrutna</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">SEK 25.00</p>
                                                                <p>Received</p>
                                                            </td>
                                                            <td><a href="#" data-toggle="modal" data-target="#ld"
                                                                    class="buttona">Lesson Details</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="lesson_tbl">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <p class="title">Michelle Leong</p>
                                                                <p>Maths (GCSE)</p>
                                                            </td>
                                                            <td>
                                                                <p>Sat 12th Jan</p>
                                                                <p>12:00 - 13:00</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">SEK 30.00</p>
                                                                <p>Avbrutna</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">-</p>
                                                                <p>-</p>
                                                            </td>
                                                            <td><a href="#" data-toggle="modal" data-target="#ld"
                                                                    class="buttona">Lesson Details</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!--<p class="p-3 b fs12 text-center"><a href="tutor-account-2.htm "><i class="fas fa-arrow-left"></i> Go Back</a></p>-->
                                        </div>
                                    </form>
                                </div>

                                <div class="tab-pane fade" id="pills-lesson2" role="tabpanel"
                                    aria-labelledby="pills-profile-tab">
                                    <form>
                                        <div class="emp_area">
                                            <div class="lesson_tbl">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <p class="title">Michelle Leong</p>
                                                                <p>Maths (GCSE)</p>
                                                            </td>
                                                            <td>
                                                                <p>Sat 12th Jan</p>
                                                                <p>12:00 - 13:00</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">SEK 30.00</p>
                                                                <p>Avbrutna</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">-</p>
                                                                <p>-</p>
                                                            </td>
                                                            <td><a href="#" data-toggle="modal" data-target="#ld"
                                                                    class="buttona">Lesson Details</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="lesson_tbl">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <p class="title">Michelle Leong</p>
                                                                <p>Maths (GCSE)</p>
                                                            </td>
                                                            <td>
                                                                <p>Sat 12th Jan</p>
                                                                <p>12:00 - 13:00</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">SEK 30.00</p>
                                                                <p>Avbrutna</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">SEK 25.00</p>
                                                                <p>Received</p>
                                                            </td>
                                                            <td><a href="#" data-toggle="modal" data-target="#ld"
                                                                    class="buttona">Lesson Details</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="lesson_tbl">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <p class="title">Michelle Leong</p>
                                                                <p>Maths (GCSE)</p>
                                                            </td>
                                                            <td>
                                                                <p>Sat 12th Jan</p>
                                                                <p>12:00 - 13:00</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">SEK 30.00</p>
                                                                <p>Avbrutna</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">-</p>
                                                                <p>-</p>
                                                            </td>
                                                            <td><a href="#" data-toggle="modal" data-target="#ld"
                                                                    class="buttona">Lesson Details</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!--<p class="p-3 b fs12 text-center"><a href="tutor-account-2.htm "><i class="fas fa-arrow-left"></i> Go Back</a></p>-->
                                        </div>

                                    </form>
                                </div>

                                <div class="tab-pane fade" id="pills-lesson3" role="tabpanel"
                                    aria-labelledby="pills-contact-tab">
                                    <form>
                                        <div class="emp_area">
                                            <div class="lesson_tbl">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <p class="title">Michelle Leong</p>
                                                                <p>Maths (GCSE)</p>
                                                            </td>
                                                            <td>
                                                                <p>Sat 12th Jan</p>
                                                                <p>12:00 - 13:00</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">SEK 30.00</p>
                                                                <p>Avbrutna</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">-</p>
                                                                <p>-</p>
                                                            </td>
                                                            <td><a href="#" data-toggle="modal" data-target="#ld"
                                                                    class="buttona">Lesson Details</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="lesson_tbl">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <p class="title">Michelle Leong</p>
                                                                <p>Maths (GCSE)</p>
                                                            </td>
                                                            <td>
                                                                <p>Sat 12th Jan</p>
                                                                <p>12:00 - 13:00</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">SEK 30.00</p>
                                                                <p>Avbrutna</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">SEK 25.00</p>
                                                                <p>Received</p>
                                                            </td>
                                                            <td><a href="#" data-toggle="modal" data-target="#ld"
                                                                    class="buttona">Lesson Details</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="lesson_tbl">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <p class="title">Michelle Leong</p>
                                                                <p>Maths (GCSE)</p>
                                                            </td>
                                                            <td>
                                                                <p>Sat 12th Jan</p>
                                                                <p>12:00 - 13:00</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">SEK 30.00</p>
                                                                <p>Avbrutna</p>
                                                            </td>
                                                            <td>
                                                                <p class="b">-</p>
                                                                <p>-</p>
                                                            </td>
                                                            <td><a href="#" data-toggle="modal" data-target="#ld"
                                                                    class="buttona">Lesson Details</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!--<p class="p-3 b fs12 text-center"><a href="tutor-account-2.htm "><i class="fas fa-arrow-left"></i> Go Back</a></p>-->
                                        </div>
                                    </form>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="pills-tab4" role="tabpanel" aria-labelledby="pills-tab4-tab">
                        <div class="list_box1">

                            <div class="tab_cont">

                                <form>
                                    <div class="emp_area">
                                        <div class="lesson_tbl">
                                            <table>
                                                <tbody>
                                                    <tr class="b">
                                                        <td>No.</td>
                                                        <td>Transaction date</td>
                                                        <td>Beskrivning</td>
                                                        <td>Transaction type</td>
                                                        <td>Belopp</td>
                                                    </tr>
                                                    <tr>
                                                        <td>1.</td>
                                                        <td>4 March, 2022</td>
                                                        <td>Maths (GCSE)</td>
                                                        <td>Online</td>
                                                        <td>SEK 30.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="lesson_tbl">
                                            <table>
                                                <tbody>
                                                    <tr class="b">
                                                        <td>No.</td>
                                                        <td>Transaction date</td>
                                                        <td>Beskrivning</td>
                                                        <td>Transaction type</td>
                                                        <td>Belopp</td>
                                                    </tr>
                                                    <tr>
                                                        <td>1.</td>
                                                        <td>4 March, 2022</td>
                                                        <td>Maths (GCSE)</td>
                                                        <td>Online</td>
                                                        <td>SEK 30.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="lesson_tbl">
                                            <table>
                                                <tbody>
                                                    <tr class="b">
                                                        <td>No.</td>
                                                        <td>Transaction date</td>
                                                        <td>Beskrivning</td>
                                                        <td>Transaction type</td>
                                                        <td>Belopp</td>
                                                    </tr>
                                                    <tr>
                                                        <td>1.</td>
                                                        <td>4 March, 2022</td>
                                                        <td>Maths (GCSE)</td>
                                                        <td>Online</td>
                                                        <td>SEK 30.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!--<p class="p-3 b fs12 text-center"><a href="tutor-account-2.htm "><i class="fas fa-arrow-left"></i> Go Back</a></p>-->
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>




                    <div class="tab-pane fade" id="pills-tab44" role="tabpanel" aria-labelledby="pills-tab44-tab">
                        <div class="My-Earning">
                            <div class="row">
                                <!-- <div class="col-md-12 col-sm-12">
                                    <div class="">
                                        <label> Your Name</label>
                                        <input type="text" autocomplete="on" class="form-control-design1"
                                            placeholder="Please enter a name" (keyup)="onSearchKey($event.target)">
                                    </div>
                                </div> -->
                                <div class="col-md-6 col-sm-12">
                                    <div class="">
                                        <label> Startdatum</label>
                                        <input type="date" class="form-control-design1"
                                            [ngModel]="startDate | date:'yyyy-MM-dd'"
                                            (ngModelChange)="setDate('start', $event)" name="startDate"
                                            [max]="getCurrentDate() | date : 'yyyy-MM-dd'">
                                    </div>

                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class=""><label> Slutdatum</label>
                                        <input type="date" class="form-control-design1" [ngModel]="endDate"
                                            (ngModelChange)="setDate('end', $event)" name="endDate"
                                            [max]="getCurrentDate() | date : 'yyyy-MM-dd'">
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12"></div>
                                <div class="col-md-6 col-sm-12 d-flex flex-row-reverse">
                                    <button type="button" class="style1 Clear-Search"
                                        (click)="clearDateFilter()">RENSA</button>

                                    <!-- <span><button type="button" class="Serch-style sec">RENSA</button></span> -->
                                    <!-- <span><button type="button" class="Serch-style"
                                            >Search</button></span> -->
                                </div>

                            </div>
                            <section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
                                <table class="table table-bordered my-5 tutor-class-exty">
                                    <thead>
                                        <tr>
                                            <th scope="col">Lärarnamn</th>
                                            <th scope="col">Bokat Datum</th>
                                            <th scope="col">Beskrivning</th>
                                            <th scope="col">Belopp</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="total_count!=0">
                                        <tr
                                            *ngFor="let myclasslist of MyClass| paginate:{itemsPerPage:bodyDataRequest['limit'],currentPage:p,totalItems:total_count};let i= index">
                                            <td>{{myclasslist.teacher_name}}</td>
                                            <td>{{myclasslist.schedule_slot_date |date:'EEEE, MMMM d, y'}} -
                                                {{myclasslist.time_from}}</td>
                                            <td *ngIf="myclasslist.payment_status == 0">
                                                <p>Unpaid</p>
                                            </td>
                                            <td *ngIf="myclasslist.payment_status == 1">
                                                <p>Betald</p>
                                            </td>
                                            <td>{{myclasslist?.rate || '12'}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="total_count==0">
                                        <tr>
                                            <td>Dummy Payment</td>
                                            <td>13-06-2023</td>
                                            <td>Betald</td>
                                            <td>12</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin showrecord">
                                            <label>
                                                Visa
                                                <select class="filter-select mr-2"
                                                    [(ngModel)]="bodyDataRequest['limit']"
                                                    (change)="onChangeLimit2($event.target.value)">
                                                    <option selected disabled>välj</option>
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                </select>
                                                Poster
                                            </label>
                                        </div>
                                        <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin text-right">
                                            <pagination-controls previousLabel="Föregående" nextLabel="Nästa"
                                                (pageChange)="onPageChange2($event)"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>



                    </div>






                </div>

            </div>
        </div>
    </div>
</section>


<!-- lesson Modal -->
<div class="modal fade" id="ld" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered logmod" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">Lesson Details</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 pr-0">

                        <form>
                            <div class="emp_area ac fs14">
                                <div class="name_feild">
                                    <p class="fs22">Michelle Leong</p>
                                    <p>Maths (GCSE)</p>
                                </div>
                                <div class="name_feild">
                                    <p>Belopp</p>
                                    <p class="fs22">SEK 30.00</p>
                                </div>

                                <div class="loc_feild font-weight-normal">
                                    <p>Sun, 17th February, 2019</p>
                                    <p>11:00 - 12:00</p>
                                </div>
                                <div class="loc_feild">
                                    <p>Calverley St, Leeds LS1 3AB</p>
                                </div>

                            </div>
                        </form>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary mt-4 " data-dismiss="modal">Cancel</button>
                <button type="button" data-toggle="modal" data-target="#fed" data-dismiss="modal" class="submit">Provide
                    feedback</button>
            </div>
        </div>
    </div>
</div>


<div mdbModal #deleteModal1="mdb-modal" class="modal fade" id="centralModalSuccess" tabindex="-1" user="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto">
    <div class="modal-dialog modal-dialog-centered logmod" user="document">
        <div class="modal-content">
            <div class="modal-header" style="background-color: #1c1919">
                <p class="heading lead text-white">Uppgifter om ditt barn</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="deleteModal1.hide()">
                    <span aria-hidden="true" class="text-white">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 pr-0">

                        <form [formGroup]="AddForm">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="">Förnamn</label>
                                    <input placeholder="Enter Child First name" type="text" formControlName="name"
                                        class="form-control">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="">Efternamn</label>
                                    <input placeholder="Enter Child Last name" type="text" formControlName="last_name"
                                        class="form-control">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="">E-post</label>
                                    <input placeholder="Enter child Email" type="text" formControlName="email"
                                        class="form-control">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="">Date of Birth</label>
                                    <input type="date" formControlName="dob" class="form-control">
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="">Mobile</label>

                                    <input placeholder="Please type your mobile number" class="form-control" type="tel"
                                        formControlName="phone" (keyup)="onSearchKey3($event.target.value,'phone')">
                                    <span class="warning">{{ phone }} </span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="">Nytt Lösenord</label>
                                    <input placeholder="Please Enter Your Password" type="password"
                                        formControlName="password" class="form-control" autocomplete="off">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="">Bekräfta Nytt Lösenord</label>
                                    <input type="Enter Confirm password" type="password"
                                        formControlName="reenterpassword" class="form-control" autocomplete="off">
                                </div>
                                <div class="col-md-12">
                                    <div class="loc_feild">
                                        <p class="add_cart"><a href="javascript:void(0);" (click)="onSubmit()"><i
                                                    class="fas fa-laptop text-black"></i> Save</a></p>
                                    </div>
                                </div>

                            </div>

                        </form>

                    </div>
                </div>
            </div>
            <!-- <div class="loc_feild">
                <div class="row">
                    <div class="col-md-2 p-0 mb-2"></div>
                    <div class="col-md-4 p-0 mb-2">
                        <a type="button" class="btn" mdbRippleRadius (click)="onSubmit()">Update & Save</a>
                    </div>
                    <div class="col-md-4 p-0 mb-2">
                        <a type="button" class="btn" data-dismiss="modal" (click)="deleteModal1.hide()"
                            mdbRippleRadius>Cancel</a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>