<div class="modal fade show pp" id="forgot" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
<!-- <div class="modal show pp" id="forgot" tabindex="" role="dialog">
    <div class="modal-dialog modal-dialog-centered logmod" role="document">
        <div class="modal-content"> -->
            <div class="modal-header">
                <h1 class="modal-title">Glömt lösenord</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 pr-0">
                        <!-- start -->
                        <div class="log_box">
                            <form [formGroup]="AddForm">
                                <div class="login_form mt-4">
                                    <div class="mt10">
                                        <label>E-post</label>
                                        <input type="text" name="email_id" id="email_id" placeholder="Vänligen ange din e-post"
                                         formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                         <div  *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">

                                                                <div class="warning" *ngIf="f.email.errors.required">
                                                                    E-post krävs.
                                                                </div>
                                                                <div *ngIf="f.email.errors.email" class="warning">E-post
                                                                    must be a valid e-post address</div>

                                                            </div>
                                                        </div>
                                </div>
                            </form>
                        </div>
                        <!-- end -->
                    </div>
                </div> 
            </div>
            <div class="modal-footer">
                <button type="button" class="style1" data-dismiss="modal" (click)="onClosePopup()">Cancel</button>
                <button type="button" class="style1 bg" (click)="onSubmit()">SKICKA in</button>
            </div>
        </div>
    </div>
</div>