import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';

@Component({
  selector: 'app-compare-course',
  templateUrl: './compare-course.component.html',
  styleUrls: ['./compare-course.component.scss']
})
export class CompareCourseComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any=[];
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  bodyRequestData:any={};
  CourseList:any=[];
  localData:any=[];
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
    if(localStorage.getItem('compareCourseData')){
      var jsonData:any=localStorage.getItem('compareCourseData');
      this.localData=JSON.parse(jsonData);
      console.log('this.localData>>>>>>',this.localData);
      if(this.localData.length>0){
        this.localData.forEach((element:any) => {
          this.getCourseList(element);
        });
      }
      console.log('this.localData>>>>>>>>>',this.localData);
    }
  }

  ngOnInit(): void {
    // this.getCourseList(this.bodyRequestData);
  }
  getCourseList(id:any){
    this.apiService.post('courses_details',{id:id}).subscribe((data:any) => {
      this.CourseList.push(data.data[0]);
      console.log('this.CourseList>>>>>>>',this.CourseList);
    })
  }

  allowurl(url:any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
