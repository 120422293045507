import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';

import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { Meta, Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-product-listing',
  templateUrl: './product_listing.component.html',
  styleUrls: ['./product_listing.component.scss']
})

export class ProductListingComponent implements OnInit {
  flag:any=null;
  categoryId=null;
  homeBlog: any=[];
  resData:any=null;
  catData:any=null;
  constructor(
    public apiService :ApiService,public sanitizer: DomSanitizer, public activatedRoute: ActivatedRoute,private title: Title, private meta: Meta,public commonService:CommonService) { 
    this.flag = this.activatedRoute.snapshot.paramMap.get("id");
    this.commonService.SetFooterContent(false);
    if (this.flag) {
      this.categoryId = this.flag;
      console.log(this.flag);
      this.commonService.SetisProductIdChange(this.categoryId);
    }
  }

  ngOnInit(): void {
    // this.getDataById(this.categoryId);
    this.commonService.GetisProductIdChange().subscribe(data =>{
      console.log(data);
      this.getDataById(data);
    })
  }
  allowurl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getDataById(category_id: any) {
    console.log('category_id:::',category_id)
    var self = this;
    var sendRequestData = {
      isAdmin:0,
      category_id: category_id,
      limit:'500'
    };
    self.apiService.post('product_list', sendRequestData).subscribe((res: any) => {
      if (res.replyCode == 'success') {
        this.resData = res.data;
        this
      }
    },
    error => {
      console.log("Oops! Something went wrong!");
    });

    
    self.apiService.post('category_details', {id:category_id}).subscribe((res: any) => {
      if (res.replyCode == 'success') {
        this.catData = res.data;
        this.title.setTitle(this.catData.meta_title);
        this.meta.updateTag({ name: 'keyword', content: this.catData.meta_keyword });
        this.meta.updateTag({ name: 'description', content: this.catData.meta_description });
      }
    },
    error => {
      console.log("Oops! Something went wrong!");
    });


  }

}
