import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { CanonicalService } from './services/canonical';
import { CommonService } from './services/common.service';
import { LinkService } from './services/seo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'Marbletop';
  isLoginUser: any;

  constructor(public common: CommonService, private router:Router,private renderer: Renderer2, private linkService: LinkService,private canonical: CanonicalService ) {
    if(localStorage.getItem('user') == undefined){
      localStorage.removeItem('user')
      this.common.SetLoggedIn(false);
      this.router.navigate(['/'])

    }else if(localStorage.getItem('user')){
      var json:any=localStorage.getItem('user')
      this.common.SetLoginUserDetails(JSON.parse(json))
      this.common.SetLoggedIn(true);
    }
    // this.linkService.addTag( { rel: 'canonical', href: 'http://blogs.example.com/blah/nice' } );
    // this.linkService.addTag( { rel: 'alternate', hreflang: 'es', href: 'http://es.example.com/' } )
  }

isLoginPopup:boolean=false;
isRegisterPopup:boolean=false;
isAddFriendPopup:boolean=false;
isForgotPopup:boolean=false;
isLogoutPopup:boolean=false;
isLocationPopup:boolean=false;
isAddSubject:boolean=false;
isBookClassPopup:boolean=false;
isBookClassCancelPopup:boolean=false;
isBookClassCancelPopup1:boolean=false;
isAddProject:boolean=false;
isAddQuiz:boolean=false;
isAddParentChild:boolean=false;
isTutorView:boolean=false;
isChatGroup:boolean=false;
isChatGroupUser:boolean=false;
iseditchatGroup:boolean=false;
isupdateprofile:boolean=false;
paymentsPopup:boolean=false;

  ngAfterViewInit() {
    // setTimeout(() => {
    //   let loader = this.renderer.selectRootElement('#loader');
    //   this.renderer.setStyle(loader, 'display', 'none');

    // }, 2000);
  }
  ngOnInit() {
    this.common.GetLoggedIn().subscribe((data:any)=>{
      this.isLoginUser=data;
      if(this.isLoginUser){
      }else{
        this.router.navigate(['/'])
      }
      console.log('isLoginUser>>>>>>',this.isLoginUser);
    })
    this.canonical.createCanonicalLink();
    this.common.GetLoginPopup().subscribe((data:any)=>{return this.isLoginPopup=data});
    this.common.Getpayments().subscribe((data:any)=>{return this.paymentsPopup=data});
    this.common.GetRegisterPopup().subscribe((data:any)=>{return this.isRegisterPopup=data});
    this.common.GetAddFriendPopup().subscribe((data:any)=>{return this.isAddFriendPopup=data});
    this.common.GetForgotPopup().subscribe((data:any)=>{return this.isForgotPopup=data});
    this.common.GetLogoutPopup().subscribe((data:any)=>{return this.isLogoutPopup=data});
    this.common.GetLocationPopup().subscribe((data:any)=>{return this.isLocationPopup=data});
    this.common.GetAddSubject().subscribe((data:any)=>{return this.isAddSubject=data});
    this.common.GetBookAClassModal().subscribe((data:any)=>{return this.isBookClassPopup=data});
    this.common.GetBookClassCancelTutorModal().subscribe((data:any)=>{return this.isBookClassCancelPopup=data});
    this.common.GetBookClassCancelTutorModal1().subscribe((data:any)=>{return this.isBookClassCancelPopup1=data});
    this.common.GetAddProject().subscribe((data:any)=>{return this.isAddProject=data});
    this.common.GetAddQuiz().subscribe((data:any)=>{return this.isAddQuiz=data});
    this.common.GetAddParentChild().subscribe((data:any)=>{return this.isAddParentChild=data});
    this.common.GetAddParentChild().subscribe((data:any)=>{return this.isAddParentChild=data});
    this.common.Getupdateprofile().subscribe((data:any)=>{return this.isupdateprofile=data});
    this.common.GeteditchatGroup().subscribe((data:any)=>{return this.iseditchatGroup=data});

    this.common.GetisTutorView().subscribe((data:any)=>{
      console.log(data);
      return this.isTutorView=data});
    this.common.GetChatGroup().subscribe((data:any)=>{
      console.log(data);
      return this.isChatGroup=data});
    this.common.GetChatGroupUser().subscribe((data:any)=>{
      console.log(data);
      return this.isChatGroupUser=data});
    // this.seoService.createCanonicalURL();
    // this.seoService.createLinkForCanonicalURL();
    
  }
}
