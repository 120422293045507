<div class="mb-3 contT">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <ul class="breadcrumb p-0">
                    <li><a href="/">Home</a></li>
                    <!--<li><a href="listing.htm">Chair</a></li>-->
                    <li class="active">Sök vår FAQ
                    </li>
                </ul>
                <div class="">
                    <h1><span class="txt_gray mob_hide">Sök vår </span> FAQ</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="clearfix"></div>
<!--head+filter end-->

<!-- Content -->
<section class="animated3 wow drop_eff5">
    <div class="container mb-5">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="any-burning">
                    <h4>Har du några funderingar? Sök svar i vårt bibliotek.
                    </h4>
                </div>
                <div class="mb-4">
                    <ul class="tabs">
                        <li class=""><a href="javascript:void(0);" class="style11  {{activeFlag1?'active':''}}"
                                data-tab="tab1" (click)="onChangeType(1)"><span>Mentorer</span> </a></li>
                        <li class="tab-button">
                            <a href="javascript:void(0);" class="style11  {{activeFlag2?'active':''}}" data-tab="tab2"
                                (click)="onChangeType(2)"> <span>Elever</span></a>
                        </li>
                    </ul>
                    <div class="tab-pane">
                        <div class="tab-panel withbg animated drop_eff active" id="tab1">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="emp_area mb-4">
                                        <div class="loc_feild">
                                            <p class="rel"><a href="javascript:void(0);" class="abico"><i
                                                        class="fas fa-search"></i></a><input type="text"
                                                    placeholder="Skriv ett nyckelord, fråga eller ämne"
                                                    (keypress)="onSearchKey($event.target)" class="form-control"></p>
                                        </div>
                                    </div>
                                    <section class="faq-section">
                                        <div class="faq" id="accordion">
                                            <div class="card" *ngFor="let faq of FaqList; let i= index">
                                                <div class="card-header" id="faqHeading-{{i}}">
                                                    <div class="mb-0">
                                                        <h5 class="faq-title" data-toggle="collapse"
                                                            [attr.data-target]="'#faqCollapse-'+i"
                                                            data-aria-expanded="true">
                                                            {{faq.question}} <img src="assets/images/daro.png" alt=""
                                                                class="daro1">
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div id="faqCollapse-{{i}}" class="collapse"
                                                    [attr.aria-label]="'faqHeading-'+i" data-parent="#accordion">
                                                    <div class="card-body">
                                                        <p>{{faq.answer}} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>





            </div>

        </div>
    </div>
</section>