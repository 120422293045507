<section class="contT">
    <div class="container" *ngIf="TutorDetails">
        <div class="row justify-content-center">

            <div class="col-md-11 col-lg-9 col-sm-12 xs-12">
                <div class="list_box Gibraltar">
                    <div class="row">
                        <div class="col-md-4 pb-4 text-center rel">
                            <a href="javascript:void(0);" class="del1"></a><img
                                [src]="allowurl(apiService.imageBase+TutorDetails?.image)"
                                onerror="this.src='assets/images/profile-demo.png';" alt="..."
                                class="img-fluid radius15 m-auto" alt="">
                        </div>
                        <div class="col-md-8 bggray Subjects-Provided">
                            <div class="">
                                <p *ngIf="TutorDetails.verified==1" class="veri"><i class="fas fa-check"></i>Verifierad
                                    profil</p>
                                <p *ngIf="TutorDetails.avg_rating?.toFixed()==5" class="star"><i
                                        class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                        class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn">
                                        Betyg</span></p>
                                <p *ngIf="TutorDetails.avg_rating?.toFixed()==4" class="star"><i
                                        class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                        class="fas fa-star"></i><i class='far'>&#xf005;</i><span class="letn">
                                        Betyg</span></p>
                                <p *ngIf="TutorDetails.avg_rating?.toFixed()==3" class="star"><i
                                        class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                        class='far'>&#xf005;</i><i class='far'>&#xf005;</i><span class="letn">
                                        Betyg</span></p>
                                <p *ngIf="TutorDetails.avg_rating?.toFixed()==2" class="star"><i
                                        class="fas fa-star"></i><i class="fas fa-star"></i><i class='far'>&#xf005;</i><i
                                        class='far'>&#xf005;</i><i class='far'>&#xf005;</i><span class="letn">
                                        Betyg</span></p>
                                <p *ngIf="TutorDetails.avg_rating?.toFixed()==1" class="star"><i
                                        class="fas fa-star"></i>
                                    <i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i
                                        class='far'>&#xf005;</i><i class='far'>&#xf005;</i>
                                    <span class="letn"> Betyg</span>
                                </p>
                                <p *ngIf="TutorDetails.avg_rating==null" class="star"><i class='far'>&#xf005;</i>
                                    <i class='far'>&#xf005;</i>
                                    <i class='far'>&#xf005;</i>
                                    <i class='far'>&#xf005;</i>
                                    <i class='far'>&#xf005;</i>

                                    <span class="letn"> Betyg</span>
                                </p>
                                <h1 class="float-left tutor-details-tittle">{{TutorDetails?.name}}
                                    {{TutorDetails?.last_name}}</h1>
                                <a href="javascript:void(0);" routerLink></a>
                                <p class="TutorDetails-rate"><b> {{TutorDetails.rate}}.00 </b><span class="fs14 ml-2">
                                        kr/h </span></p>
                                <p class="clearfix"></p>
                                <p class="TutorDetails-rate">{{TutorDetails?.city_name}}, {{TutorDetails?.state_name}},
                                    {{TutorDetails?.country_name}}</p>

                                <p class="fs12 mt-2 pr-2 s-name-design">{{TutorDetails?.bio}}
                                    <a href="javascript:void(0);" routerLink></a>
                                </p>
                                <div *ngIf="isUserLoggedInData.role_id!=3 && isUserLoggedInData"
                                    class="row mt-4 text-center">
                                    <!-- <div class="col-md-3 Oliva-Tusari"><a href="javascript:void(0);"
                                            routerLink="/chats/{{TutorDetails.id}}"
                                            target="_parent"><i class="fas fa-comments fs18"></i>  <p class="fs12 mt-1 ">Let’s Chat</p></a>
                                      
                                    </div> -->
                                    <!-- <div *ngIf="isUserLoggedInData.role_id!=5" class="col-md-3 Oliva-Tusari"><a
                                            href="javascript:void(0);" routerLink="/book-a-class/{{TutorDetails?.id}}"
                                            target="_parent"><i class="fas fa-laptop fs18"></i>
                                            <p class="fs12 mt-1 ">Intresseanmälan </p>
                                        </a>

                                    </div> -->
                                    <div *ngIf="isUserLoggedInData.role_id!=3" class="col-md-3 Oliva-Tusari"><a
                                            href="javascript:void(0);" routerLink="/book-a-class/{{TutorDetails?.id}}"
                                            target="_parent"><i class="fas fa-laptop fs18"></i>
                                            <p class="fs12 mt-1 ">Intresseanmälan </p>
                                        </a>

                                    </div>
                                    

                                    
                                </div>
                                <div *ngIf="!isUserLoggedInData" class="row mt-4 text-center">
                                    <!-- <div class="col-md-3 Oliva-Tusari"><a (click)="onOpenLoginPopup()"
                                           
                                            target="_parent"><i class="fas fa-comments fs18"></i> <p class="fs12 mt-1 ">Let’s Chat</p></a>
                                        
                                    </div> -->
                                    <div *ngIf="isUserLoggedInData.role_id!=5" class="col-md-3 Oliva-Tusari"><a
                                            (click)="onOpenLoginPopup()" target="_parent"><i
                                                class="fas fa-laptop fs18"></i>
                                            <p class="fs12 mt-1 ">Intresseanmälan </p>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-0">
                        <div class="row">
                            <div class="col-md-12 p-0">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="list_box-bg-gray text-center">
                                            <a href="javascript:void(0);" class="del1"></a>
                                            <p class="b mb-4 rel style02">Läraren Talar</p>
                                            <p>{{TutorDetails?.languages}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="list_box-bg-gray text-center">
                                            <a href="javascript:void(0);" class="del1"></a>
                                            <p class="b mb-4 rel style02">År av Erfarenhet</p>
                                            <p>{{TutorDetails?.work_experience}} År</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="list_box-bg-gray text-center">
                                            <a href="javascript:void(0);" class="del1"></a>
                                            <p class="b mb-4 rel style02">Antal genomförda lektioner</p>
                                            <p>{{count_lession || '0'}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="pt-5 pb-5 bdrb">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <p class="b mb-4 rel style02">Undervisar i följande ämnen
                                <a href="javascript:void(0);" class="del1"></a>
                            </p>
                            <table class="table table-bordered tutor-class-ext">
                                <thead>
                                    <tr>
                                        <th scope="col">Ämne</th>
                                        <th scope="col">Nivå</th>
                                        <!-- <th scope="col">Timpris</th> -->
                                    </tr>
                                </thead>
                                <tbody *ngFor="let subject of teacher_subjects">
                                    <tr>
                                        <td>{{subject.subject_name}}</td>
                                        <td>{{subject.level_name}}</td>
                                        <!-- <td>SEK {{subject.hourly_rate}}</td> -->

                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="col-md-12 p-0 mt-3">
                            <p class="b mb-4 rel style02">Min utbildning
                                <a href="javascript:void(0);" class="del1"></a>
                            </p>
                            <div class="row">
                                <div class="col-md-12 p-0 rel stnew">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Skolans namn</th>
                                                <th scope="col">Kurs/Program</th>
                                                <th scope="col">År</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let educations of TutorDetails?.educations">
                                            <tr>
                                                <td>{{educations.university}}</td>
                                                <td>{{educations.degree}}</td>
                                                <td>{{educations.completion_year}}</td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="My-Achievements-and-Teaching-Srategy">
                    <div class="row">
                        <div class="col-md-12 p-0 rel mt-3">
                            <p class="b mb-4 rel style02">Mina Framgångar och Undervisningsstrategi
                                <a href="javascript:void(0);" class="del1"></a>
                            </p>
                            <div class="row">
                                <div class="col-md-12 p-0 rel stnew">
                                    {{TutorDetails?.achievement}}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



                <div class="pt-5 pb-5  p-0">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <p class="b mb-4 rel style02">Min tillgänglighet
                                <a href="javascript:void(0);" class="del1"></a>
                            </p>
                            <div class="section-title mt-4" *ngIf="SchedulerList">
                                <ng-container *ngIf="SchedulerList.length>0">
                                    <ng-container *ngFor="let SchedList of SchedulerList;">
                                        <ng-container *ngIf="SchedList.holiday == '1'">
                                            <div class="row mt-3 my-2">
                                                <div class="col-12">
                                                    &nbsp;<strong class="ml-2">{{SchedList.schedule_date
                                                        |date}}</strong>
                                                    &nbsp;<strong class="ml-2">{{SchedList.schedule_date
                                                        |date:'EEEE'}}</strong>
                                                </div>
                                                <div class="col-12 ml-2">
                                                    <p class="select_slot_caption">Select Slot</p>
                                                    <div class="row">
                                                        <div class="col-4" *ngFor="let check1 of SchedList.slots">
                                                            <label class="check">{{check1.time_from.slice(0,5)}}


                                                                <span class="checkmarks"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="SchedList.holiday == '0'">
                                            <div class="slot-Availability">

                                                <div class="slot-Availabilityd">
                                                    <div class="wick-date">
                                                        <strong class="ml-2">{{SchedList.schedule_date
                                                            |date}}</strong>
                                                    </div>
                                                    <div class="wick">
                                                        <strong class="ml-2">{{SchedList.schedule_date
                                                            |date:'EEEE'}}</strong>
                                                    </div>
                                                    <ul>
                                                        <li *ngFor="let check1 of SchedList.slots">
                                                            <label *ngIf="check1.available == '1'" class="check"
                                                                style="color:green; font-weight: 700;font-size: 16px !important;">{{check1.time_from.slice(0,5)}}-{{check1.time_to.slice(0,5)}}

                                                                <span class="checkmarks"></span>
                                                            </label>
                                                            <label *ngIf="check1.available != '1'" class="check"
                                                                style="color: #000; ">{{check1.time_from.slice(0,5)}}-{{check1.time_to.slice(0,5)}}

                                                                <span class="checkmarks"></span>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="My-Achievements-and-Teaching-Srategy mb-5">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <p class="b mb-4 rel style02">Betyg och recensioner</p>
                            <div class="row">
                                <div class="col-md-12 p-0">
                                    <ul class="revies">
                                        <li *ngFor="let teacher_review of TutorDetails.Recensioner">
                                            <h5 class=" teacher_review of TutorDetails.Recensioner">
                                                {{teacher_review.name}}<span>{{teacher_review.created |date}}</span>
                                            </h5>
                                            <div class="star teacher_review-start">
                                                <ng-container *ngIf="teacher_review.rating ==1">
                                                    <i class="fas fa-star"></i>
                                                </ng-container>
                                                <ng-container *ngIf="teacher_review.rating ==2">
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                </ng-container>
                                                <ng-container *ngIf="teacher_review.rating ==3">
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                </ng-container>
                                                <ng-container *ngIf="teacher_review.rating ==4">
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                </ng-container>
                                                <ng-container *ngIf="teacher_review.rating ==5">
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                    <i class="fas fa-star"></i>
                                                </ng-container>
                                                <p class="mt-2">{{teacher_review.review}}.</p>
                                            </div>


                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-12" *ngIf="isUserLoggedInData?.role_id ==2">
                                    <form [formGroup]="createuser1">
                                        <ngx-star-rating class="teacher_review-fint-25" formControlName="rating"
                                            [id]="'rating'"></ngx-star-rating>
                                        <div class="loc_feild">
                                            <textarea name="" id="" cols="30" rows="5" formControlName="review"
                                                class="form-controlsss" placeholder="Skriv din feedback här..."
                                                [ngClass]="{ 'is-invalid': submitted1 && f1.review.errors }"></textarea>
                                        </div>
                                        <button type="button" class="style1 bg" (click)="onSubmit()">SKICKA in</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- listing Ends -->