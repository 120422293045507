import { MessageService } from '../../services/message.service';
import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/services';


@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss']
})
export class BlogListComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any=[];
  
  public blogList:any=[];
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  blogFlag:any;
  bodyRequestData:any={};
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService,private activatedRoute: ActivatedRoute) { 
    this.commonService.SetFooterContent(false);
    this.blogFlag = this.activatedRoute.snapshot.paramMap.get("id");
    this.bodyRequestData['type']=this.blogFlag;
    this.bodyRequestData['keyword']='';
    this.onBlogList(this.bodyRequestData);
    // this.bodyRequestData['type']=this.blogFlag;
  }

  ngOnInit(): void {

    // var sendrequestinput:any={featured:1};
    


  }
  searchKeyword: string = ''; // Declare searchKeyword as a property of the component

  onBlogList(bodyRequestData: any) {
    this.apiService.post('web_blog_list', bodyRequestData).subscribe(
      (data: any) => {
        this.blogList = data.data;
        console.log(this.blogList);
      },
      (error: any) => {
        console.error('Error fetching blog list:', error);
      }
    );
  }
  
  filterBlogList(searchKeyword: string) {
    if (!searchKeyword) {
      // If the search keyword is empty, show all data
      this.onBlogList(this.bodyRequestData);
    } else {
      // Filter the blog list based on the searchKeyword
      this.blogList = this.blogList.filter((blog: any) => {
        const title = blog.title?.toLowerCase() || '';
        const content = blog.content?.toLowerCase() || '';
        return title.includes(searchKeyword.toLowerCase()) || content.includes(searchKeyword.toLowerCase());
      });
      console.log(this.blogList);
    }
  }
  
  onSearch(event: any) {
    this.searchKeyword = event.value;
    this.filterBlogList(this.searchKeyword); // Call filterBlogList directly in onSearch
  }
  
  onSearchSubmit() {
    // Update the bodyRequestData with the search keyword before applying the filter
    this.bodyRequestData['keyword'] = this.searchKeyword;
    
    // Apply the search filter and fetch the filtered blog list
    this.filterBlogList(this.searchKeyword);
  }
  
  public blogDetails(id: any) {
    this.router.navigate(['/blog-details'], { queryParams: { id: btoa(id) }});
  }
  
  allowurl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  

}



