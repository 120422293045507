<div class="modal fade show pp" id="c-l" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered logmod" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">Edit Group</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePopup()">
               <span aria-hidden="true">×</span>
               </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 pr-0">

                        <form [formGroup]="createuser1">
                            <div class="emp_area">
                                <div class="name_feild bdrb" *ngIf="editFlag">
                                    <div class="row justify-content-center">
                                        <div class="col-md-4 text-center pb-4">
                                            <img src="assets/images/p-blank.jpg" alt="" class="m-auto img-fluid" *ngIf="uploadedImagesData ==''">
                                            <img [src]="allowurl(apiService.imageBase+uploadedImagesData)" alt="" class="m-auto img-fluid" *ngIf="uploadedImagesData !=''">
                                            <p class="mt-3">Add Group Icon</p>
                                            <p><button class="compare"><i class="fas fa-plus"></i><input
                                                type="file" accept="image/*"
                                                (change)="onSelectFile($event.target)" #file id="file"
                                                style="    opacity: 0;
                                                position: absolute;
                                                left: 1px;"> Add Photo</button></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="name_feild">
                                    <p>Group Name</p>
                                    <div><input type="text" class="form-control" formControlName="groupname" [ngClass]="{ 'is-invalid': submitted1 && f1.groupname.errors }">
                                        <div *ngIf="f1.groupname.invalid && (f1.groupname.dirty || f1.groupname.touched)">

                                            <div class="warning" *ngIf="f1.groupname.errors.required">
                                                Group Name is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>                     
                                
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary mt-4" data-dismiss="modal" (click)="onClosePopup()">Cancel</button>
                <button type="button" class="submit" (click)="updateGroup()">Update name</button>
            </div>
            <div class="modal-footer" *ngIf="!editFlag">
                <!-- <button type="button" class="btn btn-secondary mt-4" data-dismiss="modal" (click)="onClosePopup()">Cancel</button> -->
                <button type="button" class="submit" (click)="onAdduserfriend()">Add new User</button>
            </div>
            <!-- <button type="button" style="width: fit-content;" class="submit" (click)="onAdduserfriend()">Add new User</button> -->

            <div class="table-responsive">
               
                <table class="table">
                  <thead>
                    <tr>
                     
                 
                      <th>Group Member</th>
                      
                    </tr>
                  </thead>
                  <tbody *ngFor="let group_user of members | keyvalue;">
                    <tr >
                      <td>{{group_user.value.username}} </td>
                      <td>
                        <label class="badge badge-success" style="margin-left: 10px;"
                    (click)="kickUsersFromGroup(group_user.value)"
                 >Kick user</label>
                        <label class="badge badge-success" style="margin-left: 10px;"
                    (click)="removeUsersFromGroup(group_user.value)"
                 >Remove user</label>
                        <label class="badge badge-success" style="margin-left: 10px;"
                    (click)="banUsersFromGroup(group_user.value)"
                 >Ban User</label>
                        <label class="badge badge-success" style="margin-left: 10px;"
                    (click)="unbanUsersFromGroup(group_user.value)"
                 >UnBan User</label>
                         </td>
                   
                    </tr>
                  
                  </tbody>
                </table>
              </div>
        </div>
    </div>
</div>