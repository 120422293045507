
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { ApiService } from './../../../services/api.service';
@Component({
  selector: 'app-parent-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ParentProfileComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any=[];
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  bodyRequestData:any={};
  tutorList:any=[];
  localData:any=[];
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
    if(localStorage.getItem('user')){
      var jsonData:any=localStorage.getItem('user');
      this.localData=JSON.parse(jsonData);
      }
  }

  ngOnInit(): void {
    // this.getTutorList(this.bodyRequestData);
  }

  allowurl(url:any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
