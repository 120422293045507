<div class="mb-3 contT">
	<div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Online Space</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
    	<h1><span class="txt_gray mob_hide">Welcome To </span>Online Space</h1>            
    </div>
</div>

<section class="animated3 wow drop_eff5" style="visibility: hidden; animation-name: none;">
   <div class="container mb-5">
      <div class="row justify-content-center">
         <div class="col-lg-12 wow fadeInLeft" style="visibility: hidden; animation-name: none;">
            <div class="list_box p-5">
               <div class="row">
                  <div class="col-md-4"><img src="assets/images/faqgirl.png" class="img-fluid" alt=""></div>
                  <div class="col-md-8">
                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut dui felis. Donec vulputate ullamcorper massa, in euismod orci euismod sit amet. Ut efficitur turpis nec neque congue tempor sit amet vitae orci. Vestibulum mattis elementum ipsum et gravida. Pellentesque a lorem ipsum. Pellentesque id lorem mattis, dapibus augue vitae, ullamcorper arcu. Morbi dictum justo ac gravida congue.  <br><br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut dui felis. Donec vulputate ullamcorper massa, in euismod orci euismod sit amet. Ut efficitur turpis nec neque congue tempor sit amet vitae orci. Vestibulum mattis elementum ipsum et gravida. Pellentesque a lorem ipsum. Pellentesque id lorem mattis, dapibus augue vitae, ullamcorper arcu. Morbi dictum justo ac gravida congue.<br><br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut dui felis. Donec vulputate ullamcorper massa, in euismod orci euismod sit amet. Ut efficitur turpis nec neque congue tempor sit amet vitae orci. Vestibulum mattis elementum ipsum et gravida. Pellentesque a lorem ipsum. Pellentesque id lorem mattis, dapibus augue vitae, ullamcorper arcu. Morbi dictum justo ac gravida congue.  
                  </div>
               </div>
            </div>
            <div class="list_box p-5">
               <div class="row">
                  <div class="col-md-4"><img src="assets/images/faqgirl.png" class="img-fluid" alt=""></div>
                  <div class="col-md-8">
                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut dui felis. Donec vulputate ullamcorper massa, in euismod orci euismod sit amet. Ut efficitur turpis nec neque congue tempor sit amet vitae orci. Vestibulum mattis elementum ipsum et gravida. Pellentesque a lorem ipsum. Pellentesque id lorem mattis, dapibus augue vitae, ullamcorper arcu. Morbi dictum justo ac gravida congue.  <br><br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut dui felis. Donec vulputate ullamcorper massa, in euismod orci euismod sit amet. Ut efficitur turpis nec neque congue tempor sit amet vitae orci. Vestibulum mattis elementum ipsum et gravida. Pellentesque a lorem ipsum. Pellentesque id lorem mattis, dapibus augue vitae, ullamcorper arcu. Morbi dictum justo ac gravida congue.<br><br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut dui felis. Donec vulputate ullamcorper massa, in euismod orci euismod sit amet. Ut efficitur turpis nec neque congue tempor sit amet vitae orci. Vestibulum mattis elementum ipsum et gravida. Pellentesque a lorem ipsum. Pellentesque id lorem mattis, dapibus augue vitae, ullamcorper arcu. Morbi dictum justo ac gravida congue. 
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>