<div class="mb-3 contT">
    <div class="container pl-3">
        <!-- <ul class="breadcrumb"> -->
            <!-- <li><a href="index.htm">Home</a></li> -->
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <!-- <li class="active">Mitt Konto</li> -->
        <!-- </ul> -->
    </div>
    <div class="container pl-3 pr-3">
        <h1 class="text-center"><span class="txt_gray mob_hide">My </span>Support</h1>
        <p class="clearfix"></p>
    </div>
</div>

<section class="animated3 wow drop_eff5">
    <div class="container">
        <div class="row justify-content-center">

            <div class="col-md-6">
                <div class="list_box p-5 m-auto bg-gray">
                    <form [formGroup]="createuser1">
                        <div class="emp_area">
                            <div class="name_feild">
                                <p><textarea name="" id="" cols="30" rows="20" class="form-control" formControlName="message" [ngClass]="{ 'is-invalid': submitted1 && f1.message.errors }"></textarea></p>
                            </div>

                            <div class="loc_feild">
                                <div class="row">
                                    <div class="col-md-12"><a href="javascript:void(0);" class="compare-amt" (click)="onSubmit()">Submit</a></div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>