import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { PasswordMatchPattern } from 'src/app/services/validation/password-match-pattern';

@Component({
  selector: 'app-chat-group-user',
  templateUrl: './add-chat-group-user.component.html',
  styleUrls: ['./add-chat-group-user.component.scss']
})
export class AddChatgroupUserComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any = [];

  uploadedImagesData: any = '';
  fileToUpload: any = null;
  localData: any;
  createuser1: FormGroup | any; public submitted1 = false;
  editFlag:boolean=false;
  constructor(private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService) {
    var jsonData: any = localStorage.getItem('user');
    this.localData = JSON.parse(jsonData);
  }
  get f1() { return this.createuser1.controls; }
  STudentList:any=[];
  chat_group_id:any;
  getUserList(){
    this.apiService.post('user_drop_down',{}).subscribe((data:any) => {
      this.STudentList=data.data;
    })
  }
  ngOnInit(): void {
    this.getUserList();
    this.createuser1 = this.fb.group({
      user_id: new FormControl('', [Validators.required]),
      // group_id: new FormControl(''),
    });
    this.commonService.GetChatData().subscribe((data:any) =>{
      if(data){
        console.log('data>>>>>>',data);
        this.editFlag=true;
        this.chat_group_id=data;
        // this.createuser1.controls['user_id'].setValue(data.user_id);
        // this.createuser1.controls['group_id'].setValue(data.id);
        // this.uploadedImagesData=data.group_icon;
      }
    })
  }
  allowurl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  uploadFileToActivity(image: any) {
    this.apiService.postFile(image).subscribe((data: any) => {
      this.uploadedImagesData = data.name;
    }, error => {
      console.log(error);
    });
  }
  onSelectFile(file: any) {
    this.uploadFileToActivity(file.files[0])
    if (file.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(file.files[0]);
      reader.onload = (event) => { }
    }
  }
  onClosePopup() {
    this.commonService.SetChatGroupUser(false);
  }
  onSubmit() {
    this.submitted1=true;
    if (this.createuser1.invalid) {
      this.messageService.showWarning('Please enter group name')
      return
    } else {
      var sendRequestData: any = this.createuser1.value;
      sendRequestData.chat_group_id = this.chat_group_id;
      sendRequestData.group_icon = this.uploadedImagesData;
      var requestDataUrl='';
      if(this.editFlag){
        requestDataUrl='add_chat_group_user';
      }else{
        requestDataUrl='create_chat_group';
      }
      this.apiService.post(requestDataUrl, sendRequestData).subscribe((data: any) => {
        if (data.replyCode == "success") {
          this.editFlag=false;
          this.commonService.SetChatChanged(true);
          this.onClosePopup();
          this.commonService.SetUpdateProfileChanged(true);
          this.messageService.showSuccess(data.replyMsg);
        } else {
          this.messageService.showError(data.replyMsg);
        }
      })
    }
  }
}
