import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
  FormArray,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { ApiService } from './../../../services/api.service';
import { DatePipe, formatDate } from '@angular/common';
import { PasswordMatchPattern } from 'src/app/services/validation/password-match-pattern';
declare const $: any;
@Component({
  selector: 'app-tutor-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
  providers: [DatePipe],
})
export class TutorEditProfileComponent implements OnInit {
  @ViewChild('deleteModal', { static: false }) public deleteModal: any;
  @ViewChild('deleteModal1', { static: false }) public deleteModal1: any;

  config: any = {
    placeholder: '',
    tabsize: 2,
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear',
        ],
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
    ],
    fontNames: [
      'Helvetica',
      'Arial',
      'Arial Black',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
    ],
  };
  TimeZone: any = [
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Astrakhan',
    'Europe/Athens',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Helsinki',
    'Europe/Istanbul',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Kirov',
    'Europe/Lisbon',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/Saratov',
    'Europe/Simferopol',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Ulyanovsk',
    'Europe/Uzhgorod',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'CET',
];
  // TimeZone: any = [
  //   'Africa/Abidjan',
  //   'Africa/Accra',
  //   'Africa/Algiers',
  //   'Africa/Bissau',
  //   'Africa/Cairo',
  //   'Africa/Casablanca',
  //   'Africa/Ceuta',
  //   'Africa/El_Aaiun',
  //   'Africa/Johannesburg',
  //   'Africa/Juba',
  //   'Africa/Khartoum',
  //   'Africa/Lagos',
  //   'Africa/Maputo',
  //   'Africa/Monrovia',
  //   'Africa/Nairobi',
  //   'Africa/Ndjamena',
  //   'Africa/Sao_Tome',
  //   'Africa/Tripoli',
  //   'Africa/Tunis',
  //   'Africa/Windhoek',
  //   'America/Adak',
  //   'America/Anchorage',
  //   'America/Araguaina',
  //   'America/Argentina/Buenos_Aires',
  //   'America/Argentina/Catamarca',
  //   'America/Argentina/Cordoba',
  //   'America/Argentina/Jujuy',
  //   'America/Argentina/La_Rioja',
  //   'America/Argentina/Mendoza',
  //   'America/Argentina/Rio_Gallegos',
  //   'America/Argentina/Salta',
  //   'America/Argentina/San_Juan',
  //   'America/Argentina/San_Luis',
  //   'America/Argentina/Tucuman',
  //   'America/Argentina/Ushuaia',
  //   'America/Asuncion',
  //   'America/Atikokan',
  //   'America/Bahia',
  //   'America/Bahia_Banderas',
  //   'America/Barbados',
  //   'America/Belem',
  //   'America/Belize',
  //   'America/Blanc-Sablon',
  //   'America/Boa_Vista',
  //   'America/Bogota',
  //   'America/Boise',
  //   'America/Cambridge_Bay',
  //   'America/Campo_Grande',
  //   'America/Cancun',
  //   'America/Caracas',
  //   'America/Cayenne',
  //   'America/Chicago',
  //   'America/Chihuahua',
  //   'America/Costa_Rica',
  //   'America/Creston',
  //   'America/Cuiaba',
  //   'America/Curacao',
  //   'America/Danmarkshavn',
  //   'America/Dawson',
  //   'America/Dawson_Creek',
  //   'America/Denver',
  //   'America/Detroit',
  //   'America/Edmonton',
  //   'America/Eirunepe',
  //   'America/El_Salvador',
  //   'America/Fort_Nelson',
  //   'America/Fortaleza',
  //   'America/Glace_Bay',
  //   'America/Goose_Bay',
  //   'America/Grand_Turk',
  //   'America/Guatemala',
  //   'America/Guayaquil',
  //   'America/Guyana',
  //   'America/Halifax',
  //   'America/Havana',
  //   'America/Hermosillo',
  //   'America/Indiana/Indianapolis',
  //   'America/Indiana/Knox',
  //   'America/Indiana/Marengo',
  //   'America/Indiana/Petersburg',
  //   'America/Indiana/Tell_City',
  //   'America/Indiana/Vevay',
  //   'America/Indiana/Vincennes',
  //   'America/Indiana/Winamac',
  //   'America/Inuvik',
  //   'America/Iqaluit',
  //   'America/Jamaica',
  //   'America/Juneau',
  //   'America/Kentucky/Louisville',
  //   'America/Kentucky/Monticello',
  //   'America/La_Paz',
  //   'America/Lima',
  //   'America/Los_Angeles',
  //   'America/Maceio',
  //   'America/Managua',
  //   'America/Manaus',
  //   'America/Martinique',
  //   'America/Matamoros',
  //   'America/Mazatlan',
  //   'America/Menominee',
  //   'America/Merida',
  //   'America/Metlakatla',
  //   'America/Mexico_City',
  //   'America/Miquelon',
  //   'America/Moncton',
  //   'America/Monterrey',
  //   'America/Montevideo',
  //   'America/Nassau',
  //   'America/New_York',
  //   'America/Nipigon',
  //   'America/Nome',
  //   'America/Noronha',
  //   'America/North_Dakota/Beulah',
  //   'America/North_Dakota/Center',
  //   'America/North_Dakota/New_Salem',
  //   'America/Nuuk',
  //   'America/Ojinaga',
  //   'America/Panama',
  //   'America/Pangnirtung',
  //   'America/Paramaribo',
  //   'America/Phoenix',
  //   'America/Port-au-Prince',
  //   'America/Port_of_Spain',
  //   'America/Porto_Velho',
  //   'America/Puerto_Rico',
  //   'America/Punta_Arenas',
  //   'America/Rainy_River',
  //   'America/Rankin_Inlet',
  //   'America/Recife',
  //   'America/Regina',
  //   'America/Resolute',
  //   'America/Rio_Branco',
  //   'America/Santarem',
  //   'America/Santiago',
  //   'America/Santo_Domingo',
  //   'America/Sao_Paulo',
  //   'America/Scoresbysund',
  //   'America/Sitka',
  //   'America/St_Johns',
  //   'America/Swift_Current',
  //   'America/Tegucigalpa',
  //   'America/Thule',
  //   'America/Thunder_Bay',
  //   'America/Tijuana',
  //   'America/Toronto',
  //   'America/Vancouver',
  //   'America/Whitehorse',
  //   'America/Winnipeg',
  //   'America/Yakutat',
  //   'America/Yellowknife',
  //   'Antarctica/Casey',
  //   'Antarctica/Davis',
  //   'Antarctica/DumontDUrville',
  //   'Antarctica/Macquarie',
  //   'Antarctica/Mawson',
  //   'Antarctica/Palmer',
  //   'Antarctica/Rothera',
  //   'Antarctica/Syowa',
  //   'Antarctica/Troll',
  //   'Antarctica/Vostok',
  //   'Asia/Almaty',
  //   'Asia/Amman',
  //   'Asia/Anadyr',
  //   'Asia/Aqtau',
  //   'Asia/Aqtobe',
  //   'Asia/Ashgabat',
  //   'Asia/Atyrau',
  //   'Asia/Baghdad',
  //   'Asia/Baku',
  //   'Asia/Bangkok',
  //   'Asia/Barnaul',
  //   'Asia/Beirut',
  //   'Asia/Bishkek',
  //   'Asia/Brunei',
  //   'Asia/Chita',
  //   'Asia/Choibalsan',
  //   'Asia/Colombo',
  //   'Asia/Damascus',
  //   'Asia/Dhaka',
  //   'Asia/Dili',
  //   'Asia/Dubai',
  //   'Asia/Dushanbe',
  //   'Asia/Famagusta',
  //   'Asia/Gaza',
  //   'Asia/Hebron',
  //   'Asia/Ho_Chi_Minh',
  //   'Asia/Hong_Kong',
  //   'Asia/Hovd',
  //   'Asia/Irkutsk',
  //   'Asia/Jakarta',
  //   'Asia/Jayapura',
  //   'Asia/Jerusalem',
  //   'Asia/Kabul',
  //   'Asia/Kamchatka',
  //   'Asia/Karachi',
  //   'Asia/Kathmandu',
  //   'Asia/Khandyga',
  //   'Asia/Kolkata',
  //   'Asia/Krasnoyarsk',
  //   'Asia/Kuala_Lumpur',
  //   'Asia/Kuching',
  //   'Asia/Macau',
  //   'Asia/Magadan',
  //   'Asia/Makassar',
  //   'Asia/Manila',
  //   'Asia/Nicosia',
  //   'Asia/Novokuznetsk',
  //   'Asia/Novosibirsk',
  //   'Asia/Omsk',
  //   'Asia/Oral',
  //   'Asia/Pontianak',
  //   'Asia/Pyongyang',
  //   'Asia/Qatar',
  //   'Asia/Qostanay',
  //   'Asia/Qyzylorda',
  //   'Asia/Riyadh',
  //   'Asia/Sakhalin',
  //   'Asia/Samarkand',
  //   'Asia/Seoul',
  //   'Asia/Shanghai',
  //   'Asia/Singapore',
  //   'Asia/Srednekolymsk',
  //   'Asia/Taipei',
  //   'Asia/Tashkent',
  //   'Asia/Tbilisi',
  //   'Asia/Tehran',
  //   'Asia/Thimphu',
  //   'Asia/Tokyo',
  //   'Asia/Tomsk',
  //   'Asia/Ulaanbaatar',
  //   'Asia/Urumqi',
  //   'Asia/Ust-Nera',
  //   'Asia/Vladivostok',
  //   'Asia/Yakutsk',
  //   'Asia/Yangon',
  //   'Asia/Yekaterinburg',
  //   'Asia/Yerevan',
  //   'Atlantic/Azores',
  //   'Atlantic/Bermuda',
  //   'Atlantic/Canary',
  //   'Atlantic/Cape_Verde',
  //   'Atlantic/Faroe',
  //   'Atlantic/Madeira',
  //   'Atlantic/Reykjavik',
  //   'Atlantic/South_Georgia',
  //   'Atlantic/Stanley',
  //   'Australia/Adelaide',
  //   'Australia/Brisbane',
  //   'Australia/Broken_Hill',
  //   'Australia/Darwin',
  //   'Australia/Eucla',
  //   'Australia/Hobart',
  //   'Australia/Lindeman',
  //   'Australia/Lord_Howe',
  //   'Australia/Melbourne',
  //   'Australia/Perth',
  //   'Australia/Sydney',
  //   'CET',
  //   'CST6CDT',
  //   'EET',
  //   'EST',
  //   'EST5EDT',
  //   'Etc/GMT',
  //   'Etc/GMT+1',
  //   'Etc/GMT+10',
  //   'Etc/GMT+11',
  //   'Etc/GMT+12',
  //   'Etc/GMT+2',
  //   'Etc/GMT+3',
  //   'Etc/GMT+4',
  //   'Etc/GMT+5',
  //   'Etc/GMT+6',
  //   'Etc/GMT+7',
  //   'Etc/GMT+8',
  //   'Etc/GMT+9',
  //   'Etc/GMT-1',
  //   'Etc/GMT-10',
  //   'Etc/GMT-11',
  //   'Etc/GMT-12',
  //   'Etc/GMT-13',
  //   'Etc/GMT-14',
  //   'Etc/GMT-2',
  //   'Etc/GMT-3',
  //   'Etc/GMT-4',
  //   'Etc/GMT-5',
  //   'Etc/GMT-6',
  //   'Etc/GMT-7',
  //   'Etc/GMT-8',
  //   'Etc/GMT-9',
  //   'Etc/UTC',
  //   'Europe/Amsterdam',
  //   'Europe/Andorra',
  //   'Europe/Astrakhan',
  //   'Europe/Athens',
  //   'Europe/Belgrade',
  //   'Europe/Berlin',
  //   'Europe/Brussels',
  //   'Europe/Bucharest',
  //   'Europe/Budapest',
  //   'Europe/Chisinau',
  //   'Europe/Copenhagen',
  //   'Europe/Dublin',
  //   'Europe/Gibraltar',
  //   'Europe/Helsinki',
  //   'Europe/Istanbul',
  //   'Europe/Kaliningrad',
  //   'Europe/Kiev',
  //   'Europe/Kirov',
  //   'Europe/Lisbon',
  //   'Europe/London',
  //   'Europe/Luxembourg',
  //   'Europe/Madrid',
  //   'Europe/Malta',
  //   'Europe/Minsk',
  //   'Europe/Monaco',
  //   'Europe/Moscow',
  //   'Europe/Oslo',
  //   'Europe/Paris',
  //   'Europe/Prague',
  //   'Europe/Riga',
  //   'Europe/Rome',
  //   'Europe/Samara',
  //   'Europe/Saratov',
  //   'Europe/Simferopol',
  //   'Europe/Sofia',
  //   'Europe/Stockholm',
  //   'Europe/Tallinn',
  //   'Europe/Tirane',
  //   'Europe/Ulyanovsk',
  //   'Europe/Uzhgorod',
  //   'Europe/Vienna',
  //   'Europe/Vilnius',
  //   'Europe/Volgograd',
  //   'Europe/Warsaw',
  //   'Europe/Zaporozhye',
  //   'Europe/Zurich',
  //   'HST',
  //   'Indian/Chagos',
  //   'Indian/Christmas',
  //   'Indian/Cocos',
  //   'Indian/Kerguelen',
  //   'Indian/Mahe',
  //   'Indian/Maldives',
  //   'Indian/Mauritius',
  //   'Indian/Reunion',
  //   'MET',
  //   'MST',
  //   'MST7MDT',
  //   'PST8PDT',
  //   'Pacific/Apia',
  //   'Pacific/Auckland',
  //   'Pacific/Bougainville',
  //   'Pacific/Chatham',
  //   'Pacific/Chuuk',
  //   'Pacific/Easter',
  //   'Pacific/Efate',
  //   'Pacific/Enderbury',
  //   'Pacific/Fakaofo',
  //   'Pacific/Fiji',
  //   'Pacific/Funafuti',
  //   'Pacific/Galapagos',
  //   'Pacific/Gambier',
  //   'Pacific/Guadalcanal',
  //   'Pacific/Guam',
  //   'Pacific/Honolulu',
  //   'Pacific/Kiritimati',
  //   'Pacific/Kosrae',
  //   'Pacific/Kwajalein',
  //   'Pacific/Majuro',
  //   'Pacific/Marquesas',
  //   'Pacific/Nauru',
  //   'Pacific/Niue',
  //   'Pacific/Norfolk',
  //   'Pacific/Noumea',
  //   'Pacific/Pago_Pago',
  //   'Pacific/Palau',
  //   'Pacific/Pitcairn',
  //   'Pacific/Pohnpei',
  //   'Pacific/Port_Moresby',
  //   'Pacific/Rarotonga',
  //   'Pacific/Tahiti',
  //   'Pacific/Tarawa',
  //   'Pacific/Tongatapu',
  //   'Pacific/Wake',
  //   'Pacific/Wallis',
  //   'WET',
  // ];
  addForm: FormGroup | any;
  contactData: any = [];
  uploadedImagesData: any = '';
  uploadedDocumentImagesData: any = '';
  fileToUpload: any = null;
  bodyRequestData: any = {};
  bodyDataRequestu: any = {};
  bodyDataRequestc: any = {};
  bodyDataRequestca: any = {};
  tutorList: any = [];
  localData: any = [];
  userData: any = {};
  result: any = 0;
  today_date: any = new Date();
  namefield: any;
  lastname: any;
  dob: any;
  email: any;
  phone: any;
  bio: any;
  reason: any;
  // User_Data: any;
  ProfileStep1: boolean = true;
  ProfileStep2: boolean = false;
  ProfileStep3: boolean = false;
  ProfileStep4: boolean = false;
  ProfileStep5: boolean = false;
  ProfileStep6: boolean = false;
  ProfileStep7: boolean = false;
  createuser: FormGroup | any;
  public submitteed = false;
  createuserb: FormGroup | any;
  public submittedb = false;

  createuser1: FormGroup | any;
  public submitted1 = false;
  createuser2: FormGroup | any;
  public submitted2 = false;
  createuser3: FormGroup | any;
  public submitted3 = false;
  createuser4: FormGroup | any;
  public submitted4 = false;
  createuser5: FormGroup | any;
  public submitted5 = false;
  createuser6: FormGroup | any;
  public submitted6 = false;
  createuser7: FormGroup | any;
  public submitted7 = false;
  createuser8: FormGroup | any;
  public submitted8 = false;
  createuser9: FormGroup | any;
  public submitted9 = false;
  createuser10: FormGroup | any;
  public submitted10 = false;
  createuser11: FormGroup | any;
  public submitted11 = false;
  createuser12: FormGroup | any;
  public submitted12 = false;
  createuser13: FormGroup | any;
  public submitted13 = false;
  createuser14: FormGroup | any;
  public submitted14 = false;
  bankdetail: FormGroup | any;
  public submitted15 = false;
  list: any;
  total_count_complete: any;
  total_count_upcomming: any;
  total_count_cancel: any;
  total_count_payout: any;
  category_id1: any;
  level_id1: any;
  quiz_title1: any;
  quiz_description1: any;
  exam_date1: any;
  question_doc1: any;
  exam_time1: any;
  req_amount: any;
  get f1() {
    return this.createuser1.controls;
  }
  get f2() {
    return this.createuser2.controls;
  }
  get f3() {
    return this.createuser3.controls;
  }
  get f4() {
    return this.createuser4.controls;
  }
  get f5() {
    return this.createuser5.controls;
  }
  get f6() {
    return this.createuser6.controls;
  }
  get f7() {
    return this.createuser7.controls;
  }
  get f8() {
    return this.createuser8.controls;
  }
  get f9() {
    return this.createuser9.controls;
  }
  get f10() {
    return this.createuser10.controls;
  }
  get f11() {
    return this.createuser11.controls;
  }
  get f12() {
    return this.createuser12.controls;
  }
  get f13() {
    return this.createuser13.controls;
  }
  get f14() {
    return this.createuser14.controls;
  }
  get f15() {
    return this.bankdetail.controls;
  }
  get fbi() {
    return this.createuserb.controls;
  }

  User_Data: any;
  SchedulerList: any;
  AddForm: FormGroup | undefined;
  Id: any;
  UserData: any;
  submitted = false;
  btnsubmitted = false;
  flag: boolean = false;
  user_status: any;
  Edit_Flag: boolean = false;
  public Teacher_Details_Id: any;
  role: any;
  SchedulerSlotList: any;
  SchedulerSlotID: any;
  Date_Select_Flag: boolean = true;
  student_id_for_socecard: any;
  p: number = 1;
  pu: number = 1;
  pc: number = 1;
  pca: number = 1;
  total_count: any;
  bodyDataRequest: any = {};
  get f() {
    return this.createuser.controls;
  }

  // Array1: any = []; Array2: any = []; Array3: any = []; Array4: any = []; Array5: any = []; Array6: any = []; Array7: any = []; Array8: any = []; Array9: any = []; Array10: any = [];

  // Array11: any = []; Array12: any = []; Array13: any = []; Array14: any = []; Array15: any = []; Array16: any = []; Array17: any = []; Array18: any = []; Array19: any = []; Array20: any = [];

  // Array21: any = []; Array22: any = []; Array23: any = []; Array24: any = []; Array25: any = []; Array26: any = []; Array27: any = []; Array28: any = []; Array29: any = []; Array30: any = [];

  constructor(
    private fb: FormBuilder,
    public apiService: ApiService,
    public messageService: MessageService,
    public sanitizer: DomSanitizer,
    private router: Router,
    public _datePipe: DatePipe,
    public commonService: CommonService
  ) {
    this.commonService.SetFooterContent(false);
    if (localStorage.getItem('user')) {
      var jsonData: any = localStorage.getItem('user');
      this.localData = JSON.parse(jsonData);
      this.list = this.localData.id;

      this.userData = this.localData;
      this.userData.name = this.localData.name;
      this.userData.last_name = this.localData.last_name;
      this.userData.email = this.localData.email;
      this.userData.phone = this.localData.phone;
      this.userData.state = this.localData.state;
      this.userData.city = this.localData.city;
      this.userData.about = this.localData.about;
      this.userData.dob = this.localData.dob;
      this.userData.job_type = this.localData.job_type;
      this.userData.work_experience = this.localData.work_experience;
      this.userData.age_group_id = this.localData.age_group_id;
      this.userData.rate = this.localData.rate;
      this.userData.state = this.localData.state;
      this.userData.city = this.localData.city;
      this.userData.Completed_l = this.localData.Completed_l;
      this.userData.languages = this.localData.languages;
      this.userData.achievement = this.localData.achievement;
      this.userData.bio = this.localData.bio;

      this.userData.phone_code = this.localData.phone_code;
      if (
        this.localData.image != '' ||
        this.localData.image != null ||
        this.localData.image != undefined
      ) {
        this.uploadedImagesData = this.localData.image;
      }
    }
  }
  public Index_Value: number = 0;
  createItem() {
    return this.fb.group({
      institution_name: new FormControl('Wall 1'),

      course_name: new FormControl('0'),
      year: new FormControl(''),
    });
  }
  CountryList: any = [];
  getCountryList() {
    var sendrequestinput: any = { id: 1 };
    this.apiService
      .post('country_list', sendrequestinput)
      .subscribe((data: any) => {
        this.CountryList = data.data;
      });
  }
   
  ngOnInit(): void {
    this.countryList();
    this.getLevelList();

    this.bodyDataRequest['start'] = '0';
    this.bodyDataRequest['limit'] = '20';
    this.bodyDataRequestc['start'] = '0';
    this.bodyDataRequestc['limit'] = '5';
    this.bodyDataRequestu['start'] = '0';
    this.bodyDataRequestu['limit'] = '5';
    this.bodyDataRequestca['start'] = '0';
    this.bodyDataRequestca['limit'] = '5';
    this.bodyDataRequest['learning'] = '0';
    this.bodyDataRequest['keyword'] = this.keyValue;
    this.getMyClass(this.bodyDataRequest);

    this.getMyClass_cancel(this.bodyDataRequestca);
    this.getMyClass_upcomming(this.bodyDataRequestu);
    this.getMyClass_complete(this.bodyDataRequestc);

    this.student_performance();
    // this.generate_jwt()
    // this.merithub_new_classes()
    this.getCountryList();
    this.count_complete_lession();
    this.GetDocumentList();

    this.commonService.GetUpdateProfileChanged().subscribe((data) => {
      if (data) {
        this.getMyClass(this.bodyDataRequest);

        this.getUserDetails(this.localData.id);
        // this.getMyClass_cancel(this.bodyDataRequest);
        // this.getMyClass_upcomming(this.bodyDataRequest);
        // this.getMyClass_complete(this.bodyDataRequest);
      }
    });
    this.getUserDetails(this.localData.id);
    this.createuser1 = this.fb.group({
      image: new FormControl('', [Validators.required]),
    });
    this.createuserb = this.fb.group({
      billing_address: new FormControl(''),
      b_last_name: new FormControl(''),
      b_name: new FormControl(''),
      bcountry: new FormControl(''),
      bcity: new FormControl(''),
      bpcode: new FormControl(''),
      bstate: new FormControl(''),
      user_id: new FormControl(''),
    });
    this.createuser2 = this.fb.group({
      description: new FormControl('', [Validators.required]),
      work_experience: new FormControl('', [Validators.required]),
      achievement: new FormControl('', [Validators.required]),
    });
    this.createuser3 = this.fb.group({
      address: new FormControl('', [Validators.required]),
      pcode: new FormControl('', [Validators.required]),
      time_zone: new FormControl(''),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
    });
    this.createuser4 = this.fb.group({
      university: new FormControl('', [Validators.required]),
      degree: new FormControl('', [Validators.required]),
      completion_year: new FormControl('', [Validators.required]),
      id: new FormControl(''),
    });
    this.createuser5 = this.fb.group({
      certificate_title: new FormControl('', [Validators.required]),
      completion_year: new FormControl('', [Validators.required]),
      id: new FormControl(''),
    });
    this.createuser6 = this.fb.group({
      work_experience: new FormControl('', [Validators.required]),
      bio: new FormControl('', [Validators.required]),
      qualification_certifications: new FormControl('', [Validators.required]),
      Completed_l: new FormControl('', [Validators.required]),
      achievement: new FormControl('', [Validators.required]),
      languages: new FormControl('', [Validators.required]),
      id: new FormControl(''),
    });
    this.createuser7 = this.fb.group({
      bank_details: new FormControl('', [Validators.required]),
    });
    this.createuser8 = this.fb.group({
      name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone_code: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      image: new FormControl(''),
    });
    this.createuser9 = this.fb.group({
      job_type: new FormControl('', [Validators.required]),
    });
    this.createuser10 = this.fb.group({
      course_name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      image: new FormControl(''),
      course_type: new FormControl('', [Validators.required]),
      age_group_id: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
      curriculum: new FormControl('', [Validators.required]),
      price_per_classes: new FormControl('', [Validators.required]),
      fake_price: new FormControl('', [Validators.required]),
      no_of_classes: new FormControl('', [Validators.required]),
      hands_on_activities: new FormControl('', [Validators.required]),
    });
    this.createuser11 = this.fb.group({
      document_name: new FormControl('', [Validators.required]),
      document: new FormControl('', [Validators.required]),
      id: new FormControl(''),
    });
    this.createuser12 = this.fb.group({
      category_id: new FormControl('', [Validators.required]),
      level_id: new FormControl('', [Validators.required]),
      quiz_title: new FormControl('', [Validators.required]),
      quiz_description: new FormControl('', ),
      exam_date: new FormControl('', [Validators.required]),
      exam_time: new FormControl('', [Validators.required]),
      // question_doc: new FormControl('',[Validators.required]),
      // answer_doc: new FormControl(''),
      id: new FormControl(''),
    });
    
    this.createuser13 = this.fb.group({
      l_understanding: new FormControl('', [Validators.required]),
      remory_retention: new FormControl('', [Validators.required]),
      analytical_skills: new FormControl('', [Validators.required]),
      problem_solving: new FormControl('', [Validators.required]),
      mindfullness: new FormControl('', [Validators.required]),
      engagement: new FormControl('', [Validators.required]),
      willingness: new FormControl('', [Validators.required]),
      persaverance: new FormControl('', [Validators.required]),
      collaboration: new FormControl('', [Validators.required]),
      strengths: new FormControl('', [Validators.required]),
      Improvement: new FormControl('', [Validators.required]),
      moving_forward: new FormControl('', [Validators.required]),
      t_ans: new FormControl('', [Validators.required]),
      s_ans: new FormControl('', [Validators.required]),
      classes: new FormControl('', [Validators.required]),

      id: new FormControl(''),
    });
    this.createuser14 = this.fb.group({
      job_type: ['', Validators.required],
      work_experience: ['', Validators.required],
      age_group_id: ['', Validators.required],
      rate: ['', Validators.required],
      id: new FormControl(''),
    });
    this.bankdetail = this.fb.group({
      acc_number: new FormControl(''),
      bank_sort_code: new FormControl(''),
      iban: new FormControl(''),
      bic: new FormControl(''),
    });
    this.createuser = this.fb.group(
      {
        password: [
          null,
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
          ],
        ],
        current_password: [
          null,
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
          ],
        ],
        reenterpassword: ['', Validators.required],
      },
      {
        validator: PasswordMatchPattern('password', 'reenterpassword'),
      }
    );

    this.GetSchedulerList();
    this.Gettutor_payout(this.bodyDataRequest);
    this.GetCourseList();
    this.getTeacherSubject();
    this.GetQuestionList();
    this.ans_quizzes_sheet();
    this.teacher_review_rating();
  }

  CategoryList: any = [];
  getTeacherSubject() {
    var sendrequestinput: any = { id: 1 };
    this.apiService
      .post('category_list', sendrequestinput)
      .subscribe((data: any) => {
        this.CategoryList = data.data;
      });
  }
  uploadFileToActivity(image: any) {
    this.apiService.postFile(image).subscribe(
      (data: any) => {
        this.uploadedImagesData = data.name;
        this.userData.image = data.name;
      },
      (error) => {
      }
    );
  }
  getUserDetails(id: any) {
    this.apiService
      .post('teacher_details', { user_id: id })
      .subscribe((data: any) => {
        if (data.replyCode == 'success') {
          this.user_status = data.data.status;
          this.userData = data.data;

          const vacationPayPercentage = 12;
          const employerTaxPercentage = 31.42;
          const valueAddedTaxPercentage = 25;
          const adminFeePercentage = 20;
          
          let rate = this.userData.rate;
          
          rate = rate / (1 + adminFeePercentage / 100);
          
          rate = rate / (1 + valueAddedTaxPercentage / 100);
          
          rate = rate / (1 + employerTaxPercentage / 100);
          
          rate = rate / (1 + vacationPayPercentage / 100);

          this.userData.rate = Math.floor(rate);

          this.createuser6.controls['work_experience'].setValue(
            this.userData.work_experience
          );
          this.createuser6.controls['bio'].setValue(this.userData.bio);
          this.createuser6.controls['qualification_certifications'].setValue(
            this.userData.qualification_certifications
          );
          this.createuser6.controls['Completed_l'].setValue(
            this.userData.Completed_l
          );
          this.createuser6.controls['achievement'].setValue(
            this.userData.achievement
          );
          this.createuser6.controls['languages'].setValue(
            this.userData.languages
          );
          this.createuser3.controls['address'].setValue(this.userData.address);
          this.createuser3.controls['time_zone'].setValue(
            this.userData.time_zone
          );
          this.createuser3.controls['country'].setValue(this.userData.country);
          this.createuser3.controls['state'].setValue(this.userData.state);
          this.createuser3.controls['city'].setValue(this.userData.city);
          this.createuser3.controls['pcode'].setValue(this.userData.pcode);
          this.createuser8.controls['name'].setValue(this.userData.name);
          this.createuser8.controls['email'].setValue(this.userData.email);
          this.createuser8.controls['phone'].setValue(this.userData.phone);
          this.createuser9.controls['job_type'].setValue(
            this.userData.job_type
          );
          this.createuserb.controls['b_name'].setValue(this.userData.b_name);
          this.bankdetail.controls['acc_number'].setValue(
            this.userData.acc_number
          );
          this.bankdetail.controls['bank_sort_code'].setValue(
            this.userData.bank_sort_code
          );
          this.bankdetail.controls['iban'].setValue(this.userData.iban);
          this.bankdetail.controls['bic'].setValue(this.userData.bic);

          this.createuserb.controls['b_last_name'].setValue(
            this.userData.b_last_name
          );

          this.createuserb.controls['bcountry'].setValue(
            this.userData.bcountry
          );

          this.createuserb.controls['bstate'].setValue(this.userData.bstate);

          this.createuserb.controls['bcity'].setValue(this.userData.bcity);

          this.createuserb.controls['bpcode'].setValue(this.userData.bpcode);

          this.createuserb.controls['billing_address'].setValue(
            this.userData.billing_address
          );

          if (
            this.userData.image != '' ||
            this.userData.image != null ||
            this.userData.image != undefined
          ) {
            this.uploadedImagesData = this.userData.image;
          }
          // this.onChangeState(this.userData.state);
          if (
            this.userData.country != '' ||
            this.userData.country != undefined ||
            this.userData.country != null
          ) {
            this.onSetValue(this.userData.country, this.userData.state);
          }
        }
      });
  }
  onSetValue(country_id: any, state_id: any) {
    var sendrequestinput: any = { country_id: country_id };
    this.apiService
      .post('state_list', sendrequestinput)
      .subscribe((data: any) => {
        this.loadstateList = data.data;
        if (this.loadstateList.length > 0) {
          this.onSetCity(state_id);
        }
      });
  }
  allowurl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  onSelectFile(file: any) {
    if(file.files[0].type=="image/jpeg" || file.files[0].type=="image/png" || file.files[0].type=="image/jpg"){

    this.uploadFileToActivity(file.files[0]);
    if (file.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(file.files[0]);
      reader.onload = (event) => {};
    }
    }else{
      this.messageService.showError("Select only .jpeg, jpg or.png");

    }
  }
  onChangePassword() {
    this.createuser.get('current_password').markAsTouched();
    this.createuser.get('password').markAsTouched();
    this.createuser.get('reenterpassword').markAsTouched();
    this.submitteed = true;
    if (this.createuser.invalid) {
      return;
    } else {
      var sendrequestinput: any = this.createuser.value;
      sendrequestinput.sid = localStorage.getItem('sid');

      this.apiService
        .post('change_password', sendrequestinput)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.createuser.reset();
            this.submitteed = false;
            this.messageService.showSuccess(data.replyMsg);
          } else {
            this.messageService.showError(data.replyMsg);
          }
        });
    }
  }
  onUpdateProfile() {
    if (this.userData.name == '') {
      this.namefield = 'First name is required';
      this.messageService.showError('First name is required');
    }
    if (this.userData.last_name == '') {
      this.lastname = 'Last name is required';
      this.messageService.showError('Last name is required');
    }
    // if (this.userData.dob == '') {
    //   this.dob = 'Date of Birth';
    //   this.messageService.showError('Date of Birth is required');
    // }
    if (this.userData.email == '') {
      this.email = 'Email is required';
      this.messageService.showError('Email is required');
    }
    
    if (this.userData.phone == '') {
      this.phone = 'Mobile number is required';
      this.messageService.showError('Mobile number is required');
    }
    if (this.userData.phone == '' && this.phone =="Please enter a valid number") {
      this.phone = 'Mobile number is required';
      this.messageService.showError('Mobile number is required');
    }
    // if (this.userData.bio == '') {
    //   this.bio = 'Please share a few tips about yourself and great aspirations';
    //   this.messageService.showError(
    //     'Please share a few tips about yourself and great aspirations'
    //   );
    // }
    this.userData.age = 0;

    if (
      this.userData.name != '' &&
      this.userData.last_name != '' &&
      this.userData.email != '' &&
      this.userData.phone != '' &&
      this.userData.dob != '' && this.phone !="Please enter a valid number"
      // this.userData.about != ''
    ) {
      this.apiService.post('update_user', this.userData).subscribe((data: any) => {
          if (data.replyCode == 'success') {
              this.namefield =""
              this.lastname =""
              this.dob =""
              this.email =""
              this.phone =""
            localStorage.removeItem('user');
            var json = JSON.stringify(this.userData);
            localStorage.setItem('user', json);
            this.messageService.showSuccess(data.replyMsg);
            window.location.reload()
            // this.router.navigate(['/tutor-edit-profile']);
          } else {
            this.messageService.showError(data.replyMsg);
          }
        });
    }
    else this.messageService.showError('complete all field');
    // this.apiService.post('update_user', this.userData).subscribe((data: any) => {
    //   if (data.replyCode == "success") {
    //     localStorage.removeItem('user')
    //     var json = JSON.stringify(this.userData);
    //     localStorage.setItem('user', json)
    //     this.messageService.showSuccess(data.replyMsg);
    //     this.router.navigate(['/student-profile'])
    //   } else {
    //     this.messageService.showError(data.replyMsg);
    //   }
    // })
  }
  loadcountryList: any = [];
  countryList() {
    var sendrequestinput: any = {};
    this.apiService
      .post('country_list', sendrequestinput)
      .subscribe((data: any) => {
        this.loadcountryList = data.data;
      });
  }
  loadstateList: any = [];
  onChangeCountry(event: any) {
    var sendrequestinput: any = { country_id: event.value };
    this.apiService
      .post('state_list', sendrequestinput)
      .subscribe((data: any) => {
        this.loadstateList = data.data;
      });
  }
  loadcityList: any = [];
  onChangeState(event: any) {
    var sendrequestinput: any = { state_id: event.value };
    this.apiService
      .post('city_list', sendrequestinput)
      .subscribe((data: any) => {
        this.loadcityList = data.data;
      });
  }
  onSetCity(event: any) {
    var sendrequestinput: any = { state_id: event };
    this.apiService
      .post('city_list', sendrequestinput)
      .subscribe((data: any) => {
        this.loadcityList = data.data;
      });
  }
  onChangeStep(type: any) {
    if (type == 1) {
      this.submitted1 = true;
      this.createuser1.controls['image'].setValue(this.uploadedImagesData);
      if (this.createuser1.invalid) {
        this.messageService.showWarning('Please select an image');
        return;
      } else {
        this.ProfileStep1 = false;
        this.ProfileStep3 = true;
        alert('done1');
        this.localData.image = this.uploadedImagesData;
        this.apiService
          .post('update_user', this.localData)
          .subscribe((data: any) => {
            if (data.replyCode == 'success') {
              this.getUserDetails(this.localData.id);
              localStorage.removeItem('user');
              var json = JSON.stringify(this.localData);
              localStorage.setItem('user', json);
              this.messageService.showSuccess(data.replyMsg);
              // this.router.navigate(['/student-profile'])
            } else {
              this.messageService.showError(data.replyMsg);
            }
          });
      }
    } else if (type == 2) {
      this.submitted2 = true;
      this.createuser2.controls['image'].setValue(this.uploadedImagesData);
      if (this.createuser2.invalid) {
        this.messageService.showWarning('Please select an image');
        return;
      } else {
        this.ProfileStep2 = false;
        this.ProfileStep3 = true;
        alert('done2');
        this.getUserDetails(this.localData.id);
      }
    } else if (type == 3) {
      this.submitted3 = true;
      if (this.createuser3.invalid) {
        this.messageService.showWarning('Please fill all the field');
        return;
      } else {
        this.ProfileStep3 = false;
        this.ProfileStep4 = true;
        alert('done3');
        var sendRequestData = this.createuser3.value;
        sendRequestData.user_id = this.localData.id;
        this.apiService
          .post('update_profile_address', sendRequestData)
          .subscribe((data: any) => {
            if (data.replyCode == 'success') {
              this.getUserDetails(this.localData.id);
              this.messageService.showSuccess(data.replyMsg);
            } else {
              this.messageService.showWarning(data.replyMsg);
            }
          });
      }
    } else if (type == 4) {
      this.submitted4 = true;
      if (this.createuser4.invalid) {
        this.messageService.showWarning('Please fill all the field');
        return;
      } else {
        this.ProfileStep4 = false;
        this.ProfileStep5 = true;
        alert('done4');
        var sendRequestData = this.createuser4.value;
        sendRequestData.user_id = this.localData.id;
        this.apiService
          .post('add_teacher_education', sendRequestData)
          .subscribe((data: any) => {
            if (data.replyCode == 'success') {
              this.getUserDetails(this.localData.id);
              this.messageService.showSuccess(data.replyMsg);           

            } else {
              this.messageService.showWarning(data.replyMsg);
            }
          });
      }
    } else if (type == 5) {
      this.submitted5 = true;
      if (this.createuser5.invalid) {
        this.messageService.showWarning('Please fill all the field');
        return;
      } else {
        this.ProfileStep5 = false;
        this.ProfileStep6 = true;
        var sendRequestData = this.createuser5.value;
        sendRequestData.user_id = this.localData.id;
        this.apiService
          .post('add_teacher_certificate', sendRequestData)
          .subscribe((data: any) => {
            
            if (data.replyCode == 'success') {
              this.getUserDetails(this.localData.id);
              this.messageService.showSuccess(data.replyMsg);
            } else {
              this.messageService.showWarning(data.replyMsg);
            }
          });
      }
    } else if (type == 6) {
      this.submitted6 = true;
      if (this.createuser6.invalid) {
        this.messageService.showWarning('Please fill all the field');
        return;
      } else {
        this.ProfileStep6 = false;
        this.ProfileStep7 = true;
        alert('done6');
        var sendRequestData = this.createuser6.value;
        sendRequestData.user_id = this.localData.id;
        this.apiService
          .post('update_teacher_bio', sendRequestData)
          .subscribe((data: any) => {
            if (data.replyCode == 'success') {
              this.getUserDetails(this.localData.id);
              this.messageService.showSuccess(data.replyMsg);
            } else {
              this.messageService.showWarning(data.replyMsg);
            }
          });
      }
    } else if (type == 7) {
      this.submitted7 = true;
      if (this.createuser7.invalid) {
        this.messageService.showWarning('Please fill all the field');
        return;
      } else {
        this.ProfileStep7 = false;
        // this.ProfileStep7=true;
        alert('done7');
        var sendRequestData = this.createuser7.value;
        sendRequestData.user_id = this.localData.id;
        this.apiService
          .post('update_bank_details', sendRequestData)
          .subscribe((data: any) => {
            if (data.replyCode == 'success') {
              this.getUserDetails(this.localData.id);
              this.messageService.showSuccess(data.replyMsg);
            } else {
              this.messageService.showWarning(data.replyMsg);
            }
          });
      }
    }
  }
  onChangeStepBack(type: any) {
    if (type == 1) {
      this.ProfileStep1 = true;
      this.ProfileStep2 = false;
      this.ProfileStep3 = false;
      this.ProfileStep4 = false;
      this.ProfileStep5 = false;
      this.ProfileStep6 = false;
      this.ProfileStep7 = false;
    } else if (type == 2) {
      this.ProfileStep1 = true;
      this.ProfileStep2 = false;
      this.ProfileStep3 = false;
      this.ProfileStep4 = false;
      this.ProfileStep5 = false;
      this.ProfileStep6 = false;
      this.ProfileStep7 = false;
    } else if (type == 3) {
      this.ProfileStep1 = true;
      this.ProfileStep2 = false;
      this.ProfileStep3 = false;
      this.ProfileStep4 = false;
      this.ProfileStep5 = false;
      this.ProfileStep6 = false;
      this.ProfileStep7 = false;
    } else if (type == 4) {
      this.ProfileStep1 = false;
      this.ProfileStep2 = false;
      this.ProfileStep3 = true;
      this.ProfileStep4 = false;
      this.ProfileStep5 = false;
      this.ProfileStep6 = false;
      this.ProfileStep7 = false;
    } else if (type == 5) {
      this.ProfileStep1 = false;
      this.ProfileStep2 = false;
      this.ProfileStep3 = false;
      this.ProfileStep4 = true;
      this.ProfileStep5 = false;
      this.ProfileStep6 = false;
      this.ProfileStep7 = false;
    } else if (type == 6) {
      this.ProfileStep1 = false;
      this.ProfileStep2 = false;
      this.ProfileStep3 = false;
      this.ProfileStep4 = false;
      this.ProfileStep5 = true;
      this.ProfileStep6 = false;
      this.ProfileStep7 = false;
    } else if (type == 7) {
      this.ProfileStep1 = false;
      this.ProfileStep2 = false;
      this.ProfileStep3 = false;
      this.ProfileStep4 = false;
      this.ProfileStep5 = false;
      this.ProfileStep6 = true;
      this.ProfileStep7 = false;
    }
  }
  Content_Type: any = 'Edit and Update Profile';
  onChangeTypeContent(value: any) {
    this.Content_Type = value;
    this.getMyClass_upcomming(this.bodyDataRequest);
  }
  address: any;
  city: any;
  country: any;
  state: any;
  time_zone: any;
  onSubmitAddress() {
    if(this.createuser3.value.address==null){

    }else{
    }
    
    if (this.createuser3.value.address == '' || this.createuser3.value.address ==null) {
      this.address = 'Select address field';
    }
    if (this.createuser3.value.city == '') {
      this.city = 'Select city field';
    }
    if (this.createuser3.value.country == '') {
      this.country = 'Select country field';
    }
    if (this.createuser3.value.state == '') {
      this.state = 'Select state field';
    }
 
    this.submitted3 = true;
    if (this.createuser3.invalid) {
      this.messageService.showWarning('Please fill all the field');
      return;
    } else {
      this.ProfileStep3 = false;
      this.ProfileStep4 = true;
      // alert('done3');
      var sendRequestData = this.createuser3.value;
      sendRequestData.user_id = this.localData.id;
      this.apiService
        .post('update_profile_address', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.getUserDetails(this.localData.id);
            this.messageService.showSuccess(data.replyMsg);
          } else {
            this.messageService.showWarning(data.replyMsg);
          }
        });
    }
  }
  onOpenLogout() {
    this.commonService.SetLogoutPopup(true);
  }
  onSubmitInfo() {
    this.submitted6 = true;

    if (this.createuser6.invalid) {
      this.messageService.showWarning('Please fill all the field');
      return;
    } else {
      this.ProfileStep6 = false;
      this.ProfileStep7 = true;
      // alert('done6');
      var sendRequestData = this.createuser6.value;
      sendRequestData.user_id = this.localData.id;
      this.apiService
        .post('update_teacher_bio1', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.getUserDetails(this.localData.id);
            this.messageService.showSuccess("Tutor’s bio updated successfully");
          } else {
            this.messageService.showWarning(data.replyMsg);
          }
        });
    }
  }
  count_lession:any;
  count_complete_lession(){
    var sendrequestinput:any={id:this.localData.id};
    this.apiService.post('count_complete_lession',sendrequestinput).subscribe((data:any) => {
      
      this.count_lession=data.data.total;
    })
  }
  onSubmitQualification() {
    // return
    this.submitted4 = true;
    
    if (this.createuser4.invalid) {
      this.messageService.showWarning('Please fill all the field');
      return;
    } else {
      this.ProfileStep4 = false;
      this.ProfileStep5 = true;
      // alert('done4');
      var sendRequestData = this.createuser4.value;
      sendRequestData.user_id = this.localData.id;
      this.apiService
        .post('add_teacher_education', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.getUserDetails(this.localData.id);
            this.messageService.showSuccess(data.replyMsg);
            this.createuser4.reset();
              
            this.createuser4 = false;
          } else {
            this.messageService.showWarning(data.replyMsg);
          }
        });
    }
  }
  update_rate() {
    this.submitted14 = true;
    if (this.createuser14.invalid) {
      this.messageService.showWarning('Please fill all the field');
      return;
    } else {
      var sendRequestData = this.createuser14.value;
      sendRequestData.user_id = this.localData.id;
      this.apiService
        .post('update_teacher_bio', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.getUserDetails(this.localData.id);
            this.messageService.showSuccess(data.replyMsg);
          } else {
            this.messageService.showWarning(data.replyMsg);
          }
        });
    }
  }

  onSubmitpersonalInfo() {
    this.submitted8 = true;
    this.createuser8.controls['image'].setValue(this.uploadedImagesData);
    if (this.createuser8.invalid) {
      return;
    } else {
      var sendRequestData: any = {};
      sendRequestData = this.userData;
      sendRequestData.name = this.createuser8.value.name;
      sendRequestData.last_name = this.createuser8.value.last_name;
      sendRequestData.phone = this.createuser8.value.phone;
      sendRequestData.phone_code = this.createuser8.value.phone_code;
      sendRequestData.image = this.uploadedImagesData;
      this.apiService
        .post('update_user', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.getUserDetails(this.localData.id);
            this.messageService.showSuccess(data.replyMsg);
          } else {
            this.messageService.showError(data.replyMsg);
          }
        });
    }
  }
  onSubmitJobType() {
    this.submitted9 = true;
    if (this.createuser9.invalid) {
      return;
    } else {
      var sendRequestData: any = {};
      sendRequestData = this.userData;
      sendRequestData.job_type = this.createuser9.value.job_type;
      this.apiService
        .post('update_user', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.getUserDetails(this.localData.id);
            this.messageService.showSuccess(data.replyMsg);
          } else {
            this.messageService.showError(data.replyMsg);
          }
        });
    }
  }
  menuClick(id: any) {
    if ($('#menu-' + id).hasClass('open')) {
      $('#menu-' + id).removeClass('open');
      // $('body').removeClass('layout-expanded');
    } else {
      $('.sidenav-item').removeClass('open');
      $('#menu-' + id).addClass('open');
      // $('body').removeClass('layout-expanded');
    }
  }
  Schedulerslotid: any;
  // slotid(data:any){

  //   // this.Schedulerslotid=data.value
  //   var requestData = {
  //     user_id: this.userData.id,
  //     schedule_id: data.value
  //   }
  //   this.Date_Select_Flag = false;
  //   this.apiService.post('teacher_schedule_slots_list', requestData).subscribe((res: any) => {
  //     if (!res.error) {
  //       if (res.replyCode == 'success') {
  //         if (res.data.length > 0) {
  //           this.SchedulerSlotList = res.data;
  //         } else {
  //           this.Teacher_Details_Id = '';
  //           this.Edit_Flag = true;
  //         }
  //       }
  //     }
  //   }, error => {
  //     this.messageService.showError("Oops! Something went wrong!");
  //   });
  // }
  lastdate: any;
  utcDate2: any;
  date: any = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
  GetSchedulerList() {
   

    this.apiService
      .post('teacher_schedule_days_list', { user_id: this.userData.id })
      .subscribe(
        (res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              if (res.data.length > 0) {
                this.SchedulerList = res.data;
                this.lastdate =
                  this.SchedulerList[0].schedule_date;
                this.utcDate2 = formatDate(
                  new Date(
                    this.SchedulerList[0].schedule_date
                  ),
                  'yyyy-MM-dd',
                  'en_US'
                );
                // if (formatDate( this.date,'yyyy-MM-dd','en_US') > formatDate(this.utcDate2,'yyyy-MM-dd','en_US'))
                //   else

                if (this.SchedulerList.available == 1) {
                  // this.onDateSelect(this.SchedulerList.available,this.SchedulerSlotID,this.Index_Value);
                } else {
                  // this.onDateSelect(this.SchedulerSlotID,this.Index_Value);
                }
              } else {
                this.Teacher_Details_Id = '';
                this.Edit_Flag = true;
              }
            }
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
  }
  CourseList: any = [];
  GetCourseList() {
    this.apiService
      .post('courses_list', { created_by: this.userData.id })
      .subscribe(
        (res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              if (res.data.length > 0) {
                this.CourseList = res.data;
              } else {
                // this.Teacher_Details_Id ='';
                // this.Edit_Flag=true;
              }
            }
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
  }
  DocumentList: any = [];
  GetDocumentList() {
    this.apiService
      .post('user_documents_list', { user_id: this.userData.id })
      .subscribe(
        (res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              if (res.data.length > 0) {

                for (let index = 0; index < res.data.length; index++) {
                  this.DocumentList[res.data[index].document_name] = res.data[index];
                }
               // this.DocumentList = res.data;
              } else {
                // this.Teacher_Details_Id ='';
                // this.Edit_Flag=true;
              }
            }
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
  }
  onDateSelect(flag: any, id: any, index: any) {
    if (flag == '0') {
      this.SchedulerSlotID = id;
      this.Index_Value = index;
      if ($('#menu-' + index).hasClass('clickable')) {
        $('#menu-' + index).removeClass('clickable');
      } else {
        $('.sidenav-item').removeClass('clickable');
        $('#menu-' + index).addClass('clickable');
      }
      var requestData = {
        user_id: this.userData.id,
        schedule_id: id,
      };
      this.Date_Select_Flag = false;
      this.apiService
        .post('teacher_schedule_slots_list', requestData)
        .subscribe(
          (res: any) => {
            if (!res.error) {
              if (res.replyCode == 'success') {
                if (res.data.length > 0) {
                  this.SchedulerSlotList = res.data;
                } else {
                  this.Teacher_Details_Id = '';
                  this.Edit_Flag = true;
                }
              }
            }
          },
          (error) => {
            this.messageService.showError('Oops! Something went wrong!');
          }
        );
    } else {
      this.Index_Value = 0;
      this.Date_Select_Flag = true;
    }
  }
  onChangeStatusbackup(object: any, value: any, index: any) {

    this.SchedulerSlotID = object.id;
    this.Index_Value = index;
    var available;
    if (value == true) {
      available = '1';
    } else {
      available = '0';
    }
    var requestData = {
      id: this.SchedulerSlotID,
      available: available,
    };
    this.apiService.post('update_schedule_day_status', requestData).subscribe(
      (res: any) => {
        if (!res.error) {
          if (res.replyCode == 'error') {
            this.messageService.showError(res.replyMsg);
          } else {
            this.GetSchedulerList();
            this.messageService.showSuccess(res.replyMsg);
            // if(value == true){
            this.onDateSelect(
              object.available,
              this.SchedulerSlotID,
              this.Index_Value
            );
          }

          // }
        } else {
          this.messageService.showError(res.replyMsg);
        }
      },
      (error) => {
        this.messageService.showError('Oops! Something went wrong!');
      }
    );
  }
  onChangeSlotStatusbackup(id: any, value: any) {
    var available;
    if (value == true) {
      available = '1';
    } else {
      available = '0';
    }
    var requestData = {
      id: id,
      available: available,
    };
    this.apiService.post('update_schedule_slot_status', requestData).subscribe(
      (res: any) => {
        if (!res.error) {
          this.GetSchedulerList();
          this.messageService.showSuccess(res.replyMsg);
        } else {
          this.messageService.showError(res.replyMsg);
        }
      },
      (error) => {
        this.messageService.showError('Oops! Something went wrong!');
      }
    );
  }

  onChangeStatus(object: any, event: any) {
    let VALUE = event.checked;
    this.SchedulerSlotID = object.id;
    // this.Index_Value=index;
    var available;
    if (VALUE == true) {
      available = '1';
    } else {
      available = '0';
    }
    var requestData = {
      id: this.SchedulerSlotID,
      available: available,
    };
    this.apiService.post('update_schedule_day_status', requestData).subscribe(
      (res: any) => {
        if (!res.error) {
          if (res.replyCode == 'error') {
            this.messageService.showError(res.replyMsg);
          } else {
            this.GetSchedulerList();
            this.messageService.showSuccess(res.replyMsg);
            // if(value == true){
            this.onDateSelect(
              object.available,
              this.SchedulerSlotID,
              this.Index_Value
            );
          }

          // }
        } else {
          this.messageService.showError(res.replyMsg);
        }
      },
      (error) => {
        this.messageService.showError('Oops! Something went wrong!');
      }
    );
  }
  onChangeSlotStatus(id: any, event: any) {
    var available;
    let VALUE = event.checked;
    if (VALUE == true) {
      available = '1';
    } else {
      available = '0';
    }
    var requestData = {
      id: id,
      available: available,
    };
    this.apiService.post('update_schedule_slot_status', requestData).subscribe(
      (res: any) => {
        if (!res.error) {
          this.GetSchedulerList();
          this.messageService.showSuccess(res.replyMsg);
        } else {
          this.messageService.showError(res.replyMsg);
        }
      },
      (error) => {
        this.messageService.showError('Oops! Something went wrong!');
      }
    );
  }
  onOpenSubjectModal() {
    this.commonService.SetAddSubject(true);
  }
  addCourseFlag: boolean = false;
  onAddCourse() {
    this.addCourseFlag = true;
  }
  LevelList: any = [];
  getLevelList() {
    var sendrequestinput: any = { id: 1 };
    this.apiService.post('age_group_list_dropdown', sendrequestinput).subscribe((data: any) => {
        this.LevelList = data.data;
      });
  }
  roleId: any;
  delete_user(value: any) {
    this.deleteModal.show();
    this.roleId = value;
  }
  reasontest:any
  reasonfordelete(value:any){
    console.log(value,"reasonfordelete");

    if(value!='')
    this.reasontest=value
    
}
  deleteRecord() {
    var self = this;
    var sendRequestData = { id: self.roleId, status: 2 ,reason:this.reasontest};
    self.apiService.loadingShow = true;

    self.apiService.post('update_user_status', sendRequestData).subscribe(
      (res: any) => {
        self.apiService.loadingShow = false;

        if (res.replyCode == 'success') {
          this.messageService.showSuccess("Account deleted successfully");
          this.deleteModal.hide();
          this.onLogout()

        }
      },
      (error) => {
        self.apiService.loadingShow = false;

      }
    );
  }
  onLogout(){
    localStorage.removeItem('user');
    localStorage.removeItem('sid');
    this.commonService.SetLoggedIn(false);
    this.commonService.SetContentHidden(true);
    this.router.navigate(['/'])
  }
  onSubmitCourse() {
    this.submitted10 = true;
    if (this.createuser10.invalid) {
      return;
    } else {
      var requestData: any = {};
      requestData = this.createuser10.value;
      requestData.created_by = this.userData.id;
      requestData.image = this.uploadedImagesData;
      requestData.id = this.EditCourseId;
      this.apiService.post('add_courses', requestData).subscribe(
        (res: any) => {
          if (!res.error) {
            this.createuser10.reset();
            this.EditCourseId = '';
            this.submitted10 = false;
            this.addCourseFlag = false;
            this.GetCourseList();
            this.messageService.showSuccess(res.replyMsg);
          } else {
            this.messageService.showError(res.replyMsg);
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
    }
  }
  EditCourseId: any = '';
  onEditCourse(courseObject: any) {
    this.addCourseFlag = true;
    this.EditCourseId = courseObject.id;
    this.createuser10.controls['course_name'].setValue(
      courseObject.course_name
    );
    this.createuser10.controls['description'].setValue(
      courseObject.description
    );
    this.createuser10.controls['course_type'].setValue(
      courseObject.course_type
    );
    this.createuser10.controls['age_group_id'].setValue(
      courseObject.age_group_id
    );
    this.createuser10.controls['amount'].setValue(courseObject.amount);
    this.createuser10.controls['curriculum'].setValue(courseObject.curriculum);
    this.createuser10.controls['price_per_classes'].setValue(
      courseObject.price_per_classes
    );
    this.createuser10.controls['fake_price'].setValue(courseObject.fake_price);
    this.createuser10.controls['no_of_classes'].setValue(
      courseObject.no_of_classes
    );
    this.createuser10.controls['hands_on_activities'].setValue(
      courseObject.hands_on_activities
    );
    if (
      courseObject.image != undefined ||
      courseObject.image != null ||
      courseObject.image != ''
    ) {
      this.uploadedImagesData = courseObject.image;
    }
  }
  resetfilter() {
    this.bodyDataRequest['start'] = '0';
    this.bodyDataRequest['keyword'] = '';
    this.getMyClass_cancel(this.bodyDataRequest);
    this.getMyClass_upcomming(this.bodyDataRequest);
    this.getMyClass_complete(this.bodyDataRequest);
    this.getMyClass(this.bodyDataRequest);

    this.Gettutor_payout(this.bodyDataRequest);
  }
  onChangeLimit(limit: any) {
    this.p = 1;
    this.bodyDataRequest['start'] = '0';
    this.bodyDataRequest['limit'] = String(limit);
    // this.getMyClass_cancel(this.bodyDataRequest);
    // this.getMyClass(this.bodyDataRequest);

    // this.getMyClass_upcomming(this.bodyDataRequest);
    // this.getMyClass_complete(this.bodyDataRequest);
    this.Gettutor_payout(this.bodyDataRequest);
  }
  keyValue = '';

  onSearch() {
    this.p = 1;
    this.bodyDataRequest['start'] = '0';
    this.bodyDataRequest['limit'] = '5';
    this.bodyDataRequest['keyword'] = this.keyValue;
    this.getMyClass(this.bodyDataRequest);

    // this.getMyClass_cancel(this.bodyDataRequest);
    // this.getMyClass_upcomming(this.bodyDataRequest);
    // this.getMyClass_complete(this.bodyDataRequest);
    this.Gettutor_payout(this.bodyDataRequest);
  }
  onPageChange(event: any) {
    this.apiService.loadingShow = true;
    this.p = event;
    this.bodyDataRequest['start'] = String(
      event * this.bodyDataRequest['limit'] - this.bodyDataRequest['limit']
    );
    // this.getMyClass_cancel(this.bodyDataRequest);
    // this.getMyClass_complete(this.bodyDataRequest);
    // this.getMyClass_upcomming(this.bodyDataRequest);
    this.Gettutor_payout(this.bodyDataRequest);
    // this.getMyClass(this.bodyDataRequest);


  }
  onChangeLimitupc(limit: any) {
    this.pu = 1;
    this.bodyDataRequestu['start'] = '0';
    this.bodyDataRequestu['limit'] = String(limit);
   
    this.getMyClass_upcomming(this.bodyDataRequestu);
  }

  onPageChangeupc(event: any) {
    this.apiService.loadingShow = true;
    this.pu = event;
    this.bodyDataRequestu['start'] = String(
      event * this.bodyDataRequestu['limit'] - this.bodyDataRequestu['limit']
    );
   
    this.getMyClass_upcomming(this.bodyDataRequestu);
  }
  onChangeLimitcom(limit: any) {
    this.pc = 1;
    this.bodyDataRequestc['start'] = '0';
    this.bodyDataRequestc['limit'] = String(limit);
    
    this.getMyClass_complete(this.bodyDataRequestc);

  }

  onPageChangecom(event: any) {
    this.apiService.loadingShow = true;
    this.pc = event;
    this.bodyDataRequestc['start'] = String(
      event * this.bodyDataRequestc['limit'] - this.bodyDataRequestc['limit']
    );
   
    this.getMyClass_complete(this.bodyDataRequestc);

  }
  onChangeLimitcan(limit: any) {
    this.pca = 1;
    this.bodyDataRequestca['start'] = '0';
    this.bodyDataRequestca['limit'] = String(limit);
   
    this.getMyClass_cancel(this.bodyDataRequestca);

  }

  onPageChangecan(event: any) {
    this.apiService.loadingShow = true;
    this.pca = event;
    this.bodyDataRequestca['start'] = String(
      event * this.bodyDataRequestca['limit'] - this.bodyDataRequestca['limit']
    );
   
    this.getMyClass_cancel(this.bodyDataRequestca);
   
  }
  onChangeCourseStatus(object: any, event: any) {
    let VALUE = event;
    this.SchedulerSlotID = object.id;
    var requestData = {
      id: this.SchedulerSlotID,
      status: VALUE,
    };
    this.apiService.post('update_courses_status', requestData).subscribe(
      (res: any) => {
        if (!res.error) {
          if (res.replyCode == 'error') {
            this.messageService.showError(res.replyMsg);
          } else {
            this.GetCourseList();
            this.messageService.showSuccess(res.replyMsg);
          }
        } else {
          this.messageService.showError(res.replyMsg);
        }
      },
      (error) => {
        this.messageService.showError('Oops! Something went wrong!');
      }
    );
  }
  MyClass_complete: any = [];
  MyClass: any = [];
  MyClass_upcomming: any = [];
  MyClass_cancel: any = [];
  getMyClass_complete(bodyDataRequest: any) {
    var self = this;
    self.apiService.loadingShow = true;
    var requestData: any = {};
    requestData = bodyDataRequest;
    requestData.sid = localStorage.getItem('sid');

    // var sendrequestinput: any = { sid: localStorage.getItem('sid') };
    this.apiService
      .post('teacher_classes_list_complete', requestData)
      .subscribe(
        (data: any) => {
          this.MyClass_complete = data.data;
          this.total_count_complete = data.total_count;


          this.MyClass_complete.forEach((element: any) => {
            var obj = element;
            Object.assign(obj, { assign_flag: false });
          });
        },
        (error) => {
          self.apiService.loadingShow = false;

        }
      );
  }
  getMyClass(bodyDataRequest: any) {
   
    var requestData: any = bodyDataRequest;
    // requestData = bodyDataRequest;
    requestData.sid = localStorage.getItem('sid');

    // var sendrequestinput: any = { sid: localStorage.getItem('sid') };
    this.apiService.post('teacher_classes_list', requestData).subscribe(
      (data: any) => {
        this.MyClass = data.data;
        this.total_count = data?.total_count;


        this.MyClass.forEach((element: any) => {
          var obj = element;
          Object.assign(obj, { assign_flag: false });
        });
      },
      
        
    );
  }
  getMyClass_upcomming(bodyDataRequest: any) {
    var sendrequestinput: any = bodyDataRequest;
    sendrequestinput.sid = localStorage.getItem('sid');
    this.apiService
      .post('teacher_classes_list_upcoming', sendrequestinput)
      .subscribe((data: any) => {
        this.MyClass_upcomming = data.data;
        this.total_count_upcomming = data.total_count;


        this.MyClass_upcomming.forEach((element: any) => {
          var obj = element;
          Object.assign(obj, { assign_flag: false });
        });
      });
  }
  getMyClass_cancel(bodyDataRequest: any) {
    var self = this;
    self.apiService.loadingShow = true;
    var requestData: any = {};
    requestData = bodyDataRequest;
    requestData.sid = localStorage.getItem('sid');

    // var sendrequestinput: any = { sid: localStorage.getItem('sid') };
    this.apiService.post('teacher_classes_list_cancel', requestData).subscribe(
      (data: any) => {
        this.MyClass_cancel = data.data;
        this.total_count_cancel = data.total_count;


        this.MyClass_cancel.forEach((element: any) => {
          var obj = element;
          Object.assign(obj, { assign_flag: false });
        });
      },
      (error) => {
        self.apiService.loadingShow = false;

      }
    );
  }
  onCancelClassPopup(classObject: any) {
    this.commonService.SetBookClassCancelTutorModal(true);
    this.commonService.SetBookClassCancelTutorDataModal(classObject);
  }
  onsub(classObject: any) {

    this.commonService.SetBookClassCancelTutorModal1(true);
    this.commonService.SetBookClassCancelTutorDataModal1(classObject);
  }
  document_name:any
  onSelectDocumentFile(file: any,document_name1:any) {
if(file.files[0].type=="image/jpeg" || file.files[0].type=="image/png" || file.files[0].type=="image/jpg"){
this.document_name=document_name1

    this.uploadFileToDocumentActivity(file.files[0]);
    if (file.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(file.files[0]);
      reader.onload = (event) => {};
    }
  }
    else{
      this.messageService.showError("Select only .jpeg, jpg or.png");

    }
  }
  uploadFileToDocumentActivity(image: any) {
    
    this.apiService.postFile(image).subscribe(
      (data: any) => {
        this.uploadedDocumentImagesData = data.name;
      },
      (error) => {
      }
    );
  }
  onSubmitDocument() {
    this.submitted11 = true;
    this.createuser11.controls['document'].setValue(
      this.uploadedDocumentImagesData
    );
      var requestData: any = {};
      requestData.document_name = this.document_name;
      requestData.document = this.uploadedDocumentImagesData;
      requestData.user_id = this.userData.id;
      this.apiService.post('add_user_documents', requestData).subscribe(
        (res: any) => {
          if (!res.error) {
            this.uploadedDocumentImagesData = '';
            this.createuser11.reset();
            this.submitted11 = false;
            this.GetDocumentList();
            this.document_name=""
            this.messageService.showSuccess(res.replyMsg);
          } else {
            this.messageService.showError(res.replyMsg);
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
    // }
  }
  onDeleteDocument(object: any, status: number) {
    var requestData = {
      id: object.id,
      status: status,
    };
    
    this.apiService.post('update_user_documents_status', requestData).subscribe(
      (res: any) => {
        if (!res.error) {
          if (res.replyCode == 'error') {
            this.messageService.showError(res.replyMsg);
          } else {
            this.GetDocumentList();
            this.messageService.showSuccess(res.replyMsg);
          }
        } else {
          this.messageService.showError(res.replyMsg);
        }
      },
      (error) => {
        this.messageService.showError('Oops! Something went wrong!');
      }
    );
  }
  editDocumentFlag: boolean = false;
  onEditDocument(documentObject: any) {
    this.editDocumentFlag = true;
    this.uploadedDocumentImagesData = documentObject.document;
    this.createuser11.controls['id'].setValue(documentObject.id);
    this.createuser11.controls['document_name'].setValue(
      documentObject.document_name
    );
    this.createuser11.controls['document'].setValue(documentObject.document);
  }

  addQuestionFlag: boolean = false;
  onAddQuestion() {
    this.addQuestionFlag = true;
  }
  uploadedQuestionData: any;
  uploadedAnswerData: any;
  onSelectQuestionFile(file: any) {
    if(file.files[0].type=="image/jpeg" || file.files[0].type=="image/png" || file.files[0].type=="image/jpg" || file.files[0].type=="application/pdf" ||  file.files[0].type=="application/msword" || file.files[0].type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document"){

    // .pdf, .docx, .doc, .txt. .xslx, .png, .jpg
    this.uploadFileToQuestionActivity(file.files[0]);

    if (file.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(file.files[0]);
      reader.onload = (event) => {};
    }
  }else{
    this.messageService.showError("Select only .jpeg, jpg, doc, pdf or.png ");

  }
  }

  uploadFileToAnswerActivity(image: any) {

    this.apiService.postFile(image).subscribe(
      (data: any) => {
        this.uploadedAnswerData = data.name;
      },
      (error) => {
      }
    );
  }
  onSelectAnswerFile(file: any) {

    this.uploadFileToAnswerActivity(file.files[0]);
    if (file.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(file.files[0]);
      reader.onload = (event) => {};
    }
  }

  uploadFileToQuestionActivity(image: any) {

    this.apiService.postFile(image).subscribe(
      (data: any) => {

        this.uploadedQuestionData = data.name;
      },
      (error) => {
      }
    );
  }
  onSubmitQuestionAnswer() {

    this.submitted12 = true;
    if (this.createuser12.value.category_id == '') {
      this.category_id1 = 'Select Subject';
    }
    if (this.createuser12.value.level_id == '') {
      this.level_id1 = 'Please select level';
    }
    if (this.createuser12.value.quiz_title == '') {
      this.quiz_title1 = 'Please type Quiz Title';
    }
    
    if (this.createuser12.value.exam_date == '') {
      this.exam_date1 = 'Please add Exam Date';
    }
    if (this.createuser12.value.exam_time == '') {
      this.exam_time1 = 'Please add Exam Time';
    }
   
    if (this.createuser12.invalid) {

      return;
    } else {
      var sendRequestData: any = this.createuser12.value;
      sendRequestData.answer_doc = "this.uploadedAnswerData";
      sendRequestData.question_doc = this.uploadedQuestionData;
      sendRequestData.created_by = this.userData.id;
      if(sendRequestData.answer_doc ){}
      this.apiService.post('add_quizzes', sendRequestData).subscribe(
        (res: any) => {

          if (!res.error) {
            if (res.replyCode == 'error') {
              this.createuser12.reset();
              this.addQuestionFlag = false;
              this.messageService.showError(res.replyMsg);
            } else {
              this.GetQuestionList();
              this.messageService.showSuccess(res.replyMsg);
              window.location.reload();
            }
          } else {
            this.messageService.showError(res.replyMsg);
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
    }
  }
  id(id: any) {
    this.student_id_for_socecard = id;
  }
  Submitstudentmarks() {
    this.submitted13 = true;
    if (this.createuser13.invalid) {

      return;
    } else {

      var sendRequestData: any = this.createuser13.value;

      sendRequestData.teacher_name =
        this.userData?.name + ' ' + this.userData?.last_name;
      sendRequestData.teacher_id = this.userData.id;
      sendRequestData.student_id = this.student_id_for_socecard;
      this.apiService
        .post('student_marks_upload_by_tutor', sendRequestData)
        .subscribe(
          (res: any) => {

            if (!res.error) {
              if (res.replyCode == 'error') {
                this.createuser13.reset();
                this.addQuestionFlag = false;
                this.messageService.showError(res.replyMsg);
              } else {
                this.GetQuestionList();
                this.messageService.showSuccess(res.replyMsg);
              }
            } else {
              this.messageService.showError(res.replyMsg);
            }
          },
          (error) => {
            this.messageService.showError('Oops! Something went wrong!');
          }
        );
    }
  }
  QuestionList: any = [];
  GetQuestionList() {
    this.apiService
      .post('quizzes_list', { created_by: this.userData.id })
      .subscribe(
        (res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              if (res.data.length > 0) {
                this.QuestionList = res.data;
              } else {
                // this.Teacher_Details_Id ='';
                // this.Edit_Flag=true;
              }
            }
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
  }
  tutor_payout: any = [];
  totalamount: any = [];
  Gettutor_payout(bodyDataRequest: any) {
    var self = this;
    this.tutor_payout=[]
    // self.apiService.loadingShow = true;
    var requestData: any = bodyDataRequest;
    requestData.teacher_id = this.userData.id;


    this.apiService.post('teacher_payout_details', requestData).subscribe(
      (res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            if (res.data.length > 0) {
              
              this.tutor_payout = res.data;
              this.totalamount = res?.data[0]?.Total;

              this.total_count_payout= res.count_total;
            } else {
            }
          }
        }
      }
    );
  }
  ansList: any = [];
  ans_quizzes_sheet() {
    this.apiService
      .post('student_quizzes_list_for_tutor', { teacher_id: this.userData.id })
      .subscribe(
        (res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              if (res.data.length > 0) {
                this.ansList = res.data;
              } else {
                // this.Teacher_Details_Id ='';
                // this.Edit_Flag=true;
              }
            }
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
  }
  student_scorecard: any = [];
  scorecard_length: any = 0;

  student_performance() {
    this.apiService
      .post('student_performance', { teacher_id: this.userData.id })
      .subscribe(
        (res: any) => {
          this.student_scorecard = res.data;

          //   for (let i = 0; i < this.student_scorecard.filter((item: any) => item).length; i++) {

          //     this.result +=(this.student_scorecard[i].mindfullness + this.student_scorecard[i].engagement + this.student_scorecard[i].willingness + this.student_scorecard[i].persaverance + this.student_scorecard[i].l_understanding + this.student_scorecard[i].remory_retention + this.student_scorecard[i].analytical_skills + this.student_scorecard[i].problem_solving)/8
          //   }
          // this.result= this.result/this.student_scorecard.filter((item: any) => item).length;
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
  }
  updateStatusData(data: any) {

    if (data == '1') {
      // data = "0";
      this.user_status = '0';
    }
    if (data == '0') {
      // data = "0";
      this.user_status = '1';
    }
    if (data == '2') {
      // data = "0";
      this.user_status = '2';
    }


    var sendRequestData = { id: this.userData.id, status: this.user_status };

    this.apiService
      .post('update_user_status', sendRequestData)
      .subscribe((res: any) => {
        if (res.replyCode == 'success') {

          this.messageService.showSuccess('Privacy settings have been updated');
        }
      });
  }
  class_status(data: any) {
    if (data.status == '1') {
      // data = "0";
      var sendRequestData = { id: data.booked_class_id, status: '2' };

      this.apiService
        .post('complete_class_by_tutor', sendRequestData)
        .subscribe((res: any) => {
          if (res.replyCode == 'success') {

            this.messageService.showSuccess('Status updated');
            window.location.reload();
          }
        });
    }
  }
 
  teacher_rating_list: any = [];
  teacher_review_rating() {

    this.apiService
      .post('teacher_review', { id: this.userData.id })
      .subscribe((res: any) => {
        this.teacher_rating_list = res.data;
      });
  }
  delete_teacher_educations(id: any) {
    this.apiService
      .post('delete_teacher_educations', { id: id })
      .subscribe((res: any) => {
        this.teacher_rating_list = res.data;
        if (res.replyCode == 'success') {
          this.getUserDetails(this.localData.id);
          this.messageService.showSuccess('Delete successfully');
          // window.location.reload();
        }
      });
  }

  onChangetime(event: any) {

    var t = event.value;
    var tArr = t.split(' ');
    var hour = tArr[0];
    var minute = tArr[1];
    this.bodyRequestData.start_time = hour;
    this.bodyRequestData.end_time = minute;

    this.getMyClass_cancel(this.bodyRequestData);
    this.getMyClass_upcomming(this.bodyRequestData);
    this.getMyClass_complete(this.bodyRequestData);

  }
 
  printDate() {
    this.bodyRequestData.start_date = this.startDate;
    this.bodyRequestData.end_date = this.endDate;
    // this.Gettutor_payout(this.bodyDataRequest);
    this.getMyClass(this.bodyRequestData);

    this.getMyClass_cancel(this.bodyRequestData);
    this.getMyClass_upcomming(this.bodyRequestData);
    this.getMyClass_complete(this.bodyRequestData);
  }
  startDate: Date | any;

  endDate: Date | any;
  setDate(date: any, e: any) {
    date === 'start' ? (this.startDate = e) : (this.endDate = e);

    this.printDate();
  }
  seach(){
    this.Gettutor_payout(this.bodyRequestData);  
  }
  clearDateFilter() {
    this.startDate = null; // or this.startDate = new Date();
    this.endDate = null; // or this.endDate = new Date();
    this.bodyRequestData.start_date = "";
    this.bodyRequestData.end_date = "";
    this.Gettutor_payout(this.bodyRequestData);
  }
  printDate1() {
    
    var start = this.startDate.split('-');
    var end = this.endDate.split('-');
    // date.split("/").reverse().join("-");
    this.bodyRequestData.start_date =
      start[0] + '-' + start[1] + '-' + start[2]; //this.startDate.split("-").reverse().join("-")
    this.bodyRequestData.end_date = end[0] + '-' + end[1] + '-' + end[2];
  }

  setDate1(date: any, e: any) {
    date === 'start' ? (this.startDate = e) : (this.endDate = e);

    this.printDate1();
  }
  startDateaval: Date | any;

  setDateaval(date: any) {
    this.startDateaval = date;
  }

  aval() {
    this.apiService
      .post('add_time_slot', { id: this.userData.id, date: this.startDateaval })
      .subscribe((res: any) => {
        this.teacher_rating_list = res.data;

        if (res.replyCode === 'success') {
          this.messageService.showSuccess('Slot Created successfully');
          window.location.reload();
        }
        if (res.replyCode == 'success2') {
          this.messageService.showSuccess('Slot Already Created');
          window.location.reload();
        }
      });
  }
  onSubmitBillingAddress() {
    this.submitted2 = true;
    if (this.createuserb.invalid) {
      this.messageService.showWarning('Please fill in the required fields');
      return;
    } else {
      var sendRequestData = this.createuserb.value;

      sendRequestData.user_id = this.localData.id;
      this.apiService
        .post('update_billing_address', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.getUserDetails(this.localData.id);
            this.messageService.showSuccess(data.replyMsg);
            window.location.reload();
          } else {
            this.messageService.showWarning(data.replyMsg);
          }
        });
    }
  }
  onBankdetails() {
    if (this.bankdetail.invalid) {
      this.messageService.showWarning('Please fill all the field');
      return;
    } else {

      //  return this.bankdetail.value;
      var sendRequestData = this.bankdetail.value;
      sendRequestData.user_id = this.localData.id;

      this.apiService
        .post('add_bankdetail', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            localStorage.removeItem('user');
            var json = JSON.stringify(this.userData);
            localStorage.setItem('user', json);
            this.messageService.showSuccess(data.replyMsg);
            this.router.navigate(['/tutor-edit-profile']);
          } else {
            this.messageService.showError(data.replyMsg);
          }
        });
    }
  }
  onSubmitPhotoVideo() {
    var sendRequestData: any = {};
    sendRequestData.user_id = this.localData.id;
    sendRequestData.video_url = this.uploadedVideoData;
    sendRequestData.image = this.uploadedImagesData;
    // var json = JSON.stringify(this.userData);
    // return
    this.apiService
      .post('update_video_url', sendRequestData)
      .subscribe((data: any) => {
        if (data.replyCode == 'success') {
          localStorage.removeItem('user');
            var json = JSON.stringify(this.userData);
            localStorage.setItem('user', json);
          // this.getUserDetails(this.localData.id);
    window.location.reload()

          this.messageService.showSuccess(data.replyMsg);
        } else {
          this.messageService.showWarning(data.replyMsg);
        }
      });
  }
  uploadedVideoData: any = '';
  uploadVideoFileToActivity(image: any) {
    this.apiService.postFile(image).subscribe(
      (data: any) => {
        this.uploadedVideoData = data.name;
      },
      (error) => {
      }
    );
  }
  onSelectVideoFile(file: any) {
    this.uploadVideoFileToActivity(file.files[0]);
    if (file.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(file.files[0]);
      reader.onload = (event) => {};
    }
  }
  withdramamount:any;
  withdraw(data:any){
    this.withdramamount=0
   
      this.withdramamount=data.value

    
  }
  withdrawamountrequest(){
    
    if(this.withdramamount=="" || typeof this.withdramamount=='undefined'){
      this.req_amount="Please add Amount"

    }else{
      var sendRequestData:any = {};
  
    sendRequestData.req_amount=this.withdramamount
    sendRequestData.teacher_id = this.localData.id;
    sendRequestData.teacher_name = this.localData?.name+ ' '+this.localData?.last_name;
 
    if(sendRequestData.req_amount >= this.totalamount ){
      this.messageService.showWarning("Balance withdrawals should not exceed your total earnings");
      this.req_amount="Please add less Amount"

    }
    if(sendRequestData.req_amount<="20" || sendRequestData.req_amount<=20 ){
      this.messageService.showWarning("Please Increase Your Amount");
this.req_amount="Please Increase Your Amount"
    }
    if(sendRequestData.req_amount < this.totalamount && (sendRequestData.req_amount>"20" || sendRequestData.req_amount> 20)){
    this.apiService.post('withdraw_payment_request', sendRequestData).subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.getUserDetails(this.localData.id);
            this.messageService.showSuccess(data.replyMsg);
          } else {
            this.messageService.showWarning(data.replyMsg);
          }
        });

  }
  }}
  question_id:any
  getquestionid(id:any){
    
this.question_id=id
this.apiService.post('findquizzes', {id:id}).subscribe((data: any) => {
      this.createuser12.patchValue(data.data[0])

  
  if (data.replyCode == 'success') {
    this.messageService.showSuccess(data.replyMsg);
  } else {
    this.messageService.showWarning(data.replyMsg);
  }
});

  }
  onupdateQuestionAnswer(){
    this.submitted12 = true;
    if (this.createuser12.value.category_id == '') {
      this.category_id1 = 'Select Subject';
    }
    if (this.createuser12.value.level_id == '') {
      this.level_id1 = 'Please select level';
    }
    if (this.createuser12.value.quiz_title == '') {
      this.quiz_title1 = 'Please type Quiz Title';
    }
    
    if (this.createuser12.value.exam_date == '') {
      this.exam_date1 = 'Please add Exam Date';
    }
    if (this.createuser12.value.exam_time == '') {
      this.exam_time1 = 'Please add Exam Time';
    }
    
   
      var sendRequestData: any = this.createuser12.value;
      sendRequestData.answer_doc = "";
      sendRequestData.question_doc = this.uploadedQuestionData;
      sendRequestData.created_by = this.userData.id;
      sendRequestData.answer_doc = "this.uploadedAnswerData";

      sendRequestData.id = this.question_id;
      if(sendRequestData.answer_doc ){}
      this.apiService.post('add_quizzes', sendRequestData).subscribe(
        (res: any) => {

          if (!res.error) {
            if (res.replyCode == 'error') {
              this.createuser12.reset();
              this.addQuestionFlag = false;
              this.messageService.showError(res.replyMsg);
            } else {
              this.GetQuestionList();
              this.messageService.showSuccess(res.replyMsg);
              window.location.reload();
            }
          } else {
            this.messageService.showError(res.replyMsg);
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
      }
  deletequestionpaperid(id:any){
    this.deleteModal1.show()
    this.delete_id=id
  }
  delete_id:any
  deletequestionpaper(){
    this.QuestionList=[]
    this.apiService.post('delete_quizz', {id:this.delete_id}).subscribe((res: any) => {
      
      this.deleteModal1.hide()
      if (res.replyCode="success") {
        this.apiService.post('quizzes_list', { created_by: this.userData.id },)
      .subscribe(
        (res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              if (res.data.length > 0) {
                this.QuestionList = res.data;

              }
            }
          }
        }
      );
        this.deleteModal1.hide()
        this.messageService.showSuccess(res.replyMsg); // Execute the code block after 3 seconds      
      } else {
        this.addQuestionFlag = false;
        this.messageService.showError(res.replyMsg);
      }

    })

  }
  validateNumberString(input: string): boolean {
    return /^[+]?\d+(\.\d+)?$/.test(input);
  }
  phone_code:any
  onSearchKey3(event: any,name:any) {
    // this.phone_code = event.value;

console.log(event,'asdfasdfasdf');


    var check=this.validateNumberString(event)
    if(check==true && name!=""){
      this.phone=""
    } else{
      this.phone="Please enter a valid number"
    } 
    
    console.log(this.validateNumberString(event))    
  }
  clear_image(name:any){
    this.document_name=name
   this.uploadedDocumentImagesData="";
    this.onSubmitDocument()

  }
  edu_flag: boolean = false;
  
  updateeducationsdetail(value:any){
    this.edu_flag=true

this.createuser4.patchValue(value)

  }
  onSearchKey2(event: any,name:any) {

    if(name== "name" && event.value!=""){
      this.namefield=""
      
    }
    if(name== "last_name" && event.value!=""){
      this.lastname=""
      
    }
    if(name== "dob" && event.value!=""){
      this.dob=""
      
    }
    if(name== "email" && event.value!=""){
      this.email=""
      
    }

  }
  getCurrentDate(): Date {
    return new Date();
  }



  delete_teacher_subject(user_id: number, id: number) {
    console.log('Deleting item with user_id:', user_id, 'and id:', id);
  
    if (confirm('Are you sure you want to delete this item?')) {
      // Update the API call to send both user_id and id in the JSON data
      this.apiService.post('delete_teacher_subject', { user_id, id }).subscribe(
        (response: any) => {
          console.log('Deletion API Response:', response);
  
          if (response && response.replyCode === 'success') {
            // Find the index of the subject with the matching id
            const index = this.userData.subjects.findIndex((subject: { id: number }) => subject.id === id);
  
            // Remove the item from the local array
            if (index !== -1) {
              this.userData.subjects.splice(index, 1);
            }
            window.location.reload();
          } else {
            console.error('Error deleting item from the server. Response:', response);
            // Handle error if necessary
          }
        },
        (error: any) => {
          console.error('Error calling the deletion API', error);
          // Handle error if necessary
        }
      );
    }
  }
  
  
  
  
  

  

}

  
  


