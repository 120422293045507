<div class="modal fade show pp" id="cr" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">Cancel &amp; Refund</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 pr-0">
                        <form [formGroup]="addForm">
                            <div class="emp_area">
                                <div class="name_feild">
                                    <p>Reason</p>
                                    <p><textarea name="" id="" cols="30" rows="6" class="form-control" formControlName="reason" [ngClass]="{ 'is-invalid': submitted && f.reason.errors }"></textarea></p>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary mt-4" data-dismiss="modal" (click)="onClosePopup()">Cancel</button>
                <button type="button" class="submit" (click)="onSubmit()">Submit</button>
            </div>
        </div>
    </div>
</div>