<!--head+filter-->
<div class="mb-3 contT">
    <div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <li class="active">Mitt Konto</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1 class="float-left"><span class="txt_gray mob_hide">Just </span>Compare Your Course</h1>
        <p class="clearfix"></p>
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->


<!-- listing-->
<section class="animated3 wow drop_eff5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-sm-6 wow fadeInLeft" *ngFor="let list of this.CourseList">
                        <div class="list_box">
                            <!-- <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i
										class="fas fa-comments"></i></a> -->
                            <!-- <a href="javascript:void(0);" class="book_btn" title="Book A 15 Minuts Meeting" routerLink="/book-a-demo-class/{{list.id}}"><i
										class="fas fa-handshake"></i></a>
                            <a href="javascript:void(0);" class="class_btn" title="Intresseanmälan " routerLink="/book-a-class/{{list.id}}"><i
										class="fas fa-laptop"></i></a> -->
                            <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1"><img [src]="allowurl(apiService.imageBase+list.image)" onError="this.src='assets/images/profile-demo.png'" alt="" class="img-fluid"></a>
                            <div class="p-3 rel">
                                <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 & up (232)</span></p>
                                <p class="b float-left"><a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1">{{list.course_name}}</a></p>
                                <p class="b float-right">${{list.amount}}.00/hr</p>
                                <p class="clearfix"></p>
                                <!-- <p class="fs12">state</p> -->
                                <p class="tbot bdrb pb-3">Total no. of class : <span>{{list.no_of_classes}}</span></p>
                                <div class="pt-3 pb-3">
                                    <p class="b mb-2">Curriculum</p>
                                    <p [innerHTML]="list.curriculum" style="word-break: break-all;"></p>
                                </div>
                                <div class="pt-3 pb-3">
                                    <p class="b mb-2">Description</p>
                                    <p [innerHTML]="list.description" style="word-break: break-all;"></p>
                                </div>
                                <div class="pt-3 pb-3">
                                    <p class="b mb-2">Activities</p>
                                    <p [innerHTML]="list.hands_on_activities" style="word-break: break-all;"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- listing Ends -->