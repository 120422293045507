<div class="mb-3 contT">
    <div class="container pl-3">
        <div class="row">
            <div class="col-md-12">
                <ul class="breadcrumb">
                    <li><a href="/">Home</a></li>
                    <li class="active">Populära ämnen?</li>
                </ul>
                <div class="_Browse-Subjects pl-3 pr-3">
                    <h1><span class="txt_gray mob_hide">Populära  </span>ämnen??</h1>
                </div>
            </div>
        </div>

    </div>
    
</div>


<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
    <div class="container mb-5">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-12 cus-wid mb-4" *ngFor="let sujects of categoryList let i = index">
                <a href="javascript:void(0);" routerLink="/find-a-tutor" (click)="subjectid(sujects.id)"
                    class="subject">
                    <div class="sub-icon">
                        <img *ngIf="sujects.image" [src]="allowurl(apiService.imageBase + sujects.image)"
                            onError="this.src='assets/images/s-i-1.png'" alt="Skolbryggan Classic" />
                        <img *ngIf="!(sujects.image)" alt="" class="img-fluid" src="assets/images/s-i-1.png" />
                        <h5>{{ sujects?.name }}</h5>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>