<div class="clearfix"></div>
<div class="mb-3 ptfix mt-5 Thank-You">
    <div class="container pt-4 pl-3">
        <ul class="breadcrumb">
            <li><a href="/">Home</a></li>
            <li class="active">Thank You</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide">We are </span>Thank You</h1>            
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->

<!-- Content Stars -->


<section class="animated3 wow drop_eff5">
<div class="container mb-5">
<div class="row">
  <div class="col-lg-8 wow fadeInLeft offset-md-2 text-justify">
      <p class="text-center mt-4 mb-4"><img src="assets/images/thank.png" alt=""></p>
      <span [innerHtml]="cmsData.content"></span>
      </div>
  </div>
</div>
</section>