<div class="modal fade show pp" id="c-l" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered logmod" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">LÄGG TILL ÄMNE</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 pr-0">
                        <form [formGroup]="createuser1">
                            <div class="emp_area">
                                <div class="name_feild">
                                    <p>Välj  ÄMNE</p>
                                    <p><select name="" id="" class="form-control" formControlName="category_id" [ngClass]="{ 'is-invalid': submitted1 && f1.category_id.errors }">
                                        <option [value]="lvl.id" *ngFor="let lvl of CategoryList">{{lvl.name}}
                                        </option>
                                    </select></p>
                                </div>

                                <div class="loc_feild">
                                    <p>Nivå</p>
                                    <p><select name="" id="" class="form-control" formControlName="level" [ngClass]="{ 'is-invalid': submitted1 && f1.level.errors }">
                                        <option [value]="lvl.id" *ngFor="let lvl of LevelList">{{lvl.title}}
                                        </option>
                                    </select></p>
                                </div>

                                <div class="loc_feild">
                                    <p>ÄMNE Areas Covered (optional)</p>
                                    <p><textarea name="" id="" cols="30" rows="3" class="form-control" formControlName="description" [ngClass]="{ 'is-invalid': submitted1 && f1.description.errors }"></textarea></p>
                                </div>

                                <div class="loc_feild">
                                    <p>Timpris</p>
                                    <p><input type="text" class="form-control" formControlName="hourly_rate" [ngClass]="{ 'is-invalid': submitted1 && f1.hourly_rate.errors }"></p>
                                </div>

                                <div class="loc_feild">
                                    <p>Profil Rate</p>
                                    <p><input type="text" class="form-control" formControlName="profile_rate" [ngClass]="{ 'is-invalid': submitted1 && f1.profile_rate.errors }"></p>
                                </div>

                                <!-- <div class="loc_feild">
                                    <p>Your current commission rate is set at 25%</p>
                                </div> -->

                            </div>
                        </form>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary mt-4" data-dismiss="modal">Cancel</button>
                <button type="button" class="submit" (click)="onSubmit()">Lägg  här</button>
            </div>
        </div>
    </div>
</div>