import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from './../../services/message.service';

import { CommonService } from 'src/app/services';
@Component({
  selector: 'app-browse-course',
  templateUrl: './browse-course.component.html',
  styleUrls: ['./browse-course.component.scss']
})
export class BrowseCourseComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any=[];
  categoryList:any;
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
  }

  ngOnInit(): void {

    var sendrequestinput:any={};
    this.apiService.post('category_list',sendrequestinput).subscribe((data:any) => {
      this.categoryList=data.data;
    })

  }
  subjectid(id:any){
    localStorage.setItem('tutorsubject',id)

  }
  allowurl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

   public detailsPage(id:any){
    
    this.router.navigate(['/find-course'], { queryParams: { id: btoa(id) }});

  }


}
