export * from './blog-details/blog-details.component';
export * from './blog/blog.component';
export * from './browse-course/browse-course.component';
export * from './browse-subject/browse-subject.component';
export * from './class-dashboard/class-dashboard.component';
export * from './course-dtl/course-dtl.component';
export * from './group-class/group-class.component';
export * from './my-wishlist/my-wishlist.component';
export * from './online-space/online-space.component';
export * from './parent-blog/parent-blog.component';
export * from './parent-notification/parent-notification.component';
export * from './parent-support/parent-support.component';
export * from './price/price.component';
export * from './student-classes-schedule/student-classes-schedule.component';
export * from './student-monthly-scorecard/student-monthly-scorecard.component';
export * from './student-study-material/student-study-material.component';
export * from './support/support.component';
export * from './tutor-manage-request-meeting-classes/tutor-manage-request-meeting-classes.component';
export * from './tutor-manage-results/tutor-manage-results.component';
export * from './tutor-my-earning/tutor-my-earning.component';
export * from './tutor-redeem-requests/tutor-redeem-requests.component';
export * from './tutor-short-details/tutor-short-details.component';
export * from './tutor-student-details/tutor-student-details.component';
export * from './find-course/find-course.component';
export * from './parent-contact/parent-contact.component';
export * from './chat/chat.component';
export * from './compare-course/compare-course.component';
export * from './contact-us/contact-us.component';
export * from './all-course/all-course.component';
export * from './refer-program/refer-program.component';
export * from './blog-list/blog-list.component';
export * from './my-score/my-score.component';