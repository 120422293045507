import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';

import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  cmsData: any=[];
  constructor(public apiService :ApiService,private title: Title, private meta: Meta,public commonService:CommonService) {
    this.commonService.SetFooterContent(false);
   }

  
  ngOnInit(): void {
    this.getData();
  }
  getData(){
    var sendrequestinput:any={id:"1"};
    this.apiService.post('cms_details',sendrequestinput).subscribe((data:any) =>{
      this.cmsData=data.data
      this.title.setTitle(this.cmsData.meta_title);
      this.meta.updateTag({ name: 'keyword', content: this.cmsData.meta_keyword });
      this.meta.updateTag({ name: 'description', content: this.cmsData.meta_description });
    } 
    );
    
  }
}
