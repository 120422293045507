import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiService,CommonService,MessageService } from '../services';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {
  addForm: FormGroup | any;
  pro_id:any;
  userObject:any={
    first_name:'',
    last_name:'',
    email:'',
    mobile:'',
  };
  submitted = false; btnsubmitted = false;


  get f() { return this.addForm.controls; }

  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public activatedRoute: ActivatedRoute,public commonService:CommonService) {
    this.commonService.SetFooterContent(false);
    this.pro_id = this.activatedRoute.snapshot.paramMap.get("id");
    console.log(' this.pro_id', this.pro_id);
  }

  ngOnInit(): void {
    this.addForm = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      email: ['', [Validators.required]],
     // type: ['3']
    })
  }

  onaddForm() {
    this.submitted= true;
    var self = this;
    if (self.addForm.valid) {
      var sendRequestData = self.addForm.value;
      sendRequestData.type = "4";
      var requestUrl;
      requestUrl = 'contact_us_inquiry';
      self.apiService.post(requestUrl,sendRequestData).subscribe((res: any) => {
        if (res.replyCode == 'success') {
          this.submitted= false;
          self.addForm.reset();
          //this.messageService.showSuccess(res.replyMsg);
          this.router.navigate(['/calculate-cost/',res.id,this.pro_id])
        }else {
          this.messageService.showError(res.replyMsg)
        }
      },error => {
          this.messageService.showError('Oops! Something went wrong!');
      });
    }
  }
}