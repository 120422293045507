<div class="mb-3 ptfix">
    <div class="container pt-4 pl-3">
        <ul class="breadcrumb">
            <li><a routerLink="/">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Request A Quote</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide">Send Us </span>Request A Quote</h1>
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->

<!-- Content Stars -->
<section class="animated3 wow drop_eff5">
    <div class="container mb-5">
        <div class="row justify-content-center">
            <div class="col-lg-6 wow fadeInLeft newsletter">
                <form [formGroup]="addForm" (ngSubmit)="onaddForm()">
                    <div class="mb-3">
                        <p>First Name</p>
                        
                        <input type="text" mdbInput class="form-control" formControlName="first_name"
                        placeholder="First Name *"
                        [class.is-invalid]="addForm.get('first_name').invalid && addForm.get('first_name').touched">
                    </div>
                    
                    <div class="mb-3">
                        <p>Last Name</p>
                        
                        <input type="text" mdbInput class="form-control" formControlName="last_name"
                        placeholder="Last Name *"
                        [class.is-invalid]="addForm.get('last_name').invalid && addForm.get('last_name').touched">
                    </div>

                    <div class="mb-3">
                        <p>Your Address</p>
                        <textarea name="address" id="address" cols="30" rows="5"
                        placeholder="Address*" class="form-control" formControlName="address"></textarea>
                    </div>
                    <div class="mb-3">
                        <p>Your Email</p>
                        <input type="text" mdbInput class="form-control" formControlName="email"
                        placeholder="Email ID *"
                        [class.is-invalid]="addForm.get('email').invalid && addForm.get('email').touched">
                    </div>
                    <div class="mb-3">
                        <p>Your Contact Number</p>
                        <input type="text" mdbInput class="form-control" formControlName="mobile"
                        placeholder="Mobile No. *"
                        [class.is-invalid]="addForm.get('mobile').invalid && addForm.get('mobile').touched">
                    </div>
                    <div class="mb-3">
                        <p>Comment</p>
                        <textarea name="description" id="description" cols="30" rows="5"
                        placeholder="Comment/Message *" class="form-control" formControlName="comment"></textarea>
                    </div>
                    
                    <div class="mb-3">
                            <input type="file" mdbInput id="file" (change)="handleFileInput($event,'file')" formControlName="file" class="form-control" >
                    </div>

                    <div class="mb-3">
                        <button class="nbtn1 text-center ttu mt-5 " mdbBtn type="submit">Submit</button>
                        <p class="clearfix"></p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>