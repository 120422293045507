
<div class="error">
    <div class="container">
        <div class="row">
            <div class="col-md-3">

            </div>
            <div class="col-md-6">
                <div class="Something">
                    <span class="exclamation"><i class="fa fa-exclamation" aria-hidden="true"></i></span>
                    <h1>Oops! Something went wrong.</h1>
                    <p>While trying to reserve money from your account</p>
                </div>
                <span><a href="#" class="again">Try again</a></span>
            </div>
        </div>
    </div>
</div>
