import { MessageService } from '../../services/message.service';
import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { PageEvent } from '@angular/material/paginator';
import { Location } from '@angular/common';
// import * as io from 'socket.io-client';

import { CommonService } from 'src/app/services';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  p: number = 1;
  total_count: any;
  bodyDataRequest: any = {};
  keyValue: any = ''
  addForm: FormGroup | any;
  contactData: any = [];
  tutorList: any = [];
  pageEvent: any = PageEvent;
  popcompair: any;
  public blogList: any = [];
  public ChatfriendList: any = [];
  public ChatGroupList: any = [];
  bodyRequestData: any = {};
  role_id: any;
  public ChatGroupDetails: any = [];
  public ChatGroupUserDetails: any = [];
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  localData: any;
  LoginUserDetails: any;
  constructor(private route: ActivatedRoute, private _location: Location, private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService) {
    var jsonData: any = localStorage.getItem('user');
    this.localData = JSON.parse(jsonData);
    this.commonService.SetFooterContent(false);

    this.apiService.newMessageReceived().subscribe((data: any) => {
        var json = JSON.parse(data)

        if (json.receiverUID == this.localData.id) {
          var sendRequestData: any = {}
          sendRequestData.UID = json.senderUID;
          sendRequestData.returnFriends = 1,
            sendRequestData.returnJoinedGroups = 1,
            this.apiService.post('getUser', sendRequestData).subscribe((data: any) => {
              var groupcheck = data.success.data.user;
              this.getChatGroupDetails(groupcheck);
            })

        }
        if (json?.isGroup == 1) {
          var sendRequestData: any = {}
          sendRequestData.UID = this.localData.id;
          sendRequestData.returnFriends = 1,
            sendRequestData.returnJoinedGroups = 1,
            this.apiService.post('getUser', sendRequestData).subscribe((data: any) => {
              var groupcheck = data.success.data.groups;
              var f = groupcheck.find((item: any) => item.guid == json.receiverUID)
              if (f.guid == json.receiverUID) {
                this.getChatGroupDetails(f);
              }
            })
        }
        else {
        }
      });
  }
  private socket: any;
  public data: any;
  route_id: any;
  ngOnInit(): void {
    this.commonService.GetLoginUserDetails().subscribe((data: any) => {
      this.LoginUserDetails = data;
    })
    this.role_id = this.localData.role_id
    this.getChatGroupList();
    this.commonService.GetChatChanged().subscribe(data => {
      if (data) {
        this.getChatGroupList();
      }
    })

    this.allowurl1()
   this.route.params.subscribe(params => {
    this.route_id=params['id'] //log the value of id
    });
    console.log(this.route_id,'this.route_id');
    
    if(this.route_id){
      this.tutor_connection()
      console.log("not empty");
      

    }else{
      console.log("empty");
      
    }
  }
  onSearchKey(event: any) {

    if (event.value.length > 0) {
      this.ChatGroupList = this.ChatGroupList.filter((friend: any) => friend.name.toLowerCase().includes(event.value.toLowerCase()));
      this.ChatfriendList = this.ChatfriendList.filter((friend: any) => friend.name.toLowerCase().includes(event.value.toLowerCase()));
    } else {
      this.getChatGroupList();

    }

  }
  tutor_connection(){
    
  
      
      
      var sendRequestData: any = {};
      sendRequestData.friendsUID = this.route_id;
      sendRequestData.clearExisting = 0;
      sendRequestData.UID = this.localData.id;
    
    
      this.apiService.post("addFriends", sendRequestData).subscribe((data: any) => {
      var sendRequestData2: any = {};
      sendRequestData2.UID =this.route_id;
      sendRequestData2.clearExisting = 0;
      sendRequestData2.friendsUID = this.localData.id;
    
      this.apiService.post("addFriends", sendRequestData2).subscribe((data: any) => {
          this.commonService.SetChatChanged(true);
          this.commonService.SetUpdateProfileChanged(true);
      })     

      })
      var sendRequestData: any = {}
      sendRequestData.UID =this.route_id;
      sendRequestData.returnFriends = 1,
        sendRequestData.returnJoinedGroups = 1,
        this.apiService.post('getUser', sendRequestData).subscribe((data: any) => {
          var groupcheck = data.success.data.user;
          this.getChatGroupDetails(groupcheck);
        })
    }

  formattedArray: any = [];
  getTutorList() {
    var bodyRequestData: any = {}
    bodyRequestData.offset = "",
      bodyRequestData.limit = "",
      this.apiService.post('listUsers', bodyRequestData).subscribe((data: any) => {
        this.tutorList = data.data;


      })

  }
  onEditGroup(groupObject: any) {
    this.commonService.SetChatData(groupObject);
    this.commonService.SetChatGroup(true);
  }
  onEditchatGroup(groupObject: any) {
    this.commonService.SeteditchatGroup(groupObject);

  }

  onAddGroupuser() {
    this.commonService.SetChatData(this.ChatGroupId);
    this.commonService.SetChatGroupUser(true);
  }
  Setupdateprofile() {
    this.commonService.Setupdateprofile(this.ChatGroupId);
  }
  onAdduserfriend() {
    var create: any = "create"
    this.commonService.SetAddFriendPopup(create);
  }
  ChatGroupId: any = {};
  deleteFriends(data: any) {

    var sendRequestData: any = {}
    sendRequestData.UID = this.localData.id;
    sendRequestData.friendsUID = data.uid,

      sendRequestData.clearExisting = 1,
      this.apiService.post('deleteFriends', sendRequestData).subscribe((data: any) => {


        this.getChatGroupList();

      })
  }
  deleteGroup(data: any) {

    var sendRequestData: any = {}
    sendRequestData.GUID = data.guid;

    sendRequestData.clearExisting = 1,
      this.apiService.post('deleteGroup', sendRequestData).subscribe((data: any) => {
        this.getChatGroupList();

      })
  }
  kickUsers: any
  kickUsersFromGroup(data: any) {

    var sendRequestData: any = {}
    sendRequestData.GUID = data.guid;
    sendRequestData.UIDs = this.localData.id,

      sendRequestData.clearExisting = 1,
      this.apiService.post('kickUsersFromGroup', sendRequestData).subscribe((data: any) => {

        this.getChatGroupList();

      })
  }
  blockUser(data: any) {

    var sendRequestData: any = {}

    sendRequestData.senderUID = this.localData.id;
    sendRequestData.receiverUID = data.uid,

      sendRequestData.clearExisting = 1,
      this.apiService.post('blockUser', sendRequestData).subscribe((data: any) => {


        this.getChatGroupList();

      })
  }
  unblockUser(data: any) {

    var sendRequestData: any = {}

    sendRequestData.senderUID = this.localData.id;
    sendRequestData.receiverUID = data.uid,

      sendRequestData.clearExisting = 1,
      this.apiService.post('unblockUser', sendRequestData).subscribe((data: any) => {


        this.getChatGroupList();

      })
  }
  userProfilesArray: any = [];
  user: any = [];

  getChatGroupList() {

    var sendRequestData: any = {}
    sendRequestData.UID = this.localData.id;
    sendRequestData.returnFriends = 1,
      sendRequestData.returnJoinedGroups = 1,
      this.apiService.post('getUser', sendRequestData).subscribe((data: any) => {
        this.ChatfriendList = data.success.data.friends;
        this.user = data.success.data.user;
        for (const userProfile of this.ChatfriendList) {
          const uid = userProfile.uid;
          this.userProfilesArray[uid] = userProfile;
        }

        this.ChatGroupList = data.success.data.groups;


      })


  }

  username: any
  userstatus: any
  grp: any = 0
  groupList: any = {}
  groupuserdetail: any = []
  active_input:any

  getChatGroupDetails(detail: any) {
    this.active_input=1
    this.username = detail;


    var sendrequestinput: any = this.bodyDataRequest

    this.ChatGroupId = detail.uid;

    sendrequestinput.withUIDs = detail.uid;
    sendrequestinput.UID = detail.uid;
    sendrequestinput.returnFriends = 0
    sendrequestinput.returnJoinedGroups = 0
    sendrequestinput.UIDs = this.localData.id;
    sendrequestinput.GUIDs = this.localData.id;

    if (!detail.guid) {

      this.grp = 0
      this.apiService.post('getUserStatus', { UID: detail.uid }).subscribe((data: any) => {
        this.userstatus = data.success[detail.uid];
      })
      this.apiService.post('getMessages', sendrequestinput).subscribe((data: any) => {
        var chat = data.success.data[this.localData.id];
        if (chat['one-on-one'] != "No chats available") {
          this.ChatGroupDetails = chat['one-on-one']?.slice().reverse();


        } else {
          this.ChatGroupDetails = chat['one-on-one'];
        }
      })
    }
    else {
      this.grp = 1
      this.ChatGroupId = detail.guid;

      sendrequestinput.GUIDs = detail.guid;
      sendrequestinput.limit = "";
      sendrequestinput.offset = "";

      this.apiService.post('getGroupList', sendrequestinput).subscribe((data: any) => {
        this.groupList = data.success.data[detail.guid].group_members || [];

      })


      this.apiService.post('getGroupMessages', sendrequestinput).subscribe((data: any) => {
        var chat = data.success.data[detail.guid];
        if (chat.groupchats != "No chats available") {
          this.ChatGroupDetails = chat.groupchats?.slice().reverse();
        } else {
          this.ChatGroupDetails = chat['one-on-one'];
        }
      })
    }

  }

  onOpenGroup() {
    this.commonService.SetChatGroup(true);
  }
  allowurl1() {
    return this.sanitizer.bypassSecurityTrustResourceUrl('50846.chatforyoursite.com/images/spiderman.png');
  }
  allowurl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  Message: any = '';
  sendMessage() {
    // return
    console.log(this.ChatGroupId,'this.ChatGroupId');
    
    if (this.Message != '') {
      var sendRequestData: any = {}
      sendRequestData.senderUID = this.localData.id;
      sendRequestData.receiverUID = this.ChatGroupId;
      sendRequestData.message = this.Message;
      if (this.grp == 1) {
        sendRequestData.isGroup = 1;
      } else {
        sendRequestData.isGroup = 0;
      }
      this.apiService.sendMessage(sendRequestData);

      this.Message = '';
      var userchatdetail: any = {}

      userchatdetail = this.username
      userchatdetail.uid = this.ChatGroupId
      if (this.grp == 1) {
        userchatdetail.guid = this.ChatGroupId

      } else {

        userchatdetail.uid = this.ChatGroupId

      }

      this.getChatGroupDetails(userchatdetail);
      // })
    }
    else {
      this.messageService.showError('Text here..');
    }
  }
  backClicked() {
    this._location.back();
  }
  keyDownFunction(event: any) {
    if (event.keyCode === 13) {
      this.sendMessage()
    }
  }
  uploadFileToActivity(image: any) {
    this.apiService.postFile(image).subscribe((data: any) => {
      this.uploadedImagesData = data.name;

    }, error => {
    });
  }
  onSelectFile(file: any) {
    this.uploadFileToActivity(file.files[0])
    if (file.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(file.files[0]);
      reader.onload = (event) => { }
    }
  }
}
