<div class="mb-3 ptfix">
    <div class="container pt-4 pl-3">
        <ul class="breadcrumb">
            <li><a href="/">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">About Marble Tops</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide">About </span>Marble Tops</h1>
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->

<!-- Content Stars -->
<section class="animated3 wow drop_eff5">
    <div class="container mb-5">
        <div class="row">
            <div class="col-lg-8 wow fadeInLeft offset-md-2 text-justify">
                <span [innerHtml]="cmsData.content"></span>
            </div>
        </div>
    </div>
</section>
<!-- Content Ends -->