import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { PasswordMatchPattern } from 'src/app/services/validation/password-match-pattern';

@Component({
  selector: 'app-add-child',
  templateUrl: './add-child.component.html',
  styleUrls: ['./add-child.component.scss']
})
export class AddParentChildComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any = [];

  uploadedImagesData: any = '';
  fileToUpload: any = null;
  localData: any;
  createuser1: FormGroup | any; public submitted1 = false;
  constructor(private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService) {
    var jsonData: any = localStorage.getItem('user');
    this.localData = JSON.parse(jsonData);
  }
  get f1() { return this.createuser1.controls; }

  ngOnInit(): void {
    this.getTeacherSubject();
    this.getLevelList();
    this.createuser1 = this.fb.group({
      name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      // email: new FormControl('', [Validators.required]),
      email : new FormControl('', [Validators.required,Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),

      phone: new FormControl(''),
      dob: new FormControl(''),
      password : [null, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      reenterpassword: ['', Validators.required],
    //   description: new FormControl('', [Validators.required]),
      id: new FormControl(''),
    }, {
        validator: PasswordMatchPattern('password', 'reenterpassword')
      });
  }
  onClosePopup() {
    this.commonService.SetAddParentChild(false);
  }
  CategoryList:any=[];
  getTeacherSubject(){
    var sendrequestinput:any={id:1};
    this.apiService.post('category_list',sendrequestinput).subscribe((data:any) => {
      this.CategoryList=data.data;
    })
  }
  LevelList:any=[];
  getLevelList(){
    var sendrequestinput:any={id:1};
    this.apiService.post('age_group_list_dropdown',sendrequestinput).subscribe((data:any) => {
      this.LevelList=data.data;
    })
  }
  type:boolean=false;
  cptype:boolean=false;
  onChangeType1(){
    this.type=true;
  }
  onChangeType2(){
    this.type=false;
  }
  onChangecnType1(){
    this.cptype=true;
  }
  onChangecnType2(){
    this.cptype=false;
  }


// validateEmail($email:any){
//         var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

//         if (reg.test($email.value) == false) 
//         {
//           this.createuser1.get('email').markAsTouched();
//           this.messageService.showWarning('Email must be a valid email address.')
//             return false;
//         }
//         return true;
// }


  onSubmit() {
    this.submitted1=true;
    this.createuser1.get('name').markAsTouched();
    this.createuser1.get('last_name').markAsTouched();
    this.createuser1.get('phone').markAsTouched();
    // this.createuser1.get('dob').markAsTouched();
    this.createuser1.get('email').markAsTouched();
    this.createuser1.get('password').markAsTouched();
    this.createuser1.get('reenterpassword').markAsTouched();
    // var emcheck = this.validateEmail(this.createuser1.get('email'));
    
    if (this.createuser1.invalid || this.phone=="Please enter a valid number"
    ) {
      this.messageService.showWarning('Field input is required')
      return
    }
     else {
      var sendRequestData: any = this.createuser1.value;
      sendRequestData.role_id = 2;
      sendRequestData.parent_id = this.localData.id;
    //   return;
      this.apiService.post('add_user', sendRequestData).subscribe((data: any) => {
        if (data.replyCode == "success") {
          this.onClosePopup();
          this.commonService.SetUpdateProfileChanged(true);
          this.messageService.showSuccess("Account registered successfully");
          // window.location.reload()
        } else {
          this.messageService.showError(data.replyMsg);
        }
      })
    }
  }
  validateNumberString(input: string): boolean {
    return /^[+]?\d+(\.\d+)?$/.test(input);
  }
  phone_code:any
  phone:any
  onSearchKey3(event: any,name:any) {
    // this.phone_code = event.value;

// console.log(event,'asdfasdfasdf');


//     var check=this.validateNumberString(event)
//     if(check==true && name!=""){
//       this.phone=""
//     } else{
//       this.phone="Please enter a valid number"
//     }   // this.getMyClass(this.bodyRequestData);
    console.log(this.validateNumberString(event))    // this.getMyClass(this.bodyRequestData);
  }
}
