import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss']
})
export class AddProjectComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any = [];

  uploadedImagesData: any = '';
  fileToUpload: any = null;
  localData: any;
  createuser1: FormGroup | any; public submitted1 = false;
  constructor(private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService) {
    var jsonData: any = localStorage.getItem('user');
    this.localData = JSON.parse(jsonData);
  }
  get f1() { return this.createuser1.controls; }

  ngOnInit(): void {
    this.getTeacherSubject();
    this.getLevelList();
    this.createuser1 = this.fb.group({
      category_id: new FormControl('', [Validators.required]),
      level: new FormControl('', [Validators.required]),
      hourly_rate: new FormControl('', [Validators.required]),
      profile_rate: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      id: new FormControl(''),
    });
  }
  onClosePopup() {
    this.commonService.SetAddProject(false);
  }
  CategoryList:any=[];
  getTeacherSubject(){
    var sendrequestinput:any={id:1};
    this.apiService.post('category_list',sendrequestinput).subscribe((data:any) => {
      this.CategoryList=data.data;
    })
  }
  LevelList:any=[];
  getLevelList(){
    var sendrequestinput:any={id:1};
    this.apiService.post('age_group_list_dropdown',sendrequestinput).subscribe((data:any) => {
      this.LevelList=data.data;
    })
  }
  onSubmit() {
    this.submitted1=true;
    if (this.createuser1.invalid) {
      this.messageService.showWarning('Please select an image')
      return
    } else {
      var sendRequestData: any = this.createuser1.value;
      sendRequestData.user_id = this.localData.id;
      this.apiService.post('add_teacher_subjects', sendRequestData).subscribe((data: any) => {
        if (data.replyCode == "success") {
          this.onClosePopup();
          this.commonService.SetUpdateProfileChanged(true);
          this.messageService.showSuccess(data.replyMsg);
        } else {
          this.messageService.showError(data.replyMsg);
        }
      })
    }
  }
}
