import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiService,CommonService,MessageService } from '../services';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-request-quote',
  templateUrl: './request_quote.component.html',
  styleUrls: ['./request_quote.component.scss']
})
export class RequestQouteComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any=[];
  
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
  }

  ngOnInit(): void {
    
    this.addForm = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      comment: ['', [Validators.required]],
      address: ['', [Validators.required]],
      email: ['', [Validators.required]],
      file: '',
      type: ['2']
    })
   
  }

  onaddForm() {
    var self = this;
    if (self.addForm.valid) {
      var sendRequestData = self.addForm.value;
      sendRequestData.file=self.uploadedImagesData || '';
      var requestUrl;
      requestUrl = 'contact_us_inquiry';
      
      self.apiService.post(requestUrl, sendRequestData).subscribe((res: any) => {
        if (res.replyCode == 'success') {
          self.addForm.reset();
          this.messageService.showSuccess(res.replyMsg)
          //this.router.navigate(['/product'])
        }
        else {
          this.messageService.showError(res.replyMsg)
        }
      },
        error => {
          this.messageService.showError('Oops! Something went wrong!')

        });

    }
  }

  handleFileInput(event: any, name :any) {
    this.fileToUpload = event.target.files.item(0);
    this.uploadFileToActivity(name)
    
  }

  uploadFileToActivity(name :any) {
    var self = this;
    self.apiService.postFile(self.fileToUpload).subscribe((data: any) => {
      
      self.uploadedImagesData = data.name;

      console.log(self.uploadedImagesData);

    }, error => {
      console.log(error);
    });
  }  

 

}
