import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { PasswordMatchPattern } from 'src/app/services/validation/password-match-pattern';

@Component({
  selector: 'app-tutor-registration',
  templateUrl: './tutor-registration.component.html',
  styleUrls: ['./tutor-registration.component.scss']
})
export class TutorRegistrationComponent implements OnInit {
  createuser: FormGroup | any; public submitted = false;
  contactData: any=[];
  
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  type:boolean=false;
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
  }
  get f() { return this.createuser.controls; }
  onChangeType1(){
    this.type=true;
  }
  onChangeType2(){
    this.type=false;
  }
  ngOnInit(): void {
    this.createuser = this.fb.group({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email : new FormControl('', [Validators.required]),
      role_id : ['3'],
      phone : ['', Validators.required],
      password : [null, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      reenterpassword: ['', Validators.required],
      gender: ['', Validators.required],
      job_type : ['', Validators.required],
      work_experience : ['', Validators.required],
      subscription_type : [''],
      age_group_id : ['', Validators.required],
      rate : ['', Validators.required],
      news_letter : ['', Validators.required],
      country : ['', Validators.required],
      termscond : ['', Validators.required],
    }, {
      validator: PasswordMatchPattern('password', 'reenterpassword')
    });
    this.getCountryList();
    this.getLevelList();
  }
  CountryList:any=[];
  getCountryList(){
    var sendrequestinput:any={id:1};
    this.apiService.post('web_countries_list',sendrequestinput).subscribe((data:any) => {
      this.CountryList=data.data;
    })
  }
  LevelList:any=[];
  getLevelList(){
    var sendrequestinput:any={id:1};
    this.apiService.post('age_group_list_dropdown',sendrequestinput).subscribe((data:any) => {
      this.LevelList=data.data;
    })
  }
  onSubmit(){
    this.submitted=true;
    console.log('this.createuser>>>>>>>',this.createuser.value)
    if(this.createuser.invalid){
      return
    }else{
      // alert();
      console.log('this.createuser>>>>>>>',this.createuser.value)
      var sendrequestinput:any=this.createuser.value;
      if(this.createuser.value.news_letter){
        sendrequestinput.news_letter=1;
      }else{
        sendrequestinput.news_letter=0;
      }
      sendrequestinput.name=this.createuser.value.first_name+this.createuser.value.last_name
      this.apiService.post('add_user',sendrequestinput).subscribe((data:any) => {
        if(data.replyCode == "success"){
          // if(sendrequestinput.role_id == 3){
            this.apiService.post('generate_teacher_slots',{user_id:data.uid}).subscribe((response:any) => {
              console.log('response>>>>>>>>>',response);
              return response;
            })
          // }
          this.createuser.reset();
          this.submitted=false;
          this.messageService.showSuccess(data.replyMsg);
        }else{
          this.messageService.showError(data.replyMsg);
        }
      })
    }
  }

}
