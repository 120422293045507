// gaurav
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    constructor() {}
    GETUSER(): string { return window.localStorage['user']};
    SAVEUSER(user: string) { window.localStorage['user'] = user};
    DELETEUSER(user: string) { window.localStorage['user'] = user};
    GETSID(): string { return window.localStorage['sid']};
    SAVESID(sid: string) { window.localStorage['sid'] = sid};

}