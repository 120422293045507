<div class="mb-3 contT">
    <div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Kontakta oss</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide">Welcome Tobnm </span>Kontakta oss</h1>
    </div>
</div>
<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
    <div class="container mb-5">
        <div class="row">
            <div class="col-md-12 ac fs22 b pb-3">How Can We Help You?</div>
            <div class="col-md-4 ac">
                <div class="blog_box p-4 ac cont_mh">
                    <p class="fs22"><i class="fas fa-mobile-alt"></i></p>
                    <p class="fs18 b mb-3">Give us a Buzz </p>
                    <p>Operating Hours</p>
                    
                    <p>Monday to Sunday<br>9:00 am to 7:00 pm</p>

                    <a href="https://suited-tutor.com/whatsapp"><i style="color:black" class="fab fa-whatsapp" aria-hidden="true"></i> +44 (0)7526 116 872</a><br>
                    <p><a href="447754 562 769"><i class="fa fa-phone"></i> +44 (0)7754 562 769</a></p>
                </div>
            </div>
            <div class="col-md-4 ac">
                <div class="blog_box p-4 ac cont_mh">
                    <p class="fs22"><i class="fas fa-envelope-open"></i></p>
                    <p class="fs18 b mb-3">Send us a message</p>
                    <p class="mt-3">Email us any time at <br><a href="mailto:care@suitedtutor.com">care@suitedtutor.com</a></p>
                    <p class="mt-3">You can also chat with us through our  <br><a href="javascript:void(0);" routerLink="/parent-support">Chat channel</a> </p>
                </div>
            </div>
            <div class="col-md-4 ac">
                <div class="blog_box p-4 ac cont_mh">
                    <p class="fs22"><i class="fas fa-envelope-open"></i></p>
                    <p class="fs18 b mb-3">Sök vår FAQ</p>
                    <p>Do you have any burning questions? Why not have a look on our insightful library? </p>
                    <p class="mt-4 add_cart"><a href="javascript:void(0);" routerLink="/faqs">Let’s go there </a></p>
                </div>
            </div>
        </div>
        <!--<div class="row justify-content-center">
              <div class="col-md-3">
                  <div class="add-sec">
                        <div>
                            <i class="fas fa-map-marker-alt"></i>
                            Address:
                            <span class="black">1085 Rockaway ave, Valley<br>Stream, MA 11581</span>
                            <p class="clearfix"></p>
                        </div>
                        <div>
                            <i class="fas fa-mobile-alt"></i>
                            Phone:
                            <span class="black">123-456-7890 (Toll Free)</span>
                            <p class="clearfix"></p>
                        </div>
                        <div>
                            <i class="fas fa-envelope-open"></i>
                            Email:
                            <span class="black">info@suitedtutor.com</span>
                            <p class="clearfix"></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="list_box p-5 m-auto bgw">
                            <form>
                                <div class="emp_area">
                                    <div class="name_feild">
                                        <p>First Name</p>
                                        <p><input type="text"></p>
                                    </div>
                                    <div class="loc_feild">
                                        <p>Last Name</p>
                                        <p><input type="text"></p>
                                    </div>
                                    <div class="loc_feild">
                                        <p>Email ID</p>
                                        <p><input type="text"></p>
                                    </div>
                                    <div class="loc_feild">
                                        <p>Mobile No.</p>
                                        <p><input type="text"></p>
                                    </div>
                                    <div class="loc_feild">
                                        <p>Enquiry/Comment</p>
                                        <p><textarea name="" id="" cols="30" rows="4"></textarea></p>
                                    </div>
                                    
                                    <div class="loc_feild">
                                        <p class="add_cart"><a href="">Submit</a></p>
                                    </div>
                                    
                                </div>
                            </form>
                        </div>
              </div>
            </div>-->
    </div>
</section>