<div class="mb-3 contT">
      <div class="container pl-3">
      <ul class="breadcrumb">
          <li><a href="index.htm">Home</a></li>
          <!--<li><a href="listing.htm">Chair</a></li>-->
          <li class="active">Mitt Konto</li>
      </ul>
  </div>
  <div class="container pl-3 pr-3">
   <h1 class="float-left"><span class="txt_gray mob_hide">Browse Your </span>Wishlist</h1>         
   <div class="float-right mt-3">Just tick <img src="assets/images/tickc.jpg" alt=""> any 3 tutor and press <form class="frmt" action="student-compare-tutor.htm">
<input type="submit" class="compare" value="Compare"></form> button. If you want delete selected press <form class="frmt">
<input type="submit" class="compare" value="Delete"></form>
   </div>
   <p class="clearfix"></p>
  </div>
</div>

<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <div class="row justify-content-center">
               <div class="col-lg-3 col-sm-6 wow fadeInLeft animated" style="visibility: visible;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1"><img src="assets/images/t1.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
                        <p class="b float-left"><a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1">Rebecca L</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Dwarka, New Delhi</p>
                        <p class="chkbox"><input type="checkbox"></p>
                        <p class="tbot">Totala klass Erfarenhet : <span>10</span></p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-sm-6 wow fadeInLeft animated" style="visibility: visible;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1"><img src="assets/images/t3.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
                        <p class="b float-left"><a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1">Imozen H.</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Dwarka, New Delhi</p>
                        <p class="chkbox"><input type="checkbox"></p>
                        <p class="tbot">Totala klass Erfarenhet : <span>10</span></p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-sm-6 wow fadeInLeft animated" style="visibility: visible;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1"><img src="assets/images/t4.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
                        <p class="b float-left"><a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1">Max C</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Dwarka, New Delhi</p>
                        <p class="chkbox"><input type="checkbox"></p>
                        <p class="tbot">Totala klass Erfarenhet : <span>10</span></p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-sm-6 wow fadeInLeft animated" style="visibility: visible;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1"><img src="assets/images/t4.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
                        <p class="b float-left"><a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1">Rebecca L</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Dwarka, New Delhi</p>
                        <p class="chkbox"><input type="checkbox"></p>
                        <p class="tbot">Totala klass Erfarenhet : <span>10</span></p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-sm-6 wow fadeInLeft" style="visibility: hidden; animation-name: none;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1"><img src="assets/images/t3.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
                        <p class="b float-left"><a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1">Mia M</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Dwarka, New Delhi</p>
                        <p class="chkbox"><input type="checkbox"></p>
                        <p class="tbot">Totala klass Erfarenhet : <span>10</span></p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-sm-6 wow fadeInLeft" style="visibility: hidden; animation-name: none;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1"><img src="assets/images/t2.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
                        <p class="b float-left"><a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1">Imozen H.</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Dwarka, New Delhi</p>
                        <p class="chkbox"><input type="checkbox"></p>
                        <p class="tbot">Totala klass Erfarenhet : <span>10</span></p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-sm-6 wow fadeInLeft" style="visibility: hidden; animation-name: none;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1"><img src="assets/images/t1.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
                        <p class="b float-left"><a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1">Max C</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Dwarka, New Delhi</p>
                        <p class="chkbox"><input type="checkbox"></p>
                        <p class="tbot">Totala klass Erfarenhet : <span>10</span></p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-sm-6 wow fadeInLeft" style="visibility: hidden; animation-name: none;">
                  <div class="list_box">
                     <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                     <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                     <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                     <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1"><img src="assets/images/t3.jpg" alt="" class="img-fluid"></a>
                     <div class="p-3 rel">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
                        <p class="b float-left"><a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1">Max C</a></p>
                        <p class="b float-right">$50.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">Dwarka, New Delhi</p>
                        <p class="chkbox"><input type="checkbox"></p>
                        <p class="tbot">Totala klass Erfarenhet : <span>10</span></p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>