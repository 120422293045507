<div class="mb-3 contT">
    <div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Mitt Konto</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1 class="text-center"><span class="txt_gray mob_hide">Update &amp; </span>Change Password</h1>
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->


<!-- listing-->
<section class="animated3 wow drop_eff5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-sm-6 wow fadeInLeft">
                <div class="list_box p-5">
                    <form [formGroup]="createuser">
                        <div class="emp_area">
                            <div class="name_feild">
                                <p>Old Password</p>
                                <div><input type="text" formControlName="current_password"
                                        [ngClass]="{ 'is-invalid': submitted && f.current_password.errors }"
                                        class="form-control">
                                  
                                    <div *ngIf="(f.current_password.errors && f.current_password.touched) || (f.current_password.errors && submitted)"
                                        class="errorMsg">
                                        <div class="warning" *ngIf="f.current_password.errors.mustMatch">Password do not match.</div>
                                        </div>
                                <div *ngIf="f.current_password.invalid && (f.current_password.dirty || f.current_password.touched)">

                                    <div class="warning" *ngIf="f.current_password.errors.required">
                                        Old password is required.
                                    </div>
                                </div>
                            </div>
                            </div>
                                <div class="loc_feild">
                                    <p>New Password</p>
                                    <div><input type="text" formControlName="password"
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                            class="form-control">
                                            <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)">

                                                <div class="warning" *ngIf="f.password.errors.required">
                                                    New password is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <div class="loc_feild">
                                    <p>Confirm New Password</p>
                                    <div><input type="text" formControlName="reenterpassword"
                                            [ngClass]="{ 'is-invalid': submitted && f.reenterpassword.errors }"
                                            class="form-control">
                                    <div *ngIf="(f.reenterpassword.errors && f.reenterpassword.touched) || (f.reenterpassword.errors && submitted)"
                                        class="errorMsg">
                                        <div class="warning" *ngIf="f.reenterpassword.errors.mustMatch">The password and
                                            Re-Enter password do not match.</div>
                                        </div>
                                        <div *ngIf="f.reenterpassword.invalid && (f.reenterpassword.dirty || f.reenterpassword.touched)">

                                                <div class="warning" *ngIf="f.reenterpassword.errors.required">
                                                    This field is required.
                                                </div>
                                            </div>
                                    </div>
                                    
                                </div>

                                <div class="loc_feild">
                                    <div class="row">
                                        <div class="col-md-12 p-0">
                                            <p class="add_cart"><a href="javascript:void(0);"
                                                    (click)="onChangePassword()">Save</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- listing Ends -->