<div class="modal fade show pp" id="c-l" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered logmod" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">Add Group User</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePopup()">
               <span aria-hidden="true">×</span>
               </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 pr-0">

                        <form [formGroup]="createuser1">
                            <div class="emp_area">
                                <div class="name_feild">
                                    <p>Select User</p>
                                    <p>
                                        <select name="" id="" class="form-control" formControlName="user_id" [ngClass]="{ 'is-invalid': submitted1 && f1.user_id.errors }">
                                            <option value="">Select</option>
                                            <option [value]="lvl.id" *ngFor="let lvl of STudentList">{{lvl.name}}
                                            </option>
                                        </select></p>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary mt-4" data-dismiss="modal" (click)="onClosePopup()">Cancel</button>
                <button type="button" class="submit" (click)="onSubmit()">{{editFlag?'Update':'Add'}}</button>
            </div>
        </div>
    </div>
</div>