import { ApiService } from '../services/api.service';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import { loadStripe } from '@stripe/stripe-js';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-book-a-class',
  templateUrl: './book-a-class.component.html',
  styleUrls: ['./book-a-class.component.scss'],
  providers: [DatePipe],
})
export class BookClassComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any = [];

  uploadedImagesData: any = '';
  fileToUpload: any = null;
  localData: any;
  teacherId: any;
  typeSelected: string | undefined; 
  createuser: FormGroup | any;
  public submitted = false;
  selectedItems: any;
  constructor(
    private spinnerService: NgxSpinnerService,
    private http: HttpClient,
    private _location: Location,
    private fb: FormBuilder,
    public apiService: ApiService,
    public messageService: MessageService,
    public sanitizer: DomSanitizer,
    private router: Router,
    public commonService: CommonService,
    public activatedRoute: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.teacherId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getTeacherDetails(this.teacherId);
    this.getTeacherSlotDate(this.teacherId);
    var jsonData: any = localStorage.getItem('user');
    this.localData = JSON.parse(jsonData);
    this.typeSelected = 'ball-fussion';
  }
  get f() {
    return this.createuser.controls;
  }

  ngOnInit(): void {
    this.getTeacherSubject();
    this.getLevelList();
    this.createuser = this.fb.group({
      slot_id: new FormControl('', [Validators.required]),
      schedule_slot_date: new FormControl('', [Validators.required]),
      level_id: new FormControl('', [Validators.required]),
      subject_id: new FormControl('', [Validators.required]),
      tution_for: new FormControl('', [Validators.required]),
      tution_frequency: new FormControl('', [Validators.required]),
    });
  }

  allowurl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  onClosePopup() {
    this.commonService.SetAddSubject(false);
  }
  TeacherSLotDetails: any = [];
  getTeacherSlotDate(id: any) {
    var sendrequestinput: any = { teacher_id: id };
    this.apiService
      .post('student_teacher_schedule_days_list', sendrequestinput)
      .subscribe((data: any) => {
        this.TeacherSLotDetails = data.data.reverse();
      });
  }

  onCombinedAction() {
    // Perform Stripe payment gateway action
    if (this.createuser.invalid) {
      this.messageService.showWarning("Field's are required");
      return;
    }
    this.onCheckout();

    // Perform confirmation action
    this.onSubmit();
}

TeacherDetails: any;
subjects: any[] = [];

getTeacherDetails(id: any) {
  var sendrequestinput: any = { user_id: id };
  this.apiService
    .post('teacher_details', sendrequestinput)
    .subscribe((data: any) => {
      this.TeacherDetails = data.data;
      this.subjects = this.TeacherDetails.subjects; 
      console.log(this.TeacherDetails, 'raterate');
      var ss = this.TeacherDetails?.merithub_client_id
        ? this.TeacherDetails?.merithub_client_id
        : 'cgraqa9nuvt14lbtoebg';
      console.log(ss, 'raterate');
    });
}


  CategoryList: any = [];
  getTeacherSubject() {
    var sendrequestinput: any = { id: 1 };
    this.apiService
      .post('category_list', sendrequestinput)
      .subscribe((data: any) => {
        this.CategoryList = data.data;
      });
  }
  LevelList: any = [];
  getLevelList() {
    var sendrequestinput: any = { id: 1 };
    this.apiService
      .post('age_group_list_dropdown', sendrequestinput)
      .subscribe((data: any) => {
        this.LevelList = data.data;
      });
  }
  onCheckout(): void {
    // this.http
    //   .post('https://suited-admin.mrmmbs.com:8999/checkout', {
    //     items: [
    //       {
    //         name: 'narender',
    //         price: 1000,
    //         product: 'asdfasdas',
    //         quantity: 1,
    //       },
    //     ],
    //   })
    //   .subscribe(async (res: any) => {
    //     let stripe = await loadStripe(
    //       'pk_test_51Mc1QjSAsqTNd0k5AZcQpdLgrrgXh17hFS2dKwT4gZuRc4uJKqr9YvUL6WzuKzdzqslBfeAwwBwfpwGtZw6jKCta0095rdOQSk'
    //     );
    //     stripe?.redirectToCheckout({
    //       sessionId: res.id,
    //     });
    //   });

    this.http.post('https://api.skolbryggan.se/create-checkout-session', {
      items: JSON.parse(localStorage.getItem('book_slot_student') || '{}'),
    })
    .subscribe(async (res: any) => {
      let stripe = await loadStripe('pk_live_51LHclFKqsR0a21um0DThlLmARmGPnKVCKGkgp8hIUWYFTc9GuWat0iFmgYYK51urkUTkAXUCc4LTWNKPHMr2QcT900IVbCAVrf');
      stripe?.redirectToCheckout({
        sessionId: res.id,
      });
    });
  }
  superpayment(data: any) {
    console.log(data, this.commonService.Setpayments(data), 'super data value');

    this.commonService.Setpayments(data);
  }
  onSubmit() {
    this.submitted = true;
    this.createuser.get('slot_id').markAsTouched();
    this.createuser.get('schedule_slot_date').markAsTouched();
    this.createuser.get('level_id').markAsTouched();
    this.createuser.get('subject_id').markAsTouched();
    this.createuser.get('tution_for').markAsTouched();
    this.createuser.get('tution_frequency').markAsTouched();

    console.log('this.createuser.value>>>>>>>>>>>>>>', this.createuser.value);

    if (this.createuser.invalid) {
      this.messageService.showWarning("Field's are required");
      return;
    } else {
      var sendRequestData: any = this.createuser.value;
      sendRequestData.sid = localStorage.getItem('sid');
      sendRequestData.student_name =
        this.localData.name + ' ' + this.localData?.last_name;
      sendRequestData.parents_name = this.localData.parents_name;
      sendRequestData.phone = this.localData.phone;
      sendRequestData.schedule_slot_date = this.Schdule_Slot_Date;
      sendRequestData.schedule_slot_time = this.Schdule_Slot_Time;
      sendRequestData.teacher_id = this.teacherId;
      sendRequestData.rate = this.TeacherDetails.rate;
      sendRequestData.class_type = 0;
      sendRequestData.userId = this.TeacherDetails?.merithub_client_id
        ? this.TeacherDetails?.merithub_client_id
        : 'cgraqa9nuvt14lbtoebg';

      console.log('sendRequestData>>>>>>', sendRequestData);
      this.apiService
        .post('book_slot_student', sendRequestData)
        .subscribe((data: any) => {
          var json: any = data.data;
          json.rate = this.TeacherDetails.rate;
          var json2: any = JSON.stringify(json);
          console.log(json2, 'json2');

          localStorage.setItem('book_slot_student', json2);

          sendRequestData.id = this.localData.id;
          var sendRequestData_c = this.localData;
          sendRequestData_c.book_class_data = data.data;
          console.log(sendRequestData_c, 'log');
          this.apiService
            .post('generate_jwt', sendRequestData)
            .subscribe((resp: any) => {
              sendRequestData_c.access_token = resp.access_token;
              sendRequestData_c.userId = this.TeacherDetails?.merithub_client_id
                ? this.TeacherDetails?.merithub_client_id
                : 'cgraqa9nuvt14lbtoebg';
              this.apiService
                .post('merithub_new_classes', sendRequestData_c)
                .subscribe((respon: any) => {
                  if (respon.statusCode != 400) {
                    this.apiService
                      .post('cashbackOfferId', sendRequestData)
                      .subscribe((data: any) => {
                        localStorage.setItem(
                          'supertoken',
                          JSON.stringify(data.data)
                        );

                        console.log(data.data, 'narender');

                        this.superpayment(data);
                      });
                  } else {
                    this.messageService.showError(
                      "class can't created at that times"
                    );
                  }
                  console.log(respon, 'merithub_new_classes');
                });
            });
          if (data.replyCode == 'success') {
            // this.superpayment(data)
            this.createuser.reset();
            this.submitted = false;
            this.onClosePopup();
            this.commonService.SetUpdateProfileChanged(true);
            // this.messageService.showSuccess(data.replyMsg);
          } else {
            this.messageService.showError(data.replyMsg);
          }
        });
    }
  }
  SlotData: any = [];
  selectedItem = [];
  dropdownSettings = {};
  Schdule_Slot_Date: any = '';
  Schdule_Slot_Time: any = '';
  onSelectDate(event: any) {
    console.log('event>>>>>>>>', event);
    console.log('event.value>>>>>>>>', event.value);
    this.SlotData = [];
    var filter = this.TeacherSLotDetails.filter(
      (data: any) => data.id == event.value
    );
    console.log('filter>>>>>>>>', filter);
    if (filter.length > 0) {
      this.Schdule_Slot_Date = this.datePipe.transform(
        filter[0].schedule_date,
        'yyyy-MM-dd'
      );
      this.SlotData = filter[0].slots;
    }
  }
  onSelectTime(event: any) {
    console.log('event>>>>>>>>', event);
    console.log('event.value>>>>>>>>', event.value);
    var filter = this.SlotData.filter((x: any) => x.id == event.value);
    console.log('filter>>>>>>>>', filter);

    this.Schdule_Slot_Time = filter[0].time_from;
  }
  backClicked() {
    this._location.back();
  }
}