<!--head+filter-->
<div class="mb-3 contT">
    <div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Mitt Konto</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1 class="text-center">Welcome "{{userData?.name}}"</h1>
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->


<!-- listing-->
<section class="animated3 wow drop_eff5">
    <div class="container" *ngIf="userData">
        <div class="row justify-content-center">

            <div class="col-md-9">
                <div class="list_box m-0 p-5">
                    <div class="row">
                        <div class="col-md-3 pb-4 text-center rel"><a href="javascript:void(0);" routerLink="/tutor-edit-profile" class="del1"><i
										class="far fa-edit"></i></a><img [src]="allowurl(apiService.imageBase+userData?.image)" class="img-fluid radius15 m-auto" alt=""></div>
                        <div class="col-md-9">
                            <div class="">
                                <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
                                <h1 class="float-left ">{{userData?.name}}</h1><a href="javascript:void(0);" routerLink="/tutor-edit-profile"><i class="far fa-edit "></i></a>
                                <p class="mt-2 fs22 float-right "><b>$50.00</b><span class="fs14 ml-2">(per
											hour)</span></p>
                                <p class="clearfix"></p>
                                <p class="fs12 ">{{userData?.city_name}}, {{userData?.state_name}}, {{userData?.country_name}}</p>

                                <p class="fs12 mt-4 pr-2 ">{{userData?.bio}}
                                    <a href="javascript:void(0);" routerLink="/tutor-edit-profile"><i class="far fa-edit "></i></a></p>
                                <p class="bdrb mt-3 mb-3"><img src="images/spacer.gif" alt=""></p>
                                <div class="row mt-4 text-center">
                                    <div class="col-md-4 add_cart"><a href="chat-me.htm" target="_parent"><i
													class="fas fa-comments fs18"></i> </a>
                                        <p class="fs12 mt-1 ">Chat Me</p>
                                    </div>
                                    <!--<div class="col-md-4 add_cart"><a href="book-meeting.htm" target="_parent"><i class="fas fa-handshake fs18"></i></a> <p class="fs12 lh15 mt-1 ">Book A 15 Minuts Meeting</p></div>-->
                                    <div class="col-md-4 add_cart"><a href="book-class.htm" target="_parent"><i
													class="fas fa-laptop fs18"></i> </a>
                                        <p class="fs12 mt-1 ">Intresseanmälan </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-4">
                        <div class="row">
                            <div class="col-md-12 p-0">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="list_box bg-gray text-center pt-5 pb-5 rel">
                                            <a href="javascript:void(0);" routerLink="/tutor-edit-profile" class="del1"><i class="far fa-edit"></i></a>
                                            <p class="b mb-4">Language</p>
                                            <p>{{userData?.language}}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="list_box bg-gray text-center pt-5 pb-5 rel">
                                            <a href="javascript:void(0);" routerLink="/tutor-edit-profile" class="del1"><i class="far fa-edit"></i></a>
                                            <p class="b mb-4">Total Exp. as tutor</p>
                                            <p>{{userData?.work_experience}} Years</p>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-4">
                                        <div class="list_box bg-gray text-center pt-5 pb-5">
                                            <p class="b mb-4">Totala klass Erfarenhet</p>
                                            <p>10 Years, 11 Months</p>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <!--<div class="pt-5 pb-5 bdrb">
					<div class="row">
						<div class="col-md-12 p-0">
							<p class="b mb-4">Video Introduction</p>
							<div class="row">
								<div class="col-md-6">
									<iframe width="100%" height="200" src="https://www.youtube.com/embed/kCHeKc2R96o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="border-radius: 15px;"></iframe>
								</div>
								<div class="col-md-6">
									<iframe width="100%" height="200" src="https://www.youtube.com/embed/kCHeKc2R96o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="border-radius: 15px;"></iframe>
								</div>
							</div>	
						</div>
					</div>
				</div>-->

                <div class="pt-5 pb-5 bdrb">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <p class="b mb-4 rel">About My Qualifications<a href="javascript:void(0);" routerLink="/tutor-edit-profile" class="del1"><i
											class="far fa-edit"></i></a></p>
                            <div class="row">
                                <div class="col-md-12 p-0 rel">
                                    {{userData?.qualification}}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="pt-5 pb-5 bdrb">
                    <div class="row">
                        <div class="col-md-12 p-0 rel">
                            <p class="b mb-4">About My Achievements and Teaching Strategy<a href="javascript:void(0);" routerLink="/tutor-edit-profile" class="del1"><i
											class="far fa-edit"></i></a></p>
                            <div class="row">
                                <div class="col-md-12 p-0 rel">
                                    {{userData?.achievement}}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="pt-5 pb-5 bdrb">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <p class="b mb-4 rel">Certifications<a href="javascript:void(0);" routerLink="/tutor-edit-profile" class="del1"><i
											class="far fa-edit"></i></a></p>
                            <div class="row">
                                <div class="col-md-12 p-0 rel" *ngFor="let cert of userData?.certificates">
                                    {{cert?.certificate_title}} - {{cert?.completion_year}}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="pt-5 pb-5 bdrb">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <p class="b mb-4 rel">Expertise<a href="javascript:void(0);" routerLink="/tutor-edit-profile" class="del1"><i class="far fa-edit"></i></a>
                            </p>
                            <div class="row">
                                <div class="col-md-12 p-0 rel">
                                    I have a certificate in TEFL, and a track record of helping students to reach their goals - whether that be finding the perfect turn of phrase in an essay or passing an exam with flying colours. English Literature and French (Bachelors) - University of
                                    Warwick History of Art (Masters) - Courtauld Institute of Art
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="pt-5 pb-5 bdrb p-0">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <p class="b mb-4 rel">Subject Taught<a href="javascript:void(0);" routerLink="/tutor-edit-profile" class="del1"><i
											class="far fa-edit"></i></a></p>
                            <div class="row list_box">
                                <div class="col-md-3 b p-2 mob_hide">Curriculum Type</div>
                                <div class="col-md-3 b p-2 mob_hide">Subject</div>
                                <div class="col-md-3 b p-2 mob_hide">Level</div>
                                <div class="col-md-3 b p-2 mob_hide">Hourly Rate</div>

                                <div class="col-md-12 mob_hide">
                                    <hr>
                                </div>

                                <div class="col-md-3 p-2">British(Including IGCCSE)</div>
                                <div class="col-md-3 p-2">English</div>
                                <div class="col-md-3 p-2">Key Stage 4/GCSE</div>
                                <div class="col-md-3 p-2">RM 115.00</div>

                                <div class="col-md-12">
                                    <hr>
                                </div>

                                <div class="col-md-3 p-2">British(Including IGCCSE)</div>
                                <div class="col-md-3 p-2">English</div>
                                <div class="col-md-3 p-2">Key Stage 4/GCSE</div>
                                <div class="col-md-3 p-2">RM 115.00</div>

                                <div class="col-md-12">
                                    <hr>
                                </div>

                                <div class="col-md-3 p-2">British(Including IGCCSE)</div>
                                <div class="col-md-3 p-2">English</div>
                                <div class="col-md-3 p-2">Key Stage 4/GCSE</div>
                                <div class="col-md-3 p-2">RM 115.00</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pt-5 pb-5 bdrb p-0">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <p class="b mb-4 rel">General Availability(Tutor's availability is shown in your local time zone.)<a href="javascript:void(0);" routerLink="/tutor-edit-profile" class="del1"><i class="far fa-edit"></i></a></p>
                            <div class="row list_box">
                                <div class="col-md-3 b p-2 mob_hide">Day</div>
                                <div class="col-md-3 b p-2 mob_hide">06:00 - 09:00</div>
                                <div class="col-md-3 b p-2 mob_hide">09:00 - 12:00</div>
                                <div class="col-md-3 b p-2 mob_hide">12:00 - 15:00</div>

                                <div class="col-md-12 mob_hide">
                                    <hr>
                                </div>

                                <div class="col-md-3 p-2 b">Monday</div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>

                                <div class="col-md-12">
                                    <hr>
                                </div>

                                <div class="col-md-3 p-2 b">Tuesday</div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>

                                <div class="col-md-12">
                                    <hr>
                                </div>

                                <div class="col-md-3 p-2 b">Wednesday</div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>

                                <div class="col-md-12">
                                    <hr>
                                </div>

                                <div class="col-md-3 p-2 b">Thursday</div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>

                                <div class="col-md-12">
                                    <hr>
                                </div>

                                <div class="col-md-3 p-2 b">Monday</div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>

                                <div class="col-md-12">
                                    <hr>
                                </div>

                                <div class="col-md-3 p-2 b">Friday</div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>

                                <div class="col-md-12">
                                    <hr>
                                </div>

                                <div class="col-md-3 p-2 b">Saturday</div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>

                                <div class="col-md-12">
                                    <hr>
                                </div>

                                <div class="col-md-3 p-2 b">Sunday</div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                                <div class="col-md-3 p-2 green"><i class="fas fa-check"></i></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pt-5 pb-5 bdrb p-0">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <p class="b mb-4">Ratings and Recensioner</p>
                            <div class="row">
                                <div class="col-md-12 p-0">
                                    <ul class="revies">
                                        <li>
                                            <div class="star">
                                                <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i> <span>(5.0)</span>
                                            </div>
                                            <h5>Madison Nesting Coffee Tables<span>2021-1-4</span></h5>
                                            <p class="mt-2">Madison is compact and easy to move around the home or office.
                                            </p>
                                        </li>
                                        <li>
                                            <div class="star">
                                                <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i> <span>3.0</span>
                                            </div>
                                            <h5>Madison Nesting Coffee Tables<span>2021-1-4</span></h5>
                                            <p class="mt-2">Madison is compact and easy to move around the home or office.
                                            </p>
                                        </li>
                                        <li>
                                            <div class="star">
                                                <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i> <span>2.0</span>
                                            </div>
                                            <h5>Madison Nesting Coffee Tables<span>2021-1-4</span></h5>
                                            <p class="mt-2">Madison is compact and easy to move around the home or office.
                                            </p>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- listing Ends -->