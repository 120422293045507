import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';

import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {
  cmsData: any=[];
  
  siteData: any=[];
  flag:any=null;
  LoginUserDetails: any;
  constructor(public apiService :ApiService,public sanitizer: DomSanitizer,private title: Title, private meta: Meta,public activatedRoute: ActivatedRoute,public commonService:CommonService) {
    this.flag = this.activatedRoute.snapshot.paramMap.get("flag");
    this.commonService.SetFooterContent(false);

   }

  ngOnInit(): void {
    this.getData();
    this.commonService.GetSetting().subscribe((data:any)=> {
      this.siteData=data;
    })
    this.commonService.GetLoginUserDetails().subscribe((data: any) => {
      this.LoginUserDetails = data;
      console.log('LoginUserDetails>>>>>>', this.LoginUserDetails.role_id);
    })
  }
  getData(){
    var self = this;
    var sendRequestData = {
      slug: self.flag
    };
    
    this.apiService.post('cms_details',sendRequestData).subscribe((data:any) =>{
      this.cmsData=data.data;
      console.log('cmsData>>>>>>',this.cmsData);
      this.title.setTitle(this.cmsData.meta_title);
      this.meta.updateTag({ name: 'keyword', content: this.cmsData.meta_keyword });
      this.meta.updateTag({ name: 'description', content: this.cmsData.meta_description });
    } 
    );
    
  }
  onOpenRegisterPopup(){
    localStorage.setItem('register_pop_up',"0");
    this.commonService.SetRegisterPopup(true);
  }
  onOpenRegisterPopup1(){
    localStorage.setItem('register_pop_up',"1");
    this.commonService.SetRegisterPopup(true);
  }
  onOpenRegisterPopup2(){
    localStorage.setItem('register_pop_up',"2");

    this.commonService.SetRegisterPopup(true);
  }
  allowurl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
