import { ApiService } from '../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-book-course',
  templateUrl: './book-course.component.html',
  styleUrls: ['./book-course.component.scss'],
  providers:[DatePipe],
})
export class BookCourseComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any = [];

  uploadedImagesData: any = '';
  fileToUpload: any = null;
  localData: any;
  teacherId:any;
  createuser: FormGroup | any; public submitted = false;
  constructor(private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService,public activatedRoute: ActivatedRoute,private datePipe: DatePipe) {
    this.teacherId = this.activatedRoute.snapshot.paramMap.get("id");
    this.getTeacherDetails(this.teacherId);
    this.getTeacherSlotDate(this.teacherId);
    var jsonData: any = localStorage.getItem('user');
    this.localData = JSON.parse(jsonData);
  }
  get f() { return this.createuser.controls; }

  ngOnInit(): void {
    this.getTeacherSubject();
    this.getLevelList();
    this.createuser = this.fb.group({
      slot_id : new FormControl('', [Validators.required]),
      schedule_slot_date : new FormControl('', [Validators.required]),
      level_id : new FormControl('', [Validators.required]),
      subject_id  : new FormControl('', [Validators.required]),
      tution_for  : new FormControl('', [Validators.required]),
      tution_frequency   : new FormControl('', [Validators.required])
    });
  }
  
  allowurl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  onClosePopup() {
    this.commonService.SetAddSubject(false);
  }
  TeacherSLotDetails:any=[];
  getTeacherSlotDate(id:any){
    var sendrequestinput:any={teacher_id:id};
    this.apiService.post('student_teacher_schedule_days_list',sendrequestinput).subscribe((data:any) => {
      this.TeacherSLotDetails=data.data;
    })
  }
  TeacherDetails:any;
  getTeacherDetails(id:any){
    var sendrequestinput:any={user_id:id};
    this.apiService.post('teacher_details',sendrequestinput).subscribe((data:any) => {
      this.TeacherDetails=data.data;
    })
  }
  CategoryList:any=[];
  getTeacherSubject(){
    var sendrequestinput:any={id:1};
    this.apiService.post('category_list',sendrequestinput).subscribe((data:any) => {
      this.CategoryList=data.data;
    })
  }
  LevelList:any=[];
  getLevelList(){
    var sendrequestinput:any={id:1};
    this.apiService.post('age_group_list_dropdown',sendrequestinput).subscribe((data:any) => {
      this.LevelList=data.data;
    })
  }
  onSubmit() {
    this.submitted=true;
    console.log('this.createuser.value>>>>>>>>>>>>>>',this.createuser.value);
    
    if (this.createuser.invalid) {
      this.messageService.showWarning("Field's are required");
      return
    } else {
      var sendRequestData: any = this.createuser.value;
      sendRequestData.sid = localStorage.getItem('sid');
      sendRequestData.student_name = this.localData.name;
      sendRequestData.parents_name  = this.localData.parents_name;
      sendRequestData.phone  = this.localData.phone;
      sendRequestData.schedule_slot_date  = this.Schdule_Slot_Date;
      sendRequestData.schedule_slot_time  = this.Schdule_Slot_Time;
      sendRequestData.teacher_id  = this.teacherId;
      console.log('sendRequestData>>>>>>',sendRequestData);
      // return;
      this.apiService.post('student_subscription', sendRequestData).subscribe((data: any) => {
        if (data.replyCode == "success") {
          this.createuser.reset();
          this.submitted=false;
          this.onClosePopup();
          this.commonService.SetUpdateProfileChanged(true);
          this.messageService.showSuccess(data.replyMsg);
        } else {
          this.messageService.showError(data.replyMsg);
        }
      })
    }
  }
  SlotData:any=[];
  Schdule_Slot_Date:any='';
  Schdule_Slot_Time:any='';
  onSelectDate(event:any){
    console.log('event>>>>>>>>',event);
    console.log('event.value>>>>>>>>',event.value);
    this.SlotData=[];
    var filter=this.TeacherSLotDetails.filter((data:any) => data.id == event.value);
    console.log('filter>>>>>>>>',filter);
    if(filter.length>0){
      this.Schdule_Slot_Date=this.datePipe.transform(filter[0].schedule_date, 'yyyy-MM-dd');
      this.SlotData=filter[0].slots;
    }
  }
  onSelectTime(event:any){
    console.log('event>>>>>>>>',event);
    console.log('event.value>>>>>>>>',event.value);
    var filter= this.SlotData.filter((x:any)=>x.id == event.value);
    console.log('filter>>>>>>>>',filter);
    
    this.Schdule_Slot_Time=filter[0].time_from;
  }
}
