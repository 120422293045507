import { ApiService } from '../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';

@Component({
  selector: 'app-tutor-details',
  templateUrl: './tutor-details.component.html',
  styleUrls: ['./tutor-details.component.scss']
})
export class TutorDetailsComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any = [];

  uploadedImagesData: any = '';
  fileToUpload: any = null;
  localData: any;
  TutorId:any;
  currentRate = 0;
  createuser1: FormGroup | any; public submitted1 = false;
  SchedulerList: any;
  Edit_Flag: boolean = false;
  public Teacher_Details_Id: any;
  constructor(private route: ActivatedRoute,private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService, public activatedRoute: ActivatedRoute) {
    this.TutorId = this.activatedRoute.snapshot.paramMap.get("id");
    this.getTutorDetails(this.TutorId);
    this.GetSchedulerList();
    var jsonData: any = localStorage.getItem('user');
    this.localData = JSON.parse(jsonData);
    this.commonService.SetFooterContent(true);

  }
  get f1() { return this.createuser1.controls; }
  TutorDetails:any=[];
  // self_intro:any=[]
  Loader_Flag:boolean=false;
  ;
  self_intro1: SafeResourceUrl | undefined;

  getTutorDetails(id:any){
    this.apiService.post('teacher_details',{ user_id: id }).subscribe((data:any) => {
      this.TutorDetails=data.data;
      console.log(this.TutorDetails.educations,'this.TutorDetails.self_intro');
      
      this.self_intro1=this.sanitizer.bypassSecurityTrustResourceUrl(this.TutorDetails.self_intro)
console.log(this.self_intro1,'self_intro');
this.Loader_Flag=false; 


    })
  }
  allowurl(url:any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  isLoggedInStatus:boolean=false;
  isUserLoggedInData:any={};
  ngOnInit(): void {
    console.log(this.route.snapshot.params.id,'fooog');
    this.teacher_subject();
    this.teacher_avalible();
    this.commonService.GetLoggedIn().subscribe(data =>{
      console.log(data);
      this.isLoggedInStatus=data;
      console.log(this.isLoggedInStatus,'narender');
      
    })
    this.commonService.GetLoginUserDetails().subscribe(data =>{
      console.log(data);
      this.isUserLoggedInData=data;
      console.log(this.isUserLoggedInData,'narender');

    })
    this.getTeacherSubject();
    this.getLevelList();
    this.count_complete_lession();
    this.createuser1 = this.fb.group({
      review : new FormControl('', [Validators.required]),
      rating : new FormControl('', [Validators.required])
    });
  }
    onOpenLoginPopup(){
    
    this.commonService.SetLoginPopup(true);
  }
  onClosePopup() {
    this.commonService.SetAddSubject(false);
  }
  CategoryList:any=[];
  getTeacherSubject(){
    var sendrequestinput:any={id:1};
    this.apiService.post('category_list',sendrequestinput).subscribe((data:any) => {
      this.CategoryList=data.data;
    })
  }
  count_lession:any;
  count_complete_lession(){
    var sendrequestinput:any={id:this.TutorId};
    this.apiService.post('count_complete_lession',sendrequestinput).subscribe((data:any) => {
      console.log(data,typeof data.data.total,'asdfa');
      
      this.count_lession=data.data.total;
    })
  }
  LevelList:any=[];
  getLevelList(){
    var sendrequestinput:any={id:1};
    this.apiService.post('age_group_list_dropdown',sendrequestinput).subscribe((data:any) => {
      this.LevelList=data.data;
    })
  }
  getReviewList(){
    var sendrequestinput:any={id:1};
    this.apiService.post('age_group_list_dropdown',sendrequestinput).subscribe((data:any) => {
      this.LevelList=data.data;
    })
  }
  onSubmit() {
    if(this.isLoggedInStatus){
      this.submitted1=true;
      if (this.createuser1.invalid) {
        this.messageService.showWarning('Please add an rating and review')
        return
      } else {
        var sendRequestData: any = this.createuser1.value;
        sendRequestData.student_id = this.localData.id;
        sendRequestData.teacher_id = this.TutorId;
        console.log('sendRequestData',sendRequestData)
        // return;
        this.apiService.post('create_review', sendRequestData).subscribe((data: any) => {
          if (data.replyCode == "success") {
            this.createuser1.reset();
            this.submitted1=false;
            this.getTutorDetails(this.TutorId);
            this.messageService.showSuccess(data.replyMsg);
          } else {
            this.messageService.showError(data.replyMsg);
          }
        })
      }
    }else{
      this.messageService.showError('Please login here.')
    }
    
  }
  teacher_subjects:any=[];
  
  teacher_subject(){
    var sendrequestinput:any={user_id:this.route.snapshot.params.id};
    this.apiService.post('teacher_subjects_list',sendrequestinput).subscribe((data:any) => {
      this.teacher_subjects=data.data;
      console.log(data,'subject');
    })
  }
  teacher_avalibles:any=[];

  teacher_avalible(){
    var sendrequestinput:any={user_id:this.route.snapshot.params.id};
    this.apiService.post('teacher_avalible',sendrequestinput).subscribe((data:any) => {
      this.teacher_avalibles=data.data;
      console.log(data,'available');
      
    })
  }
  GetSchedulerList() {
    this.apiService.post('teacher_schedule_days_list', { user_id:this.route.snapshot.params.id }).subscribe((res: any) => {
      if (!res.error) {
        if (res.replyCode == 'success') {
          if (res.data.length > 0) {
            this.SchedulerList = res.data;
            

            console.log('SchedulerList::::', this.SchedulerList);
            if (this.SchedulerList.available == 1) {
              // this.onDateSelect(this.SchedulerList.available,this.SchedulerSlotID,this.Index_Value);
            } else {
              // this.onDateSelect(this.SchedulerSlotID,this.Index_Value);
            }
          } else {
            this.Teacher_Details_Id = '';
            this.Edit_Flag = true;
          }
        }
      }
    }, error => {
      this.messageService.showError("Oops! Something went wrong!");
    });
  }
  
}
