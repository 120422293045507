<div class="mb-3 contT">
    <div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Mitt Konto</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1 class="float-left"><span class="txt_gray mob_hide">Welcome To </span>My Class</h1>
        <!-- <p class="float-right"><a href="#" data-toggle="modal" data-target="#add" class="compare"><i
                    class="fas fa-plus"></i> Add Course</a></p> -->
        <p class="clearfix"></p>
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->


<!-- listing-->
<section class="animated3 wow drop_eff5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <!-- #BeginLibraryItem "/Library/tutor-left-link.lbi" -->
                    <!-- #EndLibraryItem -->
                    <div class="col-lg-9 wow fadeInLeft">
                        <div class="list_box p-4 fs13 rel">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 pb-3">
                                    <p>Course Name</p>
                                    <p class="b mt-2"><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Booked Date</p>
                                    <p class="b mt-2">Friday, July 20, 2021 - 4:30pm</p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Payment</p>
                                    <p class="b mt-2">Paid</p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Price</p>
                                    <p class="b mt-2">$30.00/hr</p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p><a href="#" data-toggle="modal" data-target="#inv" class="compare">View
                                            Invoice</a></p>
                                </div>
                                <div class="col-lg-12 col-md-12 bdrb"></div>
                                <div class="col-lg-12 col-md-12 pt-4">
                                    <div class="row">
                                        <div class="col-md-6 p-0"></div>
                                        <div class="col-md-3 p-0 pl-3"><a href="#" data-toggle="modal" data-target="#res" class="compare">Reschedule</a></div>
                                        <div class="col-md-3 p-0 pl-3"><a href="#" data-toggle="modal" data-target="#cr" class="compare">Cancel &amp; Refund</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="list_box p-4 fs13 rel">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 pb-3">
                                    <p>Course Name</p>
                                    <p class="b mt-2"><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Booked Date</p>
                                    <p class="b mt-2">Friday, July 20, 2021 - 4:30pm</p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Payment</p>
                                    <p class="b mt-2">Paid</p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Price</p>
                                    <p class="b mt-2">$30.00/hr</p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p><a href="#" data-toggle="modal" data-target="#inv" class="compare">View
                                            Invoice</a></p>
                                </div>
                                <div class="col-lg-12 col-md-12 bdrb"></div>
                                <div class="col-lg-12 col-md-12 pt-4">
                                    <div class="row">
                                        <div class="col-md-6 p-0"></div>
                                        <div class="col-md-3 p-0 pl-3"><a href="#" data-toggle="modal" data-target="#res" class="compare">Reschedule</a></div>
                                        <div class="col-md-3 p-0 pl-3"><a href="#" data-toggle="modal" data-target="#cr" class="compare">Cancel &amp; Refund</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="list_box p-4 fs13 rel">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 pb-3">
                                    <p>Course Name</p>
                                    <p class="b mt-2"><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Booked Date</p>
                                    <p class="b mt-2">Friday, July 20, 2021 - 4:30pm</p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Payment</p>
                                    <p class="b mt-2">Paid</p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Price</p>
                                    <p class="b mt-2">$30.00/hr</p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p><a href="#" data-toggle="modal" data-target="#inv" class="compare">View
                                            Invoice</a></p>
                                </div>
                                <div class="col-lg-12 col-md-12 bdrb"></div>
                                <div class="col-lg-12 col-md-12 pt-4">
                                    <div class="row">
                                        <div class="col-md-6 p-0"></div>
                                        <div class="col-md-3 p-0 pl-3"><a href="#" data-toggle="modal" data-target="#res" class="compare">Reschedule</a></div>
                                        <div class="col-md-3 p-0 pl-3"><a href="#" data-toggle="modal" data-target="#cr" class="compare">Cancel &amp; Refund</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- listing Ends -->