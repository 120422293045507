<div class="mb-3 ptfix">
    <div class="container pt-4 pl-3">
        <ul class="breadcrumb">
            <li><a routerLink="/">Home</a></li>
            <li><a routerLink="/product-listing/{{resData?.category_id}}">{{resData?.category_name}}</a></li>
            <li class="active">{{resData?.title}}</li>
        </ul>
    </div>
   
</div>
<div class="clearfix"></div>
<!--head+filter end-->

<!--listing-->
<div class="container mt-5">
    <div class="row">
        
        <div class="col-md-6 p-5 text-center rel">
            <div class="scroll-box">
                <p class="mb-2">
                   <a href="{{apiService.imageBase+resData?.image}}" data-fancybox="gallery">
                     <img [src]="allowurl(apiService.imageBase+resData?.image)" alt="{{resData?.image}}" class="img-fluid"/>
                    </a>
                </p>
                <p class="mb-2" *ngIf="resData?.image_1">
                    <a href="{{apiService.imageBase+resData?.image_1}}" data-fancybox="gallery">
                        <img [src]="allowurl(apiService.imageBase+resData?.image_1)" alt="{{resData?.image_1}}" class="img-fluid"/>
                       </a>
                </p>
                <p class="mb-2" *ngIf="resData?.image_2">
                    <a href="{{apiService.imageBase+resData?.image_2}}" data-fancybox="gallery">
                        <img [src]="allowurl(apiService.imageBase+resData?.image_2)" alt="{{resData?.image_2}}" class="img-fluid"/>
                       </a>
                </p>
                <p class="mb-2" *ngIf="resData?.image_3">
                    <a href="{{apiService.imageBase+resData?.image_3}}" data-fancybox="gallery">
                        <img [src]="allowurl(apiService.imageBase+resData?.image_3)" alt="{{resData?.image_3}}" class="img-fluid"/>
                       </a>
                </p>
            </div>
            <div class="cover-bar"></div>
        </div>
        

        <div class="col-md-6 pt-5 pb-3 pl-5 pr-5 bggray">
            <div class="dtl_r_1">
                <h1>{{resData?.title}}</h1>
                <p class="fs12 mt-3">Product Code : {{resData?.code}}</p>

                <p class="fs13 mt-4 pr-2"  [innerHtml]="resData?.description"></p>
                <p class="bdrb mt-3 mb-3"><img src="/assets/images/spacer.gif" alt=""></p>
               
                <div class="row mt-2 mb-2" *ngIf="resData.colors.length>0">
                    <div class="col-md-12 b">Colours Available</div>
                    <div class="col-md-12 color_cir">
                        <a href="javascript:void(0);" class="col1" *ngFor="let clr of resData.colors; let i= index" [ngStyle]="{
                            background:clr.color}" style="border-color: #000;"></a>
                            
                    </div>
                </div>

                <!-- <p class="bdrb mb-3"><img src="images/spacer.gif" alt=""></p> -->
                <div class="row mt-4">
                    <div class="col-lg-12 wow fadeInLeft newsletter">
                        <form [formGroup]="addForm" (ngSubmit)="onaddForm()">
                            <input type="hidden" formControlName="product_id" value="{{resData?.id}}">
                            <div class="mb-3">
                                <p>Your First Name</p>
                                <input type="text" mdbInput class="form-control" formControlName="first_name"
                                placeholder="First Name *"
                                [class.is-invalid]="addForm.get('first_name').invalid && addForm.get('first_name').touched">
                                
                            </div>
                            
                            <div class="mb-3">
                                <p>Your Last Name</p>
                                <input type="text" mdbInput class="form-control" formControlName="last_name"
                                placeholder="Last Name *"
                                [class.is-invalid]="addForm.get('last_name').invalid && addForm.get('last_name').touched">
                                
                            </div>

                            <div class="mb-3">
                                <p>Your Address</p>
                                <input type="text" mdbInput class="form-control" formControlName="address"
                                placeholder="Address *"
                                [class.is-invalid]="addForm.get('address').invalid && addForm.get('address').touched">
                            </div>
                            <div class="mb-3">
                                <p>Your Email</p>
                                <input type="text" mdbInput class="form-control" formControlName="email"
                                placeholder="Email ID *"
                                [class.is-invalid]="addForm.get('email').invalid && addForm.get('email').touched">
                            </div>
                            <div class="mb-3">
                                <p>Your Contact Number</p>
                                <input type="text" mdbInput class="form-control" formControlName="mobile"
                                placeholder="Mobile No. *"
                                [class.is-invalid]="addForm.get('mobile').invalid && addForm.get('mobile').touched">
                            
                            </div>
                            <div class="mb-3">
                                <p>Comment</p>
                                <textarea name="description" id="description" cols="30" rows="5"
                                placeholder="Comment/Message *" class="form-control" formControlName="comment"></textarea>
                            </div>
                            <div class="mb-3">
                                <button class="nbtn1 text-center ttu mt-5" mdbBtn type="submit">Submit</button>
                                
                            </div>
                        </form>
                        
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>
<!--listing end-->

<!--ads-->
<div class="container mt-5">
    <div class="row">
        <div class="col-md-12 pt-5 pb-3 pl-5 pr-5 pb-5">
            <h2 class="black">Product Details</h2>
            <p class="mt-4 fs13"  [innerHtml]="resData?.description" >
            </p>
        </div>

    </div>


</div>
<div class="clearfix"></div>