<div class="mb-3 contT">
   <div class="container pl-3">
      <ul class="breadcrumb">
         <li><a href="index.htm">Home</a></li>
         <!--<li><a href="listing.htm">Chair</a></li>-->
         <li class="active">Class Dashboard</li>
      </ul>
   </div>
   <div class="container pl-3 pr-3">
      <h1><span class="txt_gray mob_hide">Class </span>Dashboard</h1>
   </div>
</div>

<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
  <div class="container mb-5">
    <div class="row justify-content-center">
      dfgdfgdsg
      </div>
    </div>
</section>