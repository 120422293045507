import { ApiService } from '../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { DatePipe } from '@angular/common';
import {Location} from '@angular/common';

@Component({
  selector: 'app-demo-class',
  templateUrl: './demo-class.component.html',
  styleUrls: ['./demo-class.component.scss'],
  providers:[DatePipe],
})
export class DemoClassComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any = [];

  uploadedImagesData: any = '';
  fileToUpload: any = null;
  localData: any;
  teacherId:any;
  createuser: FormGroup | any; public submitted = false;
  constructor(private _location: Location,private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService,public activatedRoute: ActivatedRoute,private datePipe: DatePipe) {
    this.teacherId = this.activatedRoute.snapshot.paramMap.get("id");
    this.getTeacherDetails(this.teacherId);
    this.getTeacherSlotDate(this.teacherId);
    var jsonData: any = localStorage.getItem('user');
    this.localData = JSON.parse(jsonData);
  }
  get f() { return this.createuser.controls; }

  ngOnInit(): void {
    this.getTeacherSubject();
    this.getLevelList();
    this.createuser = this.fb.group({
      slot_id : new FormControl('', [Validators.required]),
      schedule_slot_date : new FormControl('', [Validators.required]),
      level_id : new FormControl('', [Validators.required]),
      subject_id  : new FormControl('', [Validators.required]),
      tution_for  : new FormControl('', [Validators.required]),
      tution_frequency   : new FormControl('', [Validators.required])
    });
  }
  backClicked() {
    this._location.back();
  } 
  allowurl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  onClosePopup() {
    this.commonService.SetAddSubject(false);
  }
  TeacherSLotDetails:any=[];

  getTeacherSlotDate(id:any){
    console.log(new Date());
    console.log(new Date());
    console.log(new Date());
    console.log(new Date());
    
    var sendrequestinput:any={teacher_id:id};
    this.apiService.post('student_teacher_schedule_days_list',sendrequestinput).subscribe((data:any) => {
      this.TeacherSLotDetails=data.data;
    })
  }
  TeacherDetails:any;
  getTeacherDetails(id:any){
    var sendrequestinput:any={user_id:id};
    this.apiService.post('teacher_details',sendrequestinput).subscribe((data:any) => {
      this.TeacherDetails=data.data;
      console.log(this.TeacherDetails,'rateee');
      
    })
  }
  CategoryList:any=[];
  getTeacherSubject(){
    var sendrequestinput:any={id:1};
    this.apiService.post('category_list',sendrequestinput).subscribe((data:any) => {
      this.CategoryList=data.data;
    })
  }
  LevelList:any=[];
  getLevelList(){
    var sendrequestinput:any={id:1};
    this.apiService.post('age_group_list_dropdown',sendrequestinput).subscribe((data:any) => {
      this.LevelList=data.data;
    })
  }
  superpayment(data:any){
    this.commonService.Setpayments(data);
  }
  class_data:any=[]
  onSubmit() {
    this.submitted=true;
    this.createuser.get('slot_id').markAsTouched();
    this.createuser.get('schedule_slot_date').markAsTouched();
    this.createuser.get('level_id').markAsTouched();
    this.createuser.get('subject_id').markAsTouched();
    this.createuser.get('tution_for').markAsTouched();
    this.createuser.get('tution_frequency').markAsTouched();
    console.log('this.createuser.value>>>>>>>>>>>>>>',this.createuser.value);
    
    if (this.createuser.invalid) {
      this.messageService.showWarning("Field's are required");
      return
    } else {

      
      var sendRequestData: any = this.createuser.value;
                  sendRequestData.class_type  = 1;

      sendRequestData.sid = localStorage.getItem('sid');
      sendRequestData.student_name = this.localData.name+ ' '+ this.localData?.last_name;
      sendRequestData.parents_name  = this.localData.parents_name;
      sendRequestData.phone  = this.localData.phone;
      sendRequestData.schedule_slot_date  = this.Schdule_Slot_Date;
      sendRequestData.schedule_slot_time  = this.Schdule_Slot_Time;
      sendRequestData.teacher_id  = this.teacherId;
      sendRequestData.rate  = this.TeacherDetails.rate;

      console.log('sendRequestData>>>>>>',sendRequestData);
   
      this.apiService.post('book_slot_student', sendRequestData).subscribe((data: any) => {

        var json:any=data.data
        json.rate= this.TeacherDetails.rate
        var json2:any=JSON.stringify(json)
        console.log(json2,'json2');
        
        localStorage.setItem('book_slot_student',json2)
 
        // var sendRequestData: any = {};
     sendRequestData.id = this.localData.id;
     var sendRequestData_c = this.localData;
     sendRequestData_c.book_class_data = data.data;
     console.log(sendRequestData_c,'log');
     
 
 
     this.apiService.post('generate_jwt', sendRequestData).subscribe((resp: any) => {
 
       console.log(resp.access_token, 'generate_jwt');
       sendRequestData_c.access_token = resp.access_token;
     
         sendRequestData_c.userId  = this.TeacherDetails?.merithub_client_id?this.TeacherDetails?.merithub_client_id:'cgraqa9nuvt14lbtoebg';
         
         console.log(sendRequestData_c, 'sendRequestData_c');
         this.apiService.post('merithub_new_classes', sendRequestData_c).subscribe((respon: any) => {
          // this.apiService.post('cashbackOfferId',sendRequestData).subscribe((data:any) => {
            // localStorage.setItem('supertoken',JSON.stringify(data.data))
              // this.superpayment(data)
              // console.log(data.data,'narender');
              // console.log(data.data.transactionId,'narender');
              // sendRequestData_c.transactionId = data.data.transactionId
              // console.log(sendRequestData_c,'/sendRequestData_c');
     
            //  localStorage.setItem('class_data',JSON.stringify(sendRequestData_c))
            //  this.superpayment(data)
            if(respon.replyCode=="success"){
          this.messageService.showSuccess("class created");

            }else{
          this.messageService.showError('Class cannot be scheduled in the past');

              
            }
          //  })
          //  console.log(respon, 'merithub_new_classes');

         })
 
 
   })
 

        if (data.replyCode == "success") {
              
          // this.superpayment(data)
          this.createuser.reset();
          this.submitted=false;
          this.onClosePopup();
          this.commonService.SetUpdateProfileChanged(true);
          // this.messageService.showSuccess(data.replyMsg);
        } else {
          this.messageService.showError(data.replyMsg);
        }
      })
    }
  }
  SlotData:any=[];
  Schdule_Slot_Date:any='';
  Schdule_Slot_Time:any='';
  onSelectDate(event:any){
    console.log('event>>>>>>>>',event);
    console.log('event.value>>>>>>>>',event.value);
    this.SlotData=[];
    var filter=this.TeacherSLotDetails.filter((data:any) => data.id == event.value);
    console.log('filter>>>>>>>>',filter);
    if(filter.length>0){
      this.Schdule_Slot_Date=this.datePipe.transform(filter[0].schedule_date, 'yyyy-MM-dd');
      this.SlotData=filter[0].slots;
    }
  }
  onSelectTime(event:any){
    console.log('event>>>>>>>>',event);
    console.log('event.value>>>>>>>>',event.value);
    var filter= this.SlotData.filter((x:any)=>x.id == event.value);
    console.log('filter>>>>>>>>',filter);
    
    this.Schdule_Slot_Time=filter[0].time_from;
  }
}
