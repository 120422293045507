import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
 
  FaqList: any=[];
  activeFlag1:boolean=true;
  activeFlag2:boolean=false;
  bodyRequestData:any={};
  constructor(public apiService :ApiService,public commonService:CommonService) {
    this.commonService.SetFooterContent(false);
   }

  ngOnInit(): void {
    this.bodyRequestData['isAdmin']='0';
    this.bodyRequestData['type']='1';
    this.getFaqList(this.bodyRequestData);
  }
  getFaqList(bodyRequestData:any){
    this.apiService.post('faqs_list',bodyRequestData).subscribe((data:any) => {
      this.FaqList=data.data;
     
    })
  }
  onChangeType(type:any){
    this.bodyRequestData['type']=type;
    if(type ==1){
      this.activeFlag1=true;
      this.activeFlag2=false;
    }else{
      this.activeFlag1=false;
      this.activeFlag2=true;
    }
    this.getFaqList(this.bodyRequestData);
  }
  onSearchKey(event:any){
    this.bodyRequestData['keyword']=event.value;
    this.getFaqList(this.bodyRequestData);
  }
}
