import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';

import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
 
  Teamist: any=[];
  constructor(public apiService :ApiService,public sanitizer: DomSanitizer,public commonService:CommonService) {
    this.commonService.SetFooterContent(false);
   }

  ngOnInit(): void {
    this.getTeamList();
  }
  getTeamList(){
    var sendrequestinput:any={isAdmin:'0'};
    this.apiService.post('brand_list',sendrequestinput).subscribe((data:any) => {
      this.Teamist=data.data;
     
    })
  }
  

  allowurl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
