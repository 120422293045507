import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { MessageService } from './../../services/message.service';
import { CommonService } from 'src/app/services';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any=[];
  public blogId:any;
  
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  blogDetailsList:any;
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,private activatedRoute: ActivatedRoute,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
  }

  ngOnInit(): void {

    this.blogId = this.activatedRoute.snapshot.paramMap.get("id");
    console.log(this.blogId,'this.blogId')
   // alert(this.blogId);
    this.loadData();
  }

  public loadData(){
      var sendrequestinput:any = {'id':this.blogId,'slug':''};
      this.apiService.post('blog_details',sendrequestinput).subscribe((data:any) => {
        console.log(data)
        this.blogDetailsList = data.data;
      })
  }

   allowurl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public back(){
    this.router.navigate(['/blog']);
  }

}
