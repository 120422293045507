<div class="mb-3 ptfix">
    <div class="container pt-4 pl-3">
        <ul class="breadcrumb">
            <li><a routerLink="/">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Category</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide">OUR PRODUCT 
        </span>CATEGORIES</h1>            
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->


<!--listing-->
<div class="container mt-5 mb-5">
    <div class="row">
        <div class="col-md-3"  *ngFor="let pro of resData; let i= index">
            <div class="list_box">
                <a routerLink="/product-listing/{{pro.id}}">
                    <img [src]="allowurl(apiService.imageBase+pro.image)" alt="{{pro.title}}" class="img-fluid"/>
                    {{pro.name}}
                </a>
            </div>
        </div>
    </div>
</div>