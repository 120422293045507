<div class="mb-3 contT">
    <div class="container pl-3">
        <div class="row">
            <div class="col-md-12">
                <ul class="breadcrumb">
                    <li><a href="/">Home</a></li>
                    <li class="active" *ngIf="blogFlag == 0">Students' Blog</li>
                    <li class="active" *ngIf="blogFlag == 1">Tutors' Blog</li>
                    <li class="active" *ngIf="blogFlag == 2">Parents' Blog</li>
                </ul>
            </div>
        </div>

    </div>
</div>

<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
    <div class="container mb-5">
        <div class="row mt-5 justify-content-md-center">
            <div class="col-md-12">
                <div class="emp_area mb-4">
                    <div class="loc_feild">
                        <p class="rel"><a href="javascript:void(0);" (click)="onSearchSubmit()" class="abico"><i
                                    class="fas fa-search"></i></a><input type="text"
                                placeholder="Please enter a topic, question or keyword"
                                (keyup)="onSearch($event.target)" class="form-control"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-12" *ngFor="let bloglist of blogList">
                <div class="min-blog margin-30">
                    <div class="min-blog-images custom-img-css">
                        <a href="javascript:void(0);">
                            <img [src]="allowurl(apiService.imageBase+bloglist.image)" alt="" class="img-fluid">
                        </a>
                    </div>
                    <div class="blog-cont">
                        <h3>{{bloglist.title}}</h3>
                        <p class="bloglist-name">Posted by: {{bloglist.name}}</p>
                        <p class="mt-2 fix-cont">{{bloglist.body | slice:0:200}}..</p>
                        <p class="mt-2 Read_More mt-3"><a href="javascript:void(0);"
                                routerLink="/blog-details/{{bloglist.id}}" class="style1">Read More</a></p>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-3">
                <p class="fs18 b">Select any of the categories listed below or enter specific title  keywords</p>
                <p class="blog_link">
                    <a href="">All</a>
                    <a href="">Educational Advice</a>
                    <a href="">Exams &amp; Revision</a>
                    <a href="">Just For Fun</a>
                    <a href="">University</a>
                    <a href="">A Level</a>
                </p>
            </div> -->
        </div>
    </div>
</section>