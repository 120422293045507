<div class="mb-3 ptfix">
    <div class="container pt-4 pl-3">
        <ul class="breadcrumb">
            <li><a routerLink="/">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Calculator</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide"></span>Calculator</h1>
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->
<!-- Content Stars -->
<section class="animated3 wow drop_eff5">
    <div class="container mb-5 minh_600">
        <form [formGroup]="orderForm" class="form" style="padding: 0px;background: white;border-radius: 0
          px;border: none;">
            <div formArrayName="items" *ngFor="let item of orderForm.get('items').controls; let i = index;">
                <div [formGroupName]="i">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 wow fadeInLeft newsletter form_class_template">
                            <div class="row mt-4">
                                <div class="col-md-12 b black fs18 mb-3">Add A Piece</div>
                                <div class="col-md-3">
                                    <img src="assets/radio_check.png" (click)="getPanelList(0,i)" *ngIf="item.value.value1">
                                    <img src="assets/radio_uncheck.png" (click)="getPanelList(0,i)" *ngIf="!item.value.value1">
                                    <label for="type1{{i}}">Splashback</label>
                                </div>
                                <div class="col-md-3">
                                    <img src="assets/radio_check.png" (click)="getPanelList(1,i)" *ngIf="item.value.value2">
                                    <img src="assets/radio_uncheck.png" (click)="getPanelList(1,i)" *ngIf="!item.value.value2">
                                    <label for="type2{{i}}">Worktop</label>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-2" *ngFor="let panel of item.value.panel_list">
                                        <!-- <input type="radio" id="p-{{panel.id}}" name="radio-group3"> -->
                                        <label for="p-{{panel.id}}" [ngClass]="panel.id == item.value.panel_list_id ?'onSelected':''">
                                            <img [src]="allowurl(apiService.imageBase+panel.image)" alt="panel" (click)="getPanelDetails(panel.id,i)" />
                                        </label>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 b black fs18 mb-3">Template, supply and install - or - supply only?
                                </div>
                                <div class="col-md-12">
                                    <img src="assets/radio_check.png" (click)="onChangetype('template',$event,i)" *ngIf="item.value.templateFlag1">
                                    <img src="assets/radio_uncheck.png" (click)="onChangetype('template',$event,i)" *ngIf="!item.value.templateFlag1">
                                    <!-- <input type="radio" id="test1" name="radio-group"> -->
                                    <label for="test1" (click)="onChangetype('template',$event,i)">Template, Supply and Install</label>
                                </div>
                                <div class="col-md-12">
                                    <img src="assets/radio_check.png" (click)="onChangetype('supply',$event,i)" *ngIf="item.value.supplyFlag1">
                                    <img src="assets/radio_uncheck.png" (click)="onChangetype('supply',$event,i)" *ngIf="!item.value.supplyFlag1">
                                    <!-- <input type="radio" id="test2" name="radio-group"> -->
                                    <label for="test2" (click)="onChangetype('supply',$event,i)">Supply Only</label>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-3 form_box1 dn" style="display: block;">
                            <div class="col-lg-8 offset-md-2 wow fadeInLeft newsletter form_class_template">
                                <form action="#" class="form">
                                    <div class="row mt-4">
                                        <div class="col-md-12 b black fs18 mb-4 bdrb">
                                            <p class="float-left">
                                                <img [src]="allowurl(apiService.imageBase+item.value.panel_details.image)" alt="panel" class="mr-3" /> {{item.value.panel_details.panel_title}}
                                            </p>
                                            <p class="float-right"></p>
                                            <p class="clearfix"></p>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="col-md-12 b black mb-3">Add Option</div>
                                            <div class="col-md-12">
                                                <input type="checkbox" placeholder="" id="sink" (click)="onChangeoption('sink',$event,i)">
                                                <label for="c1" class="label1">Sink
                                                    Cutout</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" placeholder="" id="hon"
                                                    (click)="onChangeoption('hob',$event,i)"><label for="c1"
                                                    class="label1">HOB
                                                    Cutout</label>
                                            </div>
                                            <div class="col-md-12">
                                                <input type="checkbox" placeholder="" id="draining"
                                                    (click)="onChangeoption('draining',$event,i)"><label for="c1"
                                                    class="label1">Draining</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 row">
                                            <div class="col-md-12 b black mb-3">Add Your Measurements</div>
                                            <div class="col-md-3"><input type="text" placeholder="Length(mm)"
                                                    class="p-2 mb-2" (keyup)="onAddLengthWidth('length',$event,i)">
                                            </div>
                                            <div class="col-md-3"><input type="text" placeholder="Width(mm)"
                                                    class="p-2 mb-2" (keyup)="onAddLengthWidth('width',$event,i)"></div>
                                            <div class="col-md-3"><img
                                                    [src]="allowurl(apiService.imageBase+item.value.panel_details.image)"
                                                    alt="panel" /></div>
                                        </div>
                                        <div class="col-md-12 row mt-4">
                                            <div class="col-md-12 b black mb-3">Panel Location / Comments</div>
                                            <div class="col-md-6"><textarea name="" id="" cols="30" rows="5"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12 row mt-4">
                                            <div class="col-md-12 b black mb-3">Sub total : SEK  {{item.value?.netAmount + item.value?.vat}} </div>
                                            <div class="col-md-6">Cost : SEK  {{item.value?.netAmount}} </div>
                                            <div class="col-md-6">Vat : SEK  {{item.value?.vat}} </div>
                                        </div>
                                    </div>
                                    <p class="green ac mt-4"><a href="javascript:void(0)" (click)="addItem(this.orderForm.value.items.length+1)">Add
                                            Another Piece</a></p>
                                </form>
                            </div>
                        </div>
                        <p class="clearfix"></p>
                    </div>
                </div>
            </div>
        </form>
        <div class="row justify-content-center mt-3">
            <div class="col-lg-8 wow fadeInLeft newsletter">
                <p class="black fs18 mb-4 bdrb b">Summary</p>
                <div class="row">
                    <div class="col-md-8">
                        <p class="b">Number of Piece : {{panelCount}}</p>
                    </div>
                    <div class="col-md-8"  *ngFor="let panel of orderForm.value.items">
                        <p class="b">{{panel.panel_details.panel_title}}</p>
                        <p>{{panel.panel_details.description}}</p>
                        <hr>
                    </div>
                    <div class="col-md-4">
                        <p>Net Total (in SEK )<input type="text" placeholder="Net Total" class="p-2 mb-2" value="{{netAmount}}"></p>
                        <p>VAT (in SEK )<input type="text" placeholder="VAT @20.00%" class="p-2 mb-2" value="{{getVat(netAmount)}}">
                        </p>
                        <p>Gross Total (in SEK )<input type="text" placeholder="Gross Total" class="p-2 mb-2" value="{{gross}}">
                        </p>
                    </div>
                    <div class="col-md-12 text-right pt-4">
                        <div class="mb-3">
                            <span style="color: red;" *ngIf="errorF==1">Please fill values in Lenght and Width of Panel</span>
                            <p class="nbtn1 text-center ttu mt-5"><a href="javascript:void(0)" class="w100" (click)="onSubmitForm()">Submit</a>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 text-right pt-4">
                        <!-- <a href="" class="green mr-4"><i class="fas fa-download"></i> Download PDF</a><a href=""  class="green"><i class="fas fa-print"></i> Print Quote</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>