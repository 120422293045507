<div class="mb-3 contT">
	<div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Parent Blog</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
    	<h1><span class="txt_gray mob_hide">Welcome To </span>Parent Blog</h1>            
    </div>
</div>

<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
    <div class="container mb-5">      
         <div class="row mt-5">
             <div class="col-md-9">
                <div class="row">   
                    <div class="col-md-12">
                        <div class="emp_area mb-4">
                            <div class="loc_feild">
                                <p class="rel"><a href="#" class="abico"><i class="fas fa-search"></i></a><input type="text" placeholder="Enter keyword, question, or topic"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                    <div class="news_box">
                        <a href="blog-detail.htm">
                            <img src="assets/images/b1.jpg" alt="" class="img-fluid">
                            Suited Tutor Role in Online Education
                        </a>
                            <p class="fs12">Posted by: Admin</p>
                            <p class="mt-2">Covid-19. Everything has come to a halt and we don’t know what the future holds. At a time like this, the world is reaching out for stability and familiarity.  ...</p>
                    </div>
                    </div>
                    <div class="col-md-4">
                    <div class="news_box">
                        <a href="blog-detail.htm">
                            <img src="assets/images/b2.jpg" alt="" class="img-fluid">
                            Suited Tutor Role in Online Education
                        </a>
                            <p class="fs12">Posted by: Admin</p>
                            <p class="mt-2">Covid-19. Everything has come to a halt and we don’t know what the future holds. At a time like this, the world is reaching out for stability and familiarity.  ...</p>
                    </div>
                    </div>
                    <div class="col-md-4">
                    <div class="news_box">
                        <a href="blog-detail.htm">
                            <img src="assets/images/b3.jpg" alt="" class="img-fluid">
                            Suited Tutor Role in Online Education
                        </a>
                            <p class="fs12">Posted by: Admin</p>
                            <p class="mt-2">Covid-19. Everything has come to a halt and we don’t know what the future holds. At a time like this, the world is reaching out for stability and familiarity.  ...</p>
                    </div>
                    </div>
                    <div class="col-md-4">
                    <div class="news_box">
                        <a href="blog-detail.htm">
                            <img src="assets/images/b1.jpg" alt="" class="img-fluid">
                            Suited Tutor Role in Online Education
                        </a>
                            <p class="fs12">Posted by: Admin</p>
                            <p class="mt-2">Covid-19. Everything has come to a halt and we don’t know what the future holds. At a time like this, the world is reaching out for stability and familiarity.  ...</p>
                    </div>
                    </div>
                    <div class="col-md-4">
                    <div class="news_box">
                        <a href="blog-detail.htm">
                            <img src="assets/images/b2.jpg" alt="" class="img-fluid">
                            Suited Tutor Role in Online Education
                        </a>
                            <p class="fs12">Posted by: Admin</p>
                            <p class="mt-2">Covid-19. Everything has come to a halt and we don’t know what the future holds. At a time like this, the world is reaching out for stability and familiarity.  ...</p>
                    </div>
                    </div>
                    <div class="col-md-4">
                    <div class="news_box">
                        <a href="blog-detail.htm">
                            <img src="assets/images/b3.jpg" alt="" class="img-fluid">
                            Suited Tutor Role in Online Education
                        </a>
                            <p class="fs12">Posted by: Admin</p>
                            <p class="mt-2">Covid-19. Everything has come to a halt and we don’t know what the future holds. At a time like this, the world is reaching out for stability and familiarity.  ...</p>
                    </div>
                    </div> 
                </div>
             </div>
             <div class="col-md-3">
                <p class="fs18 b">Click on the category below or search articles to dig deeper</p>
                 <p class="blog_link">
                     <a href="">All</a>
                     <a href="">Educational Advice</a>
                     <a href="">Exams &amp; Revision</a>
                     <a href="">Just For Fun</a>
                     <a href="">University</a>
                     <a href="">A Level</a>
                 </p>
             </div>
        </div>
    </div>
</section>