<div class="modal fade show pp" id="cr" tabindex="-1" role="dialog">
    <div class="bd-example-modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h1 class="modal-title">Cancel &amp; Refund</h1> -->
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="TutorDetails">
                    <div class="col-md-3 p-3 text-center Compare-Tutor-Profiles-img">
                        <p class="mb-2"><img [src]="allowurl(apiService.imageBase+TutorDetails.image)" onError="this.src='assets/images/profile-demo.png'" alt="" class="img-fluid radius15"></p>
                    </div>
                    <div class="col-md-9 p-3 bggray Compare-Tutor-Profiles">
                        <div class="">
                            <p class="veri"><i class="fas fa-check"></i> Profile Verified</p>
                            <p *ngIf="TutorDetails.avg_rating?.toFixed()==5" class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                class="fas fa-star"></i><i class="fas fa-star"></i><i
                                class="fas fa-star"></i><span class="letn"> Betyg </span></p>
                        <p *ngIf="TutorDetails.avg_rating?.toFixed()==4" class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                class="fas fa-star"></i><i class="fas fa-star"></i><i class='far'>&#xf005;</i><span class="letn"> Betyg </span></p>
                        <p *ngIf="TutorDetails.avg_rating?.toFixed()==3" class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                 class="fas fa-star"></i><i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><span class="letn"> Betyg </span></p>
                        <p *ngIf="TutorDetails.avg_rating?.toFixed()==2" class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><span class="letn"> Rating</span></p>
                        <p *ngIf="TutorDetails.avg_rating?.toFixed()==1" class="star"><i class="fas fa-star"></i>
                            <i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i class='far'>&#xf005;</i>
                            <span class="letn">Betyg</span></p>
                        <p *ngIf="TutorDetails.avg_rating==null" class="star"><i class='far'>&#xf005;</i>
                            <i class='far'>&#xf005;</i>
                            <i class='far'>&#xf005;</i>
                            <i class='far'>&#xf005;</i>
                            <i class='far'>&#xf005;</i>

                            <span class="letn">Betyg</span></p>
                            <h1 class="float-left">{{TutorDetails.name}}</h1>
                            <p class="mt-2 fs22 float-right"><b>${{TutorDetails.rate}}.00</b><span class="fs14 ml-2">(per hour)</span></p>
                            <p class="clearfix"></p>
                            <p class="fs12">{{TutorDetails.state}}</p>
                            <p class="bdrb mb-2"><img src="assets/images/spacer.gif" alt=""></p>
                            <div class="row fs12">
                                <div class="col-md-5 b">Kvalifikationer:</div>
                                <div class="col-md-7">{{TutorDetails.qualification}}</div>
                            </div>
                            <p class="bdrb mt-2 mb-2"><img src="assets/images/spacer.gif" alt=""></p>
                            <div class="row fs12">
                                <div class="col-md-5 b">Expertise:</div>
                                <div class="col-md-7">{{TutorDetails.subjects}}</div>
                            </div>
                            <p class="bdrb mt-2 mb-2"><img src="assets/images/spacer.gif" alt=""></p>
                            <div class="row fs12">
                                <div class="col-md-5 b">Coaching Språk:</div>
                                <div class="col-md-7">{{TutorDetails.languages}}</div>
                            </div>
                            <p class="fs12 mt-4 pr-2">{{TutorDetails.description}}.</p>
                            <p class="bdrb mt-3 mb-3"><img src="assets/images/spacer.gif" alt=""></p>
                            <div *ngIf="isUserLoggedInData.role_id!=3 && isUserLoggedInData" class="row mt-4 text-center">
                                <!-- <div class="col-md-3 add_cart"><a href="javascript:void(0);" routerLink="/chats/{{TutorDetails.id}}" (click)="onClosePopup()"><i
                                            class="fas fa-comments"></i> </a>
                                    <p class="fs12 mt-1">Chat Me</p>
                                </div> -->
                                <div *ngIf="isUserLoggedInData.role_id!=5" class="col-md-3 add_cart"><a href="javascript:void(0);" routerLink="/book-a-demo-class/{{TutorDetails.id}}" (click)="onClosePopup()"><i
                                            class="fas fa-handshake"></i></a>
                                    <p class="fs12 lh15 mt-1">Book A 15 Minuts Complementry Class</p>
                                </div>
                                <div *ngIf="isUserLoggedInData.role_id!=5" class="col-md-3 add_cart"><a href="javascript:void(0);" routerLink="/book-a-class/{{TutorDetails.id}}" (click)="onClosePopup()"><i
                                            class="fas fa-laptop"></i> </a>
                                    <p class="fs12 mt-1">Intresseanmälan </p>
                                </div>
                                <div class="col-md-3 add_cart"><a href="javascript:void(0);" target="_parent" routerLink="/tutor-details/{{TutorDetails.id}}" (click)="onClosePopup()"><i
                                            class="fas fa-arrow-right"></i> </a>
                                    <p class="fs12 mt-1">Visa  detaljer</p>
                                </div>
                                <!-- <div *ngIf="isUserLoggedInData.role_id!=5" class="col-md-12 add_cart pt-3"><a href="javascript:void(0);" routerLink="/book-a-class/{{TutorDetails.id}}" (click)="onClosePopup()"><i
                                            class="fas fa-laptop"></i> </a>
                                    <p class="fs12 mt-1">Group Classes</p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary mt-4"  data-dismiss="modal" (click)="onClosePopup()">Cancel</button>
                <button *ngIf="isUserLoggedInData.role_id!=3 && isUserLoggedInData" type="button" class="submit" routerLink="/book-a-class/{{TutorDetails.id}}" (click)="onClosePopup()">Intresseanmälan </button>
            </div>
        </div>
    </div>
</div>