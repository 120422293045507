<div class="modal fade show pp" id="regi" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered logmod" role="document">
        <div class="modal-content">
            <!--<div class="modal-header">
                <h1 class="modal-title">Logga in to your account</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
             </div>-->
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 pr-0">
                        <!-- start -->
                        <div class="log_box pt-0">
                            <div class="bdrb pb-3">
                                <h1 class="b ac">Select Payment GetWay</h1>
                            </div>
                            <div class="login_form mt-4">
                                <div class="student">
                                    <form [formGroup]="AddForm">
                                        <div class="emp_area">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <span class="add_cartff"><a
                                                            href="javascript:void(0);" (click)="onCheckout()"><img src="assets/images/Stripe-wordmark-blurple-large.png" alt="..."
                                                                class="d-block w-100">
                                                            Strips Payment getway</a></span>
                                                </div>
                                                <!-- <div class="col-md-6">

                                                    <span class="add_cartff"><a href="javascript:void(0);"
                                                            (click)="cashbackOfferId()"><img src="assets/images/superpayment.png" alt="..."
                                                            class="d-block w-100">
                                                            Super Payment getway</a></span>
                                                </div> -->
                                            </div>
                                        </div>
                                        <!-- <h3 class="Direct-Bank-Transfer">Direct Bank Transfer</h3> -->

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="name_feild payment_methodlable">
                                                    <label>Name</label>
                                                    <input type="text" formControlName="name"
                                                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                                                        placeholder="Skolbryggan" class="form-control" disabled>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="name_feild">
                                                    <label>Account Number</label>
                                                    <input type="text" formControlName="name"
                                                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                                                            placeholder="0000 0000 0000" class="form-control" disabled>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="name_feild">
                                                    <label>Sort Code:</label>
                                                    <input type="text" class="form-control" formControlName="last_name"
                                                        placeholder="00 00 00"
                                                        [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" disabled>

                                                </div>
                                            </div>
                                        </div>




                                    </form>

                                </div>
                            </div>
                        </div>
                        <!-- end -->
                    </div>
                </div>
            </div>
            <!--<div class="modal-footer">
                 <button type="button" class="btn btn-secondary mt-4" data-dismiss="modal">Cancel</button>
                <button type="button" class="submit">Save</button>
             </div>-->
        </div>
    </div>
</div>