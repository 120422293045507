import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ApiService,CommonService,MessageService } from '../services';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any=[];
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
  }
  ngOnInit(): void {
  
    this.addForm = this.fb.group({
      first_name: ['', [Validators.required]],
      // last_name: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      // ,Validators.pattern(/^\d{10}$/)
      comment: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      file: [''],
      type: ['1']
    })
    this.ContatcsiteSettings();
  }

  onaddForm() {
    var self = this;
    // self.addForm.patchValue({
    //   file: self.uploadedImagesData
    // })
    if (self.addForm.valid) {
      var sendRequestData = self.addForm.value;
      sendRequestData.file=self.uploadedImagesData || '';
      console.log('sendRequestData::::',sendRequestData)
      var requestUrl;
      requestUrl = 'contact_us_inquiry_general';
      self.apiService.post(requestUrl, sendRequestData).subscribe((res: any) => {
        if (res.replyCode == 'success') {
          self.addForm.reset();
          this.messageService.showSuccess(res.replyMsg);
          this.router.navigate(['/thankyou'])
          //this.router.navigate(['/product'])
        }
        else {
          this.messageService.showError(res.replyMsg)
        }
      },
        error => {
          this.messageService.showError('Oops! Something went wrong!')
        });

    }
  }

  ContatcsiteSettings(){
    var sendrequestinput:any={id:1};
    this.apiService.post('get_site_settings',sendrequestinput).subscribe((data:any) => this.contactData=data.data)
  }
  handleFileInput(event: any, name :any) {
    this.fileToUpload = event.target.files.item(0);
    this.uploadFileToActivity(name)
    
  }
  uploadFileToActivity(name :any) {
    var self = this;
    self.apiService.postFile(self.fileToUpload).subscribe((data: any) => {
      
      self.uploadedImagesData = data.name;

      console.log(self.uploadedImagesData);

    }, error => {
      console.log(error);
    });
  }
}
