<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
    <div class="container">
		<div class="row">
		<div class="col-md-6 p-5 text-center">
			<p class="mb-2"><img src="assets/images/t1.jpg" alt="" class="img-fluid radius15"></p>
		</div>
		<div class="col-md-6 p-5 bggray">
			<div class="">
				<p class="veri"><i class="fas fa-check"></i> Profile Verified</p>
				<p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
				<h1 class="float-left">Rebecca L</h1><p class="mt-2 fs22 float-right"><b>$50.00</b><span class="fs14 ml-2">(per hour)</span></p>
				<p class="clearfix"></p>
				<p class="fs12">Dwarka, New Delhi</p>
				<p class="bdrb mb-2"><img src="assets/images/spacer.gif" alt=""></p>
				<div class="row fs12">
					<div class="col-md-5 b">Qualification:</div>
					<div class="col-md-7">English Literature and French (BA) - University of Warwick</div>
				</div>
				
				<p class="bdrb mt-2 mb-2"><img src="assets/images/spacer.gif" alt=""></p>
				<div class="row fs12">
					<div class="col-md-5 b">Expertise:</div>
					<div class="col-md-7">Biology , Chemistry , English , English Group (Siblings) , English (Second Language) , Maths , Science</div>
				</div>
				
				<p class="bdrb mt-2 mb-2"><img src="assets/images/spacer.gif" alt=""></p>
				<div class="row fs12">
					<div class="col-md-5 b">Coaching Language:</div>
					<div class="col-md-7">English</div>
				</div>
				
				<p class="fs12 mt-4 pr-2">I'm friendly, caring and dedicated to helping you learn - based on your personality, skills and learning style. I am committed to helping my students reach their goals, and am right there with you every step of the way.</p>
				<p class="bdrb mt-3 mb-3"><img src="assets/images/spacer.gif" alt=""></p>
				<div class="row mt-4 text-center">
					<div class="col-md-3 add_cart"><a href="chat-me.htm" target="_parent"><i class="fas fa-comments"></i> </a><p class="fs12 mt-1">Chat Me</p></div>
					<div class="col-md-3 add_cart"><a href="book-meeting.htm" target="_parent"><i class="fas fa-handshake"></i></a> <p class="fs12 lh15 mt-1">Book A 15 Minuts Complementry Class</p></div>
					<div class="col-md-3 add_cart"><a href="book-class.htm" target="_parent"><i class="fas fa-laptop"></i> </a><p class="fs12 mt-1">Intresseanmälan </p></div>
					<div class="col-md-3 add_cart"><a href="tutor-details.htm" target="_parent"><i class="fas fa-arrow-right"></i> </a><p class="fs12 mt-1">View Details</p></div>
					<div class="col-md-12 add_cart pt-3"><a href="group-class.htm" target="_parent"><i class="fas fa-laptop"></i> </a><p class="fs12 mt-1">Group Classes</p></div>
				</div>
			</div>
		</div>
	</div>
</div>
</section>