import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { PasswordMatchPattern } from 'src/app/services/validation/password-match-pattern';

@Component({
  selector: 'app-student-registration',
  templateUrl: './student-registration.component.html',
  styleUrls: ['./student-registration.component.scss']
})
export class StudentRegistrationComponent implements OnInit {
  AddForm: FormGroup | any; submitted = false; btnsubmitted = false;
  constructor(private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService) {

  }
  get f() { return this.AddForm.controls; }

  ngOnInit(): void {
    this.getCountryList();
    this.getLevelList();
    this.AddForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      phone_code: new FormControl('', [Validators.required]),
      email : new FormControl('', [Validators.required]),
      age : new FormControl('', [Validators.required]),
      role_id : ['2', [Validators.required]],
      phone : ['', Validators.required],
      password : [null, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      reenterpassword: ['', Validators.required],
      gender: ['', Validators.required],
      news_letter : ['', Validators.required],
      country : ['', Validators.required],
      termscond : ['', Validators.required],
      reId : localStorage.getItem('referral_id'),

    }, {
      validator: PasswordMatchPattern('password', 'reenterpassword')
    });
  }
  onClosePopup() {
    this.commonService.SetRegisterPopup(false);
  }
  tutorFlag:boolean=false;
  onChangeType(event:any){

      this.tutorFlag=false;
      this.AddForm = this.fb.group({
        name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        phone_code: new FormControl('', [Validators.required]),
        email : new FormControl('', [Validators.required]),
        age : new FormControl('', [Validators.required]),
        role_id : '2',
        phone : ['', Validators.required],
        password : [null, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
        reenterpassword: ['', Validators.required],
        gender: ['', Validators.required],
        news_letter : ['', Validators.required],
        country : ['', Validators.required],
        termscond : ['', Validators.required],
        reId : localStorage.getItem('referral_id') ? localStorage.getItem('referral_id'):'0',
      }, {
        validator: PasswordMatchPattern('password', 'reenterpassword')
      });
    
  }
  CountryList:any=[];
  getCountryList(){
    var sendrequestinput:any={id:1};
    this.apiService.post('country_list',sendrequestinput).subscribe((data:any) => {
      this.CountryList=data.data;
           
    })
  }
  LevelList:any=[];
  getLevelList(){
    var sendrequestinput:any={id:1};
    this.apiService.post('age_group_list_dropdown',sendrequestinput).subscribe((data:any) => {
      this.LevelList=data.data;
    })
  }
  type:boolean=false;
  cptype:boolean=false;
  onChangeType1(){
    this.type=true;
  }
  onChangeType2(){
    this.type=false;
  }
  onChangecnType1(){
    this.cptype=true;
  }
  onChangecnType2(){
    this.cptype=false;
  }
  onSubmit(){
    this.submitted=true;
    console.log('this.AddForm>>>>>>>',this.AddForm.value)
    if(this.AddForm.invalid){
      return
    }else{
      // alert();
      console.log('this.AddForm>>>>>>>',this.AddForm.value)
      var sendrequestinput:any=this.AddForm.value;
      if(this.AddForm.value.news_letter){
        sendrequestinput.news_letter=1;
      }else{
        sendrequestinput.news_letter=0;
      }
      sendrequestinput.last_name=this.AddForm.value.last_name;
      // this.AddForm.value.phone_code + this.AddForm.value.phone;
      
      sendrequestinput.name=this.AddForm.value.name;
      console.log('sendrequestinput>>>>>>>>',sendrequestinput);
      // return
      this.apiService.post('add_user',sendrequestinput).subscribe((data:any) => {
        if(data.replyCode == "success"){
          if(sendrequestinput.role_id == 3){
            this.apiService.post('generate_teacher_slots',{user_id:data.uid}).subscribe((response:any) => {
              console.log('response>>>>>>>>>',response);
              return response;
            })
          }
          if(data.data){
            this.commonService.SetLoginUserDetails(data.data);
            this.commonService.SetLoggedIn(true);
            var json=JSON.stringify(data.data);
            localStorage.setItem('user',json)
            localStorage.setItem('sid',data.sid)
          }
          if(sendrequestinput.role_id == 3){
            this.router.navigate(['/tutor-edit-profile'])
          }else if(sendrequestinput.role_id == 2){
            this.router.navigate(['/student-edit-profile'])
          }else{
            this.router.navigate(['/parent-edit-profile'])
          }
          this.onClosePopup();
          this.AddForm.reset();
          this.submitted=false;
          this.messageService.showSuccess(data.replyMsg);
        }else{
          this.messageService.showError(data.replyMsg);
        }
      })
    }
  }

}
