<div class="mb-3 contT">
	<div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Mitt Konto</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
    	<h1 class="float-left"><span class="txt_gray mob_hide">My </span>Study Materials</h1>
		<p class="clearfix"></p>
    </div>
</div>

<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-sm-12 wow fadeInLeft animated" style="visibility: visible;">
                        <div class="list_box p-4 fs13">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 pb-3">
                                    <p>Course Name</p>
                                    <p class="b mt-2 fs18"><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                    <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique.</p>
                                </div>
                                <div class="col-lg-8 col-md-6 pb-3">
                                    <p>Course Material</p>
                                    <div class="b mt-2 c-cont">
                                        <a href="#"><i class="far fa-play-circle"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-pdf"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-excel"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-pdf"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-word"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-powerpoint"></i> Download<br>Material Name</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="list_box p-4 fs13">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 pb-3">
                                    <p>Course Name</p>
                                    <p class="b mt-2 fs18"><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                    <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique.</p>
                                </div>
                                <div class="col-lg-8 col-md-6 pb-3">
                                    <p>Course Material</p>
                                    <div class="b mt-2 c-cont">
                                        <a href="#"><i class="far fa-play-circle"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-pdf"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-excel"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-pdf"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-word"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-powerpoint"></i> Download<br>Material Name</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="list_box p-4 fs13">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 pb-3">
                                    <p>Course Name</p>
                                    <p class="b mt-2 fs18"><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                    <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique.</p>
                                </div>
                                <div class="col-lg-8 col-md-6 pb-3">
                                    <p>Course Material</p>
                                    <div class="b mt-2 c-cont">
                                        <a href="#"><i class="far fa-play-circle"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-pdf"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-excel"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-pdf"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-word"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-powerpoint"></i> Download<br>Material Name</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="list_box p-4 fs13">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 pb-3">
                                    <p>Course Name</p>
                                    <p class="b mt-2 fs18"><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                    <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique.</p>
                                </div>
                                <div class="col-lg-8 col-md-6 pb-3">
                                    <p>Course Material</p>
                                    <div class="b mt-2 c-cont">
                                        <a href="#"><i class="far fa-play-circle"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-pdf"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-excel"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-pdf"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-word"></i> Download<br>Material Name</a>
                                        <a href="#"><i class="fas fa-file-powerpoint"></i> Download<br>Material Name</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    
                  </div>
            </div>
        </div>
    </div>
</section>