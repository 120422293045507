<div class="mb-3 contT">
    <div class="container pl-3">
        <div class="row">
            <div class="col-md-12">
                <ul class="breadcrumb">
                    <li><a href="/">Home</a></li>
                    <li class="active">blogg</li>
                </ul>
                <div class="blog-section pl-3 pr-3">
                    <h1><span class="txt_gray mob_hide">Välkommen till </span> vår blogg</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="animated3 wow drop_eff5">
    <div class="container mb-5">
        <div class="row justify-content-md-center">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="min-blog">
                    <div class="min-blog-images">
                        <img src="assets/img/blog.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="blog-cont">
                        <h3>För studiementorer</h3>
                        <p class="mt-3">Läs om hur det är att vara mentor på Skolbryggan.
                        </p>
                        <p class="mt-3 text-center"><a href="javascript:void(0);" routerLink="/blog/1"
                                class="style1 bg">Mentors blogg</a></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="min-blog">
                    <div class="min-blog-images">
                        <img src="assets/img/blog2.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="blog-cont">
                        <h3>För föräldrar</h3>
                        <p class="mt-3">Få tips och trix om hur du som förälder kan hjälpa ditt barn utvecklas i skolan.
                        </p>
                        <p class="mt-3 text-center"><a href="javascript:void(0);" routerLink="/blog/2"
                                class="style1 bg">Föräldrar blogg
                            </a></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="min-blog">
                    <div class="min-blog-images">
                        <img src="assets/img/blog3.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="blog-cont">
                        <h3>För elever</h3>
                        <p class="mt-3">En blogg för dig som elev med massor intressant information.</p>
                        <p class="mt-3 text-center"><a href="javascript:void(0);" routerLink="/blog/0"
                                class="style1 bg">Elev blogg</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>