import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';

import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit {
 
  Collectionslist: any=[];
  constructor(public apiService :ApiService,public sanitizer: DomSanitizer,public commonService:CommonService) {
    this.commonService.SetFooterContent(false);
   }

  ngOnInit(): void {
    this.getCollectionsList();
  }
  getCollectionsList(){
    var sendrequestinput:any={isAdmin:'0'};
    this.apiService.post('collection_list',sendrequestinput).subscribe((data:any) => {
      this.Collectionslist=data.data;
     
    })
  }
  

  allowurl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
