import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';
import { DomSanitizer } from '@angular/platform-browser';
// import { threadId } from 'worker_threads';
import { Router } from '@angular/router';
declare const $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  categoryList: any=[];
  siteData: any=[];
  bodyDataRequest: any = {};
  userlistData: any = {};
  LoginUserDetails: any = {};
  Username='';
  keyValue = '';
  total_count = '0';
  isContentShow:boolean=false;
  isContentShow1:boolean=false;
  localData:any;
  constructor(private router:Router, public apiService :ApiService,public sanitizer: DomSanitizer,public commonService:CommonService) { 
    if(localStorage.getItem('user')){
      var jsonData:any=localStorage.getItem('user');
      this.localData=JSON.parse(jsonData);
      }
  }
  isLoginUser:boolean=false;
  ngOnInit(): void {
    this.siteSettings();
    this.commonService.GetLoggedIn().subscribe((data:any)=>{
      this.isLoginUser=data;
      if(this.isLoginUser){
      }else{
        this.router.navigate(['/'])
      }
      console.log('isLoginUser>>>>>>',this.isLoginUser);
    })
    this.commonService.GetLoginUserDetails().subscribe((data:any)=>{
      this.LoginUserDetails=data;
      this.Username=this.LoginUserDetails?.last_name;
      console.log(this.Username);
      console.log('isLoginUser>>>>>>',this.isLoginUser);
    })
    this.commonService.GetContentHidden().subscribe((data:any)=>{
      this.isContentShow=false;
      this.isContentShow1=false;
    })
  }
  siteSettings(){
    var sendrequestinput:any={id:1};
    this.apiService.post('get_site_settings',sendrequestinput).subscribe((data:any) => {
      this.siteData=data.data;
      this.commonService.SetSetting(this.siteData);
    })
  }
  allowurl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  isShow(){
    this.isContentShow=!this.isContentShow;
  }
  isShow1(){
    this.isContentShow1=!this.isContentShow1;
  }
  onOpenLoginPopup(){
    this.commonService.SetLoginPopup(true);
  }
  onOpenRegisterPopup(){
    localStorage.removeItem('register_pop_up');

    this.commonService.SetRegisterPopup(true);
  }
  onOpenLogout(){
    this.commonService.SetLogoutPopup(true);
  }
  onRedirectProfilePage(){
    console.log('this.LoginUserDetails>>>>>>>',this.LoginUserDetails.role_id);
    if(this.LoginUserDetails.role_id == 3){
      this.router.navigate(['/tutor-edit-profile'])
    }else if(this.LoginUserDetails.role_id == 2){
      this.router.navigate(['/student-edit-profile'])
    }else if(this.LoginUserDetails.role_id == 5){
      this.router.navigate(['/parent-edit-profile'])
    }
  }
  onClosePopup(){
    // this.(false);
  }
  public isLoggedIn = false;


funlogin(){
  this.isLoggedIn = true
}
}

