<div class="mb-3 contT">
	<div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Mitt Konto</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
    	<h1 class="float-left"><span class="txt_gray mob_hide">My </span>Redeem Requests</h1>			
		<!--<p class="float-right"><a href="#" data-toggle="modal" data-target="#add" class="compare"><i class="fas fa-plus"></i> Add Course</a></p>-->
		<p class="clearfix"></p>
    </div>
</div>

<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-3">
                        <!-- filter-->
                            <form class="list_box mb-4">
                            <div class="dd_next hand p-3 bdrb fs16 b"> <span>Filter</span></div>
                            <div class="emp_area f_dd_box p-3 mb-4">
                                <div class="name_feild">
                                    <p>Transfer Status</p>
                                    <p><input type="text"></p>
                                </div>
                                <div class="loc_feild">
                                    <p>Date</p>
                                    <p><input type="text"></p>
                                </div>
                                
                                <div class="loc_feild">
                                    <input type="submit" value="Submit" class="compare w100">
                                </div>
                            </div>
                            </form>
                        <!-- filter end-->
                    </div>
                    <div class="col-lg-9 wow fadeInLeft animated" style="visibility: visible;">
                        <div class="list_box p-4 fs13 rel">
                            <form>
                                <div class="emp_area">
                                    <div class="name_feild">
                                        <p>Withdraw Amount</p>
                                        <p><input type="text"></p>
                                        <p class="fs11 ac p-3">Min amount of RM400<br>(payments will be conducted during the weekend)</p>
                                        <div class="row justify-content-center">
                                            <div class="col-md-4"><p><a href="#" class="compare">Withdraw Fund</a></p></div>
                                        </div>
                                    </div>
                                    <div class="loc_feild">
                                        <p>Bank Account Number</p>
                                        <p><input type="text"></p>
                                    </div>
                                    <div class="loc_feild">
                                        <p>Bank Name</p>
                                        <p><input type="text"></p>
                                    </div>
                                    <div class="loc_feild">
                                        <p>Beneficery Name</p>
                                        <p><input type="text"></p>
                                    </div>
                                    <div class="loc_feild">
                                        <div class="row">
                                            <div class="col-md-12 p-0"><p class="add_cart"><a href="">Submit</a></p></div>
                                        </div>
                                    </div>  
                                </div>
                            </form>
                        </div>
                        
                        <p class="b mt-4">My Withdrawal</p>
                        <div class="list_box p-4 fs13 rel">
                            
                            <div class="row">
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Requested Date</p>
                                    <p class="b mt-2">Friday, July 20, 2021</p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Request Amount</p>
                                    <p class="b mt-2">$2000.00</p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Transfer Status</p>
                                    <p class="b mt-2">Paid</p>
                                </div>
                                <div class="col-lg-3 col-md-6 pb-3">
                                    <p><a href="#" data-toggle="modal" data-target="#inv" class="compare">View Invoice</a></p>
                                </div>
                                <div class="col-lg-3 col-md-6 pb-3">
                                    <p><a href="#" data-toggle="modal" data-target="#cmt" class="compare">View Comment</a></p>
                                </div>
                            </div>
                        </div>
                        
                        <div class="list_box p-4 fs13 rel">
                            <div class="row">
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Requested Date</p>
                                    <p class="b mt-2">Friday, July 20, 2021</p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Request Amount</p>
                                    <p class="b mt-2">$2000.00</p>
                                </div>
                                <div class="col-lg-2 col-md-6 pb-3">
                                    <p>Transfer Status</p>
                                    <p class="b mt-2">Paid</p>
                                </div>
                                <div class="col-lg-3 col-md-6 pb-3">
                                    <p><a href="#" data-toggle="modal" data-target="#inv" class="compare">View Invoice</a></p>
                                </div>
                                <div class="col-lg-3 col-md-6 pb-3">
                                    <p><a href="#" data-toggle="modal" data-target="#cmt" class="compare">View Comment</a></p>
                                </div>
                            </div>
                        </div>
                        
                    </div>  
                </div>
            </div>
        </div>
    </div>
</section>