import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { OwlModule } from 'ngx-owl-carousel';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon'

import { HomeComponent } from './home/home.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
// import { BlogComponent } from './blog/blog.component';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { EnquiryComponent } from './in_person_enquiry/in_person_enquiry.component';
// import { BlogDetailsComponent } from './blog_details/blog_details.component';
import { ProductListingComponent } from './product_listing/product_listing.component';
import { ProductDetailsComponent } from './product_details/product_details.component';
import { CategoryListingComponent } from './category_listing/category_listing.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { RequestQouteComponent } from './request_qoute/request_quote.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { CalculateCostComponent } from './calculate_cost/calculate_cost.component';
import { HotProductComponent } from './hot_product/hot_product.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { ProductSearchComponent } from './product_search/product_search.component';
import { BrandProductComponent } from './brand_product/brand_product.component';
import { LinkService } from './services/seo.service';
import { FaqsComponent } from './faqs/faqs.component';
import { TeamComponent } from './team/team.component';
import { CollectionsComponent } from './collections/collections.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BecomeTutorComponent, CompareStudentTutorComponent, FindTutorComponent, ParentRegistrationComponent, StudentRegistrationComponent, TutorRegistrationComponent } from './tutor';
import { AddCourseComponent, ForgotComponent, LocationComponent, LoginComponent, LogoutComponent, RegisterComponent,AddSubjectComponent, BookAClassComponent, AddQuizComponent, AddProjectComponent, TutorViewComponent, AddChatGroupComponent, BookClassCancelTutorComponent,RescheduleClassComponent } from './modal';
import { ChangePasswordComponent, ParentEditProfileComponent, ParentProfileComponent, StudentEditProfileComponent, StudentProfileComponent, TutorEditProfileComponent, TutorProfileComponent } from './profile';
import { HomeFooterComponent } from './home-footer/home-footer.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { BookClassComponent } from './book-a-class/book-a-class.component';
import { MyClassComponent } from './my-class/my-class.component';
import { TutorDetailsComponent } from './tutor-details/tutor-details.component';
// import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxStarRatingModule } from 'ngx-star-rating';
import { BlogComponent,BlogDetailsComponent,BrowseCourseComponent,BrowseSubjectComponent,ClassDashboardComponent,CourseDetailsComponent,GroupClassComponent,MyWishlistComponent,OnlineSpaceComponent,ParentBlogComponent,ParentNotificationComponent,ParentSupportComponent,PriceComponent,StudentClassesScheduleComponent,StudentMonthlyScorecardComponent,StudentStudyMaterialComponent,SupportComponent,TutorManageRequestMeetingClassesComponent,TutorManageResultsComponent,TutorMyEarningComponent,TutorRedeemRequestsComponent,TutorShortDetailsComponent,TutorStudentDetailsComponent,FindCourseComponent, ParentContactComponent, ChatComponent, CompareCourseComponent, ContactUsPageComponent, AllCourseComponent, ReferProgramComponent, BlogListComponent, MyScorecardComponent } from './component';
import { BookCourseComponent } from './book-course/book-course.component';
import { AddParentChildComponent } from './modal/add-child/add-child.component';
import { DemoClassComponent } from './demo-class/demo-class.component';
import { AddChatgroupUserComponent } from './modal/add-chat-group-user/add-chat-group-user.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {NgxPaginationModule} from 'ngx-pagination';
import { JwPaginationModule } from 'jw-angular-pagination';
import {NgxStripeModule} from 'ngx-stripe';
import { MatFormFieldModule } from "@angular/material/form-field";
import { NewdeshboardComponent } from './component/newdeshboard/newdeshboard.component';
import { PaymentsComponent } from './modal/payments/payments.component';
import { SuccessComponent } from './success/success.component';
import { CancelComponent } from './cancel/cancel.component';
import { FailureComponent } from './failure/failure.component';
// import { NgxPaginationModule } from 'ngx-pagination';
import { DatePipe } from '@angular/common';
import { VisitorsService } from './visitors.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

const config: SocketIoConfig = {
	url: environment.API_URL, // socket server url;
	options: {
		transports: ['websocket']
	}
}
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  FacebookLoginProvider
} from 'angularx-social-login';
import { GoogleLoginProvider ,MicrosoftLoginProvider} from 'angularx-social-login';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { AddFriendComponent } from './modal/add-friend/add-friend.component';
import { EditGroupDetailComponent } from './modal/edit-group-detail/edit-group-detail.component';
import { UpdateprofileComponent } from './modal/updateprofile/updateprofile.component';
@NgModule({
  declarations: [
    AppComponent,LayoutComponent,HomeFooterComponent,
    routingComponents,
    HomeComponent,
    TestimonialsComponent,
    BlogComponent,
    AboutComponent,
    ContactComponent,
    EnquiryComponent,
    BlogDetailsComponent,
    ProductListingComponent,
    ProductDetailsComponent,
    CategoryListingComponent,
    NewsletterComponent,
    RequestQouteComponent,
    CalculatorComponent,
    CalculateCostComponent,
    HotProductComponent,
    FeedbackComponent,
    ProductSearchComponent,
    BrandProductComponent,
    FaqsComponent,
    TeamComponent,
    CollectionsComponent,BecomeTutorComponent, FindTutorComponent,TutorRegistrationComponent,StudentRegistrationComponent,ParentRegistrationComponent,CompareStudentTutorComponent,LoginComponent,RegisterComponent,ForgotComponent,LogoutComponent,LocationComponent,ChangePasswordComponent,
    StudentProfileComponent,StudentEditProfileComponent,ParentEditProfileComponent,ParentProfileComponent,TutorProfileComponent,TutorEditProfileComponent,AddSubjectComponent,AddCourseComponent,BookAClassComponent,BookClassComponent,MyClassComponent,RescheduleClassComponent,BookClassCancelTutorComponent,TutorDetailsComponent,AddQuizComponent,AddProjectComponent,
    BlogComponent,BlogDetailsComponent,BrowseCourseComponent,BrowseSubjectComponent,ClassDashboardComponent,CourseDetailsComponent,GroupClassComponent,MyWishlistComponent,OnlineSpaceComponent,ParentBlogComponent,ParentNotificationComponent,ParentSupportComponent,PriceComponent,StudentClassesScheduleComponent,StudentMonthlyScorecardComponent,StudentStudyMaterialComponent,SupportComponent,TutorManageRequestMeetingClassesComponent,TutorManageResultsComponent,TutorMyEarningComponent,TutorRedeemRequestsComponent,TutorShortDetailsComponent,TutorStudentDetailsComponent,FindCourseComponent,BookCourseComponent,ParentContactComponent,AddParentChildComponent,ChatComponent,CompareCourseComponent,DemoClassComponent,ContactUsPageComponent,AllCourseComponent,ReferProgramComponent,BlogListComponent,MyScorecardComponent,TutorViewComponent,AddChatGroupComponent,AddChatgroupUserComponent, NewdeshboardComponent, PaymentsComponent, SuccessComponent, CancelComponent, FailureComponent, AddFriendComponent, EditGroupDetailComponent, UpdateprofileComponent,
  ],
  imports: [
    SocketIoModule.forRoot(config),
    NgxSpinnerModule,
    NgxSliderModule,
    SocialLoginModule,
    NgxPaginationModule ,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule ,
    JwPaginationModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CarouselModule,
    AppRoutingModule,OwlModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastModule.forRoot({timeOut: 1000, preventDuplicates: true}),
    NgxSummernoteModule,NgxStarRatingModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
    // NgxStripeModule.forRoot('pk_test_51Mc1QjSAsqTNd0k5AZcQpdLgrrgXh17hFS2dKwT4gZuRc4uJKqr9YvUL6WzuKzdzqslBfeAwwBwfpwGtZw6jKCta0095rdOQSk')
    // NgbModule
  ],
  providers: [MDBSpinningPreloader,VisitorsService,LinkService,DatePipe,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('701419007612-m27unargqa1sqbfmr48n632p6s6ccho7.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              '731786168687139' ,{
                // redirect_uri: 'https://localhost:4200',
              }
            ),
          },
        ]
      } as SocialAuthServiceConfig
    }
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
