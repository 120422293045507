<div class="mb-3 ptfix contT">
    <div class="container pt-4">
        <div class="row">
            <div class="col-md-12">
                <ul class="breadcrumb">
                    <li><a href="/">Home</a></li>
                    <li class="active">Kontakta oss</li>
                </ul>
            </div>

            <div class="col-md-12">
                <h1><span class="txt_gray mob_hide"></span>Kontakta oss</h1>
            </div>
        </div>
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->

<!-- Content Stars -->
<section class="animated3 wow drop_eff5">
    <div class="container mb-5">
        <div class="row">
            <div class="col-lg-12 wow fadeInLeft ">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="contact_area">
                            <h6 class="font-s-30" style="font-size: 30px;"><i class="fa fa-mobile"
                                    aria-hidden="true"></i></h6>
                            <p class="cnt_heading">Slå oss en signal</p>
                            <p class="cont-pstyle">Öppetider <br> Måndag till Fredag 09:00 till 17:00
                            </p>
                            <div class="sec_cnt">
                                <p><a href="tel:+447526116872" class="greenss"><span
                                            class="fa fa-phone fs24 mr8 font-s-20"></span>
                                        {{contactData.support_contact}}</a>
                                </p>
                                <!-- <p><a href="https://api.whatsapp.com/message/WMUUBYZLFLUWL1?autoload=1&app_absent=0"
                                        class="greenss" target="_blank"><i class="fa fa-whatsapp"
                                            aria-hidden="true"></i>
                                            +91-9818990611</a>
                                </p> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="contact_area">
                            <h6 class="font-s-30"><i class="fa fa-envelope-open" aria-hidden="true"></i></h6>
                            <p class="cnt_heading">Skriv till oss
                            </p>
                            <p class="cont-pstyle">Maila oss på
                            </p>
                            <p><a href="mailto:{{contactData.support_email}}" class="greenss">
                                    {{contactData.support_email}}</a></p>

                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="contact_area">
                            <h6 class="font-s-30"><i class="fa fa-envelope-open" aria-hidden="true"></i></h6>
                            <p class="cnt_heading">Sök i vår FAQ</p>
                            <p class="cont-pstyle mb-3">Har du några frågor eller funderingar? Tveka inte att utforska vår insiktsfulla svarsbank.</p>
                            <p><a href="#" target="_blank" class="style1 bg">Ta mig dit</a></p>
                        </div>
                    </div>
                </div>
                <div class="row mt-5 bg-color">
                    <div class="row MB-5">
                        <div class="col-md-12">
                          <h1 class="text-center mb-1 animated2 drop_eff2">Intresseanmälan</h1>
                          <p class="ac mb-3 fs16">	Skriv och berätta för oss hur vi kan hjälpa dig
                        </p>
                        </div>
                      </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="contact-enquire">
                            <form  [formGroup]="addForm">
                                <div class="form-group">
                                    <label for="inputName">Förnamn</label>
                                    <input type="text" formControlName="first_name" class="form-control" id="inputName" placeholder="Förnamn ">
                                </div>
                                <!-- <div class="form-group">
                                    <label for="inputLast">Last Name</label>
                                    <input type="text" formControlName="last_name" class="form-control" id="inputLast" placeholder="Last Name">
                                </div> -->
                                <div class="form-group">
                                    <label for="inputEmail4">E-post</label>
                                    <input type="email" formControlName="email" class="form-control" id="inputEmail4" placeholder="E-post">
                                </div>
                                <div class="form-group">
                                    <label for="inputLast">Mobilnr</label>
                                    <input type="text" formControlName="mobile" class="form-control" id="inputLast" placeholder="Mobilnr">
                                </div>
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1">Intresseanmälan / funderinga</label>
                                    <textarea class="form-controls" id="exampleFormControlTextarea1"
                                        placeholder="Intresseanmälan / funderinga" rows="3" formControlName="comment"></textarea>
                                </div>
                                <button class="btn style1 bg"  (click)="onaddForm()">SKICKA in</button>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="Google-map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2096.540757542288!2d14.285140812830717!3d58.299926792882104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465a4d1b920ea6cd%3A0xaf0a0f3e5c984eaa!2sSkolbryggan%20i%20Sj%C3%B6gatans%20f%C3%B6rl%C3%A4ngning%20i%20Hjo!5e0!3m2!1sen!2sin!4v1691645855887!5m2!1sen!2sin"
                                width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>