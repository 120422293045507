import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';

@Component({
  selector: 'app-become',
  templateUrl: './become.component.html',
  styleUrls: ['./become.component.scss']
})
export class BecomeTutorComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any=[];
  Loader_Flag:boolean=true;
  contentBlockData: any=[];
  urlSafe1: SafeResourceUrl | undefined;
  FaqList: any=[];
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);

  }


  ngOnInit(): void {
    this.getContentBlocks();
    this.getFaqList();
  }
  onOpenRegisterPopup(){
    localStorage.setItem('register_pop_up',"1");
    this.commonService.SetRegisterPopup(true);
  }
  getContentBlocks(){
    var sendrequestinput:any={'limit':40};
    this.apiService.post('content_blocks_list',sendrequestinput).subscribe((data:any) =>{
      this.contentBlockData=data.data
      this.urlSafe1=this.sanitizer.bypassSecurityTrustResourceUrl(this.contentBlockData[18].video_url)
      this.Loader_Flag=false; 
      } 
    );
  }

  getFaqList(){
    var sendrequestinput:any={isAdmin:'0',limit:'20',type:'1'};
    this.apiService.post('faqs_list',sendrequestinput).subscribe((data:any) => {
      this.FaqList=data.data;
     
    })
  }

}
