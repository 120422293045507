import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  localData:any = {};
  constructor(public apiService :ApiService,private router:Router) { }

  ngOnInit(): void {
    // this.router.url
    var jsonData :any = localStorage.getItem('supertoken')
    var jsonData2 :any = localStorage.getItem('book_slot_student')
    var jsonData3 :any = localStorage.getItem('users')
     jsonData3= JSON.parse(jsonData3);
     jsonData3=jsonData3?.name+' '+jsonData3?.last_name
    this.localData = JSON.parse(jsonData);
    this.localData.student_name=jsonData3
    this.localData.booked_class = JSON.parse(jsonData2);
console.log(this.localData,'this.localData' );


   console.log(this.localData.transactionId,'localData');
    console.log(this.router.url,'url');
    this.apiService.post('paymentscheck',this.localData).subscribe((data:any) => {
      localStorage.removeItem('supertoken')
     })
  }
}
