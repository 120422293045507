<div class="top=main-section contT">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <ul class="breadcrumb">
                    <li><a href="/">Home</a></li>
                    <li class="active">Min Profil</li>
                </ul>
            </div>
            <div class="col-md-12">
                <h1 class="text-center"><span class="txt_gray mob_hide">My </span>Profile</h1>
            </div>
        </div>
    </div>
</div>


<!-- listing-->
<section class="animated3 wow drop_eff5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-sm-6 wow fadeInLeft">
                <div class="list_box p-5 fs13 rel">
                    <a href="javascript:void(0);" class="del1" routerLink="/student-edit-profile"><i
                            class="far fa-edit"></i></a>
                    <div class="row">
                        <div class="col-md-6 b p-3">Name</div>
                        <div class="col-md-6 p-3">{{localData.name}}</div>

                        <div class="col-md-6 b p-3">Date of Birth</div>
                        <div class="col-md-6 p-3">{{localData.dob}}</div>

                        <div class="col-md-6 b p-3">Email ID</div>
                        <div class="col-md-6 p-3">{{localData.email}}</div>

                        <div class="col-md-6 b p-3">Phone No.</div>
                        <div class="col-md-6 p-3">{{localData.phone}}</div>

                        <div class="col-md-6 b p-3">State</div>
                        <div class="col-md-6 p-3">{{localData.state}}</div>

                        <div class="col-md-6 b p-3">City</div>
                        <div class="col-md-6 p-3">{{localData.city}}</div>
                    </div>
                    <div class="loc_feild">
                        <div class="row">
                            <div class="col-md-12 p-0">
                                <p class="add_cart"><a href="javascript:void(0);" routerLink="/change-password">Change
                                        Password</a></p>
                            </div>
                        </div>
                    </div>
                    <!--<form>
                    <div class="emp_area">
                        <div class="name_feild">
                            <p>First Name</p>
                            <p><input type="text"></p>
                        </div>							
                        <div class="loc_feild">
                            <p>Last Name</p>
                            <p><input type="text"></p>
                        </div>							
                        <div class="loc_feild">
                            <p>Date Of Birth</p>
                            <p><input type="text"></p>
                        </div>							
                        <div class="loc_feild">
                            <div class="row">
                                <div class="col-md-12 p-0"><p class="add_cart"><a href="">Save</a></p></div>
                            </div>
                        </div>							
                    </div>
                </form>-->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- listing Ends -->