<div class="mb-3 contT">
    <div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Mitt Konto</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1>Welcome to our Referral Program</h1>
    </div>
</div>
<div class="clearfix"></div>
<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-sm-12 wow fadeInLeft animated" style="visibility: visible;">
                <div class="list_box1">
                    <div class="refer_head">
                        <p class="fs22 b">SEK 25 for them, SEK 25 for you.</p>
                        <p>SEK 20 goes to them, SEK 20 goes to you. We will offer to a student who accepts your invitation SEK 20, and as a thank you, we will give you SEK 20 for each person you recommend.</p>
                    </div>
                    <div class="tab_cont pt-0">
                        <form>
                            <div class="emp_area bdrb pb-4 mb-4">
                                <div class="row">
                                    <div class="col-md-6 p-0">
                                        <div class="info_box">
                                            <div class="title">Invite by email (*)</div>
                                            <p class="rel">
                                                <a href="javascript:void(0);" class="abico red"></a><input placeholder="Please type an email" type="text" [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
                                                <span class="warning">{{emailerror }} </span></p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 p-0">
                                        <div class="info_box">
                                            <div class="title" style="opacity: 0;">Invite by email</div>
                                            <p class="add_cart"><a href="javascript:void(0);" (click)="onSubmitBillingAddress()">Send</a></p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 p-0">
                                        <div class="info_box">
                                            
                                            <p  class="title">Your invitation link</p>
                                       
                                            <a href="javascript:void(0);" class="abico red"></a><input style="margin-right: 150px;" placeholder="Referral link is" value={{globalurl}} type="text" >
                                            
                                        </div>
                                    </div>
                            
                                </div>
                            </div>
                            <div class="flex_cont">
                                <div class="flex_cont_in">
                                    <div class="pb-4 d_flex">
                                        <div class="df1 b">No.</div>
                                        <div class="df3 b">Email</div>
                                        <div class="df4 b">Name</div>
                                        <div class="df4 b">Phone</div>
                                        
                                    </div>
                                    <div class="pb-4 d_flex" *ngFor="let data of InvitationList ;let i=index">
                                        <div class="df1">{{i+1 || 'null'}}</div>
                                        <div class="df3">{{data.email}}</div>
                                        <div class="df4">{{data.name}} {{data.last_name}}</div>
                                        <div class="df4">{{data.phone}}</div>
                                        
                                    </div>
                                    <!-- <div class="pb-4 d_flex">
                                        <div class="df1">2</div>
                                        <div class="df2">John Doe</div>
                                        <div class="df3">johndoe@gmail.com</div>
                                        <div class="df4">14/4/2022</div>
                                        <div class="df5">Action</div>
                                    </div>
                                    <div class="pb-4 d_flex bg-gray">
                                        <div class="df1">3</div>
                                        <div class="df2">John Doe</div>
                                        <div class="df3">johndoe@gmail.com</div>
                                        <div class="df4">14/4/2022</div>
                                        <div class="df5">Action</div>
                                    </div> -->
                                </div>
                            </div>
                            <mat-paginator #paginator
               class="demo-paginator"
               (page)="handlePageEvent($event)"
               [length]="length"
               [pageSize]="pageSize"
               [disabled]="disabled"
               [showFirstLastButtons]="showFirstLastButtons"
               [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
               [hidePageSize]="hidePageSize"
               [pageIndex]="pageIndex"
               aria-label="Select page">
</mat-paginator>

<div class="demo-data">
  <div> Output event: {{(pageEvent | json) || 'No events dispatched yet'}} </div>
  <div> getNumberOfPages: {{paginator.getNumberOfPages()}} </div>
</div>
                        </form>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</section>
 