<div class="mb-3 contT">
	<div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Our Prices</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
    	<h1><span class="txt_gray mob_hide">Welcome To </span>Our Prices</h1>            
    </div>
</div>


<section class="animated3 wow drop_eff5" style="visibility: hidden; animation-name: none;">
  <div class="container mb-5">
      
    <div class="be-ban">
        <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4">
                <div class="list_box p-5 bgw">
                    <p class="b fs22">YOUR INVITATION TO 21ST CENTURY ONLINE TEACHING</p>
                    <p class="mt-4 fs16">Access eager students from around the world and inspire them to learn Maths, English, Science, French, Coding and much, much more! It is a simple process once you have started with us. Choose your subjects, select the students you accept and be the boss of your business.</p>
                </div>
            </div>
        </div>
    </div>
      
    <div class="row justify-content-center mt-5">
        <div class="col-md-12"><h1 class="text-center">Discounts</h1></div>
        <div class="col-lg-3 wow fadeInLeft" style="visibility: hidden; animation-name: none;">   
            <div class="list_box">
                <img src="assets/images/b1.jpg" class="img-fluid mb-3" alt="">
                <p class="p-3 b text-center">Student <br>Referral</p>
            </div>
        </div>
        <div class="col-lg-3 wow fadeInLeft" style="visibility: hidden; animation-name: none;">   
            <div class="list_box">
                <img src="assets/images/b2.jpg" class="img-fluid mb-3" alt="">
                <p class="p-3 text-center">Multiple Class Booking</p>
            </div>
        </div>
        <div class="col-lg-3 wow fadeInLeft" style="visibility: hidden; animation-name: none;">   
            <div class="list_box">
                <img src="assets/images/b3.jpg" class="img-fluid mb-3" alt="">
                <p class="p-3 text-center">Group Classes</p>
            </div>
        </div>
        <div class="col-lg-3 wow fadeInLeft" style="visibility: hidden; animation-name: none;">   
            <div class="list_box">
                <img src="assets/images/b4.jpg" class="img-fluid mb-3" alt="">
                <p class="p-3 text-center">Tutor Referral </p>
            </div>
        </div>
    </div>
      
    </div>
</section>