import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any=[];
  
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 

  }

  ngOnInit(): void {
  }
  onClosePopup(){
    this.commonService.SetLogoutPopup(false);
  }
  onLogout(){
    localStorage.removeItem('user');
    localStorage.removeItem('sid');
    this.commonService.SetLoggedIn(false);
    this.onClosePopup();
    this.commonService.SetContentHidden(true);
    this.router.navigate(['/'])
  }

}
