import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {
  cmsData: any=[];
  flag:any=null;
  constructor(public apiService :ApiService,private title: Title, private meta: Meta,public activatedRoute: ActivatedRoute,public commonService:CommonService) {
    this.commonService.SetFooterContent(false);
    this.flag = this.activatedRoute.snapshot.paramMap.get("id");

   }

  ngOnInit(): void {
    this.getData();
  }
  getData(){
    var sendrequestinput:any={id:"6"};
    this.apiService.post('cms_details',sendrequestinput).subscribe((data:any) =>{
      this.cmsData=data.data
      this.title.setTitle(this.cmsData.meta_title);
      this.meta.updateTag({ name: 'keyword', content: this.cmsData.meta_keyword });
      this.meta.updateTag({ name: 'description', content: this.cmsData.meta_description });
    } 
    );
    
  }
}
