<div class="mb-3 ptfix">
    <div class="container pt-4 pl-3">
        <ul class="breadcrumb">
            <li><a routerLink="/">Home</a></li>
            <li *ngIf="catData?.name"><a href="javascript:void(0)">{{catData?.name}}</a></li>
            <li class="active">Listing</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide">Product </span>Listing</h1>
        <p _ngcontent-qeb-c166="" class="fs13 mt-4 pr-2">{{catData?.description}}</p>
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->

<!--listing-->
<!-- <div class="container ">
    <div class="content mt-5 mb-5 " *ngFor="let pro of resData; let i= index">
        <p class="b_name">
            <a routerLink="/product-details/{{pro.id}}">
                {{pro.title}}
            </a>
        </p>
        <div class="content-overlay"></div>
        
        <img [src]="allowurl(apiService.imageBase+pro.image)" alt="{{pro.title}}"
                id="image{{pro.id}}" class="img-fluid bgb_img content-image" />
        <div class="content-details fadeIn-bottom">
            <a routerLink="/product-details/{{pro.id}}">
                <p class="content-text">{{pro.title}}</p>
            </a>
        </div>
    </div>
</div> -->
<div class="container mt-5 mb-5">
    <div class="row">
        <div class="content mt-5 mb-5 " *ngFor="let pro of resData; let i= index">
            <!-- <p class="b_name">
                <a routerLink="/product-details/{{pro.id}}">
                    {{pro.title}}
                </a>
            </p> -->
            <a routerLink="/product-details/{{pro.id}}"><div class="content-overlay"></div></a>

            <img [src]="allowurl(apiService.imageBase+pro.image)" alt="{{pro.title}}" id="image{{pro.id}}"
                class="img-fluid bgb_img content-image" />
            <div class="content-details fadeIn-bottom">
                <a routerLink="/product-details/{{pro.id}}">
                    <p class="content-text">{{pro.title}}</p>
                </a>
            </div>
            {{pro.title}}
        </div>
        
        <!-- <div class="col-md-3"  *ngFor="let pro of resData; let i= index">
            <div class="list_box">
                <a routerLink="/product-details/{{pro.id}}">
                    <img [src]="allowurl(apiService.imageBase+pro.image)" alt="{{pro.title}}" class="img-fluid"/>
                    {{pro.title}}
                </a>
            </div>
        </div> -->
    </div>
</div>
<div class="clearfix"></div>