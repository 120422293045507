import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-tutor-view',
  templateUrl: './tutor-view.component.html',
  styleUrls: ['./tutor-view.component.scss'],
  providers:[DatePipe],
})
export class TutorViewComponent implements OnInit {
  addForm: FormGroup | any;public submitted = false;
  localData:any;
  Book_Class_Data:any;
  TutorDetails:any={};
  isUserLoggedInData: any;
  constructor(private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService,public activatedRoute: ActivatedRoute,private datePipe: DatePipe) {
    var jsonData: any = localStorage.getItem('user');
    this.localData = JSON.parse(jsonData);
  }
  get f() { return this.addForm.controls; }


  allowurl(url:any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  ngOnInit(): void {
    this.commonService.GetBookClassCancelTutorDataModal().subscribe(data =>{
      console.log('Class>>>>>',data);
      this.Book_Class_Data=data;
    })
    this.commonService.GetTutorViewDetails().subscribe(data =>{
      console.log('Class>>>>>',data);
      this.TutorDetails=data;
    })
    this.addForm = this.fb.group({
      reason : new FormControl('', [Validators.required]),
    });
    this.commonService.GetLoginUserDetails().subscribe(data =>{
      console.log(data);
      this.isUserLoggedInData=data;
      console.log(this.isUserLoggedInData,'narender');

    })
  }
  onClosePopup() {
    this.commonService.SetisTutorView(false);
  }
  onSubmit() {
    this.submitted=true;
    console.log('this.addForm.value>>>>>>>>>>>>>>',this.addForm.value);
    
    if (this.addForm.invalid) {
      this.messageService.showWarning("Field's are required");
      return
    } else {
      var sendRequestData: any = this.addForm.value;
      sendRequestData.sid = localStorage.getItem('sid');
      sendRequestData.booked_class_id  = this.Book_Class_Data.booked_class_id;
      sendRequestData.slot_id   = this.Book_Class_Data.slot_id;
      sendRequestData.teacher_id   = this.Book_Class_Data.teacher_id;
      sendRequestData.schedule_date   = this.datePipe.transform(this.Book_Class_Data.schedule_slot_date, 'yyyy-MM-dd');;
      sendRequestData.time_from   = this.Book_Class_Data.time_from;
      sendRequestData.student_id   = this.Book_Class_Data.student_id;
      console.log('sendRequestData>>>>>>',sendRequestData);
      // return;
      this.apiService.post('cancle_slot_booking', sendRequestData).subscribe((data: any) => {
        if (data.replyCode == "success") {
          this.addForm.reset();
          this.submitted=false;
          this.onClosePopup();
          this.commonService.SetUpdateProfileChanged(true);
          this.messageService.showSuccess(data.replyMsg);
        } else {
          this.messageService.showError(data.replyMsg);
        }
      })
    }
  }
}
