import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'src/app/services';

import { CommonService } from 'src/app/services';
@Component({
  selector: 'app-my-score',
  templateUrl: './my-score.component.html',
  styleUrls: ['./my-score.component.scss']
})
export class MyScorecardComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any=[];
  localData: any=[];
  userData: any=[];
  result:any=0
  mindfullness:any=0
  engagement:any=0
  willingness:any=0
  persaverance:any=0
  l_understanding:any=0
  remory_retention:any=0
  problem_solving:any=0
  analytical_skills:any=0
  scorecard_length:any=0
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  p: number = 1;
  total_count: any;
  bodyDataRequest: any = {};
  keyValue = '';
  Username='';
  LoginUserDetails: any = {};
  isContentShow:boolean=false;
  isContentShow1:boolean=false;

  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
    var jsonData: any = localStorage.getItem('user');
    this.localData = JSON.parse(jsonData);
    this.userData = this.localData;
  }
  isLoginUser:boolean=false;
  ngOnInit(): void {
    this.bodyDataRequest['start'] = "0";
    this.bodyDataRequest['limit'] = "2";
    this.bodyDataRequest['learning'] = "0";
    this.bodyDataRequest['keyword'] = this.keyValue;
    this.getstudentscorecard(this.bodyDataRequest);

    this.commonService.GetLoggedIn().subscribe((data:any)=>{
      this.isLoginUser=data;
      if(this.isLoginUser){
      }else{
        this.router.navigate(['/'])
      }
      console.log('isLoginUser>>>>>>',this.isLoginUser);
    })
    this.commonService.GetLoginUserDetails().subscribe((data:any)=>{
      this.LoginUserDetails=data;
      this.Username=this.LoginUserDetails?.last_name
      console.log(this.Username);
      
      console.log('isLoginUser>>>>>>',this.isLoginUser);
    })
    this.commonService.GetContentHidden().subscribe((data:any)=>{
      this.isContentShow=false;
      this.isContentShow1=false;
    })
   
  }
  onOpenSupportPopup(){
    
  }
  student_scorecard:any=[]
  getstudentscorecard(bodyDataRequest:any){
    var sendRequestData:any=bodyDataRequest;
    sendRequestData.student_id=this.userData.id 

    this.apiService.post('student_scorecard', sendRequestData).subscribe((res: any) => {
      this.result =0
      this.mindfullness =0
this.engagement =0
this.willingness =0
this.persaverance =0
this.l_understanding =0
this.remory_retention =0
this.problem_solving =0
this.analytical_skills =0
  this.student_scorecard=res.data;
this.scorecard_length=this.student_scorecard.filter((item: any) => item).length;
  for (let i = 0; i < this.student_scorecard.filter((item: any) => item).length; i++) {
this.mindfullness +=this.student_scorecard[i].mindfullness;
  this.engagement +=  this.student_scorecard[i].engagement;
  this.willingness +=  this.student_scorecard[i].willingness;
  this.persaverance +=  this.student_scorecard[i].persaverance;
  this.l_understanding +=  this.student_scorecard[i].l_understanding;
  this.remory_retention +=  this.student_scorecard[i].remory_retention;
  this.problem_solving +=  this.student_scorecard[i].problem_solving;
  this.analytical_skills +=  this.student_scorecard[i].analytical_skills;
    this.result +=(this.student_scorecard[i].mindfullness + this.student_scorecard[i].engagement + this.student_scorecard[i].willingness + this.student_scorecard[i].persaverance + this.student_scorecard[i].l_understanding + this.student_scorecard[i].remory_retention + this.student_scorecard[i].analytical_skills + this.student_scorecard[i].problem_solving)/8
console.log('this.result',this.result);
 
  }

    }, error => {
      this.messageService.showError("Oops! Something went wrong!");
    });
  }
  
resetfilter() {
  this.bodyDataRequest['start'] = "0";
  this.bodyDataRequest['keyword'] = '';
     this.getstudentscorecard(this.bodyDataRequest);

}
onChangeLimit(limit:any){
  this.p = 1;
  this.bodyDataRequest['start'] = "0";
  this.bodyDataRequest['limit'] = String(limit.value);
     this.getstudentscorecard(this.bodyDataRequest);

}

onSearch() {
  this.p = 1;
  this.bodyDataRequest['start'] = "0";
  this.bodyDataRequest['limit'] = "5";
  this.bodyDataRequest['keyword'] = this.keyValue;
     this.getstudentscorecard(this.bodyDataRequest);

}
onPageChange(event:any) {
  this.apiService.loadingShow = true;
  this.p = event;
  // console.log('event::::', event);
  this.bodyDataRequest['start'] = String((event * this.bodyDataRequest['limit']) - this.bodyDataRequest['limit']);
     this.getstudentscorecard(this.bodyDataRequest);

}
}
