<style>
  .close {
    position: absolute;
    top: 13px;
    right: 0;
    margin-right: 10px;
    transition: all 200ms;
    font-size: inherit;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }

</style>

<header style="padding-top: 3px !important">
  <div class="container rel">
   
    <div class="srch_bar" *ngIf="!isLoginUser">
      <!-- <a href="javascript:void(0);" class="style1 ms-5" routerLink="/find-a-tutor">Intresseanmälan</a> -->
      <a href="javascript:void(0);" class="style1 ms-5" routerLink="/contact-us">Intresseanmälan</a>
      <a href="javascript:void(0);" class="style1 ms-2 mobile-view-none" routerLink="/contact-us"><i class="fa-solid fa-phone"></i></a>
    </div>
    
    <div *ngIf="!isLoggedIn" class="user_links" id="target" [ngStyle]="{ display: isContentShow ? 'block' : 'none' }">
      <div class="row bdrb p-3">
        <div class="log_head">Välkommen</div>
        <div class="log_link">
          <a href="javascript:void(0);" (click)="onOpenLoginPopup()"  id="hidess">Logga in</a>
          <a href="javascript:void(0);" (click)="onOpenRegisterPopup()" id="regi">Registrera dig</a>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    
    <div class="clearfix"></div>

    <nav class="navbar navbar-expand-lg navbar-dark Skolbryggan">
      <a class="navbar-brand" href="./" title="Skolbryggan">
        <img src="assets/img/logo.png" alt="Skolbryggan" />
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_nav" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="main_nav">
        <ul class="navbar-nav ml-nav">
          <!-- <li class="nav-item active" data-toggle="collapse" data-target="#main_nav" aria-expanded="false">
            <a class="nav-link" href="#" target="_blank">Careers</a>
          </li> -->

          <li *ngIf="LoginUserDetails.role_id != 3" class="nav-item" data-toggle="collapse" data-target="#main_nav" aria-expanded="false">
            <a class="nav-link" routerLink="/find-a-tutor"> Hitta en Studiementor</a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target="#main_nav" aria-expanded="false">
            <a class="nav-link" href="javascript:void(0);" routerLink="/cms/whyus">
              Varför oss</a>
          </li>
          <li *ngIf="!isLoginUser" class="nav-item" data-toggle="collapse" data-target="#main_nav" aria-expanded="false">
            <a class="nav-link" routerLink="/become-a-tutor"> Bli en Studiementor</a>
          </li>
          <li class="nav-item" *ngIf="!isLoginUser" data-toggle="collapse" data-target="#main_nav" aria-expanded="false">
            <a class="nav-link toggle" href="javascript:void(0);" (click)="isShow()">
              Logga in</a>
          </li>
          <div class="srch_bar" *ngIf="isLoginUser">
            <a href="javascript:void(0);" class="toggle" (click)="isShow1()"><img *ngIf="LoginUserDetails?.image"
                [src]="allowurl(apiService.imageBase + LoginUserDetails.image)"
                onError="this.src='assets/images/profile-demo.png'" alt="" />
              <img *ngIf="!(LoginUserDetails?.image)" src="assets/images/profile-demo.png" alt="" />
              {{ LoginUserDetails?.name }} {{ Username
              }}<span class="arrow"></span></a>
          </div>
          <div class="user_links" [ngStyle]="{ display: isContentShow1 ? 'block' : 'none' }">
            <div class="row bdrb p-3" *ngIf="this.LoginUserDetails.role_id == 2">
              <div class="log_head">
                Welcome {{ LoginUserDetails?.name }} {{ Username }}!
              </div>

              <a class="close" href="javascript:void(0);" (click)="isShow1()">x</a>

              <div class="lft_link mt-0 w100">
                <a href="javascript:void(0);" (click)="isShow1()" (click)="onRedirectProfilePage()"><i
                    class="fa fa-pencil"></i>Min Profil</a>
 
                <a href="javascript:void(0);"  routerLink="/my-scorecard" ><i
                    class="fas fa-chart-pie"></i>Mitt Resultatkort</a>

                <a href="javascript:void(0);" routerLink="/find-a-tutor"
                  ><i class="fa fa-solid fa-graduation-cap"></i>Klassrumsdemo</a>
                <a href="javascript:void(0);" (click)="isShow1()" routerLink="/contact-us"><img
                    _ngcontent-nwn-c175="" alt="" onerror="this.onerror=null;this.src='assets/images/profile-demo.png';"
                    style="width: 18px; opacity: 0.5"
                    src="https://suited-admin.mrmmbs.com/node/uploads/attachment-1674819240238.png" />
                  Support</a>
                <a href="javascript:void(0);" (click)="onOpenLogout()"><i class="fas fa-sign-out-alt"></i>Logga ut</a>
              </div>
            </div>

            <div class="row bdrb p-3" *ngIf="this.LoginUserDetails.role_id == 3">
              <div class="log_head">    Welcome {{ LoginUserDetails?.name }} {{ Username }}
              </div>
              <a href="javascript:void(0);" class="close" (click)="isShow1()">x</a>
              <div class="lft_link mt-0 w100">
                <a href="javascript:void(0);" (click)="isShow1()" (click)="onRedirectProfilePage()"><i
                    class="fa fa-pencil"></i>Min Profil</a>
              
                <a href="javascript:void(0);" routerLink="/find-a-tutor"
                  href=""
                  target="_blank"><i class="fa fa-solid fa-graduation-cap"></i>Klassrumsdemo</a>
                <!-- <a href="javascript:void(0);" (click)="isShow1()" routerLink="/chat" routerLinkActive="active"
                  target="_blank" rel="bookmark"><i class="fas fa-comments"></i>Chat with Students</a> -->
                <a href="javascript:void(0);" (click)="isShow1()" routerLink="/contact-us"><img
                    _ngcontent-nwn-c175="" alt="" onerror="this.onerror=null;this.src='assets/images/profile-demo.png';"
                    style="width: 18px; opacity: 0.5"
                    src="assets/images/user_images.png" />
                  Support</a>
            
                <a href="javascript:void(0);" (click)="onOpenLogout()"><i class="fas fa-sign-out-alt"></i>Logga ut</a>
              </div>
            </div>

            <div class="row bdrb p-3" *ngIf="this.LoginUserDetails.role_id == 5">
              <div class="log_head">
                Welcome {{ LoginUserDetails?.name }} {{ Username }}!
              </div>
              <a href="javascript:void(0);" class="close" (click)="isShow1()">x</a>

              <div class="lft_link mt-0 w100">
                <a href="javascript:void(0);" (click)="isShow1()" (click)="onRedirectProfilePage()"><i
                    class="fa fa-pencil"></i>Min Profil</a>       
                <a href="javascript:void(0);" routerLink="/find-a-tutor"
                  href=""
                  target="_blank"><i class="fa fa-solid fa-graduation-cap"></i>Klassrumsdemo</a>

                <a href="javascript:void(0);" (click)="isShow1()" routerLink="/contact-us"><img
                    _ngcontent-nwn-c175="" alt="" onerror="this.onerror=null;this.src='assets/images/profile-demo.png';"
                    style="width: 18px; opacity: 0.5"
                    src="assets/images/user_images.png" />
                  Support</a>
                <a href="javascript:void(0);" (click)="isShow1()" (click)="onOpenLogout()"><i
                    class="fas fa-sign-out-alt"></i>Logga ut</a>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </nav>
  </div>
</header>

<script>
  function myFunction() {
    var x = document.getElementById("myDIV");
    if (x.style.display === "none") {
    } else {
      x.style.display = "none";
    }
  }
</script>

<div id="loader"></div>