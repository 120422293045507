import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CommonService {
    GetBookAClassModal1() {
      throw new Error('Method not implemented.');
    }
    isLoggedIn = new BehaviorSubject<boolean>(false);
    isProductIdChange = new BehaviorSubject<string>('');
    isSetting = new BehaviorSubject<any>([]);
    isLoginUserDetails = new BehaviorSubject<any>('');
    isLoginPopup = new BehaviorSubject<boolean>(false);
    isLocationPopup = new BehaviorSubject<boolean>(false);
    isRegisterPopup = new BehaviorSubject<boolean>(false);
    isAddFriendPopup = new BehaviorSubject<boolean>(false);
    isForgotPopup = new BehaviorSubject<boolean>(false);
    isLogoutPopup = new BehaviorSubject<boolean>(false);
    paymentsPopup = new BehaviorSubject<boolean>(false);
    isContentHidden = new BehaviorSubject<boolean>(false);
    isFooterContent = new BehaviorSubject<boolean>(false);
    isAddSubject = new BehaviorSubject<boolean>(false);
    isUpdateProfileChanged = new BehaviorSubject<boolean>(false);
    isUpdateCourseChanged = new BehaviorSubject<boolean>(false);
    isBookAClassModal = new BehaviorSubject<boolean>(false);
    isBookClassCancelTutorModal = new BehaviorSubject<boolean>(false);
    isBookClassCancelTutorDataModal = new BehaviorSubject<any>('');
    isBookClassCancelTutorModal1 = new BehaviorSubject<boolean>(false);
    isBookClassCancelTutorDataModal1 = new BehaviorSubject<any>('');
    isAddProject = new BehaviorSubject<boolean>(false);
    isAddQuiz = new BehaviorSubject<boolean>(false);
    isAddParentChild = new BehaviorSubject<boolean>(false);
    isTutorView = new BehaviorSubject<boolean>(false);
    isTutorViewDetails = new BehaviorSubject<any>('');
    isChatGroup = new BehaviorSubject<boolean>(false);
    iseditchatGroup = new BehaviorSubject<boolean>(false);
    isupdateprofile = new BehaviorSubject<boolean>(false);
    isChatChanged = new BehaviorSubject<boolean>(false);
    isChatData = new BehaviorSubject<boolean>(false);
    isChatGroupUser = new BehaviorSubject<boolean>(false);
    isBannerKeyword = new BehaviorSubject<any>('');
    GetTutorViewDetails(){
        return this.isTutorViewDetails.asObservable();
    }
    SetTutorViewDetails(data: boolean){
        this.isTutorViewDetails.next(data);
    }
    GetChatChanged(){
        return this.isChatChanged.asObservable();
    }
    SetChatChanged(data: boolean){
        this.isChatChanged.next(data);
    }
    GetChatGroupUser(){
        return this.isChatGroupUser.asObservable();
    }
    SetChatGroupUser(data: boolean){
        this.isChatGroupUser.next(data);
    }
    GetChatData(){
        return this.isChatData.asObservable();
    }
    SetChatData(data: boolean){
        this.isChatData.next(data);
    }
    GetChatGroup(){
        return this.isChatGroup.asObservable();
    }
    SetChatGroup(data: boolean){
        this.isChatGroup.next(data);
    }
    GeteditchatGroup(){
        return this.iseditchatGroup.asObservable();
    }
    SeteditchatGroup(data: boolean){
        this.iseditchatGroup.next(data);
    }
    Getupdateprofile(){
        return this.isupdateprofile.asObservable();
    }
    Setupdateprofile(data: boolean){
        this.isupdateprofile.next(data);
    }
    GetBannerKeyword(){
        return this.isBannerKeyword.asObservable();
    }
    SetBannerKeyword(data: boolean){
        this.isBannerKeyword.next(data);
    }
    GetisTutorView(){
        return this.isTutorView.asObservable();
    }
    SetisTutorView(data: boolean){
        this.isTutorView.next(data);
    }
    GetLoggedIn(){
        return this.isLoggedIn.asObservable();
    }
    SetLoggedIn(data: boolean){
        this.isLoggedIn.next(data);
    }
    GetisProductIdChange(){
        return this.isProductIdChange.asObservable();
    }
    SetisProductIdChange(data: any){
        this.isProductIdChange.next(data);
    }
    GetSetting(){
        return this.isSetting.asObservable();
    }
    SetSetting(data: any){
        this.isSetting.next(data);
    }
    GetLoginUserDetails(){return this.isLoginUserDetails.asObservable()}
    SetLoginUserDetails(data: boolean){this.isLoginUserDetails.next(data)}

    GetLoginPopup(){return this.isLoginPopup.asObservable()}
    SetLoginPopup(data: boolean){this.isLoginPopup.next(data)}

    GetLocationPopup(){return this.isLocationPopup.asObservable()}
    SetLocationPopup(data: boolean){this.isLocationPopup.next(data)}

    GetRegisterPopup(){return this.isRegisterPopup.asObservable()}
    SetRegisterPopup(data: boolean){this.isRegisterPopup.next(data)}

    GetAddFriendPopup(){return this.isAddFriendPopup.asObservable()}
    SetAddFriendPopup(data: boolean){this.isAddFriendPopup.next(data)}

    GetForgotPopup(){return this.isForgotPopup.asObservable()}
    SetForgotPopup(data: boolean){this.isForgotPopup.next(data)}

    GetLogoutPopup(){return this.isLogoutPopup.asObservable()}
    SetLogoutPopup(data: boolean){this.isLogoutPopup.next(data)}
    Getpayments(){return this.paymentsPopup.asObservable()}
    Setpayments(data: boolean){this.paymentsPopup.next(data)
    console.log(data,'setpayment');
    
    }

    GetContentHidden(){return this.isContentHidden.asObservable()}
    SetContentHidden(data: boolean){this.isContentHidden.next(data)}

    GetFooterContent(){return this.isFooterContent.asObservable()}
    SetFooterContent(data: boolean){this.isFooterContent.next(data)}

    GetAddSubject(){return this.isAddSubject.asObservable()}
    SetAddSubject(data: boolean){this.isAddSubject.next(data)}

    GetUpdateProfileChanged(){return this.isUpdateProfileChanged.asObservable()}
    SetUpdateProfileChanged(data: boolean){this.isUpdateProfileChanged.next(data)}

    GetUpdateCourseChanged(){return this.isUpdateCourseChanged.asObservable()}
    SetUpdateCourseChanged(data: boolean){this.isUpdateCourseChanged.next(data)}

    GetBookAClassModal(){return this.isBookAClassModal.asObservable()}
    SetBookAClassModal(data: boolean){this.isBookAClassModal.next(data)}

    GetBookClassCancelTutorModal(){return this.isBookClassCancelTutorModal.asObservable()}
    SetBookClassCancelTutorModal(data: boolean){this.isBookClassCancelTutorModal.next(data)}
//
    GetBookClassCancelTutorDataModal(){return this.isBookClassCancelTutorDataModal.asObservable()}
    SetBookClassCancelTutorDataModal(data: boolean){this.isBookClassCancelTutorDataModal.next(data)}
   
    GetBookClassCancelTutorModal1(){return this.isBookClassCancelTutorModal1.asObservable()}
    SetBookClassCancelTutorModal1(data: boolean){this.isBookClassCancelTutorModal1.next(data)}
//
    GetBookClassCancelTutorDataModal1(){return this.isBookClassCancelTutorDataModal1.asObservable()}
    SetBookClassCancelTutorDataModal1(data: boolean){this.isBookClassCancelTutorDataModal1.next(data)}

    GetAddProject(){return this.isAddProject.asObservable()}
    SetAddProject(data: boolean){this.isAddProject.next(data)}

    GetAddQuiz(){return this.isAddQuiz.asObservable()}
    SetAddQuiz(data: boolean){this.isAddQuiz.next(data)}

    GetAddParentChild(){return this.isAddParentChild.asObservable()}
    SetAddParentChild(data: boolean){this.isAddParentChild.next(data)}

}

