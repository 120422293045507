import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { FacebookLoginProvider, GoogleLoginProvider, MicrosoftLoginProvider, SocialAuthService } from 'angularx-social-login';
import { JwtHelperService } from '@auth0/angular-jwt/src/jwthelper.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  AddForm: FormGroup |any; submitted = false; btnsubmitted = false;
  user: any;
  helper = new JwtHelperService();

  constructor(private readonly authService: SocialAuthService,private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 

  }
  type:boolean=false;
  cptype:boolean=false;
  get f() { return this.AddForm.controls; }
passwordinvalid:any
emailinvalid:any
  ngOnInit(): void {
    this.authService.authState.subscribe((user) => {
        this.user = user;
        console.log(user,'data');
        if(user){
          this.apiService.post('social_login',{email:user.email}).subscribe((data:any) => {
            if(data.replyCode == "success"){
              this.commonService.SetLoginUserDetails(data.data);
              this.commonService.SetLoggedIn(true);
              var json=JSON.stringify(data.data);
              localStorage.setItem('user',json)
              localStorage.setItem('sid',data.sid)
              this.onClosePopup();
              this.AddForm.reset();
          
              this.submitted=false;
              this.messageService.showSuccess(data.replyMsg);
            window.location.reload();
    
            }else{
              this.messageService.showError(data.replyMsg);
            }
          })
        }
  
      });
    this.AddForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
  })
  
  }
  onClosePopup(){
    this.commonService.SetLoginPopup(false);
  }
  
  onSubmit(){
    this.submitted=true;
    this.AddForm.get('email').markAsTouched();
    this.AddForm.get('password').markAsTouched();
    if(this.AddForm.invalid){
      return;
    }else{
      this.apiService.post('teacher_login',this.AddForm.value).subscribe((data:any) => {
        if(data.replyCode == "error1"){
          this.passwordinvalid="Password is incorrect"
        }

        
        if(data.replyCode == "success"){
          this.commonService.SetLoginUserDetails(data.data);
          this.commonService.SetLoggedIn(true);
          var json=JSON.stringify(data.data);
          localStorage.setItem('user',json)
          localStorage.setItem('sid',data.sid)
          this.onClosePopup();
          this.AddForm.reset();
		  
          this.submitted=false;
          this.messageService.showSuccess(data.replyMsg);
		  	window.location.reload();
      
        }else if(data.replyMsg=="Please insert registered email"){
          this.emailinvalid="Email is incorrect"

        }else{
          this.messageService.showError(data.replyMsg);
      
        }
      })
    }
  }
  signInWithgoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => console.log(x));
  }
  signInWithmicro(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => console.log(x));
  }
  onOpenForgotPassword():void{
    
    this.onClosePopup()

    this.commonService.SetForgotPopup(true);
    console.log(this.commonService.SetForgotPopup(true));
    
  }
  keyDownFunction(event:any) {    
    if (event.keyCode === 13) {
      this.onSubmit()
    }
  }
  onOpenRegisterPopup(){
  this.onClosePopup()
    this.commonService.SetRegisterPopup(true);
  }
  onChangeType1(){
    this.type=true;
  }
  onChangeType2(){
    this.type=false;
  }
  async signInWithApple() {
    const CLIENT_ID = "com.myapp.bundle.backend"
    const REDIRECT_API_URL = "https://www.abc.com/api/auth-apple-signin"
    window.open(
        `https://appleid.apple.com/auth/authorize? +
            client_id=${CLIENT_ID}& +
            redirect_uri=${encodeURIComponent(REDIRECT_API_URL)}& +
            response_type=code id_token& +
            scope=name email& +
            response_mode=form_post`,
        '_blank'
    );

    window.addEventListener('message', async event => {
        const decodedToken = this.helper.decodeToken(event.data.id_token);
        let requestData = {}
        if (event.data.user) {
            const userName = JSON.parse(event.data.user);
            requestData = {
                "email": decodedToken.email,
                "name": `${userName.name.firstName} ${userName.name.lastName}`,
                "socialId": decodedToken.sub,
            };
        } else {
            requestData = {
                "email": decodedToken.email,
                "socialId": decodedToken.sub,
            };
        }
        console.log(`User Data : ${requestData}`);
        // do your next stuff here
    });
};
}
