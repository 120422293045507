import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';

@Component({
  selector: 'app-updateprofile',
  templateUrl: './updateprofile.component.html',
  styleUrls: ['./updateprofile.component.scss']
})
export class UpdateprofileComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any = [];

  uploadedImagesData: any = '';
  fileToUpload: any = null;
  localData: any;
  createuser1: FormGroup | any; public submitted1 = false;
  editFlag:boolean=false;
  constructor(private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService) {
    var jsonData: any = localStorage.getItem('user');
    this.localData = JSON.parse(jsonData);
  }
  get f1() { return this.createuser1.controls; }
  ChatfriendList:any=[];
  chat_group_id:any;

  ngOnInit(): void {
    this.createuser1 = this.fb.group({
      name: new FormControl('', [Validators.required]),

      // group_id: new FormControl(''),
    });
  

      var sendRequestData: any = {}
      sendRequestData.UID = this.localData.id;
      sendRequestData.returnFriends = 0,
        sendRequestData.returnJoinedGroups =0,
        this.apiService.post('getUser', sendRequestData).subscribe((data: any) => {
          this.ChatfriendList = data.success.data.user;
          console.log(this.ChatfriendList,'this.ChatfriendList');
          this.uploadedImagesData=this.ChatfriendList.avatar
          console.log(this.uploadedImagesData);
          
          this.createuser1.controls['name'].setValue( this.ChatfriendList.name);

  
        })
    
  }
  allowurl(url: any) {
    console.log(url,'url');
    
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  uploadFileToActivity(image: any) {
    this.apiService.postFile(image).subscribe((data: any) => {
      this.uploadedImagesData =this.apiService.imageBase+''+data.name;
      console.log(this.uploadedImagesData,'this.uploadedImagesData');
      
    }, error => {
      console.log(error);
    });
  }
  onSelectFile(file: any) {
    this.uploadFileToActivity(file.files[0])
    if (file.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(file.files[0]);
      reader.onload = (event) => { }
    }
  }
  onClosePopup() {
    this.commonService.Setupdateprofile(false);
  }
  onSubmit() {
    this.submitted1=true;
    if (this.createuser1.invalid) {
      this.messageService.showWarning('Please enter group name')
      return
    } else {
      var sendRequestData: any = this.createuser1.value;
      sendRequestData.UID  = this.ChatfriendList.uid;
      sendRequestData.avatarURL = this.uploadedImagesData;
      sendRequestData.profileURL = this.uploadedImagesData;
      sendRequestData.email = this.localData.email;
    
      this.apiService.post('updateUser', sendRequestData).subscribe((data: any) => {

        if (data.success.status == "2000") {
          this.editFlag=false;
          this.commonService.SetChatChanged(true);
          this.onClosePopup();
          this.commonService.SetUpdateProfileChanged(true);
          this.messageService.showSuccess("Profile updated Successfully");
        } else {
          this.messageService.showError(data.replyMsg);
        }
      })
    }
  }
}
