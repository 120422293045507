import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';

import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-category-listing',
  templateUrl: './category_listing.component.html',
  styleUrls: ['./category_listing.component.scss']
})
export class CategoryListingComponent implements OnInit {
  flag:any=null;
  categoryId=null;
  homeBlog: any=[];
  resData:any=null;
  constructor(public apiService :ApiService,public sanitizer: DomSanitizer, public activatedRoute: ActivatedRoute,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
    this.flag = this.activatedRoute.snapshot.paramMap.get("id");
    if (this.flag) {
      this.categoryId = this.flag;
    }
  }

  
  ngOnInit(): void {
    this.getDataById();
  }
  allowurl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getDataById() {
    var self = this;
    var sendRequestData = {
      isAdmin:0
    };
    self.apiService.post('category_list', sendRequestData).subscribe((res: any) => {
      if (res.replyCode == 'success') {
        this.resData = res.data;
      }
    },
      error => {
        console.log("Oops! Something went wrong!");
      });
  }

}
