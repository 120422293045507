<div class="mb-3 contT">
    <div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Find A Course</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide">Welcome In </span>Find A Course</h1>
    </div>
</div>


<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 wow fadeInLeft animated" style="visibility: visible;">
                <div class="d-lg-none dd_next hand mb-3 search_b"> <span class="pr-4">Search Tutor</span></div>
                <form class="find f_dd_box">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="emp_area">
                                <div class="name_feild">
                                    <p class="">Topics</p>
                                    <p><select name="" id="">
                                            <option value="">Select</option>
                                        </select></p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="emp_area">
                                <div class="name_feild">
                                    <p class="">Subtopics</p>
                                    <p><select name="" id="">
                                            <option value="">Select</option>
                                        </select></p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="emp_area">
                                <div class="name_feild">
                                    <p class="">Level</p>
                                    <p><select name="" id="">
                                            <option value="">Select</option>
                                        </select></p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="emp_area pt-2">
                                <div class="name_feild ac">
                                    <a href="" class="normw_btn">Search</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="text-right">Just tick <img src="images/tickc.jpg" alt=""> any 3 course and press
            <form class="frmt">
                <input type="submit" class="compare" value="Compare" (click)="getCompare()">
            </form> button.
        </div>
    </div>
</section>

<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <!-- filter-->
                <form class="list_box mb-4 bg-gray1">
                    <div class="dd_next hand p-3 bdrb fs16 b"> <span>Filter Your Search</span></div>
                    <div class="emp_area f_dd_box p-3 mb-4">
                        <div class="name_feild">
                            <p><strong>Star Rating</strong></p>
                            <div class="mt-2"><input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2"
                                    id="rad1"> <label for="rad1" class="star"><i class="fas fa-star"></i><i
                                        class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                        class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></label>
                            </div>
                            <div class="mt-2"><input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2"
                                    id="rad2"> <label for="rad2" class="star"><i class="fas fa-star"></i><i
                                        class="fas fa-star"></i><i class="fas fa-star"></i><i
                                        class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></label>
                            </div>
                            <div class="mt-2"><input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2"
                                    id="rad3"> <label for="rad3" class="star"><i class="fas fa-star"></i><i
                                        class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 &amp;
                                        up (232)</span></label></div>
                            <div class="mt-2"><input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2"
                                    id="rad4"> <label for="rad4" class="star"><i class="fas fa-star"></i><i
                                        class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></label>
                            </div>
                            <div class="mt-2"><input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2"
                                    id="rad5"> <label for="rad5" class="star"><i class="fas fa-star"></i><span
                                        class="letn"> 4.5 &amp; up (232)</span></label></div>
                        </div>
                        <div class="loc_feild">
                            <p>Price Range</p>
                            <div class="range-slider">
                                <span>
                                    <input type="number" value="25000" min="0" max="120000" class="mt-2 float-left">
                                    <input type="number" value="50000" min="0" max="120000" class="mt-2 float-right">
                                </span>
                                <input value="25000" min="0" max="120000" step="500" type="range">
                                <input value="50000" min="0" max="120000" step="500" type="range">
                            </div>
                        </div>
                        <div class="loc_feild">
                            <p>Tutoring Language</p>
                            <p><select name="" id="">
                                    <option value="">Select</option>
                                </select></p>
                        </div>
                        <div class="loc_feild">
                            <p>Duration</p>
                            <p><select name="" id="">
                                    <option value="">Select</option>
                                </select></p>
                        </div>
                    </div>
                </form>
                <!-- filter end-->
            </div>
            <div class="col-md-9">
                <div class="row">
                    <!-- <div class="col-lg-4 col-sm-6 wow fadeInLeft" style="visibility: visible;">
                        <div class="list_box">
                            <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                            <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                            <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                            <a href="course-details.htm" title=""><img src="images/t5.jpg" alt="" class="img-fluid"></a>
                            <div class="p-3 rel">
                                <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></p>

                                <p class="b float-left"><a href="course-details.htm" title="">Computer Progr...</a></p>
                                <p class="b float-right">$50.00/hr</p>
                                <p class="clearfix"></p>
                                <p class="fs12">Max C.</p>
                                <p class="chkbox"><input type="checkbox"></p>
                                <p class="tbot">Duration (in hour) : <span>10</span></p>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-lg-4 col-sm-6 wow fadeInLeft animated" style="visibility: visible;"
                        *ngFor="let list of courseList">
                        <div class="list_box">
                            <a href="javascript:void(0);" class="chat_btn" title="Let's Chat"><i
                                    class="fas fa-comments"></i></a>
                            <a href="javascript:void(0);" class="book_btn"
                                title="Book A 15 Minuts Complementry Class"><i class="fas fa-handshake"></i></a>
                            <a href="javascript:void(0);" class="class_btn" title="Book Course"
                                (click)="onBookCourse(list)" style="z-index: 9999"><i class="fas fa-laptop"></i></a>
                            <!-- <a href="javascript:void(0);" class="fav_btn_on mark_un_fav" title="Added in Wishlist" *ngIf="list.favourite ==0"><i
                                    class="fas fa-heart"></i></a>
                            <a href="javascript:void(0);" class="fav_btn_on mark_fav" title="Added in Wishlist" *ngIf="list.favourite ==1"><i
                                    class="fas fa-heart"></i></a> -->
                            <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:void(0);" title=""
                                class="port_pop1">
                                <div class="div" style="height: 290px;" routerLink="/course-details/{{list.id}}">
                                    <img [src]="allowurl(apiService.imageBase+list.image)" alt="" class="img-fluid"
                                        onError="this.src='assets/images/profile-demo.png'"
                                        style="width: 100%;height: 100%;">
                                </div>

                            </a>
                            <div class="p-3 rel">
                                <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                        class="fas fa-star"></i><i class="fas fa-star"></i><i
                                        class="fas fa-star"></i><span class="letn"> 4.5 & up (232)</span></p>

                                <p class="b float-left"><a href="javascript:void(0);" title=""
                                        class="port_pop1">{{list.course_name}}</a></p>
                                <p class="b float-right">${{list.amount}}</p>
                                <p class="clearfix"></p>
                                <p class="fs12" [innerHTML]="list.description | slice:0:250"></p>
                                <p class="chkbox"><input type="checkbox"
                                        (click)="addCourseToCompare($event.target,list.id)"></p>
                                <p class="tbot">Level : <span>{{list.age_group_title}}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>