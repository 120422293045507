import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cancel-class-tutor',
  templateUrl: './cancel-class-tutor.component.html',
  styleUrls: ['./cancel-class-tutor.component.scss'],
  providers:[DatePipe],
})
export class BookClassCancelTutorComponent implements OnInit {
  addForm: FormGroup | any;public submitted = false;
  localData:any;
  Book_Class_Data:any;
  constructor(private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService,public activatedRoute: ActivatedRoute,private datePipe: DatePipe) {
    var jsonData: any = localStorage.getItem('user');
    this.localData = JSON.parse(jsonData);
  }
  get f() { return this.addForm.controls; }

  ngOnInit(): void {
    this.commonService.GetBookClassCancelTutorDataModal().subscribe(data =>{
      console.log('Class>>>>>',data);
      this.Book_Class_Data=data;
    })
    this.addForm = this.fb.group({
      reason : new FormControl('', [Validators.required]),
    });
  }
  onClosePopup() {
    this.commonService.SetBookClassCancelTutorModal(false);
  }
  onSubmit() {
    this.submitted=true;
    console.log('this.addForm.value>>>>>>>>>>>>>>',this.addForm.value);
    
    if (this.addForm.invalid) {
      this.messageService.showWarning("Field's are required");
      return
    } else {
      var sendRequestData: any = this.addForm.value;
      sendRequestData.sid = localStorage.getItem('sid');
      sendRequestData.booked_class_id  = this.Book_Class_Data.booked_class_id;
      sendRequestData.slot_id   = this.Book_Class_Data.slot_id;
      sendRequestData.teacher_id   = this.Book_Class_Data.teacher_id;
      sendRequestData.schedule_date   = this.datePipe.transform(this.Book_Class_Data.schedule_slot_date, 'yyyy-MM-dd');;
      sendRequestData.time_from   = this.Book_Class_Data.time_from;
      sendRequestData.student_id   = this.Book_Class_Data.student_id;
      sendRequestData.classId   = this.Book_Class_Data.classId;
      console.log('sendRequestData>>>>>>',sendRequestData);
      this.apiService.post('generate_jwt', sendRequestData).subscribe((resp: any) => {

              console.log(resp.access_token, 'generate_jwt');
              
           
              sendRequestData.access_token = resp.access_token;
     
      this.apiService.post('cancle_slot_booking', sendRequestData).subscribe((data: any) => {
        if (data.replyCode == "success") {
          this.addForm.reset();
          this.submitted=false;
          this.onClosePopup();
          this.commonService.SetUpdateProfileChanged(true);
          this.messageService.showSuccess(data.replyMsg);
        } else {
          this.messageService.showError(data.replyMsg);
        }
      })
      })
    }
  }
}
