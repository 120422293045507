
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService, CommonService } from 'src/app/services';
import { ApiService } from './../../services/api.service';
import { PasswordMatchPattern } from 'src/app/services/validation/password-match-pattern';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  createuser: FormGroup | any; public submitted = false;
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);

  }
  get f() { return this.createuser.controls; }

  ngOnInit(): void {
    this.createuser = this.fb.group({
      password : [null, [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
      current_password : [null, [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
      reenterpassword: ['', Validators.required],
    }, {
      validator: PasswordMatchPattern('password', 'reenterpassword')
    });
    // this.getTutorList(this.bodyRequestData);
  }

  allowurl(url:any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  onChangePassword(){
    this.submitted=true;
    console.log('this.createuser>>>>>>>',this.createuser.value)
    if(this.createuser.invalid){
      return
    }else{
      console.log('this.createuser>>>>>>>',this.createuser.value)
      var sendrequestinput:any=this.createuser.value;
      sendrequestinput.sid=localStorage.getItem('sid');
      this.apiService.post('change_password',sendrequestinput).subscribe((data:any) => {
        if(data.replyCode == "success"){
          this.createuser.reset();
          this.submitted=false;
          this.messageService.showSuccess(data.replyMsg);
        }else{
          this.messageService.showError(data.replyMsg);
        }
      })
    }
  }
}
