<router-outlet>
</router-outlet>
<app-login *ngIf="isLoginPopup"></app-login>
<app-register *ngIf="isRegisterPopup"></app-register>
<app-add-friend *ngIf="isAddFriendPopup"></app-add-friend>
<app-forgot *ngIf="isForgotPopup"></app-forgot>
<app-location *ngIf="isLocationPopup"></app-location>
<app-logout *ngIf="isLogoutPopup"></app-logout>
<app-edit-group-detail *ngIf="iseditchatGroup"></app-edit-group-detail>
<app-payments *ngIf="paymentsPopup"></app-payments>
<app-add-subject *ngIf="isAddSubject"></app-add-subject>
<app-book-a-class *ngIf="isBookClassPopup"></app-book-a-class>
<app-cancel-class-tutor *ngIf="isBookClassCancelPopup"></app-cancel-class-tutor>
<app-updateprofile *ngIf="isupdateprofile"></app-updateprofile>
<app-reschedule-class *ngIf="isBookClassCancelPopup1"></app-reschedule-class>
<app-add-quiz *ngIf="isAddQuiz"></app-add-quiz>
<app-add-project *ngIf="isAddProject"></app-add-project>
<app-add-child *ngIf="isAddParentChild"></app-add-child>
<app-tutor-view *ngIf="isTutorView"></app-tutor-view>
<app-chat-group *ngIf="isChatGroup">sdfsdfsdfsd
    <script>
        var chat_appid = '58790';
        var chat_auth = '4522b50f2994c20082d7c70b467c918a';
        </script>
    <script type="text/javascript">
        
        var chat_height = '600px';
        var chat_width = '990px';
        sdsd
        document.write('<div id="cometchat_embed_synergy_container" style="width:'+chat_width+';height:'+chat_height+';max-width:100%;border:1px solid #CCCCCC;border-radius:5px;overflow:hidden;"></div>');
        
        var chat_js = document.createElement('script'); chat_js.type = 'text/javascript'; chat_js.src = 'https://fast.cometondemand.net/'+chat_appid+'x_xchatx_xcorex_xembedcode.js';
        
        chat_js.onload = function() {
            var chat_iframe = {};chat_iframe.module="synergy";chat_iframe.style="min-height:"+chat_height+";min-width:"+chat_width+";";chat_iframe.width=chat_width.replace('px','');chat_iframe.height=chat_height.replace('px','');chat_iframe.src='https://'+chat_appid+'.cometondemand.net/cometchat_embedded.php'; if(typeof(addEmbedIframe)=="function"){addEmbedIframe(chat_iframe);}
        }
        
        var chat_script = document.getElementsByTagName('script')[0]; chat_script.parentNode.insertBefore(chat_js, chat_script);
    </script>
</app-chat-group>
<app-chat-group-user *ngIf="isChatGroupUser"></app-chat-group-user>