import { ApiService } from '../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';

@Component({
  selector: 'app-my-class',
  templateUrl: './my-class.component.html',
  styleUrls: ['./my-class.component.scss']
})
export class MyClassComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any=[];
  
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  bodyRequestData:any={};
  tutorList:any=[];
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
  }

  ngOnInit(): void {
    this.getLevelList();
    this.bodyRequestData.keyword='';
    this.bodyRequestData.job_type='';
    this.bodyRequestData.age_group_id='';
    this.bodyRequestData.country_id='';
    this.bodyRequestData.rating='';
    this.getTutorList(this.bodyRequestData);
  }
  getTutorList(bodyRequestData:any){
    this.apiService.post('teachers_list',this.bodyRequestData).subscribe((data:any) => {
      this.tutorList=data.data;
    })
  }
  LevelList:any=[];
  getLevelList(){
    var sendrequestinput:any={id:1};
    this.apiService.post('age_group_list_dropdown',sendrequestinput).subscribe((data:any) => {
      this.LevelList=data.data;
    })
  }
  onSearchKey(event:any){
    this.bodyRequestData.keyword=event.value;
    this.getTutorList(this.bodyRequestData)
  }
  onChangeLevel(event:any){
    this.bodyRequestData.age_group_id=event.value;
    this.getTutorList(this.bodyRequestData)
  }
  onChangeMethod(event:any){
    this.bodyRequestData.job_type=event.value;
    this.getTutorList(this.bodyRequestData)
  }

  allowurl(url:any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  arrayUser:any=[];
  getCompare(){
    var localData = JSON.stringify(this.arrayUser)
    localStorage.setItem('compareData',localData);
    console.log('this.arrayUser>>>>>>>>>>>>',this.arrayUser);
    this.router.navigate(['/student-compare-tutor']);
  }
  addUserToCompare(event:any,id:any){
    var value=event.checked;
    if(value){
      this.arrayUser.push(id)
    }else{
      var removeskilsIndex = this.arrayUser.map(function (item:any) { return item; }).indexOf(id);
      this.arrayUser.splice(removeskilsIndex, 1);
    }
  } 
  flag1:boolean=false; 
  onFlag1(){
    this.flag1=!this.flag1;
  }
}
