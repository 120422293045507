<div class="mb-3 contT">
    <div class="container pl-3">
        
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide">Welcome To </span>Our Chat</h1>
    </div>

</div>
<section class="animated3 wow drop_eff5 animated mb-5" style="visibility: visible;">
    <div class="container">
        <div class="row">
            <div class="col-md-5 col-lg-3 col-sm-12 col-xs-12">
                <div class="chatbax-right-bar">
                    <div class="">
                        <div class="link_here mt-4">
                            <span>
                                <a href="javascript:void(0);" class="style1 bg" (click)="backClicked()">Back</a>
                            </span>
                            <div class="dropdown">
                                <input id="dropcheck" class="dropcheck" type="checkbox">
                                <label for="dropcheck" class="dropbtn"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></label>                                
                                <div class="dropdown-content">
                                  <a href="javascript:void(0);" (click)="onAdduserfriend()">Add Friend</a>
                                  <a href="javascript:void(0);" (click)="onOpenGroup()">Create Group</a>
                                  <a href="javascript:void(0);" (click)="Setupdateprofile()">Update profiile</a>
                                  <a href="javascript:void(0);">Change status</a>
                                </div>
                              </div>
                         
                        </div>

                        <div  class="chat-search-box1 mt-3">
                            <input type="text" class="form-controls" placeholder="Search.."
                                (keyup)="onSearchKey($event.target)">
                        </div>
                        <div class="chat-list-box">
                            <ul>                               
                                <li *ngFor="let chat_group of ChatGroupList">

                                    <a  href="javascript:void(0);" class="chat-item"
                                        [ngClass]="ChatGroupId == chat_group.uid ? 'active':''"
                                        (click)="getChatGroupDetails(chat_group)">
                                        <div class="chat-item-pic">
                                            <img [src]="allowurl(apiService.imageBase+chat_group.group_icon)"
                                                onError="this.src='assets/images/profile-demo.png'">
                                        </div>
                                        <div class="chat-item-info-blk">
                                            <div class="chat-item-info">
                                                <h4>{{chat_group.name}}</h4>
                                            
                                            </div>
                                            <div class="chat-item-dtls">
                                                <a class="btn btn-secondary dropdown-toggle" style="
                                                padding:0;" href="#" role="button" id="ellipsisMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                  <span><i class="bi bi-three-dots-vertical"></i>
                                                  </span>
                                                </a>
                                                
                                                <ul class="dropdown-menu" aria-labelledby="ellipsisMenu">
                                                  <li><a class="dropdown-item" (click)="kickUsersFromGroup(chat_group)" href="javascript:void(0);">Exit Group</a></li>
                                                  <li><a class="dropdown-item" (click)="deleteGroup(chat_group)" href="javascript:void(0);">Delete Group</a></li>
                                                  <li><a class="dropdown-item" (click)="unblockUser(chat_group)" href="javascript:void(0);">Unblock User</a></li>
                                                </ul>
                                              </div>
                                        </div>
                                    </a>
                                </li>
                                <li *ngFor="let chat_group of ChatfriendList">

                                    <a  href="javascript:void(0);" class="chat-item"
                                        [ngClass]="ChatGroupId == chat_group.uid ? 'active':''"
                                        (click)="getChatGroupDetails(chat_group)">
                                        <div class="chat-item-pic">
                                            <img [src]="allowurl(chat_group?.avatar)"
                                                onError="this.src='assets/images/profile-demo.png'">
                                        </div>
                                        <div class="chat-item-info-blk">
                                            <div class="chat-item-info">
                                                <h4>{{chat_group.name}}</h4>                                            
                                            </div>
                                            <div class="chat-item-dtls">
                                                <a class="btn btn-secondary dropdown-toggle" style="
                                                padding:0;" href="#" role="button" id="ellipsisMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                  <span><i class="bi bi-three-dots-vertical"></i>
                                                  </span>
                                                </a>                                                
                                                <ul class="dropdown-menu" aria-labelledby="ellipsisMenu">
                                                  <li><a class="dropdown-item" (click)="deleteFriends(chat_group)" href="javascript:void(0);">Delete User</a></li>
                                                  <li><a class="dropdown-item" (click)="blockUser(chat_group)" href="javascript:void(0);">Block User</a></li>
                                                  <li><a class="dropdown-item" (click)="unblockUser(chat_group)" href="javascript:void(0);">Unblock User</a></li>
                                                </ul>
                                              </div>
                                        </div>
                                    </a>
                                </li>
                                
                           
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7 col-lg-9 col-sm-12 col-xs-12">
                <div class="chatbax-right-bar mt-3">
                    <div class="chatrightsection">
                        <div class="chat-header">
                            <h1 style="color: #fff;">{{username?.name }}</h1>
                        
                            <p *ngIf="grp==0">
                                <span>{{userstatus?.status}}<span>&nbsp;</span>{{userstatus?.lastactivity}}</span>                                
                            </p>
                            <div *ngIf="grp==1">
                                <a href="javascript:void(0);" (click)="onEditchatGroup(username)">Edit Group setting</a>    
                                </div>
                            </div>

                        <div *ngIf="grp==1" class="chat-history">

                            <ul *ngIf="ChatGroupDetails !='No chats available'">
                                <li *ngFor="let chat_user_data of ChatGroupDetails">
                                    <div class="chat-history-box left-box-chat"
                                        *ngIf="localData.id != chat_user_data.sender_uid">
                                        <div class="chat-history-box-right" data-id="{{chat_user_data.sender_uid}}">
                                            <h6>{{this.groupList[chat_user_data.sender_uid]?.username}}</h6> 

                                            <figure><img [src]="allowurl(this.groupList[chat_user_data.sender_uid]?.avatar)"
                                                    onError="this.src='assets/images/profile-demo.png'" alt="avatar">
                                            </figure>
                                            <div class="message-data">
                                                <div class="message my-message">
                                                    {{chat_user_data?.message}}
                                                </div>
                                                <span class="message-data-time">{{chat_user_data.timestamp*1000   | date :'MMM dd hh:mm'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chat-history-box right-box-chat"
                                        *ngIf="localData.id == chat_user_data.sender_uid">
                                        
                                        <div class="chat-history-box-left">
                                            <div class="message-data">
                                                <div class="message my-message">
                                                    {{chat_user_data?.message}}
                                                </div>
                                                <span class="message-data-time">{{chat_user_data.timestamp*1000   | date :'MMM dd hh:mm'}}
                                                   </span>
                                            </div>
                                            <h6>{{this.groupList[chat_user_data.sender_uid]?.username}}</h6> 

                                            <figure><img [src]="allowurl(this.groupList[chat_user_data.sender_uid]?.avatar)"
                                                onError="this.src='assets/images/profile-demo.png'" alt="avatar">
                                            </figure>
                                        </div>
                                    </div>
                                </li>

                         
                            </ul>
                            <ul  *ngIf="ChatGroupDetails =='No chats available'">
                                <div class="chat-history-box left-box-chat"></div>
                                <div class="chat-history-box right-box-chat">
                                <div class="chat-history-box-left">
                                    <div class="message-data">                                      
                                        <span class="message-data-time"></span>
                                    </div>
                                   
                                </div>
                            </div>
                            </ul>
                        </div>
                        <div *ngIf="grp==0" class="chat-history">

                            <ul *ngIf="ChatGroupDetails !='No chats available'">
                                <li *ngFor="let chat_user_data of ChatGroupDetails">
                                    <div class="chat-history-box left-box-chat"
                                        *ngIf="localData.id != chat_user_data.sender_uid">
                                        <div class="chat-history-box-right" data-id="{{chat_user_data.reciever_uid}}">
                                            <h6>{{this.userProfilesArray[chat_user_data.sender_uid]?.name}}</h6> 

                                            <figure><img [src]="allowurl(this.userProfilesArray[chat_user_data.sender_uid]?.avatar)"
                                                    onError="this.src='assets/images/profile-demo.png'" alt="avatar">
                                            </figure>
                                            <div class="message-data">
                                                <div class="message my-message">
                                                    {{chat_user_data?.message}}
                                                </div>
                                                <span class="message-data-time">{{chat_user_data.timestamp*1000   | date :'MMM dd hh:mm'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chat-history-box right-box-chat"
                                        *ngIf="localData.id == chat_user_data.sender_uid">
                                        
                                        <div class="chat-history-box-left">
                                            <div class="message-data">
                                                <div class="message my-message">
                                                    {{chat_user_data?.message}}
                                                </div>
                                                <span class="message-data-time">{{chat_user_data.timestamp*1000   | date :'MMM dd hh:mm'}}
                                                   </span>
                                            </div>
                                            <h6>{{user.name}}</h6> 

                                            <figure><img [src]="allowurl(user.avatar)"
                                                onError="this.src='assets/images/profile-demo.png'" alt="avatar">
                                            </figure>
                                        </div>
                                    </div>
                                </li>

                         
                            </ul>
                            <ul  *ngIf="ChatGroupDetails =='No chats available'">
                                <div class="chat-history-box left-box-chat"></div>
                                <div class="chat-history-box right-box-chat">
                                <div class="chat-history-box-left">
                                    <div class="message-data">                                      
                                        <span class="message-data-time"></span>
                                    </div>
                                   
                                </div>
                            </div>
                            </ul>
                        </div>
                        <div *ngIf="active_input==1" class="chat-message-box">
                            <input type="text" class="form-control" placeholder="Text here..." [(ngModel)]="Message"
                                (keydown)="keyDownFunction($event)">
                                
                            <button class="btn send-btn" (click)="sendMessage()"><i
                                    class="fas fa-paper-plane"></i></button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>





<!-- <section *ngIf="role_id == 3" class="animated3 wow drop_eff5 animated" style="visibility: visible;">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                <div class="chatbax-right-bar">
                    <div class="">
                        <div class="link_here mt-4">
                           <span><a href="javascript:void(0);" class="link_here_btn" (click)="backClicked()" style="margin-right:37px;
                            background-color:#e20a09;">Back</a></span>
                            <span><a class="link_here_btn" (click)="onOpenGroup()">Create New<i
                                class="far fa-comment-plus"></i></a></span>
                        </div>
                        <div class="chat-search-box1 mt-3">
                            <input type="text" class="form-controls" placeholder="Search.."
                                (keyup)="onSearchKey($event.target)">
                           
                        </div>

                        <div class="chat-list-box">
                            <ul>
                         

                                <li
                                    *ngFor="let chat_group of ChatGroupList">

                                    <a if="chat_group.student_name!=0 && chat_group.group_name!=0"
                                        href="javascript:void(0);" class="chat-item"
                                        [ngClass]="ChatGroupId == chat_group.id ? 'active':''"
                                        (click)="getChatGroupDetails(chat_group.id)">
                                        <div class="chat-item-pic">
                                            <img [src]="allowurl(apiService.imageBase+chat_group.group_icon)"
                                                onError="this.src='assets/images/profile-demo.png'">
                                        </div>
                                        <div class="chat-item-info-blk">
                                            <div class="chat-item-info">
                                                <h4>{{chat_group?.student_name || chat_group.group_name}}</h4>

                                            </div>
                                            <div class="chat-item-dtls">
                                                <a style="    color: black;
                                                padding-left: 128px;
                                            " (click)="onEditGroup(chat_group)"><i class="fas fa-edit"></i></a>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                         
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                <div class="chatbax-right-bar">
                    <div class="">
                        <div class="chat-header">
                            <h1 style="color: #fff;" *ngIf="ChatGroupDetails">
                                {{ChatGroupDetails[0]?.student_name ||
                                ChatGroupDetails[0]?.group_name}}&nbsp;&nbsp;<a href="javascript:void(0);"
                                    (click)="onAddGroupuser();"><i class="fas fa-user-plus"></i></a></h1>
                            <p>
                                <span
                                    *ngFor="let chat_user of ChatGroupUserDetails; let i = index">{{chat_user.name}}<span
                                        *ngIf="i!=ChatGroupUserDetails.length-1">,&nbsp;</span></span>
                            </p>
                        </div>

                        <div class="chat-history">

                            <ul>
                                <li *ngFor="let chat_user_data of ChatGroupDetails[0]?.chat; let i = index">
                                    <div class="chat-history-box left-box-chat"
                                        *ngIf="localData.id != chat_user_data.user_id">
                                        <div class="chat-history-box-right">
                                            <h6>{{this.groupList[chat_user_data.sender_uid]?.username}}</h6> 
                                            <figure><img [src]="allowurl(apiService.imageBase+chat_user_data.image)"
                                                    onError="this.src='assets/images/profile-demo.png'" alt="avatar">
                                            </figure>
                                            <div class="message-data">
                                                <div class="message my-message">
                                                    {{chat_user_data?.message}}
                                                </div>
                                                <span class="message-data-time">{{chat_user_data.created | date :
                                                    'MMM dd hh:mm'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chat-history-box right-box-chat"
                                        *ngIf="localData.id == chat_user_data.user_id">
                                        <div class="chat-history-box-left">
                                            <div class="message-data">
                                                <div class="message my-message">
                                                    {{chat_user_data?.message}}
                                                    <i *ngIf="chat_user_data.read_status ==1" class="fas fa-check"
                                                        style="color: #34e411;"></i>
                                                    <i *ngIf="chat_user_data.read_status ==0" class="fas fa-check"
                                                        style="color: #e0ecde;"></i>
                                                </div>
                                                <span class="message-data-time">{{chat_user_data.created | date :
                                                    'MMM dd hh:mm'}}</span>
                                            </div>
                                            <figure><img [src]="allowurl(apiService.imageBase+chat_user_data.image)"
                                                    onError="this.src='assets/images/profile-demo.png'" alt="avatar">
                                            </figure>
                                        </div>
                                    </div>
                                </li>


                            </ul>
                        </div>
                        <div class="chat-message-box">
                            <input type="text" class="form-control" placeholder="Text here..." [(ngModel)]="Message"
                                (keydown)="keyDownFunction($event)">
                            <button class="btn send-btn" (click)="sendMessage()"><i
                                    class="fas fa-paper-plane"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
</section> -->