<div class="mb-3 ptfix">
    <div class="container pt-4 pl-3">
        <ul class="breadcrumb">
            <li><a routerLink="/">Home</a></li>
            <li><a href="javascript:void(0)">Hot Products</a></li>
            <li class="active">Listing</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide">Hot Product </span>Listing</h1> 
        <!-- <p _ngcontent-qeb-c166="" class="fs13 mt-4 pr-2">{{catData?.description}}</p>            -->
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->


<!--listing-->
<div class="container mt-5 mb-5">
    <div class="row">
        <div class="col-md-3"  *ngFor="let pro of resData; let i= index">
            <div class="list_box">
                <a href="/product-details/{{pro.id}}">
                    <img [src]="allowurl(apiService.imageBase+pro.image)" alt="{{pro.title}}" class="img-fluid"/>
                    {{pro.title}}
                </a>
            </div>
        </div>
    </div>
</div>