<div class="top=main-section contT">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-6 col-sm-12">
        <ul class="breadcrumb">
          <li><a href="/">Home</a></li>
          <li class="active">Mitt Konto</li>
        </ul>
        <h1><span class="txt_gray mob_hide"></span>{{ Content_Type ? Content_Type : "" }}</h1>
      </div>
      <div class="col-lg-6 col-sm-12" style="margin: auto;">
        <p class="ext-button" *ngIf="Content_Type == 'Översiktspanel'">
          <a href="javascript:void(0);" routerLink="/tutor-details/{{ list }}" class="style1">
            SE DIN PROFIL</a>
        </p>
        <p class="ext-button" *ngIf="Content_Type == 'LÄGG  Kurs'">
          <a href="javascript:void(0);" (click)="onAddCourse()" class="style1">
            <i class="fas fa-plus"></i> LÄGG Kurs</a>
        </p>
        <p class="ext-button" *ngIf="Content_Type == 'LÄGG TILL FRÅGA'">
          <a href="javascript:void(0);" (click)="onAddQuestion()" class="style1">
            <i class="fas fa-plus"></i> LÄGG TILL FRÅGA</a>
        </p>
      </div>
    </div>
  </div>
</div>
<div class="clearfix"></div>



<section class="animated3 wow drop_eff5 mb-5" *ngIf="userData">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-3 col-md-4 col-sm-12 wow fadeInLeft">
        <div class="lft_link1">
          <ul class="nav nav-pills mb-3 d-block" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-Dashboard-tab" data-toggle="pill" href="#pills-Dashboard" role="tab"
                aria-controls="pills-Dashboard" aria-selected="false" (click)="onChangeTypeContent('Översiktspanel')"><i
                  class="fa fa-dashboard"></i>Översiktspanel</a>
            </li>

            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="pills-tab1-tab" data-toggle="pill" href="#pills-tab1" role="tab"
                aria-controls="pills-tab1" aria-selected="true"
                (click)="onChangeTypeContent('Redigera och Uppdatera Profil')"><i class="fa fa-pencil"></i>Min
                Profil</a>
            </li>

            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-tab2-tab" data-toggle="pill" href="#pills-tab2" role="tab"
                aria-controls="pills-tab2" aria-selected="false"
                (click)="onChangeTypeContent('Uppdatera konto Detaljer')"><i class="fas fa-edit"></i>Konto</a>
            </li>

            <!-- <li class="nav-item" role="presentation">
              <a class="nav-link" href="/front/chat" target="_blank"><i class="fas fa-comments"></i>Chat With
                Students</a>
            </li> -->

            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-tab6-tab" data-toggle="pill" href="#pills-tab6" role="tab"
                aria-controls="pills-tab6" aria-selected="false" (click)="onChangeTypeContent('Mina Intäkter')"><i
                  class="fas fa-money-bill-alt"></i>Mina Intäkter</a>
            </li>

            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-tab222-tab" data-toggle="pill" href="#pills-tab222" role="tab"
                aria-controls="pills-tab222" aria-selected="false" (click)="onChangeTypeContent('Mina Klasser')"><i
                  class="fab fa-adversal"></i>Mina Klasser</a>
            </li>

            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-tab10-tab" data-toggle="pill" href="#pills-tab10" role="tab"
                aria-controls="pills-tab10" aria-selected="false" (click)="onChangeTypeContent('Quizpapper')"><i
                  class="far fa-file"></i>Quizpapper</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="MonitorProgress-tab" data-toggle="pill" href="#MonitorProgress" role="tab"
                aria-controls="MonitorProgress" aria-selected="false" (click)="onChangeTypeContent('Läxor')"><i
                  class="fa fa-list"></i>Övervaka Framsteg</a>
            </li>

            <li class="nav-item" role="presentation">
              <a class="nav-link" id="Recensioner-tab" data-toggle="pill" href="#Recensioner" role="tab"
                aria-controls="Recensioner" aria-selected="false" (click)="onChangeTypeContent('Recensioner')"><i
                  class="fas fa-star"></i>Recensioner</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="demo_class-tab" data-toggle="pill" href="#demo_class" role="tab"
                aria-controls="demo_class" aria-selected="false" (click)="onChangeTypeContent('Klassrumsdemo')"><i
                  class="fa fa-solid fa-graduation-cap"></i>Klassrumsdemo</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-tab4-tab" href="javascript:void();" routerLink="/contact-us"><img
                  _ngcontent-nwn-c175="" alt="" onerror="this.onerror=null;this.src='assets/images/profile-demo.png';"
                  style="width: 18px; opacity: 0.5"
                  src="https://suited-admin.mrmmbs.com/node/uploads/attachment-1674819240238.png" />
                Support</a>
            </li>
            <!-- <li class="nav-item custam-code-st1" role="presentation">
              <a class="nav-link"> <img _ngcontent-nwn-c175="" alt=""
                  onerror="this.onerror=null;this.src='assets/images/profile-demo.png';"
                  style="width: 18px; opacity: 0.5" src="../../../../assets/images/comment.png" /> Give Feedback</a>

            </li> -->
            <a href="javascript:void(0);" (click)="onOpenLogout()"><i class="fas fa-sign-out-alt"></i>Logga ut</a>
          </ul>
        </div>
      </div>

      <div class="col-lg-9 col-md-8 col-sm-12 wow fadeInLeft">
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-tab1" role="tabpanel" aria-labelledby="pills-tab1-tab">
            <div class="list_box1">
              <div class="tab_link">
                <p class="for_m dd_next hand">
                  Redigera Profil <i class="fas fa-caret-down"></i>
                </p>
                <div class="for_d">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                        aria-controls="pills-home" aria-selected="true">Personuppgifter</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                        aria-controls="pills-profile" aria-selected="false">Kvalifikationer</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab"
                        aria-controls="pills-contact" aria-selected="false">Tidszon och Land</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-billing-tab" data-toggle="pill" href="#pills-billing" role="tab"
                        aria-controls="pills-billing" aria-selected="false">Fakturaadress</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-rate-tab" data-toggle="pill" href="#pills-rate" role="tab"
                        aria-controls="pills-rate" aria-selected="false">Erfarenhet & Timpris</a>
                    </li>
                    <li class="nav-item" role="presentation2">
                      <a class="nav-link" id="pills-contact2-tab" data-toggle="pill" href="#pills-contact2" role="tab"
                        aria-controls="pills-contact2" aria-selected="false">Ändra Lösenord</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab_cont tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <form [formGroup]="createuser6">
                    <div class="emp_areas">
                      <div class="loc_feild">
                        <p>Förnamn <span class="red">*</span></p>
                        <div>
                          <input placeholder="Vänligen skriv ditt förnamn" type="text"
                            (keyup)="onSearchKey2($event.target,'name')" [(ngModel)]="userData.name"
                            [ngModelOptions]="{ standalone: true }" class="form-control" required />
                          <span class="warning">{{ namefield }} </span>
                        </div>
                      </div>
                      <div class="loc_feild">
                        <p>Efternamn <span class="red">*</span></p>
                        <p>
                          <input placeholder="Vänligen skriv ditt efternamn" type="text"
                            (keyup)="onSearchKey2($event.target,'last_name')" class="form-control"
                            [(ngModel)]="userData.last_name" [ngModelOptions]="{ standalone: true }" />
                          <span class="warning">{{ lastname }} </span>
                        </p>
                      </div>
                      <div class="loc_feild">
                        <p>Födelsedatum </p>
                        <p>
                          <input type="date" [(ngModel)]="userData.dob" class="form-control"
                            (keyup)="onSearchKey2($event.target,'dob')" [ngModelOptions]="{ standalone: true }" />
                          <span class="warning">{{ dob }} </span>
                        </p>
                      </div>
                      <div class="loc_feild">
                        <p>E-post <span class="red">*</span></p>
                        <p>
                          <input placeholder="Vänligen ange din e-post" type="email"
                            (keyup)="onSearchKey2($event.target,'email')" class="form-control"
                            [(ngModel)]="userData.email" [ngModelOptions]="{ standalone: true }" />
                          <span class="warning">{{ email }} </span>
                        </p>
                      </div>

                      <div class="loc_feild">
                        <div class="Country-wid">
                          <div class="loc_feild">
                            <p>mobilnummer <span class="red">*</span></p>
                            <div>
                              <input placeholder="Vänligen ange ditt mobilnummer" class="form-control" type="tel"
                                (keyup)="onSearchKey3($event.target.value,'phone')" [(ngModel)]="userData.phone"
                                [ngModelOptions]="{ standalone: true }" />
                              <span class="warning">{{ phone }} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="loc_feild" style="display: none;">
                        <p>Om Mig</p>
                        <p>
                          <textarea placeholder="Dela gärna lite om dig själv och dina stora drömmar" name="" id=""
                            cols="30" rows="5" [(ngModel)]="userData.bio"
                            [ngModelOptions]="{ standalone: true }"></textarea>
                        </p>
                      </div>
                      <div class="clearfix"></div>
                      <div class="loc_feild">
                        <div class="row">
                          <div class="col-md-12 p-0 mt-3">
                            <p class="">
                              <a href="javascript:void(0);" class="style1 bg" (click)="onUpdateProfile()">SPARA &
                                UPPDATERA</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <form [formGroup]="createuser4">
                    <div class="emp_areas">
                      <div class="name_feild">
                        <p class="">Skolans namn</p>
                        <p>
                          <input type="text" formControlName="university" placeholder="" class="form-control" />
                        </p>
                      </div>
                      <div class="loc_feild">
                        <p class="">Kurs/Program</p>
                        <p>
                          <input type="text" formControlName="degree" placeholder="" class="form-control" />
                        </p>
                      </div>
                      <div class="loc_feild">
                        <p class="">År</p>
                        <p>
                          <input type="text" formControlName="completion_year"
                            placeholder="E.g September 2020 to June 2023" class="form-control" />
                        </p>
                      </div>
                      <div class="loc_feild">
                        <div class="row">
                          <div class="col-md-12 p-0">
                            <p class="add_cart">
                              <a href="javascript:void(0);" (click)="onSubmitQualification()">SPARA & UPPDATERA</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="userData">
                      <div class="rel" *ngIf="this.userData.educations?.length > 0">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th>Skolans namn</th>
                              <th>Kurs/Program</th>
                              <th>År</th>
                              <th>Åtgärd</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let eduData of this.userData.educations">
                              <td>{{ eduData?.university }}</td>
                              <td>{{ eduData?.degree }}</td>
                              <td>{{ eduData?.completion_year }}</td>
                              <td class="d-flex">
                                <!-- <a><i class="fas fa-edit"
                                    (click)="updateeducationsdetail(eduData)"></i></a> -->
                                &nbsp;&nbsp;
                                <a (click)="delete_teacher_educations(eduData.id)"><i class="fas fa-trash-alt"></i></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </ng-container>
                  </form>
                </div>

                <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <form [formGroup]="createuser3">
                    <div class="emp_areas">

                      <div class="loc_feild">
                        <p class="">Gatuadress</p>
                        <div>
                          <input type="text" formControlName="address" class="form-control"
                            placeholder="Vänligen skriv din gatuadress" [(ngModel)]="userData.address" [ngClass]="{
                              'is-invalid': submitted3 && f3.address.errors
                            }" />
                          <div *ngIf="
                              f3.address.invalid &&
                              (f3.address.dirty || f3.address.touched)
                            "></div>
                          <span class="warning">{{ address }}</span>
                        </div>
                      </div>

                      <div class="name_feild">
                        <p class="">Tidszon</p>
                        <div>
                          <select name="" id="" formControlName="time_zone" class="form-control">
                            <option value="" selected>Välj</option>
                            <option [value]="time" *ngFor="let time of TimeZone">
                              {{ time }}
                            </option>
                          </select>
                          <span class="warning">{{ time_zone }}</span>
                        </div>
                      </div>
                      <div class="loc_feild">
                        <p class="">Land</p>
                        <div>
                          <select name="" id="" formControlName="country" class="form-control"
                            [(ngModel)]="userData.country" [ngClass]="{
                              'is-invalid': submitted3 && f3.country.errors
                            }" (change)="onChangeCountry($event.target)">
                            <option value="" selected>Välj</option>
                            <option [value]="countryData.id" *ngFor="let countryData of loadcountryList">
                              {{ countryData.name }}
                            </option>
                          </select>
                          <div *ngIf="
                              f3.country.invalid &&
                              (f3.country.dirty || f3.country.touched)
                            "></div>
                          <span class="warning">{{ country }}</span>
                        </div>
                      </div>

                      <div class="loc_feild">
                        <p class="">Stat/Län </p>
                        <div>
                          <select name="" id="" formControlName="state" class="form-control"
                            [(ngModel)]="userData.state" [ngClass]="{
                              'is-invalid': submitted3 && f3.state.errors
                            }" (change)="onChangeState($event.target)">
                            <option value="" selected>Välj</option>
                            <option [value]="stateData.id" *ngFor="let stateData of loadstateList">
                              {{ stateData.name }}
                            </option>
                          </select>
                          <div *ngIf="
                              f3.state.invalid &&
                              (f3.state.dirty || f3.state.touched)
                            "></div>
                          <span class="warning">{{ state }}</span>
                        </div>
                      </div>
                      <div class="loc_feild">
                        <p class="">Stad</p>
                        <div>
                          <select name="" id="" formControlName="city" class="form-control" [(ngModel)]="userData.city"
                            [ngClass]="{
                              'is-invalid': submitted3 && f3.city.errors
                            }">
                            <option value="" selected>Välj</option>
                            <option [value]="cityData.id" *ngFor="let cityData of loadcityList">
                              {{ cityData.name }}
                            </option>
                          </select>
                          <div *ngIf="
                              f3.city.invalid &&
                              (f3.city.dirty || f3.city.touched)
                            "></div>
                          <span class="warning">{{ city }}</span>
                        </div>
                      </div>
                      <div class="loc_feild">
                        <p class="">Postnummer</p>
                        <div>
                          <input type="number" formControlName="pcode" class="form-control" [(ngModel)]="userData.pcode"
                            placeholder="Vänligen skriv  Postnummer " [ngClass]="{
                              'is-invalid': submitted3 && f3.pcode.errors
                            }" />
                          <div *ngIf="
                              f3.pcode.invalid &&
                              (f3.pcode.dirty || f3.pcode.touched)
                            "></div>
                          <span class="warning">{{ address }}</span>
                        </div>
                      </div>

                      <div class="loc_feild">
                        <div class="row">
                          <div class="col-md-12 p-0">
                            <p class="add_cart">
                              <a href="javascript:void(0);" (click)="onSubmitAddress()">SPARA & UPPDATERA</a>
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
                <div class="tab-pane fade" id="pills-billing" role="tabpanel" aria-labelledby="pills-billing-tab">
                  <form [formGroup]="createuserb">
                    <div class="emp_areas">
                      <div class="loc_feild">
                        <p class="">Förnamn</p>
                        <p>
                          <input placeholder="Vänligen skriv ditt förnamn" type="text" formControlName="b_name"
                            class="form-control" [ngClass]="{
                              'is-invalid': submittedb && fbi.b_name.errors
                            }" />
                        </p>
                      </div>
                      <div class="loc_feild">
                        <p class="">Efternamn</p>
                        <p>
                          <input placeholder="Vänligen skriv ditt efternamn" type="text" formControlName="b_last_name"
                            class="form-control" [ngClass]="{
                              'is-invalid': submittedb && fbi.b_last_name.errors
                            }" />
                        </p>
                      </div>
                      <div class="loc_feild">
                        <p class="">Gatuadress</p>
                        <p>
                          <input placeholder="Vänligen skriv din gatuadress" type="text"
                            formControlName="billing_address" class="form-control" [ngClass]="{
                              'is-invalid':
                                submittedb && fbi.billing_address.errors
                            }" />
                        </p>
                      </div>
                      <div class="loc_feild">
                        <p class="">Land</p>
                        <p>
                          <input placeholder="Vänligen skriv Land" type="text" formControlName="bcountry"
                            class="form-control" [ngClass]="{
                              'is-invalid': submittedb && fbi.bcountry.errors
                            }" />
                        </p>
                      </div>
                      <div class="loc_feild">
                        <p class="">Stat/Län</p>
                        <p>
                          <input placeholder="Vänligen skriv din stat eller län" type="text" formControlName="bstate"
                            class="form-control" [ngClass]="{
                              'is-invalid': submittedb && fbi.bstate.errors
                            }" />
                        </p>
                      </div>
                      <div class="loc_feild">
                        <p class="">Stad</p>
                        <p>
                          <input placeholder=" Vänligen skriv din stad" type="text" formControlName="bcity"
                            class="form-control" [ngClass]="{
                              'is-invalid': submittedb && fbi.bcity.errors
                            }" />
                        </p>
                      </div>
                      <div class="loc_feild">
                        <p class="">Postnummer</p>
                        <p>
                          <input placeholder="Vänligen skriv din Postnummer" type="text" formControlName="bpcode"
                            class="form-control" [ngClass]="{
                              'is-invalid': submittedb && fbi.bpcode.errors
                            }" />
                        </p>
                      </div>
                      <div class="loc_feild">
                        <div class="row">
                          <div class="col-md-12 p-0">
                            <p class="add_cart">
                              <a href="javascript:void(0);" (click)="onSubmitBillingAddress()">SPARA & UPPDATERA</a>
                            </p>
                          </div>
                        </div>
                      </div>
                      <!--<p class="p-3 b fs12 text-center"><a href="tutor-account-2.htm "><i class="fas fa-arrow-left"></i> Go Back</a></p>-->
                    </div>
                  </form>
                </div>
                <div class="tab-pane fade" id="pills-rate" role="tabpanel" aria-labelledby="pills-rate-tab">
                  <form [formGroup]="createuser14">
                    <div class="emp_areas">
                      <ng-container>
                        <div class="col-md-12">
                          <div class="loc_feild">
                            <p>Välj Nivå <span class="red">*</span></p>
                            <div>
                              <select name="" id="" class="form-control" formControlName="age_group_id"
                                [(ngModel)]="userData.age_group_id" [ngClass]="{
                                  'is-invalid':
                                    submitted14 && f14.age_group_id.errors
                                }">
                                <option [value]="lvl.id" *ngFor="let lvl of LevelList">
                                  {{ lvl.title }}
                                </option>
                              </select>
                              <div *ngIf="
                                  f14.age_group_id.invalid &&
                                  (f14.age_group_id.dirty ||
                                    f14.age_group_id.touched)
                                ">
                                <div class="warning" *ngIf="f14.age_group_id.errors.required">
                                  Detta fält är obligatoriskt
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="loc_feild">
                            <p>Välj Metod <span class="red">*</span></p>
                            <div>
                              <select name="" class="form-control" id="" formControlName="job_type"
                                [(ngModel)]="userData.job_type" [ngClass]="{
                                  'is-invalid':
                                    submitted14 && f14.job_type.errors
                                }">
                                <option value="1">Online</option>
                                <option value="2">På plats</option>
                              </select>
                              <div *ngIf="
                                  f14.job_type.invalid &&
                                  (f14.job_type.dirty || f14.job_type.touched)
                                ">
                                <div class="warning" *ngIf="f14.job_type.errors.required">
                                  Välj Any One Method.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="loc_feild">
                            <p>Timpris (i SEK)<span class="red">*</span></p>
                            <div>
                              <input type="text" class="form-control" disabled formControlName="rate"
                                [(ngModel)]="userData.rate" [ngClass]="{
                                  'is-invalid': submitted14 && f14.rate.errors
                                }" />
                              <div *ngIf="
                                  f14.rate.invalid &&
                                  (f14.rate.dirty || f14.rate.touched)
                                ">
                                <div class="warning" *ngIf="f14.rate.errors.required">
                                  Detta fält är obligatoriskt
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="loc_feild">
                            <p>Arbetslivserfarenhet<span class="red">*</span></p>
                            <div>
                              <input type="text" class="form-control" formControlName="work_experience"
                                [(ngModel)]="userData.work_experience" [ngClass]="{
                                  'is-invalid':
                                    submitted14 && f14.work_experience.errors
                                }" />
                              <div *ngIf="
                                  f14.work_experience.invalid &&
                                  (f14.work_experience.dirty ||
                                    f14.work_experience.touched)
                                ">
                                <div class="warning" *ngIf="f14.work_experience.errors.required">
                                  Detta fält är obligatoriskt.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <div class="loc_feild">
                        <div class="row">
                          <div class="col-md-12 p-0">
                            <p class="add_cart">
                              <a href="javascript:void(0);" (click)="update_rate()">Save</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="tab-pane fade" id="pills-contact2" role="tabpanel" aria-labelledby="pills-contact2-tab">
                  <form [formGroup]="createuser">
                    <div class="emp_area">
                      <div class="name_feild">
                        <p>Gammalt Lösenord</p>
                        <div>
                          <input type="text" formControlName="current_password" [ngClass]="{
                              'is-invalid':
                                submitteed && f.current_password.errors
                            }" class="form-control" />

                          <div *ngIf="
                              (f.current_password.errors &&
                                f.current_password.touched) ||
                              (f.current_password.errors && submitteed)
                            " class="errorMsg">
                            <div class="warning" *ngIf="f.current_password.errors.mustMatch">
                              Password do not match.
                            </div>
                          </div>
                          <div *ngIf="
                              f.current_password.invalid &&
                              (f.current_password.dirty ||
                                f.current_password.touched)
                            ">
                            <div class="warning" *ngIf="f.current_password.errors.required">
                              Gammalt lösenord krävs.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="loc_feild">
                        <p>Nytt Lösenord</p>
                        <div>
                          <input type="text" formControlName="password" [ngClass]="{
                              'is-invalid': submitteed && f.password.errors
                            }" class="form-control" />
                          <div *ngIf="
                              f.password.invalid &&
                              (f.password.dirty || f.password.touched)
                            ">
                            <div class="warning" *ngIf="f.password.errors.required">
                              New password is required.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="loc_feild">
                        <p>Bekräfta Nytt Lösenord</p>
                        <div>
                          <input type="text" formControlName="reenterpassword" [ngClass]="{
                              'is-invalid':
                                submitteed && f.reenterpassword.errors
                            }" class="form-control" />
                          <div *ngIf="
                              (f.reenterpassword.errors &&
                                f.reenterpassword.touched) ||
                              (f.reenterpassword.errors && submitteed)
                            " class="errorMsg">
                            <div class="warning" *ngIf="f.reenterpassword.errors.mustMatch">
                              The new password and confirmed password do not match
                            </div>
                          </div>
                          <div *ngIf="
                              f.reenterpassword.invalid &&
                              (f.reenterpassword.dirty ||
                                f.reenterpassword.touched)
                            ">
                            <div class="warning" *ngIf="f.reenterpassword.errors.required">
                              bekräfta Lösenord is krävs.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="loc_feild">
                        <div class="row">
                          <div class="col-md-12 p-0">
                            <p class="add_cart">
                              <a href="javascript:void(0);" (click)="onChangePassword()">SPARA & UPPDATERA</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="pills-Dashboard" role="tabpanel" aria-labelledby="pills-Dashboard-tab">
            <div class="list_box1">
              <div class="tab_link">
                <p class="for_m dd_next hand">
                  Redigera Profil <i class="fas fa-caret-down"></i>
                </p>
                <div class="for_d">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a class="nav-link active" id="Bio-home-tab" data-toggle="pill" href="#Bio-home" role="tab"
                        aria-controls="Bio-home" aria-selected="true">Bio</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-subprovider-tab" data-toggle="pill" href="#pills-subprovider"
                        role="tab" aria-controls="pills-subprovider" aria-selected="false">Tillhandahållna Ämnen</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-Availability-tab" data-toggle="pill" href="#pills-Availability"
                        role="tab" aria-controls="pills-Availability" aria-selected="false">Tillgänglighet</a>
                    </li>

                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-Teaching-tab" data-toggle="pill" href="#pills-Teaching" role="tab"
                        aria-controls="pills-Teaching" aria-selected="false">Undervisningspreferenser</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="tab_cont tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="Bio-home" role="tabpanel" aria-labelledby="Bio-home-tab">
                  <form [formGroup]="createuser6">
                    <div class="emp_area">

                      <div class="form-group">
                        <label for="">Om Dig</label>
                        <textarea name="" id="" rows="3" formControlName="bio" class="tutor-Edit-Profile" [ngClass]="{
                              'is-invalid': submitted6 && f6.bio.errors
                            }"></textarea>
                      </div>

                      <div class="form-group">
                        <label for="">Mina Kvalifikationer och Certifikat</label>
                        <textarea name="" id="" rows="3" formControlName="qualification_certifications"
                          class="tutor-Edit-Profile" [ngClass]="{
                              'is-invalid': submitted6 && f6.qualification_certifications.errors
                            }"></textarea>
                      </div>

                      <div class="form-group">
                        <label for="">Mina Framgångar och Undervisningsstrategi</label>
                        <textarea name="" id="" rows="3" formControlName="achievement" class="tutor-Edit-Profile"
                          [ngClass]="{
                              'is-invalid':
                                submitted6 &&
                                f6.achievement.errors
                            }"></textarea>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-3">
                          <label for="">Läraren Talar</label>
                          <input type="text" formControlName="languages" class="form-control" [ngClass]="{
                            'is-invalid': submitted6 && f6.languages.errors
                          }" />
                        </div>
                        <div class="col-md-6 mb-3">
                          <label for="">År av Erfarenhet</label>
                          <input type="number" formControlName="work_experience" class="form-control-design1" [ngClass]="{
                            'is-invalid':
                              submitted6 && f6.work_experience.errors
                          }" />
                        </div>
                      </div>
                      <!-- <div class="form-group">
                        <label for="">Completed Lessons</label>
                        <input disabled type="text" value="{{count_lession}}" formControlName="Completed_l"
                          class="form-control" [ngClass]="{
                          'is-invalid': submitted6 && f6.Completed_l.errors
                        }" />
                      </div> -->

                      <div class="row">
                        <div class="col-md-12 mt-3">
                          <p class="">
                            <a href="javascript:void(0);" (click)="onSubmitInfo()" class="style1 bg">SPARA &
                              UPPDATERA</a>
                          </p>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>

                <div class="tab-pane fade" id="pills-Availability" role="tabpanel"
                  aria-labelledby="pills-Availability-tab">
                  <div class="row mb-4">
                    <div class="col-md-6 col-lg-6 col-sm-6">
                      <div  class="">
                        <a href="" data-toggle="modal" data-target="#edit" class="compare-Add-new Availability">Lägg
                          till ny Tillgänglighet</a>
                      </div>
                      <!-- <div *ngIf="!(utcDate2<0)" class=""><a href="" data-toggle="modal" data-target="#edit"
                          class="compare-Add-new Availability">Add new Availabilitys</a></div> -->
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 bg-gray">
                      <div class="select_slot_captions">
                        <p class=""> Dig can Create Slot after {{ lastdate | date }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="section-title mb-3 mt-2 inline-block" *ngIf="SchedulerList">
                    <ng-container *ngIf="SchedulerList.length > 0">
                      <ng-container *ngFor="let SchedList of SchedulerList">
                        <ng-container *ngIf="SchedList.holiday == '1'">
                          <div class="row mt-3 my-2">
                            <div class="col-12">
                              <label class="switch">
                                <input type="checkbox" [checked]="SchedList.available == '1'"
                                  [disabled]="SchedList.holiday == '1'" />
                                <span class="slider round"></span> </label>&nbsp;<strong class="ml-2">{{
                                SchedList.schedule_date | date
                                }}</strong>
                              &nbsp;<strong class="ml-2">{{
                                SchedList.schedule_date | date : "EEEE"
                                }}</strong>
                            </div>
                            <div class="col-12 ml-2">
                              <p class="select_slot_caption">Välj Slot</p>
                              <div class="row">
                                <div class="col-4" *ngFor="let check1 of SchedList.slots">
                                  <label class="check">{{ check1.time_from.slice(0, 5) }}

                                    <input type="checkbox" name="is_name" [disabled]="SchedList.holiday == '1'" />
                                    <span class="checkmarks"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="SchedList.holiday == '0'">
                          <div class="slot-Availability">
                            <!-- <div class="select_slot_caption">
                                                                <label class="switch">
                                                                    <input type="checkbox"
                                                                        [checked]="SchedList.available == '1'"
                                                                        (click)="onChangeStatus(SchedList,$event.target)">
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </div> -->

                            <div class="slot-Availabilityd">
                              <div class="wick-date">
                                <strong class="ml-2">{{
                                  SchedList.schedule_date | date
                                  }}</strong>
                              </div>
                              <div class="wick">
                                <strong class="ml-2">{{
                                  SchedList.schedule_date | date : "EEEE"
                                  }}</strong>
                              </div>
                              <ul>
                                <li *ngFor="let check1 of SchedList.slots">
                                  <label class="check" [ngStyle]="{
                                      color:
                                        check1.available == '1' ||
                                        check1.class_id != null
                                          ? 'red'
                                          : 'green'
                                    }">{{ check1.time_from.slice(0, 5) }}
                                    <input type="checkbox" checked="checked" name="is_name" [checked]="
                                        check1.available == '1' ||
                                        check1.class_id != null
                                      " [disabled]="
                                        SchedList.available == '0' ||
                                        check1.class_id != null
                                      " (click)="
                                        onChangeSlotStatus(
                                          check1.id,
                                          $event.target
                                        )
                                      " disabled />
                                    <span class="checkmarks"></span>
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>

                <div class="tab-pane fade" id="pills-subprovider" role="tabpanel"
                  aria-labelledby="pills-subprovider-tab">
                  <div class="col-lg-12 col-md-12 col-sm-12 wow fadeInLeft">
                    <div class="">
                      <table class="table table-bordered tutor-class-ext">
                        <thead>
                          <tr *ngIf="userData.subjects?.length > 0">
                            <th scope="col">Ämne</th>
                            <th scope="col">Nivå</th>
                            <th scope="col">Åtgärd</th>
                            <!-- <th scope="col">Houly Rate</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let subjectsList of this.userData.subjects">
                            <td>{{ subjectsList?.subject_name }}</td>
                            <td>{{ subjectsList?.level_name }}</td>
                            <td>
                              <a href="javascript:void(0);" (click)="delete_teacher_subject(subjectsList.id, subjectsList.user_id)">
                                <i class="fas fa-trash-alt"></i>
                              </a>
                            </td>
                            <!-- <td>SEK {{ subjectsList?.hourly_rate }}</td> -->
                          </tr>
                        </tbody>
                                            
                      </table>
                    </div>
                  </div>
                  <div class="col-md-12 mt-3 mb-3 inline-block">
                    <a href="javascript:void(0);" (click)="onOpenSubjectModal()" class="style1">
                      <i class="fas fa-plus"></i> LÄGG TILL ÄMNE</a>
                  </div>
                </div>


                <div class="tab-pane fade" id="pills-Teaching" role="tabpanel" aria-labelledby="pills-Teaching-tab">
                  <form [formGroup]="createuser9">
                    <div class="emp_area">
                      <div class="name_feild">
                        <p class="">
                          Som lärare kan du välja den undervisningsmetod du vill använda för att undervisa dina elever.
                          Dina preferenser kommer att återspeglas i Skolbryggans plattforms sökresultat
                        </p>
                        <p>
                          <select name="" class="form-control" id="" formControlName="job_type" [ngClass]="{
                              'is-invalid': submitted9 && f9.job_type.errors
                            }">
                            <option value="">Happy to teach</option>
                            <option value="1">Online</option>
                            <option value="2">På plats</option>
                            <option value="3">Both</option>
                          </select>
                        </p>
                      </div>
                      <div class="loc_feild">
                        <div class="row">
                          <div class="col-md-12 p-0">
                            <p class="">
                              <a href="javascript:void(0);" class="style1 bg" (click)="onSubmitJobType()">SPARA &
                                UPPDATERA</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="tab-pane fade" id="pills-contact2" role="tabpanel" aria-labelledby="pills-contact2-tab">
                  <form [formGroup]="createuser">
                    <div class="emp_area">
                      <div class="name_feild">
                        <p>Gammalt Lösenord</p>
                        <div>
                          <input type="text" formControlName="current_password" [ngClass]="{
                              'is-invalid':
                                submitteed && f.current_password.errors
                            }" class="form-control" />

                          <div *ngIf="
                              (f.current_password.errors &&
                                f.current_password.touched) ||
                              (f.current_password.errors && submitteed)
                            " class="errorMsg">
                            <div class="warning" *ngIf="f.current_password.errors.mustMatch">
                              Vänligen do not match.
                            </div>
                          </div>
                          <div *ngIf="
                              f.current_password.invalid &&
                              (f.current_password.dirty ||
                                f.current_password.touched)
                            ">
                            <div class="warning" *ngIf="f.current_password.errors.required">
                              Gammalt lösenord krävs.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="loc_feild">
                        <p>Nytt Lösenord</p>
                        <div>
                          <input type="text" formControlName="password" [ngClass]="{
                              'is-invalid': submitteed && f.password.errors
                            }" class="form-control" />
                          <div *ngIf="
                              f.password.invalid &&
                              (f.password.dirty || f.password.touched)
                            ">
                            <div class="warning" *ngIf="f.password.errors.required">
                              ny Vänligen is krävs.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="loc_feild">
                        <p>Bekräfta Nytt Lösenord</p>
                        <div>
                          <input type="text" formControlName="reenterpassword" [ngClass]="{
                              'is-invalid':
                                submitteed && f.reenterpassword.errors
                            }" class="form-control" />
                          <div *ngIf="
                              (f.reenterpassword.errors &&
                                f.reenterpassword.touched) ||
                              (f.reenterpassword.errors && submitteed)
                            " class="errorMsg">
                            <div class="warning" *ngIf="f.reenterpassword.errors.mustMatch">
                              The Lösenord and Re-Enter Lösenord do not match.
                            </div>
                          </div>
                          <div *ngIf="
                              f.reenterpassword.invalid &&
                              (f.reenterpassword.dirty ||
                                f.reenterpassword.touched)
                            ">
                            <div class="warning" *ngIf="f.reenterpassword.errors.required">
                              Detta fält är obligatoriskt.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="loc_feild">
                        <div class="row">
                          <div class="col-md-12 p-0">
                            <p class="add_cart">
                              <a href="javascript:void(0);" (click)="onChangePassword()">SPARA</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="pills-tab2" role="tabpanel" aria-labelledby="pills-tab2-tab">
            <div class="list_box1">
              <div class="tab_link">
                <p class="for_m dd_next hand">
                  Konto<i class="fas fa-caret-down"></i>
                </p>
                <div class="for_d">
                  <ul class="nav nav-pills mb-3" id="quiz-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a class="nav-link active" id="quiz-question2-tab" data-toggle="pill" href="#quiz-question2"
                        role="tab" aria-controls="quiz-question2" aria-selected="true">Verifiering</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="quiz-answer2-tab" data-toggle="pill" href="#quiz-answer2" role="tab"
                        aria-controls="quiz-answer2" aria-selected="false">Gratis Provklass</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="quiz-Payment-tab" data-toggle="pill" href="#quiz-Payment" role="tab"
                        aria-controls="quiz-Payment" aria-selected="false">Betalningsdetaljer</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pictureupload-tab" data-toggle="pill" href="#pictureupload" role="tab"
                        aria-controls="pictureupload" aria-selected="false">Profilbild</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="quiz-Privacy-tab" data-toggle="pill" href="#quiz-Privacy" role="tab"
                        aria-controls="quiz-Privacy" aria-selected="false">Sekretess / Inställningar</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab_cont p-2">
                <div class="tab_cont tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="quiz-question2" role="tabpanel"
                    aria-labelledby="quiz-question2-tab">
                    <div class="list_boxs">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Dokumentnamn</th>
                            <th scope="col">status</th>
                            <th scope="col">Skapelsedatum</th>
                            <th scope="col">Space</th>
                            <th scope="col">Filuppladdning</th>
                            <th scope="col">Klicka för att Ladda Upp</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Körkort</td>

                            <td *ngIf='DocumentList["Driving Licence"]?.status==1'>Approved</td>
                            <td *ngIf='DocumentList["Driving Licence"]?.status==0'>Pending</td>
                            <td *ngIf='!DocumentList["Driving Licence"]?.created'>--</td>
                            <td>{{DocumentList["Driving Licence"]?.created | date : 'medium'|| '-'}}</td>
                            <td>
                              <a href="javascript:void(0)" class="delete-btn-icon px-1"
                                (click)="onDeleteDocument(DocumentList['Driving Licence'], 2)">
                                <i class="fas fa-trash" aria-hidden="true"></i>
                              </a>
                            </td>

                            <td> <button class="comparestyle1">
                                <i class="fas fa-plus"></i>
                                <input type="file" class="chustyle" accept="image/*" (change)="
                              onSelectDocumentFile($event.target,'Driving Licence')
                            " #file id="file" />
                                Körkort
                              </button></td>
                            <td><a *ngIf="document_name=='Driving Licence'" href="javascript:void(0);"
                                (click)="onSubmitDocument()">Upload</a></td>
                          </tr>
                          <tr>
                            <td>Förstärkt DBS-kontroll</td>

                            <td *ngIf='DocumentList["Enhanced DBS Check"]?.status==1'>Approved</td>
                            <td *ngIf='DocumentList["Enhanced DBS Check"]?.status==0'>Pending</td>
                            <td *ngIf='!DocumentList["Enhanced DBS Check"]?.created'>--</td>
                            <td>{{DocumentList["Enhanced DBS Check"]?.created | date : 'medium'|| '-'}}</td>
                            <td><a href="javascript:void(0)" class="delete-btn-icon px-1"
                                (click)="clear_image('Enhanced DBS Check')"><i class="fas fa-trash"
                                  aria-hidden="true"></i></a></td>

                            <td> <button class="comparestyle1">
                                <i class="fas fa-plus"></i>
                                <input type="file" class="chustyle" accept="image/*" (change)="
                              onSelectDocumentFile($event.target,'Enhanced DBS Check')
                            " #file id="file" />
                                Enhanced DBS Check
                              </button></td>
                            <td><a *ngIf="document_name=='Enhanced DBS Check'" href="javascript:void(0);"
                                (click)="onSubmitDocument()">Upload</a></td>
                          </tr>
                          <tr>
                            <td>Adressbevis</td>

                            <td *ngIf='DocumentList["Proof of Address"]?.status==1'>Approved</td>
                            <td *ngIf='DocumentList["Proof of Address"]?.status==0'>Pending</td>
                            <td *ngIf='!DocumentList["Proof of Address"]?.created'>--</td>
                            <td>{{DocumentList["Proof of Address"]?.created | date : 'medium'|| '-'}}</td>
                            <td><a href="javascript:void(0)" class="delete-btn-icon px-1"
                                (click)="clear_image('Proof of Address')"><i class="fas fa-trash"
                                  aria-hidden="true"></i></a></td>

                            <td> <button class="comparestyle1">
                                <i class="fas fa-plus"></i>
                                <input type="file" class="chustyle" accept="image/*" (change)="
                                onSelectDocumentFile($event.target,'Proof of Address')
                              " #file id="file" />
                              Adressbevis
                              </button></td>
                            <td><a *ngIf="document_name=='Proof of Address'" href="javascript:void(0);"
                                (click)="onSubmitDocument()">Upload</a></td>
                          </tr>
                          <tr>
                            <td>Pass / ID</td>

                            <td *ngIf='DocumentList["Passport / ID"]?.status==1'>Approved</td>
                            <td *ngIf='DocumentList["Passport / ID"]?.status==0'>Pending</td>
                            <td *ngIf='!DocumentList["Passport / ID"]?.created'>--</td>
                            <td>{{DocumentList["Passport / ID"]?.created | date : 'medium'|| '-'}}</td>
                            <td><a href="javascript:void(0)" class="delete-btn-icon px-1"
                                (click)="clear_image('Passport / ID')"><i class="fas fa-trash"
                                  aria-hidden="true"></i></a></td>

                            <td> <button class="comparestyle1">
                                <i class="fas fa-plus"></i>
                                <input type="file" class="chustyle" accept="image/*" (change)="
                              onSelectDocumentFile($event.target,'Passport / ID')
                            " #file id="file" />
                            Pass / ID
                              </button></td>
                            <td><a *ngIf="document_name=='Passport / ID'" href="javascript:void(0);"
                                (click)="onSubmitDocument()">Upload</a></td>
                          </tr>
                          <tr>
                            <td>Högsta Examensgrad</td>

                            <td *ngIf='DocumentList["Highest Degree of"]?.status==1'>Approved</td>
                            <td *ngIf='DocumentList["Highest Degree of"]?.status==0'>Pending</td>
                            <td *ngIf='!DocumentList["Highest Degree of"]?.created'>--</td>
                            <td>{{DocumentList["Highest Degree of"]?.created | date : 'medium'|| '-'}}</td>
                            <td><a href="javascript:void(0)" class="delete-btn-icon px-1"
                                (click)="clear_image('Highest Degree of')"><i class="fas fa-trash"
                                  aria-hidden="true"></i></a></td>

                            <td> <button class="comparestyle1">
                                <i class="fas fa-plus"></i>
                                <input type="file" class="chustyle" accept="image/*" (change)="
                              onSelectDocumentFile($event.target,'Highest Degree of')
                            " #file id="file" />
                            Högsta Examensgrad
                              </button></td>
                            <td><a *ngIf="document_name=='Highest Degree of'" href="javascript:void(0);"
                                (click)="onSubmitDocument()">Upload</a></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="quiz-answer2" role="tabpanel" aria-labelledby="quiz-answer2-tab">
                    <p class="text-center mb-3 animated2 drop_eff2">
                      En fantastisk metod för att attrahera nya, långsiktiga elever är att erbjuda föräldrar eller
                      studenter möjligheten att schemalägga en snabb, 15-30 minuters videokonversation med dig, innan de
                      anmäler sig till lektioner</p>
                    <table class="table table-bordered tutor-class-ext">
                      <thead>
                        <tr>
                          <th scope="col">Lärarens Namn</th>
                          <th scope="col">Bokat Datum</th>
                          <th scope="col">Betalning</th>
                          <th scope="col">Undervisningsfrekvens</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Provklass</td>
                          <td>När som helst</td>
                          <td>Gratis</td>
                          <td>Provklass</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="row">
                      <div class="col-md-12 p-0 mt-3 inline-block">
                        <a href="#" target="_blank" class="style1">GÅ MED</a>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="quiz-Payment" role="tabpanel" aria-labelledby="quiz-Payment-tab">
                    <!-- <div class="name_feild">
                      <p class="">Du behöver ha mer än 20 SEK för att ta ut dina intäkter. För närvarande har du SEK {{totalamount || '0'}} </p>
                      <div>
                        <div>Add more then 10</div>
                        <input style="border-radius:30px;" class="form-control" type="number"
                          placeholder="Vänligen ange belopp" (keyup)="withdraw($event.target)">
                        <span class="warning">{{req_amount}}</span>
                      </div>
                      <div class="row">
                        <div class="col-md-12 p-0">
                          <p class="add_cart" style="text-align: center;margin-top:20px;">
                            <a href="javascript:void(0);" (click)="withdrawamountrequest()">BEGÄRAN OM UTBETALNING
                            </a>
                          </p>
                        </div>
                      </div>
                    </div> -->
                    <form [formGroup]="bankdetail">
                      <div class="emp_area">
                        <div class="name_feild">
                          <p class="">Kontonummer</p>
                          <p>
                            <input type="number" formControlName="acc_number" class="form-control" [ngClass]="{
                                'is-invalid':
                                  submitted15 && f15.acc_number.errors
                              }" />
                          </p>
                        </div>
                        <div class="name_feild">
                          <p class="">Clearing nr</p>
                          <p>
                            <input type="number" formControlName="bank_sort_code" class="form-control" [ngClass]="{
                                'is-invalid':
                                  submitted15 && f15.bank_sort_code.errors
                              }" />
                          </p>
                        </div>
                        <div class="name_feild">
                          <p class="">IBAN</p>
                          <p>
                            <input type="text" formControlName="iban" class="form-control" [ngClass]="{
                                'is-invalid': submitted15 && f15.iban.errors
                              }" />
                          </p>
                        </div>
                        <div class="name_feild">
                          <p class="">BIC</p>
                          <p>
                            <input type="text" formControlName="bic" class="form-control" [ngClass]="{
                                'is-invalid': submitted15 && f15.bic.errors
                              }" />
                          </p>
                        </div>
                        <div class="loc_feild">
                          <div class="row">
                            <div class="col-md-12 p-0">
                              <p class="add_cart">
                                <a href="javascript:void(0);" (click)="onBankdetails()">SPARA & UPPDATERA</a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="loc_feild">
                                            <p>Account Number <span class="red">*</span></p>
                                            <div><input placeholder="Please type your first name" type="text"
                                                    [(ngModel)]="userData.name"
                                                    [ngModelOptions]="{standalone: true}" required>
                                                <span class="warning">{{namefield }} </span>
                                            </div>

                                        </div>
                                        <div class="loc_feild">
                                            <p>Sort Code <span class="red">*</span></p>
                                            <p><input placeholder="Please type your Last name" type="text"
                                                    [(ngModel)]="userData.last_name"
                                                    [ngModelOptions]="{standalone: true}">
                                                <span class="warning">{{lastname }} </span>
                                            </p>

                                    </div>
                                        <div class="loc_feild">
                                            <p>IBAN <span class="red">*</span></p>
                                            <div><input placeholder="Please type your first name" type="text"
                                                    [(ngModel)]="userData.name"
                                                    [ngModelOptions]="{standalone: true}" required>
                                                <span class="warning">{{namefield }} </span>
                                            </div>

                                        </div>
                                        <div class="loc_feild">
                                            <p>BIC <span class="red">*</span></p>
                                            <p><input placeholder="Please type your Last name" type="text"
                                                    [(ngModel)]="userData.last_name"
                                                    [ngModelOptions]="{standalone: true}">
                                                <span class="warning">{{lastname }} </span>
                                            </p>

                                    </div>
                                 
                                    </div> -->
                      </div>
                    </form>
                  </div>

                  <div class="tab-pane fade" id="pictureupload" role="tabpanel" aria-labelledby="pictureupload-tab">
                    <form>
                      <div class="emp_area">
                        <div class="name_feild bdrb">
                          <div class="row justify-content-center">
                            <div class="col-md-6  col-lg-3 text-center pb-4">
                              <div class="profile-photo-pick rel">
                                <img src="assets/images/p-blank.jpg" alt="" class="m-auto round img-fluid"
                                  *ngIf="uploadedImagesData == ''" />
                                <img [src]="
                                  allowurl(
                                    apiService.imageBase + uploadedImagesData
                                  )
                                " alt="" class="m-auto img-fluid" *ngIf="uploadedImagesData != ''" />
                                <p class="mt-3">Lägg till Din Profilbild</p>
                                <p class="mt-2">
                                  <button class="style1">
                                    <i class="fas fa-plus"></i><input type="file" accept="image/*"
                                      (change)="onSelectFile($event.target)" #file id="file" style="
                                      opacity: 0;
                                      position: absolute;
                                      left: 1px;
                                    " />
                                    LÄGG TILL FOTO
                                  </button><br />
                                  <span class="tooltip-icon" data-toggle="tooltip" data-placement="right"
                                    title="Please upload photos on the following format .jpeg, jpg or .png">
                                    <i class="fas fa-info-circle"></i>
                                  </span>
                                </p>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 p-0">
                            <p class="add_cart" style="text-align: center">
                              <a href="javascript:void(0);" (click)="onSubmitPhotoVideo()">SPARA & UPPDATERA
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>


                  <div class="tab-pane fade" id="quiz-Privacy" role="tabpanel" aria-labelledby="quiz-Privacy-tab">
                    <h4 class="text-center mb-3 animated2 drop_eff2">
                      Du kan ändra statusen för din Skickade Lärarprofil här.
                    </h4>
                    <div class="col-md-12">
                      <p class="text-center fs16 mb-4">
                        Vänligen klicka här för att pausa ditt konto och gå offline
                      </p>

                      <div class="text-center">
                        <button class="btn btn-sm" [ngClass]="{
                            'btn-success': user_status == 1,
                            'btn-danger': user_status == 0
                          }" (click)="updateStatusData(user_status)">
                          {{ user_status == 1 ? "Aktiv" : "Inaktiv" }}
                        </button>
                      </div>
                      <br />

                      <div>
                        <p class="text-center fs16 mb-4">
                          Please click here to permanently delete your account
                          <a href="javascript:void(0)" class="delete-btn-icon px-1" (click)="delete_user(userData.id)">
                            <i class="fas fa-trash" aria-hidden="true"></i>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="pills-tab222" role="tabpanel" aria-labelledby="pills-tab222-tab">
            <div class="list_box1">
              <div class="tab_link">
                <p class="for_m dd_next hand">
                  Konto<i class="fas fa-caret-down"></i>
                </p>
                <div class="for_d">
                  <ul class="nav nav-pills mb-3" id="quiz-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a class="nav-link active" id="pills-lesson1-tab" data-toggle="pill" href="#pills-lesson1"
                        role="tab" aria-controls="pills-lesson1" aria-selected="true">Kommande</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-lesson2-tab" data-toggle="pill" href="#pills-lesson2" role="tab"
                        aria-controls="pills-lesson2" aria-selected="false">Slutförda</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-lesson3-tab" data-toggle="pill" href="#pills-lesson3" role="tab"
                        aria-controls="pills-lesson3" aria-selected="false">Avbrutna</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab_cont p-2">
                <div class="tab_cont tab-content" id="pills-tabContent">
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <label> Startdatum</label>
                      <input type="date" class="form-control-design1" [ngModel]="startDate | date : 'yyyy-MM-dd'"
                        (ngModelChange)="setDate('start', $event)" name="startDate" />
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <label>Slutdatum</label>
                      <input type="date" class="form-control-design1" [ngModel]="endDate"
                        (ngModelChange)="setDate('end', $event)" name="endDate" />
                    </div>

                    <div class="col-md-12 col-sm-12">
                      <select class="form-select" aria-label="Default select example"
                        (change)="onChangetime($event.target)">
                        <option value="" selected>Välj</option>
                        <option value="08:00:00 09:00:00">
                          08:00:00 - 09:00:00
                        </option>
                        <option value="09:00:00 10:00:00">
                          09:00:00 - 10:00:00
                        </option>
                        <option value="10:00:00 11:00:00">
                          10:00:00 - 11:00:00
                        </option>
                        <option value="11:00:00 12:00:00">
                          11:00:00 - 12:00:00
                        </option>
                        <option value="12:00:00 13:00:00">
                          12:00:00 - 13:00:00
                        </option>
                        <option value="13:00:00 14:00:00">
                          13:00:00 - 14:00:00
                        </option>
                        <option value="14:00:00 15:00:00">
                          14:00:00 - 15:00:00
                        </option>
                        <option value="15:00:00 16:00:00">
                          15:00:00 - 16:00:00
                        </option>
                        <option value="16:00:00 17:00:00">
                          16:00:00 - 17:00:00
                        </option>
                        <option value="17:00:00 18:00:00">
                          17:00:00 - 18:00:00
                        </option>
                        <option value="18:00:00 19:00:00">
                          18:00:00 - 19:00:00
                        </option>
                        <option value="19:00:00 20:00:00">
                          19:00:00 - 20:00:00
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="tab-pane fade show active" id="pills-lesson1" role="tabpanel"
                    aria-labelledby="pills-home-tab">
                    <div class="col-12 mt-5">
                      <div class="row">
                        <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin showrecord">
                          <label>
                            Visa
                            <select class="filter-select mr-2" [(ngModel)]="bodyDataRequestu['limit']"
                              (input)="onChangeLimitupc($event.target.value)">
                              <option selected disabled>Välj</option>
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                              <option value="40">40</option>
                              <option value="50">50</option>
                            </select>
                            Poster
                          </label>
                        </div>
                        <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin text-right">
                          <pagination-controls (pageChange)="p = $event" id="u" previousLabel="Föregående"
                            nextLabel="Nästa" (pageChange)="onPageChangeupc($event)"></pagination-controls>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 wow fadeInLeft">
                      <ng-container class="list_box p-4 fs13 rel" *ngFor="
                          let myclasslist of MyClass_upcomming
                            | paginate
                              : {
                                  itemsPerPage: bodyDataRequestu['limit'],
                                  currentPage: p,
                                  totalItems: total_count_upcomming,
                                  id: 'u' 
                                };
                          let i = index
                        ">
                        <div class="row">
                          <div class="pt-3 px-0">
                            <table class="table table-bordered tutor-class-ext">
                              <thead>
                                <tr>
                                  <th scope="col">Studentnamn</th>
                                  <th scope="col">Subject</th>
                                  <th scope="col">Bokat Datum</th>
                                  <th scope="col">Betalning</th>
                                  <th *ngIf="myclasslist.class_type == 0" scope="col">
                                    Belopp
                                  </th>
                                  <th *ngIf="myclasslist.class_type == 1" scope="col">
                                    skriv 
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{{ myclasslist.student_name }}</td>
                                  <td>{{ myclasslist.subjectname }}</td>
                                  <td>
                                    {{
                                    myclasslist.schedule_slot_date
                                    | date
                                    : "EEEE,
                                    MMMM d, y"
                                    }}
                                    - {{ myclasslist.time_from }}
                                  </td>
                                  <td>Paid</td>
                                  <td *ngIf="myclasslist.class_type == 0">
                                    {{ myclasslist?.rate || "0" }} SEK
                                  </td>
                                  <td *ngIf="myclasslist.class_type == 1">
                                    Demo Class
                                  </td>
                                </tr>

                                <div class="btn-group impwidg">

                                  <button type="button" class="btn btn-outline-primary col-md-2 p-0 pl-3" *ngIf="
                                (myclasslist.schedule_slot_date
                                  | date : 'EEEE, MMMM d, y') ==
                                (today_date | date : 'EEEE, MMMM d, y')
                              ">

                                    <a href="https://merithub.com/cjiau685utj49marj5s0/sessions/view/{{
                                    myclasslist?.classId
                                  }}/{{ myclasslist?.hostLink }}" target="_blank" class="">Join</a>

                                  </button>
                                  <button type="button" class="btn btn-outline-primary d-none">
                                    <a href="javascript:void(0);" (click)="onsub(myclasslist)" class="">Reschedule</a>
                                  </button>

                                  <button type="button" class="btn btn-outline-primary d-none">
                                    <a href="javascript:void(0);" class=""
                                      (click)="onCancelClassPopup(myclasslist)">Cancel &amp; Refund</a>
                                  </button>

                                  <button type="button" class="btn btn-outline-primary Delivered">
                                    <a href="javascript:void(0);" *ngIf="myclasslist.status == 2">Delivered</a></button>

                                  <button *ngIf="myclasslist.status == 1" class="btn btn-outline-primary"
                                    (click)="class_status(myclasslist)">
                                    Upcoming
                                  </button>

                                </div>

                              </tbody>

                            </table>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>


                  <div class="tab-pane fade" id="pills-lesson2" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div class="col-12 mt-5">
                      <div class="row">
                        <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin showrecord">
                          <label>
                            Visa
                            <select class="filter-select mr-2" [(ngModel)]="bodyDataRequestc['limit']"
                              (input)="onChangeLimitcom($event.target.value)">
                              <option selected disabled>Välj</option>

                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                              <option value="40">40</option>
                              <option value="50">50</option>
                            </select>
                            Poster
                          </label>
                        </div>
                        <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin text-right">
                          <pagination-controls (pageChange)="p = $event" id='c' previousLabel="Previous"
                            nextLabel="Next" (pageChange)="onPageChangecom($event)"></pagination-controls>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 wow fadeInLeft">
                      <ng-container class="list_box p-4 fs13 rel" *ngFor="
                          let myclasslist of MyClass_complete
                            | paginate
                              : {
                                  itemsPerPage: bodyDataRequestc['limit'],
                                  currentPage: p,
                                  totalItems: total_count_complete,
                                  id:'c'
                                };
                          let i = index
                        ">
                        <table class="table table-bordered mt-5 tutor-class-ext">
                          <thead class="thead-dark">
                            <tr>
                              <th scope="col">Studentnamn</th>
                              <th scope="col">Bokat Datum</th>
                              <th scope="col">Betalning</th>
                              <th scope="col">
                                <p *ngIf="myclasslist.class_type == 0" class="Tuition-Frequency">Amount</p>
                                <p *ngIf="myclasslist.class_type == 1" class="Tuition-Frequency">Type</p>
                                <p class="b mt-2" *ngIf="myclasslist.class_type == 0">
                                  {{ myclasslist?.rate || "0" }} SEK
                                </p>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ myclasslist.student_name }}</td>
                              <td> {{
                                myclasslist.schedule_slot_date
                                | date
                                : "EEEE,
                                MMMM d, y"
                                }}
                                -
                                {{ myclasslist.time_from }}</td>
                              <td>Paid</td>
                              <td *ngIf="myclasslist.class_type == 1">Demo Class</td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="pills-lesson3" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <div class="col-12 mt-5">
                      <div class="row">
                        <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin showrecord">
                          <label>
                            Visa
                            <select class="filter-select mr-2" [(ngModel)]="bodyDataRequestca['limit']"
                              (input)="onChangeLimitcan($event.target.value)">
                              <option selected disabled>Välj</option>
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                              <option value="40">40</option>
                              <option value="50">50</option>
                            </select>
                            Poster
                          </label>
                        </div>
                        <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin text-right">
                          <pagination-controls (pageChange)="p = $event" id="ca" previousLabel="Previous"
                            nextLabel="Next" (pageChange)="onPageChangecan($event)"></pagination-controls>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 wow fadeInLeft">
                      <ng-container class="list_box p-4 fs13 rel" *ngFor="
                          let myclasslist of MyClass_cancel
                            | paginate
                              : {
                                  itemsPerPage: bodyDataRequestca['limit'],
                                  currentPage: p,
                                  totalItems: total_count_cancel,
                                  id:'ca'
                                };
                          let i = index
                        ">

                        <table class="table table-bordered mt-5 tutor-class-ext">
                          <thead class="thead-dark">
                            <tr>
                              <th scope="col">Studentnamn</th>
                              <th scope="col">Bokat Datum</th>
                              <th scope="col">Betalning</th>
                              <th scope="col">Undervisningsfrekvens</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ myclasslist.student_name }}</td>
                              <td>{{
                                myclasslist.schedule_slot_date
                                | date
                                : "EEEE,
                                MMMM d, y"
                                }}
                                -
                                {{ myclasslist.time_from }}</td>
                              <td>Paid</td>
                              <td> {{ myclasslist.tution_frequency }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div mdbModal #deleteModal="mdb-modal" class="modal fade" id="centralModalSuccess" tabindex="-1" user="dialog"
            aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto">
            <div class="modal-dialog modal-notify modal-danger" user="document">
              <div class="modal-content">
                <div class="modal-header" style="background-color: #1c1919">
                  <p class="heading lead">Radera</p>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="deleteModal.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="text-center">
                    <i class="fa fa-close fa-4x mb-3 animated rotateIn"></i>
                    <p class="res-delete">Är du säker, vill du radera ditt konto?</p>
                  </div>
                  <div class="loc_feild">
                    <p>
                      <textarea placeholder="Anledning till radering" type="text" class="form-control-design2"
                        (keyup)="reasonfordelete($event.target.value)"></textarea>
                    </p>
                  </div>
                </div>
                <div class="loc_feild">
                  <div class="pop-mod-style">
                    <a type="button" class="btn delre" mdbRippleRadius (click)="deleteRecord()">Radera</a>
                    <a type="button" class="btn" data-dismiss="modal" (click)="deleteModal.hide()"
                      mdbRippleRadius>Ändrade mig</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="pills-tab4" role="tabpanel" aria-labelledby="pills-tab4-tab">
            <div class="list_box p-5 m-auto bg-gray">
              <form>
                <div class="emp_area">
                  <div class="name_feild">
                    <div class="row">
                      <div class="col-md-6">
                        <select name="" id="">
                          <option value="">Välj Studentnamn</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <select name="" id="">
                          <option value="">Välj Class</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="loc_feild">
                    <p>
                      <textarea name="" id="" cols="30" rows="20"></textarea>
                    </p>
                  </div>

                  <div class="loc_feild">
                    <div class="row">
                      <div class="col-md-9">
                        <p><input type="text" /></p>
                      </div>
                      <div class="col-md-3">
                        <p class="add_cart"><a href="">Send</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="tab-pane fade" id="pills-tab6" role="tabpanel" aria-labelledby="pills-tab6-tab">
            <div class="My-Earning">
              <div class="row">
                <div class="My-Earnings-price">
                  <div class="row">
                    <div class="col-md-6 p-2 margin-auto">
                      <p class="fs18 b">Mina Intäkter</p>
                    </div>
                    <div class="col-md-6 p-2 e_txt">
                      <p>Totala Intäkter:</p>
                      <p class="b mt-2 fs18">{{ totalamount }}SEK </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="">
                    <label> Startdatum</label>
                    <input type="date" class="form-control-design1" [ngModel]="startDate | date : 'yyyy-MM-dd'"
                      (ngModelChange)="setDate1('start', $event)" name="startDate"
                      [max]="getCurrentDate() | date : 'yyyy-MM-dd'" />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="">
                    <label>Slutdatum</label>
                    <input type="date" class="form-control-design1" [ngModel]="endDate"
                      (ngModelChange)="setDate1('end', $event)" name="endDate"
                      [max]="getCurrentDate() | date : 'yyyy-MM-dd'" />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12"></div>
                <div class="col-md-6 col-sm-12 Clear-Search">
                  <span><button type="button" class="style1" (click)="clearDateFilter()">RENSA</button></span>
                  <span><button type="button" class="style1 bg" (click)="seach()">SÖK</button></span>
                </div>

                <div class="mt-5">
                  <table class="table table-bordered tutor-class-ext">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">Datum</th>
                        <th scope="col">Studentnamn/ Uttag</th>
                        <th scope="col">Beskrivning</th>
                        <th scope="col">Belopp</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="
                          let tutor_payouts of tutor_payout| paginate: {
                                  itemsPerPage: bodyDataRequest['limit'],
                                  currentPage: p,
                                  totalItems: total_count_payout,
                                  id:'pay'
                                };
                          let i = index
                        ">
                        <td>{{ tutor_payouts.created }}</td>
                        <td>
                          {{ tutor_payouts?.student_id }}
                          {{ tutor_payouts?.student_last }}
                        </td>
                        <td>
                          <span *ngIf="tutor_payouts.paid == 0; else other_content">Unpaid</span>
                          <span>
                            <ng-template #other_content>Paid</ng-template></span>
                        </td>

                        <td>{{ tutor_payouts.amount }}</td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="total_count_payout==0">
                      <tr>
                        <td>Dummy Betalning</td>
                        <td>13-06-2023</td>
                        <td>Paid</td>
                        <td>12</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
              <div class="row mt-5">
                <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin showrecord">
                  <label>
                    Visa
                    <select class="filter-select mr-2" [(ngModel)]="bodyDataRequest['limit']"
                      (change)="onChangeLimit($event.target.value)">
                      <option selected disabled>Välj</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>

                    </select>
                    Poster
                  </label>
                </div>
                <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin text-right">
                  <pagination-controls (pageChange)="p = $event" id="pay" previousLabel="Föregående" nextLabel="Nästa"
                    (pageChange)="onPageChange($event)"></pagination-controls>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="demo_class" role="tabpanel" aria-labelledby="demo_class-tab">
            <div class="My-Earning">
              <table class="table table-bordered tutor-class-ext">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Lärarens Namn</th>
                    <th scope="col">Bokat Datum</th>
                    <th scope="col">Betalning</th>
                    <th scope="col">Tuition Frequency</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Demoklass</td>
                    <td>När som helst</td>
                    <td>Gratis</td>
                    <td>Demoklass</td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-md-12 mt-3 d-flex justify-content-center">
                  <a href="https://merithub.com/cjiau685utj49marj5s0/sessions/view/cjomurr92kq5ihnkg9pg/IrJGKTp.LE8kxGzT_QrJXvx0rcVosmpz6y5hLYF1YkKIoBXaMc5uXpYWt8HCpTo2Sp3H7Ko5s9EmYBa7Cxx1yULi"
                    target="_blank" class="style1">GÅ MED</a>
                </div>
              </div>
            </div>

          </div>
          <div class="tab-pane fade" id="pills-tab7" role="tabpanel" aria-labelledby="pills-tab7-tab">
            <div class="col-lg-12 col-md-12 col-sm-12 wow fadeInLeft">
              <div class="My-Earning">
                <table class="table table-bordered">
                  <thead>
                    <tr *ngIf="userData.subjects?.length > 0">
                      <th scope="col">Ämne</th>
                      <th scope="col">Nivå</th>
                      <th scope="col">Timpris</th>
                      <th scope="col">PROFIL Timpris</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="
                        let subjectsList of this.userData.subjects
                          | paginate
                            : {
                                itemsPerPage: bodyDataRequest['limit'],
                                currentPage: p,
                                totalItems: total_count
                              };
                        let i = index
                      ">
                      <td>{{ subjectsList?.subject_name }}</td>
                      <td>{{ subjectsList?.level_name }}</td>
                      <td>SEK {{ subjectsList?.hourly_rate }}</td>
                      <td>SEK {{ subjectsList?.profile_rate }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="col-12 mt-5">
                  <div class="row">
                    <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin showrecord">
                      <label>
                        Visa
                        <select class="filter-select mr-2" [(ngModel)]="bodyDataRequest['limit']"
                          (input)="onChangeLimit($event.target.value)">
                          <option selected disabled>Välj</option>
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                        Poster
                      </label>
                    </div>
                    <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin text-right">
                      <pagination-controls (pageChange)="p = $event" previousLabel="Previous" nextLabel="Next"
                        (pageChange)="onPageChange($event)"></pagination-controls>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-tab8" role="tabpanel" aria-labelledby="pills-tab8-tab">
            <ng-container *ngIf="!addCourseFlag">
              <div class="row">
                <div class="col-lg-4 col-sm-6 wow fadeInLeft" *ngFor="let course_list of CourseList">
                  <div class="list_box">
                    <a href="javascript:void(0);" class="chat_btn" title="Let's Chat"
                      (click)="onEditCourse(course_list)"><i class="fas fa-edit"></i></a>
                    <a href="javascript:void(0);" class="book_btn" title="Book A 15 Minuts Meeting"
                      style="background-color: red" *ngIf="course_list.status == 0"
                      (click)="onChangeCourseStatus(course_list, 1)"><i class="fas fa-handshake"
                        style="color: #fff"></i></a>
                    <a href="javascript:void(0);" class="book_btn" title="Book A 15 Minuts Meeting"
                      style="background-color: green" *ngIf="course_list.status == 1"
                      (click)="onChangeCourseStatus(course_list, 0)"><i class="fas fa-handshake"
                        style="color: #fff"></i></a>
                    <a href="javascript:void(0);" class="class_btn" title="Intresseanmälan "
                      (click)="onChangeCourseStatus(course_list, 2)"><i class="fas fa-trash"></i></a>

                    <a href="javascript:void(0);" title=""><img onerror="this.src='assets/images/t5.jpg'" [src]="
                          allowurl(apiService.imageBase + course_list.image)
                        " alt="" class="img-fluid" style="height: 183px; width: 100%" /></a>
                    <div class="p-3 rel">
                      <!-- <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 & up (232)</span></p> -->

                      <p class="b float-left">
                        <a href="javascript:void(0);" title="">{{
                          course_list.course_name
                          }}</a>
                      </p>
                      <p class="b float-right">${{ course_list.amount }}</p>
                      <p class="clearfix"></p>
                      <!-- <p class="fs12" [innerHTML]="course_list.description"></p> -->
                      <!-- <p class="add_cart"><a href="javascript:void(0);" class="btn-sm">Active</a></p> -->
                      <p class="tbot">
                        Total Class :
                        <span>{{ course_list.no_of_classes }}</span>
                      </p>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-lg-4 col-sm-6 wow fadeInLeft">
                                    <div class="list_box">
                                        <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i
                                                class="fas fa-comments"></i></a>
                                        <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i
                                                class="fas fa-handshake"></i></a>
                                        <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i
                                                class="fas fa-laptop"></i></a>
                                        <a href="course-details.htm" title=""><img src="assets/images/t5.jpg" alt="" class="img-fluid"></a>
                                        <div class="p-3 rel">
                                            <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 & up (232)</span></p>
                                            <p class="b float-left"><a href="course-details.htm" title="">Computer Progr...</a>
                                            </p>
                                            <p class="b float-right">$50.00/hr</p>
                                            <p class="clearfix"></p>
                                            <p class="fs12">Max C.</p>
                                            <p class="chkbox"><input type="checkbox"></p>
                                            <p class="tbot">Duration (in hour) : <span>10</span></p>
                                        </div>
                                    </div>
                                </div> -->
              </div>
            </ng-container>
            <ng-container *ngIf="addCourseFlag">
              <form [formGroup]="createuser10">
                <div class="emp_area">
                  <div class="row">
                    <div class="col-6">
                      <div class="row justify-content-center">
                        <div class="col-md-6 text-center pb-4">
                          <img src="assets/images/p-blank.jpg" alt="" class="m-auto img-fluid"
                            *ngIf="uploadedImagesData == ''" />
                          <img [src]="
                              allowurl(
                                apiService.imageBase + uploadedImagesData
                              )
                            " alt="" class="m-auto img-fluid" *ngIf="uploadedImagesData != ''" />
                          <p class="mt-3">Lägg  Course FOTO</p>
                          <p>
                            <button class="compare">
                              <i class="fas fa-plus"></i><input type="file" accept="image/*"
                                (change)="onSelectFile($event.target)" #file id="file" style="
                                  opacity: 0;  position: absolute; left: 1px; " />
                                  LÄGG TILL FOTO
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="name_feild">
                        <p class="">Course Name</p>
                        <p>
                          <input type="text" formControlName="course_name" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted10 && f10.course_name.errors
                            }" />
                        </p>
                      </div>
                      <div class="name_feild">
                        <p>Course Type</p>
                        <p>
                          <select name="" id="" formControlName="course_type" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted10 && f10.course_type.errors
                            }">
                            <option value="">Välj</option>
                            <option value="1">Live</option>
                            <option value="2">Self Learn</option>
                          </select>
                        </p>
                      </div>
                      <div class="loc_feild">
                        <p class="">Price</p>
                        <p>
                          <input type="text" formControlName="amount" class="form-control" [ngClass]="{
                              'is-invalid': submitted10 && f10.amount.errors
                            }" />
                        </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="loc_feild">
                        <p class="">Fake Price</p>
                        <p>
                          <input type="text" formControlName="fake_price" class="form-control" [ngClass]="{
                              'is-invalid': submitted10 && f10.fake_price.errors
                            }" />
                        </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="loc_feild">
                        <p>level</p>
                        <p>
                          <select name="" id="" formControlName="age_group_id" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted10 && f10.age_group_id.errors
                            }">
                            <option [value]="lvl.id" *ngFor="let lvl of LevelList">
                              {{ lvl.title }}
                            </option>
                          </select>
                        </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="loc_feild">
                        <p class="">Price Per Class</p>
                        <p>
                          <input type="text" formControlName="price_per_classes" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted10 && f10.price_per_classes.errors
                            }" />
                        </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="loc_feild">
                        <p class="">No. Of Class</p>
                        <p>
                          <input type="text" formControlName="no_of_classes" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted10 && f10.no_of_classes.errors
                            }" />
                        </p>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label for="course_name">Activities</label>
                        <div [ngxSummernote]="config" formControlName="hands_on_activities" [ngClass]="{
                            'is-invalid':
                              submitted10 && f10.no_of_classes.errors
                          }"></div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label for="course_name">curriculum</label>
                        <div [ngxSummernote]="config" formControlName="curriculum"></div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label for="course_name">Description</label>
                        <div [ngxSummernote]="config" formControlName="description"></div>
                      </div>
                    </div>
                  </div>
                  <div class="loc_feild">
                    <div class="row">
                      <div class="col-md-12 p-0">
                        <p class="add_cart">
                          <a href="javascript:void(0);" (click)="onSubmitCourse()">SPARA</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ng-container>
          </div>
          <div class="tab-pane fade" id="pills-tab9" role="tabpanel" aria-labelledby="pills-tab9-tab">
            <div class="list_box p-4 fs13 rel">
              <a href="" class="del1"><i class="far fa-trash-alt"></i></a>
              <div class="row">
                <div class="col-lg-4 col-md-12">
                  <div class="list_box">
                    <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title=""
                      class="port_pop1"><img src="assets/images/t1.jpg" alt="" class="img-fluid" /></a>
                    <div class="p-3">
                      <p class="star">
                        <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                          class="fas fa-star"></i><i class="fas fa-star"></i>
                      </p>
                      <p class="b float-left">
                        <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title=""
                          class="port_pop1">Rebecca L</a>
                      </p>
                      <p class="b float-right">$50.00/hr</p>
                      <p class="clearfix"></p>
                      <p class="fs12">Dwarka, New Delhi</p>
                      <p class="tbot">Totala klass Erfarenhet : <span>10</span></p>
                      <p class="mt-4">
                        <a href="#" class="compare">Rate & Recensioner</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 col-md-12">
                  <div class="row mt-5">
                    <div class="col-lg-12 col-md-12 pb-3">
                      <p class="">Course Name</p>
                      <p class="b mt-2 fs18">
                        <a>British (Including IGCSE)</a>
                      </p>
                      <p class="mt-2 pb-3 bdrb mb-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nam volutpat posuere tristique. Vestibulum ante ipsum
                        primis in faucibus orci luctus et ultrices posuere
                        cubilia curae; Curabitur rhoncus urna a commodo rhoncus.
                        Sed porta ac ipsum in accumsan.
                      </p>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                      <p class="">Bokat Datum</p>
                      <p class="b mt-2">Friday, July 16, 2021</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                      <p class="">Startdatum</p>
                      <p class="b mt-2">Friday, July 16, 2021</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                      <p class="">Duration</p>
                      <p class="b mt-2">1 hour</p>
                    </div>
                    <div class="col-lg-12 col-md-12 pb-3">
                      <p class="bdrb pb-2">
                        <img src="assets/images/spacer.gif" alt="" />
                      </p>
                    </div>

                    <div class="col-lg-3 col-md-6">
                      <p>
                        <a href="#" data-toggle="modal" data-target="#res" class="compare">Reshedule</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="list_box p-4 fs13 rel">
              <a href="" class="del1"><i class="far fa-trash-alt"></i></a>
              <div class="row">
                <div class="col-lg-4 col-md-12">
                  <div class="list_box">
                    <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title=""
                      class="port_pop1"><img src="assets/images/t1.jpg" alt="" class="img-fluid" /></a>
                    <div class="p-3">
                      <p class="star">
                        <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                          class="fas fa-star"></i><i class="fas fa-star"></i>
                      </p>
                      <p class="b float-left">
                        <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title=""
                          class="port_pop1">Rebecca L</a>
                      </p>
                      <p class="b float-right">$50.00/hr</p>
                      <p class="clearfix"></p>
                      <p class="fs12">Dwarka, New Delhi</p>
                      <p class="tbot">Totala klass Erfarenhet : <span>10</span></p>
                      <p class="mt-4">
                        <a href="#" class="compare">Rate & Review</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 col-md-12">
                  <div class="row mt-5">
                    <div class="col-lg-12 col-md-12 pb-3">
                      <p class="">Course Name</p>
                      <p class="b mt-2 fs18">
                        <a>British (Including IGCSE)</a>
                      </p>
                      <p class="mt-2 pb-3 bdrb mb-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nam volutpat posuere tristique. Vestibulum ante ipsum
                        primis in faucibus orci luctus et ultrices posuere
                        cubilia curae; Curabitur rhoncus urna a commodo rhoncus.
                        Sed porta ac ipsum in accumsan.
                      </p>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                      <p class="">Bokat Datum</p>
                      <p class="b mt-2">Friday, July 16, 2021</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                      <p class="">Startdatum</p>
                      <p class="b mt-2">Friday, July 16, 2021</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                      <p class="">Duration</p>
                      <p class="b mt-2">1 hour</p>
                    </div>
                    <div class="col-lg-12 col-md-12 pb-3">
                      <p class="bdrb pb-2">
                        <img src="assets/images/spacer.gif" alt="" />
                      </p>
                    </div>

                    <div class="col-lg-3 col-md-6">
                      <p>
                        <a href="#" data-toggle="modal" data-target="#res" class="compare">Reshedule</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-tab10" role="tabpanel" aria-labelledby="pills-tab10-tab">
            <div class="list_box1">
              <div class="tab_link">
                <p class="for_m dd_next hand">
                  Frågepapper <i class="fas fa-caret-down"></i>
                </p>
                <div class="for_d">
                  <ul class="nav nav-pills mb-3" id="quiz-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a class="nav-link active" id="quiz-question-tab" data-toggle="pill" href="#quiz-questionpp"
                        role="tab" aria-controls="quiz-questionpp" aria-selected="true">Question Paper</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="quiz-answer-tab" data-toggle="pill" href="#quiz-answer" role="tab"
                        aria-controls="quiz-answer" aria-selected="false">Svarsblad</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab_cont p-2">
                <div class="tab_cont tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="quiz-questionpp" role="tabpanel"
                    aria-labelledby="quiz-question-tab">
                    <ng-container *ngIf="!addQuestionFlag">
                      <div class="Quiz-Papers rel" *ngFor="let quizz of QuestionList">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 mb-3">
                            <div class="Uploaded-Exam-Paper">
                              <h4>Quiz Title</h4>
                              <h5>{{ quizz?.quiz_title }}</h5>
                              <p [innerHTML]="quizz?.quiz_description"></p>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12 pb-3 Uploaded-Exam-Paper">
                            <h5>Uploaded Exam Paper</h5>
                            <div class="b mt-2 c-cont">
                              <a href="javascript:void(0);" (click)="getquestionid(quizz.id)" data-toggle="modal"
                                data-target="#edit3"><i class="fas fa-edit"></i> </a>
                              <a href="javascript:void(0);" (click)="deletequestionpaperid(quizz.id)"><i
                                  class="fas fa-trash"></i> </a>
                            </div>
                          </div>
                          <div class="col-md-12 col-lg-6 Uploaded-Exam-Paper">
                            <h5><strong>Level :</strong> {{ quizz.level_title }}</h5>
                            <h5><strong>Submission Date & Time :</strong> {{ quizz.exam_date | date }} /
                              {{ quizz.exam_time }}</h5>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="addQuestionFlag">
                      <form [formGroup]="createuser12">
                        <div class="emp_area">
                          <div class="row">

                            <div class="form-group col-md-6">
                              <label for="Select Subject">Välj Subject</label>
                              <select name="" id="" class="form-control" formControlName="category_id" [ngClass]="{
                                'is-invalid':
                                  submitted12 && f12.category_id.errors
                              }">
                                <option value="">Please Välj subject</option>
                                <option [value]="lvl.id" *ngFor="let lvl of CategoryList">
                                  {{ lvl.name }}
                                </option>

                              </select>
                              <span class="warning">{{ category_id1 }} </span>
                            </div>
                            <div class="form-group col-md-6">
                              <label for="Level">Level</label>
                              <select name="" id="" class="form-control" formControlName="level_id" [ngClass]="{
                                'is-invalid':
                                  submitted12 && f12.level_id.errors
                              }">
                                <option value="">Please Välj level</option>

                                <option [value]="lvl.id" *ngFor="let lvl of LevelList">
                                  {{ lvl.title }}
                                </option>

                              </select>
                              <span class="warning">{{ level_id1 }} </span>
                            </div>
                            <div class="form-group col-md-12">
                              <label for="Quiz Title">Quiz Title</label>
                              <input type="text" formControlName="quiz_title" class="form-control"
                                placeholder="Please enter quiz title" [ngClass]="{
                                      'is-invalid':
                                        submitted12 && f12.quiz_title.errors
                                    }" />
                              <span class="warning">{{ quiz_title1 }} </span>
                            </div>

                            <div class="form-group col-md-12">
                              <label for="Quiz Description">Quiz Description</label>
                              <div [ngxSummernote]="config" formControlName="quiz_description" placeholder="" [ngClass]="{
                                    'is-invalid':
                                      submitted12 && f12.quiz_description.errors
                                  }"></div>
                            </div>

                            <div class="form-group col-md-6">
                              <label for="Quiz Description">Please Välj exam date</label>
                              <input type="date" formControlName="exam_date" class="form-control" [ngClass]="{
                                'is-invalid':
                                  submitted12 && f12.exam_date.errors
                              }" />
                              <span class="warning">{{ exam_date1 }} </span>
                            </div>

                            <div class="form-group col-md-6">
                              <label for="Quiz Description">Please Välj exam time</label>
                              <input type="time" formControlName="exam_time" class="form-control" [ngClass]="{
                                'is-invalid':
                                  submitted12 && f12.exam_time.errors
                              }" />
                              <span class="warning">{{ exam_time1 }} </span>
                            </div>

                            <div class="form-group col-md-12 rel">
                              <label for="Quiz Description">Question Document</label>
                              <input type="file" accept="image/*" class="form-control" (change)="
                              onSelectQuestionFile($event.target)
                            " #file id="file" />
                              <span class="warning">{{ question_doc1 }} </span>
                            </div>
                            <div class="col-md-12 ">
                              <p class="add_cart">
                                <a href="javascript:void(0);" (click)="onSubmitQuestionAnswer()">SPARA</a>
                              </p>
                            </div>
                          </div>

                        </div>
                      </form>
                    </ng-container>
                  </div>
                  <div class="tab-pane fade" id="quiz-answer" role="tabpanel" aria-labelledby="quiz-answer-tab">
                    <div class="">
                      <table class="table table-bordered tutor-class-ext">
                        <thead>
                          <tr>
                            <th scope="col">Studentnamn</th>
                            <th scope="col">Quiztitel</th>
                            <th scope="col">Datum</th>
                            <th scope="col">Svarsblad</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let ans of ansList">
                            <td>{{ ans.student_name }}</td>
                            <td>{{ ans.quiz_title }}</td>
                            <td>{{ ans.upload_date }}</td>
                            <td>
                              <span *ngIf="ans?.answer_doc != null"><a [href]="
                                    allowurl(
                                      apiService.imageBase + ans.answer_doc
                                    )
                                  " [download]="'New_Assignment' + ans.answer_doc"><i class="fa fa-download"
                                    aria-hidden="true"></i></a></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="MonitorProgress" role="tabpanel" aria-labelledby="MonitorProgress-tab">
            <div class="list_box1">
              <div class="tab_link">
                <p class="for_m dd_next hand">
                  Hantera Läxor<i class="fas fa-caret-down"></i>
                </p>
                <div class="for_d">
                  <ul class="nav nav-pills mb-3" id="quiz-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a class="nav-link active" id="quiz-question2-tab" data-toggle="pill" href="#quiz-questionnt1"
                        role="tab" aria-controls="quiz-questionnt1" aria-selected="true">Hantera Läxor</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="quiz-answer222-tab" data-toggle="pill" href="#quiz-answernt2" role="tab"
                        aria-controls="quiz-answernt2" aria-selected="false">Prestanda</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab_cont p-2">
                <div class="tab_cont tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="quiz-questionnt1" role="tabpanel"
                    aria-labelledby="quiz-question2-tab">
                    <div class="">
                      <table class="table table-bordered tutor-class-ext">
                        <thead>
                          <tr>
                            <th scope="col">Studentnamn</th>
                            <th scope="col">Quiztitel</th>
                            <th scope="col">Datum</th>
                            <th scope="col">Svarsblad</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let ans of ansList">
                            <td>{{ ans.student_name }}</td>
                            <td>{{ ans.quiz_title }}</td>
                            <td>{{ ans.upload_date }}</td>
                            <td>
                              <span class="Clear-Search" *ngIf="ans?.answer_doc != null"><a [href]="
                                    allowurl(
                                      apiService.imageBase + ans.answer_doc
                                    )
                                  " [download]="'New_Assignment' + ans.answer_doc"><i class="fa fa-download"
                                    aria-hidden="true"></i></a>
                                <a class="nav-link" id="quiz-mark-tab" data-toggle="pill" href="#quiz-mark" role="tab"
                                  aria-controls="quiz-mark" aria-selected="false" (click)="id(ans.student_id)"><i
                                    class="fas fa-edit"></i></a></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="quiz-answernt2" role="tabpanel" aria-labelledby="quiz-answer222-tab">
                    <div class="">
                      <table class="table table-bordered tutor-class-ext">
                        <thead>
                          <tr>
                            <th scope="col">Studentnamn</th>
                            <th scope="col">Prestanda</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let ans of student_scorecard">
                            <td>
                              {{ ans.student_name }} {{ ans.stu_last_name }}
                            </td>
                            <td>
                              {{
                              (
                              (ans.engagement +
                              ans.l_understanding +
                              ans.mindfullness +
                              ans.persaverance +
                              ans.problem_solving +
                              ans.remory_retention +
                              ans.willingness) /
                              8
                              ).toFixed()
                              }}
                              %
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="quiz-mark" role="tabpanel" aria-labelledby="quiz-mark-tab">
                    <form [formGroup]="createuser13">
                      <div class="emp_area">
                        <div class="row">

                          <div class="form-group col-md-6">
                            <label for="">Level of Understanding Percentage</label>
                            <select name="" class="form-control" id="" formControlName="l_understanding" [ngClass]="{
                              'is-invalid':
                                submitted && f13.l_understanding.errors
                            }">
                              <option value="15">10% to 20%</option>
                              <option value="25">20% to 30%</option>
                              <option value="35">30% to 40%</option>
                              <option value="45">40% to 50%</option>
                              <option value="55">50% to 60%</option>
                              <option value="65">60% to 70%</option>
                              <option value="75">70% to 80%</option>
                              <option value="85">80% to 90%</option>
                              <option value="90">80% to 90%</option>
                              <option value="100">100%</option>
                            </select>
                          </div>
                          <div class="form-group col-md-6">
                            <label for="">Memory Retention Percentage</label>
                            <select name="" formControlName="remory_retention" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted4 && f13.remory_retention.errors
                            }">
                              <option value="15">10% to 20%</option>
                              <option value="25">20% to 30%</option>
                              <option value="35">30% to 40%</option>
                              <option value="45">40% to 50%</option>
                              <option value="55">50% to 60%</option>
                              <option value="65">60% to 70%</option>
                              <option value="75">70% to 80%</option>
                              <option value="85">80% to 90%</option>
                              <option value="90">80% to 90%</option>
                              <option value="100">100%</option>
                            </select>
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">Analytical Skills Percentage</label>
                            <select name="" formControlName="analytical_skills" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted4 && f13.analytical_skills.errors
                            }">
                              <option value="15">10% to 20%</option>
                              <option value="25">20% to 30%</option>
                              <option value="35">30% to 40%</option>
                              <option value="45">40% to 50%</option>
                              <option value="55">50% to 60%</option>
                              <option value="65">60% to 70%</option>
                              <option value="75">70% to 80%</option>
                              <option value="85">80% to 90%</option>
                              <option value="90">80% to 90%</option>
                              <option value="100">100%</option>
                            </select>
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">Analytical Skills Percentage</label>
                            <select name="" formControlName="analytical_skills" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted4 && f13.analytical_skills.errors
                            }">
                              <option value="15">10% to 20%</option>
                              <option value="25">20% to 30%</option>
                              <option value="35">30% to 40%</option>
                              <option value="45">40% to 50%</option>
                              <option value="55">50% to 60%</option>
                              <option value="65">60% to 70%</option>
                              <option value="75">70% to 80%</option>
                              <option value="85">80% to 90%</option>
                              <option value="90">80% to 90%</option>
                              <option value="100">100%</option>
                            </select>
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">Problem Solving Percentage</label>
                            <select name="" formControlName="problem_solving" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted4 && f13.problem_solving.errors
                            }">
                              <option value="15">10% to 20%</option>
                              <option value="25">20% to 30%</option>
                              <option value="35">30% to 40%</option>
                              <option value="45">40% to 50%</option>
                              <option value="55">50% to 60%</option>
                              <option value="65">60% to 70%</option>
                              <option value="75">70% to 80%</option>
                              <option value="85">80% to 90%</option>
                              <option value="90">80% to 90%</option>
                              <option value="100">100%</option>
                            </select>
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">Mindfulness Percentage</label>
                            <select name="" formControlName="mindfullness" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted4 && f13.mindfullness.errors
                            }">
                              <option value="15">10% to 20%</option>
                              <option value="25">20% to 30%</option>
                              <option value="35">30% to 40%</option>
                              <option value="45">40% to 50%</option>
                              <option value="55">50% to 60%</option>
                              <option value="65">60% to 70%</option>
                              <option value="75">70% to 80%</option>
                              <option value="85">80% to 90%</option>
                              <option value="90">80% to 90%</option>
                              <option value="100">100%</option>
                            </select>
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">Engagement Percentage</label>
                            <select name="" formControlName="engagement" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted4 && f13.engagement.errors
                            }">
                              <option value="15">10% to 20%</option>
                              <option value="25">20% to 30%</option>
                              <option value="35">30% to 40%</option>
                              <option value="45">40% to 50%</option>
                              <option value="55">50% to 60%</option>
                              <option value="65">60% to 70%</option>
                              <option value="75">70% to 80%</option>
                              <option value="85">80% to 90%</option>
                              <option value="90">80% to 90%</option>
                              <option value="100">100%</option>
                            </select>
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">Willing-ness To Learn Percentage</label>
                            <select name="" formControlName="willingness" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted4 && f13.willingness.errors
                            }">
                              <option value="15">10% to 20%</option>
                              <option value="25">20% to 30%</option>
                              <option value="35">30% to 40%</option>
                              <option value="45">40% to 50%</option>
                              <option value="55">50% to 60%</option>
                              <option value="65">60% to 70%</option>
                              <option value="75">70% to 80%</option>
                              <option value="85">80% to 90%</option>
                              <option value="90">80% to 90%</option>
                              <option value="100">100%</option>
                            </select>
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">Persaverance Percentage</label>
                            <select name="" formControlName="persaverance" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted4 && f13.persaverance.errors
                            }">
                              <option value="15">10% to 20%</option>
                              <option value="25">20% to 30%</option>
                              <option value="35">30% to 40%</option>
                              <option value="45">40% to 50%</option>
                              <option value="55">50% to 60%</option>
                              <option value="65">60% to 70%</option>
                              <option value="75">70% to 80%</option>
                              <option value="85">80% to 90%</option>
                              <option value="90">80% to 90%</option>
                              <option value="100">100%</option>
                            </select>
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">Collaboration Shown During Lesson</label>
                            <input type="text" formControlName="collaboration" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted4 && f13.collaboration.errors
                            }" />
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">Student’s strengths</label>
                            <input type="text" formControlName="strengths" class="form-control" [ngClass]="{
                              'is-invalid': submitted4 && f13.strengths.errors
                            }" />
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">Area for Improvement</label>
                            <input type="text" formControlName="Improvement" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted4 && f13.Improvement.errors
                            }" />
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">What to prepare and progress moving forward</label>
                            <input type="text" formControlName="moving_forward" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted4 && f13.moving_forward.errors
                            }" />
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">Tutor's Answer</label>
                            <input type="text" formControlName="t_ans" class="form-control" [ngClass]="{
                                'is-invalid': submitted4 && f13.t_ans.errors
                              }" />
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">Student's Answer</label>
                            <input type="text" formControlName="s_ans" class="form-control" [ngClass]="{
                              'is-invalid': submitted4 && f13.s_ans.errors
                            }" />
                          </div>

                          <div class="form-group col-md-6">
                            <label for="">Tutor Class</label>
                            <input type="text" formControlName="classes" class="form-control" [ngClass]="{
                              'is-invalid': submitted4 && f13.classes.errors
                            }" />
                          </div>

                          <div class="col-md-12 p-0">
                            <p class="add_cart">
                              <a href="javascript:void(0);" (click)="Submitstudentmarks()">SPARA</a>
                            </p>
                          </div>

                        </div>


                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="Recensioner" role="tabpanel" aria-labelledby="Recensioner-tab">
            <div class="My-Earning">
              <div class="Ratings-and-Recensioner">
                <h4>Betyg och Recensioner</h4>
                <div class="st-ratings" *ngFor="let ans of teacher_rating_list">


                  <p><strong>
                      <p *ngIf="ans.rating?.toFixed()==5" class="star"><i class="startcolor fas fa-star"></i><i
                          class="startcolor fas fa-star"></i><i class="startcolor fas fa-star"></i><i
                          class="startcolor fas fa-star"></i><i class="startcolor fas fa-star"></i><span class="letn">
                            Betyg </span></p>
                      <p *ngIf="ans.rating?.toFixed()==4" class="star"><i class="startcolor fas fa-star"></i><i
                          class="startcolor fas fa-star"></i><i class="startcolor fas fa-star"></i><i
                          class="startcolor fas fa-star"></i><i class='far'>&#xf005;</i><span class="letn">
                            Betyg </span></p>
                      <p *ngIf="ans.rating?.toFixed()==3" class="star"><i class="startcolor fas fa-star"></i><i
                          class="startcolor fas fa-star"></i><i class="startcolor fas fa-star"></i><i
                          class='far'>&#xf005;</i><i class='far'>&#xf005;</i><span class="letn"> Betyg </span></p>
                      <p *ngIf="ans.rating?.toFixed()==2" class="star"><i class="startcolor fas fa-star"></i><i
                          class="startcolor fas fa-star"></i><i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i
                          class='far'>&#xf005;</i><span class="letn"> Betyg </span></p>
                      <p *ngIf="ans.rating?.toFixed()==1" class="star"><i class="startcolor fas fa-star"></i>
                        <i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i
                          class='far'>&#xf005;</i><i class='far'>&#xf005;</i>
                        <span class="letn"> Betyg </span>
                      </p>
                    </strong> </p>

                  <h6>{{ ans.stu_name }}</h6>

                  <p class="mt-3">{{ ans.feedback }}</p>
                  <div class="date-time-class">
                    <div>{{ans.create_at | date : "EEEE, MMMM d, y"}}</div>
                  </div>
                </div>
              </div>
              <table class="table table-bordered tutor-class-ext">
                <thead>
                  <tr>
                    <th scope="col">Studentnamn</th>
                    <th scope="col">Stjärnbetyg</th>
                    <th scope="col">Feedback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let ans of teacher_rating_list">
                    <td>{{ ans.stu_name }}</td>

                    <td>
                      <p *ngIf="ans.rating?.toFixed()==5" class="star"><i class="fas fa-star"></i><i
                          class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                          class="fas fa-star"></i><span class="letn"> Betyg </span></p>
                      <p *ngIf="ans.rating?.toFixed()==4" class="star"><i class="fas fa-star"></i><i
                          class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                          class='far'>&#xf005;</i><span class="letn"> Betyg </span></p>
                      <p *ngIf="ans.rating?.toFixed()==3" class="star"><i class="fas fa-star"></i><i
                          class="fas fa-star"></i><i class="fas fa-star"></i><i class='far'>&#xf005;</i><i
                          class='far'>&#xf005;</i><span class="letn"> Betyg </span></p>
                      <p *ngIf="ans.rating?.toFixed()==2" class="star"><i class="fas fa-star"></i><i
                          class="fas fa-star"></i><i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i
                          class='far'>&#xf005;</i><span class="letn"> Betyg</span></p>
                      <p *ngIf="ans.rating?.toFixed()==1" class="star"><i class="fas fa-star"></i>
                        <i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i
                          class='far'>&#xf005;</i><i class='far'>&#xf005;</i>
                        <span class="letn"> Rating</span>
                      </p>

                    </td>
                    <td>{{ ans.feedback }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-tab13" role="tabpanel" aria-labelledby="pills-tab13-tab">
            <div class="col-12">
              <div class="row">
                <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin">
                  <label>
                    Visa
                    <select class="filter-select mr-2" [(ngModel)]="bodyDataRequest['limit']"
                      (input)="onChangeLimit($event.target.value)">
                      <option selected disabled>Välj</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>
                    Poster
                  </label>
                </div>
                <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin text-right">
                  <pagination-controls (pageChange)="p = $event" previousLabel="Previous" nextLabel="Next"
                    (pageChange)="onPageChange($event)"></pagination-controls>
                </div>
              </div>
            </div>
            <div class="col-lg-12 wow fadeInLeft">
              <div class="list_box p-4 fs13 rel" *ngFor="
                  let myclasslist of MyClass
                    | paginate
                      : {
                          itemsPerPage: bodyDataRequest['limit'],
                          currentPage: p,
                          totalItems: total_count
                        };
                  let i = index
                ">
                <div class="row">
                  <div class="col-lg-4 col-md-6 pb-3">
                    <p>Studentnamn</p>
                    <p class="b mt-2">{{ myclasslist.student_name }}</p>
                  </div>
                  <div class="col-lg-3 col-md-6 pb-3">
                    <p>Bokat Datum</p>
                    <p class="b mt-2">
                      {{
                      myclasslist.schedule_slot_date
                      | date : "EEEE, MMMM d, y"
                      }}
                      -
                      {{ myclasslist.time_from }}
                    </p>
                  </div>
                  <div class="col-lg-2 col-md-6 pb-3">
                    <p>Betalning</p>
                    <p class="b mt-2">Paid</p>
                  </div>
                  <div class="col-lg-2 col-md-6 pb-3">
                    <p>Tuition Frequency</p>
                    <p class="b mt-2">{{ myclasslist.tution_frequency }}</p>
                  </div>
                  <!-- <div class="col-lg-2 col-md-6 pb-3">
                                        <p><a href="javascript:void(0);" data-toggle="modal" data-target="#inv" class="compare">View
                                                Invoice</a></p>
                                    </div> -->
                  <div class="col-lg-12 col-md-12 bdrb"></div>
                  <div class="col-lg-12 col-md-12 pt-4">
                    <div class="row">
                      <div *ngIf="
                          (myclasslist.schedule_slot_date
                            | date : 'EEEE, MMMM d, y') ==
                          (today_date | date : 'EEEE, MMMM d, y')
                        " class="col-md-3 p-0 pl-3">
                        <a href="https://merithub.com/cjiau685utj49marj5s0/sessions/view/{{
                            myclasslist?.classId
                          }}/{{ myclasslist?.hostLink }}" target="_blank" class="compare">Join</a>
                      </div>
                      <div class="col-md-3 p-0 pl-3">
                        <a href="javascript:void(0);" (click)="onsub(myclasslist)" class="compare">Reschedule</a>
                      </div>
                      <div class="col-md-3 p-0 pl-3">
                        <a href="javascript:void(0);" class="compare" (click)="onCancelClassPopup(myclasslist)">Cancel
                          &amp; Refund</a>
                      </div>
                      <div class="col-md-3 p-0 pl-3">
                        <a href="javascript:void(0);" class="compare" *ngIf="myclasslist.status == 2">Delivered</a>
                      </div>
                      <button *ngIf="myclasslist.status == 1" class="btn btn-sm" (click)="class_status(myclasslist)">
                        Upcoming
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="modal" id="edit" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Add New Slot</h1>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <div class="quiz-Payment-ta-input">
              <label>Startdatum</label>
              <input type="date" class="form-control-design1" [ngModel]="startDateaval"
                (ngModelChange)="setDateaval($event)" name="startDate" />
            </div>
          </div>
          <button class="style1 bg" (click)="aval()">SKICKA in</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="edit3" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered logmod" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Redigera  TILL FRÅGA</h1>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="createuser12">
          <div class="emp_area">
            <div class="row">
              <div class="form-group col-md-4">
                <label for="Select Subject">Välj ämne</label>
                <select name="" id="" class="form-control" formControlName="category_id" [ngClass]="{
                    'is-invalid':
                      submitted12 && f12.category_id.errors
                  }">
                  <option value="">Vänligen  Välj Ämne</option>
                  <option [value]="lvl.id" *ngFor="let lvl of CategoryList">
                    {{ lvl.name }}
                  </option>

                </select>
                <span class="warning">{{ category_id1 }} </span>
              </div>
              <div class="form-group col-md-4">
                <label for="Level">Nivå</label>
                <select name="" id="" class="form-control" formControlName="level_id" [ngClass]="{
                  'is-invalid':
                    submitted12 && f12.level_id.errors
                }">
                  <option value="">Vänligen  Välj Nivå</option>

                  <option [value]="lvl.id" *ngFor="let lvl of LevelList">
                    {{ lvl.title }}
                  </option>

                </select>
                <span class="warning">{{ level_id1 }} </span>
              </div>
              <div class="form-group col-md-4">
                <label for="Level">Quiztitel</label>
                <input type="text" formControlName="quiz_title" class="form-control"
                  placeholder="Vänligen  enter quiztitel" [ngClass]="{
                        'is-invalid':
                          submitted12 && f12.quiz_title.errors
                      }" />
                <span class="warning">{{ quiz_title1 }} </span>
              </div>
              <div class="form-group col-md-12">
                <label for="course_name">Quiz Beskrivning</label>
                <div [ngxSummernote]="config" formControlName="quiz_description" placeholder="" [ngClass]="{
                    'is-invalid':
                      submitted12 && f12.quiz_description.errors
                  }"></div>

              </div>
              <div class="form-group col-4">
                <div class="name_feild">
                  <p>Vänligen Välj exam datum</p>
                  <p>
                    <input type="date" formControlName="exam_date" class="form-control" [ngClass]="{
                        'is-invalid':
                          submitted12 && f12.exam_date.errors
                      }" />
                    <span class="warning">{{ exam_date1 }} </span>

                  </p>
                </div>
              </div>
              <div class="form-group col-4">
                <div class="name_feild">
                  <p>Vänligen Välj exam helst</p>
                  <p>
                    <input type="time" formControlName="exam_time" class="form-control" [ngClass]="{
                        'is-invalid':
                          submitted12 && f12.exam_time.errors
                      }" />
                    <span class="warning">{{ exam_time1 }} </span>

                  </p>
                </div>
              </div>
              <div class="form-group col-4">
                <div class="name_feild rel">
                  <p>Till FRÅGA Dokument</p>
                  <input type="file" accept="image/*" class="form-control" (change)="
                          onSelectQuestionFile($event.target)
                        " #file id="file" />
                  <span class="warning">{{ question_doc1 }} </span>
                </div>
              </div>
              <div class="col-md-12">
                <p class="add_cart">
                  <a href="javascript:void(0);" (click)="onupdateQuestionAnswer()">SPARA</a>
                </p>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="edit4" tabindex="-1" role="dialog">

  <div class="modal-dialog modal-notify modal-danger" role="document">
    <div class="modal-content">
      <div style="color:black" class="modal-header">
        <p class="heading lead">Radera Fråge</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="deleteModal.hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <i class="fa fa-close fa-4x mb-3 animated rotateIn"></i>
          <p>Are you sure, You want to delete this record?</p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a type="button" style="color:black" class="btn btn-primary-modal waves-light" mdbRippleRadius
          (click)="deletequestionpaper()">Yes</a>
        <a type="button" style="color:black" class="btn btn-outline-secondary-modal" data-dismiss="modal"
          (click)="deleteModal.hide()" mdbRippleRadius>No</a>
      </div>
    </div>
  </div>
</div>
<div mdbModal #deleteModal1="mdb-modal" class="modal fade" id="centralModalSuccess" tabindex="-1" user="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: auto">
  <div class="modal-dialog modal-notify modal-danger" user="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #1c1919">
        <p class="heading lead">Radera Fråge</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="deleteModal1.hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <i class="fa fa-close fa-4x mb-3 animated rotateIn"></i>
          <p>Are you sure, You want to delete this record?</p>
        </div>
      </div>
      <div class="loc_feild">
        <div class="row">
          <div class="col-md-2 p-0 mb-2"></div>
          <div class="col-md-4 p-0 mb-2">
            <a type="button" class="btn" mdbRippleRadius (click)="deletequestionpaper()">Yes</a>
          </div>
          <div class="col-md-4 p-0 mb-2">
            <a type="button" class="btn" data-dismiss="modal" (click)="deleteModal1.hide()" mdbRippleRadius>No</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>