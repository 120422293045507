<div class="mb-3 ptfix">
	<div class="container pt-4 pl-3">
		<ul class="breadcrumb">
            <li><a routerLink="/">Home</a></li>
			<!--<li><a href="listing.htm">Chair</a></li>-->
			<li class="active">Calculator</li>
		</ul>
	</div>
	<div class="container pl-3 pr-3">
		<h1><span class="txt_gray mob_hide"></span>Calculator</h1>            
	</div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->

<!-- Content Stars -->
<section class="animated3 wow drop_eff5">
  <div class="container mb-5 minh_600">
    <div class="row justify-content-center">
      <div class="col-lg-8 wow fadeInLeft newsletter">
        <form [formGroup]="addForm" (ngSubmit)="onaddForm()">
            <div class="mt-3 form">
                <div class="mb-3">
                    <p>Förnamn </p>
                    <p>
                        <input type="text" mdbInput class="form-control" formControlName="first_name"
                        placeholder="Förnamn  *"
                        [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }">
                       
                    </p>
                </div>    
                
                <div class="mb-3">
                    <p>Efternamn</p>
                    <p class="mt10">
                        <input type="text" mdbInput class="form-control" formControlName="last_name"
                        placeholder="Efternamn *"
                        [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
                    </p>
                </div>
                
                <div class="mb-3">
                    <p>E-post</p>
                    <p class="mt10">
                        <input type="text" mdbInput class="form-control" formControlName="email"
                        placeholder="E-post*"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    </p>
                </div>
                
                <div class="mb-3">
                    <p>Mobil</p>
                    <p class="mt10">
                        <input type="text" mdbInput class="form-control" formControlName="mobile"
                        placeholder="Mobil *"
                        [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                    </p>
                </div>
                
                <div class="mt-2">
                    <p class="pull-left">
                        <button class="nbtn1 text-center ttu mt-5" mdbBtn type="submit">SKICKA in</button>
                    </p>
                </div>
            </div>

        </form>
          
	  	</div>
      </div>
    </div>
</section>