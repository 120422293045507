import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home-footer',
  templateUrl: './home-footer.component.html',
  styleUrls: ['./home-footer.component.scss']
})
export class HomeFooterComponent implements OnInit {
  categoryList: any=[];
  footerData:any=[];
  cmsList:any=[];
  constructor(public apiService :ApiService,public sanitizer: DomSanitizer,public commonService:CommonService) { }

  ngOnInit(): void {
  }
}
