<div class="mb-3 contT">
	<div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Mitt Konto</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
    	<h1 class="float-left"><span class="txt_gray mob_hide">Student Prestanda </span>Results</h1>			
		<!--<p class="float-right"><a href="#" data-toggle="modal" data-target="#add" class="compare"><i class="fas fa-plus"></i> Add Question Paper</a></p>-->
		<p class="clearfix"></p>
    </div>
</div>


<section class="animated3 wow drop_eff5" style="visibility: hidden; animation-name: none;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-3">
                        <!-- filter-->
                            <form class="list_box mb-4">
                            <div class="dd_next hand p-3 bdrb fs16 b"> <span>Search</span></div>
                            <div class="emp_area f_dd_box p-3 mb-4">
                                <div class="name_feild">
                                    <p>Class Title</p>
                                    <p><input type="text"></p>
                                </div>
                                <div class="loc_feild">
                                    <p>Timing</p>
                                    <p><input type="text"></p>
                                </div>
                                <div class="loc_feild">
                                    <p>Date Range</p>
                                    <p><select name="" id=""><option value="">Select</option></select></p>
                                </div>
                                
                                <div class="loc_feild">
                                    <input type="submit" value="Submit" class="compare w100">
                                </div>
                            </div>
                            </form>
                        <!-- filter end-->
                    </div>
                    <div class="col-lg-9 col-sm-8 col-sm-12 wow fadeInLeft" style="visibility: hidden; animation-name: none;">
                        <div class="list_box p-4 fs13 rel">
                            <!--<a href="" class="del"><i class="fas fa-trash-alt"></i></a>-->
                            <div class="row">
                                <div class="col-lg-6 col-md-6 pb-3">
                                    <p>Course Name</p>
                                    <p class="b mt-2 fs18"><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                    <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique.</p>
                                    
                                </div>
                                <div class="col-lg-6 col-md-6 pb-3">
                                    <p>Download Answer Sheet</p>
                                    <div class="b mt-2 c-cont">
                                        <a href="#"><i class="fas fa-file-pdf"></i> Download<br>Answer</a>
                                    </div>
                                    
                                </div>
                                <div class="col-md-12 bdrb"></div>
                                <div class="col-md-12">
                                    <p class="mt-3 float-left">Level : <span class="b">Level 2</span> </p>
                                    <p class="mt-3 float-right"><a href="#" data-toggle="modal" data-target="#add-f" class="compare"><i class="fas fa-plus"></i> Post Feedbck</a> </p>
                                    <p class="clearfix"></p>
                                </div>
                            </div>
                        </div>
                        
                        <div class="list_box p-4 fs13 rel">
                            <!--<a href="" class="del"><i class="fas fa-trash-alt"></i></a>-->
                            <div class="row">
                                <div class="col-lg-6 col-md-6 pb-3">
                                    <p>Course Name</p>
                                    <p class="b mt-2 fs18"><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                    <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique.</p>
                                    
                                </div>
                                <div class="col-lg-6 col-md-6 pb-3">
                                    <p>Download Answer Sheet</p>
                                    <div class="b mt-2 c-cont">
                                        <a href="#"><i class="fas fa-file-pdf"></i> Download<br>Answer</a>
                                    </div>
                                    
                                </div>
                                <div class="col-md-12 bdrb"></div>
                                <div class="col-md-12">
                                    <p class="mt-3 float-left">Level : <span class="b">Level 2</span> </p>
                                    <p class="mt-3 float-right"><a href="#" data-toggle="modal" data-target="#add-f" class="compare"><i class="fas fa-plus"></i> Post Feedbck</a> </p>
                                    <p class="clearfix"></p>
                                </div>
                            </div>
                        </div>
                        
                    </div>  
                </div>
            </div>
        </div>
    </div>
</section>