// gaurav
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';
import { Socket } from 'ngx-socket-io';  
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public imageBase: any = environment.IMAGE_URL;
    public serviceBase = environment.API_URL;
    public headers = new HttpHeaders({
        'Content-Type': 'application/json'
    });
    public loadingShow: boolean = false;
    constructor(
        private router: Router,
        private http: HttpClient,
        public common: CommonService,
        private socket: Socket) {
    }
    public errorHandler(error: any) {
        if (error) {
            console.log('Error:::::::', error);
            // return this.testData;
            if (error == 'Error: Session expired') {
                console.log('Error: Session expired');
                //401 Unauthorized
            }
            else if (error.status == 404) {
                console.log('Error: 404');
                this.router.navigate(['/', '404']);
            }
            else if (error.status == 500) {
                console.log('Error: 500');
                this.router.navigate(['/', '500']);
            }
            else if (error.status == 401) {
                console.log('Error: 401');
                //  LoginPage
            }
        }
    }
    fetchMovies() {
		this.socket.emit('fetchMovies');
	} 

	// listen event
	onFetchMovies() {
		return this.socket.fromEvent('fetchMovies');
	}
    sendMessage(data:any)
    {
      return  this.socket.emit('message',data);
    }
    newMessageReceived(){
        let observable = new Observable<{data: any}>(observer=>{
            this.socket.on('new message', (data:any)=>{
                observer.next(data);
            });
            return () => {this.socket.disconnect();}
        });
  
        return observable;
    }
    get(url: string) {
        console.log(localStorage.getItem('user'),'forbackend');
        
        return this.http.get(this.serviceBase + url, { headers: this.headers });
    }
    post(url: string, data: any) {

        return this.http.post(this.serviceBase + url, data, { headers: this.headers });
    }
    postFile(fileToUpload: File) {
        console.log('this is api call',fileToUpload);
        
        const formData: FormData = new FormData();
        formData.append('attachment', fileToUpload, fileToUpload.name);
        return this.http.post(this.serviceBase + 'upload_file', formData)
    }
    download_file(data: File) {
        return this.http.post(this.serviceBase + 'downloads' , data, { headers: this.headers });
      
    }
}