import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
declare const $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  Loader_Flag: boolean = false;
  changeText: boolean = false;
  param = { limit: 10, offset: 0 };
  homeBanner: any = [];
  HomesiteData: any = [];
  siteData: any = [];
  cmsData: any = [];
  isCollapsed: boolean[] = [];
  selectedValue: string = '';
  categoryList: any = [];
  courseList: any = [];

  TeachersList: any = [];
  tutorList: any = [];
  total_count: any = [];
  bodyRequestData: any = {};
  HowitWorksData: any = [];
  contentBlockData: any = [];
  homeTestimonial: any = [];
  homeBlog: any = [];
  HotCategoryList: any = [];
  ProductList: any = [];
  BrandList: any = [];
  FaqList: any = [];
  CollectionsList: any = [];
  HotProductList: any = [];
  responsiveOptions: any = {};
  responsiveOptions1: any = {};
  responsiveOptions2: any = {};
  GetReferralLogo = [0, 1, 2];
  cat1_flag: boolean = true;
  cat2_flag: boolean = false;
  selectedItem = 0;
  showBrand = 1;
  SelectedcategoryId = 1;
  bannerImage: any = {};
  OwlOptions1: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    autoplay: true,
    dots: false,
    navSpeed: 700,
    autoplayTimeout: 3000,
    smartSpeed: 1000,
    navText: ['Previous', 'Next'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };
  LoginUserDetails: any;
  constructor(
    private route: ActivatedRoute,
    public apiService: ApiService,
    public sanitizer: DomSanitizer,
    private title: Title,
    private meta: Meta,
    public commonService: CommonService,
    private router: Router
  ) {
    this.commonService.SetFooterContent(true);
  }

  public loadScript(url: any) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  IMAGE: any;
  ngOnInit(): void {
    
    console.log(this, 'this call');

    console.log('current route is', this.route.snapshot.queryParams.sd);
    localStorage.setItem('referral_id', this.route.snapshot.queryParams.sd);
    this.loadScript('assets/Scripts/fluid_dg.min.js');

    this.responsiveOptions = {
      dragging: true,
      loop: true,
      dots: true,
      autoplay: true,
      autoHeight: true,
      autoWidth: true,
      navSpeed: 700,
      items: 1,

      margin: 10,
      autoplayTimeout: 3000,
      autoplayHoverPause: true,
      navigation: true,
      slideSpeed: 300,
      paginationSpeed: 400,
      singleItem: true,
      pagination: false,
      rewindSpeed: 500,
    };
    this.responsiveOptions1 = {
      dragging: true,
      loop: true,
      dots: false,
      autoHeight: true,
      autoWidth: true,
      navSpeed: 700,
      0: {
        items: 1,
      },
      500: {
        items: 1,
      },
      970: {
        items: 1,
      },
    };

    this.responsiveOptions2 = {
      dragging: true,
      loop: true,
      dots: false,
      autoHeight: true,
      autoWidth: true,
      navSpeed: 700,
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      500: {
        items: 4,
      },
    };
    
    this.getFaqList();
    this.getBannerList();
    this.getTestimonialList();
    this.getSubjectsList();
    this.getTeachersList();
    this.getContentBlocks();
    this.getCourseList();
    // this.siteSettings();
    // this.getBannerList();
    // this.getBrandList();
    // this.getContentBlocks();
    // this.getCollectionsList();
    this.commonService.GetSetting().subscribe((data: any) => {
      this.siteData = data;
      this.commonService.GetLoginUserDetails().subscribe((data: any) => {
        this.LoginUserDetails = data;
        console.log('LoginUserDetails>>>>>>', this.LoginUserDetails.role_id);
      });
    });
  }
  onOpenRegisterPopup() {
    localStorage.setItem('register_pop_up', '0');
    this.commonService.SetRegisterPopup(true);
  }
  onOpenRegisterPopup1() {
    localStorage.setItem('register_pop_up', '1');
    this.commonService.SetRegisterPopup(true);
  }
  onOpenRegisterPopup2() {
    localStorage.setItem('register_pop_up', '2');

    this.commonService.SetRegisterPopup(true);
  }
  getFaqList() {
    var sendrequestinput: any = { isAdmin: '0', limit: '5', type: '2' };
    this.apiService
      .post('faqs_list', sendrequestinput)
      .subscribe((data: any) => {
        this.FaqList = data.data;
      });
  }
  getTestimonialList() {
    var sendrequestinput: any = { isAdmin: '0', limit: '10' };
    this.apiService
      .post('web_testimonials_list', sendrequestinput)
      .subscribe((data: any) => {
        this.homeTestimonial = data.data;
        console.log(this.homeTestimonial,"thththth")
      });
  }
  onOpenLoginPopup() {
    this.commonService.SetLoginPopup(true);
  }

  getSubjectsList() {
    var sendrequestinput: any = { isAdmin: '0', limit: '4' };
    this.apiService
      .post('category_dropdown_list', sendrequestinput)
      .subscribe((data: any) => {
        console.log(data, 'category_dropdown_list');

        this.categoryList = data.data;
      });
  }

  getCourseList() {
    var sendrequestinput: any = { isAdmin: '0', limit: '4' };
    this.apiService
      .post('courses_list', sendrequestinput)
      .subscribe((data: any) => {
        this.courseList = data.data;

        console.log('course:::', this.courseList);
      });
  }

  getTeachersList() {
    var sendrequestinput: any = { isAdmin: '0', limit: '4', featured: 1 };
    this.apiService
      .post('teachers_list', sendrequestinput)
      .subscribe((data: any) => {
        // Populate this.TeachersList with data from the API response
        this.TeachersList = data.data;
        console.log(this.TeachersList, 'this.TeachersList');
      });
  }
  
  siteSettings() {
    var sendrequestinput: any = { id: 1 };
    this.apiService
      .post('get_site_settings', sendrequestinput)
      .subscribe((data: any) => {
        this.HomesiteData = data.data;
        this.title.setTitle(this.HomesiteData.meta_title);
        this.meta.updateTag({
          name: 'keyword',
          content: this.HomesiteData.meta_keyword,
        });
        this.meta.updateTag({
          name: 'description',
          content: this.HomesiteData.meta_description,
        });
        this.Loader_Flag = false;
      });
  }

  urlSafe: SafeResourceUrl | undefined;
  urlSafe1: SafeResourceUrl | undefined;

  getContentBlocks() {
    var sendrequestinput: any = { limit: 40 };
    this.apiService
      .post('content_blocks_list', sendrequestinput)
      .subscribe((data: any) => {
        this.contentBlockData = data.data;
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.contentBlockData[0].video_url
        );
        this.urlSafe1 = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.contentBlockData[31].video_url
        );
        this.Loader_Flag = false;
      });
  }
  
  getBannerList() {
    var sendrequestinput: any = {
      is_admin: 0,
      type: 1,
    };
    this.apiService
      .post('banner_list', sendrequestinput)
      .subscribe((data: any) => {
        this.homeBanner = data.data;
        this.IMAGE = this.apiService.imageBase + this.homeBanner.image;
        console.log('IMAGE:::', this.IMAGE);
      });
  }
  getBrandList() {
    var sendrequestinput: any = { isAdmin: '0', limit: '3' };
    this.apiService
      .post('brand_list', sendrequestinput)
      .subscribe((data: any) => {
        this.BrandList = data.data;
      });
  }

  getCollectionsList() {
    var sendrequestinput: any = { isAdmin: '0' };
    this.apiService
      .post('collection_list', sendrequestinput)
      .subscribe((data: any) => {
        this.CollectionsList = data.data;
      });
  }

  allowurl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onChangeCat(_flag: any) {
    console.log(_flag);
    if (_flag == 'cat1') {
      this.cat1_flag = true;
      this.cat2_flag = false;
    } else {
      this.cat1_flag = false;
      this.cat2_flag = true;
    }
  }

  flag1: boolean = false;
  flag2: boolean = false;
  
  onFlag1() {
    this.flag1 = !this.flag1;
    if (this.flag1) {
      this.flag2 = false; // Close flag2 when flag1 is opened
    }
  }
  
  onFlag2() {
    this.flag2 = !this.flag2;
    if (this.flag2) {
      this.flag1 = false; // Close flag1 when flag2 is opened
    }
  }
  // flag1: boolean = false;
  // flag2: boolean = false;
  // onFlag1() {
  //   this.flag1 = !this.flag1;
  // }
  // onFlag2() {
  //   this.flag2 = !this.flag2;
  // }
 
 
  onSearchKeyword(event: any) {
    this.commonService.SetBannerKeyword(event.value);
    console.log(event.value, 'log');
    var bodyRequestData: any = {};

    bodyRequestData.keyword = event.value;
    localStorage.setItem('tutorname', event.value);
    // this.apiService.post('teachers_list', bodyRequestData).subscribe((data: any) => {
    //   console.log(data,'asdfa');
    // })
  }
  keyDownFunction(event: any) {
    console.log(event.value, 'asdf');
    if (event.value != '') {
      this.onsearchKeywordResult();
    }
  }
  onsearchKeywordResult() {
    this.router.navigate(['/find-a-tutor']);
  }
  subjectid(id: any) {
    localStorage.setItem('tutorsubject', id);
  }

  // searchBySubject(selectedSubjectId: string) {
  //   if (selectedSubjectId) {
  //     // Perform the search action based on the selected subject ID
  //     localStorage.setItem('tutorsubject', selectedSubjectId);
  //     this.router.navigate(['/find-a-tutor']);
  //   } else {
  //     // Handle the case when no subject is selected
  //     console.log('No subject selected for search');
  //   }
  // }
  onDeliveryMethodChange() {
      localStorage.setItem('job_type', '1');
      this.router.navigate(['/find-a-tutor']);
  }
  onDeliveryMethodChange2() {
      localStorage.setItem('job_type', '2');
      this.router.navigate(['/find-a-tutor']);
  }
  

  onSearchButtonClick(selectedSubject: string, selectedMethod: string) {
    if (selectedSubject) {
      localStorage.setItem('tutorsubject', selectedSubject);
    }
    if (selectedMethod) {
      localStorage.setItem('job_type', selectedMethod);
    }
    this.router.navigate(['/find-a-tutor']);
  }
  

  
  



  isImageShow: number = 1;
  isImageContent1: boolean = false;
  isImageContent2: boolean = false;
  isImageContent3: boolean = false;
  isImageContent4: boolean = false;
  onChangeImageContent(type: any) {
    if (type == 1) {
      this.isImageShow = 1;
      this.isImageContent2 = false;
      this.isImageContent3 = false;
      this.isImageContent4 = false;
      this.isImageContent1 = !this.isImageContent1;
    } else if (type == 2) {
      this.isImageShow = 2;
      this.isImageContent1 = false;
      this.isImageContent3 = false;
      this.isImageContent4 = false;
      this.isImageContent2 = !this.isImageContent2;
    } else if (type == 3) {
      this.isImageShow = 3;
      this.isImageContent1 = false;
      this.isImageContent2 = false;
      this.isImageContent4 = false;
      this.isImageContent3 = !this.isImageContent3;
    } else if (type == 4) {
      this.isImageShow = 4;
      this.isImageContent1 = false;
      this.isImageContent2 = false;
      this.isImageContent3 = false;
      this.isImageContent4 = !this.isImageContent4;
    }
  }
}