import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from '../../services/message.service';
import {PageEvent} from '@angular/material/paginator';

import { CommonService } from 'src/app/services';
@Component({
  selector: 'app-refer-program',
  templateUrl: './refer-program.component.html',
  styleUrls: ['./refer-program.component.scss']
})
export class ReferProgramComponent implements OnInit {length = 50;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  addForm: FormGroup | any;
  contactData: any=[];
  emailerror:any;
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  bodyRequestData:any={};
  LoginUserDetails:any={};
  courseList:any=[];
  InvitationList:any=[];
  categoryId:any;
  globalurl:any;
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,private activatedRoute: ActivatedRoute,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
    this.categoryId = this.activatedRoute.snapshot.paramMap.get("id");
    console.log(this.categoryId);
  }

  isLoginUser:boolean=false;
  ngOnInit(): void {
    this.referral_list();

    this.commonService.GetLoggedIn().subscribe((data:any)=>{
      this.isLoginUser=data;
      console.log('isLoginUser>>>>>>',this.isLoginUser);
    })
    this.commonService.GetLoginUserDetails().subscribe((data:any)=>{
      this.LoginUserDetails=data;
      console.log('isLoginUser>>>>>>',this.isLoginUser);
    })
    console.log('categoryId>>>>',this.categoryId);
    this.bodyRequestData.category_id='';
     this.getTutorList(this.bodyRequestData);
    this.globalurl=`http://localhost:4300/front/?sd=${this.LoginUserDetails.id}`
    // this.getInvitationList();

  }
  
  getTutorList(bodyRequestData:any){
    this.apiService.post('courses_list',this.bodyRequestData).subscribe((data:any) => {
      this.courseList=data.data;
      console.log('courseList>>>>>>>>',this.courseList);
    })
  }
 
  
  allowurl(url:any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  onBookCourse(courseObject:any){
    if(this.isLoginUser){
      var sendRequestData: any = {};
      sendRequestData.course_id = courseObject.id;
      sendRequestData.transaction_id = new Date().getTime();
      sendRequestData.student_id  = this.LoginUserDetails.id;
      sendRequestData.price  = courseObject.amount;
      // sendRequestData.created_by  = this.Schdule_Slot_Date;
      sendRequestData.parents_name  = this.LoginUserDetails.parents_name;
      sendRequestData.student_name  = this.LoginUserDetails.name;
      sendRequestData.course_name  = courseObject.course_name;
      sendRequestData.phone  = this.LoginUserDetails.phone;
      console.log('sendRequestData>>>>>>',sendRequestData);
      // return;
      this.apiService.post('student_subscription', sendRequestData).subscribe((data: any) => {
        if (data.replyCode == "success") {
          this.commonService.SetUpdateProfileChanged(true);
          this.messageService.showSuccess(data.replyMsg);
        } else {
          this.messageService.showError(data.replyMsg);
        }
      })
    }else{
      this.commonService.SetLoginPopup(true);
      // this.messageService.showError(data.replyMsg);
    }
    
  }


  addCourseToCompare(event:any,id:any){
    var value=event.checked;
    if(value){
      this.arrayCourse.push(id)
    }else{
      var removeskilsIndex = this.arrayCourse.map(function (item:any) { return item; }).indexOf(id);
      this.arrayCourse.splice(removeskilsIndex, 1);
    }
  } 

  
  arrayCourse:any=[];
  getCompare(){
    var localData = JSON.stringify(this.arrayCourse)
    localStorage.setItem('compareCourseData',localData);
    console.log('this.arrayCourse>>>>>>>>>>>>',this.arrayCourse);
    this.router.navigate(['/compare-course']);
  }
  email:any='';
  onSubmitBillingAddress(){
    console.log('email>>>>>',this.email);
   
    if(this.email !=''){
      var sendRequestData: any = {};
      sendRequestData.user_id=this.LoginUserDetails.id;
      sendRequestData.name=this.LoginUserDetails.name;
      sendRequestData.last_name=this.LoginUserDetails.last_name;
      sendRequestData.phone=this.LoginUserDetails.phone;
      sendRequestData.id=this.LoginUserDetails.id;
      sendRequestData.user_email=this.LoginUserDetails.email;
      sendRequestData.email=this.email;
      sendRequestData.url=`http://localhost:4300/front/?sd=${this.LoginUserDetails.id}`;

      this.apiService.post('save_user_invitation', sendRequestData).subscribe((data: any) => {
        if (data.replyCode == "success") {
          this.commonService.SetUpdateProfileChanged(true);
          this.messageService.showSuccess(data.replyMsg);
        } else {
          this.messageService.showError(data.replyMsg);
        }
      })
    }else{
      this.emailerror="Email is required";
      this.messageService.showError('Please enter an email addresses.');
    }
  }
 
  // getInvitationList(){
  //   var sendRequestData: any = {};
  //     sendRequestData.user_id=this.LoginUserDetails.id;
  //     this.apiService.post('user_invitation_list', sendRequestData).subscribe((data: any) => {
  //       this.InvitationList=data.data;
        
  //       console.log('table',this.InvitationList);
        
  //     })
  // }
  referral_list(){
    var sendRequestData: any = {};
    var jsonData: any = localStorage.getItem('user');
      var localData = JSON.parse(jsonData)
      sendRequestData.user_id=localData.id;
      
      console.log(localData.id,'localData.id');
      
      this.apiService.post('referral_list', sendRequestData).subscribe((data: any) => {
        this.InvitationList=data.data;
        
        console.log('InvitationList',this.InvitationList);
        
      })
  }
  

  pageEvent: PageEvent | undefined;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }}
}
