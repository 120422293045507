<div class="mb-3 contT">
	<div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Mitt Konto</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
    	<h1 class="float-left"><span class="txt_gray mob_hide">My </span>Monthly Scorecard</h1>
		<div class="float-right"><a href="student-scorecard.htm" class="orng btn7 p-2 radius15">View Daily Scorecard</a></div>
		<p class="clearfix"></p>
    </div>
</div>

<section class="animated3 wow drop_eff5" style="visibility: hidden; animation-name: none;">
    <div class="container fs13">
        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-sm-12 wow fadeInLeft" style="visibility: hidden; animation-name: none;">
                        <div class="list_box p-4">
                            <div class="row">
                                <div class="col-md-12 text-center">
                                    <div class="row justify-content-center pb-4">
                                        <div class="col-md-2">
                                            <p class="cir_img"><img src="assets/images/t1.jpg" alt=""></p>
                                            <p class="fs18 b">Aliyah</p>
                                            <p>Biology Grade-A</p>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="progress">
                                              <div class="barOverflow">
                                                <div class="bar" style="transform: rotate(171deg);"></div>
                                              </div>
                                              <span>70</span>
                                            </div>
                                            <p class="fs18 b">Exceptional</p>
                                            <p>Overall Performance Rating</p>
                                        </div>
                                    </div>                                  
                                </div>
                                    
                                <div class="col-md-3">
                                    <div class="bg_orng p-4">
                                        <p class="b fs16 mb-2">Strength Shown</p>
                                        <p>I'm friendly, caring and dedicated to helping you learn - based on your personality, skills and learning style. I am committed to helping my students reach their goals, and am right there with you every step of the way.</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="bg_orng p-4">
                                        <p class="b fs16 mb-2">Area for Improvement</p>
                                        <p>I'm friendly, caring and dedicated to helping you learn - based on your personality, skills and learning style. I am committed to helping my students reach their goals, and am right there with you every step of the way.</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="bg_orng p-4">
                                        <p class="b fs16 mb-2">Tutor Solutions</p>
                                        <p>I'm friendly, caring and dedicated to helping you learn - based on your personality, skills and learning style. I am committed to helping my students reach their goals, and am right there with you every step of the way.</p>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="bg_orng p-4">
                                        <p class="b fs16 mb-2">Student Solutions</p>
                                        <p>I'm friendly, caring and dedicated to helping you learn - based on your personality, skills and learning style. I am committed to helping my students reach their goals, and am right there with you every step of the way.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-9 p-0">
                                <div class="row">
                                    <div class="col-md-4 p-0">
                                        <div class="list_box p-4">
                                            <p class="b fs16 mb-3">Chapter Progress</p>
                                            sdfsdf
                                        </div>
                                    </div>
                                    <div class="col-md-4 p-0">
                                        <div class="list_box p-4">
                                            <p class="b fs16 mb-3">Homework Grade</p>
                                            <div class="wrapper">
                                            <div class="chart">
                                                <table id="data-table" border="1" cellpadding="10" cellspacing="0" summary="skillset" class="js">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Mindful<br>-ness</th>
                                                            <th scope="col">Engage<br>-ment</th>
                                                            <th scope="col">Willing<br>-ness To Learn</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>        
                                                            <td>90</td>
                                                            <td>50</td>
                                                            <td>80</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            <div id="figure"><h4></h4><ul class="legend"></ul><div class="graph"><ul class="x-axis"><li><span>Mindful-ness</span></li><li><span>Engage-ment</span></li><li><span>Willing-ness To Learn</span></li></ul><ul class="y-axis"><li><span>100%</span></li><li><span>75%</span></li><li><span>50%</span></li><li><span>25%</span></li><li><span>0%</span></li></ul><div class="bars"><div class="bar-group"><div class="bar fig0"><span>90%</span></div></div><div class="bar-group"><div class="bar fig0"><span>50%</span></div></div><div class="bar-group"><div class="bar fig0"><span>80%</span></div></div></div></div></div></div>
                                        </div>

                                        </div>
                                    </div>
                                    <div class="col-md-4 p-0">
                                        <div class="list_box p-4">
                                            <p class="b fs16 mb-3">Exam Grade</p>
                                            <div class="wrapper">
                                            <div class="chart">
                                                <table id="data-table2" border="1" cellpadding="10" cellspacing="0" summary="skillset">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Mindful<br>-ness</th>
                                                            <th scope="col">Engage<br>-ment</th>
                                                            <th scope="col">Willing<br>-ness To Learn</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>        
                                                            <td>90</td>
                                                            <td>50</td>
                                                            <td>80</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            <div id="figure"><h4></h4><ul class="legend"></ul><div class="graph"><ul class="x-axis"><li><span>Mindful-ness</span></li><li><span>Engage-ment</span></li><li><span>Willing-ness To Learn</span></li></ul><ul class="y-axis"><li><span>100%</span></li><li><span>75%</span></li><li><span>50%</span></li><li><span>25%</span></li><li><span>0%</span></li></ul><div class="bars"><div class="bar-group"><div class="bar fig0" style="height: 90%;"><span>90%</span></div></div><div class="bar-group"><div class="bar fig0" style="height: 50%;"><span>50%</span></div></div><div class="bar-group"><div class="bar fig0" style="height: 80%;"><span>80%</span></div></div></div></div></div></div>
                                        </div>

                                        </div>
                                    </div>
                                    
                                    
                                    
                                    <div class="col-md-6 p-0">
                                        <div class="list_box p-4">
                                            <p class="b fs16 mb-3">Tutor Solutions</p>
                                            <p>I'm friendly, caring and dedicated to helping you learn - based on your personality, skills and learning style.I'm friendly, caring and dedicated.</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 p-0">
                                        <div class="list_box p-4">
                                            <p class="b fs16 mb-3">Student Solutions</p>
                                            <p>I'm friendly, caring and dedicated to helping you learn - based on your personality, skills and learning style.I'm friendly, caring and dedicated.</p>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-4 p-0">
                                        <div class="list_box p-4">
                                            <p class="b fs16 mb-3">Student Behavior</p>
                                            dfgdfgfd

                                        </div>
                                    </div>
                                    
                                    <div class="col-md-8 p-0">
                                        <div class="list_box p-4">
                                            <p class="b fs16 mb-3">Student Technical Skills Analysis</p>
                                            <div class="row">
                                                <div class="col-md-3 ac"><div class="pie-chart" data-percentage="70" style="height: 150px; width: 150px;"><span class="pie-chart__percentage" style="color: rgb(244, 123, 40);">70%</span><canvas class="pie-chart__canvas" height="300" width="300"></canvas></div><p>Level of Understanding</p></div>
                                                <div class="col-md-3 ac"><div class="pie-chart" data-percentage="50" style="height: 150px; width: 150px;"><span class="pie-chart__percentage" style="color: rgb(244, 123, 40);">50%</span><canvas class="pie-chart__canvas" height="300" width="300"></canvas></div><p>Memory Retention</p></div>
                                                <div class="col-md-3 ac"><div class="pie-chart" data-percentage="80" style="height: 150px; width: 150px;"><span class="pie-chart__percentage" style="color: rgb(244, 123, 40);">80%</span><canvas class="pie-chart__canvas" height="300" width="300"></canvas></div><p>Analytical Skills</p></div>
                                                <div class="col-md-3 ac"><div class="pie-chart" data-percentage="30" style="height: 150px; width: 150px;"><span class="pie-chart__percentage" style="color: rgb(244, 123, 40);">30%</span><canvas class="pie-chart__canvas" height="300" width="300"></canvas></div><p>Problem Solving</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 p-0">
                                <div class="list_box p-4 clar">
                                    <p class="bdrb p-3">Class #1 <i class="fas fa-arrow-up"></i></p>
                                    <p class="bdrb p-3">Class #2 <i class="fas fa-arrow-down"></i></p>
                                    <p class="bdrb p-3">Class #3 <i class="fas fa-arrow-down"></i></p>
                                    <p class="bdrb p-3">Class #4 <i class="fas fa-arrow-down"></i></p>
                                    <p class="bdrb p-3">Class #5 <i class="fas fa-arrow-down"></i></p>
                                    <p class="bdrb p-3">Class #6 <i class="fas fa-arrow-down"></i></p>
                                    <p class="bdrb p-3">Class #7 <i class="fas fa-arrow-down"></i></p>
                                    <p class="bdrb p-3">Class #8 <i class="fas fa-arrow-down"></i></p>
                                    <p class="bdrb p-3">Class #9 <i class="fas fa-arrow-down"></i></p>
                                    <p class="bdrb p-3">Class #10 <i class="fas fa-arrow-down"></i></p>
                                    <p class="bdrb p-3">Class #11 <i class="fas fa-arrow-down"></i></p>
                                    <p class="p-3">Class #12 <i class="fas fa-arrow-down"></i></p>
                                </div>
                            </div>
                        </div>
                        <!--
                        <div class="list_box p-4 fs13">
                            <div class="row">
                                <div class="col-lg-9 col-md-6 pb-3">
                                    <p>Course Name</p>
                                    <p class="b mt-2 fs18"><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                    <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique.</p>
                                    
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <p class="mt-3"><a href="" class="compare">Download Scorecard</a></p>
                                    <p class="mt-3"><a href=""  data-toggle="modal" data-target="#feed" class="compare">View Feedback</a></p>
                                </div>
                            </div>
                        </div>
                        
                        <div class="list_box p-4 fs13">
                            <div class="row">
                                <div class="col-lg-9 col-md-6 pb-3">
                                    <p>Course Name</p>
                                    <p class="b mt-2 fs18"><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                    <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique.</p>
                                    
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <p class="mt-3"><a href="" class="compare">Download Scorecard</a></p>
                                    <p class="mt-3"><a href=""  data-toggle="modal" data-target="#feed" class="compare">View Feedback</a></p>
                                </div>
                            </div>
                        </div>-->
                        
                        
                    </div>
                    
                    
                  </div>
            </div>
        </div>
    </div>
</section>