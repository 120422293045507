import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ApiService, CommonService, MessageService } from '../services';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-calculate-cost',
  templateUrl: './calculate_cost.component.html',
  styleUrls: ['./calculate_cost.component.scss']
})
export class CalculateCostComponent implements OnInit {
  addForm: FormGroup | any;
  panelList: any = [];
  panelData: any = [];
  pro_price: any = [];
  inquiry_id:any;
  pro_id:any;
  errorF:any=0;
  sqmt:any=0;
  vv:any=0;
  pp:any=0;
  panelCount:number=0;
  priceObject: any = {
    hob_cutout: 0,
    price_sqft: 0,
    sink_cutout: 0,
    supply_install: 0,
    supply_only: 0,
    length: '',
    width: '',
  }
  orderForm: FormGroup | any;
  items: FormArray | any;
  constructor(private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router,public activatedRoute: ActivatedRoute,public commonService:CommonService) {
    this.commonService.SetFooterContent(false);
    this.inquiry_id = this.activatedRoute.snapshot.paramMap.get("id");
    this.pro_id = this.activatedRoute.snapshot.paramMap.get("pro_id");
    console.log(' this.inquiry_id', this.inquiry_id);
    console.log(' this.pro_id', this.pro_id);
  }
  radioSelected:any;
  Type:any=0;
  ngOnInit(): void {
    this.orderForm = new FormGroup({
      items: new FormArray([])
    });
    this.addItem(0);
    this.getPanelList(0,0);
    this.getProductDetails();
  }

  getProductDetails() {
    var self = this;
    var sendRequestData = {
      id: self.pro_id
    };
    self.apiService.post('product_details', sendRequestData).subscribe((res: any) => {
      if (res.replyCode == 'success') {
        this.pro_price = res.data.price;
        
      }
    },
      error => {
        console.log("Oops! Something went wrong!");
      });
  }
  createItem(): FormGroup {
    return this.fb.group({
      type: '',
      value1: false,
      value2: false,
      templateFlag: false,
      templateFlag1: false,
      templateFlag2: false,
      supplyFlag: false,
      supplyFlag1: false,
      supplyFlag2: false,
      sinkFlag: false,
      hobFlag: false,
      drainingFlag: false,
      panel_list: [],
      panel_list_id: '',
      panel_details: {},
      hob_cutout: '',
      draining: '',
      price_sqft: '',
      sink_cutout: '',
      supply_install: '',
      supply_only: '',
      length: ['', [Validators.required]],
      width: ['', [Validators.required]],
    });
  }
  getStatus(objectValue:any,index:any){
    console.log(objectValue)
    console.log(index)
  }
  addItem(index: number): void {
    console.log('index::::::',index);
    this.items = this.orderForm.get('items') as FormArray;
    this.items.push(this.createItem());
    this.panelCount=this.panelCount+1;
    this.getPanelList(this.Type,index)
  }
  allowurl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getPanelList(type: any,index: number) {
    this.Type=type;
    const faControl:any = (<FormArray>this.orderForm.controls['items']).at(index);
    // faControl['controls'].type.setValue(type)
    if(type == 0){
      if(faControl['controls'].value1.value){
        faControl['controls'].value1.setValue(false)
      }else{
        faControl['controls'].value1.setValue(true)
        faControl['controls'].value2.setValue(false)
      }
    }else{
      if(faControl['controls'].value2.value){
        faControl['controls'].value2.setValue(false)
      }else{
        faControl['controls'].value2.setValue(true)
        faControl['controls'].value1.setValue(false)
      }
    } 
    var sendrequestinput: any = { panel_type: type };
    this.apiService.post('web_panel_list', sendrequestinput).subscribe((data: any) => {
      this.panelList = data.data;
      faControl['controls'].panel_list.setValue(this.panelList);
      this.getPanelDetails(data.data[0].id,index)
    });
  }
  getPanelDetails(id: any,index: number) {
    // console.log('index:::::::',index);
    const faControl:any = (<FormArray>this.orderForm.controls['items']).at(index);
    console.log(faControl)
    var sendrequestinput: any = { id: id };
    this.apiService.post('panel_details', sendrequestinput).subscribe((data: any) => {
      this.panelData = data.data;
      faControl['controls'].panel_list_id.setValue(id);
      faControl['controls'].panel_details.setValue(this.panelData);
      faControl['controls'].hob_cutout.setValue(data.data.hob_cutout);
      faControl['controls'].draining.setValue(data.data.draining);
      faControl['controls'].price_sqft.setValue(data.data.price_sqft);
      faControl['controls'].sink_cutout.setValue(data.data.sink_cutout);
      faControl['controls'].supply_install.setValue(data.data.supply_install);
      faControl['controls'].supply_only.setValue(data.data.supply_only);
      this.priceObject.hob_cutout = this.panelData.hob_cutout;
      this.priceObject.draining = this.panelData.draining;
      this.priceObject.price_sqft = this.panelData.price_sqft;
      this.priceObject.sink_cutout = this.panelData.sink_cutout;
      this.priceObject.supply_install = this.panelData.supply_install;
      this.priceObject.supply_only = this.panelData.supply_only;
    })
  }
  onChangetype(type: string, value: any,index: number) {
    const faControl:any = (<FormArray>this.orderForm.controls['items']).at(index);
    if (type == 'template') {
      faControl['controls'].templateFlag.setValue(true);
      faControl['controls'].supplyFlag.setValue(false);
      faControl['controls'].templateFlag1.setValue(true);
      faControl['controls'].supplyFlag1.setValue(false);
      this.priceObject.templateFlag = true;
      this.priceObject.supplyFlag = false;
    }
    if (type == 'supply') {
      faControl['controls'].templateFlag.setValue(false);
      faControl['controls'].supplyFlag.setValue(true);
      faControl['controls'].templateFlag1.setValue(false);
      faControl['controls'].supplyFlag1.setValue(true);
      this.priceObject.templateFlag = false;
      this.priceObject.supplyFlag = true;
    }
    console.log('this.priceObject:::::222', faControl.value);
    this.getTotal();
  }
  onChangeoption(type: string, value: any,index: number) {
    const faControl:any = (<FormArray>this.orderForm.controls['items']).at(index);
    if (type == 'sink') {
      faControl['controls'].sinkFlag.setValue(true);
      this.priceObject.sinkFlag = true;
    }
    if (type == 'hob') {
      faControl['controls'].hobFlag.setValue(true);
      this.priceObject.hobFlag = true;
    }

    if (type == 'draining') {
      faControl['controls'].drainingFlag.setValue(true);
      this.priceObject.drainingFlag = true;
    }

    // console.log('this.priceObject:::::222', this.priceObject);
    this.getTotal();
  }
  onAddLengthWidth(type: string, event: any,index: number) {
    const faControl:any = (<FormArray>this.orderForm.controls['items']).at(index);
    var values: any = (event.target as HTMLInputElement).value
    if (type == 'length') {
      faControl['controls'].length.setValue(values);
      this.priceObject.length = values;
    }
    if (type == 'width') {
      faControl['controls'].width.setValue(values);
      this.priceObject.width = values;
    }
    this.getTotal();
  }
  netAmount: number = 0;
  vat: number = 0;
  gross: number = 0;
 
  getTotal() {
      this.orderForm.value.items.forEach((element:any) => {
        element.netAmount = 0;
        if (element.templateFlag == true) {
          element.netAmount = element.netAmount + element.supply_install
        } else if (element.supplyFlag == true) {
          element.netAmount = element.netAmount + element.supply_only
        }
    
        if (element.sinkFlag == true) {
          element.netAmount = element.netAmount + element.sink_cutout
        } else if (element.hobFlag == true) {
          element.netAmount = element.netAmount + element.hob_cutout
        } if (element.drainingFlag == true) {
          element.netAmount = element.netAmount + element.draining
        }
    
        if (element.length != '' && element.width != '') {
          this.sqmt =(element.length * element.width)/1000000;
          console.log('this.sqmt',this.sqmt);
          element.netAmount = Math.round(element.netAmount + ((this.sqmt) *(this.pro_price) ));
        }
        element.vat = Math.round((element.netAmount * 20) / 100);
        element.gross = Math.round(element.netAmount + element.gross);
        
      });
      this.netAmount=0;
      this.orderForm.value.items.forEach((element:any) => {
        this.netAmount= this.netAmount+element.netAmount;
        
        this.vat= Math.round((element.netAmount * 20) / 100);
        this.gross= Math.round(this.netAmount+this.vat);
      });
      this.getGross();
      console.log('netAmount:::',this.netAmount)
  }
  
  
  onSubmitForm(){
    var self = this;
    console.log(this.orderForm.value.items);
    if (self.orderForm.valid) {
      var sendRequestData=this.orderForm.value.items;
      sendRequestData[0].inquiry_id = self.inquiry_id;
      console.log(sendRequestData);
      var requestUrl;
      
      requestUrl = 'add_panel_enquiry';
      self.apiService.post(requestUrl, sendRequestData).subscribe((res: any) => {
        if (res.replyCode == 'success') {
          // self.addForm.reset();
         // this.messageService.showSuccess(res.replyMsg);
          this.router.navigate(['/thankyou'])
        }
        else {
          this.messageService.showError(res.replyMsg)
        }
      },error => {
          this.messageService.showError('Oops! Something went wrong!')

      });
    }else{
      this.errorF=1;
    }
    
    

  }
  getVat(netAmount: number){
    this.vat= Math.round((netAmount * 20) / 100);
    this.getGross();
    return this.vat;
  }
  getGross(){
    this.gross= Math.round(this.netAmount+this.vat);
  }
}
