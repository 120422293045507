<style>
    .tbox {
        min-height: 330px;
    }
</style>
<div class="mb-3 ptfix">
    <div class="container pt-4 pl-3">
        <ul class="breadcrumb">
            <li><a href="/">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Testimonials</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide">Marble Tops </span>Testimonials</h1>            
    </div>
</div>
<div class="clearfix"></div>


<section class="animated3 wow drop_eff5">
    <div class="container mb-5">
      <div class="row">
              <div class="col-md-6" *ngFor="let tetimonial of homeTestimonial; let i= index">
                  <div class="tbox">
                      <img src="assets/images/tico.png" alt="" class="tico">
                      <p>"{{tetimonial.description}}"</p>
                      <p class="b mt-4">- {{tetimonial.posted_by}}</p>
                  </div>
              </div>
          </div>
      </div>
  </section>