import { MessageService } from './../../services/message.service';
import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/services';


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any=[];
  
  public blogList:any=[];
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
  }

  ngOnInit(): void {

    var sendrequestinput:any={featured:1};
    this.apiService.post('web_blog_list',sendrequestinput).subscribe((data:any) => {
      this.blogList=data.data;
    })


  }

  public blogDetails(id:any){
    
    this.router.navigate(['/blog-details'], { queryParams: { id: btoa(id) }});

  }

   allowurl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
