import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';

import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-hot-product',
  templateUrl: './hot_product.component.html',
  styleUrls: ['./hot_product.component.scss']
})
export class HotProductComponent implements OnInit {
  flag:any=null;
  categoryId=null;
  homeBlog: any=[];
  resData:any=null;
  catData:any=null;
  constructor(public apiService :ApiService,public sanitizer: DomSanitizer, public activatedRoute: ActivatedRoute,private title: Title, private meta: Meta,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
  }

  ngOnInit(): void {
    this.getDataById();
  }
  allowurl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getDataById() {
    var self = this;
    var sendRequestData = {
      isAdmin:0,
      hot:1
    };
    self.apiService.post('product_list', sendRequestData).subscribe((res: any) => {
      if (res.replyCode == 'success') {
        this.resData = res.data;
      }
    },
    error => {
      console.log("Oops! Something went wrong!");
    });

  }

}
