<!--footer-->

<footer>
    <div class="footer-style-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="logo-footer text-end lts">
                        <h4>Skolbryggan</h4>
                        <ul>
                            <li><a href="mailto:{{footerData?.support_email}}"><span class="fa fa-envelope font-s-18"></span> {{footerData?.support_email}}</a></li>
                            <li><a href="tel:020899530"><span class="fa fa-phone font-s-18"></span>{{footerData?.support_contact}}</a></li>
                        </ul>
                        <div class="Parent-Information">
                            <h5>Telefontid vardagar 08:00 – 17:00</h5>
                        </div>
                        <a href="./"><img class="" src="assets/img/logo.png" alt="Footer Logo" /></a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="logo-footer rt">
                        <ul>
                            <li><a href="javascript:void(0);" routerLink="/cms/policies-regulations">Allmänna villkor</a></li>
                            <li><a href="javascript:void(0);" routerLink="/cms/web-privacy-policy">Integritetspolicy</a></li>
                            <li><a href="javascript:void(0);" routerLink="/contact-us">Kontakta oss</a></li>
                            <li><a href="javascript:void(0);" routerLink="/find-a-tutor">Hitta en Studiementor</a></li>
                            <li><a href="javascript:void(0);" routerLink="/cms/whyus">Varför oss</a></li>
                            <li><a href="javascript:void(0);" routerLink="/cms/about-us">Om oss</a></li>
                            <li><a href="javascript:void(0);" routerLink="/blog">Blogg</a></li>
                            <li><a href="javascript:void(0);" routerLink="/faqs">FAQ</a></li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="logo-footer Social-Media">
                       <h4>Follow Skolbryggan on Social Media</h4>
                       <ul class="Socia-link">
                         <li><a href="#"><img class="" src="assets/img/facebook.png" alt="Social Media" /></a></li>
                         <li><a href="#"><img class="" src="assets/img/twitter.png" alt="Social Media" /></a></li>
                         <li><a href="#"><img class="" src="assets/img/instagram.png" alt="Social Media" /></a></li>
                         <li><a href="#"><img class="" src="assets/img/youtube.png" alt="Social Media" /></a></li>
                        
                       </ul>
                    </div>
                    <div class="">
                       <p class="copyright">© 2023 Skolbryggan - All Rights Reserved </p>
                     </div>
                </div> -->



                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="logo-footer Social-Media">
                       <h4>Följ oss på social medier</h4>
                       <ul class="Socia-link">
                         <li><a href="{{footerData?.fb_link}}"><img class="" src="assets/img/facebook.png" alt="Social Media" /></a></li>
                         <!-- <li><a href="{{footerData?.twitter_link}}"><img class="" src="assets/img/twitter.png" alt="Social Media" /></a></li> -->
                         <li><a href="{{footerData?.insta_link}}"><img class="" src="assets/img/instagram.png" alt="Social Media" /></a></li>
                         <!-- <li><a href="{{footerData?.video_link}}"><img class="" src="assets/img/youtube.png" alt="Social Media" /></a></li> -->
                         <li><a href="{{footerData?.indeed_link}}"><img class="" src="assets/img/whatsapp.png" alt="Social Media" /></a></li>
                       </ul>
                    </div>
                    <div class="">
                       <p class="copyright">{{footerData?.copyright_text}}</p>
                     </div>
                </div>






            </div>
        </div>
    </div>
</footer>