<div class="clearfix"></div>
<!--head+filter-->
<div class="mb-3 contT">
    <div class="container pl-3">
        <div class="row">
            <div class="col-md-12">
                <ul class="breadcrumb">
                    <li><a href="javascript:void(0);" routerLink="/">Home</a></li>
                    <li class="active">Bli en Studiementor</li>
                </ul>
                <div class="_Become-Tutor pl-3 pr-3">
                    <h1><span class="txt_gray mob_hide"></span>Bli en Studiementor</h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="clearfix"></div>
<!--head+filter end-->

<!-- Content -->
<section class="animated3 wow drop_eff5">
    <div class="container mb-5">

        <div class="be-ban">
            <div class="row">
                <div class="col-md-4 col-lg-8 col-sm-12"></div>
                <div class="col-md-8 col-lg-4 col-sm-12">
                    <div class="list_box p-5 bgw">
                        <p class="b fs22" [innerHtml]="contentBlockData[9]?.title"></p>
                        <p class="mt-4 fs16" [innerHtml]="contentBlockData[9]?.content"></p>
                        <p class="mt-4"><a href="javascript:void(0);" class="style1 bg"
                                (click)="onOpenRegisterPopup()"> Bli en Studiementor</a></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center">
                <h3 class="phead" [innerHtml]="contentBlockData[10]?.title"></h3>
                <p [innerHtml]="contentBlockData[10]?.content"></p>
            </div>
            <p class="clearfix"></p>
        </div>

        <div class="row mt-4">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="Become-A-Tutor">
                    <img src="assets/images/tutor/agreement.png" class="mb-3" alt="">
                    <h5 class="b" [innerHtml]="contentBlockData[11]?.title"> </h5>
                    <p class="mt-3" [innerHtml]="contentBlockData[11]?.content">
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="Become-A-Tutor">
                    <img src="assets/images/tutor/money-bag.png" class="mb-3" alt="">
                    <h5 class="b" [innerHtml]="contentBlockData[12]?.title"> </h5>
                    <p class="mt-3" [innerHtml]="contentBlockData[12]?.content">
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="Become-A-Tutor">
                    <img src="assets/images/tutor/choice.png" class="mb-3" alt="">
                    <h5 class="b" [innerHtml]="contentBlockData[13]?.title"> </h5>
                    <p class="mt-3" [innerHtml]="contentBlockData[13]?.content"></p>
                </div>
            </div>


            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="Become-A-Tutor">
                    <img src="assets/images/tutor/trophy.png" class="mb-3" alt="">
                    <h5 class="b" [innerHtml]="contentBlockData[14]?.title"> </h5>
                    <p class="mt-3" [innerHtml]="contentBlockData[14]?.content"> </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="Become-A-Tutor">
                    <img src="assets/images/tutor/customer-service.png" class="mb-3" alt="">
                    <h5 class="b" [innerHtml]="contentBlockData[15]?.title"> </h5>
                    <p class="mt-3" [innerHtml]="contentBlockData[15]?.content"></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="Become-A-Tutor">
                    <img src="assets/images/tutor/reward.png" class="mb-3" alt="">
                    <h5 class="b" [innerHtml]="contentBlockData[16]?.title"> </h5>
                    <p class="mt-3" [innerHtml]="contentBlockData[16]?.content">
                    </p>
                </div>
            </div>

        </div>

        <div class="row justify-content-center mt-5">
            <div class="col-md-12">
                <h1 class="text-center">Hur du blir en studiementor</h1>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft">
                <div class="list_box How-To-Become">
                    <img src="assets/img/step1.jpg" class="img-fluid mb-3" alt="">
                    <p class="b fs22 ac"><strong>1</strong></p>
                    <p class="p-3 text-center" [innerHtml]="contentBlockData[17]?.content"></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft">
                <div class="list_box How-To-Become">
                    <img src="assets/img/step2.jpg" class="img-fluid mb-3" alt="">
                    <p class="b fs22 ac"><strong>2</strong></p>
                    <p class="p-3 text-center" [innerHtml]="contentBlockData[18]?.content">
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft">
                <div class="list_box How-To-Become">
                    <img src="assets/img/step3.jpg" class="img-fluid mb-3" alt="">
                    <p class="b fs22 ac"><strong>3</strong></p>
                    <p class="p-3 text-center" [innerHtml]="contentBlockData[19]?.content"></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft">
                <div class="list_box How-To-Become">
                    <img src="assets/img/step4.jpg" class="img-fluid mb-3" alt="">
                    <p class="b fs22 ac"><strong>4</strong></p>
                    <p class="p-3 text-center" [innerHtml]="contentBlockData[20]?.content"></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 wow fadeInLeft">
                <p class="mt-4" style="text-align: center;"><a href="javascript:void(0);" class="style1 bg"
                        (click)="onOpenRegisterPopup()">Bli en Studiementor</a></p>
            </div>
        </div>

        <!--faq-->
        <div class="mt-5 mb-5">
            <div class="container">
                <h1 class="text-center mb-3 animated2 drop_eff2">Vanliga frågor</h1>
                <p class="text-center fs16 mb-4">Våra kunders mest ställda frågor.
                </p>
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <section class="faq-section">
                            <div class="faq" id="accordion">
                                <div class="card" *ngFor="let faq of FaqList; let i= index">
                                    <div class="card-header" id="faqHeading-{{i}}">
                                        <div class="mb-0">
                                            <h5 class="faq-title" data-toggle="collapse"
                                                [attr.data-target]="'#faqCollapse-' + i" data-aria-expanded="true"
                                                data-aria-controls="faqCollapse">
                                                {{faq?.question}}
                                                <img src="assets/images/daro.png" alt="" class="daro1" />
                                            </h5>
                                        </div>
                                    </div>
                                    <div id="faqCollapse-{{i}}" class="collapse" aria-labelledby="faqHeading"
                                        data-parent="#accordion">
                                        <div class="card-body">
                                            <p>
                                                {{faq?.answer}}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>
                    <div class="col-md-12 ac">
                        <p><a routerLink="/faqs" href="javascript:void(0);" class="morebtn style1" style="text-transform: inherit !important;">Visa alla</a></p>
                        <!-- <p><span class="sm">s</span></p> -->
                    </div>
                </div>
            </div>
        </div>
        <!--faq end-->

    </div>
</section>