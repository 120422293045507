<div class="top=main-section contT">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <ul class="breadcrumb">
          <li><a href="/">Home</a></li>
          <li class="active">Mitt Konto</li>
        </ul>
      </div>
      <div class="col-md-12">
        <h1><span class="txt_gray mob_hide"></span>{{ Content_Type ? Content_Type : "" }}</h1>
        <p class="text-right" *ngIf="Content_Type == 'Subjects'">
          <a href="javascript:void(0);" (click)="onOpenSubjectModal()" class="compare">
            <i class="fas fa-plus"></i> LÄGG TILL ÄMNE</a>
        </p>
        <p class="text-right" *ngIf="Content_Type == 'Manage Courses'">
          <a href="javascript:void(0);" (click)="onAddCourse()" class="compare">
            <i class="fas fa-plus"></i> LÄGG Kurs</a>
        </p>
        <p class="text-right" *ngIf="Content_Type == 'Exam Question Paper'">
          <a href="javascript:void(0);" (click)="onAddQuestion()" class="compare">
            <i class="fas fa-plus"></i> LÄGG Question</a>
        </p>
      </div>
    </div>
  </div>
</div>



<div class="clearfix"></div>
<section class="animated3 wow drop_eff5 mb-5" *ngIf="userData">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-3 col-md-12 col-sm-12 wow fadeInLeft">
        <div class="lft_link1">
          <ul class="nav nav-pills mb-3 d-block hover-effect-student" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active"
                id="pills-tab1-tab" data-toggle="pill" href="#pills-tab1" role="tab" aria-controls="pills-tab1"
                aria-selected="true" (click)="onChangeTypeContent('Redigera och Uppdatera Profil')"><i
                  class="fa fa-pencil"></i>Min Profil</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-tab2-tab"
                data-toggle="pill" href="#pills-tab2" role="tab" aria-controls="pills-tab2" aria-selected="false"
                (click)="onChangeTypeContent('Mina Klasser')"><i class="fas fa-edit"></i>Mina Klasser</a>
            </li>

            <!-- <li class="nav-item" role="presentation">
              <a href="javascript:void(0);" href="/front/chat" (click)="onChangeTypeContent('Group Messages')"
                target="_blank"><i class="fas fa-comments"></i>Messages</a>
            </li> -->

            <li class="nav-item" role="presentation">
              <a class="nav-link"  id="pills-tab5-tab"
                data-toggle="pill" href="#pills-tab5" role="tab" aria-controls="pills-tab5" aria-selected="false"
                (click)="onChangeTypeContent(' Låt oss göra ett Quiz')"><i class="fas fa-comments"></i> Låt oss göra ett Quiz</a>
            </li>

            <li class="nav-item" role="presentation">
              <a class="nav-link" 
                aria-controls="pills-tab6" aria-selected="false" href="javascript:void();" routerLink="/my-scorecard" >
                <i class="fas fa-chart-pie"></i>Mitt Resultatkort</a>
            </li>

            <li class="nav-item" role="presentation">
              <a class="nav-link"  id="pills-tab66-tab"
                data-toggle="pill" href="#pills-tab66" (click)="onChangeTypeContent('Transaktionsdetaljer')" role="tab"
                aria-controls="pills-tab66" aria-selected="false"><i
                  class="fa fa-solid fa-graduation-cap"></i>Transaktionsdetaljer</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-tab6-tab"
                data-toggle="pill" href="#pills-tab6" role="tab" aria-controls="pills-tab6" aria-selected="false"
                (click)="onChangeTypeContent('Klassrumsdemo')"><i class="fa fa-solid fa-graduation-cap"></i>Klassrumsdemo</a>
            </li>

            <li class="nav-item" role="presentation">
              <a class="nav-link"  id="pills-tab4-tab"
                href="javascript:void();" routerLink="/contact-us"><img _ngcontent-nwn-c175="" alt=""
                  onerror="this.onerror=null;this.src='assets/images/profile-demo.png';"
                  style="width: 18px; opacity: 0.5"
                  src="https://suited-admin.mrmmbs.com/node/uploads/attachment-1674819240238.png" />
                Support</a>
            </li>

            <!-- <li class="nav-item custam-code-st1" role="presentation">
              <a class="nav-link">
                <img _ngcontent-nwn-c175="" alt=""
                  onerror="this.onerror=null;this.src='assets/images/profile-demo.png';"
                  style="width: 18px; opacity: 0.5" src="../../../../assets/images/comment.png" /> Give Feedback</a>
            </li> -->
            <a href="javascript:void(0);" (click)="onOpenLogout()"><i class="fas fa-sign-out-alt"></i>Logga Ut</a>
          </ul>
        </div>
      </div>



      
      <div class="col-lg-9 col-md-12 col-sm-12 wow fadeInLeft">
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-tab1" role="tabpanel" aria-labelledby="pills-tab1-tab">
            <div class="list_box1">
              <div class="tab_link">
                <p class="for_m dd_next hand">
                  Edit Profile <i class="fas fa-caret-down"></i>
                </p>
                <div class="for_d">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                        aria-controls="pills-home" aria-selected="true">Personuppgifter</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                        aria-controls="pills-profile" aria-selected="false">Föräldrauppgifter</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-password-tab" data-toggle="pill" href="#pills-password" role="tab"
                        aria-controls="pills-password" aria-selected="false">Lösenordsdetaljer</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab"
                        aria-controls="pills-contact" aria-selected="false">Profilfoto</a>
                    </li>

                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="Billing-Address-tab" data-toggle="pill" href="#Billing-Address" role="tab"
                        aria-controls="Billing-Address" aria-selected="false">Fakturaadress</a>
                    </li>

                    <!-- <li class="nav-item" role="presentation">
                      <a class="nav-link" id="Payment-Details-tab" data-toggle="pill" href="#Payment-Details" role="tab"
                        aria-controls="Payment-Details" aria-selected="false">Betalningsdetaljer</a>
                    </li> -->
                  </ul>
                </div>
              </div>


              <div class="tab_cont tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <form [formGroup]="createuser6">
                    <div class="emp_area">
                      <div class="row">
                        <div class="form-group col-md-6">
                          <label for="">Förnamn <span class="red">*</span></label>
                          <input placeholder="Vänligen skriv ditt förnamn" type="text" [(ngModel)]="userData.name"
                            (keyup)="onSearchKey2($event.target,'name')" [ngModelOptions]="{ standalone: true }"
                            required />
                          <span class="warning">{{ namefield }} </span>
                        </div>

                        <div class="form-group col-md-6">
                          <label for="">Efternamn <span class="red">*</span></label>
                          <input placeholder="Vänligen skriv ditt efternamn" type="text" [(ngModel)]="userData.last_name"
                            (keyup)="onSearchKey2($event.target,'last_name')" [ngModelOptions]="{ standalone: true }" />
                          <span class="warning">{{ lastname }} </span>
                        </div>

                        <div class="form-group col-md-6">
                          <label for="">Födelsedatum</label>
                          <input type="date" [(ngModel)]="userData.dob" [ngModelOptions]="{ standalone: true }"
                            (keyup)="onSearchKey2($event.target,'dob')" />
                          <span class="warning">{{ dob }} </span>
                        </div>

                        <div class="form-group col-md-6">
                          <label for="">E-post <span class="red">*</span></label>
                          <input placeholder="Vänligen ange ditt lösenord" class="form-control" type="email"
                            [(ngModel)]="userData.email" [ngModelOptions]="{ standalone: true }"
                            (keyup)="onSearchKey2($event.target,'email')" />
                          <span class="warning">{{ email }} </span>
                        </div>

                        <div class="form-group col-md-6">
                          <label for="">Mobil <span class="red">*</span></label>
                          <input placeholder="Please type your mobile number" class="form-control" type="tel"
                            (keyup)="onSearchKey3($event.target.value,'phone')" [(ngModel)]="userData.phone"
                            [ngModelOptions]="{ standalone: true }" />
                          <span class="warning">{{ phone }} </span>
                        </div>

                        <div class="form-group col-md-6">
                          <label for="">Land</label>
                          <select class="form-control" name="" id="" [(ngModel)]="userData.country"
                            [ngModelOptions]="{ standalone: true }">
                            <option value="" selected>välj</option>
                            <option [value]="countryData.id" *ngFor="let countryData of loadcountryList">
                              {{ countryData.name }}
                            </option>
                          </select>
                        </div>

                        <div class="form-group col-md-12">
                          <label for="">Om mig</label>
                          <textarea placeholder="Dela gärna lite om dig själv och dina stora drömmar" name=""
                            id="" cols="30" class="new-styleform" rows="5" [(ngModel)]="userData.bio"
                            [ngModelOptions]="{ standalone: true }"></textarea>
                        </div>
                        <div class="col-md-12">
                          <p class="pull-right"><a href="javascript:void(0);" class="style1 bg"
                              (click)="onUpdateProfile()">SPARA & UPPDATERA</a></p>
                        </div>

                      </div>

                    </div>
                  </form>
                </div>
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <form [formGroup]="createuser16"
                    *ngIf="formopen==true || userData.parents_name =='' && userData.parent_last_name =='' && userData.parent_email =='' && userData.parent_phone =='' ">
                    <div class="emp_area">

                      <div class="form-group">
                        <label for="">Förnamn</label>
                        <input type="text" placeholder="Please type your parent’s first name if applicable"
                          formControlName="parents_name" class="form-control">
                      </div>

                      <div class="form-group">
                        <label for="">Efternamn</label>
                        <input type="text" placeholder="Please type your parent’s first name if applicable"
                          formControlName="parent_last_name" class="form-control" />
                      </div>

                      <div class="form-group">
                        <label for="">E-post</label>
                        <input type="text" type="email" placeholder="Please type your parent’s email if applicable"
                          formControlName="parent_email" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label for="">Mobil</label>
                        <input type="text" type="number" placeholder="Please type your mobile number"
                          formControlName="parent_phone" class="form-control" />
                      </div>
                      <div class="col-md-12">
                        <p class="pull-right"><a href="javascript:void(0);" class="style1 bg" (click)="addparent()"> SPARA & UPPDATERA</a></p>
                      </div>
                    </div>
                  </form>

                  <table
                    *ngIf=" formopen==false && (userData.parents_name!='' || userData.parent_last_name!='' || userData.parent_email!='' || userData.parent_phone!='' )"
                    class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Förnamn</th>
                        <th>Efternamn</th>
                        <th>E-post</th>
                        <th>Mobil</th>
                        <th>Åtgärd</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{userData.parents_name}}</td>
                        <td>{{userData.parent_last_name}}</td>
                        <td>{{userData.parent_email}}</td>
                        <td>{{userData.parent_phone}}</td>
                        <td>
                          <a href="javascript:void(0);" (click)="update_parent()"><i class="fas fa-edit"></i></a> &nbsp;
                          <a href="javascript:void(0);" (click)="delete_parent()"><i class="fas fa-trash-alt"></i></a>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
                <div class="tab-pane fade" id="pills-password" role="tabpanel" aria-labelledby="pills-password-tab">
                  <form [formGroup]="createuser">
                    <div class="emp_area">
                      <div class="name_feild">
                        <p>Gammalt Lösenord</p>
                        <div>
                          <input type="text" formControlName="current_password" [ngClass]="{
                              'is-invalid':
                                submitteed && f.current_password.errors
                            }" class="form-control" (keyup)="clear_msg()" />
                          <span class="warning">{{incor_pass}}</span>

                          <div *ngIf="
                              (f.current_password.errors &&
                                f.current_password.touched) ||
                              (f.current_password.errors && submitteed)
                            " class="errorMsg">
                            <div class="warning" *ngIf="f.current_password.errors.mustMatch">
                              Password do not match.
                            </div>
                          </div>
                          <div *ngIf="
                              f.current_password.invalid &&
                              (f.current_password.dirty ||
                                f.current_password.touched)
                            ">
                            <div class="warning" *ngIf="f.current_password.errors.required">
                              Old password is required.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="loc_feild">
                        <p>Nytt Lösenord</p>
                        <div>
                          <input type="text" formControlName="password" [ngClass]="{
                              'is-invalid': submitteed && f.password.errors
                            }" class="form-control" />
                          <div *ngIf="
                              f.password.invalid &&
                              (f.password.dirty || f.password.touched)
                            ">
                            <div class="warning" *ngIf="f.password.errors.required">
                              New password is required.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="loc_feild">
                        <p>Bekräfta nytt lösenord</p>
                        <div>
                          <input type="text" formControlName="reenterpassword" [ngClass]="{
                              'is-invalid':
                                submitteed && f.reenterpassword.errors
                            }" class="form-control" />
                          <div *ngIf="
                              (f.reenterpassword.errors &&
                                f.reenterpassword.touched) ||
                              (f.reenterpassword.errors && submitteed)
                            " class="errorMsg">
                            <div class="warning" *ngIf="f.reenterpassword.errors.mustMatch">
                              The password and Re-Enter password do not match.
                            </div>
                          </div>
                          <div *ngIf="
                              f.reenterpassword.invalid &&
                              (f.reenterpassword.dirty ||
                                f.reenterpassword.touched)
                            ">
                            <div class="warning" *ngIf="f.reenterpassword.errors.required">
                              This field is required.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <p class="pull-right"><a href="javascript:void(0);" class="style1 bg"
                            (click)="onChangePassword()"> SPARA & UPPDATERA</a></p>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <form>
                    <div class="emp_area">
                      <div class="name_feild bdrb">
                        <div class="row justify-content-center">
                          <div class="col-md-3 text-center pb-4">
                            <div class="profile-photo-pick">
                              <img src="assets/images/p-blank.jpg" alt="" class="m-auto round img-fluid"
                                *ngIf="uploadedImagesData == ''" />
                              <img [src]="
                                allowurl(
                                  apiService.imageBase + uploadedImagesData
                                )
                              " alt="" class="m-auto img-fluid" *ngIf="uploadedImagesData != ''" />
                              <p class="mt-3">Lägg till din profilbild</p><span class="tooltip-icon" data-toggle="tooltip"
                                data-placement="right"
                                title="Please upload photos on the following format .jpeg, jpg or .png">
                                <i class="fas fa-info-circle"></i>
                              </span>
                              <p class="mt-2">
                                <button class="style1">
                                  <i class="fas fa-plus"></i><input type="file" accept="image/*"
                                    (change)="onSelectFile($event.target)" #file id="file" style="
                                    opacity: 0;
                                    position: absolute;
                                    left: 1px;
                                  " />
                                  LÄGG TILL FOTO
                                </button>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <p class="pull-right" style="text-align: center"><a href="javascript:void(0);" class="style1 bg"
                            (click)="onSubmitPhotoVideo()">SPARA & UPPDATERA</a></p>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="tab-pane fade" id="Billing-Address" role="tabpanel" aria-labelledby="Billing-Address-tab">
                  <form [formGroup]="createuserb">
                    <div class="emp_area">

                      <div class="row">
                        <div class="form-group col-md-6">
                          <label for="">Förnamn</label>
                          <input placeholder="Vänligen skriv ditt förnamn" type="text" formControlName="b_name"
                            class="form-control" [ngClass]="{
                              'is-invalid': submittedb && fbi.b_name.errors
                            }" />
                        </div>
                        <div class="form-group col-md-6">
                          <label for="">Efternamn</label>
                          <input placeholder="Vänligen skriv ditt efternamn" type="text" formControlName="b_last_name"
                            class="form-control" [ngClass]="{
                            'is-invalid': submittedb && fbi.b_last_name.errors
                          }" />
                        </div>

                        <div class="form-group col-md-6">
                          <label for="">Gatuadress</label>
                          <input placeholder="Vänligen skriv din adress" type="text" formControlName="billing_address"
                            class="form-control" [ngClass]="{
                              'is-invalid':
                                submittedb && fbi.billing_address.errors
                            }" />
                        </div>

                        <div class="form-group col-md-6">
                          <label for="">Postnummer</label>
                          <input placeholder="Vänligen skriv ditt postnummer" type="text" formControlName="bpcode"
                            class="form-control" [ngClass]="{
                              'is-invalid': submittedb && fbi.bpcode.errors
                            }" />
                        </div>

                        <div class="form-group col-md-6">
                          <label for="">Stad</label>
                          <input placeholder=" Vänligen skriv din stad" type="text" formControlName="bcity"
                            class="form-control" [ngClass]="{
                              'is-invalid': submittedb && fbi.bcity.errors
                            }" />
                        </div>

                        <div class="form-group col-md-6">
                          <label for="">Stat/Län</label>
                          <input placeholder="Vänligen skriv din stat eller län" type="text" formControlName="bstate"
                            class="form-control" [ngClass]="{
                              'is-invalid': submittedb && fbi.bstate.errors
                            }" />
                        </div>

                        <div class="form-group col-md-12">
                          <label for="">Land</label>
                          <input placeholder="Vänligen skriv ditt land" type="text" formControlName="bcountry"
                            class="form-control" [ngClass]="{
                              'is-invalid': submittedb && fbi.bcountry.errors
                            }" />
                        </div>
                        <div class="col-md-12">
                          <p class="pull-right"><a href="javascript:void(0);" class="style1 bg"
                              (click)="onSubmitBillingAddress()"> SPARA & UPPDATERA</a></p>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>

                <!-- <div class="tab-pane fade" id="Payment-Details" role="tabpanel" aria-labelledby="Payment-Details-tab">
                  <form [formGroup]="bankdetail">
                    <div class="emp_area">
                      <div class="name_feild">
                        <p class="">Account Number</p>
                        <p>
                          <input type="number" max="20" formControlName="acc_number" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted15 && f17.acc_number.errors
                            }" />
                        </p>
                      </div>
                      <div class="name_feild">
                        <p class="">Sort Code</p>
                        <p>
                          <input type="number" max="20" formControlName="bank_sort_code" class="form-control" [ngClass]="{
                              'is-invalid':
                                submitted15 && f17.bank_sort_code.errors
                            }" />
                        </p>
                      </div>
                      <div class="name_feild">
                        <p class="">IBAN</p>
                        <p>
                          <input type="text" max="20" formControlName="iban" class="form-control" [ngClass]="{
                              'is-invalid': submitted15 && f17.iban.errors
                            }" />
                        </p>
                      </div>
                      <div class="name_feild">
                        <p class="">BIC</p>
                        <p>
                          <input type="text" max="20" formControlName="bic" class="form-control" [ngClass]="{
                              'is-invalid': submitted15 && f17.bic.errors
                            }" />
                        </p>
                      </div>
                      <div class="col-md-12">
                        <p class="pull-right"><a href="javascript:void(0);" class="style1 bg"
                            (click)="onBankdetails()">Save
                            & Update</a></p>
                      </div>
                    </div>
                  </form>
                </div> -->
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="pills-tab2" role="tabpanel" aria-labelledby="pills-tab2-tab">
            <div class="list_box1">
              <div class="tab_link">
                <p class="for_m dd_next hand">
                  Edit Profile <i class="fas fa-caret-down"></i>
                </p>
                <div class="for_d">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a class="nav-link active" id="pills-lesson1-tab" data-toggle="pill" href="#pills-lesson1"
                        role="tab" aria-controls="pills-lesson1" aria-selected="true">Kommande</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-lesson2-tab" data-toggle="pill" href="#pills-lesson2" role="tab"
                        aria-controls="pills-lesson2" aria-selected="false">Slutförda</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-lesson3-tab" data-toggle="pill" href="#pills-lesson3" role="tab"
                        aria-controls="pills-lesson3" aria-selected="false">Avbrutna</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="tab_cont tab-content" id="pills-tabContent">
                <div class="row mb-3">
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <div class="edt-profile-design">
                      <label> Startdatum</label>
                      <input type="date" style="width: 100%" class="form-control-design1"
                        [ngModel]="startDate | date : 'yyyy-MM-dd'" (ngModelChange)="setDate('start', $event)"
                        name="startDate" />
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    <div class="edt-profile-design">
                      <label> Startdatum</label>
                      <input type="date" style="width: 100%" class="form-control-design1" [ngModel]="endDate"
                        (ngModelChange)="setDate('end', $event)" name="endDate" />
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                    <div class="edt-profile-design">
                      <select name="" id="" class="form-select" (change)="onChangetime($event.target)">
                        <option value="" selected>välj</option>
                        <option value="08:00:00 09:00:00" selected>
                          08:00:00 - 09:00:00
                        </option>
                        <option value="09:00:00 10:00:00" selected>
                          09:00:00 - 10:00:00
                        </option>
                        <option value="10:00:00 11:00:00" selected>
                          10:00:00 - 11:00:00
                        </option>
                        <option value="11:00:00 12:00:00" selected>
                          11:00:00 - 12:00:00
                        </option>
                        <option value="12:00:00 13:00:00" selected>
                          12:00:00 - 13:00:00
                        </option>
                        <option value="13:00:00 14:00:00" selected>
                          13:00:00 - 14:00:00
                        </option>
                        <option value="14:00:00 15:00:00" selected>
                          14:00:00 - 15:00:00
                        </option>
                        <option value="15:00:00 16:00:00" selected>
                          15:00:00 - 16:00:00
                        </option>
                        <option value="16:00:00 17:00:00" selected>
                          16:00:00 - 17:00:00
                        </option>
                        <option value="17:00:00 18:00:00" selected>
                          17:00:00 - 18:00:00
                        </option>
                        <option value="18:00:00 19:00:00" selected>
                          18:00:00 - 19:00:00
                        </option>
                        <option value="19:00:00 20:00:00" selected>
                          19:00:00 - 20:00:00
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="tab-pane fade show active" id="pills-lesson1" role="tabpanel"
                  aria-labelledby="pills-home-tab">
                  <div class="row py-3">
                    <div class="col-lg-12 wow fadeInLeft">
                      <div class="My-Earning" *ngFor="
                          let myclasslist of MyClass_upcoming
                            | paginate
                              : {
                                  itemsPerPage: bodyDataRequestu['limit'],
                                  currentPage: pu,
                                  totalItems: total_count_upcoming,
                                  id:'u'
                                };
                          let i = index
                        ">
                        <table class="table table-bordered" *ngIf="
                            myclasslist.status == 1 &&
                            myclasslist.hostLink != null
                          ">
                          <thead>
                            <tr>
                              <th>Lärarnamn</th>
                              <th>Subject</th>

                              <th>Bokat datum</th>
                              <th>Betalning</th>
                              <th *ngIf="myclasslist.class_type == 0">
                                Belopp
                              </th>
                              <th *ngIf="myclasslist.class_type == 1">
                                Type
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ myclasslist.teacher_name }}</td>
                              <td>{{ myclasslist.subjectname }}</td>
                              <td>
                                {{
                                myclasslist.schedule_slot_date
                                | date : "EEEE,MMMM,d, y"
                                }}
                                - {{ myclasslist.time_from }}
                              </td>
                              <td>Paid</td>
                              <td *ngIf="myclasslist.class_type == 0">
                                {{ myclasslist?.rate || "0" }} SEK 
                              </td>
                              <td *ngIf="myclasslist.class_type == 1">
                                Demolektion
                              </td>
                            </tr>
                            <!-- <tr *ngIf="myclasslist.class_type == 0">
                              <div class="btn-group impwidg" role="group" aria-label="Basic outlined example">
                                <button *ngIf="myclasslist.payment_status != 0" type="button"
                                  class="btn btn-outline-primary">
                                  <a href="https://merithub.com/cjiau685utj49marj5s0/sessions/view/{{
                                    myclasslist?.classId
                                  }}/{{ myclasslist?.commonParticipantLink }}" target="_blank" class="">GÅ MED</a>
                                </button>
                                <p>https://merithub.com/cjiau685utj49marj5s0/sessions/view/{{
                                  myclasslist?.classId
                                }}/{{ myclasslist?.commonParticipantLink }}</p>
                                <button type="button" class="btn btn-outline-primary">
                                  <a href="javascript:void(0);" (click)="onsub(myclasslist)" class="">Reschedule</a>
                                </button>
                                <button type="button" class="btn btn-outline-primary">
                                  <a href="javascript:void(0);" class=""
                                    (click)="onCancelClassPopup(myclasslist)">Cancel &amp; Refund</a>
                                </button>
                              </div>
                            </tr> -->
                            <tr *ngIf="myclasslist.class_type == 0">
                              <div class="btn-group" role="group" aria-label="Basic outlined example">
                                <button type="button" class="btn btn-outline-primary">
                                  <a href="https://merithub.com/cjiau685utj49marj5s0/sessions/view/{{
                                    myclasslist?.classId
                                  }}/{{ myclasslist?.commonParticipantLink }}" target="_blank" class="">GÅ MED</a>
                                </button>
                                <!-- <button type="button" class="btn btn-outline-primary">
                                  <a href="javascript:void(0);" (click)="onsub(myclasslist)" class="">Reschedule</a>
                                </button>
                                <button type="button" class="btn btn-outline-primary">
                                  <a href="javascript:void(0);" class=""
                                    (click)="onCancelClassPopup(myclasslist)">Cancel &amp; Refund</a>
                                </button> -->
                              </div>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 mt-3 mb-3 showrecord">
                      <label>
                        Visa
                        <select class="filter-select mr-2" [(ngModel)]="bodyDataRequestu['limit']"
                          (change)="onChangeLimitupc($event.target.value)">
                          <option selected disabled>välj</option>
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </select>
                        Poster
                      </label>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 mt-3 mb-3 text-right">
                      <pagination-controls previousLabel="Föregående" nextLabel="Nästa" id="u"
                        (pageChange)="onPageChangeupc($event)"></pagination-controls>
                    </div>
                  </div>
                </div>

                <div class="tab-pane fade" id="pills-lesson2" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div class="row py-3">

                    <div class="col-lg-12 wow fadeInLeft">
                      <ng-container class="list_box p-4 fs13 rel" *ngFor="
                        let myclasslist of MyClass_complete
                          | paginate
                            : {
                                itemsPerPage: bodyDataRequestc['limit'],
                                currentPage: pc,
                                totalItems: total_count_complete,
                                id:'c'
                              };
                        let i = index
                      ">
                        <div class="row" *ngIf="myclasslist.status == 2">
                          <div class="col-lg-4 col-md-6 pb-3">
                            <p class="Tuition-Frequency">Lärarnamn</p>
                            <p class="b mt-2">{{ myclasslist.teacher_name }}</p>
                          </div>
                          <div class="col-lg-4 col-md-6 pb-3">
                            <p class="Tuition-Frequency">Subject</p>
                            <p class="b mt-2">{{ myclasslist.subjectname }}</p>
                          </div>
                          <div class="col-lg-4 col-md-6 pb-3">
                            <p class="Tuition-Frequency">Bokat datum</p>
                            <p class="b mt-2">
                              {{
                              myclasslist.schedule_slot_date
                              | date : "EEEE, MMMM,d, y"
                              }}
                              - {{ myclasslist.time_from }}
                            </p>
                          </div>
                          <div class="col-lg-2 col-md-6 pb-3">
                            <p class="Tuition-Frequency">Betalning</p>
                            <p class="b mt-2">Paid</p>
                          </div>
                          <div class="col-lg-2 col-md-6 pb-3">
                            <p class="Tuition-Frequency">Belopp</p>
                            <p class="b mt-2">{{ myclasslist?.rate || "0" }}SEK </p>
                          </div>

                          <div class="col-lg-12 col-md-12 bdrb"></div>
                          <div class="col-lg-12 col-md-12 pt-4">
                            <div class="row">
                              <div class="col-md-7 p-0"></div>

                              <div class="col-md-2 p-0 pl-3">
                                <a href="javascript:void(0);" (click)="onsub(myclasslist)"
                                  class="compare">Reschedule</a>
                              </div>
                              <div class="col-md-3 p-0 pl-3">
                                <a href="javascript:void(0);" class="compare"
                                  (click)="onCancelClassPopup(myclasslist)">Cancel &amp; Refund</a>
                              </div>
                              <div class="col-md-3 p-0 pl-3">
                                <a href="" data-toggle="modal" data-target="#edit" class="compare"
                                  (click)="data_user(myclasslist)">FeedBack</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 mt-3 mb-3 showrecord">
                      <label>
                        Visa
                        <select class="filter-select mr-2" [(ngModel)]="bodyDataRequestc['limit']"
                          (change)="onChangeLimitcom($event.target.value)">
                          <option selected disabled>välj</option>
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </select>
                        Poster
                      </label>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 mt-3 mb-3 text-right">
                      <pagination-controls previousLabel="Föregående" nextLabel="Nästa" id="c"
                        (pageChange)="onPageChangecom($event)"></pagination-controls>
                    </div>
                  </div>
                </div>

                <div class="tab-pane fade" id="pills-lesson3" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <div class="row py-3">

                    <div class="col-lg-12 wow fadeInLeft">
                      <ng-container class="list_box p-4 fs13 rel" *ngFor="
                        let myclasslist of MyClass_cancel
                          | paginate
                            : {
                                itemsPerPage: bodyDataRequestca['limit'],
                                currentPage: pca,
                                totalItems: total_count_cancel,
                                id:'ca'
                              };
                        let i = index
                      ">
                        <div class="row" *ngIf="myclasslist.status == 3">
                          <div class="col-lg-4 col-md-6 pb-3">
                            <p class="Tuition-Frequency">Lärarnamn</p>
                            <p class="b mt-2">{{ myclasslist.teacher_name }}</p>
                          </div>
                          <div class="col-lg-4 col-md-6 pb-3">
                            <p class="Tuition-Frequency">Subject</p>
                            <p class="b mt-2">{{ myclasslist.subjectname }}</p>
                          </div>
                          <div class="col-lg-4 col-md-6 pb-3">
                            <p class="Tuition-Frequency">Bokat datum</p>
                            <p class="b mt-2">
                              {{myclasslist.schedule_slot_date| date: "EEEE, MMMM, d, y" }} - {{ myclasslist.time_from
                              }}
                            </p>
                          </div>
                          <div class="col-lg-2 col-md-6 pb-3">
                            <p class="Tuition-Frequency">Betalning</p>
                            <p class="b mt-2">Paid</p>
                          </div>
                          <div class="col-lg-2 col-md-6 pb-3">
                            <p class="Tuition-Frequency">Belopp</p>
                            <p class="b mt-2">{{ myclasslist?.rate || "0" }}SEK </p>
                          </div>

                          <div class="col-lg-12 col-md-12 bdrb"></div>
                          <div class="col-lg-12 col-md-12 pt-4">
                            <div class="row">
                              <div class="col-md-7 p-0"></div>

                              <div class="col-md-2 p-0 pl-3">
                                <a href="javascript:void(0);" (click)="onsub(myclasslist)"
                                  class="compare">Reschedule</a>
                              </div>
                              <!-- <div class="col-md-3 p-0 pl-3"><a href="javascript:void(0);" class="compare"
                                                                (click)="onCancelClassPopup(myclasslist)">Cancel &amp; Refund</a>
                                                        </div> -->
                              <!-- <div class="col-md-3 p-0 pl-3"><a href="" data-toggle="modal" data-target="#edit" class="compare" (click)="data_user(myclasslist)">FeedBack</a></div> -->
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 mt-3 mb-3 showrecord">
                      <label>
                        Visa
                        <select class="filter-select mr-2" [(ngModel)]="bodyDataRequestca['limit']"
                          (change)="onChangeLimitcan($event.target.value)">
                          <option selected disabled>välj</option>
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </select>
                        Poster
                      </label>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 mt-3 mb-3 text-right">
                      <pagination-controls previousLabel="Föregående" nextLabel="Nästa" id="ca"
                        (pageChange)="onPageChangecan($event)"></pagination-controls>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="pills-tab3" role="tabpanel" aria-labelledby="pills-tab3-tab">
            <div class="col-md-12">
              <section class="animated3 wow drop_eff5 animated" style="visibility: visible">
                <div *ngIf="CourseList != ''">
                  <div class="containers">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="list_box p-4 fs13 rel" *ngFor="let purchasecourselist of CourseList">
                          <div *ngIf="purchasecourselist?.course_name != null">
                            <div class="row">
                              <div class="col-lg-4 col-md-12">
                                <div class="list_box">
                                  <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title=""
                                    class="port_pop1"><img [src]="
                                        allowurl(
                                          apiService.imageBase +
                                            purchasecourselist.image
                                        )
                                      " onError="this.src='assets/images/profile-demo.png'" alt=""
                                      class="img-fluid" /></a>
                                  <div class="p-3">
                                    <p class="star">
                                      <i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                        class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i>
                                    </p>
                                    <p class="b float-left">
                                      <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;"
                                        title="" class="port_pop1">{{ purchasecourselist.course_name }}</a>
                                    </p>
                                    <p class="b float-right">
                                      ${{ purchasecourselist.price }}.00
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-8 col-md-12">
                                <div class="row">
                                  <div class="col-lg-12 col-md-12 pb-3">
                                    <p class="b mt-2 fs18">
                                      <a>{{
                                        purchasecourselist.course_name
                                        }}</a>
                                    </p>
                                    <p class="mt-2 pb-3 bdrb mb-4" [innerHTML]="
                                        purchasecourselist.description
                                      "></p>
                                  </div>
                                  <div class="col-lg-3 col-md-3 col-sm-3 pb-3">
                                    <p class="">Purchase Date</p>
                                    <p class="b mt-2">
                                      {{ purchasecourselist.created | date }}
                                    </p>
                                  </div>
                                  <div class="col-lg-3 col-md-3 col-sm-3 pb-3">
                                    <p class="">No. Of Videos</p>
                                    <p class="b mt-2">
                                      {{ purchasecourselist.no_of_classes }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="CourseList == ''">
                  <div class="col-md-12">
                    <div class="list_box p-4 fs13 rel" *ngFor="let purchasecourselist of CourseList">
                      <div class="row">
                        <div class="col-lg-8 col-md-12">
                          <div class="row">
                            <div class="col-lg-12 col-md-12 pb-3">
                              <p class="b mt-2 fs18">
                                <a href="course-dtl.htm">{{
                                  purchasecourselist.course_name
                                  }}</a>
                              </p>
                              <p class="">Level - Intermediary</p>
                              <p class="mt-2 pb-3 bdrb mb-4" [innerHTML]="purchasecourselist.description"></p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 pb-3">
                              <p class="">Purchase Date</p>
                              <p class="b mt-2">
                                {{ purchasecourselist.created | date }}
                              </p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 pb-3">
                              <p class="">No. Of Videos</p>
                              <p class="b mt-2">
                                {{ purchasecourselist.no_of_classes }}
                              </p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 pb-3">
                              <p class="">Duration</p>
                              <p class="b mt-2">2 hours</p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 pb-3">
                              <p class="">Level</p>
                              <p class="b mt-2">Level</p>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <iframe width="100%" height="200" src="https://www.youtube.com/embed/kCHeKc2R96o"
                                  title="YouTube video player" frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowfullscreen="" style="border-radius: 15px"></iframe>
                              </div>
                              <div class="col-md-6">
                                <iframe width="100%" height="200" src="https://www.youtube.com/embed/kCHeKc2R96o"
                                  title="YouTube video player" frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowfullscreen="" style="border-radius: 15px"></iframe>
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-12 pb-3">
                              <p class="bdrb pb-2">
                                <img src="images/spacer.gif" alt="" />
                              </p>
                            </div>

                            <div class="col-lg-4 col-md-6">
                              <p><a href="#" class="compare">GoTo Class</a></p>
                            </div>
                            <div class="col-lg-4 col-md-6">
                              <p>
                                <a href="chat-me.htm" class="compare"><i class="fas fa-comments"></i> Message Me</a>
                              </p>
                            </div>
                            <div class="col-lg-4 col-md-6">
                              <p>
                                <a href="chat-me.htm" class="compare"><i class="fas fa-folder"></i> My Folder</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list_box p-4 fs13 rel">
                      <a href="#" data-toggle="modal" data-target="#yono" class="del1"><i
                          class="fas fa-trash-alt"></i></a>
                      <div class="row">
                        <div class="col-lg-4 col-md-12">
                          <div class="list_box">
                            <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title=""
                              class="port_pop1"><img src="images/t5.jpg" alt="" class="img-fluid" /></a>
                            <div class="p-3">
                              <p class="star">
                                <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                  class="fas fa-star"></i><i class="fas fa-star"></i>
                              </p>
                              <p class="b float-left">
                                <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title=""
                                  class="port_pop1">Rebecca L</a>
                              </p>
                              <p class="b float-right">$50.00</p>
                              <p class="clearfix"></p>
                              <p class="fs12">Dwarka, New Delhi</p>
                              <p class="tbot">
                                <a href="#" data-toggle="modal" data-target="#rate" class="compare">Send Feedback</a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-8 col-md-12">
                          <div class="row">
                            <div class="col-lg-12 col-md-12 pb-3">
                              <p class="b mt-2 fs18 st2023">
                                <a href="course-dtl.htm">Programming and Computer</a>
                              </p>
                              <p class="Intermediary">
                                Programming - Intermediary
                              </p>
                              <p class="mt-2 pb-3 bdrb mb-4 Intermediary">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Nam volutpat posuere tristique.
                                Vestibulum ante ipsum primis in faucibus orci
                                luctus et ultrices posuere cubilia curae;
                                Curabitur rhoncus urna a commodo rhoncus. Sed
                                porta ac ipsum in accumsan.
                              </p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 pb-3">
                              <p class="">Purchase Date</p>
                              <p class="b mt-2">Purchase Date</p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 pb-3">
                              <p class="">No. Of Videos</p>
                              <p class="b mt-2">2</p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 pb-3">
                              <p class="">Duration</p>
                              <p class="b mt-2">2 hours</p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3 pb-3">
                              <p class="">Level</p>
                              <p class="b mt-2">Level</p>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <iframe width="100%" height="200" src="https://www.youtube.com/embed/kCHeKc2R96o"
                                  title="YouTube video player" frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowfullscreen="" style="border-radius: 15px"></iframe>
                              </div>
                              <div class="col-md-6">
                                <iframe width="100%" height="200" src="https://www.youtube.com/embed/kCHeKc2R96o"
                                  title="YouTube video player" frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowfullscreen="" style="border-radius: 15px"></iframe>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div class="tab-pane fade" id="pills-tab5" role="tabpanel" aria-labelledby="pills-tab5-tab">
            <div class="list_box p-4 fs13 w100">
              <div class="row justify-content-center">
                <div class="col-12">
                  <div class="row">
                    <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin showrecord">
                      <label>
                        Visa
                        <select class="filter-select mr-2" [(ngModel)]="bodyDataRequest['limit']"
                          (change)="onChangeLimit($event.target.value)">
                          <option selected disabled>välj</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </select>
                        Poster
                      </label>
                    </div>
                    <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin text-right">
                      <pagination-controls previousLabel="Föregående" nextLabel="Nästa"
                        (pageChange)="onPageChange($event)"></pagination-controls>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 pb-4">
                  <div class="mt-3" *ngFor=" let sqd of student_quizzes_upload  | paginate
                          : {
                              itemsPerPage: bodyDataRequest['limit'],
                              currentPage: p,
                              totalItems: total_count
                            };
                      let i = index
                    ">

                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Assignment namn</th>
                          <th>Exam Date</th>
                          <th>Exam Time</th>
                          <th>Download / Upload</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ sqd.quiz_title }}</td>
                          <td>{{ sqd.exam_date }}</td>
                          <td>{{ sqd.exam_time }}</td>
                          <td><a [href]="
                            allowurl(
                              apiService.imageBase + sqd.question_doc
                            )
                          " [download]="
                            'New_Assignment' + sqd.question_doc
                          "><i class="fa fa-download" aria-hidden="true"></i></a></td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="row custom-row">
                      <div class="col-md-8 col-sm-12">
                        <div class="Submit-Your-Work"> 
                          <input type="file" accept="image/*" class="form-control choose-file mobile-me-change" (change)="onSelectAnswerFile($event.target)"
                            #file id="file" style="
                                  opacity: 1;
                                  position: absolute;
                                  left: 2px;
                                  margin-top:0px;
                            " />
                             </div>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <div class="Submit-Your-Work">
                          <a href="javascript:void(0);" class="style1 bg" (click)="onSubmitQuestionAnswer(sqd)">Submit Your
                            Work</a></div>
                      </div>
                    </div> 
                                
                  </div>
                </div>
                



              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="pills-tab6" role="tabpanel" aria-labelledby="pills-tab6-tab">
            <div class="row list_box mt-0">
              <div class="col-md-12 col-sm-12 mt-3">
                <table class="table table-bordered tutor-class-ext">
                  <thead>
                    <tr>
                      <th scope="col">Lärarnamn</th>
                      <th scope="col">Bokat datum</th>
                      <th scope="col">Betalning</th>
                      <th scope="col">Undervisningsfrekvens</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Demolektion</td>
                      <td>När som helst</td>
                      <td>Gratis</td>
                      <td>Demolektion</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-lg-12 col-md-12 bdrb pb-5" style=" justify-content: center;display: flex;">
                <a href="https://merithub.com/cjiau685utj49marj5s0/sessions/view/cjomurr92kq5ihnkg9pg/VD32aMr.qHnR_APksj4R27BYzLgjkWISNT7-9fJUoOvQ8E3JISidkQF9PvEysa0-lyiqNyANKdZaw4T3x7ZR5Hg31vDkWmLWxg"target="_blank" class="style1">GÅ MED</a>
              </div>
          </div>
        </div>

          <div class="tab-pane fade" id="pills-tab66" role="tabpanel" aria-labelledby="pills-tab66-tab">
            <div class="My-Transaction">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <div class="edt-profile-design">
                    <label> namn</label>
                    <input type="text" autocomplete="on" class="form-control-design1" placeholder="Vänligen ange ett namn"
                      (keyup)="onSearchKey($event.target)" />
                  </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                  <div class="edt-profile-design">
                    <label> Startdatum</label>
                    <input type="date" style="width: 100%" class="form-control-design1"
                      [ngModel]="startDate | date : 'yyyy-MM-dd'" (ngModelChange)="setDate('start', $event)"
                      name="startDate" [max]="getCurrentDate() | date : 'yyyy-MM-dd'" />
                  </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                  <div class="edt-profile-design">
                    <label> Startdatum</label>
                    <input type="date" style="width: 100%" class="form-control-design1" [ngModel]="endDate"
                      (ngModelChange)="setDate('end', $event)" name="endDate"
                      [max]="getCurrentDate() | date : 'yyyy-MM-dd'" />
                  </div>
                </div>
                <div class="col-md-6 col-sm-12"></div>
                <div class="col-md-6 col-sm-12" style="justify-content: space-evenly;display: flex;">
                  <span>
                    <button type="button" class="style1" (click)="clearDateFilter()">RENSA</button>
                  </span>
                  <span><button type="button" class="style1 bg">SÖK</button></span>
                </div>
                <div class="col-md-12">
                  <section class="animated3 wow drop_eff5 animated mt-3" style="visibility: visible">
                    <div class="row">
                      <div class="col-lg-4 col-md-6 Description-l">
                        <p class="Collaboration-Shown">namn</p>
                      </div>
                      <div class="col-lg-4 col-md-6 Description-l">
                        <p class="Collaboration-Shown">Bokat datum</p>
                      </div>
                      <div class="col-lg-2 col-md-6 Description-l">
                        <p class="Collaboration-Shown">Beskrivning</p>
                      </div>
                      <div class="col-lg-2 col-md-6 Description-l">
                        <p class="Collaboration-Shown">Belopp</p>
                      </div>
                    </div>
                    <div class="containers fs13" *ngFor="
                        let myclasslist of MyClass
                          | paginate
                            : {
                                itemsPerPage: bodyDataRequest['limit'],
                                currentPage: p,
                                totalItems: total_count
                              };
                        let i = index
                      ">
                      <div class="row">
                        <div class="col-lg-4 col-md-6 border-black2">
                          <p class="b mt-2 Collaboration-Showns">
                            {{ myclasslist.teacher_name }}
                          </p>
                        </div>
                        <div class="col-lg-4 col-md-6 border-black2">
                          <p class="b mt-2 Collaboration-Showns">
                            {{
                            myclasslist.schedule_slot_date
                            | date : "EEEE, MMMM d, y"
                            }}
                            -
                            {{ myclasslist.time_from }}
                          </p>
                        </div>
                        <div *ngIf="myclasslist.payment_status == 0" class="col-lg-2 col-md-6 border-black2">
                          <p class="b mt-2 Collaboration-Showns">Unpaid</p>
                        </div>
                        <div *ngIf="myclasslist.payment_status == 1" class="col-lg-2 col-md-6 border-black2">
                          <p class="b mt-2 Collaboration-Showns">Paid</p>
                        </div>
                        <div class="col-lg-2 col-md-6 border-black2">
                          <p class="b mt-2 Collaboration-Showns">
                            {{ myclasslist?.rate || "0" }}SEK 
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin showrecord">
                        <label>
                          Visa
                          <select class="filter-select mr-2" [(ngModel)]="bodyDataRequest['limit']"
                            (change)="onChangeLimit2($event.target.value)">
                            <option selected disabled>välj</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                          Poster
                        </label>
                      </div>
                      <div class="col-md-12 col-lg-6 col-sm-12 mobile-margin text-right">
                        <pagination-controls previousLabel="Föregående" nextLabel="Nästa"
                          (pageChange)="onPageChange2($event)"></pagination-controls>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-tab16" role="tabpanel" aria-labelledby="pills-tab16-tab">
            <div class="col-lg-12 wow fadeInLeft">
              <div class="list_box p-4 fs13 rel" *ngFor="
                  let myclasslist of MyClass
                    | paginate
                      : {
                          itemsPerPage: bodyDataRequest['limit'],
                          currentPage: p,
                          totalItems: total_count
                        };
                  let i = index
                ">
                <div class="row">
                  <div class="col-lg-4 col-md-6 pb-3">
                    <p class="Tuition-Frequency">Lärarnamn</p>
                    <p class="b mt-2">{{ myclasslist.teacher_name }}</p>
                  </div>
                  <div class="col-lg-4 col-md-6 pb-3">
                    <p class="Tuition-Frequency">Bokat datum</p>
                    <p class="b mt-2">
                      {{
                      myclasslist.schedule_slot_date
                      | date : "EEEE, MMMM d, y"
                      }}
                      -
                      {{ myclasslist.time_from }}
                    </p>
                  </div>
                  <div class="col-lg-2 col-md-6 pb-3">
                    <p class="Tuition-Frequency">Betalning</p>
                    <p class="b mt-2">Paid</p>
                  </div>
                  <div class="col-lg-2 col-md-6 pb-3">
                    <p class="Tuition-Frequency">Belopp</p>
                    <p class="b mt-2">{{ myclasslist?.rate || "0" }}SEK </p>
                  </div>

                  <div class="col-lg-12 col-md-12 bdrb"></div>
                  <div class="col-lg-12 col-md-12 pt-4">
                    <div class="row">
                      <div class="col-md-7 p-0"></div>
                      <div *ngIf="
                          (myclasslist.schedule_slot_date
                            | date : 'EEEE, MMMM d, y') ==
                          (today_date | date : 'EEEE, MMMM d, y')
                        " class="col-md-3 p-0 pl-3">
                        <a href="" target="_blank" class="compare">GÅ MED</a>
                      </div>
                      <div class="col-md-2 p-0 pl-3">
                        <a href="javascript:void(0);" (click)="onsub(myclasslist)" class="compare">Reschedule</a>
                      </div>
                      <div class="col-md-3 p-0 pl-3">
                        <a href="javascript:void(0);" class="compare" (click)="onCancelClassPopup(myclasslist)">Cancel
                          &amp; Refund</a>
                      </div>
                      <div class="col-md-3 p-0 pl-3">
                        <a href="" data-toggle="modal" data-target="#edit" class="compare"
                          (click)="data_user(myclasslist)">FeedBack</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-tab17" role="tabpanel" aria-labelledby="pills-tab17-tab">
            <div class="col-lg-9 wow fadeInLeft" style="visibility: hidden; animation-name: none">
              <div class="list_box fs13 rel">
                <div class="row">
                  <div class="col-md-8 p-4">
                    <div class="row emp_area">
                      <div class="col-md-5"><input type="date" /></div>
                      <div class="col-md-5"><input type="date" /></div>
                      <div class="col-md-2 add_cart pt-2">
                        <a href="tutor-registration.htm"><i class="fa fa-search pl-3 pr-3" aria-hidden="true"></i></a>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-4 p-4 e_txt">
                                                      <p>Period Earnings</p>
                                                      <p class="b mt-2 fs16">SEK 1,861.57</p>
                                                  </div> -->
                </div>
              </div>

              <div class="list_box fs13 rel">
                <ul class="tabl">
                  <li class="b">Date</li>
                  <li class="b">namn</li>
                  <li class="b">Beskrivning</li>
                  <li class="b">Belopp</li>
                </ul>
                <ul class="tabl2" *ngFor="let eduData of this.student_booked_class">
                  <li>{{ eduData.created | date }}</li>
                  <li>{{ eduData.student_name }}</li>
                  <li>{{ eduData.payment || "need to add payment getway" }}</li>
                  <li>need to add payment getway first</li>
                </ul>
                <!-- <ul class="tabl2">
                                                  <li>25th February, 2019</li>
                                                  <li>NAT WEST BANK PLC .... <br>3959</li>
                                                  <li>Paid</li>
                                                  <li>SEK 24.00</li>
                                              </ul> -->
                <p class="clearfix"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- lesson Modal -->

<div class="modal fade" id="ld" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered logmod" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Lesson Details</h1>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 pr-0">
            <form>
              <div class="emp_area ac fs14">
                <div class="name_feild">
                  <p class="fs22">Michelle Leong</p>
                  <p>Maths (GCSE)</p>
                </div>
                <div class="name_feild">
                  <p>Belopp</p>
                  <p class="fs22">SEK 30.00</p>
                </div>

                <div class="loc_feild font-weight-normal">
                  <p>Sun, 17th February, 2019</p>
                  <p>11:00 - 12:00</p>
                </div>
                <div class="loc_feild">
                  <p>Calverley St, Leeds LS1 3AB</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary mt-4" data-dismiss="modal">
          Cancel
        </button>
        <button type="button" data-toggle="modal" data-target="#fed" data-dismiss="modal" class="submit">
          Provide feedback
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="edit" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered logmod" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Tutor Rating</h1>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 pr-0">
            <form [formGroup]="createuser15">
              <div class="row">
                <div class="loc_feild">
                  <p class="">Enter Your Feedback</p>
                  <div>
                    <input placeholder="Enter Name" type="text" formControlName="feedback" class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted15 && f15.feedback.errors
                      }" />
                  </div>
                </div>
                <div class="loc_feild">
                  <p class="">Give Your Rating</p>
                  <!-- <div><input placeholder="Enter Name" type="text" formControlName="rating" class="form-control" [ngClass]="{ 'is-invalid': submitted15 && f15.rating.errors }" >
                                        </div> -->
                  <ul class="list-inline rating-list" *ngFor="let star of stars; let i = index">
                    <li (click)="countStar(star)" [ngClass]="{ selected: star <= selectedValue }"
                      (mouseover)="addClass(star)" (mouseout)="removeClass()">
                      <span class="material-icons">
                        <i class="fas fa-star"></i></span>
                    </li>
                  </ul>
                </div>

                <div class="col-md-12">
                  <div class="loc_feild">
                    <p class="add_cart">
                      <a href="javascript:void(0);" (click)="teacher_review_rating()"><i
                          class="fas fa-laptop"></i>Save</a>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>