<div class="modal fade show pp" id="log-in" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 pr-0">
                        <!-- start -->
                        <div class="log_box">
                            <div class="bdrb pb-3">
                                <h1 class="Registers">Login</h1>
                            </div>
                            <form [formGroup]="AddForm">
                                <div class="login_form">
                                    <div class="mt10">
                                        <label>E-post</label>
                                        <input type="text" placeholder="Vänligen ange din e-post" name="email_id"
                                            id="email_id" formControlName="email" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                            (keydown)="keyDownFunction($event)">
                                        <div class="warning">{{emailinvalid}}</div>

                                        <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">

                                            <div class="warning" *ngIf="f.email.errors.required">
                                                E-post krävs.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-4">
                                        <label class="float-left">Lösenord </label>

                                        <div class="rel">
                                            <a class="abico" (click)="onChangeType1()" *ngIf="!type"><i
                                                    class="fas fa-eye-slash"></i></a>
                                            <a href="javascript:void(0);" class="abico" (click)="onChangeType2()"
                                                *ngIf="type"><i class="fas fa-eye"></i></a>
                                            <input [type]="type? 'text':'password'"
                                                placeholder="Vänligen ange ditt lösenord" class="form-control"
                                                name="password" id="password" formControlName="password"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                                (keydown)="keyDownFunction($event)">
                                            <div class="warning">{{passwordinvalid}}</div>

                                            <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)">

                                                <div class="warning" *ngIf="f.password.errors.required">
                                                    Vänligen krävs.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="fs12 float-right rel"><a href="javascript:void(0);" class="gray"
                                            (click)="onOpenForgotPassword()">Glömt lösenord</a></p>
                                    <p class="mt-3 text-left d-block fs13"><input type="checkbox" name="vehicle1"
                                            value="Bike" class="vam">Håll mig inloggad</p>
                                    <p class="text-center"><a href="javascript:void(0);" class="style1 bg"
                                            (click)="onSubmit()">Logga in</a></p>
                                    <p class="text-center mt-2"><a href="javascript:void(0);" class="new_btn"
                                            (click)="onOpenRegisterPopup()">Skapa ett nytt konto</a></p>
                                    <p class="fs12 ac mt-2 w62 auto">Genom att logga in så godkänner du våra allmänna villkor och integritetspolicy.
<!-- 
                                        <a class="red"
                                        href="#" target="_blank"> Terms and Conditions</a> and <a class="red"
                                        href="#" target="_blank">Privacy Policy</a>. -->

                                    </p>
                                </div>
                            </form>

                        </div>
                        <!-- end -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>