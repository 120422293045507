<div class="modal fade show pp" id="regi" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered Register" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 pr-0">
                        <!-- start -->
                        <div class="log_box">
                            <div class="bdrb pb-3">
                                <h1 class="Registers">Register</h1>
                            </div>
                            <div class="login_form mt-3 switch-field">
                                <div class="row d-flex justify-content-center mb-3">
                                    <div class="col-md-4 col-lg-4 col-sm-12">
                                        <input type="radio" name="register-as" [checked]="register==0"
                                            (click)="onChangeType(2)" id="register-as_0">
                                        <label class="exp_box_radio" for="register-as_0">Som elev</label>
                                    </div>

                                    <div class="col-md-4 col-lg-4 col-sm-12">
                                        <input type="radio" name="register-as" [checked]="register==2" value="5"
                                            id="register-as_1" (click)="onChangeType(5)">
                                        <label class="exp_box_radio" for="register-as_1">Som förälder</label>
                                    </div>

                                    <div class="col-md-4 col-lg-4 col-sm-12">
                                        <input type="radio" name="register-as" [checked]="register==1" value="3"
                                            id="register-as_2" (click)="onChangeType(3)">
                                        <label class="exp_box_radio" for="register-as_2">Som Studiementor</label>
                                    </div>
                                </div>

                                <div class="student">
                                    <form [formGroup]="AddForm">
                                        <div class="emp_area">
                                            <div class="row">
                                                <div class="col-md-6 col-sm-12 col-xs-12">
                                                    <div class="name_feild">
                                                        <p>Förnamn<span class="red">*</span></p>
                                                        <div><input type="text" formControlName="name" placeholder="Vänligen ange ditt förnamn"
                                                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                                                                class="form-control">
                                                            <div
                                                                *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)">

                                                                <div class="warning" *ngIf="f.name.errors.required">
                                                                    Förnamn krävs.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 col-xs-12">
                                                    <div class="name_feild">
                                                        <p>Efternamn<span class="red">*</span></p>
                                                        <div><input type="text" class="form-control"
                                                                formControlName="last_name" placeholder="Vänligen ange ditt efternamn
                                                                "
                                                                [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
                                                            <div
                                                                *ngIf="f.last_name.invalid && (f.last_name.dirty || f.last_name.touched)">

                                                                <div class="warning"
                                                                    *ngIf="f.last_name.errors.required">
                                                                    Efternamn krävs.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-sm-12 col-xs-12">
                                                    <div class="name_feild">
                                                      <div class="loc_feild">
                                                        <p>Mobil <span class="red">*</span></p>
                                                        <div>
                                                          <input  formControlName="phone" class="form-control" type="tel" placeholder="Vänligen ange ditt mobilnummer"
                                                          [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" >
                                                          <div *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)">

                                                              <div class="warning"
                                                                  *ngIf="f.phone.errors.required">
                                                                  Mobilnummer krävs.                                                                    </div>
                                                          </div>
                                                          <span class="warning">{{phone}}</span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>


                                                <div class="col-md-6 col-sm-12 col-xs-12">
                                                    <div class="name_feild">
                                                        <p>E-post<span class="red">*</span></p>
                                                        <div><input type="text" class="form-control"
                                                                formControlName="email"
                                                                placeholder="Vänligen ange din e-post"
                                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                            <div
                                                                *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">

                                                                <div class="warning" *ngIf="f.email.errors.required">
                                                                    E-post krävs.
                                                                </div>
                                                                <div *ngIf="f.email.errors.email" class="warning">Email
                                                                    must be a valid email address</div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 col-xs-12">
                                                    <div class="name_feild">
                                                        <p>Lösenord <span class="red">*</span></p>
                                                        <div class="rel">
                                                            <a class="abico" (click)="onChangeType1()" *ngIf="!type"><i
                                                                    class="fas fa-eye-slash"></i></a>
                                                            <a href="javascript:void(0);" class="abico"
                                                                (click)="onChangeType2()" *ngIf="type"><i
                                                                    class="fas fa-eye"></i></a>
                                                            <input [type]="type? 'text':'password'" minlength="8"
                                                                class="form-control" formControlName="password"
                                                                placeholder="Vänligen ange ditt lösenord"
                                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                                            <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)">
                                                                <div class="warning" *ngIf="f.password.errors.required">
                                                                    Lösenord krävs.
                                                                </div>
                                                            </div>
                                                            <div class="warning" *ngIf="f.password.errors?.minlength">
                                                                Password must be at least 8 characters long.
                                                           </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 col-xs-12">
                                                    <div class="name_feild">
                                                        <p>Bekräfta Lösenord<span class="red">*</span></p>
                                                        <div class="rel">
                                                            <a class="abico" (click)="onChangecnType1()"
                                                                *ngIf="!cptype"><i class="fas fa-eye-slash"></i></a>
                                                            <a href="javascript:void(0);" class="abico"
                                                                (click)="onChangecnType2()" *ngIf="cptype"><i
                                                                    class="fas fa-eye"></i></a>
                                                            <input [type]="cptype? 'text':'password'" minlength="8"
                                                                class="form-control" formControlName="reenterpassword"
                                                                placeholder="Vänligen bekräfta ditt lösenord"
                                                                [ngClass]="{ 'is-invalid': submitted && f.reenterpassword.errors }">
                                                            <div
                                                                *ngIf="f.reenterpassword.invalid && (f.reenterpassword.dirty || f.reenterpassword.touched)">

                                                                <div class="warning"
                                                                    *ngIf="f.reenterpassword.errors.required">
                                                                    Bekräftelse av lösenord krävs.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="(f.reenterpassword.errors && f.reenterpassword.touched) || (f.reenterpassword.errors && submitted)"
                                                        class="errorMsg">
                                                        <div *ngIf="f.reenterpassword.errors.mustMatch">The password and confirmed password do not match.</div>
                                                    </div>
                                                </div>



                                                <div class="col-md-12">
                                                    <div class="loc_feild">
                                                        <p>Välj kön</p>
                                                        <select  class="form-control" aria-label="Default select example" formControlName="gender"
                                                        [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                                                            <option [value]="null" selected>Välj kön</option>
                                                                <option value="1">Man</option>
                                                                <option value="2">Kvinna</option>
                                                                <option value="3">Annat / Vill ej uppge</option>
                                                          </select>
                                                    </div>
                                                </div>

                                                <div class="col-md-12">
                                                    <div class="loc_feild font-weight-normal">
                                                        <input type="checkbox" name="vehicle1" value="Bike"
                                                            class="vam mr-2" formControlName="termscond"
                                                            [ngClass]="{ 'is-invalid': submitted && f.termscond.errors }">
                                                            Jag har läst och godkänner<a href="#" target="_blank"
                                                            class="red">Allmänna &amp; villkor</a> och  <a
                                                            href="#" target="_blank" class="red">Integritetspolicy</a>.
                                                        <div
                                                            *ngIf="f.termscond.invalid && (f.termscond.dirty || f.termscond.touched)">

                                                            <div class="warning" *ngIf="f.termscond.errors.required">
                                                                This field is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="loc_feild font-weight-normal">
                                                        <input type="checkbox" name="vehicle1" value="Bike"
                                                            class="vam mr-2" formControlName="news_letter"
                                                          >
                                                          Jag vill gärna prenumerera på nyhetsbrevet.
                                                    </div>
                                                </div>
                                                <div class="col-md-12" >
                                                    <div class="loc_feild">
                                                        <p class="add_cart" style="text-align: center;"><a style="width: 100% !important;" href="javascript:void(0);"
                                                                (click)="onSubmit()"><i class="fas fa-laptop"></i>
                                                                Registrera nu</a></p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="loc_feild font-weight-normal ac"> Redan medlem?  <a
                                                            href="javascript:void(0);" (click)="onOpenLoginPopup()"
                                                            id="hidess" data-dismiss="modal" class="red" aria-label="Close">Logga in</a>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                        <!-- end -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>