import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { ApiService } from './../../../services/api.service';
import { DatePipe } from '@angular/common';
import { PasswordMatchPattern } from 'src/app/services/validation/password-match-pattern';
import { HttpClient } from '@angular/common/http';
import { loadStripe } from '@stripe/stripe-js';

declare const $: any;
@Component({
  selector: 'app-student-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
  providers: [DatePipe],
})
export class StudentEditProfileComponent implements OnInit {
  config: any = {
    placeholder: '',
    tabsize: 2,
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear',
        ],
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
    ],
    fontNames: [
      'Helvetica',
      'Arial',
      'Arial Black',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
    ],
  };
  TimeZone: any = [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Algiers',
    'Africa/Bissau',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Ceuta',
    'Africa/El_Aaiun',
    'Africa/Johannesburg',
    'Africa/Juba',
    'Africa/Khartoum',
    'Africa/Lagos',
    'Africa/Maputo',
    'Africa/Monrovia',
    'Africa/Nairobi',
    'Africa/Ndjamena',
    'Africa/Sao_Tome',
    'Africa/Tripoli',
    'Africa/Tunis',
    'Africa/Windhoek',
    'America/Adak',
    'America/Anchorage',
    'America/Araguaina',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Catamarca',
    'America/Argentina/Cordoba',
    'America/Argentina/Jujuy',
    'America/Argentina/La_Rioja',
    'America/Argentina/Mendoza',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Salta',
    'America/Argentina/San_Juan',
    'America/Argentina/San_Luis',
    'America/Argentina/Tucuman',
    'America/Argentina/Ushuaia',
    'America/Asuncion',
    'America/Atikokan',
    'America/Bahia',
    'America/Bahia_Banderas',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Blanc-Sablon',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Cayenne',
    'America/Chicago',
    'America/Chihuahua',
    'America/Costa_Rica',
    'America/Creston',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver',
    'America/Detroit',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Fort_Nelson',
    'America/Fortaleza',
    'America/Glace_Bay',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/La_Paz',
    'America/Lima',
    'America/Los_Angeles',
    'America/Maceio',
    'America/Managua',
    'America/Manaus',
    'America/Martinique',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Menominee',
    'America/Merida',
    'America/Metlakatla',
    'America/Mexico_City',
    'America/Miquelon',
    'America/Moncton',
    'America/Monterrey',
    'America/Montevideo',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Nuuk',
    'America/Ojinaga',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Punta_Arenas',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Santarem',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Sitka',
    'America/St_Johns',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Macquarie',
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Anadyr',
    'Asia/Aqtau',
    'Asia/Aqtobe',
    'Asia/Ashgabat',
    'Asia/Atyrau',
    'Asia/Baghdad',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Barnaul',
    'Asia/Beirut',
    'Asia/Bishkek',
    'Asia/Brunei',
    'Asia/Chita',
    'Asia/Choibalsan',
    'Asia/Colombo',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dili',
    'Asia/Dubai',
    'Asia/Dushanbe',
    'Asia/Famagusta',
    'Asia/Gaza',
    'Asia/Hebron',
    'Asia/Ho_Chi_Minh',
    'Asia/Hong_Kong',
    'Asia/Hovd',
    'Asia/Irkutsk',
    'Asia/Jakarta',
    'Asia/Jayapura',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Karachi',
    'Asia/Kathmandu',
    'Asia/Khandyga',
    'Asia/Kolkata',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Asia/Macau',
    'Asia/Magadan',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Nicosia',
    'Asia/Novokuznetsk',
    'Asia/Novosibirsk',
    'Asia/Omsk',
    'Asia/Oral',
    'Asia/Pontianak',
    'Asia/Pyongyang',
    'Asia/Qatar',
    'Asia/Qostanay',
    'Asia/Qyzylorda',
    'Asia/Riyadh',
    'Asia/Sakhalin',
    'Asia/Samarkand',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Srednekolymsk',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tbilisi',
    'Asia/Tehran',
    'Asia/Thimphu',
    'Asia/Tokyo',
    'Asia/Tomsk',
    'Asia/Ulaanbaatar',
    'Asia/Urumqi',
    'Asia/Ust-Nera',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yangon',
    'Asia/Yekaterinburg',
    'Asia/Yerevan',
    'Atlantic/Azores',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faroe',
    'Atlantic/Madeira',
    'Atlantic/Reykjavik',
    'Atlantic/South_Georgia',
    'Atlantic/Stanley',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Darwin',
    'Australia/Eucla',
    'Australia/Hobart',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/Perth',
    'Australia/Sydney',
    'CET',
    'CST6CDT',
    'EET',
    'EST',
    'EST5EDT',
    'Etc/GMT',
    'Etc/GMT+1',
    'Etc/GMT+10',
    'Etc/GMT+11',
    'Etc/GMT+12',
    'Etc/GMT+2',
    'Etc/GMT+3',
    'Etc/GMT+4',
    'Etc/GMT+5',
    'Etc/GMT+6',
    'Etc/GMT+7',
    'Etc/GMT+8',
    'Etc/GMT+9',
    'Etc/GMT-1',
    'Etc/GMT-10',
    'Etc/GMT-11',
    'Etc/GMT-12',
    'Etc/GMT-13',
    'Etc/GMT-14',
    'Etc/GMT-2',
    'Etc/GMT-3',
    'Etc/GMT-4',
    'Etc/GMT-5',
    'Etc/GMT-6',
    'Etc/GMT-7',
    'Etc/GMT-8',
    'Etc/GMT-9',
    'Etc/UTC',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Astrakhan',
    'Europe/Athens',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Helsinki',
    'Europe/Istanbul',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Kirov',
    'Europe/Lisbon',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/Saratov',
    'Europe/Simferopol',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Ulyanovsk',
    'Europe/Uzhgorod',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'HST',
    'Indian/Chagos',
    'Indian/Christmas',
    'Indian/Cocos',
    'Indian/Kerguelen',
    'Indian/Mahe',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Reunion',
    'MET',
    'MST',
    'MST7MDT',
    'PST8PDT',
    'Pacific/Apia',
    'Pacific/Auckland',
    'Pacific/Bougainville',
    'Pacific/Chatham',
    'Pacific/Chuuk',
    'Pacific/Easter',
    'Pacific/Efate',
    'Pacific/Enderbury',
    'Pacific/Fakaofo',
    'Pacific/Fiji',
    'Pacific/Funafuti',
    'Pacific/Galapagos',
    'Pacific/Gambier',
    'Pacific/Guadalcanal',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Kiritimati',
    'Pacific/Kosrae',
    'Pacific/Kwajalein',
    'Pacific/Majuro',
    'Pacific/Marquesas',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Palau',
    'Pacific/Pitcairn',
    'Pacific/Pohnpei',
    'Pacific/Port_Moresby',
    'Pacific/Rarotonga',
    'Pacific/Tahiti',
    'Pacific/Tarawa',
    'Pacific/Tongatapu',
    'Pacific/Wake',
    'Pacific/Wallis',
    'WET',
  ];
  // createuserb: FormGroup | any;
  addForm: FormGroup | any;
  contactData: any = [];
  student_booked_class: any = [];
  uploadedImagesData: any = '';
  uploadedDocumentImagesData: any = '';
  fileToUpload: any = null;
  bodyRequestData: any = {};
  tutorList: any = [];
  localData: any = [];
  userData: any = {};
  ProfileStep1: boolean = true;
  ProfileStep2: boolean = false;
  ProfileStep3: boolean = false;
  ProfileStep4: boolean = false;
  ProfileStep5: boolean = false;
  ProfileStep6: boolean = false;
  ProfileStep7: boolean = false;
  bankdetail: FormGroup | any;
  createuser1: FormGroup | any;
  public submitted1 = false;
  createuser2: FormGroup | any;
  public submitted2 = false;
  createuser3: FormGroup | any;
  public submitted3 = false;
  createuser4: FormGroup | any;
  public submitted4 = false;
  createuser5: FormGroup | any;
  public submitted5 = false;
  createuser6: FormGroup | any;
  public submitted6 = false;
  createuser7: FormGroup | any;
  public submitted7 = false;
  createuser8: FormGroup | any;
  public submitted8 = false;
  createuser9: FormGroup | any;
  public submitted9 = false;
  createuser10: FormGroup | any;
  public submitted10 = false;
  createuser11: FormGroup | any;
  public submitted11 = false;
  createuser12: FormGroup | any;
  public submitted12 = false;
  createuser13: FormGroup | any;
  public submitted13 = false;
  createuser14: FormGroup | any;
  public submitted14 = false;
  createuser15: FormGroup | any;
  public submitted15 = false;
  createuser16: FormGroup | any;
  public submitted16 = false;
  createuserb: FormGroup | any;
  public submittedb = false;
  review_teacher_id: any;
  total_count_complete: any;
  total_count_cancel: any;
  total_count_upcoming: any;
  get fbi() {
    return this.createuserb.controls;
  }
  get f1() {
    return this.createuser1.controls;
  }
  get f2() {
    return this.createuser2.controls;
  }
  get f3() {
    return this.createuser3.controls;
  }
  get f4() {
    return this.createuser4.controls;
  }
  get f5() {
    return this.createuser5.controls;
  }
  get f6() {
    return this.createuser6.controls;
  }
  get f7() {
    return this.createuser7.controls;
  }
  get f8() {
    return this.createuser8.controls;
  }
  get f9() {
    return this.createuser9.controls;
  }
  get f10() {
    return this.createuser10.controls;
  }
  get f11() {
    return this.createuser11.controls;
  }
  get f12() {
    return this.createuser12.controls;
  }
  get f13() {
    return this.createuser13.controls;
  }
  get f14() {
    return this.createuser14.controls;
  }
  get f15() {
    return this.createuser15.controls;
  }
  get f16() {
    return this.createuser16.controls;
  }
  get f17() {
    return this.bankdetail.controls;
  }
   playerName: any;

  namefield: any;
  lastname: any;
  dob: any;
  email: any;
  phone: any;
  bio: any;
  User_Data: any;
  SchedulerList: any;
  AddForm: FormGroup | undefined;
  Id: any;
  UserData: any;
  submitted = false;
  btnsubmitted = false;
  flag: boolean = false;
  Edit_Flag: boolean = false;
  public Teacher_Details_Id: any;
  today_date: any = new Date();

  SchedulerSlotList: any;
  SchedulerSlotID: any;
  Date_Select_Flag: boolean = true;
  createuser: FormGroup | any;
  public submitteed = false;
  public studentId: any;

  public teacherList: any = [];
  public aval_CourseList: any = [];
  paymentHandler: any = null;
  p: number = 1;
  pc: number = 1;
  pca: number = 1;
  pu: number = 1;
  total_count: any;
  bodyDataRequest: any = {};
  bodyDataRequestc: any = {};
  bodyDataRequestu: any = {};
  bodyDataRequestca: any = {};
  keyValue: any = '';
  stripeAPIKey: any =
    'pk_test_51Mc1QjSAsqTNd0k5AZcQpdLgrrgXh17hFS2dKwT4gZuRc4uJKqr9YvUL6WzuKzdzqslBfeAwwBwfpwGtZw6jKCta0095rdOQSk';
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    public apiService: ApiService,
    public messageService: MessageService,
    public sanitizer: DomSanitizer,
    private router: Router,
    public _datePipe: DatePipe,
    public commonService: CommonService
  ) {
    this.commonService.SetFooterContent(false);
    if (localStorage.getItem('user')) {
      var jsonData: any = localStorage.getItem('user');
      this.localData = JSON.parse(jsonData);
      console.log('this.localData>>>>>', this.localData);
      this.userData = this.localData;
      this.userData.name = this.localData.name;
      this.userData.last_name = this.localData.last_name;
      this.userData.email = this.localData.email;
      this.userData.phone = this.localData.phone;
      this.userData.state = this.localData.state;
      this.userData.city = this.localData.city;
      this.userData.bio = this.localData.bio;
      this.userData.dob = this.localData.dob;
      this.userData.country = this.localData.country;
      this.userData.phone_code = this.localData.phone_code;
      this.userData.parents_name = this.localData.parents_name;
      this.userData.parent_last_name = this.localData.parent_last_name;
      this.userData.parent_email = this.localData.parent_email;
      this.userData.parent_phone = this.localData.parent_phone;

      // this.userData.dob = this.localData.dob;

      this.studentId = this.localData.id;
      if (
        this.localData.image != '' ||
        this.localData.image != null ||
        this.localData.image != undefined
      ) {
        this.uploadedImagesData = this.localData.image;
      }
    }
  }

  public Index_Value: number = 0;
  createItem() {
    return this.fb.group({
      institution_name: new FormControl('Wall 1'),

      course_name: new FormControl('0'),
      year: new FormControl(''),
    });
  }
  get f() {
    return this.createuser.controls;
  }

  ngOnInit(): void {

    //console.log(this.userData.country, this.userData.phone_code, 'sdfgsdf');

    this.bodyDataRequest['start'] = '0';
    this.bodyDataRequest['limit'] = '20';
    this.bodyDataRequestc['start'] = '0';
    this.bodyDataRequestc['limit'] = '5';
    this.bodyDataRequestu['start'] = '0';
    this.bodyDataRequestu['limit'] = '5';
    this.bodyDataRequestca['start'] = '0';
    this.bodyDataRequestca['limit'] = '5';

    this.bodyDataRequest['learning'] = "0";
    this.bodyDataRequest['keyword'] = this.keyValue;
    this.getMyClass_upcoming(this.bodyDataRequestu);
    this.getstudent_quizzes(this.bodyDataRequest);
    this.getMyClass(this.bodyDataRequest);
    this.getMyClass_complete(this.bodyDataRequestc);
    this.getMyClass_cancel(this.bodyDataRequestca);

    var user: any = [localStorage.getItem('user')];
    console.log('abc', user);

    this.showtransection();
    // this.invokeStripe();

    this.GetCourseList();
    this.booked_classess_data();
    this.aval_courses_list_for_student();

    console.log(
      'this is our',
      this.CourseList,
      this.aval_courses_list_for_student()
    );

    this.commonService.GetUpdateProfileChanged().subscribe((data) => {
      if (data) {
        this.getUserDetails(this.localData.id);
        this.getMyClass(this.bodyDataRequest);
        // this.getMyClass_complete(this.bodyDataRequest);
        // this.getMyClass_cancel(this.bodyDataRequest);
        // this.getMyClass_upcoming(this.bodyDataRequest);
      }
    });
    this.getUserDetails(this.localData.id);
    this.countryList();
    this.getMyClass(this.bodyDataRequest);
    this.getMyClass_complete(this.bodyDataRequestc);
    this.getMyClass_cancel(this.bodyDataRequestca);
    this.getMyClass_upcoming(this.bodyDataRequestu);
    
    
    this.GetCOurseList();
    this.createuser1 = this.fb.group({
      image: new FormControl('', [Validators.required]),
    });
    this.createuser2 = this.fb.group({
      description: new FormControl('', [Validators.required]),
      work_experience: new FormControl('', [Validators.required]),
      achievement: new FormControl('', [Validators.required]),
    });
    this.createuser = this.fb.group(
      {
        password: [
          null,
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
          ],
        ],
        current_password: [
          null,
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
          ],
        ],
        reenterpassword: ['', Validators.required],
      },
      {
        validator: PasswordMatchPattern('password', 'reenterpassword'),
      }
    );
    this.createuser3 = this.fb.group({
      address: new FormControl('', [Validators.required]),
      time_zone: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
    });
    this.createuser4 = this.fb.group({
      university: new FormControl('', [Validators.required]),
      degree: new FormControl('', [Validators.required]),
      completion_year: new FormControl('', [Validators.required]),
      id: new FormControl(''),
    });
    this.createuser5 = this.fb.group({
      certificate_title: new FormControl('', [Validators.required]),
      completion_year: new FormControl('', [Validators.required]),
      id: new FormControl(''),
    });
    this.createuser6 = this.fb.group({
      work_experience: new FormControl('', [Validators.required]),
      bio: new FormControl('', [Validators.required]),
      achievement: new FormControl('', [Validators.required]),
      languages: new FormControl('', [Validators.required]),
      id: new FormControl(''),
    });
    this.createuser7 = this.fb.group({
      bank_details: new FormControl('', [Validators.required]),
    });
    this.createuser8 = this.fb.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      image: new FormControl(''),
    });
    this.createuser9 = this.fb.group({
      job_type: new FormControl('', [Validators.required]),
    });
    this.createuser10 = this.fb.group({
      course_name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      image: new FormControl(''),
      course_type: new FormControl('', [Validators.required]),
      age_group_id: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
      curriculum: new FormControl('', [Validators.required]),
      price_per_classes: new FormControl('', [Validators.required]),
      fake_price: new FormControl('', [Validators.required]),
      no_of_classes: new FormControl('', [Validators.required]),
      hands_on_activities: new FormControl('', [Validators.required]),
    });
    this.createuser11 = this.fb.group({
      document_name: new FormControl('', [Validators.required]),
      document: new FormControl('', [Validators.required]),
      id: new FormControl(''),
    });
    this.createuser12 = this.fb.group({
      category_id: new FormControl('', [Validators.required]),
      level_id: new FormControl('', [Validators.required]),
      quiz_title: new FormControl('', [Validators.required]),
      quiz_description: new FormControl('', [Validators.required]),
      exam_date: new FormControl('', [Validators.required]),
      exam_time: new FormControl('', [Validators.required]),
      question_doc: new FormControl(''),
      answer_doc: new FormControl(''),
      id: new FormControl(''),
    });
    this.createuserb = this.fb.group({
      billing_address: new FormControl(''),
      b_last_name: new FormControl(''),
      b_name: new FormControl(''),
      bcountry: new FormControl(''),
      bcity: new FormControl(''),
      bpcode: new FormControl(''),
      bstate: new FormControl(''),
      user_id: new FormControl(''),
    });
    this.createuser13 = this.fb.group({
      email: new FormControl(''),
      name: new FormControl(''),
      last_name: new FormControl(''),
      role_id: new FormControl('5'),
      phone: new FormControl(''),
      // country: new FormControl('', [Validators.required]),
      id: new FormControl(''),
    });
    this.createuser14 = this.fb.group({
      email: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      dob: new FormControl('', [Validators.required]),

      phone: new FormControl('', [Validators.required]),
      // country: new FormControl('', [Validators.required]),
      id: new FormControl(''),
    });
    this.createuser16 = this.fb.group({
      parents_name: new FormControl(''),
      parent_last_name: new FormControl(''),
      parent_email: new FormControl(''),
      parent_phone: new FormControl(''),

      // country: new FormControl('', [Validators.required]),
      id: new FormControl(''),
    });
    this.bankdetail = this.fb.group({
      acc_number: new FormControl(''),
      bank_sort_code: new FormControl(''),
      iban: new FormControl(''),
      bic: new FormControl(''),
    });
    this.createuser15 = this.fb.group({
      // rating: new FormControl('', [Validators.required]),

      feedback: new FormControl('', [Validators.required]),
      // country: new FormControl('', [Validators.required]),
    });
    this.GetSchedulerList();
    this.GetCourseList();
    this.GetDocumentList();
    this.getCountryList();
    this.getLevelList();
    this.getTeacherSubject();
    this.GetQuestionList();
    this.getteachersList();
  }
  makePayment(amount: any) {
    console.log('this is payment getway', amount);

    const paymentHandler = (<any>window).StripeCheckout.configure({
      key: this.stripeAPIKey,
      locale: 'auto',

      token: function (stripeToken: any) {
        console.log(stripeToken);
        alert('Stripe token generated!');
      },
    });
    paymentHandler.open({
      name: 'Strip Payment Getway',
      description: '3 widgets',
      amount: amount * 100,
    });
  }

  /*------------------------------------------
  --------------------------------------------
  invokeStripe() Function
  --------------------------------------------
  --------------------------------------------*/
  // invokeStripe() {
  //   if (!window.document.getElementById('stripe-script')) {
  //     const script = window.document.createElement('script');

  //     script.id = 'stripe-script';
  //     script.type = 'text/javascript';
  //     script.src = 'https://checkout.stripe.com/checkout.js';
  //     script.onload = () => {
  //       this.paymentHandler = (<any>window).StripeCheckout.configure({
  //         key: this.stripeAPIKey,
  //         locale: 'auto',
  //         amount: 100,
  //         token: function (stripeToken: any) {
  //           console.log(stripeToken);
  //           alert('Payment has been successfull!');
  //         },
  //       });
  //     };

  //     window.document.body.appendChild(script);
  //   }
  // }
  data_user(data: any) {
    console.log(data.teacher_id, 'narender');
    this.review_teacher_id = data.teacher_id;
  }
  onCheckout(): void {
    this.http
      .post('https://suited-admin.mrmmbs.com:8999/checkout', {
        cartItems: 1,
        userId: '11',
      })
      .subscribe(async (res: any) => {
        let stripe = await loadStripe('pk_test_LI94QuNqGy2buzK0HXzimvh9');
        stripe?.redirectToCheckout({
          sessionId: res.id,
        });
      });
  }
  onOpenLoginPopup() {
    this.commonService.Setpayments(true);
  }
  CategoryList: any = [];
  getTeacherSubject() {
    var sendrequestinput: any = { id: 1 };
    this.apiService
      .post('category_list', sendrequestinput)
      .subscribe((data: any) => {
        this.CategoryList = data.data;
      });
  }
  // cashbackOfferId(){
  //   var sendrequestinput:any={amount:1000};
  //   this.apiService.post('super_payment',sendrequestinput).subscribe((data:any) => {
  //     console.log('cashbackOfferId data',data);

  //     this.CategoryList=data.data;
  //   })
  // }
  onsub(classObject: any) {
    console.log(classObject, 'classObject');

    this.commonService.SetBookClassCancelTutorModal1(true);
    this.commonService.SetBookClassCancelTutorDataModal1(classObject);
  }
  uploadFileToActivity(image: any) {
    console.log(image, 'this is image');

    this.apiService.postFile(image).subscribe(
      (data: any) => {
        console.log(data, 'this is image data');

        this.uploadedImagesData = data.name;
        this.userData.image = data.name;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getUserDetails(id: any) {
    this.apiService
      .post('teacher_details', { user_id: id })
      .subscribe((data: any) => {
        if (data.replyCode == 'success') {
          this.userData = data.data;
          if (this.userData.parent_id != null) {
            this.GetStudentParentList(this.userData.parent_id);
          }
          console.log('userData>>>>>>>>>>>>>>>', data.data);
          this.createuser6.controls['work_experience'].setValue(
            this.userData.work_experience
          );
          this.createuser6.controls['bio'].setValue(this.userData.bio);
          this.createuser6.controls['achievement'].setValue(
            this.userData.achievement
          );
          this.createuser6.controls['languages'].setValue(
            this.userData.languages
          );
          this.createuser3.controls['address'].setValue(this.userData.address);
          this.createuser3.controls['time_zone'].setValue(
            this.userData.time_zone
          );
          this.createuser3.controls['country'].setValue(this.userData.country);
          this.createuser3.controls['state'].setValue(this.userData.state);
          this.createuser3.controls['city'].setValue(this.userData.city);
          this.createuser8.controls['name'].setValue(this.userData.name);
          this.createuser8.controls['email'].setValue(this.userData.email);
          this.createuser8.controls['phone'].setValue(this.userData.phone);
          this.createuser9.controls['job_type'].setValue(
            this.userData.job_type
          );
          this.bankdetail.controls['acc_number'].setValue(
            this.userData.acc_number);
this.bankdetail.controls['bank_sort_code'].setValue(
            this.userData.bank_sort_code);
this.bankdetail.controls['iban'].setValue(this.userData.iban);
          this.bankdetail.controls['bic'].setValue(this.userData.bic);

          this.createuserb.controls['b_name'].setValue(this.userData.b_name);          
          this.createuserb.controls['b_last_name'].setValue(
            this.userData.b_last_name
          );
          this.createuserb.controls['bcountry'].setValue(
            this.userData.bcountry
          );
this.createuserb.controls['bstate'].setValue(this.userData.bstate);

this.createuserb.controls['bcity'].setValue(this.userData.bcity);

this.createuserb.controls['bpcode'].setValue(this.userData.bpcode);

this.createuserb.controls['billing_address'].setValue(
            this.userData.billing_address
          );
          if (
            this.userData.image != '' ||
            this.userData.image != null ||
            this.userData.image != undefined
          ) {
            this.uploadedImagesData = this.userData.image;
          }
          if (
            this.localData.video_url != '' ||
            this.localData.video_url != null ||
            this.localData.video_url != undefined
          ) {
            this.uploadedVideoData = this.localData.video_url;
          }
          // this.onChangeState(this.userData.state);
          if (
            this.userData.country != '' ||
            this.userData.country != undefined ||
            this.userData.country != null
          ) {
            this.onSetValue(this.userData.country, this.userData.state);
          }
        }
      });
  }
  onBankdetails() {
    if (this.bankdetail.invalid) {
      this.messageService.showWarning('Please fill all the field');
      return;
    } else {
      console.log(this.bankdetail.value);

      //  return this.bankdetail.value;
      var sendRequestData = this.bankdetail.value;
      sendRequestData.user_id = this.localData.id;

      this.apiService
        .post('add_bankdetail', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            localStorage.removeItem('user');
            var json = JSON.stringify(this.userData);
            localStorage.setItem('user', json);
            this.messageService.showSuccess(data.replyMsg);
window.location.reload()
          } else {
            this.messageService.showError(data.replyMsg);
          }
        });
    }
  }
  onSubmitBillingAddress() {
    this.submitted2 = true;
    if (this.createuserb.invalid) {
      this.messageService.showWarning('Please fill in the required fields');
      return;
    } else {
      var sendRequestData = this.createuserb.value;
      console.log(sendRequestData, 'this is sendRequestData');

      sendRequestData.user_id = this.localData.id;
      this.apiService
        .post('update_billing_address', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.getUserDetails(this.localData.id);
            this.messageService.showSuccess(data.replyMsg);
            window.location.reload();
          } else {
            this.messageService.showWarning(data.replyMsg);
          }
        });
    }
  }
  onSetValue(country_id: any, state_id: any) {
    var sendrequestinput: any = { country_id: country_id };
    this.apiService
      .post('state_list', sendrequestinput)
      .subscribe((data: any) => {
        this.loadstateList = data.data;
        if (this.loadstateList.length > 0) {
          this.onSetCity(state_id);
        }
      });
  }
  allowurl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  incor_pass:any
  onChangePassword() {
    this.createuser.get('current_password').markAsTouched();
    this.createuser.get('password').markAsTouched();
    this.createuser.get('reenterpassword').markAsTouched();
    this.submitteed = true;
    console.log('this.createuser>>>>>>>', this.createuser.value);
    if (this.createuser.invalid) {
      return;
    } else {
      console.log('this.createuser>>>>>>>', this.createuser.value);
      var sendrequestinput: any = this.createuser.value;
      sendrequestinput.sid = localStorage.getItem('sid');

      this.apiService
        .post('change_password', sendrequestinput)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.createuser.reset();
            this.submitteed = false;
            this.messageService.showSuccess(data.replyMsg);
          } else {
            this.messageService.showError(data.replyMsg);
            this.incor_pass=data.replyMsg
            console.log(this.incor_pass);
            
          }
        });
    }
  }
  clear_msg(){
    this.incor_pass=''
  }
  onSelectFile(file: any) {
    // console.log(this.file.nativeElement.value);
    console.log(file, 'selected file is');

    this.uploadFileToActivity(file.files[0]);
    if (file.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(file.files[0]);
      reader.onload = (event) => {};
    }
  }

  onUpdateProfile() {
    console.log(this.userData);
    if (this.userData.name == '') {
      this.namefield = 'Vänligen ange ditt förnamn';
      this.messageService.showError('Förnamn krävs');
    }
    if (this.userData.last_name == '') {
      this.lastname = 'Vänligen ange ditt efternamne';
      this.messageService.showError('Efternamn krävs');
    }
    if (this.userData.dob == '') {
      this.dob = 'Date of Birth';
      this.messageService.showError('Date of Birth is required');
    }
    if (this.userData.email == '') {
      this.email = 'Vänligen ange ditt lösenord';
      this.messageService.showError('Email is required');
    }
    if (this.userData.phone == '') {
      this.phone = 'Vänligen ange ditt mobilnummer';
      this.messageService.showError('Mobile number is required');
    }
    if (this.userData.bio == '') {
      this.bio = 'Please share a few tips about yourself and great aspirations';
      this.messageService.showError(
        'Please share a few tips about yourself and great aspirations'
      );
    }
    this.userData.age = 0;
    if (
      this.userData.name != '' &&
      this.userData.last_name != '' &&
      this.userData.email != '' &&
      this.userData.phone != '' &&
      this.userData.dob != '' &&
      this.userData.about != '' &&  this.phone!="Please enter a valid number"
    ) {
      this.apiService
        .post('update_user', this.userData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            localStorage.removeItem('user');
            var json = JSON.stringify(this.userData);
            localStorage.setItem('user', json);
            this.messageService.showSuccess(data.replyMsg);
            window.location.reload()

            this.router.navigate(['/student-edit-profile']);
          } else {
            this.messageService.showError(data.replyMsg);
          }
        });
    } else this.messageService.showError('complete all field');
  }
  addparent() {
    this.submitted16 = true;
    if (this.createuser16.invalid) {
      return;
    } else {
      if(this.delete_event==true){
        this.userData.parents_name=""
        this.userData.parent_last_name=""
        this.userData.parent_email=""
        this.userData.parent_phone=""
      }else{
        this.userData.parents_name=this.createuser16.value.parents_name
        this.userData.parent_last_name=this.createuser16.value.parent_last_name
        this.userData.parent_email=this.createuser16.value.parent_email
        this.userData.parent_phone=this.createuser16.value.parent_phone
      }
      var requestData: any = {};
      requestData = this.createuser16.value;
      
      requestData.id = this.userData.id;
      requestData = this.userData;


      this.apiService
        .post('update_user', this.userData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {

            localStorage.removeItem('user');
            var json = JSON.stringify(this.userData);
            localStorage.setItem('user', json);
            this.messageService.showSuccess(data.replyMsg);
            this.createuser16.reset()
            this.delete_event=false
            this.formopen=false


            this.router.navigate(['/student-edit-profile']);
          } else {
            this.messageService.showError(data.replyMsg);
          }
        });
    }
  }
  delete_event:boolean=false
  delete_parent(){
    this.delete_event=true
      this.addparent()
  }
  loadcountryList: any = [];
  countryList() {
    var sendrequestinput: any = {};
    this.apiService
      .post('country_list', sendrequestinput)
      .subscribe((data: any) => {
        this.loadcountryList = data.data;
      });
  }
  loadstateList: any = [];
  onChangeCountry(event: any) {
    console.log('event>>>>>>>>', event);
    var sendrequestinput: any = { country_id: event.value };
    this.apiService
      .post('state_list', sendrequestinput)
      .subscribe((data: any) => {
        this.loadstateList = data.data;
      });
  }
  loadcityList: any = [];
  onChangeState(event: any) {
    var sendrequestinput: any = { state_id: event.value };
    this.apiService
      .post('city_list', sendrequestinput)
      .subscribe((data: any) => {
        this.loadcityList = data.data;
      });
  }
  onSetCity(event: any) {
    var sendrequestinput: any = { state_id: event };
    this.apiService
      .post('city_list', sendrequestinput)
      .subscribe((data: any) => {
        this.loadcityList = data.data;
      });
  }

  // public getstudent_quizzes(bodyDataRequest:any){

  //   var sendrequestinput: any =bodyDataRequest
  //    sendrequestinput.student_id=this.studentId ;
  //    console.log(bodyDataRequest,'bodyDataRequest');

  //   this.apiService.post('student_quizzes_upload', sendrequestinput).subscribe((data: any) => {

  //     // console.log('student_quizzes_upload data is ',data.length);

  //     // this.student_quizzes_upload = data.data;
  //     if(data.replyCode == 'success'){
  //       this.student_quizzes_upload= data.data;

  //     }
  //   })

  // }
  onSubmit(event:any) {
    console.log(event,'this.playerName');
    
    // return this.playerName;
  }

  getNumberCellNo1(e:any)
  {
    this.phone.setNumber(e) 
    this.phone=e
  }

  public getteachersList() {
    var sendrequestinput1: any = { student_id: this.studentId };
    this.apiService
      .post('teachers_list', sendrequestinput1)
      .subscribe((data: any) => {
        this.teacherList = data.data;
      });
  }
  onChangeStep(type: any) {
    if (type == 1) {
      this.submitted1 = true;
      this.createuser1.controls['image'].setValue(this.uploadedImagesData);
      if (this.createuser1.invalid) {
        this.messageService.showWarning('Please select an image');
        return;
      } else {
        this.ProfileStep1 = false;
        this.ProfileStep3 = true;
        alert('done1');
        this.localData.image = this.uploadedImagesData;
        this.apiService
          .post('update_user', this.localData)
          .subscribe((data: any) => {
            if (data.replyCode == 'success') {
              this.getUserDetails(this.localData.id);
              localStorage.removeItem('user');
              var json = JSON.stringify(this.localData);
              localStorage.setItem('user', json);
              this.messageService.showSuccess(data.replyMsg);
              // this.router.navigate(['/student-profile'])
            } else {
              this.messageService.showError(data.replyMsg);
            }
          });
      }
    } else if (type == 2) {
      this.submitted2 = true;
      this.createuser2.controls['image'].setValue(this.uploadedImagesData);
      if (this.createuser2.invalid) {
        this.messageService.showWarning('Please select an image');
        return;
      } else {
        this.ProfileStep2 = false;
        this.ProfileStep3 = true;
        alert('done2');
        this.getUserDetails(this.localData.id);
      }
    } else if (type == 3) {
      this.submitted3 = true;
      if (this.createuser3.invalid) {
        this.messageService.showWarning('Please fill all the field');
        return;
      } else {
        this.ProfileStep3 = false;
        this.ProfileStep4 = true;
        alert('done3');
        var sendRequestData = this.createuser3.value;
        sendRequestData.user_id = this.localData.id;
        this.apiService
          .post('update_profile_address', sendRequestData)
          .subscribe((data: any) => {
            if (data.replyCode == 'success') {
              this.getUserDetails(this.localData.id);
              this.messageService.showSuccess(data.replyMsg);
            } else {
              this.messageService.showWarning(data.replyMsg);
            }
          });
      }
    } else if (type == 4) {
      this.submitted4 = true;
      if (this.createuser4.invalid) {
        this.messageService.showWarning('Please fill all the field');
        return;
      } else {
        this.ProfileStep4 = false;
        this.ProfileStep5 = true;
        alert('done4');
        var sendRequestData = this.createuser4.value;
        sendRequestData.user_id = this.localData.id;
        this.apiService
          .post('add_teacher_education', sendRequestData)
          .subscribe((data: any) => {
            if (data.replyCode == 'success') {
              this.getUserDetails(this.localData.id);
              this.messageService.showSuccess(data.replyMsg);
            } else {
              this.messageService.showWarning(data.replyMsg);
            }
          });
      }
    } else if (type == 5) {
      this.submitted5 = true;
      if (this.createuser5.invalid) {
        this.messageService.showWarning('Please fill all the field');
        return;
      } else {
        this.ProfileStep5 = false;
        this.ProfileStep6 = true;
        alert('done5');
        var sendRequestData = this.createuser5.value;
        sendRequestData.user_id = this.localData.id;
        this.apiService
          .post('add_teacher_certificate', sendRequestData)
          .subscribe((data: any) => {
            if (data.replyCode == 'success') {
              this.getUserDetails(this.localData.id);
              this.messageService.showSuccess(data.replyMsg);
            } else {
              this.messageService.showWarning(data.replyMsg);
            }
          });
      }
    } else if (type == 6) {
      this.submitted6 = true;
      if (this.createuser6.invalid) {
        this.messageService.showWarning('Please fill all the field');
        return;
      } else {
        this.ProfileStep6 = false;
        this.ProfileStep7 = true;
        alert('done6');
        var sendRequestData = this.createuser6.value;
        sendRequestData.user_id = this.localData.id;
        this.apiService
          .post('update_teacher_bio', sendRequestData)
          .subscribe((data: any) => {
            if (data.replyCode == 'success') {
              this.getUserDetails(this.localData.id);
              this.messageService.showSuccess(data.replyMsg);
            } else {
              this.messageService.showWarning(data.replyMsg);
            }
          });
      }
    } else if (type == 7) {
      this.submitted7 = true;
      if (this.createuser7.invalid) {
        this.messageService.showWarning('Please fill all the field');
        return;
      } else {
        this.ProfileStep7 = false;
        // this.ProfileStep7=true;
        alert('done7');
        var sendRequestData = this.createuser7.value;
        sendRequestData.user_id = this.localData.id;
        this.apiService
          .post('update_bank_details', sendRequestData)
          .subscribe((data: any) => {
            if (data.replyCode == 'success') {
              this.getUserDetails(this.localData.id);
              this.messageService.showSuccess(data.replyMsg);
            } else {
              this.messageService.showWarning(data.replyMsg);
            }
          });
      }
    }
  }
  onChangeStepBack(type: any) {
    if (type == 1) {
      this.ProfileStep1 = true;
      this.ProfileStep2 = false;
      this.ProfileStep3 = false;
      this.ProfileStep4 = false;
      this.ProfileStep5 = false;
      this.ProfileStep6 = false;
      this.ProfileStep7 = false;
    } else if (type == 2) {
      this.ProfileStep1 = true;
      this.ProfileStep2 = false;
      this.ProfileStep3 = false;
      this.ProfileStep4 = false;
      this.ProfileStep5 = false;
      this.ProfileStep6 = false;
      this.ProfileStep7 = false;
    } else if (type == 3) {
      this.ProfileStep1 = true;
      this.ProfileStep2 = false;
      this.ProfileStep3 = false;
      this.ProfileStep4 = false;
      this.ProfileStep5 = false;
      this.ProfileStep6 = false;
      this.ProfileStep7 = false;
    } else if (type == 4) {
      this.ProfileStep1 = false;
      this.ProfileStep2 = false;
      this.ProfileStep3 = true;
      this.ProfileStep4 = false;
      this.ProfileStep5 = false;
      this.ProfileStep6 = false;
      this.ProfileStep7 = false;
    } else if (type == 5) {
      this.ProfileStep1 = false;
      this.ProfileStep2 = false;
      this.ProfileStep3 = false;
      this.ProfileStep4 = true;
      this.ProfileStep5 = false;
      this.ProfileStep6 = false;
      this.ProfileStep7 = false;
    } else if (type == 6) {
      this.ProfileStep1 = false;
      this.ProfileStep2 = false;
      this.ProfileStep3 = false;
      this.ProfileStep4 = false;
      this.ProfileStep5 = true;
      this.ProfileStep6 = false;
      this.ProfileStep7 = false;
    } else if (type == 7) {
      this.ProfileStep1 = false;
      this.ProfileStep2 = false;
      this.ProfileStep3 = false;
      this.ProfileStep4 = false;
      this.ProfileStep5 = false;
      this.ProfileStep6 = true;
      this.ProfileStep7 = false;
    }
  }
  Content_Type: any = 'Redigera och Uppdatera Profil';
  onChangeTypeContent(value: any) {
    this.Content_Type = value;
    this.getMyClass_upcoming(this.bodyRequestData);
  }
  onSubmitAddress() {
    this.submitted3 = true;
    if (this.createuser3.invalid) {
      this.messageService.showWarning('Please fill all the field');
      return;
    } else {
      this.ProfileStep3 = false;
      this.ProfileStep4 = true;
      // alert('done3');
      var sendRequestData = this.createuser3.value;
      sendRequestData.user_id = this.localData.id;
      this.apiService
        .post('update_profile_address', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.getUserDetails(this.localData.id);
            this.messageService.showSuccess(data.replyMsg);
          } else {
            this.messageService.showWarning(data.replyMsg);
          }
        });
    }
  }
  onSubmitInfo() {
    this.submitted6 = true;
    console.log('this.createuser6>>>>>>', this.createuser6.value);
    if (this.createuser6.invalid) {
      this.messageService.showWarning('Please fill all the field');
      return;
    } else {
      this.ProfileStep6 = false;
      this.ProfileStep7 = true;
      // alert('done6');
      var sendRequestData = this.createuser6.value;
      sendRequestData.user_id = this.localData.id;
      this.apiService
        .post('update_teacher_bio', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.getUserDetails(this.localData.id);
            this.messageService.showSuccess(data.replyMsg);
          } else {
            this.messageService.showWarning(data.replyMsg);
          }
        });
    }
  }
  onSubmitQualification() {
    this.submitted4 = true;
    if (this.createuser4.invalid) {
      this.messageService.showWarning('Please fill all the field');
      return;
    } else {
      this.ProfileStep4 = false;
      this.ProfileStep5 = true;
      // alert('done4');
      var sendRequestData = this.createuser4.value;
      sendRequestData.user_id = this.localData.id;
      this.apiService
        .post('add_teacher_education', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.getUserDetails(this.localData.id);
            this.messageService.showSuccess(data.replyMsg);
          } else {
            this.messageService.showWarning(data.replyMsg);
          }
        });
    }
  }
  onSubmitpersonalInfo() {
    this.submitted8 = true;
    this.createuser8.controls['image'].setValue(this.uploadedImagesData);
    if (this.createuser8.invalid) {
      return;
    } else {
      var sendRequestData: any = {};
      sendRequestData = this.userData;
      sendRequestData.name = this.createuser8.value.name;
      sendRequestData.phone = this.createuser8.value.phone;
      sendRequestData.image = this.uploadedImagesData;
      this.apiService
        .post('update_user', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.getUserDetails(this.localData.id);
            this.messageService.showSuccess(data.replyMsg);
          } else {
            this.messageService.showError(data.replyMsg);
          }
        });
    }
  }
  onSubmitJobType() {
    this.submitted9 = true;
    if (this.createuser9.invalid) {
      return;
    } else {
      var sendRequestData: any = {};
      sendRequestData = this.userData;
      sendRequestData.job_type = this.createuser9.value.job_type;
      this.apiService
        .post('update_user', sendRequestData)
        .subscribe((data: any) => {
          if (data.replyCode == 'success') {
            this.getUserDetails(this.localData.id);
            this.messageService.showSuccess(data.replyMsg);
          } else {
            this.messageService.showError(data.replyMsg);
          }
        });
    }
  }
  menuClick(id: any) {
    if ($('#menu-' + id).hasClass('open')) {
      $('#menu-' + id).removeClass('open');
      // $('body').removeClass('layout-expanded');
    } else {
      $('.sidenav-item').removeClass('open');
      $('#menu-' + id).addClass('open');
      // $('body').removeClass('layout-expanded');
    }
  }

  GetSchedulerList() {
    this.apiService
      .post('teacher_schedule_days_list', { user_id: this.userData.id })
      .subscribe(
        (res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              if (res.data.length > 0) {
                this.SchedulerList = res.data;
                console.log('SchedulerList::::', this.SchedulerList);
                if (this.SchedulerList.available == 1) {
                  // this.onDateSelect(this.SchedulerList.available,this.SchedulerSlotID,this.Index_Value);
                } else {
                  // this.onDateSelect(this.SchedulerSlotID,this.Index_Value);
                }
              } else {
                this.Teacher_Details_Id = '';
                this.Edit_Flag = true;
              }
            }
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
  }
  CourseList: any = [];
  GetCourseList() {
    console.log('in function id is', this.localData.id);

    this.apiService
      .post('student_courses_list', { student_id: this.localData.id })
      .subscribe(
        (res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              if (res.data.length > 0) {
                this.CourseList = res.data;
                console.log('CourseList::::', this.CourseList);
              } else {
                // this.Teacher_Details_Id ='';
                // this.Edit_Flag=true;
              }
            }
          } else {
            console.log(
              'in function api is not working',
              this.localData.id,
              res.error
            );
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
  }

  aval_courses_list_for_student() {
    this.apiService
      .post('aval_courses_list_for_student', { student_id: this.localData.id })
      .subscribe(
        (res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              if (res.data.length > 0) {
                this.aval_CourseList = res.data;
                console.log(
                  'CourseList for student that can purchase::::',
                  this.aval_CourseList
                );
              } else {
              }
            }
          } else {
            console.log(
              'in function api is not working',
              this.localData.id,
              res.error
            );
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
  }

  CountryList: any = [];
  getCountryList() {
    var sendrequestinput: any = { id: 1 };
    this.apiService
      .post('country_list', sendrequestinput)
      .subscribe((data: any) => {
        this.CountryList = data.data;
      });
  }
  DocumentList: any = [];
  GetDocumentList() {
    this.apiService
      .post('user_documents_list', { user_id: this.userData.id })
      .subscribe(
        (res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              if (res.data.length > 0) {
                this.DocumentList = res.data;
                console.log('DocumentList::::', this.DocumentList);
              } else {
                // this.Teacher_Details_Id ='';
                // this.Edit_Flag=true;
              }
            }
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
  }
  onDateSelect(flag: any, id: any, index: any) {
    if (flag == '0') {
      this.SchedulerSlotID = id;
      this.Index_Value = index;
      if ($('#menu-' + index).hasClass('clickable')) {
        $('#menu-' + index).removeClass('clickable');
      } else {
        $('.sidenav-item').removeClass('clickable');
        $('#menu-' + index).addClass('clickable');
      }
      // console.log('dateObject::::',id);
      var requestData = {
        user_id: this.userData.id,
        schedule_id: id,
      };
      this.Date_Select_Flag = false;
      this.apiService
        .post('teacher_schedule_slots_list', requestData)
        .subscribe(
          (res: any) => {
            if (!res.error) {
              if (res.replyCode == 'success') {
                if (res.data.length > 0) {
                  this.SchedulerSlotList = res.data;
                } else {
                  this.Teacher_Details_Id = '';
                  this.Edit_Flag = true;
                }
                // console.log('Scheduler:::Slot', this.SchedulerSlotList);
              }
            }
          },
          (error) => {
            this.messageService.showError('Oops! Something went wrong!');
          }
        );
    } else {
      this.Index_Value = 0;
      this.Date_Select_Flag = true;
    }
  }
  onChangeStatusbackup(object: any, value: any, index: any) {
    // console.log('object:::',object);
    console.log('asdasfds', object, value, index);

    this.SchedulerSlotID = object.id;
    this.Index_Value = index;
    var available;
    if (value == true) {
      available = '1';
    } else {
      available = '0';
    }
    var requestData = {
      id: this.SchedulerSlotID,
      available: available,
    };
    this.apiService.post('update_schedule_day_status', requestData).subscribe(
      (res: any) => {
        if (!res.error) {
          if (res.replyCode == 'error') {
            this.messageService.showError(res.replyMsg);
          } else {
            this.GetSchedulerList();
            this.messageService.showSuccess(res.replyMsg);
            // if(value == true){
            this.onDateSelect(
              object.available,
              this.SchedulerSlotID,
              this.Index_Value
            );
          }

          // }
        } else {
          this.messageService.showError(res.replyMsg);
        }
      },
      (error) => {
        this.messageService.showError('Oops! Something went wrong!');
      }
    );
  }
  onChangeSlotStatusbackup(id: any, value: any) {
    // console.log('value:::',value);
    var available;
    if (value == true) {
      available = '1';
    } else {
      available = '0';
    }
    var requestData = {
      id: id,
      available: available,
    };
    this.apiService.post('update_schedule_slot_status', requestData).subscribe(
      (res: any) => {
        if (!res.error) {
          this.GetSchedulerList();
          this.messageService.showSuccess(res.replyMsg);
        } else {
          this.messageService.showError(res.replyMsg);
        }
      },
      (error) => {
        this.messageService.showError('Oops! Something went wrong!');
      }
    );
  }

  onChangeStatus(object: any, event: any) {
    let VALUE = event.checked;
    // console.log('object:::',object);
    this.SchedulerSlotID = object.id;
    // this.Index_Value=index;
    var available;
    if (VALUE == true) {
      available = '1';
    } else {
      available = '0';
    }
    var requestData = {
      id: this.SchedulerSlotID,
      available: available,
    };
    this.apiService.post('update_schedule_day_status', requestData).subscribe(
      (res: any) => {
        if (!res.error) {
          if (res.replyCode == 'error') {
            this.messageService.showError(res.replyMsg);
          } else {
            this.GetSchedulerList();
            this.messageService.showSuccess(res.replyMsg);
            // if(value == true){
            this.onDateSelect(
              object.available,
              this.SchedulerSlotID,
              this.Index_Value
            );
          }

          // }
        } else {
          this.messageService.showError(res.replyMsg);
        }
      },
      (error) => {
        this.messageService.showError('Oops! Something went wrong!');
      }
    );
  }
  onChangeSlotStatus(id: any, event: any) {
    var available;
    let VALUE = event.checked;
    if (VALUE == true) {
      available = '1';
    } else {
      available = '0';
    }
    var requestData = {
      id: id,
      available: available,
    };
    this.apiService.post('update_schedule_slot_status', requestData).subscribe(
      (res: any) => {
        if (!res.error) {
          this.GetSchedulerList();
          this.messageService.showSuccess(res.replyMsg);
        } else {
          this.messageService.showError(res.replyMsg);
        }
      },
      (error) => {
        this.messageService.showError('Oops! Something went wrong!');
      }
    );
  }
  onOpenSubjectModal() {
    this.commonService.SetAddSubject(true);
  }
  addCourseFlag: boolean = false;
  onAddCourse() {
    this.addCourseFlag = true;
  }
  LevelList: any = [];
  getLevelList() {
    var sendrequestinput: any = { id: 1 };
    this.apiService
      .post('age_group_list_dropdown', sendrequestinput)
      .subscribe((data: any) => {
        this.LevelList = data.data;
      });
  }
  onSubmitCourse() {
    this.submitted10 = true;
    if (this.createuser10.invalid) {
      return;
    } else {
      var requestData: any = {};
      requestData = this.createuser10.value;
      requestData.created_by = this.userData.id;
      requestData.image = this.uploadedImagesData;
      requestData.id = this.EditCourseId;
      this.apiService.post('add_courses', requestData).subscribe(
        (res: any) => {
          if (!res.error) {
            this.createuser10.reset();
            this.EditCourseId = '';
            this.submitted10 = false;
            this.addCourseFlag = false;
            this.GetCourseList();
            this.messageService.showSuccess(res.replyMsg);
          } else {
            this.messageService.showError(res.replyMsg);
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
    }
  }
  EditCourseId: any = '';
  onEditCourse(courseObject: any) {
    console.log(courseObject);
    this.addCourseFlag = true;
    this.EditCourseId = courseObject.id;
    this.createuser10.controls['course_name'].setValue(
      courseObject.course_name
    );
    this.createuser10.controls['description'].setValue(
      courseObject.description
    );
    this.createuser10.controls['course_type'].setValue(
      courseObject.course_type
    );
    this.createuser10.controls['age_group_id'].setValue(
      courseObject.age_group_id
    );
    this.createuser10.controls['amount'].setValue(courseObject.amount);
    this.createuser10.controls['curriculum'].setValue(courseObject.curriculum);
    this.createuser10.controls['price_per_classes'].setValue(
      courseObject.price_per_classes
    );
    this.createuser10.controls['fake_price'].setValue(courseObject.fake_price);
    this.createuser10.controls['no_of_classes'].setValue(
      courseObject.no_of_classes
    );
    this.createuser10.controls['hands_on_activities'].setValue(
      courseObject.hands_on_activities
    );
    if (
      courseObject.image != undefined ||
      courseObject.image != null ||
      courseObject.image != ''
    ) {
      this.uploadedImagesData = courseObject.image;
    }
  }
  onChangeCourseStatus(object: any, event: any) {
    let VALUE = event;
    this.SchedulerSlotID = object.id;
    var requestData = {
      id: this.SchedulerSlotID,
      status: VALUE,
    };
    this.apiService.post('update_courses_status', requestData).subscribe(
      (res: any) => {
        if (!res.error) {
          if (res.replyCode == 'error') {
            this.messageService.showError(res.replyMsg);
          } else {
            this.GetCourseList();
            this.messageService.showSuccess(res.replyMsg);
          }
        } else {
          this.messageService.showError(res.replyMsg);
        }
      },
      (error) => {
        this.messageService.showError('Oops! Something went wrong!');
      }
    );
  }
  MyClass: any = [];
  MyClass_complete: any = [];
  MyClass_upcoming: any = [];
  MyClass_cancel: any = [];
  getMyClass(bodyDataRequest: any) {
    var sendrequestinput: any = bodyDataRequest;
    sendrequestinput.sid = localStorage.getItem('sid');
    this.apiService
      .post('students_classes_list', sendrequestinput)
      .subscribe((data: any) => {
        this.MyClass = data.data;
        this.total_count = data.total_count;

        console.log(this.MyClass, 'dasda');

        this.MyClass.forEach((element: any) => {
          var obj = element;
          Object.assign(obj, { assign_flag: false });
        });
        // console.log(this.MyClass,"this is my all classess");
      });
  }
  getMyClass_complete(bodyDataRequest: any) {
    var sendrequestinput: any = bodyDataRequest;
    sendrequestinput.sid = localStorage.getItem('sid');
    this.apiService
      .post('students_classes_list_complete', sendrequestinput)
      .subscribe((data: any) => {
        this.MyClass_complete = data.data;
        this.total_count_complete = data.total_count;

        // console.log(this.MyClass, 'dasda');

        this.MyClass_complete.forEach((element: any) => {
          var obj = element;
          Object.assign(obj, { assign_flag: false });
        });
        // console.log(this.MyClass,"this is my all classess");
      });
  }
  getMyClass_cancel(bodyDataRequest: any) {
    var sendrequestinput: any = bodyDataRequest;
    sendrequestinput.sid = localStorage.getItem('sid');
    this.apiService
      .post('students_classes_list_cancel', sendrequestinput)
      .subscribe((data: any) => {
        this.MyClass_cancel = data.data;
        this.total_count_cancel = data.total_count;


        this.MyClass_cancel.forEach((element: any) => {
          var obj = element;
          Object.assign(obj, { assign_flag: false });
        });
        // console.log(this.MyClass,"this is my all classess");
      });
  }
  getMyClass_upcoming(bodyDataRequest: any) {

    var sendrequestinput: any = bodyDataRequest;
    sendrequestinput.sid = localStorage.getItem('sid');
    this.apiService
      .post('students_classes_list_upcoming', sendrequestinput)
      .subscribe((data: any) => {
        this.MyClass_upcoming = data.data;
        this.total_count_upcoming = data.total_count;


        this.MyClass_upcoming.forEach((element: any) => {
          var obj = element;
          Object.assign(obj, { assign_flag: false });
        });
        // console.log(this.MyClass,"this is my all classess");
      });
  }
  student_quizzes_upload: any = [];

  getstudent_quizzes(bodyDataRequest: any) {
    var sendrequestinput: any = bodyDataRequest;
    sendrequestinput.student_id = this.studentId;
    this.apiService
      .post('student_quizzes_upload', sendrequestinput)
      .subscribe((data: any) => {
        this.student_quizzes_upload = data.data;
        this.total_count = data.totalCount;
        console.log(this.total_count, 'asdasx');
        this.total_count = data.totalCount;

        this.student_quizzes_upload.forEach((element: any) => {
          var obj = element;
          Object.assign(obj, { assign_flag: false });
        });
      });
  }
  // public getstudent_quizzes(bodyDataRequest:any){

  //   var sendrequestinput: any =bodyDataRequest
  //    sendrequestinput.student_id=this.studentId ;
  //    console.log(bodyDataRequest,'bodyDataRequest');

  //   this.apiService.post('student_quizzes_upload', sendrequestinput).subscribe((data: any) => {

  //     // console.log('student_quizzes_upload data is ',data.length);

  //     // this.student_quizzes_upload = data.data;
  //     if(data.replyCode == 'success'){
  //       this.student_quizzes_upload= data.data;
  //       this.total_count=data.data.length;
  //       this.student_quizzes_upload.forEach((element:any) => {
  //         var obj=element;
  //         Object.assign(obj,{assign_flag:false})
  //       });
  //     }
  //   })

  // }
  onCancelClassPopup(classObject: any) {
    this.commonService.SetBookClassCancelTutorModal(true);
    this.commonService.SetBookClassCancelTutorDataModal(classObject);
  }
  onSelectDocumentFile(file: any) {
    // console.log(this.file.nativeElement.value);
    this.uploadFileToDocumentActivity(file.files[0]);
    if (file.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(file.files[0]);
      reader.onload = (event) => {};
    }
  }
  uploadFileToDocumentActivity(image: any) {
    this.apiService.postFile(image).subscribe(
      (data: any) => {
        this.uploadedDocumentImagesData = data.name;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  onSubmitDocument() {
    this.submitted11 = true;
    console.log('this.createuser11', this.createuser11);
    this.createuser11.controls['document'].setValue(
      this.uploadedDocumentImagesData
    );
    if (this.createuser11.invalid) {
      return;
    } else {
      var requestData: any = {};
      requestData = this.createuser11.value;
      requestData.document = this.uploadedDocumentImagesData;
      requestData.user_id = this.userData.id;
      this.apiService.post('add_user_documents', requestData).subscribe(
        (res: any) => {
          if (!res.error) {
            this.uploadedDocumentImagesData = '';
            this.createuser11.reset();
            this.submitted11 = false;
            this.GetDocumentList();
            this.messageService.showSuccess(res.replyMsg);
          } else {
            this.messageService.showError(res.replyMsg);
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
    }
  }
  onDeleteDocument(object: any, event: any) {
    let VALUE = event;
    this.SchedulerSlotID = object.id;
    var requestData = {
      id: this.SchedulerSlotID,
      status: VALUE,
    };
    this.apiService.post('update_user_documents_status', requestData).subscribe(
      (res: any) => {
        if (!res.error) {
          if (res.replyCode == 'error') {
            this.messageService.showError(res.replyMsg);
          } else {
            this.GetDocumentList();
            this.messageService.showSuccess(res.replyMsg);
          }
        } else {
          this.messageService.showError(res.replyMsg);
        }
      },
      (error) => {
        this.messageService.showError('Oops! Something went wrong!');
      }
    );
  }
  editDocumentFlag: boolean = false;
  onEditDocument(documentObject: any) {
    console.log(documentObject);
    this.editDocumentFlag = true;
    this.uploadedDocumentImagesData = documentObject.document;
    this.createuser11.controls['id'].setValue(documentObject.id);
    this.createuser11.controls['document_name'].setValue(
      documentObject.document_name
    );
    this.createuser11.controls['document'].setValue(documentObject.document);
  }

  addQuestionFlag: boolean = false;
  onAddQuestion() {
    this.addQuestionFlag = true;
  }
  uploadedQuestionData: any;
  uploadedAnswerData: any;
  onSelectQuestionFile(file: any) {
    this.uploadFileToQuestionActivity(file.files[0]);
    if (file.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(file.files[0]);
      reader.onload = (event) => {};
    }
  }

  uploadFileToAnswerActivity(image: any) {
    this.apiService.postFile(image).subscribe(
      (data: any) => {
        this.uploadedAnswerData = data.name;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSelectAnswerFile(file: any) {
    this.uploadFileToAnswerActivity(file.files[0]);
    if (file.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(file.files[0]);
      reader.onload = (event) => {};
    }
  }

  uploadFileToQuestionActivity(image: any) {
    this.apiService.postFile(image).subscribe(
      (data: any) => {
        this.uploadedQuestionData = data.name;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  onSubmitQuestionAnswer(data: any) {
    console.log(data, 'data');
    var sendRequestData: any = data;

    sendRequestData.answer_doc = this.uploadedAnswerData;
    sendRequestData.student_id = this.userData.id;
    sendRequestData.student_name = this.userData.name + this.userData.last_name;
    sendRequestData.quiz_id = data.id;
    sendRequestData.teacher_id = data.created_by;
    sendRequestData.quiz_title = data.quiz_title;
    sendRequestData.category_id = data.category_id;
    sendRequestData.upload_date = new Date();
    console.log(sendRequestData.answer_doc,'answer')
    if(sendRequestData.answer_doc){

    console.log('sendRequestData>>>>>>', sendRequestData);
    this.apiService.post('student_quiz_uploaded', sendRequestData).subscribe(
      (res: any) => {
        if (!res.error) {
          if (res.replyCode == 'error') {
            this.createuser12.reset();
            this.addQuestionFlag = false;
            this.messageService.showError(res.replyMsg);
          } else {
            this.GetQuestionList();
            this.messageService.showSuccess(res.replyMsg);
          }
        } else {
          this.messageService.showError(res.replyMsg);
        }
      },
      (error) => {
        this.messageService.showError('Oops! Something went wrong!');
      }
    );
    }

  else{
    this.messageService.showError('upload answer sheet!');

    

  }
  
  }
  QuestionList: any = [];
  GetQuestionList() {
    this.apiService
      .post('quizzes_list', { created_by: this.userData.id })
      .subscribe(
        (res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              if (res.data.length > 0) {
                this.QuestionList = res.data;
                console.log('QuestionList::::', this.QuestionList);
              } else {
                // this.Teacher_Details_Id ='';
                // this.Edit_Flag=true;
              }
            }
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
  }
  StudentParentDetails: any = {};
  GetStudentParentList(parent_id: any) {
    this.apiService.post('parent_details', { parent_id: parent_id }).subscribe(
      (res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            if (res.data) {
              this.StudentParentDetails = res.data[0];
              this.createuser13.controls['name'].setValue(
                this.StudentParentDetails?.name
              );
              this.createuser13.controls['email'].setValue(
                this.StudentParentDetails?.email
              );
              this.createuser13.controls['phone'].setValue(
                this.StudentParentDetails?.phone
              );
              this.createuser13.controls['last_name'].setValue(
                this.StudentParentDetails?.last_name
              );
              console.log(
                'StudentParentDetails::::',
                this.StudentParentDetails
              );
            }
          }
        }
      },
      (error) => {
        this.messageService.showError('Oops! Something went wrong!');
      }
    );
  }
  onOpenLogout() {
    this.commonService.SetLogoutPopup(true);
  }
  onUpdateParentProfile() {
    this.submitted13 = true;
    // if(this.createuser13.value.last_name){
    //   this.messageService.showError("Last name missing");
    // }
    var sendRequestData: any = this.createuser13.value;
    console.log('sendRequestData>>>>>>>>>', sendRequestData);
    if (this.createuser13.invalid) {
      return;
    } else {
      var requestname = '';
      if (this.userData.parent_id != null) {
        sendRequestData.id = this.userData.parent_id;
        requestname = 'update_parent';
      } else {
        requestname = 'add_parent';
        sendRequestData.sid = localStorage.getItem('sid');
      }
      console.log('sendRequestData>>>>>>2222', sendRequestData);
      this.apiService.post(requestname, sendRequestData).subscribe(
        (res: any) => {
          if (!res.error) {
            if (res.replyCode == 'error') {
              this.getUserDetails(this.localData.id);
              this.createuser13.reset();
              this.submitted13 = false;
              this.messageService.showError(res.replyMsg);
            } else {
              this.GetStudentParentList(this.userData.parent_id);
              this.messageService.showSuccess(res.replyMsg);
            }
          } else {
            this.messageService.showError(res.replyMsg);
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
    }
  }

  COurseList: any = [];
  GetCOurseList() {
    this.apiService
      .post('user_documents_list', { user_id: this.userData.id })
      .subscribe(
        (res: any) => {
          if (!res.error) {
            if (res.replyCode == 'success') {
              if (res.data.length > 0) {
                this.COurseList = res.data;
                console.log('COurseList::::', this.COurseList);
              } else {
                // this.Teacher_Details_Id ='';
                // this.Edit_Flag=true;
              }
            }
          }
        },
        (error) => {
          this.messageService.showError('Oops! Something went wrong!');
        }
      );
  }
  onSubmitPhotoVideo() {
    var sendRequestData: any = {};
    sendRequestData.user_id = this.localData.id;
    sendRequestData.video_url = this.uploadedVideoData;
    sendRequestData.image = this.uploadedImagesData;
    this.apiService
      .post('update_video_url', sendRequestData)
      .subscribe((data: any) => {
        if (data.replyCode == 'success') {
          localStorage.removeItem('user');
          var json = JSON.stringify(this.userData);
          localStorage.setItem('user', json);
          window.location.reload()
          this.getUserDetails(this.localData.id);
          this.messageService.showSuccess(data.replyMsg);
        } else {
          this.messageService.showWarning(data.replyMsg);
        }
      });
  }

  uploadedVideoData: any = '';
  uploadVideoFileToActivity(image: any) {
    this.apiService.postFile(image).subscribe(
      (data: any) => {
        this.uploadedVideoData = data.name;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  onSelectVideoFile(file: any) {
    this.uploadVideoFileToActivity(file.files[0]);
    if (file.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(file.files[0]);
      reader.onload = (event) => {};
    }
  }
  booked_classess_data() {
    var sendRequestData: any = {};
    sendRequestData.student_id = this.localData.id;
    this.apiService
      .post('student_classes_list', sendRequestData)
      .subscribe((res: any) => {
        console.log('class data responce is', res);
        this.student_booked_class = res.data;
      });
  }
  showtransection() {
    var sendRequestData: any = {};
    sendRequestData.id = this.localData.id;
    this.apiService.post('tran_data', sendRequestData).subscribe((res: any) => {
      console.log(res, 'trans_responce');
    });
  }
  // generate_jwt(){
  //   var sendRequestData:any = {};
  //   var sendRequestData_c:any = {};
  //       sendRequestData.id = this.localData.id;
  //     var  sendRequestData_c = this.localData;

  //       this.apiService.post('generate_jwt', sendRequestData).subscribe((res: any) => {
  //       console.log(res.data,'generate_jwt');
  //       sendRequestData_c.access_token = res.data;
  //       this.apiService.post('merithub_users_list', sendRequestData_c).subscribe((res: any) => {
  //             console.log(res,'generate_jwt');
  //         })

  //       })

  // }
  // MyClass:any=[];
  // getMyClass(){
  //   var sendrequestinput:any={sid :localStorage.getItem('sid')};
  //   this.apiService.post('teacher_classes_list',sendrequestinput).subscribe((data:any) => {
  //     this.MyClass=data.data;
  //   })
  // }
  resetfilter() {
    this.bodyDataRequest['start'] = '0';
    this.bodyDataRequest['keyword'] = '';
    this.getstudent_quizzes(this.bodyDataRequest);
    //  this.Gettutor_payout(this.bodyDataRequest);
  }
  // onChangeLimit(limit:any){
  //   this.p = 1;
  //   this.bodyDataRequest['start'] = "0";
  //   this.bodyDataRequest['limit'] = String(limit);
  //   this.getstudent_quizzes(this.bodyDataRequest);
  // }
  // onPageChange(event:any) {
  //   this.apiService.loadingShow = true;
  //   this.p = event;
  //   // console.log('event::::', event);
  //   this.bodyDataRequest['start'] = String((event * this.bodyDataRequest['limit']) - this.bodyDataRequest['limit']);
  //   this.getstudent_quizzes(this.bodyDataRequest);
  // }

  onSearch() {
    this.p = 1;
    this.bodyDataRequest['start'] = '0';
    this.bodyDataRequest['limit'] = '0';
    this.bodyDataRequest['keyword'] = this.keyValue;
    this.getstudent_quizzes(this.bodyDataRequest);
    //  this.Gettutor_payout(this.bodyDataRequest);
  }
  onChangeLimit(limit: any) {
    this.p = 1;
    this.bodyDataRequest['start'] = '0';
    this.bodyDataRequest['limit'] = String(limit);
    this.getstudent_quizzes(this.bodyDataRequest);
  }

  onPageChange(event: any) {
    this.apiService.loadingShow = true;
    this.p = event;
    console.log('event::::', event);
    this.bodyDataRequest['start'] = String(
      event * this.bodyDataRequest['limit'] - this.bodyDataRequest['limit']
    );
    this.getstudent_quizzes(this.bodyDataRequest);
  }
  onChangeLimit2(limit: any) {
    this.p = 1;
    this.bodyDataRequest['start'] = '0';
    this.bodyDataRequest['limit'] = String(limit);
    this.getMyClass(this.bodyDataRequest);
    // this.getMyClass_complete(this.bodyDataRequest);
    // this.getMyClass_cancel(this.bodyDataRequest);
    // this.getMyClass_upcoming(this.bodyDataRequest);
  }

  onPageChange2(event: any) {
    this.apiService.loadingShow = true;
    this.p = event;
    console.log('event::::', event);
    this.bodyDataRequest['start'] = String(
      event * this.bodyDataRequest['limit'] - this.bodyDataRequest['limit']
    );
    this.getMyClass(this.bodyDataRequest);
    // this.getMyClass_complete(this.bodyDataRequest);
    // this.getMyClass_cancel(this.bodyDataRequest);
    // this.getMyClass_upcoming(this.bodyDataRequest);
  }
  onChangeLimitupc(limit: any) {
    this.pu = 1;
    this.bodyDataRequestu['start'] = '0';
    this.bodyDataRequestu['limit'] = String(limit);
   
    this.getMyClass_upcoming(this.bodyDataRequestu);
  }

  onPageChangeupc(event: any) {
    this.apiService.loadingShow = true;
    this.pu = event;
    console.log('event::::', event);
    this.bodyDataRequestu['start'] = String(
      event * this.bodyDataRequestu['limit'] - this.bodyDataRequestu['limit']
    );
   
    this.getMyClass_upcoming(this.bodyDataRequestu);
  }
  onChangeLimitcom(limit: any) {
    this.pc = 1;
    this.bodyDataRequestc['start'] = '0';
    this.bodyDataRequestc['limit'] = String(limit);
    
    this.getMyClass_complete(this.bodyDataRequestc);

  }

  onPageChangecom(event: any) {
    this.apiService.loadingShow = true;
    this.pc = event;
    console.log('event::::', event);
    this.bodyDataRequestc['start'] = String(
      event * this.bodyDataRequestc['limit'] - this.bodyDataRequestc['limit']
    );
   
    this.getMyClass_complete(this.bodyDataRequestc);

  }
  onChangeLimitcan(limit: any) {
    this.pca = 1;
    this.bodyDataRequestca['start'] = '0';
    this.bodyDataRequestca['limit'] = String(limit);
   
    this.getMyClass_cancel(this.bodyDataRequestca);

  }

  onPageChangecan(event: any) {
    this.apiService.loadingShow = true;
    this.pca = event;
    console.log('event::::', event);
    this.bodyDataRequestca['start'] = String(
      event * this.bodyDataRequestca['limit'] - this.bodyDataRequestca['limit']
    );
   
    this.getMyClass_cancel(this.bodyDataRequestca);
   
  }
  // onSearch() {
  //   this.p = 1;
  //   this.bodyDataRequest['start'] = "0";
  //   this.bodyDataRequest['limit'] = "20";
  //   this.bodyDataRequest['keyword'] = this.keyValue;
  //   this.getTutorList(this.bodyDataRequest);
  // }
  teacher_Recensioner: any;

  teacher_review_rating() {
    this.submitted15 = true;
    if (this.createuser15.invalid) {
      return;
    } else {
      var requestData: any = {};
      requestData = this.createuser15.value;

      requestData.rating = this.selectedValue;
      requestData.teacher_id = this.review_teacher_id;
      requestData.stu_name =
        this.localData.name + ' ' + this.localData.last_name;
      console.log(requestData, 'requestData');

      this.apiService
        .post('teacher_review_rating', requestData)
        .subscribe((res: any) => {
          console.log('class data responce is', res);
          if (res.replyCode == 'success') {
            this.messageService.showSuccess(res.replyMsg);
          }
          window.location.reload();
        });
    }
  }
  parent_add() {
    this.submitted15 = true;
    if (this.createuser15.invalid) {
      return;
    } else {
      var requestData: any = {};
      requestData = this.createuser15.value;

      requestData.id = this.localData.id;
      console.log(requestData, 'requestData');

      this.apiService.post('update_user', requestData).subscribe((res: any) => {
        console.log('class data responce is', res);
        if (res.replyCode == 'success') {
          this.messageService.showSuccess(res.replyMsg);
        }
        window.location.reload();
      });
    }
  }
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: number = 0;
  isMouseover = true;

  countStar(star: number) {
    this.isMouseover = false;
    this.selectedValue = star;
    console.log(this.selectedValue, 'select');
  }

  addClass(star: number) {
    if (this.isMouseover) {
      this.selectedValue = star;
      console.log(this.selectedValue, 'select2');
    }
  }
  removeClass() {
    if (this.isMouseover) {
      this.selectedValue = 0;
    }
  }

  clearDateFilter() {
    this.startDate = null; // or this.startDate = new Date();
    this.endDate = null; // or this.endDate = new Date();
    this.bodyRequestData.start_date = ""; 
    this.bodyRequestData.end_date = "";
    // this.Gettutor_payout(this.bodyRequestData); 
    this.getMyClass(this.bodyRequestData);
 
    this.getMyClass_complete(this.bodyRequestData);
    this.getMyClass_cancel(this.bodyRequestData);
    this.getMyClass_upcoming(this.bodyRequestData);
  }
  printDate() {
    console.log(this.startDate);
    console.log(this.endDate);
    this.bodyRequestData.start_date = this.startDate;
    this.bodyRequestData.end_date = this.endDate;
    // this.Gettutor_payout(this.bodyDataRequest);
    this.getMyClass(this.bodyRequestData);
     this.getMyClass_complete(this.bodyDataRequest);
    this.getMyClass_cancel(this.bodyDataRequest);
    this.getMyClass_upcoming(this.bodyDataRequest);
  }
  startDate: Date | any;

  endDate: Date | any;
  setDate(date: any, e: any) {
    date === 'start' ? (this.startDate = e) : (this.endDate = e);
    console.log(date, 'date');

    this.printDate();
  }
  printDate1() {
    console.log(this.startDate);
    console.log(this.endDate);
    this.bodyRequestData.start_date = this.startDate;
    this.bodyRequestData.end_date = this.endDate;
    // this.Gettutor_payout(this.bodyDataRequest);
    this.getstudent_quizzes(this.bodyDataRequest);
  }

  setDate1(date: any, e: any) {
    date === 'start' ? (this.startDate = e) : (this.endDate = e);
    console.log(date, 'date');

    this.printDate();
  }
  onChangetime(event: any) {
    console.log(event.value, 'hello');

    var t = event.value;
    var tArr = t.split(' ');
    var hour = tArr[0];
    var minute = tArr[1];
    this.bodyRequestData.start_time = hour;
    this.bodyRequestData.end_time = minute;

    this.getMyClass(this.bodyRequestData);
     this.getMyClass_complete(this.bodyDataRequest);
    this.getMyClass_cancel(this.bodyDataRequest);
    this.getMyClass_upcoming(this.bodyDataRequest);

    console.log(hour, 'asas', minute);
  }
  onSearchKey(event: any) {
    console.log(event.value, 'event123onSearchKey');

    this.bodyRequestData.name = event.value;
    this.getMyClass(this.bodyRequestData);
  }
 phone_code:any
  onSearchKey3(event: any,name:any) {
    // this.phone_code = event.value;

console.log(event,'asdfasdfasdf');


    var check=this.validateNumberString(event)
    if(check==true && name!=""){
      this.phone=""
    } else{
      this.phone="Please enter a valid number"
    }   // this.getMyClass(this.bodyRequestData);
    console.log(this.validateNumberString(event))    // this.getMyClass(this.bodyRequestData);
  }
  onSearchKey2(event: any,name:any) {

    if(name== "name" && event.value!=""){
      console.log("name");
      this.namefield=""
      
    }
    if(name== "last_name" && event.value!=""){
      console.log("last_name");
      this.lastname=""
      
    }
    if(name== "dob" && event.value!=""){
      console.log("dob");
      this.dob=""
      
    }
    if(name=="email" && event.value!=""){
      console.log("email");
      this. email=""
      
    }
    console.log(event.value, 'event123onSearchKey');

  }
  getCurrentDate(): Date {
    return new Date();
  }
  validateNumberString(input: string): boolean {
    return /^[+]?\d+(\.\d+)?$/.test(input);
  }
  formopen:boolean=false
  update_parent(){
    this.formopen=true
    this.createuser16.patchValue(this.userData)
  }
}
