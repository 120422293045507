export * from './login/login.component';
export * from './register/register.component';
export * from './forgot/forgot.component';
export * from './logout/logout.component';
export * from './location/location.component';
export * from './add-course/add-course.component';
export * from './add-subject/add-subject.component';
export * from './book-a-class/book-a-class.component';
export * from './cancel-class-tutor/cancel-class-tutor.component';
export * from './reschedule-class/reschedule-class.component';
export * from './add-project/add-project.component';
export * from './add-quiz/add-quiz.component';
export * from './tutor-view/tutor-view.component';
export * from './add-chat-group/add-chat-group.component';