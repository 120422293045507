<!--head+filter-->
<div class="top-section contT">
    <div class="container">
         <div class="row">
            <div class="col-lg-8 col-md-6 col-sm-12">
               <div class="Compare-Tutor-Profiles">
                <ul class="breadcrumb">
                    <li><a href="/">Hem</a></li>
                    <li class="active">Mitt Konto</li>
                    <h1 class="float-left">Jämför lärarprofiler</h1>
                </ul>
               </div> 
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="Compare-Tutor-Profiles">
                    <a _ngcontent-fax-c151="" (click)="backClicked()" class="style1 Go-Back-to-Find-A-Tutor" >GÅ TILLBAKA TILL HITTA EN STUDIEMENTOR</a>

                </div> 
             </div>
         </div>
    </div>
</div>
<div class="clearfix"></div>



<!-- listing-->
<section class="animated3 wow drop_eff5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 col-sm-6 wow fadeInLeft custom-code-st11"
                        *ngFor="let list of this.tutorList">
                        <div class="list_box Featured-Tutors" (click)="onViewTutor(list)">
                            <div class="snip1205">
                                <a data-type="iframe" href="javascript:void(0);" title="" class="port_pop1"
                                    (click)="onViewTutor(list)">
                                    <img [src]="allowurl(apiService.imageBase+list.image)" class="img-fluid"
                                        onerror="this.src='assets/images/profile-demo.png';" alt="..."></a>
                            </div>

                            <div class="all-Featured">
                                <div class="star-reating">
                                    <p *ngIf="list.avg_rating?.toFixed()==5" class="star"><i class="fas fa-star"></i><i
                                            class="fas fa-star"></i><i class="fas fa-star"></i><i
                                            class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn">
                                                Betyg</span></p>
                                    <p *ngIf="list.avg_rating?.toFixed()==4" class="star"><i class="fas fa-star"></i><i
                                            class="fas fa-star"></i><i class="fas fa-star"></i><i
                                            class="fas fa-star"></i><i class='far'>&#xf005;</i><span class="letn">
                                                Betyg</span></p>
                                    <p *ngIf="list.avg_rating?.toFixed()==3" class="star"><i class="fas fa-star"></i><i
                                            class="fas fa-star"></i><i class="fas fa-star"></i><i
                                            class='far'>&#xf005;</i><i class='far'>&#xf005;</i><span class="letn">
                                                Betyg</span></p>
                                    <p *ngIf="list.avg_rating?.toFixed()==2" class="star"><i class="fas fa-star"></i><i
                                            class="fas fa-star"></i><i class='far'>&#xf005;</i><i
                                            class='far'>&#xf005;</i><i class='far'>&#xf005;</i><span class="letn">
                                                Betyg</span></p>
                                    <p *ngIf="list.avg_rating?.toFixed()==1" class="star"><i class="fas fa-star"></i>
                                        <i class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i
                                            class='far'>&#xf005;</i><i class='far'>&#xf005;</i><i
                                            class='far'>&#xf005;</i>
                                        <span class="letn"> Betyg</span>
                                    </p>
                                    <p *ngIf="list.avg_rating==null" class="star"><i class='far'>&#xf005;</i>
                                        <i class='far'>&#xf005;</i>
                                        <i class='far'>&#xf005;</i>
                                        <i class='far'>&#xf005;</i>
                                        <i class='far'>&#xf005;</i>

                                        <span class="letn"> Betyg</span>
                                    </p>
                                </div>
                                <div class="Tutors-name">
                                    <a data-type="iframe" href="javascript:void(0);" (click)="onViewTutor(list)"
                                        title="" class="port_pop1">
                                        <h4>{{list.name}}</h4>
                                    </a>
                                    <span>{{list.rate}}kr/tim </span>
                                </div>
                                <p>{{list.state}}</p>
                                <div class="Tutors-checkbox bdrb">
                                    <p class="tbot st2023">År av erfarenhet
                                        <span>{{list.work_experience}}</span>
                                    </p>
                                </div>
                                <div class="pt-3 pb-3 bdrb desc-tt">
                                    <p class="b mb-2 Qualifications">Kvalifikationer</p>
                                    <p>{{list.qualification}}</p>
                                </div>
                                <div class="pt-3 pb-3 bdrb desc-tt">
                                    <p class="b mb-2 Qualifications">Språk</p>
                                    <p>{{list.languages}}</p>
                                </div>
                                <div class="pt-3 pb-3 desc-tt">
                                    <p class="b mb-2 Qualifications">Beskrivning</p>
                                    <p>{{list.description}}.</p>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>
<!-- listing Ends -->