import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from '../../services/message.service';

import { CommonService } from 'src/app/services';
@Component({
  selector: 'app-all-course',
  templateUrl: './all-course.component.html',
  styleUrls: ['./all-course.component.scss']
})
export class AllCourseComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any=[];
  
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  bodyRequestData:any={};
  LoginUserDetails:any={};
  courseList:any=[];
  categoryId:any;
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,private activatedRoute: ActivatedRoute,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
    this.categoryId = this.activatedRoute.snapshot.paramMap.get("id");
    console.log(this.categoryId);
  }

  isLoginUser:boolean=false;
  ngOnInit(): void {
    this.commonService.GetLoggedIn().subscribe((data:any)=>{
      this.isLoginUser=data;
      console.log('isLoginUser>>>>>>',this.isLoginUser);
    })
    this.commonService.GetLoginUserDetails().subscribe((data:any)=>{
      this.LoginUserDetails=data;
      console.log('isLoginUser>>>>>>',this.isLoginUser);
    })
    console.log('categoryId>>>>',this.categoryId);
    this.bodyRequestData.category_id='';
     this.getTutorList(this.bodyRequestData);
  }
  getTutorList(bodyRequestData:any){
    this.apiService.post('courses_list',this.bodyRequestData).subscribe((data:any) => {
      this.courseList=data.data;
      console.log('courseList>>>>>>>>',this.courseList);
    })
  }
 
  
  allowurl(url:any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  onBookCourse(courseObject:any){
    if(this.isLoginUser){
      var sendRequestData: any = {};
      sendRequestData.course_id = courseObject.id;
      sendRequestData.transaction_id = new Date().getTime();
      sendRequestData.student_id  = this.LoginUserDetails.id;
      sendRequestData.price  = courseObject.amount;
      // sendRequestData.created_by  = this.Schdule_Slot_Date;
      sendRequestData.parents_name  = this.LoginUserDetails.parents_name;
      sendRequestData.student_name  = this.LoginUserDetails.name;
      sendRequestData.course_name  = courseObject.course_name;
      sendRequestData.phone  = this.LoginUserDetails.phone;
      console.log('sendRequestData>>>>>>',sendRequestData);
      // return;
      this.apiService.post('student_subscription', sendRequestData).subscribe((data: any) => {
        if (data.replyCode == "success") {
          this.commonService.SetUpdateProfileChanged(true);
          this.messageService.showSuccess(data.replyMsg);
        } else {
          this.messageService.showError(data.replyMsg);
        }
      })
    }else{
      this.commonService.SetLoginPopup(true);
      // this.messageService.showError(data.replyMsg);
    }
    
  }


  addCourseToCompare(event:any,id:any){
    var value=event.checked;
    if(value){
      this.arrayCourse.push(id)
    }else{
      var removeskilsIndex = this.arrayCourse.map(function (item:any) { return item; }).indexOf(id);
      this.arrayCourse.splice(removeskilsIndex, 1);
    }
  } 

  
  arrayCourse:any=[];
  getCompare(){
    var localData = JSON.stringify(this.arrayCourse)
    localStorage.setItem('compareCourseData',localData);
    console.log('this.arrayCourse>>>>>>>>>>>>',this.arrayCourse);
    this.router.navigate(['/compare-course']);
  }
}
