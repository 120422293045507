import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';
import { PasswordMatchPattern } from 'src/app/services/validation/password-match-pattern';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  register: any = {}
  AddForm: FormGroup | any; submitted = false; btnsubmitted = false;
  constructor(private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService) {

  }
  get f() { return this.AddForm.controls; }

  ngOnInit(): void {
    this.register = localStorage.getItem('register_pop_up');
    this.register = JSON.parse(this.register)
    console.log(this.register, 'register');


    this.getLevelList();
    this.AddForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      // age : new FormControl('', [Validators.required]),
      role_id: ['2', [Validators.required]],
      phone: ['', Validators.required],
      password: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      reenterpassword: ['', Validators.required],
      gender: ['null'],
      news_letter: [''],
      country: ['', Validators.required],
      termscond: ['', Validators.required],
      // reId : localStorage.getItem('referral_id'),  

    }, {
      validator: PasswordMatchPattern('password', 'reenterpassword')
    });
  }

  onClosePopup() {
    this.commonService.SetRegisterPopup(false);
  }
  tutorFlag: boolean = false;
  changeevent: boolean = false;
  onChangeType(event: any) {
    this.changeevent = true
    console.log(event, "logevent");

    // this.AddForm.control['role_id'].setValue(event);
    localStorage.removeItem('register_pop_up');

    if (event == 3) {
      this.tutorFlag = true;
      this.AddForm = this.fb.group({
        name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
        role_id: [event],
        phone: ['', Validators.required],
        password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
        reenterpassword: ['', Validators.required],
        gender: [''],
        subscription_type: [''],
        news_letter: [''],
        // country : ['', Validators.required],
        termscond: ['', Validators.required],
        // reId : localStorage.getItem('referral_id'),
      }, {
        validator: PasswordMatchPattern('password', 'reenterpassword')
      });
    } else if (event == 2) {
      this.tutorFlag = false;
      this.AddForm = this.fb.group({
        name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
        role_id: [event],
        phone: ['', Validators.required],
        password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
        reenterpassword: ['', Validators.required],
        gender: [''],
        news_letter: [''],
        // country : ['', Validators.required],
        termscond: ['', Validators.required],
        // reId : localStorage.getItem('referral_id'),
      }, {
        validator: PasswordMatchPattern('password', 'reenterpassword')
      });
    } else if (event == 5) {
      this.tutorFlag = false;
      this.AddForm = this.fb.group({
        name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
        role_id: [event],
        phone: ['', Validators.required],
        password: [null, [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
        reenterpassword: ['', Validators.required],
        gender: [''],
        news_letter: [''],
        // country : ['', Validators.required],
        termscond: ['', Validators.required],
        // reId : localStorage.getItem('referral_id'),
      }, {
        validator: PasswordMatchPattern('password', 'reenterpassword')
      });
    }
  }

  LevelList: any = [];
  getLevelList() {
    var sendrequestinput: any = { id: 1 };
    this.apiService.post('age_group_list_dropdown', sendrequestinput).subscribe((data: any) => {
      this.LevelList = data.data;
    })
  }
  type: boolean = false;
  cptype: boolean = false;
  onChangeType1() {
    this.type = true;
  }
  onChangeType2() {
    this.type = false;
  }
  onChangecnType1() {
    this.cptype = true;
  }
  onChangecnType2() {
    this.cptype = false;
  }
  onOpenLoginPopup() {
    this.onClosePopup();

    this.commonService.SetLoginPopup(true);
  }
  onSubmit() {
    this.submitted = true;
    this.AddForm.get('name').markAsTouched();
    this.AddForm.get('last_name').markAsTouched();
    this.AddForm.get('email').markAsTouched();
    this.AddForm.get('role_id').markAsTouched();
    this.AddForm.get('phone').markAsTouched();
    this.AddForm.get('password').markAsTouched();
    this.AddForm.get('reenterpassword').markAsTouched();
    // this.AddForm.get('country').markAsTouched();
    this.AddForm.get('termscond').markAsTouched();
    // this.AddForm.get('reId').markAsTouched();
    this.AddForm.value.country = 0
    console.log(this.AddForm.value.termscond, 'this.AddForm.value.');


    console.log('this.AddForm>>>>>>>', this.AddForm.value)
    if (this.AddForm.invalid || this.phone == "Please enter a valid number") {
      console.log("abcccc");
      if (this.changeevent != true) {
        this.messageService.showError("Please select any user If not selected  ");
      }

      return
    } else {
      console.log('this.AddForm2>>>>>>>', this.AddForm.value)
      var sendrequestinput: any = this.AddForm.value;
      if (this.AddForm.value.news_letter) {
        sendrequestinput.news_letter = 1;
      } else {
        sendrequestinput.news_letter = 0;
      }
      sendrequestinput.last_name = this.AddForm.value.last_name;
      sendrequestinput.phone_code = 0;

      sendrequestinput.name = this.AddForm.value.name;
      console.log('sendrequestinput>>>>>>>>', sendrequestinput);

      // this.apiService.post('add_user',sendrequestinput).subscribe((data:any) => {
      //   if(data.replyCode == "success"){
      //     if(sendrequestinput.role_id == 3){
      //       this.apiService.post('generate_teacher_slots',{user_id:data.uid}).subscribe((response:any) => {
      //         console.log('response>>>>>>>>>',response);
      //         return response;
      //       })
      //     }
      //     if(data.data){
      //       this.commonService.SetLoginUserDetails(data.data);
      //       this.commonService.SetLoggedIn(true);
      //       var json=JSON.stringify(data.data);
      //       localStorage.setItem('user',json)
      //       localStorage.setItem('sid',data.sid)
      //     }
      //     if(sendrequestinput.role_id == 3){
      //       this.router.navigate(['/tutor-edit-profile'])
      //     }else if(sendrequestinput.role_id == 2){
      //       this.router.navigate(['/student-edit-profile'])
      //     }else{
      //       this.router.navigate(['/parent-edit-profile'])
      //     }
      //     this.onClosePopup();
      //     this.AddForm.reset();
      //     this.submitted=false;
      //     this.messageService.showSuccess(data.replyMsg);
      //     window.location.reload()
      //   }else{
      //     this.messageService.showError(data.replyMsg);
      //   }
      // })
      if (this.AddForm.value.termscond == true) {

        this.apiService.post('add_user', sendrequestinput).subscribe((data: any) => {
          console.log(data, "this is register data");
          if (data.replyCode == "error") {
            this.messageService.showError(data.replyMsg);

          }

          if (data.replyCode == "success") {

            var sendRequestData: any = {}

            sendRequestData.UID = data.data.id;
            if (sendrequestinput.role_id == 3) {

              sendRequestData.role = 3;
            }
            if (sendrequestinput.role_id == 2) {

              sendRequestData.role = 2;
            }
            if (sendrequestinput.role_id == 5) {

              sendRequestData.role = 5;
            }

            sendRequestData.name = data.data.name + ' ' + data.data.last_name,
              this.apiService.post('createUser', sendRequestData).subscribe((data: any) => {
                console.log(data, 'datadatadata');
              })

              //commented this portion for replicating dates 


            // if (sendrequestinput.role_id == 3) {
            //   this.apiService.post('generate_teacher_slots', { user_id: data.uid }).subscribe((response: any) => {
            //     console.log('response>>>>>>>>>', response);
            //     this.apiService.post('generate_jwt', { id: 1 }).subscribe((resp: any) => {
            //       console.log(resp.access_token, 'generate_jwt');
            //       var sendRequestData_c = data.data
            //       sendRequestData_c.access_token = resp.access_token;
            //       this.apiService.post('merithub_new_users', sendRequestData_c).subscribe((respo: any) => {
            //       })
            //     })
            //     return response;

            //   })
            // }

            if (data.data) {
              this.commonService.SetLoginUserDetails(data.data);
              this.commonService.SetLoggedIn(true);
              var json = JSON.stringify(data.data);
              localStorage.setItem('user', json)
              localStorage.setItem('sid', data.sid)
            }
            // if(sendrequestinput.role_id == 3){
            //   this.router.navigate(['/tutor-edit-profile'])
            // }else if(sendrequestinput.role_id == 2){
            //   this.router.navigate(['/student-edit-profile'])
            // }else{
            //   this.router.navigate(['/parent-edit-profile'])
            // }
            this.onClosePopup();
            this.AddForm.reset();
            this.submitted = false;
            this.messageService.showSuccess(data.replyMsg);
            window.location.reload()
          } else {
            this.messageService.showError(data.replyMsg);
          }
        })
      } else {

        this.messageService.showError("Please select Terms & Condition");

      }
    }

  }
  validateNumberString(input: string): boolean {
    return /^[+]?\d+(\.\d+)?$/.test(input);
  }
  phone_code: any
  phone: any
  onSearchKey3(event: any, name: any) {
    // this.phone_code = event.value;

    console.log(event, 'asdfasdfasdf');


    var check = this.validateNumberString(event)
    if (check == true && name != "") {
      this.phone = ""
    } else {
      this.phone = "Please enter a valid number"
    }   // this.getMyClass(this.bodyRequestData);
    console.log(this.validateNumberString(event))    // this.getMyClass(this.bodyRequestData);
  }

}
