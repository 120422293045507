import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  AddForm: FormGroup |any; submitted = false; btnsubmitted = false;
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 

  }
  get f() { return this.AddForm.controls; }

  ngOnInit(): void {
    this.AddForm = this.fb.group({
        email : new FormControl('', [Validators.required,Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
  })
  }
  onClosePopup(){
    this.commonService.SetForgotPopup(false);
  }
  onSubmit(){
    this.submitted=true;
    if(this.AddForm.invalid){
      return;
    }else{
      this.apiService.post('forgot_password',this.AddForm.value).subscribe((data:any) => {
        if(data.replyCode == "success"){
          this.onClosePopup();
          this.AddForm.reset();
          this.submitted=false;
          this.messageService.showSuccess(data.replyMsg);
        }else{
          this.messageService.showError(data.replyMsg);
        }
      })
    }
  }

}
