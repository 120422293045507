<div class="slider-main">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="serch-bar-slider pt-5">
          <div *ngIf="contentBlockData[0]?.status==1" [innerHtml]="contentBlockData[0]?.content"></div>
          <!-- <form class="banin">
            <input type="button" value="Hitta en Studiementor" class="btnflo" (click)="onsearchKeywordResult()" />
            <input type="text" placeholder="Search a tutor" (keyup)="onSearchKeyword($event.target)"
              (keydown.enter)="keyDownFunction($event.target)" />
          </form>
       -->
          <div class="row onSearchButtonClick">
            <div class="mt-3 col-12 Select-a-Subject">
              <select id="subjectSelect" class="form-control subjectSelects" #subjectSelect>
                <option value="">Välj ämne</option>
                <option *ngFor="let subject of categoryList let i = index" [value]="subject.id">
                  {{ subject.name }}
                </option>
              </select>
              <select id="deliveryMethodSelect" class="form-control subjectSelects" #deliveryMethodSelect>
                <option [value]="null">Välj plats</option>
                <option [value]="1">Online</option>
                <option [value]="2">In-person</option>
              </select>
              <div class="home-pages-button">
                <button type="button" class="style1 bg"
                  (click)="onSearchButtonClick(subjectSelect.value, deliveryMethodSelect.value)">SÖK</button>
              </div>
            </div>


          </div>











        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="serch-bar-slider">
          <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouselExampleIndicators" attr.data-slide-to="{{ i }}" class="active"
                *ngFor="let key of homeBanner; let i = index"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item" *ngFor="let homeBannerList of homeBanner; let i = index"
                [ngClass]="i == 0 ? 'active' : ''">
                <img class="" [src]="allowurl(this.apiService.imageBase + homeBannerList.image)" alt="First slide" />
              </div>
            </div>
            <a class="bot-suk" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class=" bot-suk right-0" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--infobar-->
<div class="infob">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <img src="assets/images/info-1.png" alt="" /> Personligt anpassad läxhjälp
      </div>
      <div class="col-md-4">
        <img src="assets/images/star.png" alt="" /> Framsteg och kvalitet I focus
      </div>
      <div class="col-md-4">
        <img src="assets/images/info-2.png" alt="" /> Årets unga företagsidé 2022
      </div>
    </div>
  </div>
</div>

<div class="clearfix"></div>


<div class="about-section pt-5 mt-5">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-lg-3 col-md-6 col-sm-12">
        <a href="javascript:void(0);" routerLink="/in-person-enquiry">
          <div class="about-bg d-flex flex-column align-items-center text-center">
            <div class="abt-icon">
              <img src="assets/img/1b.png" alt="Skolbryggan Classic" />
            </div>
            <div class="about-content">
              <h4 *ngIf="contentBlockData[1]?.status==1" [innerHtml]="contentBlockData[1]?.title"></h4>
              <p [innerHtml]="contentBlockData[1]?.content"></p>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <div (click)="onDeliveryMethodChange()">
          <div class="about-bg d-flex flex-column align-items-center text-center">
            <div class="abt-icon">
              <img src="assets/img/2b.png" alt="Skolbryggan Classic" />
            </div>
            <div class="about-content">
              <h4 *ngIf="contentBlockData[2]?.status==1" [innerHtml]="contentBlockData[2]?.title"></h4>
              <p [innerHtml]="contentBlockData[2]?.content"></p>
            </div>
          </div>
        </div>

      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <div (click)="onDeliveryMethodChange2()">
          <div class="about-bg d-flex flex-column align-items-center text-center">
            <div class="abt-icon">
              <img src="assets/img/3b.png" alt="Skolbryggan Classic" />
            </div>
            <div class="about-content">
              <h4 *ngIf="contentBlockData[3]?.status==1" [innerHtml]="contentBlockData[3]?.title"></h4>
              <p [innerHtml]="contentBlockData[3]?.content"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12">
        <a href="javascript:void(0);" routerLink="/find-a-tutor">
          <div class="about-bg d-flex flex-column align-items-center text-center">
            <div class="abt-icon">
              <img src="assets/img/4b.png" alt="Skolbryggan Classic" />
            </div>
            <div class="about-content">
              <h4 *ngIf="contentBlockData[4]?.status==1" [innerHtml]="contentBlockData[4]?.title"></h4>
              <p [innerHtml]="contentBlockData[4]?.content"></p>
            </div>
          </div>
        </a>
      </div>
      <!-- <div class="col-lg-4 col-md-6 col-sm-12">
        <a href="#">
          <div class="about-bg d-flex flex-column align-items-center text-center">
            <div class="abt-icon">
              <img src="assets/img/5b.png" alt="Skolbryggan Classic" />
            </div>
            <div class="about-content">
              <h4 *ngIf="contentBlockData[33]?.status==1" [innerHtml]="contentBlockData[33]?.title"></h4>
              <p [innerHtml]="contentBlockData[33]?.content"></p>
            </div>
          </div>
        </a>
      </div> -->
    </div>
  </div>
</div>


<!--what do we offer-->
<div class="Twosection">
  <div class="mb-5">
    <div class="container">
      <div class="tab-content mt-3 mb-5" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-tab1" role="tabpanel" aria-labelledby="pills-tab1-tab">
          <div class="row">
            <div class="col-md-12">
              <h1 class="text-center mb-1 animated2 drop_eff2">Populära ämnen?</h1>
              <p class="ac mb-3 fs16">
                Oavsett vad du behöver hjälp med så har vi något för alla
              </p>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-12 cus-wid mt-3 mb-2"
              *ngFor="let sujects of categoryList.slice(0,5); let i = index">
              <a href="javascript:void(0);" routerLink="/find-a-tutor" (click)="subjectid(sujects.id)" class="subject">
                <div class="sub-icon">
                  <img *ngIf="sujects.image" [src]="allowurl(apiService.imageBase + sujects.image)"
                    onError="this.src='assets/images/s-i-1.png'" alt="Skolbryggan Classic" />
                  <img *ngIf="!(sujects.image)" alt="" class="img-fluid" src="assets/images/s-i-1.png" />
                  <h5>{{ sujects?.name }}</h5>
                </div>
              </a>
            </div>

            <div class="col-md-12 ac mt-3">
              <p><a href="javascript:void(0);" routerLink="/browser-course" class="style1 bg">Visa alla ämnen</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="text-center mb-1 animated2 drop_eff2">Hur fungerar det?</h1>
          <p class="ac mb-3 fs16">På Skolbryggan har vi ett enkelt recept för framgång</p>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="easy-steps">
            <div class="icon-Works">
              <img src="assets/img/icon/profile-ico1.png" alt="Skolbryggan Classic" />
            </div>
            <div class="perfect-Tutor">
              <h4 *ngIf="contentBlockData[5]?.status==1" [innerHtml]="contentBlockData[5]?.title"></h4>
              <p [innerHtml]="contentBlockData[5]?.content">
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="easy-steps ex-bg">
            <div class="icon-Works">
              <img src="assets/img/icon/profile-ico2.png" alt="Skolbryggan Classic" />
            </div>
            <div class="perfect-Tutor">
              <h4 class="black" *ngIf="contentBlockData[6]?.status==1" [innerHtml]="contentBlockData[6]?.title"></h4>
              <p class="black" [innerHtml]="contentBlockData[6]?.content">
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="easy-steps">
            <div class="icon-Works">
              <img src="assets/img/icon/profile-ico3.png" alt="Skolbryggan Classic" />
            </div>
            <div class="perfect-Tutor">
              <h4 *ngIf="contentBlockData[7]?.status==1" [innerHtml]="contentBlockData[7]?.title"></h4>
              <p [innerHtml]="contentBlockData[7]?.content"></p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="easy-steps ex-bg">
            <div class="icon-Works">
              <img src="assets/img/icon/profile-ico4.png" alt="Skolbryggan Classic" />
            </div>
            <div class="perfect-Tutor">
              <h4 class="black" *ngIf="contentBlockData[8]?.status==1" [innerHtml]="contentBlockData[8]?.title"></h4>
              <p class="black" [innerHtml]="contentBlockData[8]?.content"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-5">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-center mb-1 animated2 drop_eff2">Vad säger våra kunder?</h1>
        <p class="ac mb-3 fs16"> Se för dig själv vad våra kunder säger om oss
        </p>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-12">
        <div id="owl-port1" class="owl-carousel owl-theme">


          <div class="item Raving-Recensioner-cont">
            <div>
              <h6>{{ homeTestimonial[0].student_name }}</h6>
              <h4>{{ homeTestimonial[0].message }}</h4>
              <div class="quote">
                <img src="assets/img/quote.png" alt="Skolbryggan Classic" />
              </div>
            </div>
          </div>
          <div class="item Raving-Recensioner-cont">
            <div>
              <h6>{{ homeTestimonial[1].student_name }}</h6>
              <h4>{{ homeTestimonial[1].message }}</h4>
              <div class="quote">
                <img src="assets/img/quote.png" alt="Skolbryggan Classic" />
              </div>
            </div>
          </div>
          <div class="item Raving-Recensioner-cont">
            <div>
              <h6>{{ homeTestimonial[2].student_name }}</h6>
              <h4>{{ homeTestimonial[2].message }}</h4>
              <div class="quote">
                <img src="assets/img/quote.png" alt="Skolbryggan Classic" />
              </div>
            </div>
          </div>
          <div class="item Raving-Recensioner-cont">
            <div>
              <h6>{{ homeTestimonial[3].student_name }}</h6>
              <h4>{{ homeTestimonial[3].message }}</h4>
              <div class="quote">
                <img src="assets/img/quote.png" alt="Skolbryggan Classic" />
              </div>
            </div>
          </div>
          <div class="item Raving-Recensioner-cont">
            <div>
              <h6>{{ homeTestimonial[4].student_name }}</h6>
              <h4>{{ homeTestimonial[4].message }}</h4>
              <div class="quote">
                <img src="assets/img/quote.png" alt="Skolbryggan Classic" />
              </div>
            </div>
          </div>
          <div class="item Raving-Recensioner-cont">
            <div>
              <h6>{{ homeTestimonial[5].student_name }}</h6>
              <h4>{{ homeTestimonial[5].message }}</h4>
              <div class="quote">
                <img src="assets/img/quote.png" alt="Skolbryggan Classic" />
              </div>
            </div>
          </div>
          <div class="item Raving-Recensioner-cont">
            <div>
              <h6>{{ homeTestimonial[6].student_name }}</h6>
              <h4>{{ homeTestimonial[6].message }}</h4>
              <div class="quote">
                <img src="assets/img/quote.png" alt="Skolbryggan Classic" />
              </div>
            </div>
          </div>
          <div class="item Raving-Recensioner-cont">
            <div>
              <h6>{{ homeTestimonial[7].student_name }}</h6>
              <h4>{{ homeTestimonial[7].message }}</h4>
              <div class="quote">
                <img src="assets/img/quote.png" alt="Skolbryggan Classic" />
              </div>
            </div>
          </div>
          <div class="item Raving-Recensioner-cont">
            <div>
              <h6>{{ homeTestimonial[8].student_name }}</h6>
              <h4>{{ homeTestimonial[8].message }}</h4>
              <div class="quote">
                <img src="assets/img/quote.png" alt="Skolbryggan Classic" />
              </div>
            </div>
          </div>
          <div class="item Raving-Recensioner-cont">
            <div>
              <h6>{{ homeTestimonial[9].student_name }}</h6>
              <h4>{{ homeTestimonial[9].message }}</h4>
              <div class="quote">
                <img src="assets/img/quote.png" alt="Skolbryggan Classic" />
              </div>
            </div>
          </div>




        </div>
      </div>
    </div>
  </div>

</div>



<div class="mt-5 mb-5">
  <div class="container">
    <h1 class="text-center mb-3 animated2 drop_eff2">Våra stjärnor</h1>
    <p class="text-center fs16 mb-4">
      Fantastiska och erfarna mentorer redo att hjälpa dig
    </p>
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-3 col-sm-12" *ngFor="let data of TeachersList; let i = index">
        <div class="list_box Featured-Tutors">
          <div *ngIf="LoginUserDetails.role_id != 3 && LoginUserDetails">
            <!-- <a *ngIf="LoginUserDetails.role_id != 5" href="javascript:void(0);" class="chat_btn"
              routerLink="/chats/{{ data?.id }}" title="Let’s Chat"><i class="fas fa-comments"></i></a> -->
            <!-- <a href="javascript:void(0);" class="book_btn" title="Book a free trial class"
              routerLink="/book-a-demo-class/{{ data.id }}"><i class="fas fa-handshake"></i></a> -->
            <a *ngIf="LoginUserDetails.role_id != 3" href="javascript:void(0);" class="class_btn"
              title="Intresseanmälan " routerLink="/book-a-class/{{ data.id }}" style="left: 40%;"><i
                class="fas fa-laptop"></i></a>
          </div>
          <div *ngIf="!LoginUserDetails">
            <!-- <a href="javascript:void(0);" class="chat_btn" (click)="onOpenLoginPopup()" title="Let’s Chat"><i
                class="fas fa-comments"></i></a> -->
            <!-- <a *ngIf="LoginUserDetails.role_id != 5" href="javascript:void(0);" class="book_btn"
              title="Book a free trial class" (click)="onOpenLoginPopup()"><i class="fas fa-handshake"></i></a> -->
            <a *ngIf="LoginUserDetails.role_id != 3" href="javascript:void(0);" class="class_btn"
              title="Intresseanmälan " (click)="onOpenLoginPopup()" style="left: 40%;"><i class="fas fa-laptop"></i></a>
          </div>
          <div class="snip1205">
            <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:void(0);" title=""
              class="port_pop1" routerLink="/tutor-details/{{ data.id }}">
              <img *ngIf="data.image" [src]="allowurl(apiService.imageBase + data.image)" alt="" class="img-fluid"
                onError="this.src='assets/images/profile-demo.png'" />
              <img *ngIf="!(data.image)" alt="" class="img-fluid" src="assets/images/s-i-1.png" />
            </a>
          </div>

          <div class="all-Featured">
            <div class="star-reating">
              <p *ngIf="data.avg_rating?.toFixed() == 5" class="star">
                <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                  class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> Betyg</span>
              </p>
              <p *ngIf="data.avg_rating?.toFixed() == 4" class="star">
                <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                  class="fas fa-star"></i><i class="far">&#xf005;</i><span class="letn"> Betyg</span>
              </p>
              <p *ngIf="data.avg_rating?.toFixed() == 3" class="star">
                <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                  class="far">&#xf005;</i><i class="far">&#xf005;</i><span class="letn"> Betyg</span>
              </p>
              <p *ngIf="data.avg_rating?.toFixed() == 2" class="star">
                <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="far">&#xf005;</i><i
                  class="far">&#xf005;</i><i class="far">&#xf005;</i><span class="letn"> Betyg</span>
              </p>
              <p *ngIf="data.avg_rating?.toFixed() == 1" class="star">
                <i class="fas fa-star"></i> <i class="far">&#xf005;</i><i class="far">&#xf005;</i><i
                  class="far">&#xf005;</i><i class="far">&#xf005;</i><i class="far">&#xf005;</i>
                <span class="letn"> Betyg</span>
              </p>
              <p *ngIf="data.avg_rating == null" class="star">
                <i class="far">&#xf005;</i>
                <i class="far">&#xf005;</i>
                <i class="far">&#xf005;</i>
                <i class="far">&#xf005;</i>
                <i class="far">&#xf005;</i>

                <span class="letn"> Betyg</span>
              </p>
            </div>
            <div class="Tutors-name">
              <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1">
                <h4>{{ data.name }}</h4>
              </a>
              <span>SEK {{ data.rate }}/hr</span>
            </div>
            <p>{{ data.city }}, {{ data.state }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-12 ac mt-5">
        <p><a href="javascript:void(0);" routerLink="/find-a-tutor" class="style1 bg">Visa alla mentorer</a></p>
      </div>
    </div>
  </div>
</div>
<div class="clearfix"></div>

<div class="join">
  <div class="container">
    <div class="row padding-30">
      <div class="col-lg-12 col-md-12 col-sm-12 mb-5">
        <div class="join-us">
          <a href="#" class="join-us-button"> Anmäl dig</a>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-12">
            <div class="join-us-images">
              <img src="assets/img/student.png" alt="sample42" />
              <p><a href="javascript:void(0);" (click)="onOpenRegisterPopup()" class="style2 bg-black">Anmäl dig här</a>
              </p>
            </div>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12">
            <div class="join-us-content">
              <h3>Elever</h3>
              <P>På Skolbryggan tror vi att varje elev har enorm potential. Vårt mål är att ge dig de verktyg och den
                stöttning du behöver för att lyckas i skolan och i livet. Oavsett utmaningar, är vi här för att hjälpa
                dig att nå dina mål.
              </P>
              <P>Upptäck hur Skolbryggan kan förstärka dina akademiska färdigheter och bygga en stark grund för framtida
                studier. Vår individanpassade läxhjälp är designad för att maximera ditt lärande och självförtroende.
              </P>
            </div>
          </div>
        </div>
      </div>
      <div class="border-line"></div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-lg-7 col-md-7 col-sm-12">
            <div class="join-us-content">
              <h3>Föräldrar</h3>
              <P>Ditt engagemang i ditt barns utbildning är ovärderligt. Hos Skolbryggan finner du ett stödnätverk som
                ger dig och ditt barn tillgång till kvalitativ läxhjälp och resurser som underlättar lärandet.
                Tillsammans skapar vi en ljus framtid för våra barn.
              </P>
              <P>Välj Skolbryggan för att ge ditt barn de bästa förutsättningarna för framgång. Vårt engagemang för
                kontinuerlig utveckling och stöd hjälper ditt barn att inte bara nå utan överträffa sina utbildningsmål.
              </P>
            </div>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-12">
            <div class="join-us-images">
              <img src="assets/img/pngimg.png" alt="sample42" />
              <p><a href="javascript:void(0);" (click)="onOpenRegisterPopup2()" class="style2 bg-black">Anmäl dig
                  här</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="Asked Questions">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-center mb-1 animated2 drop_eff2">Vanliga frågor (FAQ)</h1>
        <p class="ac mb-3 fs16"> Se för dig själv vad våra kunder säger om oss
        </p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="Frequently-images">
          <img src="assets/img/faq-banner-image.png" alt="sample42" />
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="Frequently-content">
          <div class="accordion" id="accordionPanelsStayOpenExample">

            <div class="accordion-item" *ngFor="let faq of FaqList; let i = index">
              <h2 class="accordion-header" id="flush-faqHeading-{{ i }}">
                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#flush-faqCollapse-' + i" aria-expanded="true"
                  aria-controls="flush-faqCollapse">
                  {{ faq?.question }}
                </button>
              </h2>
              <div id="flush-faqCollapse-{{ i }}" class="accordion-collapse collapse "
                aria-labelledby="flush-faqHeading" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  {{ faq?.answer }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>