<div class="mb-3 contT">
    <div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Course In Details</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide">Course In </span>Details</h1>
    </div>
</div>


<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <!-- filter-->
                <form class="list_box m-0 m-auto">
                    <div class="dd_next hand p-3 bdrb fs16 b"> <span>Filter Your Search</span></div>
                    <div class="emp_area f_dd_box p-3">
                        <div class="name_feild">
                            <p><strong>Star Rating</strong></p>
                            <p>
                                <select name="" id="">
                           <option value="">Select</option>
                           <option value="">1</option>
                           <option value="">2</option>
                        </select>
                            </p>
                        </div>
                        <div class="loc_feild">
                            <p>Select Experiece</p>
                            <p>
                                <select name="" id="">
                           <option value="">Select</option>
                           <option value="">1</option>
                           <option value="">2</option>
                        </select>
                            </p>
                        </div>
                        <div class="loc_feild">
                            <p>Select State</p>
                            <p>
                                <select name="" id="">
                           <option value="">Select</option>
                        </select>
                            </p>
                        </div>
                        <div class="loc_feild">
                            <p>Select City</p>
                            <p>
                                <select name="" id="">
                           <option value="">Select</option>
                        </select>
                            </p>
                        </div>
                        <div class="loc_feild">
                            <p>Select Duration</p>
                            <p>
                                <select name="" id="">
                           <option value="">Select</option>
                           <option value="">1 hour</option>
                           <option value="">2 hours</option>
                        </select>
                            </p>
                        </div>
                    </div>
                </form>
                <!-- filter end-->
            </div>
            <div class="col-md-9">
                <div class="list_box profbg m-0 p-5">
                    <div class="row" *ngIf="courseDetails">
                        <div class="col-md-3 pb-4 text-center">
                            <!-- <img src="assets/images/t5.jpg" class="img-fluid radius15 m-auto" alt=""> -->
                            <img [src]="allowurl(apiService.imageBase+courseDetails.image)" alt="" class="img-fluid radius15 m-auto" onError="this.src='assets/images/profile-demo.png'" style="width: 100%;height: 100%;">
                        </div>
                        <div class="col-md-9">
                            <div class="">
                                <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
                                <h1 class="float-left">{{courseDetails.course_name}}</h1>
                                <p class="mt-2 fs22 float-right white"><b>${{courseDetails.amount}}.00</b><span class="fs14 ml-2">(per hour)</span></p>
                                <p class="clearfix"></p>
                                <!-- <p class="fs12">Max C.</p> -->
                                <p class="fs12 mt-4 pr-2" [innerHTML]="courseDetails.description"></p>
                                <p class="bdrb mt-3 mb-3"><img src="assets/images/spacer.gif" alt=""></p>
                                <div class="row mt-4 text-center">
                                    <div class="col-md-4 add_cart">
                                        <a href="chat-me.htm" target="_parent"><i class="fas fa-comments fs18"></i> </a>
                                        <p class="fs12 mt-1">Chat Me</p>
                                    </div>
                                    <div class="col-md-4 add_cart">
                                        <a href="book-meeting.htm" target="_parent"><i class="fas fa-handshake fs18"></i></a>
                                        <p class="fs12 lh15 mt-1">Book A 15 Minuts Meeting</p>
                                    </div>
                                    <div class="col-md-4 add_cart">
                                        <a href="book-class.htm" target="_parent"><i class="fas fa-laptop fs18"></i> </a>
                                        <p class="fs12 mt-1">Intresseanmälan </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="row">
                            <div class="col-md-12 p-0">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="list_box bg-gray p-4">
                                            <p class="b mb-4">What you'll learn:</p>
                                            <p [innerHTML]="courseDetails.curriculum"></p>
                                            <!-- <p><i class="fas fa-check"></i> Make accurate predictions</p>
                                            <p><i class="fas fa-check"></i> Make powerful analysis</p> -->
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="list_box bg-gray p-4">
                                            <p class="b mb-4">This course includes:</p>
                                            <p [innerHTML]="courseDetails.hands_on_activities"></p>
                                            <!-- <p><i class="fas fa-check"></i> 73 articles</p>
                                            <p><i class="fas fa-check"></i> 38 downloadable resources</p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pt-5 pb-5 bdrb" *ngIf="courseDetails.video_url != ''">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <p class="b mb-4">Video Introduction</p>
                            <div class="row">
                                <div class="col-md-6 text-center">
                                    <iframe width="100%" height="200" src="{{courseDetails.video_url}}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" style="border-radius: 15px;"></iframe>
                                </div>
                                <!-- <div class="col-md-6">
                                    <iframe width="100%" height="200" src="https://www.youtube.com/embed/kCHeKc2R96o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" style="border-radius: 15px;"></iframe>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="pt-5 pb-5 bdrb">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <p class="b mb-4">About My Qualifications</p>
                            <div class="row">
                                <div class="col-md-12 p-0">
                                    I have a certificate in TEFL, and a track record of helping students to reach their goals - whether that be finding the perfect turn of phrase in an essay or passing an exam with flying colours. English Literature and French (Bachelors) - University of
                                    Warwick History of Art (Masters) - Courtauld Institute of Art
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="pt-5 pb-5 bdrb p-0">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <p class="b mb-4">Subject Teaches</p>
                            <div class="row list_box">
                                <div class="col-md-3 b p-2 mob_hide">Course Type</div>
                                <div class="col-md-3 b p-2 mob_hide">Subject</div>
                                <div class="col-md-3 b p-2 mob_hide">Level</div>
                                <div class="col-md-3 b p-2 mob_hide">Hourly Rate</div>
                                <div class="col-md-12 mob_hide">
                                    <hr>
                                </div>
                                <div class="col-md-3 p-2">British(Including IGCCSE)</div>
                                <div class="col-md-3 p-2">English</div>
                                <div class="col-md-3 p-2">Key Stage 4/GCSE</div>
                                <div class="col-md-3 p-2">RM 115.00</div>
                                <div class="col-md-12">
                                    <hr>
                                </div>
                                <div class="col-md-3 p-2">British(Including IGCCSE)</div>
                                <div class="col-md-3 p-2">English</div>
                                <div class="col-md-3 p-2">Key Stage 4/GCSE</div>
                                <div class="col-md-3 p-2">RM 115.00</div>
                                <div class="col-md-12">
                                    <hr>
                                </div>
                                <div class="col-md-3 p-2">British(Including IGCCSE)</div>
                                <div class="col-md-3 p-2">English</div>
                                <div class="col-md-3 p-2">Key Stage 4/GCSE</div>
                                <div class="col-md-3 p-2">RM 115.00</div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="pt-5 pb-5 bdrb p-0">
                    <div class="row">
                        <div class="col-md-12 p-0">
                            <p class="b mb-4">Ratings and Recensioner</p>
                            <div class="row">
                                <div class="col-md-12 p-0">
                                    <ul class="revies">
                                        <li>
                                            <div class="star">
                                                <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i> <span>(5.0)</span>
                                            </div>
                                            <h5>Madison Nesting Coffee Tables<span>2021-1-4</span></h5>
                                            <p class="mt-2">Madison is compact and easy to move around the home or office.</p>
                                        </li>
                                        <li>
                                            <div class="star">
                                                <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i> <span>3.0</span>
                                            </div>
                                            <h5>Madison Nesting Coffee Tables<span>2021-1-4</span></h5>
                                            <p class="mt-2">Madison is compact and easy to move around the home or office.</p>
                                        </li>
                                        <li>
                                            <div class="star">
                                                <i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i> <span>2.0</span>
                                            </div>
                                            <h5>Madison Nesting Coffee Tables<span>2021-1-4</span></h5>
                                            <p class="mt-2">Madison is compact and easy to move around the home or office.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="pt-5 pb-5 bdrb p-0">
                    <p class="b mb-4">More Courses by "Max C."</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6 wow fadeInLeft" style="visibility: hidden; animation-name: none;">
                            <div class="list_box">
                                <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                                <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                                <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                                <a href="course-details.htm" title=""><img src="assets/images/t5.jpg" alt="" class="img-fluid"></a>
                                <div class="p-3 rel">
                                    <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></p>
                                    <p class="b float-left"><a href="course-details.htm" title="">Computer Progr...</a></p>
                                    <p class="b float-right">$50.00/hr</p>
                                    <p class="clearfix"></p>
                                    <p class="fs12">Max C.</p>
                                    <p class="tbot">Duration (in hour) : <span>10</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 wow fadeInLeft" style="visibility: hidden; animation-name: none;">
                            <div class="list_box">
                                <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                                <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                                <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                                <a href="course-details.htm" title=""><img src="assets/images/t5.jpg" alt="" class="img-fluid"></a>
                                <div class="p-3 rel">
                                    <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></p>
                                    <p class="b float-left"><a href="course-details.htm" title="">Computer Progr...</a></p>
                                    <p class="b float-right">$50.00/hr</p>
                                    <p class="clearfix"></p>
                                    <p class="fs12">Max C.</p>
                                    <p class="tbot">Duration (in hour) : <span>10</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 wow fadeInLeft" style="visibility: hidden; animation-name: none;">
                            <div class="list_box">
                                <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                                <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                                <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>
                                <a href="course-details.htm" title=""><img src="assets/images/t5.jpg" alt="" class="img-fluid"></a>
                                <div class="p-3 rel">
                                    <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 &amp; up (232)</span></p>
                                    <p class="b float-left"><a href="course-details.htm" title="">Computer Progr...</a></p>
                                    <p class="b float-right">$50.00/hr</p>
                                    <p class="clearfix"></p>
                                    <p class="fs12">Max C.</p>
                                    <p class="tbot">Duration (in hour) : <span>10</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>