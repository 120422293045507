<div class="mb-3 contT">
	<div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Mitt Konto</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
    	<h1 class="float-left"><span class="txt_gray mob_hide">My </span>Classes Schedule</h1>
		<p class="clearfix"></p>
    </div>
</div>

<section class="animated3 wow drop_eff5" style="visibility: hidden; animation-name: none;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-3">
                <!-- filter-->
                    <form class="list_box mb-4 bg-gray1">
                    <div class="dd_next hand p-3 bdrb fs16 b"> <span>Filter Your Search</span></div>
                    <div class="emp_area f_dd_box p-3 mb-4">
                        <div class="name_feild">
                            <p class="">Tutor Name</p>
                            <p><select name="" id="">
                                <option value="">Select</option>
                            </select></p>
                            <!--<div class="selection-group">
                              <input id="aa" type="radio" name="rate01" value="aa"><label for="aa">Male</label>
                              <input id="ba" type="radio" name="rate01" value="ba"><label for="ba">Female</label>
                          </div>-->
                        </div>
                        <div class="loc_feild">
                            <p class="">Date</p>
                            <p><input type="text"></p>
                        </div>
                        <div class="loc_feild">
                            <p class="">Level</p>
                            <p><select name="" id=""><option value="">Select</option></select></p>
                        </div>
                        <div class="loc_feild">
                            <p class="">Subject</p>
                            <p><select name="" id=""><option value="">Select</option></select></p>
                        </div>
                        <div class="loc_feild">
                            <p class="">Status Of Class</p>
                            <p><select name="" id=""><option value="">Select</option></select></p>
                        </div>
                        <div class="loc_feild">
                            <p><a href="#" class="compare">Reset Filter</a></p>
                        </div>
                    </div>
                    </form>
                <!-- filter end-->
            </div>
                    <div class="col-lg-9 col-sm-8 col-sm-12 wow fadeInLeft" style="visibility: hidden; animation-name: none;">
                        <div class="list_box p-4 fs13 rel">
                            <a href="" class="del1"><i class="far fa-trash-alt"></i></a>
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <div class="list_box">
                                        <!--<a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                                        <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                                        <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>-->
                                        <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1"><img src="assets/images/t1.jpg" alt="" class="img-fluid"></a>
                                        <div class="p-3">
                                            <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
                                            <p class="b float-left "><a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1">Rebecca L</a></p>
                                            <p class="b float-right ">$50.00/hr</p>
                                            <p class="clearfix"></p>
                                            <p class="fs12 ">Dwarka, New Delhi</p>
                                            <p class="tbot ">Totala klass Erfarenhet : <span>10</span></p>
                                            <p class="mt-4"><a href="#" class="compare">Rate &amp; Review</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <div class="row mt-5">
                                        <div class="col-lg-12 col-md-12 pb-3">
                                            <p class="">Course Name</p>
                                            <p class="b mt-2 fs18 "><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                            <p class="mt-2 pb-3 bdrb mb-4 ">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur rhoncus urna a commodo rhoncus. Sed porta ac ipsum in accumsan.</p>
                                        </div>
                                        
                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p class="">Booked Date</p>
                                            <p class="b mt-2 ">Friday, July 16, 2021</p>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p class="">Start Date</p>
                                            <p class="b mt-2 white">Friday, July 16, 2021</p>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p class="">Duration</p>
                                            <p class="b mt-2 ">1 hour</p>
                                        </div>
                                        
                                        <!--<div class="row">
                                            <div class="col-md-6">
                                                <iframe width="100%" height="200" src="https://www.youtube.com/embed/kCHeKc2R96o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" style="border-radius: 15px;"></iframe>
                                            </div>
                                            <div class="col-md-6">
                                                <iframe width="100%" height="200" src="https://www.youtube.com/embed/kCHeKc2R96o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" style="border-radius: 15px;"></iframe>
                                            </div>
                                        </div>-->
                                        
                                        <div class="col-lg-12 col-md-12 pb-3">
                                            <p class="bdrb pb-2"><img src="assets/images/spacer.gif" alt=""></p>
                                        </div>
                                        
                                        <div class="col-lg-3 col-md-6">
                                            <p><a href="#" class="compare">Reshedule</a></p>
                                        </div>
                                        <div class="col-lg-3 col-md-6">
                                            <p><a href="#" class="compare">Join Class</a></p>
                                        </div>
                                        <div class="col-lg-3 col-md-6">
                                            <p><a href="chat-me.htm" class="compare"><i class="fas fa-comments"></i> Chat Me</a></p>
                                        </div>
                                        <div class="col-lg-3 col-md-6">
                                            <p><a href="" class="compare"><i class="fas fa-folder"></i> My Folder</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="list_box p-4 fs13 rel">
                            <a href="" class="del1"><i class="far fa-trash-alt"></i></a>
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <div class="list_box">
                                        <!--<a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i class="fas fa-comments"></i></a>
                                        <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i class="fas fa-handshake"></i></a>
                                        <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i class="fas fa-laptop"></i></a>-->
                                        <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1"><img src="assets/images/t1.jpg" alt="" class="img-fluid"></a>
                                        <div class="p-3">
                                            <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
                                            <p class="b float-left "><a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;" title="" class="port_pop1">Rebecca L</a></p>
                                            <p class="b float-right ">$50.00/hr</p>
                                            <p class="clearfix"></p>
                                            <p class="fs12 ">Dwarka, New Delhi</p>
                                            <p class="tbot ">Totala klass Erfarenhet : <span>10</span></p>
                                            <p class="mt-4"><a href="#" class="compare">Rate &amp; Review</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <div class="row mt-5">
                                        <div class="col-lg-12 col-md-12 pb-3">
                                            <p class="">Course Name</p>
                                            <p class="b mt-2 fs18 "><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                            <p class="mt-2 pb-3 bdrb mb-4 ">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur rhoncus urna a commodo rhoncus. Sed porta ac ipsum in accumsan.</p>
                                        </div>
                                        
                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p class="">Booked Date</p>
                                            <p class="b mt-2 ">Friday, July 16, 2021</p>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p class="">Start Date</p>
                                            <p class="b mt-2 white">Friday, July 16, 2021</p>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p class="">Duration</p>
                                            <p class="b mt-2 ">1 hour</p>
                                        </div>
                                        
                                        <!--<div class="row">
                                            <div class="col-md-6">
                                                <iframe width="100%" height="200" src="https://www.youtube.com/embed/kCHeKc2R96o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" style="border-radius: 15px;"></iframe>
                                            </div>
                                            <div class="col-md-6">
                                                <iframe width="100%" height="200" src="https://www.youtube.com/embed/kCHeKc2R96o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" style="border-radius: 15px;"></iframe>
                                            </div>
                                        </div>-->
                                        
                                        <div class="col-lg-12 col-md-12 pb-3">
                                            <p class="bdrb pb-2"><img src="assets/images/spacer.gif" alt=""></p>
                                        </div>
                                        
                                        <div class="col-lg-3 col-md-6">
                                            <p><a href="#" class="compare">Reshedule</a></p>
                                        </div>
                                        <div class="col-lg-3 col-md-6">
                                            <p><a href="#" class="compare">Join Class</a></p>
                                        </div>
                                        <div class="col-lg-3 col-md-6">
                                            <p><a href="chat-me.htm" class="compare"><i class="fas fa-comments"></i> Chat Me</a></p>
                                        </div>
                                        <div class="col-lg-3 col-md-6">
                                            <p><a href="" class="compare"><i class="fas fa-folder"></i> My Folder</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    
                  </div>
            </div>
        </div>
    </div>
</section>