<div class="mb-3 contT">
	<div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Mitt Konto</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
    	<h1 class="float-left"><span class="txt_gray mob_hide">Details Of </span>"Madhur S."</h1>			
		<!--<p class="float-right"><a href="#" data-toggle="modal" data-target="#add" class="compare"><i class="fas fa-plus"></i> Add Course</a></p>-->
		<p class="clearfix"></p>
    </div>
</div>

<section class="animated3 wow drop_eff5 animated" style="visibility: visible;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-3">
                        <!-- filter-->
                            <form class="list_box mb-4">
                            <div class="dd_next hand p-3 bdrb fs16 b"> <span>Search</span></div>
                            <div class="emp_area f_dd_box p-3 mb-4">
                                <div class="name_feild">
                                    <p>Student Name</p>
                                    <p><input type="text"></p>
                                </div>
                                <div class="loc_feild">
                                    <p>Student Email-ID</p>
                                    <p><input type="text"></p>
                                </div>
                                <div class="loc_feild">
                                    <p>Student Contact Number</p>
                                    <p><input type="text"></p>
                                </div>
                                <div class="loc_feild">
                                    <p>Date Range</p>
                                    <p><select name="" id=""><option value="">Select</option></select></p>
                                </div>
                                
                                <div class="loc_feild">
                                    <input type="submit" value="Submit" class="compare w100">
                                </div>
                            </div>
                            </form>
                        <!-- filter end-->
                    </div>
                    <div class="col-lg-9 col-sm-8 col-sm-12 wow fadeInLeft animated" style="visibility: visible;">
                        <div class="list_box p-4 fs13 bg-gray">
                            <p class="fs16 b bdrb pb-1 mb-4">Student Info</p>
                            <div class="row">
                                <div class="col-lg-4 col-md-6 pb-3">
                                    <p>Email ID</p>
                                    <p class="b mt-2"><a href="mailto:madhurs@gmail.com">madhurs@gmail.com</a></p>
                                </div>
                                <div class="col-lg-4 col-md-6 pb-3">
                                    <p>Contact No.</p>
                                    <p class="b mt-2">1234567890</p>
                                </div>
                                
                                <div class="col-lg-4 col-md-6 pb-3">
                                    <p><a href="#cha" class="compare">Chat With "Madhur S."</a></p>
                                </div>
                            </div>
                        </div>

                        <div class="list_box p-4 fs13 bg-gray">
                            <div class="row bdrb pb-4 mb-4">
                                <div class="col-md-6"><p class="fs16 b">Schedule Classes</p></div>
                                <div class="col-md-3 mb-2"><a href="#" data-toggle="modal" data-target="#res" class="compare">Reschedule</a></div>
                                <div class="col-md-3 mb-2"><a href="#" data-toggle="modal" data-target="#cr" class="compare">Cancel &amp; Refund</a></div>
                            </div>
                            
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 pb-3">
                                            <p>Course Name</p>
                                            <p class="b mt-2 fs18"><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                            <p class="mt-2 pb-3 bdrb mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur rhoncus urna a commodo rhoncus. Sed porta ac ipsum in accumsan.</p>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p>Booked Date</p>
                                            <p class="b mt-2">Friday, July 16, 2021</p>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p>Start Date</p>
                                            <p class="b mt-2">Friday, July 20, 2021</p>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p>Payment Status</p>
                                            <p class="b mt-2">Paid</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <a name="cha"></a>
                        <div class="list_box p-4 fs13 bg-gray">
                            <p class="fs16 b bdrb pb-1 mb-4">Scorecard</p>
                            <div class="row">
                                <div class="col-lg-9 col-md-6 pb-3">
                                    <p>Course Name</p>
                                    <p class="b mt-2 fs18"><a href="course-dtl.htm">British (Including IGCSE)</a></p>
                                    <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique.</p>

                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <p class="mt-3"><button onclick="importData()" class="compare">Upload Scorecard</button></p>
                                </div>
                            </div>
                        </div>

                        <!--<div class="list_box p-4 fs13 bg-gray">
                            <p class="fs16 b bdrb pb-1 mb-4">Notification</p>
                            <div class="list_box p-4 fs13">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 pb-3">
                                        <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique.</p>                                   
                                    </div>
                                </div>
                            </div>

                            <div class="list_box p-4 fs13">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 pb-3">
                                        <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam volutpat posuere tristique.</p>                                   
                                    </div>
                                </div>
                            </div>
                        </div>-->
                        
                        <div class="list_box p-4 fs13 bg-gray">
                            <p class="fs16 b bdrb pb-1 mb-4">Chat With "Madhur S."</p>
                            <form>
                                <div class="emp_area">
                                    <div class="name_feild">
                                        <p><textarea name="" id="" cols="30" rows="20"></textarea></p>
                                    </div>                          
                                </div>
                            </form>
                        </div>

                    </div>  
                </div>
            </div>
        </div>
    </div>
</section>