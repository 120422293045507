<div class="mb-3 contT">
	<div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Mitt Konto</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
    	<h1 class="float-left"><span class="txt_gray mob_hide">Manage </span> 15 Minute Meeting Requests</h1>			
		<!--<p class="float-right"><a href="#" data-toggle="modal" data-target="#add" class="compare"><i class="fas fa-plus"></i> Add Question Paper</a></p>-->
		<p class="clearfix"></p>
    </div>
</div>


<section class="animated3 wow drop_eff5" style="visibility: hidden; animation-name: none;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-3">
                        <!-- filter-->
                            <form class="list_box mb-4 profbg">
                            <div class="dd_next hand p-3 bdrb fs16 b white"> <span>Search</span></div>
                            <div class="emp_area f_dd_box p-3 mb-4">
                                <div class="name_feild white">
                                    <p>Class Title</p>
                                    <p><input type="text"></p>
                                </div>
                                <div class="loc_feild white">
                                    <p>Timing</p>
                                    <p><input type="text"></p>
                                </div>
                                <div class="loc_feild white">
                                    <p>Date Range</p>
                                    <p><select name="" id=""><option value="">Select</option></select></p>
                                </div>
                                
                                <div class="loc_feild">
                                    <input type="submit" value="Submit" class="compare w100">
                                </div>
                            </div>
                            </form>
                        <!-- filter end-->
                    </div>
                    <div class="col-lg-9 col-sm-8 col-sm-12 wow fadeInLeft" style="visibility: hidden; animation-name: none;">
                        <div class="list_box p-4 fs13 rel profbg">
                            <a href="" class="del"><i class="fas fa-trash-alt"></i></a>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 pb-3">
                                    <p class="b mt-2 fs18 ac white">Farah Husni</p>
                                    <div class="p-4 row">
                                        <div class="col-md-4 ac white">
                                            <p>British(Including IGCSE)</p>
                                        </div>
                                        <div class="col-md-4 ac white">
                                            <p>Key stage -2</p>
                                        </div>
                                        <div class="col-md-4 ac white">
                                            <p>English</p>
                                        </div>
                                    </div>
                                    
                                    <div class="row mt-4">
                                        <div class="col-md-4">
                                            <form class="list_box p-4 bgw">
                                                <div class="emp_area ">
                                                    <div class="name_feild">
                                                        <p>Date</p>
                                                        <p><select name="" id="">
                                                            <option value="">Select</option>
                                                        </select></p>
                                                    </div>
                                                    <div class="loc_feild">
                                                        <p>Time</p>
                                                        <p><select name="" id="">
                                                            <option value="">Select</option>
                                                        </select></p>
                                                    </div>
                                                    <div class="loc_feild ac">
                                                        <input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        
                                        <div class="col-md-4">
                                            <form class="list_box p-4 bgw">
                                                <div class="emp_area ">
                                                    <div class="name_feild">
                                                        <p>Date</p>
                                                        <p><select name="" id="">
                                                            <option value="">Select</option>
                                                        </select></p>
                                                    </div>
                                                    <div class="loc_feild">
                                                        <p>Time</p>
                                                        <p><select name="" id="">
                                                            <option value="">Select</option>
                                                        </select></p>
                                                    </div>
                                                    <div class="loc_feild ac">
                                                        <input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        
                                        <div class="col-md-4">
                                            <form class="list_box p-4 bgw">
                                                <div class="emp_area">
                                                    <div class="name_feild">
                                                        <p>Date</p>
                                                        <p><select name="" id="">
                                                            <option value="">Select</option>
                                                        </select></p>
                                                    </div>
                                                    <div class="loc_feild">
                                                        <p>Time</p>
                                                        <p><select name="" id="">
                                                            <option value="">Select</option>
                                                        </select></p>
                                                    </div>
                                                    <div class="loc_feild ac">
                                                        <input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-4"><a href="" class="compare">Accept</a></div>
                                        <div class="col-md-4"><a href="" class="compare">Chat with Farah Husni</a></div>
                                        <div class="col-md-4"><a href="" class="compare">Reject</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="list_box p-4 fs13 rel profbg">
                            <a href="" class="del"><i class="fas fa-trash-alt"></i></a>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 pb-3">
                                    <p class="b mt-2 fs18 ac white">Farah Husni</p>
                                    <div class="p-4 row">
                                        <div class="col-md-4 ac white">
                                            <p>British(Including IGCSE)</p>
                                        </div>
                                        <div class="col-md-4 ac white">
                                            <p>Key stage -2</p>
                                        </div>
                                        <div class="col-md-4 ac white">
                                            <p>English</p>
                                        </div>
                                    </div>
                                    
                                    <div class="row mt-4">
                                        <div class="col-md-4">
                                            <form class="list_box p-4 bgw">
                                                <div class="emp_area ">
                                                    <div class="name_feild">
                                                        <p>Date</p>
                                                        <p><select name="" id="">
                                                            <option value="">Select</option>
                                                        </select></p>
                                                    </div>
                                                    <div class="loc_feild">
                                                        <p>Time</p>
                                                        <p><select name="" id="">
                                                            <option value="">Select</option>
                                                        </select></p>
                                                    </div>
                                                    <div class="loc_feild ac">
                                                        <input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        
                                        <div class="col-md-4">
                                            <form class="list_box p-4 bgw">
                                                <div class="emp_area ">
                                                    <div class="name_feild">
                                                        <p>Date</p>
                                                        <p><select name="" id="">
                                                            <option value="">Select</option>
                                                        </select></p>
                                                    </div>
                                                    <div class="loc_feild">
                                                        <p>Time</p>
                                                        <p><select name="" id="">
                                                            <option value="">Select</option>
                                                        </select></p>
                                                    </div>
                                                    <div class="loc_feild ac">
                                                        <input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        
                                        <div class="col-md-4">
                                            <form class="list_box p-4 bgw">
                                                <div class="emp_area">
                                                    <div class="name_feild">
                                                        <p>Date</p>
                                                        <p><select name="" id="">
                                                            <option value="">Select</option>
                                                        </select></p>
                                                    </div>
                                                    <div class="loc_feild">
                                                        <p>Time</p>
                                                        <p><select name="" id="">
                                                            <option value="">Select</option>
                                                        </select></p>
                                                    </div>
                                                    <div class="loc_feild ac">
                                                        <input type="checkbox" name="vehicle1" value="Bike" class="vam mr-2">
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-4"><a href="" class="compare">Accept</a></div>
                                        <div class="col-md-4"><a href="" class="compare">Chat with Farah Husni</a></div>
                                        <div class="col-md-4"><a href="" class="compare">Reject</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>  
                </div>
            </div>
        </div>
    </div>
</section>