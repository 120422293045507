import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';

import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { Meta, Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-product-search',
  templateUrl: './product_search.component.html',
  styleUrls: ['./product_search.component.scss']
})

export class ProductSearchComponent implements OnInit {
  flag:any=null;
  keyword=null;
  homeBlog: any=[];
  resData:any=null;
  catData:any=null;
  constructor(
    public apiService :ApiService,public sanitizer: DomSanitizer, public activatedRoute: ActivatedRoute,private title: Title, private meta: Meta,public commonService:CommonService) { 
    this.flag = this.activatedRoute.snapshot.paramMap.get("keyword");
    this.commonService.SetFooterContent(false);
    if (this.flag) {
      this.keyword = this.flag;
      console.log(this.flag);
      this.commonService.SetisProductIdChange(this.keyword);
    }
  }

  ngOnInit(): void {
    // this.getDataById(this.categoryId);
    
    this.getDataById();
  }
  allowurl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getDataById() {
    var self = this;
    var sendRequestData = {
      isAdmin:0,
      keyword: this.keyword,
      limit:'500'
    };
    self.apiService.post('product_list', sendRequestData).subscribe((res: any) => {
      if (res.replyCode == 'success') {
        this.resData = res.data;
       
      }
    },
    error => {
      console.log("Oops! Something went wrong!");
    });
  }

}
