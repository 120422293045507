import { Component, OnInit } from '@angular/core';
import { ApiService, CommonService } from '../services';
import { DomSanitizer } from '@angular/platform-browser';
import { WINDOW } from 'ngx-owl-carousel-o/lib/services/window-ref.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  categoryList: any=[];
  footerData:any=[];
  cmsList:any=[];
  contentBlockData:any=[];
  constructor(public apiService :ApiService,public sanitizer: DomSanitizer,public commonService:CommonService) { }
  footer_content:boolean=false;
  onOpenLoginPopup(){
    this.commonService.SetLoginPopup(true);
  }
  ngOnInit(): void {
    this.commonService.GetFooterContent().subscribe(data =>{
      this.footer_content=data;
    })
    this.getCmsList();
    this.getContentBlocks();
    this.commonService.GetSetting().subscribe((data:any)=> {
      this.footerData=data;
      console.log('this.footerData>>>>>',this.footerData);
    })
  }
  onOpenRegisterPopup(){
    localStorage.setItem('register_pop_up',"1");
    
    this.commonService.SetRegisterPopup(true);
  }
  getContentBlocks(){
    var sendrequestinput:any={'limit':40};
    this.apiService.post('content_blocks_list',sendrequestinput).subscribe((data:any) =>{
      this.contentBlockData=data.data
      
    } 
    );
  }
  allowurl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  
  getCmsList(){
    var sendrequestinput:any={isAdmin:0,type:0,limit:5};
    this.apiService.post('web_cms_list',sendrequestinput).subscribe((data:any) => this.cmsList=data.data)
  }
}
