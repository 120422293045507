<div class="clearfix"></div>
<div class="mb-3 contT">
    <div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="javascript:void(0);">Home</a></li>
            <li><a href="javascript:void(0);">Hitta en Studiementor</a></li>
            <li class="active">Intresseanmälan </li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
        <h1 class="text-center"><span class="txt_gray mob_hide">Welcome In </span>Intresseanmälan </h1>
    </div>
</div>
<div class="clearfix"></div>
<section class="animated3 wow drop_eff5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-3" *ngIf="TeacherDetails">
                <div class="list_box">
                    <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:void(0);" title="" class="port_pop1"><img [src]="allowurl(apiService.imageBase+TeacherDetails.image)" onError="this.src='assets/images/profile-demo.png'" alt="" class="img-fluid"></a>
                    <div class="p-3">
                        <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></p>
                        <p class="b float-left"><a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:void(0);" title="" class="port_pop1">{{TeacherDetails.name}}</a></p>
                        <p class="b float-right">SEK {{TeacherDetails.rate}}.00/hr</p>
                        <p class="clearfix"></p>
                        <p class="fs12">{{TeacherDetails.city_name}},{{TeacherDetails.state_name}}, {{TeacherDetails.country_name}}</p>
                        <p class="tbot">Totala klass Erfarenhet : <span>{{TeacherDetails.work_experience}}</span></p>
                    </div>
                </div>
                <p class="link_btn"><a href="javascript:void(0);" title="Let's Chat"><i class="fas fa-comments"></i> Chat
                        Me</a></p>
            </div>
            <div class="col-md-6">
                <div class="list_box p-5 m-auto bg-gray">
                    <form [formGroup]="createuser">
                        <div class="emp_area">
                            <div class="name_feild">
                                <p>Level</p>
                                <p><select name="" id="" class="form-control" formControlName="level_id" [ngClass]="{ 'is-invalid': submitted && f.level_id.errors }">
                                        <option value="">Select</option>
                                        <option [value]="lvl.id" *ngFor="let lvl of LevelList">{{lvl.title}}
                                        </option>
                                    </select></p>
                            </div>
                            <div class="loc_feild">
                                <p>Subject</p>
                                <p><select name="" id="" class="form-control" formControlName="subject_id" [ngClass]="{ 'is-invalid': submitted && f.subject_id.errors }">
                                        <option value="">Select</option>
                                        <option [value]="lvl.id" *ngFor="let lvl of CategoryList">{{lvl.name}}
                                        </option>
                                    </select></p>
                            </div>
                            <div class="loc_feild">
                                <div class="row">
                                    <div class="col-md-12 p-0 mb-2">
                                        <p>For whom is the class for?</p>
                                        <p><select name="" id="" class="form-control" formControlName="tution_for" [ngClass]="{ 'is-invalid': submitted && f.tution_for.errors }">
                                                <option value="">Select</option>
                                                <option value="Me">Me</option>
                                                <option value="Child">Child</option>
                                            </select></p>
                                    </div>
                                </div>
                            </div>
                            <div class="loc_feild">
                                <div class="row">
                                    <div class="col-md-12 p-0 mb-2">
                                        <p>Frequency of the tuition classes?</p>
                                        <p><select name="" id="" class="form-control" formControlName="tution_frequency" [ngClass]="{ 'is-invalid': submitted && f.tution_frequency.errors }">
                                                <option value="">Select</option>
                                                <option value="Regular lessons">Regular lessons</option>
                                                <option value="One-off lesson">One-off lesson</option>
                                            </select></p>
                                    </div>
                                </div>
                            </div>
                            <div class="loc_feild bdrb pb-4">
                                <div class="row">
                                    <div class="col-md-6 p-0 mb-2">
                                        <p>Select Date</p>
                                        <p><select name="" id="" (change)="onSelectDate($event.target)" class="form-control" formControlName="schedule_slot_date" [ngClass]="{ 'is-invalid': submitted && f.schedule_slot_date.errors }">
                                                <option value="">Select</option>
                                                <option [value]="lvl.id" *ngFor="let lvl of TeacherSLotDetails" [disabled]="lvl.available != 1">{{lvl.schedule_date |date}}
                                                </option>
                                            </select></p>
                                    </div>
                                    <div class="col-md-6 p-0 mb-2">
                                        <p>Select Slot</p>
                                        <p><select name="" id="" class="form-control" formControlName="slot_id" [ngClass]="{ 'is-invalid': submitted && f.slot_id.errors }" (change)="onSelectTime($event.target)">
                                                <option value="">Select</option>
                                                <option [value]="lvl.id" *ngFor="let lvl of SlotData" [disabled]="lvl.available != 1">{{lvl.time_from}} - {{lvl.time_to}}
                                                </option>
                                            </select></p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="loc_feild">
                                <div class="row">
                                    <div class="col-md-12 p-0 mb-2">
                                        <p><textarea name="" id="" cols="30" rows="5" placeholder="Share your great aspirations with Rebecca L to help you achieve your goals."></textarea>
                                        </p>
                                        <p class="gray1 font-weight-normal fs11 ac">Let "Rebecca L" find out more about your bright objectives to help you set learning goals. Please don’t include any personal or bank details, including email(s).</p>
                                    </div>
                                </div>
                            </div> -->
                            <div class="loc_feild">
                                <p class="add_cart"><a href="javascript:void(0);" (click)="onSubmit()"><i class="fas fa-laptop"></i>Confirm</a></p>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>