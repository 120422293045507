<div class="mb-3 contT">
	<div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <!--<li><a href="listing.htm">Chair</a></li>-->
            <li class="active">Mitt Konto</li>
        </ul>
    </div>
    <div class="container pl-3 pr-3">
    	<h1 class="float-left"><span class="txt_gray mob_hide">Welcome To </span>My Earning</h1>			
		<!--<p class="float-right"><a href="#" data-toggle="modal" data-target="#add" class="compare"><i class="fas fa-plus"></i> Add Course</a></p>-->
		<p class="clearfix"></p>
    </div>
</div>

<section class="animated3 wow drop_eff5" style="visibility: hidden; animation-name: none;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row justify-content-center"><!-- #BeginLibraryItem "/Library/tutor-left-link.lbi" --><div class="col-lg-3 wow fadeInLeft " style="visibility: hidden; animation-name: none;">
    <div class="lft_link1">
        <a href="tutor-account.htm"><i class="fas fa-edit"></i>Min Profil</a>
        <a href="tutor-account-detail.htm"><i class="fas fa-edit"></i>Account</a>
        <a href="tutor-verification.htm"><i class="fas fa-edit"></i>Verification</a>
        <a href="tutor-chat-students.htm"><i class="fas fa-comments"></i>Chat With Students</a>
        <a href="tutor-account-n3.htm"><i class="fas fa-comments"></i>Availability</a>
        <!--<a href="../tutor-manage-class.htm"><i class="fas fa-child"></i>Lessons</a>-->
        <a href="tutor-my-earning.htm"><i class="fas fa-money-bill-alt"></i>My Earning</a>
        <a href="tutor-subject.htm"><i class="fas fa-money-bill-alt"></i>Subjects</a>
        <a href="tutor-manage-courses.htm"><i class="fas fa-money-bill-alt"></i>Courses</a>
        <a href="tutor-manage-class.htm"><i class="fas fa-money-bill-alt"></i>Group Classes</a>
        <a href="tutor-manage-exam-questions.htm"><i class="fas fa-money-bill-alt"></i>Exam Papers</a>
        <a href="tutor-manage-homework.htm"><i class="fas fa-money-bill-alt"></i>Monitor Progress</a>
        <a href="tutor-manage-Recensioner.htm"><i class="fas fa-star"></i>Recensioner</a>       
        <a data-toggle="modal" data-target="#lot"><i class="fas fa-sign-out-alt"></i>Logout</a>
    </div>          
</div>
<div class="col-lg-9 wow fadeInLeft" style="visibility: hidden; animation-name: none;">
              <div class="list_box fs13 rel ">
                            <div class="row">
                                <div class="col-md-6 p-4">
                                    <p class="fs18 b">My Earnings</p>
                                    <p class="mt-2">Please note: payment dates are indicative only</p>
                                </div>
                                <div class="col-md-6 p-4 e_txt bg-gray">
                                    <p>Total Earnings:</p>
                                    <p class="b mt-2 fs18">SEK 1,861.57</p>
                                </div>
                            </div>
                        </div>
                        <div class="list_box fs13 rel ">
                            <div class="row">
                                <div class="col-md-8 p-4">
                                    <div class="row emp_area">
                                        <div class="col-md-5"><input type="date"></div>
                                        <div class="col-md-5"><input type="date"></div>
                                        <div class="col-md-2 add_cart pt-2"><a href="tutor-registration.htm"><i class="fa fa-search pl-3 pr-3" aria-hidden="true"></i></a></div>
                                    </div>
                                </div>
                                <div class="col-md-4 p-4 e_txt">
                                    <p>Period Earnings</p>
                                    <p class="b mt-2 fs16">SEK 1,861.57</p>
                                </div>
                            </div>
                        </div>
                        
                        <div class="list_box fs13 rel">
                            <ul class="tabl">
                                <li class="b">Date</li>
                                <li class="b">Name</li>
                                <li class="b">Description</li>
                                <li class="b">Amount</li>
                            </ul>
                            <ul class="tabl2">
                                <li>25th February, 2019</li>
                                <li>NAT WEST BANK PLC .... <br>3959</li>
                                <li>Paid</li>
                                <li>SEK 24.00</li>
                            </ul>
                            <ul class="tabl2">
                                <li>25th February, 2019</li>
                                <li>NAT WEST BANK PLC .... <br>3959</li>
                                <li>Paid</li>
                                <li>SEK 24.00</li>
                            </ul>
                            <p class="clearfix"></p>                            
                        </div>
                        
                        
                    </div>  
          </div>
          </div>
        </div>
    </div>
</section>