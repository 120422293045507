<div class="modal fade show pp" id="lot" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">Logga ut</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 pr-0">
                        <!-- start -->
                        <div class="log_box pt-0">
                            <div class="login_form">
                                    <h5>Är du säker, vill du  Logga ut?</h5>
                                    <div class="row">
                                        <div class="col-md-6 ac"><a href="javascript:void(0);" (click)="onClosePopup()" class="style1 bg extra-padding" data-dismiss="modal">Cancel</a></div>
                                        <div class="col-md-6 ac"> <a href="javascript:void(0);" class="style1 extra-padding" (click)="onLogout()" style="display: inline-block;">Yes</a></div>
                                    </div>
                            </div>
                        </div>
                        <!-- end -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>