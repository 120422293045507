import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout/layout.component';

import { HomeComponent } from './home/home.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { AboutComponent } from './about/about.component';
// import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { EnquiryComponent } from './in_person_enquiry/in_person_enquiry.component';
// import { BlogDetailsComponent } from './blog_details/blog_details.component';
import { ProductListingComponent } from './product_listing/product_listing.component';
import { ProductDetailsComponent } from './product_details/product_details.component';
import { CategoryListingComponent } from './category_listing/category_listing.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { RequestQouteComponent } from './request_qoute/request_quote.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { CalculateCostComponent } from './calculate_cost/calculate_cost.component';
import { CmsComponent } from './cms/cms.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { HotProductComponent } from './hot_product/hot_product.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { ProductSearchComponent } from './product_search/product_search.component';
import { BrandProductComponent } from './brand_product/brand_product.component';
import { FaqsComponent } from './faqs/faqs.component';
import { TeamComponent } from './team/team.component';
import { CollectionsComponent } from './collections/collections.component';
import { BecomeTutorComponent, CompareStudentTutorComponent, FindTutorComponent, ParentRegistrationComponent, StudentRegistrationComponent, TutorRegistrationComponent } from './tutor';

import { ChangePasswordComponent, ParentEditProfileComponent, ParentProfileComponent, StudentEditProfileComponent, StudentProfileComponent, TutorEditProfileComponent, TutorProfileComponent } from './profile';
import { BookClassComponent } from './book-a-class/book-a-class.component';
import { MyClassComponent } from './my-class/my-class.component';
import { TutorDetailsComponent } from './tutor-details/tutor-details.component';

//hot-category-lisitng
import { BlogComponent,BlogDetailsComponent,BrowseCourseComponent,BrowseSubjectComponent,ClassDashboardComponent,CourseDetailsComponent,GroupClassComponent,MyWishlistComponent,OnlineSpaceComponent,ParentBlogComponent,ParentNotificationComponent,ParentSupportComponent,PriceComponent,StudentClassesScheduleComponent,StudentMonthlyScorecardComponent,StudentStudyMaterialComponent,SupportComponent,TutorManageRequestMeetingClassesComponent,TutorManageResultsComponent,TutorMyEarningComponent,TutorRedeemRequestsComponent,TutorShortDetailsComponent,TutorStudentDetailsComponent,FindCourseComponent, ParentContactComponent, ChatComponent, CompareCourseComponent, ContactUsPageComponent, AllCourseComponent, ReferProgramComponent, BlogListComponent, MyScorecardComponent } from './component';
import { BookCourseComponent } from './book-course/book-course.component';
import { DemoClassComponent } from './demo-class/demo-class.component';
import { NewdeshboardComponent } from './component/newdeshboard/newdeshboard.component';
import { SuccessComponent } from './success/success.component';
import { FailureComponent } from './failure/failure.component';
import { CancelComponent } from './cancel/cancel.component';

// import { DashboardComponent } from './profile/tutor/dashboard/dashboard.component';

const routes: Routes = [
 {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'newdeshboard', component: NewdeshboardComponent },

      { path: 'browser-course', component: BrowseCourseComponent },
      { path: 'browser-subject', component: BrowseSubjectComponent },
      { path: 'class-dashboard', component: ClassDashboardComponent },
      { path: 'course-details/:id', component: CourseDetailsComponent },
      { path: 'group-class', component: GroupClassComponent },
      { path: 'my-wishlist', component: MyWishlistComponent },
      { path: 'online-space', component: OnlineSpaceComponent },
      { path: 'parent-blog', component: ParentBlogComponent },
      { path: 'parent-notofication', component: ParentNotificationComponent },
      { path: 'parent-support', component: ParentSupportComponent },
      { path: 'contact', component: ParentContactComponent },
      { path: 'price', component: PriceComponent },
      { path: 'student-class-schedule', component: StudentClassesScheduleComponent },
      { path: 'student-monthly-scorecard', component: StudentMonthlyScorecardComponent },
      { path: 'student-study-material', component: StudentStudyMaterialComponent },
      { path: 'support', component: SupportComponent },
      { path: 'tutor-manage-request-meeting-class', component: TutorManageRequestMeetingClassesComponent },
      { path: 'tutor-manage-result', component: TutorManageResultsComponent },
      { path: 'tutor-earning', component: TutorMyEarningComponent },
      { path: 'tutor-redeem-request', component: TutorRedeemRequestsComponent },
      { path: 'tutor-short-details', component: TutorShortDetailsComponent },
      { path: 'tutor-student-details', component: TutorStudentDetailsComponent },
      { path: 'testimonials', component: TestimonialsComponent },
      { path: 'faqs', component: FaqsComponent },
      { path: 'success', component: SuccessComponent },
      { path: 'failure', component: FailureComponent },
      { path: 'cancel', component: CancelComponent },
      { path: 'team', component: TeamComponent },
      { path: 'collections', component: CollectionsComponent },
      { path: 'about-us', component: AboutComponent },
      { path: 'cms/:flag', component: CmsComponent },
      { path: 'blog', component: BlogComponent },
      { path: 'blog/:id', component: BlogListComponent },
      { path: 'find-course/:id', component: FindCourseComponent },
      { path: 'contact-us', component: ContactComponent },
      { path: 'in-person-enquiry', component: EnquiryComponent },
      { path: 'blog-details/:id', component: BlogDetailsComponent },
      { path: 'product-listing/:id', component: ProductListingComponent },
      { path: 'product-search-result/:keyword', component: ProductSearchComponent },
      { path: 'product-details/:id', component: ProductDetailsComponent },
      { path: 'category-lisitng/:type', component: CategoryListingComponent },
      { path: 'newsletters', component: NewsletterComponent },
      { path: 'request-quote', component: RequestQouteComponent },
      { path: 'calculator/:id', component: CalculatorComponent },
      { path: 'calculate-cost/:id/:pro_id', component: CalculateCostComponent },
      { path: 'thankyou', component: ThankyouComponent },
      { path: 'hot-product', component: HotProductComponent },
      { path: 'feedback', component: FeedbackComponent },
      { path: 'brand-product-list/:brand', component: BrandProductComponent },
      { path: 'become-a-tutor', component: BecomeTutorComponent },
      { path: 'find-a-tutor', component: FindTutorComponent },
      { path: 'tutor-registration', component: TutorRegistrationComponent },
      { path: 'student-registration', component: StudentRegistrationComponent },
      { path: 'parent-registration', component: ParentRegistrationComponent },
      { path: 'student-compare-tutor', component: CompareStudentTutorComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'student-profile', component: StudentProfileComponent },
      { path: 'student-edit-profile', component: StudentEditProfileComponent },
      { path: 'parent-profile', component: ParentProfileComponent },
      { path: 'parent-edit-profile', component: ParentEditProfileComponent },
      { path: 'tutor-profile', component: TutorProfileComponent },
      { path: 'tutor-edit-profile', component: TutorEditProfileComponent },
      { path: 'book-a-class/:id', component: BookClassComponent },
      { path: 'book-course/:id', component: BookCourseComponent },
      { path: 'my-class', component: MyClassComponent },
      { path: 'tutor-details/:id', component: TutorDetailsComponent },
      { path: 'chat', component: ChatComponent },
      { path: 'chats/:id', component: ChatComponent },
      { path: 'compare-course', component: CompareCourseComponent },
      { path: 'book-a-demo-class/:id', component: DemoClassComponent },
      { path: 'refer-program', component: ReferProgramComponent },
      { path: 'contact-us', component: ContactUsPageComponent },
      { path: 'all-courses', component: AllCourseComponent },
      { path: 'my-scorecard', component: MyScorecardComponent },
    ]
  },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [HeaderComponent, FooterComponent]
