import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiService,CommonService,MessageService } from '../services';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  addForm: FormGroup | any;
  
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);

  }

  ngOnInit(): void {
    this.addForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      status: ['', [Validators.required]]
    })
  }

  onaddForm() {
    var self = this;
    if (self.addForm.valid) {
      var sendRequestData = self.addForm.value;
      var requestUrl;
      requestUrl = 'newsletter_subscribe';
      
      self.apiService.post(requestUrl, sendRequestData).subscribe((res: any) => {
        if (res.replyCode == 'success') {
          self.addForm.reset();
          this.messageService.showSuccess(res.replyMsg)
          //this.router.navigate(['/product'])
        }
        else {
          this.messageService.showError(res.replyMsg)
        }
      },
        error => {
          this.messageService.showError('Oops! Something went wrong!')

        });

    }
  }
}
