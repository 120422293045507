<div class="clearfix"></div>
<!-- #EndLibraryItem -->
<!--head+filter-->
<div class="mb-3 contT">
    <div class="container pl-3">
        <ul class="breadcrumb">
            <li><a href="index.htm">Home</a></li>
            <li class="active">Mitt Konto</li>
        </ul>
    </div>

    <div class="container pl-3 pr-3">
        <h1><span class="txt_gray mob_hide"></span>{{Content_Type? Content_Type : ''}}</h1>
        <p class="text-right" *ngIf="Content_Type == 'Subjects'">
            <a href="javascript:void(0);" (click)="onOpenSubjectModal()" class="compare">
                <i class="fas fa-plus"></i> LÄGG TILL ÄMNE</a>
        </p>


        <p class="clearfix"></p>
    </div>
</div>
<div class="clearfix"></div>
<div class="mb-3 contT">
    <div class="container pl-3">

    </div>

    <div class="container pl-3 pr-3">
        <a href="javascript:void(0);" routerLink="/tutor-details/{{list}}" class="link_here_btn" title=""> SE DIN PROFIL</a>
        <!-- <a class="nav-link" style="background: none !important;color: #909090 !important;" routerLink="/tutor-details/{{list.id}}"><i class="fas fa-comments"></i>Chat With Students</a> -->

        <p class="clearfix"></p>
    </div>
</div>
<section class="animated3 wow drop_eff5" *ngIf="userData">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 wow fadeInLeft ">
                <div class="lft_link1">
                    <ul class="nav nav-pills mb-3 d-block" id="pills-tab" role="tablist">

                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" style="    background: none !important;
                        color: #909090 !important;" id="pills-tab1-tab" data-toggle="pill" href="#pills-tab1"
                                role="tab" aria-controls="pills-tab1" aria-selected="true"
                                (click)="onChangeTypeContent('Bio');"><i
                                    class="fas fa-edit"></i>Bio</a>

                        </li>


                        <!-- <li class="nav-item" role="presentation">
                            <a class="nav-link" style="background: none !important;
                        color: #909090 !important;" id="pills-tab7-tab" data-toggle="pill" href="#pills-tab7" role="tab" aria-controls="pills-tab7" aria-selected="false" (click)="onChangeTypeContent('Subjects');"><i
                                    class="fas fa-money-bill-alt"></i>Subjects Provided</a>
                        </li> -->
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" style="background: none !important;color: #909090 !important;"
                                id="pills-tab5-tab" data-toggle="pill" href="#pills-tab5" role="tab"
                                aria-controls="pills-tab5" aria-selected="false"
                                (click)="onChangeTypeContent('Tillgänglighet');"><i
                                    class="fas fa-comments"></i>Tillgänglighet</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" style="background: none !important;color: #909090 !important;"
                                id="pills-tab6-tab" data-toggle="pill" href="#pills-tab6" role="tab"
                                aria-controls="pills-tab6" aria-selected="false"
                                (click)="onChangeTypeContent('Undervisningspreferenser');"><i
                                    class="fas fa-money-bill-alt"></i>Undervisningspreferenser</a>
                        </li>

                    </ul>
                </div>
            </div>
            <div class="col-lg-9 col-sm-12 wow fadeInLeft">
                <div class="tab-content" id="pills-tabContent">

                    <div class="tab-pane fade show active" id="pills-tab1" role="tabpanel"
                        aria-labelledby="pills-tab1-tab">
                        <div class="list_box1">
                            <div class="tab_link">
                                <p class="for_m dd_next hand">Edit BIO <i class="fas fa-caret-down"></i></p>
                                <div class="for_d ">
                                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link active" aria-selected="true">Edit BIO</a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div class="tab_cont tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                    aria-labelledby="pills-home-tab">
                                    <form [formGroup]="createuser6">
                                        <div class="emp_area">
                                            <div class="name_feild">
                                                <p class="">Om Mig</p>
                                                <p><textarea name="" id="" cols="30" rows="3" formControlName="bio"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted6 && f6.bio.errors }"></textarea>
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <p class="">Mina Kvalifikationer och Certifikat</p>
                                                <p><textarea name="" id="" cols="30" rows="3"
                                                        formControlName="achievement" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted6 && f6.achievement.errors }"></textarea>
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <p class="">Mina Framgångar och Undervisningsstrategi</p>
                                                <p><textarea name="" id="" cols="30" rows="3"
                                                        formControlName="qualification_certifications"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted6 && f6.qualification_certifications.errors }"></textarea>
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <p class="">Läraren Talar</p>
                                                <p><input type="text" formControlName="languages" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted6 && f6.languages.errors }">
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <p class="">År av Erfarenhet</p>
                                                <p><input type="number" formControlName="work_experience"
                                                        class="form-control-design1"
                                                        [ngClass]="{ 'is-invalid': submitted6 && f6.work_experience.errors }">
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <p class="">Antal genomförda lektioner</p>
                                                <p><input type="text" formControlName="Completed_l" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted6 && f6.Completed_l.errors }">
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <div class="row">
                                                    <div class="col-md-12 p-0">
                                                        <p class="add_cart"><a href="javascript:void(0);"
                                                                (click)="onSubmitInfo()">SPARA & UPPDATERA</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                                    aria-labelledby="pills-profile-tab">
                                    <form [formGroup]="createuser4">
                                        <div class="emp_area">
                                            <div class="name_feild">
                                                <p class="">Institution Name</p>
                                                <p><input type="text" formControlName="university" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted4 && f4.university.errors }">
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <p class="">Course Name</p>
                                                <p><input type="text" formControlName="degree" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted4 && f4.degree.errors }">
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <p class="">Year</p>
                                                <p><input type="text" formControlName="completion_year"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted4 && f4.completion_year.errors }">
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <div class="row">
                                                    <div class="col-md-12 p-0">
                                                        <p class="add_cart"><a href="javascript:void(0);"
                                                                (click)="onSubmitQualification()">Save</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="userData">
                                            <div class="list_box fs13 rel" *ngIf="this.userData.educations?.length>0">
                                                <ul class="tabl">
                                                    <li class="b">Institution Name</li>
                                                    <li class="b">Course Name</li>
                                                    <li class="b">Year</li>
                                                    <li class="b">Action</li>
                                                </ul>
                                                <ul class="tabl2" *ngFor="let eduData of this.userData.educations">
                                                    <li>{{eduData?.university}}</li>
                                                    <li>{{eduData?.degree}}</li>
                                                    <li>{{eduData?.completion_year}}</li>
                                                    <li class="d-flex">
                                                        <a><i class="fas fa-edit"></i></a>&nbsp;&nbsp;
                                                        <a><i class="fas fa-trash-alt"></i></a>
                                                    </li>
                                                </ul>
                                                <p class="clearfix"></p>
                                            </div>
                                        </ng-container>

                                    </form>
                                </div>
                                <div class="tab-pane fade" id="pills-contact" role="tabpanel"
                                    aria-labelledby="pills-contact-tab">
                                    <form [formGroup]="createuser3">
                                        <div class="emp_area">
                                            <div class="name_feild">
                                                <p class="">Time Zone</p>
                                                <p>
                                                    <select name="" id="" formControlName="time_zone"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted3 && f3.time_zone.errors }">
                                                        <option value="" selected>select</option>
                                                        <option [value]="time" *ngFor="let time of TimeZone">{{time}}
                                                        </option>
                                                    </select>
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <p class="">Country</p>
                                                <p>
                                                    <select name="" id="" formControlName="country" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted3 && f3.country.errors }"
                                                        (change)="onChangeCountry($event.target)">
                                                        <option value="" selected>select</option>
                                                        <option [value]="countryData.id"
                                                            *ngFor="let countryData of loadcountryList">
                                                            {{countryData.name}}</option>
                                                    </select>
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <p class="">State</p>
                                                <p>
                                                    <select name="" id="" formControlName="state" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted3 && f3.state.errors }"
                                                        (change)="onChangeState($event.target)">
                                                        <option value="" selected>select</option>
                                                        <option [value]="stateData.id"
                                                            *ngFor="let stateData of loadstateList">{{stateData.name}}
                                                        </option>
                                                    </select>
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <p class="">City</p>
                                                <p>
                                                    <select name="" id="" formControlName="city" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted3 && f3.city.errors }">
                                                        <option value="" selected>select</option>
                                                        <option [value]="cityData.id"
                                                            *ngFor="let cityData of loadcityList">{{cityData.name}}
                                                        </option>
                                                    </select>
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <p class="">Address</p>
                                                <p><input type="text" formControlName="address" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted3 && f3.address.errors }">
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <div class="row">
                                                    <div class="col-md-12 p-0">
                                                        <p class="add_cart"><a href="javascript:void(0);"
                                                                (click)="onSubmitAddress()">Save</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!-- <div class="tab-pane fade" id="pills-contact1" role="tabpanel" aria-labelledby="pills-contact1-tab">
                                    <form>
                                        <div class="emp_area">
                                            <div class="d_flex">
                                                <div class="we"></div>
                                                <div class="we b ac">Availability</div>
                                            </div>
                                            <div class="d_flex rad_cont">
                                                <div class="we b">Mon</div>
                                                <div class="we ac">
                                                    <input type="checkbox" name="chk" id="a1">
                                                    <label for="a1"><i class="fas fa-check"></i></label>
                                                </div>
                                            </div>
                                            <div class="d_flex rad_cont">
                                                <div class="we b">Tue</div>
                                                <div class="we ac">
                                                    <input type="checkbox" name="chk" id="a4">
                                                    <label for="a4"><i class="fas fa-check"></i></label>
                                                </div>
                                            </div>
                                            <div class="d_flex rad_cont">
                                                <div class="we b">Wed</div>
                                                <div class="we ac">
                                                    <input type="checkbox" name="chk" id="a7">
                                                    <label for="a7"><i class="fas fa-check"></i></label>
                                                </div>
                                            </div>
                                            <div class="d_flex rad_cont">
                                                <div class="we b">Thu</div>
                                                <div class="we ac">
                                                    <input type="checkbox" name="chk" id="a10">
                                                    <label for="a10"><i class="fas fa-check"></i></label>
                                                </div>
                                            </div>
                                            <div class="d_flex rad_cont">
                                                <div class="we b">Fri</div>
                                                <div class="we ac">
                                                    <input type="checkbox" name="chk" id="a13">
                                                    <label for="a13"><i class="fas fa-check"></i></label>
                                                </div>
                                            </div>
                                            <div class="d_flex rad_cont">
                                                <div class="we b">Sat</div>
                                                <div class="we ac">
                                                    <input type="checkbox" name="chk" id="a16">
                                                    <label for="a16"><i class="fas fa-check"></i></label>
                                                </div>
                                            </div>
                                            <div class="d_flex rad_cont">
                                                <div class="we b">Sun</div>
                                                <div class="we ac">
                                                    <input type="checkbox" name="chk" id="a19">
                                                    <label for="a19"><i class="fas fa-check"></i></label>
                                                </div>
                                            </div>
                                            <div class="loc_feild">
                                                <div class="row">
                                                    <div class="col-md-12 p-0">
                                                        <p class="add_cart"><a href="">Save</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div> -->
                                <div class="tab-pane fade" id="pills-contact2" role="tabpanel"
                                    aria-labelledby="pills-contact2-tab">
                                    <form [formGroup]="createuser9">
                                        <div class="emp_area">
                                            <div class="name_feild">
                                                <p class="">Teaching Preference</p>
                                                <p>
                                                    <select name="" class="form-control" id=""
                                                        formControlName="job_type"
                                                        [ngClass]="{ 'is-invalid': submitted9 && f9.job_type.errors }">
                                                        <option value="">Select</option>
                                                        <option value="1">Online</option>
                                                        <option value="2">På plats</option>
                                                        <option value="3">Both</option>
                                                    </select>
                                                </p>
                                            </div>
                                            <div class="loc_feild">
                                                <div class="row">
                                                    <div class="col-md-12 p-0">
                                                        <p class="add_cart"><a href="javascript:void(0);"
                                                                (click)="onSubmitJobType()">Save</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-tab2" role="tabpanel" aria-labelledby="pills-tab2-tab">
                        <div class="list_box1">
                            <div class="tab_link">
                                <p class="for_m dd_next hand">Personuppgifter <i class="fas fa-caret-down"></i></p>
                                <div class="for_d">
                                    <a href="tutor-account-detail.htm" class="acti">Personuppgifter</a>
                                    <a href="tutor-account-detail-n1.htm">Payment</a>
                                </div>
                            </div>

                            <div class="tab_cont">

                                <form [formGroup]="createuser8">
                                    <div class="emp_area">
                                        <div class="name_feild">
                                            <div class="row justify-content-center">
                                                <div class="col-md-6 text-center pb-4">
                                                    <img src="assets/images/p-blank.jpg" alt="" class="m-auto img-fluid"
                                                        *ngIf="uploadedImagesData ==''">
                                                    <img [src]="allowurl(apiService.imageBase+uploadedImagesData)"
                                                        alt="" class="m-auto img-fluid" *ngIf="uploadedImagesData !=''">
                                                    <p class="mt-3">Add Your Profile Photo</p>
                                                    <p><button class="compare"><i class="fas fa-plus"></i><input
                                                                type="file" accept="image/*"
                                                                (change)="onSelectFile($event.target)" #file id="file"
                                                                style="    opacity: 0;
                                                                position: absolute;
                                                                left: 1px;"> Add Photo</button></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="loc_feild">
                                            <p class="">Name</p>
                                            <p><input type="text" placeholder="Please type your First name"
                                                    formControlName="name" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted8 && f8.name.errors }"></p>
                                        </div>
                                        <div class="loc_feild">
                                            <p>Last Name <span class="red">*</span></p>
                                            <p><input placeholder="Please type your Last name" type="text"
                                                    [(ngModel)]="userData.last_name"
                                                    [ngModelOptions]="{standalone: true}"></p>
                                        </div>
                                        <div class="loc_feild">
                                            <p class="">Email</p>
                                            <p><input type="text" formControlName="email" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted8 && f8.email.errors }"
                                                    readonly></p>
                                        </div>
                                        <div class="loc_feild">
                                            <p>Country code</p>

                                            <p class="">
                                                <select name="" id="" formControlName="phone_code" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted3 && f3.phone_code.errors }">
                                                    <option [ngValue]="undefined" [disabled]="true">Select Country Code
                                                    </option>
                                                    <option [value]="lvl.phonecode" *ngFor="let lvl of loadcountryList">
                                                        + {{lvl.phonecode}}
                                                    </option>
                                                </select>
                                            </p>



                                        </div>
                                        <div class="loc_feild">
                                            <p>Phone <span class="red">*</span></p>
                                            <p><input placeholder="Please type your mobile number" type="text"
                                                    [(ngModel)]="'+'+userData.phone_code +' '+ userData.phone"
                                                    [ngModelOptions]="{standalone: true}"></p>
                                        </div>
                                        <div class="loc_feild">
                                            <div class="row">
                                                <div class="col-md-12 p-0">
                                                    <p class="add_cart"><a href="javascript:void(0);"
                                                            (click)="onSubmitpersonalInfo()">Save</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-tab3" role="tabpanel" aria-labelledby="pills-tab3-tab">
                        <div class="list_box p-5 m-auto bg-gray">
                            <form [formGroup]="createuser11">
                                <div class="emp_area">

                                    <div class="row justify-content-center">
                                        <div class="col-md-6 text-center pb-4">
                                            <img src="assets/images/p-blank.jpg" alt="" class="m-auto img-fluid"
                                                *ngIf="uploadedDocumentImagesData ==''" style="height: 200px;">
                                            <img [src]="allowurl(apiService.imageBase+uploadedDocumentImagesData)"
                                                alt="" class="m-auto img-fluid" *ngIf="uploadedDocumentImagesData !=''"
                                                style="height: 200px;">
                                            <p class="mt-3">Upload Photo ID {{uploadedDocumentImagesData}}</p>
                                            <p><button class="compare"><i class="fas fa-plus"></i>
                                                    <input type="file" accept="image/*"
                                                        (change)="onSelectDocumentFile($event.target)" #file id="file"
                                                        style="    opacity: 0;
                                                        position: absolute;
                                                        left: 1px;" formControlName="document"
                                                        *ngIf="!editDocumentFlag">
                                                    <input type="file" accept="image/*"
                                                        (change)="onSelectDocumentFile($event.target)" #file id="file"
                                                        style="    opacity: 0;
                                                        position: absolute;
                                                        left: 1px;" *ngIf="editDocumentFlag"> Upload Photo ID</button>
                                            </p>
                                        </div>
                                        <div class="col-md-6 text-center pb-4">
                                            <div class="name_feild">
                                                <p class="">Document name</p>
                                                <p><input type="text" formControlName="document_name"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted11 && f11.document_name.errors }">
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="loc_feild">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <p class="add_cart"><a href="javascript:void(0);"
                                                        (click)="onSubmitDocument()">Send</a></p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>

                            <div class="emp_area row">
                                <div class="col-4 mx-auto" *ngFor="let imgs of DocumentList">
                                    <div class="list_box">
                                        <a href="javascript:void(0);" style="left: 35px;" class="chat_btn"
                                            title="Edit Document" (click)="onEditDocument(imgs)"><i
                                                class="fas fa-edit"></i></a>
                                        <!-- <a href="javascript:void(0);" class="class_btn" title="Intresseanmälan " routerLink="/book-a-class/{{imgs.id}}"><i
                                            class="fas fa-eye"></i></a> -->
                                        <a href="javascript:void(0);" style="left: 118px;" class="book_btn"
                                            title="Delete Document" (click)="onDeleteDocument(imgs,2)"><i
                                                class="fas fa-trash"></i></a>
                                        <a data-type="iframe" data-src="tutor-short-details.htm"
                                            href="javascript:void(0);" title="" class="port_pop1">
                                            <div class="div" style="height: 290px;">
                                                <img [src]="allowurl(apiService.imageBase+imgs.document)" alt=""
                                                    class="img-fluid"
                                                    onError="this.src='assets/images/profile-demo.png'"
                                                    style="width: 100%;height: 100%;">
                                            </div>

                                        </a>
                                        <div class="p-3 rel">
                                            <p class="b float-left">{{imgs.document_name}}</p>
                                        </div>
                                    </div>
                                    <!-- <a ><i class="fa-solid fa-trash-can"></i>del</a>
                                    <a (click)="onEditDocument(imgs)"><i class="fa-solid fa-trash-can"></i>edit</a>
                                    <img [src]="allowurl(apiService.imageBase+imgs.document)" alt="" class="m-auto img-fluid" style="height: 200px;">
                                    <p class="text-left">{{imgs.document_name}}</p> -->
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="pills-tab4" role="tabpanel" aria-labelledby="pills-tab4-tab">
                        <div class="list_box p-5 m-auto bg-gray">
                            <form>
                                <div class="emp_area">
                                    <div class="name_feild">
                                        <div class="row">
                                            <div class="col-md-6"><select name="" id="">
                                                    <option value="">Select Student Name</option>
                                                </select></div>
                                            <div class="col-md-6"><select name="" id="">
                                                    <option value="">Select Class</option>
                                                </select></div>
                                        </div>
                                    </div>

                                    <div class="loc_feild">
                                        <p><textarea name="" id="" cols="30" rows="20"></textarea></p>
                                    </div>

                                    <div class="loc_feild">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <p><input type="text"></p>
                                            </div>
                                            <div class="col-md-3">
                                                <p class="add_cart"><a href="">Send</a></p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="pills-tab5" role="tabpanel" aria-labelledby="pills-tab5-tab">
                        <div class="section-title  My-Earning" *ngIf="SchedulerList">
                            <ng-container *ngIf="SchedulerList.length>0">
                                <ng-container *ngFor="let SchedList of SchedulerList;let i=index">
                                    <ng-container *ngIf="SchedList.holiday == '1'">
                                        <div class="row mt-3 my-2">
                                            <div class="col-12">
                                                <label class="switch">
                                                    <input type="checkbox" [checked]="SchedList.available == '1'"
                                                        [disabled]="SchedList.holiday == '1'">
                                                    <span class="slider round"></span>
                                                </label>&nbsp;<strong class="ml-2">{{SchedList.schedule_date
                                                    |date}}</strong>
                                            </div>
                                            <div class="col-12 ml-2">
                                                <p class="select_slot_caption">Select Slot</p>
                                                <div class="row">
                                                    <div class="col-4" *ngFor="let check1 of SchedList.slots">
                                                        <label class="check">{{check1.time_from.slice(0,5)}} -
                                                            {{check1.time_to.slice(0,5)}}
                                                            <input type="checkbox" name="is_name"
                                                                [disabled]="SchedList.holiday == '1'">
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="SchedList.holiday == '0'">
                                        <div class="row mt-3 my-2 mt-5">
                                            <div class="col-12">
                                                <label class="switch">
                                                    <input type="checkbox" [checked]="SchedList.available == '1'"
                                                        (click)="onChangeStatus(SchedList,$event.target)">
                                                    <span class="slider round"></span>
                                                </label>&nbsp;<strong class="ml-2">{{SchedList.schedule_date
                                                    |date}}</strong>
                                            </div>
                                            <div class="col-12 ml-2">
                                                <p class="select_slot_caption">Select Slot</p>
                                                <div class="row">
                                                    <div class="col-4" *ngFor="let check1 of SchedList.slots">
                                                        <label class="check"
                                                            [ngStyle]="{'color':check1.class_id != null ? '#ED7F21' : '' }">{{check1.time_from.slice(0,5)}}
                                                            - {{check1.time_to.slice(0,5)}}
                                                            <input type="checkbox" checked="checked" name="is_name"
                                                                [checked]="check1.available == '1' || check1.class_id != null"
                                                                [disabled]="SchedList.available == '0' || check1.class_id != null "
                                                                (click)="onChangeSlotStatus(check1.id,$event.target)">
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-tab6" role="tabpanel" aria-labelledby="pills-tab6-tab">

                        <form [formGroup]="createuser9">
                            <div class="emp_area">
                                <div class="name_feild">
                                    <p class="">Teaching Preference</p>
                                    <p>
                                        <select name="" class="form-control" id="" formControlName="job_type"
                                            [ngClass]="{ 'is-invalid': submitted9 && f9.job_type.errors }">
                                            <option value="">Select</option>
                                            <option value="1">Online</option>
                                            <option value="2">På plats</option>
                                            <option value="3">Both</option>
                                        </select>
                                    </p>
                                </div>
                                <div class="loc_feild">
                                    <div class="row">
                                        <div class="col-md-12 p-0">
                                            <p class="add_cart"><a href="javascript:void(0);"
                                                    (click)="onSubmitJobType()">Save</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane fade" id="pills-tab7" role="tabpanel" aria-labelledby="pills-tab7-tab">
                        <div class="col-lg-12 col-md-12 col-sm-12 wow fadeInLeft">
                            <div class="list_box">
                                <form>
                                    <div class="" *ngIf="userData.subjects?.length>0">
                                        <div class="d_flex">
                                            <div class="sbj b">SUBJECT</div>
                                            <div class="sbj b">LEVEL</div>
                                            <div class="sbj b">HOURLY RATE</div>
                                            <div class="sbj b">PROFILE RATE</div>
                                        </div>
                                        <div class="d_flex" *ngFor="let subjectsList of this.userData.subjects">
                                            <div class="sbj">{{subjectsList?.subject_name}}</div>
                                            <div class="sbj">{{subjectsList?.level_name}}</div>
                                            <div class="sbj">SEK {{subjectsList?.hourly_rate}}</div>
                                            <div class="sbj">SEK {{subjectsList?.profile_rate}}</div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-tab8" role="tabpanel" aria-labelledby="pills-tab8-tab">
                        <ng-container *ngIf="!addCourseFlag">
                            <div class="row">
                                <div class="col-lg-4 col-sm-6 wow fadeInLeft" *ngFor="let course_list of CourseList">
                                    <div class="list_box">
                                        <a href="javascript:void(0);" class="chat_btn" title="Let's Chat"
                                            (click)="onEditCourse(course_list)"><i class="fas fa-edit"></i></a>
                                        <a href="javascript:void(0);" class="book_btn" title="Book A 15 Minuts Meeting"
                                            style="background-color: red;" *ngIf="course_list.status == 0"
                                            (click)="onChangeCourseStatus(course_list,1)"><i class="fas fa-handshake"
                                                style="color: #fff;"></i></a>
                                        <a href="javascript:void(0);" class="book_btn" title="Book A 15 Minuts Meeting"
                                            style="background-color: green;" *ngIf="course_list.status == 1"
                                            (click)="onChangeCourseStatus(course_list,0)"><i class="fas fa-handshake"
                                                style="color: #fff;"></i></a>
                                        <a href="javascript:void(0);" class="class_btn" title="Intresseanmälan "
                                            (click)="onChangeCourseStatus(course_list,2)"><i
                                                class="fas fa-trash"></i></a>

                                        <a href="javascript:void(0);" title=""><img
                                                onerror="this.src='assets/images/t5.jpg'"
                                                [src]="allowurl(apiService.imageBase+course_list.image)" alt=""
                                                class="img-fluid" style="height: 183px;width: 100%;"></a>
                                        <div class="p-3 rel">
                                            <!-- <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 & up (232)</span></p> -->

                                            <p class="b float-left"><a href="javascript:void(0);"
                                                    title="">{{course_list.course_name}}</a>
                                            </p>
                                            <p class="b float-right">${{course_list.amount}}</p>
                                            <p class="clearfix"></p>
                                            <!-- <p class="fs12" [innerHTML]="course_list.description"></p> -->
                                            <!-- <p class="add_cart"><a href="javascript:void(0);" class="btn-sm">Active</a></p> -->
                                            <p class="tbot">Total Class : <span>{{course_list.no_of_classes}}</span></p>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-4 col-sm-6 wow fadeInLeft">
                                    <div class="list_box">
                                        <a href="chat-me.htm" class="chat_btn" title="Let's Chat"><i
                                                class="fas fa-comments"></i></a>
                                        <a href="book-meeting.htm" class="book_btn" title="Book A 15 Minuts Meeting"><i
                                                class="fas fa-handshake"></i></a>
                                        <a href="book-class.htm" class="class_btn" title="Intresseanmälan "><i
                                                class="fas fa-laptop"></i></a>
                                        <a href="course-details.htm" title=""><img src="assets/images/t5.jpg" alt="" class="img-fluid"></a>
                                        <div class="p-3 rel">
                                            <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><span class="letn"> 4.5 & up (232)</span></p>
                                            <p class="b float-left"><a href="course-details.htm" title="">Computer Progr...</a>
                                            </p>
                                            <p class="b float-right">$50.00/hr</p>
                                            <p class="clearfix"></p>
                                            <p class="fs12">Max C.</p>
                                            <p class="chkbox"><input type="checkbox"></p>
                                            <p class="tbot">Duration (in hour) : <span>10</span></p>
                                        </div>
                                    </div>
                                </div> -->

                            </div>
                        </ng-container>
                        <ng-container *ngIf="addCourseFlag">
                            <form [formGroup]="createuser10">
                                <div class="emp_area">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="row justify-content-center">
                                                <div class="col-md-6 text-center pb-4">
                                                    <img src="assets/images/p-blank.jpg" alt="" class="m-auto img-fluid"
                                                        *ngIf="uploadedImagesData ==''">
                                                    <img [src]="allowurl(apiService.imageBase+uploadedImagesData)"
                                                        alt="" class="m-auto img-fluid" *ngIf="uploadedImagesData !=''">
                                                    <p class="mt-3">Add Course Photo</p>
                                                    <p><button class="compare"><i class="fas fa-plus"></i><input
                                                                type="file" accept="image/*"
                                                                (change)="onSelectFile($event.target)" #file id="file"
                                                                style="    opacity: 0;
                                                                position: absolute;
                                                                left: 1px;"> Add Photo</button></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="name_feild">
                                                <p class="">Course Name</p>
                                                <p><input type="text" formControlName="course_name" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted10 && f10.course_name.errors }">
                                                </p>
                                            </div>
                                            <div class="name_feild">
                                                <p>Course Type</p>
                                                <p><select name="" id="" formControlName="course_type"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted10 && f10.course_type.errors }">
                                                        <option value="">Select</option>
                                                        <option value="1">Live</option>
                                                        <option value="2">Self Learn</option>
                                                    </select></p>
                                            </div>
                                            <div class="loc_feild">
                                                <p class="">Price</p>
                                                <p><input type="text" formControlName="amount" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted10 && f10.amount.errors }">
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="loc_feild">
                                                <p class="">Fake Price</p>
                                                <p><input type="text" formControlName="fake_price" class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted10 && f10.fake_price.errors }">
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="loc_feild">
                                                <p>level</p>
                                                <p><select name="" id="" formControlName="age_group_id"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted10 && f10.age_group_id.errors }">
                                                        <option [value]="lvl.id" *ngFor="let lvl of LevelList">
                                                            {{lvl.title}}
                                                        </option>
                                                    </select></p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="loc_feild">
                                                <p class="">Price Per Class</p>
                                                <p><input type="text" formControlName="price_per_classes"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted10 && f10.price_per_classes.errors }">
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="loc_feild">
                                                <p class="">No. Of Class</p>
                                                <p><input type="text" formControlName="no_of_classes"
                                                        class="form-control"
                                                        [ngClass]="{ 'is-invalid': submitted10 && f10.no_of_classes.errors }">
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="course_name">Activities</label>
                                                <div [ngxSummernote]="config" formControlName="hands_on_activities"
                                                    [ngClass]="{ 'is-invalid': submitted10 && f10.no_of_classes.errors }">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="course_name">curriculum</label>
                                                <div [ngxSummernote]="config" formControlName="curriculum"></div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="course_name">Description</label>
                                                <div [ngxSummernote]="config" formControlName="description"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="loc_feild">
                                        <div class="row">
                                            <div class="col-md-12 p-0">
                                                <p class="add_cart"><a href="javascript:void(0);"
                                                        (click)="onSubmitCourse()">Save</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </ng-container>

                    </div>
                    <div class="tab-pane fade" id="pills-tab9" role="tabpanel" aria-labelledby="pills-tab9-tab">
                        <div class="list_box p-4 fs13 rel ">
                            <a href="" class="del1"><i class="far fa-trash-alt"></i></a>
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <div class="list_box">
                                        <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;"
                                            title="" class="port_pop1"><img src="assets/images/t1.jpg" alt=""
                                                class="img-fluid"></a>
                                        <div class="p-3">
                                            <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                                    class="fas fa-star"></i><i class="fas fa-star"></i><i
                                                    class="fas fa-star"></i></p>
                                            <p class="b float-left "><a data-type="iframe"
                                                    data-src="tutor-short-details.htm" href="javascript:;" title=""
                                                    class="port_pop1">Rebecca L</a></p>
                                            <p class="b float-right ">$50.00/hr</p>
                                            <p class="clearfix"></p>
                                            <p class="fs12 ">Dwarka, New Delhi</p>
                                            <p class="tbot ">Totala klass Erfarenhet : <span>10</span></p>
                                            <p class="mt-4"><a href="#" class="compare">Rate & Review</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <div class="row mt-5">
                                        <div class="col-lg-12 col-md-12 pb-3">
                                            <p class="">Course Name</p>
                                            <p class="b mt-2 fs18 "><a href="course-dtl.htm">British (Including
                                                    IGCSE)</a></p>
                                            <p class="mt-2 pb-3 bdrb mb-4 ">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Nam volutpat posuere tristique. Vestibulum ante ipsum
                                                primis in faucibus orci luctus et ultrices posuere cubilia curae;
                                                Curabitur rhoncus urna a commodo
                                                rhoncus. Sed porta ac ipsum in accumsan.</p>
                                        </div>

                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p class="">Booked Date</p>
                                            <p class="b mt-2 ">Friday, July 16, 2021</p>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p class="">Start Date</p>
                                            <p class="b mt-2 ">Friday, July 16, 2021</p>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p class="">Duration</p>
                                            <p class="b mt-2 ">1 hour</p>
                                        </div>
                                        <div class="col-lg-12 col-md-12 pb-3">
                                            <p class="bdrb pb-2"><img src="assets/images/spacer.gif" alt=""></p>
                                        </div>

                                        <div class="col-lg-3 col-md-6">
                                            <p><a href="#" data-toggle="modal" data-target="#res"
                                                    class="compare">Reshedule</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="list_box p-4 fs13 rel ">
                            <a href="" class="del1"><i class="far fa-trash-alt"></i></a>
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <div class="list_box">
                                        <a data-type="iframe" data-src="tutor-short-details.htm" href="javascript:;"
                                            title="" class="port_pop1"><img src="assets/images/t1.jpg" alt=""
                                                class="img-fluid"></a>
                                        <div class="p-3">
                                            <p class="star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i
                                                    class="fas fa-star"></i><i class="fas fa-star"></i><i
                                                    class="fas fa-star"></i></p>
                                            <p class="b float-left "><a data-type="iframe"
                                                    data-src="tutor-short-details.htm" href="javascript:;" title=""
                                                    class="port_pop1">Rebecca L</a></p>
                                            <p class="b float-right ">$50.00/hr</p>
                                            <p class="clearfix"></p>
                                            <p class="fs12 ">Dwarka, New Delhi</p>
                                            <p class="tbot ">Totala klass Erfarenhet : <span>10</span></p>
                                            <p class="mt-4"><a href="#" class="compare">Rate & Review</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-12">
                                    <div class="row mt-5">
                                        <div class="col-lg-12 col-md-12 pb-3">
                                            <p class="">Course Name</p>
                                            <p class="b mt-2 fs18 "><a href="course-dtl.htm">British (Including
                                                    IGCSE)</a></p>
                                            <p class="mt-2 pb-3 bdrb mb-4 ">Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Nam volutpat posuere tristique. Vestibulum ante ipsum
                                                primis in faucibus orci luctus et ultrices posuere cubilia curae;
                                                Curabitur rhoncus urna a commodo
                                                rhoncus. Sed porta ac ipsum in accumsan.</p>
                                        </div>

                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p class="">Booked Date</p>
                                            <p class="b mt-2 ">Friday, July 16, 2021</p>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p class="">Start Date</p>
                                            <p class="b mt-2 ">Friday, July 16, 2021</p>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 pb-3">
                                            <p class="">Duration</p>
                                            <p class="b mt-2 ">1 hour</p>
                                        </div>
                                        <div class="col-lg-12 col-md-12 pb-3">
                                            <p class="bdrb pb-2"><img src="assets/images/spacer.gif" alt=""></p>
                                        </div>

                                        <div class="col-lg-3 col-md-6">
                                            <p><a href="#" data-toggle="modal" data-target="#res"
                                                    class="compare">Reshedule</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-tab10" role="tabpanel" aria-labelledby="pills-tab10-tab">
                        <div class="list_box1">
                            <div class="tab_link">
                                <p class="for_m dd_next hand">Question Paper <i class="fas fa-caret-down"></i></p>
                                <div class="for_d">
                                    <ul class="nav nav-pills mb-3" id="quiz-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link active" id="quiz-question-tab" data-toggle="pill"
                                                href="#quiz-question" role="tab" aria-controls="quiz-question"
                                                aria-selected="true">Question Paper</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link" id="quiz-answer-tab" data-toggle="pill"
                                                href="#quiz-answer" role="tab" aria-controls="quiz-answer"
                                                aria-selected="false">Answer Sheet</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="tab_cont p-2">
                                <div class="tab_cont tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="quiz-question" role="tabpanel"
                                        aria-labelledby="quiz-question-tab">
                                        <ng-container *ngIf="!addQuestionFlag">
                                            <div class="list_box p-4 fs13 rel" *ngFor="let quizz of QuestionList">
                                                <a href="#" data-toggle="modal" data-target="#yono" class="del"><i
                                                        class="fas fa-trash-alt"></i></a>
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 pb-3">
                                                        <p>Quiz Title</p>
                                                        <p class="b mt-2 fs18"><a
                                                                href="course-dtl.htm">{{quizz.quiz_title}}</a></p>
                                                        <p class="mt-2" [innerHTML]="quizz.quiz_description"></p>

                                                    </div>
                                                    <!-- <div class="col-lg-6 col-md-6 pb-3">
                                                        <p>Uploaded Exam Paper</p>
                                                        <div class="b mt-2 c-cont">
                                                            <a href="#"><i class="far fa-play-circle"></i> Download<br>Material
                                                                Name</a>
                                                            <a href="#"><i class="fas fa-file-pdf"></i> Download<br>Material
                                                                Name</a>
                                                        </div>

                                                    </div> -->
                                                    <div class="col-md-12 bdrb"></div>
                                                    <div class="col-md-12">
                                                        <p class="mt-3 float-left">Level : <span
                                                                class="b">{{quizz.level_title}}</span> </p>
                                                        <p class="mt-3 float-right">Submission Date & Time : <span
                                                                class="b">{{quizz.exam_date |date}} /
                                                                {{quizz.exam_time}}</span> </p>
                                                        <p class="clearfix"></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="addQuestionFlag">
                                            <form [formGroup]="createuser12">
                                                <div class="emp_area">
                                                    <div class="row">
                                                        <!-- <div class="col-6">
                                                            <div class="row justify-content-center">
                                                                <div class="col-md-6 text-center pb-4">
                                                                    <img src="assets/images/p-blank.jpg" alt="" class="m-auto img-fluid" *ngIf="uploadedImagesData ==''">
                                                                    <img [src]="allowurl(apiService.imageBase+uploadedImagesData)" alt="" class="m-auto img-fluid" *ngIf="uploadedImagesData !=''">
                                                                    <p class="mt-3">Add Course Photo</p>
                                                                    <p><button class="compare"><i class="fas fa-plus"></i><input
                                                                                type="file" accept="image/*"
                                                                                (change)="onSelectFile($event.target)" #file id="file"
                                                                                style="    opacity: 0;
                                                                                position: absolute;
                                                                                left: 1px;"> Add Photo</button></p>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-12">
                                                            <div class="name_feild">
                                                                <p>Select Subject</p>
                                                                <p><select name="" id="" class="form-control"
                                                                        formControlName="category_id"
                                                                        [ngClass]="{ 'is-invalid': submitted12 && f12.category_id.errors }">
                                                                        <option [value]="lvl.id"
                                                                            *ngFor="let lvl of CategoryList">
                                                                            {{lvl.name}}
                                                                        </option>
                                                                    </select></p>
                                                            </div>
                                                            <div class="name_feild">
                                                                <p>Level</p>
                                                                <p><select name="" id="" class="form-control"
                                                                        formControlName="level_id"
                                                                        [ngClass]="{ 'is-invalid': submitted12 && f12.level_id.errors }">
                                                                        <option [value]="lvl.id"
                                                                            *ngFor="let lvl of LevelList">{{lvl.title}}
                                                                        </option>
                                                                    </select></p>
                                                            </div>
                                                            <div class="loc_feild">
                                                                <p class="">Quiz Title</p>
                                                                <p><input type="text" formControlName="quiz_title"
                                                                        class="form-control"
                                                                        [ngClass]="{ 'is-invalid': submitted12 && f12.quiz_title.errors }">
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="course_name">Quiz Description</label>
                                                                <div [ngxSummernote]="config"
                                                                    formControlName="quiz_description"
                                                                    [ngClass]="{ 'is-invalid': submitted12 && f12.quiz_description.errors }">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-12 row">
                                                            <div class="col-6">
                                                                <div class="name_feild">
                                                                    <p>Exam Date</p>
                                                                    <p><input type="date" formControlName="exam_date"
                                                                            class="form-control"
                                                                            [ngClass]="{ 'is-invalid': submitted12 && f12.exam_date.errors }">
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <div class="name_feild">
                                                                    <p>Exam Time</p>
                                                                    <p><input type="time" formControlName="exam_time"
                                                                            class="form-control"
                                                                            [ngClass]="{ 'is-invalid': submitted12 && f12.exam_time.errors }">
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <div class="name_feild">
                                                                    <p>Question Document</p>
                                                                    <p><input type="file" accept="image/*"
                                                                            (change)="onSelectQuestionFile($event.target)"
                                                                            #file id="file" style="    opacity: 1;
                                                                        position: absolute;
                                                                        left: 1px;">
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <div class="name_feild">
                                                                    <p>Answer Document</p>
                                                                    <p><input type="file" accept="image/*"
                                                                            (change)="onSelectAnswerFile($event.target)"
                                                                            #file id="file" style="    opacity: 1;
                                                                        position: absolute;
                                                                        left: 1px;">
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="loc_feild" style="margin-top: 45px;">
                                                        <div class="row">
                                                            <div class="col-md-12 p-0">
                                                                <p class="add_cart"><a href="javascript:void(0);"
                                                                        (click)="onSubmitQuestionAnswer()">Save</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </ng-container>
                                    </div>
                                    <div class="tab-pane fade" id="quiz-answer" role="tabpanel"
                                        aria-labelledby="quiz-answer-tab">
                                        <div class="list_box p-4 fs13 rel">
                                            <a href="#" data-toggle="modal" data-target="#yono" class="del"><i
                                                    class="fas fa-trash-alt"></i></a>
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 pb-3">
                                                    <p>Course Name</p>
                                                    <p class="b mt-2 fs18"><a href="course-dtl.htm">British (Including
                                                            IGCSE)</a></p>
                                                    <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing
                                                        elit. Nam volutpat posuere tristique. Lorem ipsum dolor sit
                                                        amet, consectetur adipiscing elit. Nam volutpat posuere
                                                        tristique.</p>

                                                </div>
                                                <div class="col-lg-6 col-md-6 pb-3">
                                                    <p>Uploaded Exam Paper</p>
                                                    <div class="b mt-2 c-cont">
                                                        <a href="#"><i class="far fa-play-circle"></i>
                                                            Download<br>Material
                                                            Name</a>
                                                        <a href="#"><i class="fas fa-file-pdf"></i> Download<br>Material
                                                            Name</a>
                                                    </div>

                                                </div>
                                                <div class="col-md-12 bdrb"></div>
                                                <div class="col-md-12">
                                                    <p class="mt-3 float-left">Level : <span class="b">Level 2</span>
                                                    </p>
                                                    <p class="mt-3 float-right">Submission Date & Time : <span
                                                            class="b">Friday,
                                                            July 22, 2021 / 4:30pm</span> </p>
                                                    <p class="clearfix"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab11-tab">
                        <div class="list_box1">
                            <div class="tab_link">
                                <p class="for_m dd_next hand">Manage Homework <i class="fas fa-caret-down"></i></p>
                                <div class="for_d">
                                    <a href="tutor-manage-exam-questions.htm" class="acti">Manage Homework</a>
                                    <a href="tutor-manage-student-performance.htm">Student Prestanda</a>
                                </div>
                            </div>

                            <div class="tab_cont p-2">
                                <div class="list_box p-4 fs13 rel">
                                    <a href="#" data-toggle="modal" data-target="#yono" class="del"><i
                                            class="fas fa-trash-alt"></i></a>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 pb-3">
                                            <p>Course Name</p>
                                            <p class="b mt-2 fs18"><a href="course-dtl.htm">British (Including
                                                    IGCSE)</a></p>
                                            <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                                                volutpat posuere tristique. Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Nam volutpat posuere tristique.</p>

                                        </div>
                                        <div class="col-lg-6 col-md-6 pb-3">
                                            <p>Uploaded Exam Paper</p>
                                            <div class="b mt-2 c-cont">
                                                <a href="#"><i class="far fa-play-circle"></i> Download<br>Material
                                                    Name</a>
                                                <a href="#"><i class="fas fa-file-pdf"></i> Download<br>Material
                                                    Name</a>
                                            </div>

                                        </div>
                                        <div class="col-md-12 bdrb"></div>
                                        <div class="col-md-12">
                                            <p class="mt-3 float-left">Submission Date : <span class="b">Friday,
                                                    July 22, 2021 / 4:30pm</span> </p>
                                            <p class="clearfix"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="list_box p-4 fs13 rel">
                                    <a href="#" data-toggle="modal" data-target="#yono" class="del"><i
                                            class="fas fa-trash-alt"></i></a>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 pb-3">
                                            <p>Course Name</p>
                                            <p class="b mt-2 fs18"><a href="course-dtl.htm">British (Including
                                                    IGCSE)</a></p>
                                            <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                                                volutpat posuere tristique. Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit. Nam volutpat posuere tristique.</p>

                                        </div>
                                        <div class="col-lg-6 col-md-6 pb-3">
                                            <p>Uploaded Exam Paper</p>
                                            <div class="b mt-2 c-cont">
                                                <a href="#"><i class="far fa-play-circle"></i> Download<br>Material
                                                    Name</a>
                                                <a href="#"><i class="fas fa-file-pdf"></i> Download<br>Material
                                                    Name</a>
                                            </div>

                                        </div>
                                        <div class="col-md-12 bdrb"></div>
                                        <div class="col-md-12">
                                            <p class="mt-3 float-left">Submission Date : <span class="b">Friday,
                                                    July 22, 2021 / 4:30pm</span> </p>
                                            <p class="clearfix"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab12-tab">
                        <ng-container *ngIf="userData.Recensioner?.length>0">
                            <div class="list_box p-4 fs13 rel" *ngFor="let teacher_review of this.userData?.Recensioner">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#yono" class="del"><i
                                        class="fas fa-trash-alt"></i></a>
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#yono" class="del"><i
                                        class="fas fa-trash-alt"></i></a>
                                <ul class="revies">
                                    <p>{{teacher_review.name}}</p>
                                    <div class="star">
                                        <ng-container *ngIf="teacher_review.rating ==1">
                                            <i class="fas fa-star"></i>
                                        </ng-container>
                                        <ng-container *ngIf="teacher_review.rating ==2">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                        </ng-container>
                                        <ng-container *ngIf="teacher_review.rating ==3">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                        </ng-container>
                                        <ng-container *ngIf="teacher_review.rating ==4">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                        </ng-container>
                                        <ng-container *ngIf="teacher_review.rating ==5">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                        </ng-container>
                                    </div>
                                    <h5>Madison Nesting Coffee Tables<span>{{teacher_review.created |date}}</span></h5>
                                    <p class="mt-2">{{teacher_review.review}}.</p>
                                    <!-- <p>British (Including IGCSE) - Key Stage2</p> -->
                                </ul>
                            </div>
                        </ng-container>

                    </div>
                    <div class="tab-pane fade" id="pills-tab13" role="tabpanel" aria-labelledby="pills-tab13-tab">
                        <div class="col-lg-12 wow fadeInLeft">
                            <div class="list_box p-4 fs13 rel" *ngFor="let myclasslist of MyClass">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 pb-3">
                                        <p>Student Name</p>
                                        <p class="b mt-2"><a href="course-dtl.htm">{{myclasslist.student_name}}</a></p>
                                    </div>
                                    <div class="col-lg-3 col-md-6 pb-3">
                                        <p>Booked Date</p>
                                        <p class="b mt-2">{{myclasslist.schedule_slot_date |date:'EEEE, MMMM d, y'}} -
                                            {{myclasslist.time_from}}
                                        </p>
                                    </div>
                                    <div class="col-lg-2 col-md-6 pb-3">
                                        <p>Payment</p>
                                        <p class="b mt-2">Paid</p>
                                    </div>
                                    <div class="col-lg-2 col-md-6 pb-3">
                                        <p>Tuition Frequency</p>
                                        <p class="b mt-2">{{myclasslist.tution_frequency}}</p>
                                    </div>
                                    <!-- <div class="col-lg-2 col-md-6 pb-3">
                                        <p><a href="javascript:void(0);" data-toggle="modal" data-target="#inv" class="compare">View
                                                Invoice</a></p>
                                    </div> -->
                                    <div class="col-lg-12 col-md-12 bdrb"></div>
                                    <div class="col-lg-12 col-md-12 pt-4">
                                        <div class="row">
                                            <div class="col-md-6 p-0"></div>
                                            <div class="col-md-3 p-0 pl-3"><a href="javascript:void(0);"
                                                    class="compare">Reschedule</a></div>
                                            <div class="col-md-3 p-0 pl-3"><a href="javascript:void(0);" class="compare"
                                                    (click)="onCancelClassPopup(myclasslist)">Cancel &amp; Refund</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>