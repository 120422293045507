import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiService,CommonService,MessageService } from '../services';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-product-details',
  templateUrl: './product_details.component.html',
  styleUrls: ['./product_details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  addForm: FormGroup | any;
  flag:any=null;
  productId=null;
  resData:any=null;
  constructor(public apiService :ApiService,public sanitizer: DomSanitizer, public activatedRoute: ActivatedRoute,private fb: FormBuilder,public messageService: MessageService,private router: Router,private title: Title, private meta: Meta,public commonService:CommonService ) { 
    this.commonService.SetFooterContent(false);
    this.flag = this.activatedRoute.snapshot.paramMap.get("id");
    if (this.flag) {
      this.productId = this.flag;
    }
  }

  ngOnInit(): void {
    this.getDataById();
    this.addForm = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      comment: ['', [Validators.required]],
      email: ['', [Validators.required]],
      type: ['0'],
      product_id: [''],
    })
  }
  allowurl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  getDataById() {
    var self = this;
    var sendRequestData = {
      id: self.productId
    };
    self.apiService.post('product_details', sendRequestData).subscribe((res: any) => {
      if (res.replyCode == 'success') {
        this.resData = res.data;
        this.title.setTitle(this.resData.meta_title);
        this.meta.updateTag({ name: 'keyword', content: this.resData.meta_keyword });
        this.meta.updateTag({ name: 'description', content: this.resData.meta_description });
      }
    },
      error => {
        console.log("Oops! Something went wrong!");
      });
  }
  onaddForm() {
    var self = this;
    self.addForm.patchValue({
      product_id:self.productId
    })
    if (self.addForm.valid) {
      var sendRequestData = self.addForm.value;
      var requestUrl;
      requestUrl = 'product_inquiry';
      
      self.apiService.post(requestUrl, sendRequestData).subscribe((res: any) => {
        if (res.replyCode == 'success') {
          self.addForm.reset();
          this.messageService.showSuccess(res.replyMsg)
          //this.router.navigate(['/product'])
        }
        else {
          this.messageService.showError(res.replyMsg)
        }
      },
        error => {
          this.messageService.showError('Oops! Something went wrong!')

        });

    }
  }

}
