import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';

@Component({
  selector: 'app-parent-support',
  templateUrl: './parent-support.component.html',
  styleUrls: ['./parent-support.component.scss']
})
export class ParentSupportComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any = [];

  uploadedImagesData: any = '';
  fileToUpload: any = null;
  localData: any;
  createuser1: FormGroup | any; public submitted1 = false;
  TYPE:any='1';
  constructor(private fb: FormBuilder, public apiService: ApiService, public messageService: MessageService, public sanitizer: DomSanitizer, private router: Router, public commonService: CommonService) {
    this.commonService.SetFooterContent(false);
    var jsonData: any = localStorage.getItem('user');
    this.localData = JSON.parse(jsonData);
    if(this.localData.role_id == 5){
      this.TYPE=2;
    }else if(this.localData.role_id == 3){
      this.TYPE=3;
    }else{
      this.TYPE=1;
    }
  }
  get f1() { return this.createuser1.controls; }

  ngOnInit(): void {
    this.createuser1 = this.fb.group({
      message: new FormControl('', [Validators.required]),
    });
  }
  onClosePopup() {
    this.commonService.SetAddParentChild(false);
  }
  onSubmit() {
    this.submitted1=true;
    if (this.createuser1.invalid) {
      this.messageService.showWarning('Please select an image')
      return
    } else {
      var sendRequestData: any = this.createuser1.value;
      console.log(sendRequestData.message);
      
      sendRequestData.type = this.TYPE;
      
      sendRequestData.user_id = this.localData.id;
      sendRequestData.user_id = this.localData.emai;
      sendRequestData.name=this.localData.name;
      sendRequestData.last_name=this.localData.last_name;
      sendRequestData.phone=this.localData.phone;
      sendRequestData.id=this.localData.id;
      sendRequestData.user_email=this.localData.email;
    //   return;
      this.apiService.post('create_support', sendRequestData).subscribe((data: any) => {
        if (data.replyCode == "success") {
          this.createuser1.reset();
          this.submitted1=false;
          this.onClosePopup();
          this.commonService.SetUpdateProfileChanged(true);
          this.messageService.showSuccess(data.replyMsg);
        } else {
          this.messageService.showError(data.replyMsg);
        }
      })
    }
  }
}
