import { ApiService } from '../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, MessageService } from 'src/app/services';

@Component({
  selector: 'app-course-dtl',
  templateUrl: './course-dtl.component.html',
  styleUrls: ['./course-dtl.component.scss']
})
export class CourseDetailsComponent implements OnInit {
  addForm: FormGroup | any;
  contactData: any=[];
  
  uploadedImagesData: any = '';
  fileToUpload: any = null;
  courseId:any;
  courseDetails:any={};
  constructor(private fb: FormBuilder,public apiService :ApiService,public messageService: MessageService,public sanitizer: DomSanitizer,private router: Router,private activatedRoute: ActivatedRoute,public commonService:CommonService) { 
    this.commonService.SetFooterContent(false);
  }

  ngOnInit(): void {
    this.courseId = this.activatedRoute.snapshot.paramMap.get("id");
    console.log('courseId>>>>>>>>',this.courseId);
    this.getCourseDetails(this.courseId)
  }
  getCourseDetails(courseId:any){
    this.apiService.post('courses_details',{id:courseId}).subscribe((data:any) => {
      this.courseDetails=data.data[0];
      console.log('courseDetails>>>>>>>>',this.courseDetails);
    })
  }
  
  allowurl(url:any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
